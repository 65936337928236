import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GridComponent} from '../../grid.component';
import {OverlayComponent} from '../../../ui/overlay/overlay.component';
import {ColDef, Utils as AgUtils} from '@ag-grid-community/core';
import {AsyncSubject} from 'rxjs';
import {ListSettingsService} from '../services/list-settings.service';

@Component({
  selector: 'eo-custom-sort',
  templateUrl: './custom-sort.component.html',
  styleUrls: ['./custom-sort.component.scss']
})
export class CustomSortComponent {

  @Input() eoGrid: GridComponent;
  @Input() overlay: OverlayComponent;
  @Input() id = '#sort';

  private _sortFields: any[] = [];
  model: any[] = [];

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() active: EventEmitter<any> = new EventEmitter<any>();


  @Input() set sortFields(opts: any[]) {
    this._sortFields = this.settingsService.setupSettings(this.id, opts, 'field', 'sort');
    if (this.sortFields && this.sortFields.some(f => f.sort)) {
      setTimeout(() => this.sort('', ''), 0);
    }
  }

  get sortFields() {
    return this._sortFields;
  }


  constructor(private settingsService: ListSettingsService) {
  }

  static sortColDefs(sortFields: ColDef[]): ColDef[] {
    const val = sortFields.map(f => ({...f, sortable: true, hide: true, comparator: (a, b) => AgUtils.defaultComparator(a, b, true)}) as ColDef);
    return val;
  }

  onChange() {
    let isActive = !!this.model.length;
    this.active.emit(isActive);
    let evt = new CustomEvent('change', {detail: this.model});
    this.change.emit(evt);
    this.settingsService.saveSettings(this.id, this.model, 'colId', 'sort');
    this.updateGrid(this.eoGrid, evt);
    if (this.overlay) {
      this.overlay.active = isActive;
    }
  }

  sort(field, sort) {
    if (field) {
      this.sortFields.find(f => f.field === field).sort = sort;
    }
    this.model = this.sortFields
      .filter(f => f.sort)
      .map(f => ({colId: f.field, sort: f.sort}));
    this.onChange();
  }

  updateGrid(grid: GridComponent | AsyncSubject<GridComponent>, evt: CustomEvent) {
    if (grid instanceof AsyncSubject) {
      grid.subscribe(g => this.updateGrid(g, evt));
    } else if (grid && grid.isReady && evt.detail) {
      grid.api.setSortModel(evt.detail);
    }
  }

  reset() {
    this.sortFields.forEach(f => (f.sort = ''));
    this.model = [];
    this.onChange();
  }
}
