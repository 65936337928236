<div class="controls flex-row" *ngIf="pagination && pagination.pages > 1">
  <button [disabled]="pagination.page === 1" (click)="goToPage(1)">
    <eo-icon class="first" [iconSrc]="'assets/_default/svg/ic_arrow_last.svg'" ></eo-icon>
  </button>
  <button [disabled]="pagination.page <= 1" (click)="goToPage(pagination.page - 1)">
    <eo-icon class="prev" [iconSrc]="'assets/_default/svg/ic_arrow_next.svg'" ></eo-icon>
  </button>

  <form (ngSubmit)="onPagingFormSubmit()" [formGroup]="pagingForm">
    <input type="number" formControlName="page" />
    <div>{{ pagination.pages }}{{ isGreater ? '+' : '' }}</div>
  </form>

  <button [disabled]="isGreater ? false : pagination.page >= pagination.pages" (click)="goToPage(pagination.page + 1)">
    <eo-icon class="next" [iconSrc]="'assets/_default/svg/ic_arrow_next.svg'" ></eo-icon>
  </button>
  <button [disabled]="isGreater ? true : pagination.page === pagination.pages" (click)="goToPage(pagination.pages)">
    <eo-icon class="last" [iconSrc]="'assets/_default/svg/ic_arrow_last.svg'" ></eo-icon>
  </button>
</div>