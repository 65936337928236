import {AgGridModule} from '@ag-grid-community/angular';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {YvcDatepickerModule} from '@yuuvis/components/datepicker';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';
import {YvcTabsModule} from '@yuuvis/components/tabs';
import {PipesModule} from '../../eo-framework-core/pipes/pipes.module';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {GridModule} from '../grid/grid.module';
import {ListContainerModule} from '../list-container/list-container.module';
import {MediaModule} from '../media/media.module';
import {ShortcutsModule} from '../shortcuts/shortcuts.module';
import {UiModule} from '../ui/ui.module';
import {ObjectFormEditComponent} from './object-form-edit/object-form-edit.component';
import {ObjectFormHelperService} from './object-form-helper.service';
import {FormElementTableComponent} from './object-form/form-element-table/form-element-table.component';
import {RowEditComponent} from './object-form/form-element-table/row-edit/row-edit.component';
import {FormElementComponent} from './object-form/form-element/form-element.component';
import {ObjectFormGroupComponent} from './object-form/object-form-group/object-form-group.component';
import {ObjectFormComponent} from './object-form/object-form.component';
import { FormElementTemplate } from './object-form/form-element/form-element-template.directive';


/**
 * @module
 * @description
 * Module rendering a dms objects form.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    PipesModule,
    AgGridModule,
    ShortcutsModule,
    FormElementsModule,
    ListContainerModule,
    GridModule,
    MediaModule,
    YvcDatepickerModule,
    YvcTabsModule,
    YvcSplitViewModule
  ],
  exports: [ObjectFormComponent, ObjectFormGroupComponent, ObjectFormEditComponent, FormElementComponent, FormElementTableComponent],
  providers: [ObjectFormHelperService],
  declarations: [
    ObjectFormComponent,
    ObjectFormGroupComponent,
    ObjectFormEditComponent,
    FormElementComponent,
    FormElementTableComponent,
    RowEditComponent,
    FormElementTemplate
  ]
})
export class ObjectFormModule {
}
