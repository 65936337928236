<eo-side-bar (eoOutsideClick)="sidebarShow = !sidebarShow" *ngIf="sidebarShow">
  <div class="navi">
    <ng-content select="eo-sidebar-plugin.navi"></ng-content>
  </div>
  <div class="help">
    <ng-content select="eo-sidebar-plugin.help"></ng-content>
  </div>
</eo-side-bar>

<div class="eo-app-bar" [ngClass]="{transparent: transparent}" eoRtlAware>

  <div class="left">

    <eo-icon id="app-menu" class="menu white" tabindex="0"
             (click)="toggleSidebar()" (keyup.enter)="toggleSidebar()"
             [iconSrc]="'assets/_default/svg/ic_hamburger.svg'"></eo-icon>
    <eo-icon (click)="openDashboard($event.ctrlKey)" (auxclick)="openDashboard(true)"
              (keyup.enter)="openDashboard(false)" tabindex="0"
              class="logo white" [iconSrc]="logo"></eo-icon>

    <div class="actions">
      <a tabindex="0" (keydown.enter)="openSearchOverlay()" (click)="openSearchOverlay()">
        <eo-icon id="app-search" class="search white" queryParamsHandling="preserve"
                [iconTitle]="iconTitles.search"
                [iconSrc]="'assets/_default/svg/ic_search.svg'"></eo-icon>
      </a>

      <a tabindex="0" (keydown.enter)="openCreateObjectOverlay()" (click)="openCreateObjectOverlay()">
        <eo-icon id="app-add" class="add white" *ngIf="capabilities.intray"
                [iconTitle]="iconTitles.file"
                [iconSrc]="'assets/_default/svg/ic_add.svg'"></eo-icon>
      </a>
      <a tabindex="0" (keydown.enter)="openProcessOverlay()" (click)="openProcessOverlay()">
        <eo-icon id="app-actions" class="actions white" *ngIf="exeActions"
                [iconTitle]="iconTitles.exeactions"
                [iconSrc]="'assets/_default/svg/ic_bpm_start.svg'"></eo-icon>
      </a>
    </div>
  </div>
  <div class="right">
    <div class="sub">
      <eo-clipboard></eo-clipboard>
    </div>
    <a tabindex="0" (keydown.enter)="openState('/favorites')" (click)="openState('/favorites')">
      <eo-icon class="button white favorite"
              [iconTitle]="iconTitles.favorites" *ngIf="capabilities.favorites"
              [iconSrc]="'assets/_default/svg/ic_favorite.svg'"></eo-icon>
    </a>
    <a tabindex="0" (keydown.enter)="openState('/prepare')" (click)="openState('/prepare')">
      <eo-icon class="button white prepare" *ngIf="capabilities.intray"
              [iconTitle]="iconTitles.prepare"
              [badge]="preparedItemsCount" [iconSrc]="'assets/_default/svg/ic_prepare.svg'"></eo-icon>
    </a>
    <a tabindex="0" (keydown.enter)="openState('/inbox')" (click)="openState('/inbox')">
      <eo-icon class="button white inbox" [ngClass]="{alert: inboxState?.overduemessages > 0}" *ngIf="capabilities.inbox"
              [iconTitle]="iconTitles.index"
              [badge]="inboxState?.totalmessages" [iconSrc]="'assets/_default/svg/ic_inbox.svg'"></eo-icon>
    </a>
    <a tabindex="0" href="{{docu.link}}" target="_blank">
      <eo-icon class="button white about"
              [iconTitle]="iconTitles.about" [iconSrc]="'assets/_default/svg/ic_about.svg'"></eo-icon>
    </a>
    <eo-overlay #userpanel (onToggle)="onToggleProfileOverlay()">
      <eo-user-avatar class="btn" id="app-profile" [showPresence]="true" [title]="iconTitles.profile" tabindex="0"></eo-user-avatar>

      <div class="eo-profile" tabindex="-1">
        <div class="avatar" tabindex="-1">
          <eo-user-avatar tabindex="-1" ></eo-user-avatar>
          <div class="overlay" tabindex="-1">
            <div class="name">{{user.firstname}} {{user.lastname}}</div>
            <div class="mail">{{user.email}}</div>
            <div class="presence" *ngIf="!user.present" translate>eo.state.settings.presence.absent</div>
          </div>
        </div>
        <div class="nav" eoTrapFocus>
          <div class="nav-item settings" (keydown.enter)="userpanel?.close()" (click)="userpanel?.close()" >
            <a (keydown.enter)="openState('/settings')" (click)="openState('/settings')" id="settings" tabindex="0" routerLinkActive="active-link" focusable translate>eo.bar.button.settings.title</a>
          </div>
          <div class="nav-item logout" *ngIf="!capabilities.sso">
            <a [routerLink]="['/enter', { logout: true }]" routerLinkActive="active-link" translate>eo.bar.button.logout.title</a>
          </div>
          <ng-content select="eo-sidebar-plugin.profile"></ng-content>
        </div>
      </div>
    </eo-overlay>


  </div>
</div>
