import {Component} from '@angular/core';
import {GridService} from './../../../eo-framework-core/api/grid.service';
import {IFilterAngularComp} from '@ag-grid-community/angular';
import {IAfterGuiAttachedParams, IDoesFilterPassParams} from '@ag-grid-community/core';
import {SetFilterComponent} from '../extensions/filter/setFilters/set-filter.component';

@Component({
  selector: 'eo-list-filter',
  templateUrl: '../extensions/filter/setFilters/set-filter.component.html',
  styleUrls: ['../extensions/filter/setFilters/set-filter.component.scss']
})
export class ListFilterComponent extends SetFilterComponent implements IFilterAngularComp {
  params: any = {};
  operator = 'OR';

  constructor(private gridApi: GridService) {
    super(null);
  }

  onValueChange() {
    this.value = this.isFilterActive() ? [''].concat(this.options.filter(o => o.value).map(o => o.val)) : null;
    if (!this.params.filterParams || !this.params.filterParams.applyButton) {
      this.params.api.onFilterChanged();
    }
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    const value = params.data[this.params.colDef.field];
    return this.options.some(opt => opt.value ? value === opt.val : false);
  }

  getModel() {
    return this.value && this.value.length ? {
      filterType: 'ListFilterComponent',
      filter: this.value
    } : null;
  }

  setModel(value: any) {
    this.value = value;
    (this.options || []).forEach(o => (o.value = this.value && this.value.length ? this.value.contains(o.val) : true));
  }

  agInit(params: any) {
    this.params = params;
    this.params.context = {defaultValue: true};
    this.params.filterChangedCallback = () => this.onValueChange();
    this.options = (this.params.colDef.filterParams.values || [])
    .map(val => ({value: true, val, label: this.params.colDef.cellRenderer({value: val, context: this.gridApi.getContext()}) }));
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    // this.autoCompleteInput.multiInputEL.nativeElement.focus();
  }
}
