/**
 * Interface defining a shortcut to execute a GlobalShortcutAction
 */
export class Shortcut {

  public which;

  static isValid(evt: KeyboardEvent | Shortcut) {
    // match only 1 digit or 1 letter
    return (evt.key || String.fromCharCode(evt.which)).match(new RegExp('^(\\d|[a-zA-Z])$'));
  }

  static match(evt: KeyboardEvent, shortcut: Shortcut | string) {
    let s = shortcut instanceof Shortcut ? shortcut : new Shortcut(shortcut);
    return s.key.toLocaleLowerCase() === (evt.key || String.fromCharCode(evt.which)).toLocaleLowerCase() &&
      s.ctrlKey === evt.ctrlKey && s.shiftKey === evt.shiftKey && s.altKey === evt.altKey;
  }

  constructor(public key: string, public ctrlKey = false, public shiftKey = false, public altKey = false) {
    if (!Shortcut.isValid(this)) {
      console.error(`Shortcut '${key}' is not valid. Shortcut should contain 1 digit or 1 letter!`);
    } else {
      this.which = key.charCodeAt(0);
    }
  }

  match(evt: KeyboardEvent) {
    return Shortcut.match(evt, this);
  }

  toString() {
    // todo: Cmd vs. Ctrl
    return `${this.ctrlKey ? 'Ctrl + ' : ''}
            ${this.shiftKey ? 'Shift + ' : ''}
            ${this.altKey ? 'Alt + ' : ''}
            ${this.key}`;
  }
}
