import {from as observableFrom, BehaviorSubject, ReplaySubject, Observable} from 'rxjs';
import {groupBy, reduce, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {
  SearchFilter, SearchQuery, SearchState, SystemService,
  ObjectType, SearchService, EventService, EnaioEvent, Utils, QueryScope
} from '@eo-sdk/core';

/**
 * AppSearchService is the apps main entry point for searching (used by the appBarSearch component).
 * It provides an `query$` observable components can subscribe to, to
 * stay in touch with updates to the query.
 */
@Injectable()
export class AppSearchService {

  private query = new SearchQuery();
  private aggs: any;
  private lastQuery = new SearchQuery();
  private queryState = new SearchState();
  // Holds the last aggregation properties sent to the server
  // Used for preventing redundant calls
  private lastAggCallComparator: string;

  // Subject holding the current query (initial value is a new SearchQuery)
  // @see: http://blog.angular-university.io/how-to-build-angular2-apps-using-rxjs-observable-data-services-pitfalls-to-avoid/#rxjssubjectandhowtouseit
  // todo: maybe add support for holding a query history, so the user can reload older queries as well
  private querySource = new ReplaySubject<SearchQuery>(1);
  private queryStateSource = new BehaviorSubject<SearchState>(this.queryState);

  // Expose an observable instead of the subject itself to prevent the service clients from
  // themselves emitting store values directly instead of calling action methods, and therefore
  // bypassing the store.
  public query$: Observable<SearchQuery> = this.querySource.asObservable();
  // Search service also exposes an observable that emits the state of the current query.
  // We separate the state of the query from the query itself as otherwise changhing the queries
  // state will trigger all subscribers of the query observale and lead to undesired behaviour
  public queryState$: Observable<SearchState> = this.queryStateSource.asObservable();
  public objectTypeGroups = [];
  public typesAllowedUnderSysroot = [];

  constructor(private searchService: SearchService,
    private eventService: EventService,
    private system: SystemService) {

    this.setDefaultAggs();
    this.eventService.on(EnaioEvent.LOGOUT)
      .subscribe(res => {
        this.reset(true);
      });

    this.querySource.next(this.query);

    // Fetch object types and groups that serve as search filter params.
    // These groups are provided through the services `objectTypeGroups` property.
    this.system.system$.subscribe((systemDefinition) => {

      this.typesAllowedUnderSysroot = systemDefinition.types.filter(type => type.parenttypes.includes('sysroot'));

      let groups = [];
      observableFrom(systemDefinition.types)
        .pipe(
          groupBy((type: any) => type.group || '0'),
          mergeMap(g => g.pipe(reduce((acc, curr) => [...acc, curr], [])))
        )
        .subscribe(otg => {
          groups.push({
            label: otg[0].group || '0',
            types: this.sortObjectTypeGroup(otg)
          });
        });
      this.objectTypeGroups = groups.sort(Utils.sortValues('label'));
    });
  }

  resetAggs() {
    this.setDefaultAggs();
    this.lastAggCallComparator = null;
  }

  private setDefaultAggs() {
    this.aggs = {
      type: {}
    };
  }

  // helper method for sorting the entries of an object type group
  private sortObjectTypeGroup(types) {
    return types.sort((a, b) => {

      if (a.isContextFolder && !b.isContextFolder) {
        return -1;
      }
      if (!a.isContextFolder && b.isContextFolder) {
        return 1;
      }
      if (a.isFolder && !b.isFolder) {
        return -1;
      }
      if (!a.isFolder && b.isFolder) {
        return 1;
      }
      if (a.isFolder && b.isFolder) {
        return Utils.sortValues('label').call(this, a, b);
      }
      return Utils.sortValues('label').call(this, a, b);
    });
  }

  // Extends the objectTypesGroups by the elements for each object type
  // Used in expert search mode
  public buildTypeGroupTree() {

    let gTree = [];
    let i = 0;
    for (let group of this.objectTypeGroups) {
      let g = {
        id: i++,
        label: group.label,
        tree: []
      };
      let types = [];
      for (let objectType of group.types) {

        let t = {
          id: objectType.id,
          label: objectType.label,
          children: []
        };
        let children = [];
        for (let el of objectType.elements) {
          children.push({
            label: el.label,
            type: el.type,
            data: el.qname
          });
        }
        // sort by label
        t.children = children.sort(Utils.sortValues('label'));
        types.push(t);
      }
      g.tree = types.sort(Utils.sortValues('label'));


      gTree.push(g);
    }
    return gTree;
  }

  /**
   * Override the current query by a different one.
   * This could be done by for example loading a stored query.
   *
   * @param query - the query that should replace the current query, this may be either a SearchQuery instance,
   * a JSON string of an object matching the search api
   */
  public setQuery(query: SearchQuery) {
    if (this.isNewQuery(query)) {
      this.setDefaultAggs();
      this.query = query;
      this.querySource.next(this.query);
    }
  }

  /**
   * Resets the current query.
   */
  public clearQuery() {
    this.setQuery(new SearchQuery());
  }

  private isNewQuery(query: SearchQuery): boolean {
    return JSON.stringify(query.getQueryJson()) !== JSON.stringify(this.query.getQueryJson());
  }

  /**
   * toggles the searchmode from regular search to expert search and vice versa
   */
  public toggleExpertMode() {
    if (!this.query.expertMode) {
      this.lastQuery = this.query;
      this.setDefaultAggs();
      this.query = new SearchQuery();
      this.query.expertMode = true;
    } else {
      this.query = this.lastQuery;
    }
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.TOGGLED_EXPERTMODE;
    this.querySource.next(this.query);
    this.aggregate();

  }

  public setTerm(term: string) {
    this.query.term = term;
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.TERM_SET;
    this.querySource.next(this.query);
    this.aggregate();
  }

  public setQueryScope(scope: QueryScope) {
    this.query.scope = scope;
    this.querySource.next(this.query);
    this.aggregate();
  }

  // set aggregation properties to be applied to ONLY aggregate search queries
  public setAggs(aggs: any, extend = false) {
    this.aggs = extend ? {...this.aggs, ...aggs} : aggs;
  }

  /**
   * Toggles a type filter.
   * @param type - object type
   */
  public toggleQueryType(type: ObjectType) {
    this.query.toggleType(type);
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.TOGGLED_TYPE;
    this.querySource.next(this.query);
    this.aggregate(SearchQuery.BASE_PARAMS.TYPE);
  }

  /**
   * Sets the queries target types.
   *
   * @param types - array of ObjectTypes
   * @param silent If set to true aggregate won't be called
   */
  public setQueryTypes(types: ObjectType[], silent?: boolean) {
    this.query.types = types;
    this.aggs = {
      type: {
        sub: {
          contextfoldertype: {}
        }
      }
    };
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.TYPES_SET;
    this.querySource.next(this.query);
    if (!silent) {
      this.aggregate();
    }
  }

  /**
   * Sets the queries target context type.
   *
   * @param type The context type to be set or null for resetting
   * @param silent If set to true aggregate won't be called
   */
  public setContextType(type: ObjectType, silent?: boolean) {

    if (this.query.contextFolderTypes.length && !type) {
      // clear context folder
      this.emitNewContextFolderType(null);
    } else if (this.query.contextFolderTypes.length && type.name !== this.query.contextFolderTypes[0].name) {
      // got a type to be set up that differs from the current one
      this.emitNewContextFolderType(type);
    } else if (!this.query.contextFolderTypes.length) {
      // nothing set up so far
      this.emitNewContextFolderType(type);
    }
    // trying to setup the same type as the current on should not emit a new value
  }

  private emitNewContextFolderType(type, silent?: boolean) {
    if (!type && this.query.contextFolderTypes.length === 0) {
      return;
    }
    this.aggs = {
      type: {
        sub: {
          contextfoldertype: {}
        }
      }
    };
    this.query.contextFolderTypes = type ? [type] : [];
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.CONTEXT_FOLDER_SET;
    this.querySource.next(this.query);
    if (!silent) {
      this.aggregate();
    }
  }

  /**
   * Toggles a created filter.
   * @param filter - the filter to be toggled
   */
  public toggleQueryFilter(filter: SearchFilter, override?: boolean) {
    this.query.toggleFilter(filter, override);
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.TOGGLED_FILTER;
    this.querySource.next(this.query);
    this.aggregate(filter.property);
  }

  /**
   * Toggles created filters and aggregates them together.
   * @param filters - the filters to be toggled
   */
  public toggleQueryFilters(filters: SearchFilter[], override?: boolean) {
    let baseParam = '';
    filters.forEach(filter => {
      this.query.toggleFilter(filter, override);
      this.query.__updateCause = SearchQuery.UPDATE_CAUSE.TOGGLED_FILTER;
      baseParam += filter.property + ' ';
    });

    this.querySource.next(this.query);
    this.aggregate(baseParam.trim());
  }

  /**
   * Adds a filter to the query.
   * @param filter - the filter to be added
   */
  public addQueryFilter(filter: SearchFilter) {
    this.query.addFilter(filter);
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.ADDED_FILTER;
    this.querySource.next(this.query);
    this.aggregate(filter.property);
  }

  /**
   * Removes a filter to the query.
   * @param filter - the filter to be removed
   */
  public removeQueryFilter(filter: SearchFilter) {
    this.query.removeFilter(filter.property);
    this.query.__updateCause = SearchQuery.UPDATE_CAUSE.REMOVED_FILTER;
    this.querySource.next(this.query);
    this.aggregate(filter.property);
  }

  /**
   * Sets the filters for an indexdata search.
   * Setting them will also remove all indexdata filters set before.
   *
   * @param filters - the filters to be set
   * @param silent If set to true aggregate won't be called
   */
  public setIndexSearchQueryFilters(filters: SearchFilter[], silent?: boolean) {

    // remove previous filters (all filters that are not baseparam filters)
    this.query.filters = this.query.filters.filter((f) => this.isBaseParamFilter(f));
    for (let f of filters) {
      this.query.addFilter(f);
    }
    if (!silent) {
      this.aggregate();
    }
  }

  public getIndexSearchQueryFilters(query?: SearchQuery): SearchFilter[] {
    let q = query || this.query;
    return q.filters.filter((f) => !this.isBaseParamFilter(f));
  }

  /**
   * Determines whether or not the provided search filter belongs to a base param.
   *
   * @param filter - the filter to be checked
   */
  public isBaseParamFilter(filter: SearchFilter): boolean {
    //return Object.values(SearchQuery.BASE_PARAMS).some((bp) => filter.property === bp);
    return Object.keys(SearchQuery.BASE_PARAMS).some((bpKey) => filter.property === SearchQuery.BASE_PARAMS[bpKey]);
  }

  /**
   * Executes the current query as aggregate search
   */
  public aggregate(baseParam = '') {

    const aggQuery = {aggs: this.aggs, ...this.query.getQueryJson()};
    // compare the call to be made with a former request
    if (this.lastAggCallComparator !== JSON.stringify(aggQuery)) {
      this.lastAggCallComparator = JSON.stringify(aggQuery);
      this.getSearchState(aggQuery)
        .subscribe((queryState: SearchState) => {
          queryState.lastChange = baseParam;
          this.queryState = queryState;
          this.queryStateSource.next(this.queryState);
        });
      return true;
    }
    return false;
  }

  /**
   * Reset the query
   */
  public reset(silent?: boolean) {
    this.query = new SearchQuery();
    this.setDefaultAggs();
    this.querySource.next(this.query);
    if (!silent) {
      this.aggregate();
    }
  }

  exitIndexdataSearch() {
    this.setIndexSearchQueryFilters([], true);
    this.setQueryTypes([], true);
    this.setContextType(null, true);
    this.aggregate();
  }

  /**
   * Executes the current query as aggregate search
   */
  public getSearchState(queryJson: any) {
    return this.searchService.getSearchState(queryJson);
  }

  public autocomplete(term: string) {
    return this.searchService.autocomplete(term);
  }

  /**
   * Finds the object type element inside of the queries target types
   * for a form elements name. Used e.g. for the search summary panel.
   *
   * @param formElementName - the name of the form element
   * @return the object type element matching the form elements name
   */
  public getTargetTypeElementByName(formElementName: string): any {
    let i = 0;
    let match;
    while (!match && i < this.query.types.length) {
      match = this.query.types[i].elements.find((element) => element.qname === formElementName);
      i++;
    }
    return match;
  }
}
