import {Component, EventEmitter, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {DmsService, NotificationsService, TranslateService} from '@eo-sdk/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';

@Component({
  selector: 'eo-share-object',
  templateUrl: './share-object.component.html',
  styleUrls: ['./share-object.component.scss']
})
export class ShareObjectComponent implements OnInit, ActionComponent {
  selection: any[];
  finished: EventEmitter<any> = new EventEmitter<any>();
  canceled: EventEmitter<any> = new EventEmitter<any>();

  header: string;
  orgaLabel: string;
  shareForm: UntypedFormGroup = this.fb.group({
    orgaFieldFCN: [],
    includeChildren: []
  });
  // dataMeta: any = [];

  constructor(
    private translate: TranslateService,
    private dms: DmsService,
    private toast: NotificationsService,
    private fb: FormBuilder) {

    this.header = this.translate.instant('eo.manage.rights.document.title');
    this.orgaLabel = this.translate.instant('eo.manage.rights.element.label');

  }

  ngOnInit() {
    const orgaValue = [];
    const shareChildren = [];
    if (this.selection[0].isFolder) {
      this.header = this.translate.instant('eo.manage.rights.folder.title');
    }
    this.selection[0].shares.forEach(avObject => avObject.sharechildren ? shareChildren.push(avObject.name) : orgaValue.push(avObject.name));

    this.shareForm.patchValue({
      orgaFieldFCN: orgaValue,
      includeChildren: shareChildren
    })
  }


  private _shareMessage() {
    let shared = '';
    let field = '';

    if (this.shareForm.value.orgaFieldFCN.length > 0) {
      shared = this.shareForm.value.orgaFieldFCN.toString();
    } else if (this.shareForm.value.includeChildren.length > 0) {
      shared = this.shareForm.value.includeChildren.toString();
    } else {
      this.toast.success(this.translate.instant('eo.manage.rights.message.notshared'));
      return;
    }
    field = 'eo.manage.rights.message';

    if (this.shareForm.value.orgaFieldFCN.length > 0 && this.shareForm.value.includeChildren.length > 0) {
      shared = `${this.shareForm.value.orgaFieldFCN.toString()}, ${this.shareForm.value.includeChildren.toString()}`;
    }
    this.translate.get(field, {
      title: this.selection[0].title,
      shared: shared,
    }).subscribe(val => this.toast.success(val));
  }

  private _getShareWith(): {id: string, shareChildren?: boolean}[] {
    return [
      ...(this.shareForm.value.orgaFieldFCN || []).map(o => ({id: o})),
      ...(this.shareForm.value.includeChildren || []).map(o => ({id: o, shareChildren: true})),
    ]
  }

  share() {
    this.dms.share(
      this.selection[0],
      this._getShareWith()
    ).subscribe(() => {
      this._shareMessage();
      this.finished.emit();
    });
  }

  unshare() {
    this.dms.share(this.selection[0], []).subscribe(() => {
      this.toast.success(this.translate.instant('eo.manage.rights.message.notshared'));
      this.finished.emit();
    });

  }

  cancel() {
    this.canceled.emit();
  }

}
