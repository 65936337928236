import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GridModule} from '../../eo-framework/grid/grid.module';
import {EoFrameworkModule} from '../../eo-framework/eo-framework.module';
import {PrepareStateRoutingModule} from './prepare-state-routing.module';
import {PrepareStateComponent} from './prepare-state/prepare-state.component';
import {ListContainerModule} from '../../eo-framework/list-container/list-container.module';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';


@NgModule({
  imports: [
    CommonModule,
    PrepareStateRoutingModule,
    GridModule,
    ListContainerModule,
    EoFrameworkModule,
    YvcSplitViewModule
  ],
  declarations: [PrepareStateComponent]
})
export class PrepareStateModule {
}
