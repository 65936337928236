import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {NotificationsService, SearchResult, TranslateService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PendingChangesComponent} from '../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
@UntilDestroy()
@Component({
  selector: 'eo-result-state',
  templateUrl: './result-state.component.html',
  styleUrls: ['./result-state.component.scss']
})
export class ResultStateComponent implements OnDestroy, PendingChangesComponent {

  query: any;
  clearAfterExecute: boolean;
  searchTerm: string;
  openImmediately = false;
  typeName = 'default';

  constructor(private pendingChanges: PendingChangesService,
    private route: ActivatedRoute,
    private titleService: PageTitleService,
    private selection: SelectionService,
    private router: Router,
    private notify: NotificationsService,
    public translate: TranslateService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.search.title'));

    this.route.queryParams
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((params: any) => {
        if (params.query) {
          if (!!this.pendingChanges.check()) return;
          this.query = Utils.formDataParse(decodeURIComponent(params.query));
          this.typeName = this.query.types && this.query.types.length === 1 ? this.query.types[0] : 'default';
          this.clearAfterExecute = params.silent;
          this.searchTerm = this.query.term;
        }
        if (params.open) {
          this.openImmediately = params.open;
        }
      });
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  onResult(result: SearchResult) {
    if (result.count.value === 1 && this.openImmediately) {
      const queryParams: NavigationExtras = {queryParams: {'type': result.hits[0].type}};
      return this.router.navigate(['/object', result.hits[0].id], queryParams);
    }
    this.openImmediately = false;
  }

  onQueryInvalid() {
    this.notify.warning(this.translate.instant('eo.search.query.invalid'));
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.selection.clear();
  }
}
