<ng-container *ngIf="isOverDue; else notOverDue">
  <div class="notice duetime pastDue"
    [translateParams]="{date: item?.duetime | localeDate}"
    translate>eo.process.task.overdue</div>
</ng-container>
  
<ng-template #notOverDue>
  <div class="notice duetime"
    [translateParams]="{date: item?.duetime | localeDate}"
    translate>eo.process.task.due</div>
</ng-template>