import {Component, OnInit, Input, HostListener, Output, EventEmitter} from '@angular/core';
import {GlobalShortcutComponent} from '../../global-shortcut-component.interface';
import {GlobalShortcutAction} from '../../global-shortcut-action.interface';
import {Shortcut} from '../../shortcut.interface';

@Component({
  selector: 'eo-global-shortcuts-section',
  templateUrl: './global-shortcuts-section.component.html',
  styleUrls: ['./global-shortcuts-section.component.scss']
})
export class GlobalShortcutsSectionComponent implements OnInit {

  _component: GlobalShortcutComponent;
  _actions: GlobalShortcutAction[];

  @Input() target;

  @Input('component')
  set component(component: GlobalShortcutComponent) {
    // convert strings to Shortcuts which adds immediate validation
    component.actions.forEach(a => a.shortcut = a.shortcut instanceof Shortcut ? a.shortcut : new Shortcut(a.shortcut));
    this._component = component;

    // filter hidden actions
    this._actions = this._component.actions.filter((a) => !a['isHidden'] || !a.isHidden(this.target));
  }

  @Output() onActionExecuted = new EventEmitter();
  activeActionIndex = 0;

  constructor() {
  }

  @HostListener('document:keyup', ['$event']) onKey(event) {
    if (this._actions.length && this._component.focused) {
      let length = this._actions.length - 1;
      if (event.which === 38) {
        // Arrow Up
        this.activeActionIndex = this.activeActionIndex === 0 ? length : this.activeActionIndex - 1;
      } else if (event.which === 40) {
        // Arrow Down
        this.activeActionIndex = this.activeActionIndex === length ? 0 : this.activeActionIndex + 1;
      } else if (event.which === 13) {
        // ENTER
        this.executeAction(this._actions[this.activeActionIndex]);
      }
    }

    // evaluate shortcuts
    if (Shortcut.isValid(event)) {
      let action = this._actions.find(({shortcut}) => shortcut && Shortcut.match(event, shortcut));
      this.executeAction(action);
    }
  }

  executeAction(action) {
    if (action) {
      this.onActionExecuted.emit();
      action.onExecuteAction(this.target);
    }
  }

  ngOnInit() {
  }

}
