import { Component } from '@angular/core';

@Component({
  selector: 'eo-empty-list',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent {

}
