<section [formGroup]="parent" class="password-input">
  <input  class=""
          autocomplete="off"
          [type]="showPassword ? 'text' : 'password'"
          [pattern]="pattern"
          [placeholder]="placeholder"
          autocomplete="new-password"
          formControlName="{{element}}">

  <a *ngIf="canChangepasswordVisiblety" (click)="showPassword = !showPassword">
    <eo-icon class="password-input__icon-show"
             [iconSrc]="'assets/_default/svg/ic_eye.svg'"
             [iconTitle]="('eo.password.reset.toogle.icon' | translate)"
             [ngClass]="{'visible': showPassword}"></eo-icon>
  </a>
</section>
