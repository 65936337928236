<!-- global upload component -->
<eo-upload-overlay *ngIf="authenticated && capabilities?.intray"></eo-upload-overlay>

<!-- main application skeleton -->
<div class="application" [dir]="dir" [eoShortcuts]="navShortcuts">

  <div class="app" *ngIf="!initError" [eoShortcuts]="shortcuts">
    <eo-app-bar [transparent]="transparentBar" *ngIf="authenticated">
      <eo-sidebar-plugin class="navi" [type]="'sidebar-navigation'"></eo-sidebar-plugin>
      <eo-sidebar-plugin class="help" [type]="'sidebar-help'"></eo-sidebar-plugin>
      <eo-sidebar-plugin class="profile" [type]="'sidebar-profile'"></eo-sidebar-plugin>
    </eo-app-bar>
    <div class="eo-app-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- app init failed notification -->
  <div class="init-error" *ngIf="initError">
    <eo-icon [iconSrc]="'assets/_default/svg/ic_error.svg'"></eo-icon>
    <div class="message">
      <div translate>eo.app.init.fail.title</div>
      <div class="cause" translate>eo.app.init.fail.message</div>
      <div class="cause error">{{initError.cause}}</div>
    </div>
  </div>

  <div class="overlay">
    <!--<ng-progress [positionUsing]="'marginLeft'" [minimum]="0.3" [maximum]="1"-->
    <!--[speed]="300" [showSpinner]="false"-->
    <!--[direction]="dir === 'rtl' ? 'rightToLeftIncreased' : 'leftToRightIncreased'"-->
    <!--[color]="'#bb3f5a'" [trickleSpeed]="300" [thick]="true" [ease]="'linear'"-->
    <!--&gt;</ng-progress>-->
  </div>
  <router-outlet name="modal"></router-outlet>
  <eo-global-shortcuts *ngIf="authenticated && !isMobileEnv"></eo-global-shortcuts>
  <eo-action-menu></eo-action-menu>
  <section #externalDialog></section>
</div>
