import {Component, ElementRef, Input, Renderer2, ViewEncapsulation} from '@angular/core';
import {Logger, ObjectType, BackendService} from '@eo-sdk/core';

/**
 * Component to display an icon.
 *
 * Specify `iconSrc` attribute to set the src of the icon to be displayed:
 *   <eo-icon [iconSrc]="'assets/_default/svg/ic_favorite.svg'"></eo-icon>
 *
 * Adding the `badge` property will show a tiny badge on top of the icon:
 *   <eo-icon [iconSrc]="{{src}}" [badge]="15"></eo-icon>
 *
 * Adding a `button` class to the component, will style the svg icon more like a button:
 *   <eo-icon class="button" [iconSrc]="{{src}}"></eo-icon>
 *
 * By default all svg-icons will be rendered as kind of black on white. If you like them
 * to be placed on a dark background (and colored white) then add the class `white` to the
 * component:
 *   <eo-icon class="white" [iconSrc]="{{src}}"></eo-icon>
 *
 * You may also provide the component with an `objectType` attribute which expects an
 * `ObjectType` as input. This will then display the svg icon of this ObjectType.
 *
 */
@Component({
  selector: 'eo-icon',
  template: '',
  styleUrls: ['./eo-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EoIconComponent {

  // an ObjectType to display its icon for
  @Input('objectType')
  set objectType(objectType: ObjectType) {
    if (objectType) {
      this.backend
        .getViaCache(this.backend.getIconBase(objectType.iconId))
        .subscribe((svg) => this.render(svg));
    }
  }

  // id of the icon
  @Input('iconId')
  set iconId(iconId: string) {
    if (iconId) {
      this.backend
        .getViaCache(this.backend.getIconBase(iconId))
        .subscribe((svg) => this.render(svg));
    }
  }

  // path to the icon
  @Input('iconSrc')
  set iconSrc(iconSrc: string) {
    if (iconSrc) {
      const icon = iconSrc.includes('/') ? iconSrc : `assets/_default/svg/${iconSrc}`;

      this.backend
        .getViaCache(icon)
        .subscribe((svg) => this.render(svg));
    }
  }

  // badge content
  @Input('badge')
  set badge(badge: string) {
    if (badge) {
      this._renderer
        .setAttribute(
          this.elementRef.nativeElement,
          'data-badge',
          badge
        );
      this._renderer
        .addClass(
          this.elementRef.nativeElement,
          'badged'
        );
    } else {
      this._renderer
        .removeClass(
          this.elementRef.nativeElement,
          'badged'
        );
    }
  }

  @Input('iconTitle')
  set title(title: string) {
    if (title) {
      this._renderer
        .setAttribute(
          this.elementRef.nativeElement,
          'title',
          title
        );
    }
  }

  constructor(private backend: BackendService,
              private elementRef: ElementRef,
              private _renderer: Renderer2,
              private logger: Logger) {
  }

  // renders the actual svg string by adding it to the DOM
  private render(svg: string) {
    const svgElement: SVGElement = this.svgElementFromString(svg);
    if (svgElement && svgElement instanceof SVGElement) {
      this.elementRef.nativeElement.innerHTML = '';
      this.elementRef.nativeElement.appendChild(svgElement);
    } else {
      this.logger.error('Icon is not a valid SVGElement: ' + svg);
    }
  }

  /**
   * Transforms a string to an SVGElement.
   * @param str - the string to be converted
   * @return the SVGElement created from the input or NULL if conversion failed
   */
  private svgElementFromString(str: string): SVGElement {
    const div = document.createElement('DIV');
    div.innerHTML = str;
    return div.querySelector('svg') as SVGElement;
  }
}
