import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'eo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {

  value: boolean | null;

  @Input() required = false;
  @Input() tristate = false;
  @Input() readonly: boolean;

  reset(): void {
    this.value = null;
    this.propagateChange(this.value);
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: any): void {
    if (typeof value === 'undefined') {
      this.value = null;
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onChange(value) {
    if (this.tristate && this.required && value === null) {
      this.value = true;
    }
    this.propagateChange(this.value);
  }

}
