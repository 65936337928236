import {Component, Input} from '@angular/core';
import {EmptyState} from '../../../eo-framework-core/empty-state/empty-state.interface';

@Component({
  selector: 'eo-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {

  iconUri = 'assets/_default/svg/ic_error.svg';
  _emptyState: EmptyState;

  @Input()
  set emptyState(es: EmptyState) {
    this._emptyState = es;
    if (es.icon && es.icon.length) {
      if (es.icon.startsWith('assets/_default/svg/')) {
        this.iconUri = es.icon;
      } else {
        this.iconUri = `assets/_default/svg/${es.icon}`;
      }
    }
  }

  get emptyState() {
    return this._emptyState;
  }
}

