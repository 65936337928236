import {UtilitiesService} from './../../util/services/utilities.service';
import {Component, ChangeDetectionStrategy, Input, ViewChild, OnInit, ChangeDetectorRef} from '@angular/core';
import {BaseParams, DmsObject, Utils, UserService, DmsService} from '@eo-sdk/core';
import {TranslateService} from '@eo-sdk/core';
import {GridService} from '../../../eo-framework-core/api/grid.service';
import {catchError} from 'rxjs/operators';
import {SimpleAccordionComponent} from '../../accordion/simple-accordion/simple-accordion.component';
import {ISummaryEntryDiffValue} from './indexdata-summary-entry.interface';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eo-indexdata-summary',
  templateUrl: './indexdata-summary.component.html',
  styleUrls: ['./indexdata-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndexdataSummaryComponent implements OnInit {

  isUserAllowedToShowStorageSection: boolean;
  private _indexData: IndexData;

  coreValues: any[];
  dataValues: any[];
  storageInfos: any[];
  item: DmsObject;
  moreInfoAcc = false;
  dataInfoAcc = false;
  diffActive = false;
  emptyState = false;

  // @ViewChild('dataAccordion') dataAccordion: SimpleAccordionComponent;
  @ViewChild('storageAccordion') storageAccordion: SimpleAccordionComponent;

  @Input()
  set indexdata(id: IndexData) {
    this._indexData = id;
    this.coreValues = this.processIndexData(id, 0).filter(value => !value.name.startsWith('yuv'));
    this.dataValues = this.processIndexData(id, 1);
  }

  @Input()
  set dmsObject(item: DmsObject) {
    this.item = item;
    this.storageInfos = null;
    if(item)
    this.getStorageInfos();
    if (this.storageAccordion) {
      this.storageAccordion.selected = false;
    }
  }

  @Input() baseparams: BaseParams;
  @Input() enableVersions = true;

  @Input() set diff({indexdata, indexdata2, baseparams, baseparams2}) {
    this.diffActive = true;

    const diffValue = (val1, val2): ISummaryEntryDiffValue => !(UtilitiesService.isEmpty(val1) && UtilitiesService.isEmpty(val2)) && val1 !== val2 ? {val1, val2} : null;

    if (baseparams && baseparams2) {
      this.baseparams = {} as BaseParams;
      ['contentFileName', 'contentFileSize', 'mimeGroup']
        .forEach(k => diffValue(baseparams[k], baseparams2[k]) && (this.baseparams[k] = diffValue(baseparams[k], baseparams2[k])));
    }

    if (indexdata && indexdata2) {
      const coreValues2 = this.processIndexData(indexdata2, 0, true).reduce((acc, cur) => (acc[cur.qname] = cur, acc), {});
      const coreValues = this.processIndexData(indexdata, 0, true)
        .map(({label, _value, qname, reference}) => ({label, reference, _value: diffValue(_value, coreValues2[qname]._value)}))
        .filter(v => v._value);

      const dataValues2 = this.processIndexData(indexdata2, 1, true).reduce((acc, cur) => (acc[cur.qname] = cur, acc), {});
      const dataValues = this.processIndexData(indexdata, 1, true)
        .map(({label, _value, qname, reference}) => ({label, reference, _value: diffValue(_value, dataValues2[qname]._value)}))
        .filter(v => v._value);

      this.coreValues = coreValues;
      this.dataValues = dataValues;
      this.moreInfoAcc = !!dataValues.length;
    }

    this.emptyState = UtilitiesService.isEmpty(this.coreValues) && UtilitiesService.isEmpty(this.dataValues) && UtilitiesService.isEmpty(Object.keys(this.baseparams || {}));
  }

  static collect(data: {}, elements: any[] = [], collection: any[], force = false) {
    elements.forEach(el => {
      if (el.type !== 'TABLE') {
        if (el.elements) {
          collection.concat(IndexdataSummaryComponent.collect(data, el.elements, collection, force));
        } else {
          let key = el.name;
          if (force || (data.hasOwnProperty(key) && (Array.isArray(data[key]) ? data[key].length : true))) {
            // only add arrays when they contain any values
            collection.push(Object.assign({}, el, {
              value: data[key],
              meta: data[key + '_meta']
            }));
          }
        }
      }
    });
    return collection;
  }


  constructor(public translate: TranslateService,
    public gridApi: GridService,
    private dmsService: DmsService,
    private userService: UserService,
    private cd: ChangeDetectorRef) {
  }

  onValueClick(event: any, item: any = {}) {
    if (item.reference || item.version) {
      this.gridApi.openRouterLink(event, 'entry');
    }
  }

  getVersionLink(version: number) {
    const link = `versions/${this.item.id}?type=${this.item.typeName}&version=${version}`;
    return `<a class="link router-link" href="${link}" target="_blank" onclick="return false;">${version}</a>`;
  }

  getLink(label: string) {
    const link = `object/${this.item.id}?type=${this.item.typeName}`;
    return `<a class="link router-link" href="${link}" target="_blank" onclick="return false;">${label}</a>`;
  }

  processIndexData(id: IndexData, index = 0, force = false) {
    return id && id.form && id.data ? this.gridApi.getResolvedDefs(IndexdataSummaryComponent.collect(id.data, id.form.elements[index].elements, [], force)) : [];
  }

  onMoreInfoToggle(info: boolean) {
    this.moreInfoAcc = info;
  }

  onStorageInfoToggle(info: boolean) {
    this.dataInfoAcc = info;
  }

  trackByIndex(index, item) {
    return index;
  }

  getStorageInfos() {
    this.dmsService.getDmsObjectByParams({id: this.item.id, type: this.item.typeName, version: this.item.version, recyclebin: true, withStorageInfo: true})
      .pipe(
        untilDestroyed(this),
        catchError(Utils.catchSkip(err => err.status === 404))
      ).subscribe(res => {
      this.storageInfos = res.storageInfo;
      this.cd.markForCheck();
    });
  }

  ngOnInit() {
    this.isUserAllowedToShowStorageSection = this.userService.getCurrentUser().hasPrivilege('MANAGE_STORAGES');
  }
}

export interface IndexData {
  form: any;
  data: any;
}
