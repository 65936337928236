<div class="eo-about-state">

  <div class="about">
    <div class="header" [style.background-image]="backgroundImage">
      <img [src]="headerLogo"/>
    </div>

    <div class="body">
      <div class="claim" translate>eo.help.about.claim</div>

      <div class="wrap">
        <table>
          <tbody>
          <tr>
            <th translate>eo.help.about.component.label</th>
            <td>{{ctrl.componentName}}</td>
          </tr>
          <tr>
            <th translate>eo.help.about.component.version.label</th>
            <td>{{ctrl.componentVersion}}</td>
          </tr>
          <tr>
            <th translate>eo.help.about.product.label</th>
            <td>{{ ctrl.productName }}</td>
          </tr>
          <tr>
            <th translate>eo.help.about.product.version.label</th>
            <td>{{ ctrl.productVersion }}</td>
          </tr>
          <tr>
            <th translate>eo.help.about.producer.label</th>
            <td translate>eo.help.about.producer.text</td>
          </tr>
          </tbody>
        </table>
      </div>


      <h2 translate>eo.help.license.title</h2>
      <p translate>eo.help.license.intro</p>
      <div class="showLic" (click)="licenseShow = !licenseShow">
        <span class="toggle"><span translate>eo.help.license.title</span><span class="toggle-indicator">{{licenseShow ? '-' : '+'}}</span></span></div>
      <div class="licenses" *ngIf="licenseShow">
        <div class="lib" *ngFor="let lib of __libraries__; trackBy: trackByFn">
          <div class="name">{{lib.name}}</div>
          <div class="lic" *ngIf="licenses[lib.license]">
            <a href="{{licenses[lib.license].url}}" target="_blank" *ngIf="licenses[lib.license].url">{{licenses[lib.license].label}}</a>
            <span *ngIf="!licenses[lib.license].url">{{licenses[lib.license].label}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
