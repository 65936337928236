import {Component, Input, AfterViewInit, QueryList, ContentChildren, Output, EventEmitter} from '@angular/core';
import {GridComponent} from '../../../grid.component';
import {OverlayComponent} from '../../../../ui/overlay/overlay.component';
import {AbstractFilterComponent} from '../abstract-filter.component';
import {TextFilterComponent} from '../text/text-filter.component';
import {SetFilterComponent} from '../setFilters/set-filter.component';
import {IFilterParams, RowNode} from '@ag-grid-community/core';
import {AsyncSubject} from 'rxjs';

@Component({
  selector: 'eo-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements AfterViewInit {

  @Input() overlay: OverlayComponent;
  @Input() eoGrid: GridComponent;

  @Input() activeFilter: boolean;
  @Input() isFilterActive: boolean;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() active: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResetFilters: EventEmitter<any> = new EventEmitter<any>();

  allFilters: AbstractFilterComponent[] = [];

  @ContentChildren(TextFilterComponent) textFilters: QueryList<TextFilterComponent>;
  @ContentChildren(SetFilterComponent) setFilters: QueryList<SetFilterComponent>;


  doesExternalFilterPass(node: RowNode) {
    return this.allFilters
      .filter(f => f.isFilterActive())
      .every(f => f.doesExternalFilterPass(node));
  }

  onChange() {
    this.isFilterActive = !!this.allFilters.filter(f => f.isFilterActive()).length;
    this.active.emit(this.isFilterActive);
    let evt = new CustomEvent('change', {detail: this.doesExternalFilterPass.bind(this)});
    this.change.emit(evt);
    this.updateGrid(this.eoGrid, evt);
    if (this.overlay) {
      this.overlay.active = this.isFilterActive;
    }
  }

  updateGrid(grid: GridComponent | AsyncSubject<GridComponent>, evt: CustomEvent) {
    if (grid instanceof AsyncSubject) {
      grid.subscribe(g => this.updateGrid(g, evt));
    } else if (grid && grid.isReady && evt.detail) {
      grid.gridOptions.isExternalFilterPresent = () => true;
      grid.gridOptions.doesExternalFilterPass = (node) => evt.detail(node);
      grid.api.onFilterChanged();
    }
  }

  setupFilter(filter: AbstractFilterComponent) {
    filter.agInit(<IFilterParams>{
      filterChangedCallback: () => this.onChange(),
      valueGetter: (node) => ''
    });
    this.allFilters.push(filter);
  }

  resetFilter() {
    this.allFilters.forEach(f => f.reset());
    this.onChange();
  }

  ngAfterViewInit() {
    this.textFilters.forEach(f => this.setupFilter(f));
    this.setFilters.forEach(f => this.setupFilter(f));
  }
}
