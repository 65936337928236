import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MediaComponent} from './media.component';
import {UiModule} from '../ui/ui.module';
import {FormsModule} from '@angular/forms';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {YvcPopoutModule} from '@yuuvis/components/popout';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    FormElementsModule,
    YvcPopoutModule
  ],
  declarations: [MediaComponent],
  exports: [MediaComponent]
})
export class MediaModule {
}
