<div class="eo-delete" eoTrapFocus>

  <h2 *ngIf="!folder && !retentionTime" translate [translateParams]="{item: selection[0].title}">eo.action.delete.dms.object.confirm.message</h2>
  <h2 *ngIf="folder && !retentionTime" translate [translateParams]="{folder: folder, count: count}">eo.action.delete.dms.object.confirm.context.folder.title</h2>
  <h2 *ngIf="retentionTime" translate [translateParams]="{retentionTime: retentionTime}">eo.action.delete.dms.object.error.retention.title</h2>

  <div *ngIf="!deleting">
    <div class="form-buttons">
      <button (click)="cancel()" translate>eo.checkout.cancel</button>
      <button *ngIf="!retentionTime" class="primary" (click)="run()">OK</button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="deleting">
    <eo-loading-spinner></eo-loading-spinner>
  </div>
</div>

<eo-dialog [title]="'eo.action.delete.dms.dialog.title' | translate"
           [(visible)]="showForceDeleteDialog" [focusOnShow]="false" [minWidth]="400" [styleClass]="'force-delete__dialog'">

  <div>{{'eo.action.delete.dms.dialog.message' | translate}}</div>

  <div class="action-buttons flex-row">
    <button type="button" class="button cancel" (click)="showForceDeleteDialog = false; cancel()"
            translate>eo.action.delete.dms.dialog.cancel</button>
    <button type="button" (click)="deleteDmsObject(selection[0], true)" class="button primary"
            translate>eo.action.delete.dms.dialog.delete</button>
  </div>
</eo-dialog>


