<div *ngIf="group" [ngSwitch]="groupType"
  class="form-element {{group._eoFormGroup ? group._eoFormGroup.type : 'o2mControlWrap'}} align-{{(group._eoFormGroup && group._eoFormGroup.layout) ? group._eoFormGroup.layout.align : ''}}"
  [formGroup]="group">

  <!-- group stacks (tabs) -->
  <yvc-tabs *ngSwitchCase="'stack'" [layoutSettingsID]="layoutSettingsID">
    <ng-template *ngFor="let key of group.controls | keys; index as i;" [yvcTab]="{id: 't' + i, 
    headerStyleClass: 'dirty-' + group.controls[key].dirty + ' invalid-' + group.controls[key].invalid,
    label: getObjectFormGroup(group.controls[key]).label}">
      <div class="stack" [ngClass]="{stackBase: group._eoFormGroup.label === 'data'}">

        <eo-form-element [element]="group.controls[key]"
          *ngIf="getObjectFormControlWrapper(group.controls[key]); else stkgroup" [situation]="situation"
          [skipToggle]="skipToggle">
          <ng-template eoFormElementTemplate propertyType="TABLE" let-element="element"
            let-formElementRef="formElementRef">
            <ng-container *ngTemplateOutlet="tplFormElementTable; context: {element, formElementRef}"></ng-container>
          </ng-template>
        </eo-form-element>
        <ng-template #stkgroup>
          <eo-form-group [group]="group.controls[key]" [noGroupLabels]="true" [situation]="situation"
            [skipToggle]="skipToggle"></eo-form-group>
        </ng-template>
      </div>
    </ng-template>
  </yvc-tabs>

  <!-- group with legend -->
  <div *ngSwitchCase="'fieldset'" class="om-group fieldset">
    <div class="legend"><span>{{group._eoFormGroup.label}}</span></div>

    <div>
      <ng-container *ngFor="let key of group.controls | keys">
        <eo-form-element [element]="group.controls[key]"
          *ngIf="getObjectFormControlWrapper(group.controls[key]); else fsgroup" [situation]="situation"
          [skipToggle]="skipToggle">
          <ng-template eoFormElementTemplate propertyType="TABLE" let-element="element"
            let-formElementRef="formElementRef">
            <ng-container *ngTemplateOutlet="tplFormElementTable; context: {element, formElementRef}"></ng-container>
          </ng-template>
        </eo-form-element>
        <ng-template #fsgroup>
          <eo-form-group [group]="group.controls[key]" [situation]="situation"
            [skipToggle]="skipToggle"></eo-form-group>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <!-- group -->
  <ng-container class="om-group" *ngSwitchCase="'group'">
    <ng-container *ngFor="let key of group.controls | keys">

      <eo-form-element [element]="group.controls[key]"
        *ngIf="getObjectFormControlWrapper(group.controls[key]); else omgroup" [situation]="situation"
        [skipToggle]="skipToggle">

        <ng-template eoFormElementTemplate propertyType="TABLE" let-element="element"
          let-formElementRef="formElementRef">
          <ng-container *ngTemplateOutlet="tplFormElementTable; context: {element, formElementRef}"></ng-container>
        </ng-template>

      </eo-form-element>
      <ng-template #omgroup>
        <eo-form-group [group]="group.controls[key]" [situation]="situation" [skipToggle]="skipToggle"></eo-form-group>
      </ng-template>
    </ng-container>
  </ng-container>

</div>


<ng-template #tplFormElementTable let-element="element" let-formElementRef="formElementRef">
  <div class="table">
    <eo-table [params]="{
situation: element._eoFormControlWrapper.situation,
element: formElementRef._eoFormElement,
size: formElementRef._eoFormElement.size,
object: formElementRef._eoFormElement.object
}" [formControl]="element.get(element._eoFormControlWrapper.controlName)"></eo-table>
  </div>
</ng-template>