import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InboxStateRoutingModule} from './inbox-state-routing.module';
import {InboxStateComponent} from './inbox-state/inbox-state.component';
import {GridModule} from '../../eo-framework/grid/grid.module';
import {EoFrameworkModule} from '../../eo-framework/eo-framework.module';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';

@NgModule({
  imports: [
    CommonModule,
    InboxStateRoutingModule,
    GridModule,
    EoFrameworkModule,
    YvcSplitViewModule
  ],
  declarations: [InboxStateComponent]
})
export class InboxStateModule {
}
