import {Component, ViewChild, EventEmitter, OnDestroy} from '@angular/core';
import {
  Utils,
  BpmService,
  NotificationsService,
  TranslateService,
  SigningService,
  SigningRequest,
  EnaioEvent,
  EventService,
  DmsService,
  SystemService,
  LocalStorageService
} from '@eo-sdk/core';
import {EoDialogComponent} from '../../../../ui/eo-dialog/eo-dialog.component';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {PendingChangesService} from '../../../../../eo-framework-core/pending-changes/pending-changes.service';
import {ObjectFormOptions} from '../../../../object-form';
import {FormGroup, UntypedFormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ChangeMode} from '../signature.model';

@Component({
  selector: 'eo-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements ActionComponent, OnDestroy {
  selection: any[];
  finished: EventEmitter<any> = new EventEmitter();
  canceled: EventEmitter<any> = new EventEmitter();
  processingRequest: boolean;
  headerTitle: string;
  headerDescription: string;
  selected: any;
  formOptions: ObjectFormOptions;
  contents: {id: string, type: string}[];
  mode: ChangeMode;

  // ID set by pendingChanges service when editing indexdata
  // Used to finish the pending task when editing is done
  pendingTaskIds: string[] = [];

  @ViewChild('dialog') dialog: EoDialogComponent;
  signingForm: FormGroup;
  maxSigners: number;
  signingFormListener: Subscription;
  yuvsigtypeCodesystem = {
    id: '1291BAB91B0D49D1A9A4DFCCF49A1666',
    name: 'yuvsigtype',
    entries: []
  };
  signatureTypeStorageKey = 'eo.signature.type';
  signatureParallelStorageKey = 'eo.signature.parallel';

  constructor(private bpmService: BpmService,
    private pendingChanges: PendingChangesService,
    private translate: TranslateService,
    private toaster: NotificationsService,
    private signingService: SigningService,
    private eventService: EventService,
    private dmsService: DmsService,
    private systemService: SystemService,
    private storageService: LocalStorageService,
    private fb: UntypedFormBuilder) {
    const defaultSignatureType = this.storageService.getItem(this.signatureTypeStorageKey);
    const defaultSignatureParallel = this.storageService.getItem(this.signatureParallelStorageKey);

    this.signingForm = this.fb.group({
      yuvsigtype: [],
      yuvsigners: [],
      parallelSigning: [defaultSignatureParallel ? defaultSignatureParallel : false],
      yuvsigemailsubject: [''],
      yuvsigemailbody: ['']
    });
    this.headerTitle = this.translate.instant('eo.action.signature.label');

    this.signingFormListener = this.signingForm.valueChanges.subscribe(() => this.onIndexDataChanged());
    this.signingService.getInfo().subscribe((info) => {
      let platformName = info.platform === 'docusign' ? 'DocuSign' : 'Adobe Sign';
      this.headerDescription = this.translate.instant('eo.action.signature.description', {platform: platformName});
      if (info.signatureTypesEnabled) {
        this.yuvsigtypeCodesystem.entries = this.systemService.getCodesystem(this.yuvsigtypeCodesystem.id).entries;
        this.yuvsigtypeCodesystem.entries = this.yuvsigtypeCodesystem.entries.filter(entry => info.signatureTypesEnabled.includes(entry.data));
      }

      this.maxSigners = info.maxSigners || 6;

      this.signingForm.patchValue({
        yuvsigemailsubject: info.defaultEmailSubject,
        yuvsigemailbody: info.defaultEmailBody,
        yuvsigtype: defaultSignatureType ? defaultSignatureType : (info.signatureTypesEnabled ? info.signatureTypesEnabled[0] : '')
      });
    });
  }

  saveForm() {
    this.processingRequest = true;
    if (this.signingForm && !this.signingForm.invalid) {
      const signers = (this.signingForm.get('yuvsigners').value || []).map((value, index) => ({
            signerName: value.name,
            signerEmail: value.email,
            signerOrder: this.signingForm.get('parallelSigning').value ? 1 : index + 1,
            signerPhone: value.phone
          })
        );
      const signingData = {
        emailSubject: this.signingForm.get('yuvsigemailsubject').value,
        emailBody: this.signingForm.get('yuvsigemailbody').value,
        signatureType: this.signingForm.get('yuvsigtype').value ? this.signingForm.get('yuvsigtype').value : this.yuvsigtypeCodesystem.entries[0].data,
        signers
      } as SigningRequest;
      this.signingService
        .signDocument(this.selection[0].id, signingData)
        .pipe(
          switchMap(() => this.dmsService.getDmsObject(this.selection[0].id))
        )
        .subscribe((dmsObject) => {
          this.processingRequest = false;
          this.toaster.success(this.translate.instant('eo.action.signature.request.success'));
          this.storageService.setItem(this.signatureTypeStorageKey, signingData.signatureType);
          this.eventService.trigger(EnaioEvent.DMS_OBJECT_UPDATED, dmsObject);
          this.finish();
        }, Utils.throw(error => {
          if (error.status === 500) {
            this.toaster.error(this.translate.instant('eo.error.connection.interrupted.title'), this.translate.instant('eo.error.connection.interrupted.text'));
          } else {
            this.toaster.error(error.error?.message);
          }
          this.processingRequest = false;
        }));
    }
  }

  finish() {
    this.finishPending();
    this.dialog.visible = false;
    this.finished.emit();
  }

  cancel() {
    if (!this.pendingChanges.checkForPendingTasks(this.pendingTaskIds)) {
      this.finishPending();
      this.dialog.visible = false;
      this.canceled.emit();
    }
  }

  onIndexDataChanged() {
    if (this.signingForm.dirty) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskIds[0] || ' ')) {
      this.pendingTaskIds = [this.pendingChanges.startTask()];
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskIds[0]);
  }

  ngOnDestroy(): void {
    this.signingFormListener.unsubscribe();
  }
}
