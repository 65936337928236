
import {of as observableOf} from 'rxjs';
import {DmsObjectTarget} from '../../action-target';
import {ComponentAction} from '../../interfaces/action.interface';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {DmsObject} from '@eo-sdk/core';
import {EmailComponent} from './email/email.component';

@Component({
  selector: 'eo-email-action',
  template: ``
})
export class EmailActionComponent extends DmsObjectTarget implements ComponentAction {
  header: string;
  label: string;
  description: string;
  priority = 10;
  iconSrc = 'assets/_default/svg/ic_mail.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;
  component = EmailComponent;
  constructor(private translate: TranslateService) {
    super();
    this.label = this.translate.instant('eo.action.menu.email.label');
    this.description = this.translate.instant('eo.action.menu.email.description');
    this.header = this.translate.instant('eo.email.title');
}

  isExecutable(element: DmsObject) {
    return observableOf(true);
  };
}
