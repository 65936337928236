
import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {DmsObject, EnaioEvent, EventService, NotificationsService, UserService} from '@eo-sdk/core';
import {EnaioErrorKeys} from '../../../eo-framework-core/error-handler/error-keys';

import {TranslateService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'eo-favorite-icon',
  templateUrl: './favorite-icon.component.html',
  styleUrls: ['./favorite-icon.component.scss']
})
export class FavoriteIconComponent {
  display = false;
  value = '';
  tooltip: '';
  private _item: DmsObject;

  @Input()
  set item(item: DmsObject) {
    this._item = item;
    this.tooltip = this.translate.instant(
      'eo.favorite.icon.tooltip.' + (item.isFavorite ? 'remove' : 'add')
    );
  }

  get item() {
    return this._item;
  }

  @ViewChild('favName') favNameInputElement: ElementRef;

  constructor(private userService: UserService,
    private eventService: EventService,
    private toasty: NotificationsService,
    public translate: TranslateService) {

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_FAVORITE_ON).pipe(untilDestroyed(this))
      .subscribe((event: any) => event.data === this.item.id && this.onSave());

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_FAVORITE_OFF).pipe(untilDestroyed(this))
      .subscribe((event: any) => event.data === this.item.id && this.onRemove());
  }

  toggle(event, hide?: boolean) {
    this.display = hide || this.item.isFavorite ? false : !this.display;

    if (!hide && this.item.isFavorite) {
      this.remove();
    } else {
      this.value = this.generateSuggestedName(this.item);
    }

    if (hide && this.value.length > 0) {
      this.value = '';
    }

    if (this.display) {
      setTimeout(() => {
        this.favNameInputElement.nativeElement.select();
      })
    }
    event.preventDefault();
    event.stopPropagation();
  }

  generateSuggestedName(item) {
    const pre = (!item.isContextFolder && item.contextFolder) ? item.contextFolder.title + ' > ' : '';
    const title = pre + item.title;
    return title//.substring(0, 250);
  }


  onError(error) {
    let {key, callerinput} = error.error;
    callerinput = callerinput.match(/\d+/)[0];

    if (key === EnaioErrorKeys.FAVORITE_TITLE_TOO_LONG) {
      this.toasty
        .error(this.translate.instant('eo.favorite.dialog.input.error.title'),
          this.translate.instant('eo.favorite.dialog.input.error.message', {val1: callerinput}))
    }
  }

  save() {
    const title = this.value || this.generateSuggestedName(this.item.title);
    this.userService
      .createFavorite(this.item.id, this.item.typeName, title)
      .subscribe(() => this.onSave(), (error) => this.onError(error));
  }

  remove() {
    this.userService
      .removeFavoritesForTarget(this.item.id)
      .subscribe(() => this.onRemove());
  }

  onRemove() {
    this.item.isFavorite = false;
    this.tooltip = this.translate.instant('eo.favorite.icon.tooltip.add');
  }

  onSave() {
    this.item.isFavorite = true;
    this.display = false;
    this.tooltip = this.translate.instant('eo.favorite.icon.tooltip.remove');
  }
}
