<div class="entry">
  <eo-icon class="handle" cdkDragHandle [iconSrc]="'assets/_default/svg/ic_drag.svg'"></eo-icon>
  <div class="index">
    <span>{{ index + 1 }}</span><span>&darr;</span>
  </div>

  <div class="action">
    <button class="edit" type="button" (click)="changes(index, changeMode.EDIT)">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_edit.svg'"></eo-icon>
    </button>
    <button class="remove" type="button" (click)="changes(index, changeMode.DELETE)">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_trash.svg'"></eo-icon>
    </button>
  </div>

  <div class="content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <eo-icon class="innerAdd" [ngClass]="{ active: addTargetIndex === index, disabled: disableAdd }" [iconSrc]="'assets/_default/svg/ic_add.svg'" *ngIf="index !== 0" (click)="changes(index, changeMode.INSERT)"></eo-icon>
</div>
