<div class="eo-grid-empty" [hidden]="!(isEmpty || isEmptyRows)">
  <ng-content select=".empty"></ng-content>
</div>
<div class="eo-grid-header" *ngIf="showHeader" [hidden]="isEmpty">
  <ng-content select=".header"></ng-content>
</div>
<ag-grid-angular #agGrid class="ag-theme-balham" [modules]="modules"
                 [hidden]="isEmpty"
                 [ngClass]="{'hide-header': showHeader, 'enable-footer': showFooter, 'full-width': fullWidth}"
                 [gridOptions]="gridOptions"
                 [frameworkComponents]="frameworkComponents"
                 (modelUpdated)="onModelUpdated()"
                 (cellFocused)="onCellFocused($event)"
                 (cellContextMenu)="onContextMenuClicked($event)"
                 (contextmenu)="$event.stopPropagation();$event.preventDefault()"
                 (mousedown)="onMouseDown($event)"
                 (cellClicked)="onCellClicked($event)"
                 (cellDoubleClicked)="onCellDoubleClicked($event)"
                 (selectionChanged)="onSelectionChanged($event)"
                 (columnResized)="onColumnResized($event)"
                 (gridReady)="onReady()">
</ag-grid-angular>
<div class="eo-grid-footer" *ngIf="showFooter" [hidden]="isEmpty">
  <ng-content select=".footer"></ng-content>
</div>
