import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {StoredQueryComponent} from './stored-query/stored-query.component';
import {ObjectFormModule} from '../object-form/object-form.module';
import {UiModule} from '../ui/ui.module';
import {StoredQueryDetailsComponent} from './stored-query-details/stored-query-details.component';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {DynamicPropertySwitchComponent} from './stored-query-details/dynamic-property-switch/dynamic-property-switch.component';
import {GridModule} from '../grid/grid.module';

@NgModule({
  imports: [
    CommonModule,
    ObjectFormModule,
    UiModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    FormElementsModule
  ],
  declarations: [
    StoredQueryComponent,
    StoredQueryDetailsComponent,
    DynamicPropertySwitchComponent
  ],
  exports: [
    StoredQueryComponent,
    StoredQueryDetailsComponent
  ]
})
export class StoredQueryModule {
}
