import {filter} from 'rxjs/operators';
import {Component, ViewChild} from '@angular/core';
import {IDateAngularComp} from '@ag-grid-community/angular';
import {EoDialogComponent} from '../../ui/eo-dialog/eo-dialog.component';
import {EnaioEvent, EventService} from '@eo-sdk/core';
import {TranslateService} from '@eo-sdk/core';
import {DateFilter, IAfterGuiAttachedParams, IFilterParams, Utils} from '@ag-grid-community/core';
import {DateComponent} from '../../form-elements/date/date.component';

// custom condition for DateTime values
const createCondition = DateFilter.prototype['createCondition'];

DateFilter.prototype['createCondition'] = function(position) {
  return Object.assign(createCondition.call(this, position), this.dateCompFrom1.dateComp._frameworkComponentInstance.serialize());
};

@Component({
  selector: 'eo-datetime-custom',
  templateUrl: '../../form-elements/date/date.component.html',
  styleUrls: ['../../form-elements/date/date.component.scss']
})
export class DatetimeFilterComponent extends DateComponent implements IDateAngularComp {

  params: any = {};
  column: any;
  filterParams: IFilterParams;
  // filterInstance: DateFilter;
  filterInstance: any;

  @ViewChild(EoDialogComponent) eoDialog: EoDialogComponent;

  constructor(translate: TranslateService, private eventService: EventService) {
    super(translate);
  }

  serialize() {
    return this.filterInstance['_filterModel'] = {
      dateTo: super.formatDate(this.filterInstance['dateCompTo1'].getDate()),
      dateFrom: super.formatDate(this.filterInstance['dateCompFrom1'].getDate()),
      type: this.filterInstance.getCondition1Type(),
      withTime: this.withTime,
      filterType: 'date'
    };
  }

  onValueChange(e: any) {
    this.serialize();
    this.params.onDateChanged();
  }

  setDate(value) {
    this.writeValue(value);
  }

  getDate() {
    return this.value ? this.value : null;
  }

  agInit(params: any): void {
    this.params = params;
    this.filterParams = params.filterParams;
    this.withTime = this.filterParams['withTime'];
    // this.pickerTitle = this.filterParams.colDef.headerName;

    setTimeout(() => {
      this.filterInstance = this.filterParams.api.getFilterInstance(this.filterParams.colDef.field);
      this.filterInstance.serialize = this.serialize.bind(this);
    }, 0);

    this.eventService.on(EnaioEvent.EO_DIALOG_STACK_CHANGED).pipe(
      filter((event: any) => event && this.filterParams.column && this.filterParams.column.isMenuVisible())
    ).subscribe((event: any) => {
        let elements = this.filterParams.api['filterManager'].popupService.popupList;
        if (event.data.active) {
          elements.push({element: document.body});
        } else {
          setTimeout(() => {
            this.filterParams.api['filterManager'].popupService.popupList = elements.filter(el => el.element !== document.body);
          }, 0);
        }
      });
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    // this.inputMask.el.nativeElement.focus();
  }
}
