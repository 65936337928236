import {Component, ComponentFactoryResolver, ElementRef, Type, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, take, tap} from 'rxjs/operators';
import {ActionService, ActionShowCommand} from '../action-service/action.service';
import {ActionComponent} from '../interfaces/action-component.interface';
import {ActionListEntry} from '../interfaces/action-list-entry';
import {ComponentAction, ExternalComponentAction, ExternalProcessComponentAction, ListAction, SimpleAction} from '../interfaces/action.interface';
import {ActionComponentAnchorDirective} from './action-component-anchor/action-component-anchor.directive';

@UntilDestroy()
@Component({
  selector: 'eo-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionMenuComponent {

  @ViewChild(ActionComponentAnchorDirective) eoActionComponentAnchor: ActionComponentAnchorDirective;
  @ViewChild(ActionComponentAnchorDirective) externalDialog: ActionComponentAnchorDirective;
  actionLists: {
    common: ActionListEntry[],
    further: ActionListEntry[]
  } = {
    common: [], further: []
  };
  subActionsListHeader = '';
  subActionsList: ActionListEntry[];
  selection: any[];
  subSelection: any[];
  target: string;
  showComponent = false;
  actionDescription: string;
  showMenu = false;
  loading = false;
  activeActionIndex = 0;

  constructor(private actionService: ActionService,
              private router: Router,
              public viewContainerRef: ViewContainerRef,
              private elemRef: ElementRef,
              private componentFactoryResolver: ComponentFactoryResolver) {

    // subscribe for visibility observable indicating whether to show or hide the actions
    this.actionService
      .actionsShowing$.pipe(
      untilDestroyed(this))
      .subscribe((cmd: ActionShowCommand) => {
        if (!this.showMenu && cmd.show) {
          this.selection = cmd.selection;
          this.target = cmd.target;
          this.showActionMenu();
        } else if (this.showMenu && !cmd.show) {
          this.hideActionMenu();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(evt => evt instanceof NavigationStart),
      )
      .subscribe(() => this.hide());

  }

  private getActions() {
    this.actionService
      .getActionsList(this.selection, this.viewContainerRef)
      .subscribe(actionsList => {
        this.actionLists.common = actionsList.filter(actionListEntry => actionListEntry.action.group === 'common');
        this.actionLists.further = actionsList.filter(actionListEntry => actionListEntry.action.group === 'further');
      });

  }

  private getMoreActions() {
    this.loading = true;
    this.actionService
      .getMoreActions(this.selection, this.viewContainerRef)
      .subscribe(actionList => {
        this.loading = false;
        this.actionLists.further = this.actionLists.further.concat(actionList).sort(Utils.sortValues('action.label'));
      }, Utils.throw(() => this.loading = false));
  }

  hide() {
    this.actionService.hideActions();
  }

  showActionDescription(i, event) {
    event.stopPropagation();
    event.preventDefault();
    this.actionDescription = i === this.actionDescription ? null : i;
  }

  catchFocus() {    
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('[tabindex="0"]').focus();
    },);
  }

  isArrowUpOrDownPressed(event: KeyboardEvent) {
    const key = event.key;
    let length = this.actionLists.common.length + this.actionLists.further.length - 1;
    if (key === 'ArrowUp' || (event.shiftKey && key === 'Tab') ) {
      this.activeActionIndex = (this.activeActionIndex === 0) ? length : this.activeActionIndex - 1;
    } else if (key === 'ArrowDown' || key === 'Tab') {
      this.activeActionIndex = (this.activeActionIndex === length) ? 0 : this.activeActionIndex + 1;
    } else if (key === 'Enter') {
      let clickedListEntry;
      if (this.activeActionIndex < this.actionLists.common.length) {
        clickedListEntry = this.actionLists.common[this.activeActionIndex];
      } else {
        clickedListEntry = this.actionLists.further[this.activeActionIndex - this.actionLists.common.length];
      }
      this.onClick(clickedListEntry);
    }
  }

  private showActionMenu() {
    this.activeActionIndex = 0;
    this.getActions();
    if (this.target === 'DMS_OBJECT') {
      this.getMoreActions();
    }
    this.showMenu = true;
    this.catchFocus();
  }

  private hideActionMenu() {
    this.clear();
    this.showMenu = false;
    this.actionLists = {common: [], further: []};
  }

  onClick(actionListEntry: ActionListEntry) {
    this.activeActionIndex = 0;
    this.subSelection = actionListEntry.availableSelection;
    // It is possible that actions implement more than one action interface
    // so we should be aware of running an action and then open its sub actions

    const isSimpleAction = !!actionListEntry.action['run'];
    const isListAction = actionListEntry.action.hasOwnProperty('subActionComponents');
    const isComponentAction = actionListEntry.action.hasOwnProperty('component');
    const isExternalComponentAction = actionListEntry.action.hasOwnProperty('extComponents');
    const isWorkFlowAction = actionListEntry.action['executableProcess'] && isExternalComponentAction;
    const isSimpleActionOnly = isSimpleAction && !isListAction && !isComponentAction && !isExternalComponentAction;

    if (isSimpleAction) {
      const simpleAction = actionListEntry.action as SimpleAction;
      simpleAction.run(actionListEntry.availableSelection);

      // hide action menu if nothing else is to be shown/done
      if (isSimpleActionOnly) {
        this.hideActionMenu();
      }
    }

    if (isListAction) {
      const listAction = actionListEntry.action as ListAction;
      this.subActionsListHeader = listAction.header;
      this.actionService
        .getExecutableActionsListFromGivenActions(listAction.subActionComponents, this.selection, this.viewContainerRef)
        .subscribe((actionsList: ActionListEntry[]) => this.subActionsList = actionsList);
    } else if (isComponentAction) {
      const componentAction = actionListEntry.action as ComponentAction;
      this.showActionComponent(componentAction.component, this.eoActionComponentAnchor, this.componentFactoryResolver, true);
    } else if (isExternalComponentAction && !isWorkFlowAction) {
      const extComponentAction = actionListEntry.action as ExternalComponentAction;
      this.showActionComponent(extComponentAction.extComponents, this.externalDialog, this.componentFactoryResolver, false);
    } else if (isWorkFlowAction) {
      setTimeout(() => {
        const extComponentAction = actionListEntry.action as ExternalProcessComponentAction;
        this.showActionComponent(extComponentAction.extComponents, this.externalDialog, this.componentFactoryResolver,
          false, {executableProcess: extComponentAction.executableProcess});
      });
    }
  }

  private showActionComponent(component: Type<any>, viewContRef, factoryResolver, showComponent, inputs?: any) {
    this.showComponent = showComponent;
    let componentFactory = factoryResolver.resolveComponentFactory(component);
    let anchorViewContainerRef = viewContRef.viewContainerRef;
    anchorViewContainerRef.clear();
    let componentRef = anchorViewContainerRef.createComponent(componentFactory);
    (<ActionComponent>componentRef.instance).selection = this.subSelection || this.selection;
    (<ActionComponent>componentRef.instance).canceled.pipe(take(1), tap(() => (this.subSelection = null))).subscribe(() => this.onCancel());
    (<ActionComponent>componentRef.instance).finished.pipe(take(1), tap(() => (this.subSelection = null))).subscribe(() => this.onFinish());
    if (inputs) {
      Object.keys(inputs).forEach(function (key) {
        componentRef.instance[key] = inputs[key];
      });
    }
  }

  isLinkAction(action) {
    // used from within template
    return !!(action && action.getLink && action.getParams);
  }

  private clear() {
    this.showComponent = false;
    this.subActionsList = null;
    this.actionDescription = null;
    this.viewContainerRef.clear();
    if (this.eoActionComponentAnchor) {
      this.eoActionComponentAnchor.viewContainerRef.clear();
    }
  }

  onCancel() {
    this.clear();
    this.catchFocus();
  }

  onFinish() {
    this.hideActionMenu();
  }
}
