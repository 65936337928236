import {ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Capabilities, CapabilitiesService, Config, UserService} from '@eo-sdk/core';



@Component({
  selector: 'eo-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  capabilities: Capabilities;
  backgroundImage: SafeResourceUrl;

  userLang: string;
  docu: { link: string, label: string };

  @Output()
  public eoOutsideClick = new EventEmitter();

  constructor(private capabilityService: CapabilitiesService,
              private config: Config,
              private sanitizer: DomSanitizer,
              private elemRef: ElementRef) {

  }

  getCapabilities() {
    this.capabilities = this.capabilityService.getCapabilities();
  }

  setBackgroundImage() {
    this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(`url(${this.config.getSideBarHeaderImage()})`);
  }

  close() {
    this.eoOutsideClick.emit();
  }

  ngOnInit() {
    this.elemRef.nativeElement.querySelector('[tabindex="0"]').focus();
    this.getCapabilities();
    this.setBackgroundImage();
  }
}
