import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'eo-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {

  showPassword = false;
  @Input() pattern = '';
  @Input() parent: UntypedFormGroup;
  @Input() element: string;
  @Input() placeholder = '';
  @Input() canChangepasswordVisiblety: boolean;
  @Input() set passwordVisible(val: boolean) {
    this.showPassword = val;
  }
}

