<div class="eo-share-object" eoTrapFocus yvcAutofocusChild>
  <h2>{{header}}</h2>

  <form [formGroup]="shareForm" *ngIf="shareForm">
    <div class="fieldset">

      <div class="row">
        <eo-form-input [label]="orgaLabel">
          <eo-organization [removeCurrentUser]="true" [multiselect]="true" formControlName="orgaFieldFCN"></eo-organization>
        </eo-form-input>
        <div class="desc" *ngIf="selection[0].isFolder" translate>eo.manage.rights.folder.label</div>
      </div>

      <div class="row" *ngIf="selection[0].isFolder">
        <eo-form-input [label]="orgaLabel">
          <eo-organization [removeCurrentUser]="true" [multiselect]="true" formControlName="includeChildren"></eo-organization>
        </eo-form-input>
        <div class="desc" translate>eo.manage.rights.folder.include.children.label</div>
      </div>
    </div>
  </form>
  <div class="form-buttons">
    <button class="secondary" (click)="cancel()" translate>eo.manage.rights.cancel</button>
    <button class="danger" (click)="unshare()" *ngIf="selection[0].additionalvisibility.length"
      translate>eo.manage.rights.stop</button>
    <button class="primary" (click)="share()" [disabled]="shareForm.errors || !shareForm.dirty"
      translate>eo.manage.rights.apply</button>
  </div>
</div>