<div class="toolbar" *ngIf="!entryForm; else tplEntryForm">
  <h4>{{ 'eo.action.signature.signers' | translate }}</h4>
  <button id="add-item" class="add primary" (click)="showEntryForm()" [disabled]="maxSigners">
    <eo-icon [iconTitle]="('eo.action.signature.add.button.tooltip' | translate)"
             [iconSrc]="'assets/_default/svg/ic_add.svg'">
    </eo-icon>
  </button>
</div>

<div class="entries" cdkDropList (cdkDropListDropped)="dragDrop($event)" [cdkDropListData]="entries">
  <div [ngClass]="{ disabled: !!entryForm, single: entries.length === 1 }" cdkDrag *ngFor="let entry of entries; let i = index">
    <eo-sequence-list-item [index]="i" (changeEntry)="changeEntry($event)" [disableAdd]="maxSigners">
      <ng-template #content>
        <div class="signer-list-entry">
          <div class="signer-name" title="{{entry.name}}">{{ entry.name }}</div>
          <div class="signer-email" title="{{entry.email}}">{{ entry.email }}</div>
          <div class="signer-phone" *ngIf="entry.phone" title="{{entry.phone}}">{{ entry.phone }}</div>
        </div>
      </ng-template>
    </eo-sequence-list-item>
  </div>
</div>

<ng-template #tplEntryForm>
  <form class="entryForm" [formGroup]="entryForm" (ngSubmit)="addSigner()">
    <div class="form-body">
      <eo-form-input
        class="name"
        [invalid]="entryForm.get('name').dirty && entryForm.get('name').invalid"
        [skipToggle]="true"
        [label]="'eo.action.signature.yuvsigners.yuvsignername' | translate"
        [required]="true"
      >
        <eo-string maxLength="100" [qname]="'yuvsigning.yuvsigners.yuvsignername'" [autocomplete]="true" [autofocus]="true" formControlName="name"></eo-string>
      </eo-form-input>

      <eo-form-input
        class="task"
        [invalid]="entryForm.get('email').dirty && entryForm.get('email').invalid"
        [skipToggle]="true"
        [label]="'eo.action.signature.yuvsigners.yuvsigneremail' | translate"
        [required]="true"
      >
        <eo-string maxLength="100" [qname]="'yuvsigning.yuvsigners.yuvsigneremail'" [autocomplete]="true" formControlName="email"></eo-string>
      </eo-form-input>
      <small class="errors" *ngIf="entryForm.get('email').hasError('email')" translate>eo.form.property.string.error.classification.email</small>
      <eo-form-input
        class="task"
        *ngIf="phoneRequired"
        [invalid]="entryForm.get('phone').dirty && entryForm.get('phone').invalid"
        [skipToggle]="true"
        [label]="'eo.action.signature.yuvsigners.yuvsignerphone' | translate"
        [required]="true"
      >
        <eo-string maxLength="100" [qname]="'yuvsigning.yuvsigners.yuvsignerphone'" formControlName="phone"> </eo-string>
      </eo-form-input>
    </div>
    <footer class="footer-action">
      <button class="secondary" type="button" (click)="cancel()" translate>eo.action.signature.cancel</button>
      <ng-container [ngSwitch]="mode">
        <button *ngSwitchCase="'edit'" class="primary" [disabled]="entryForm.invalid || entryForm.pristine" type="submit" translate>eo.action.signature.editSigner</button>
        <button *ngSwitchDefault class="primary" [disabled]="entryForm.invalid" type="submit" translate>eo.action.signature.addSigner</button>
      </ng-container>
    </footer>
  </form>
</ng-template>
