<div class="eo-cancel-signing" eoTrapFocus yvcAutofocusChild>

  <h2 translate>eo.action.cancel.signing.label</h2>
  <form *ngIf="!cancelling" [formGroup]="cancelSigningForm">
    <eo-form-input [skipToggle]="true" [required]="true" [label]="commentLabel" [invalid]="commentValidity">
      <eo-string [multiline]="true" [minlength]="3" [maxlength]="100" formControlName="commentField"></eo-string>
    </eo-form-input>
  </form>
  <div *ngIf="!cancelling">
    <div class="form-buttons">
      <button (click)="cancel()" translate>eo.action.cancel.signing.cancel</button>
      <button class="primary" (click)="run()"
              [disabled]="cancelSigningForm.errors || cancelSigningForm.invalid || !cancelSigningForm.dirty"
              translate>eo.action.cancel.signing.cancelSigning</button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="cancelling">
    <eo-loading-spinner></eo-loading-spinner>
  </div>
</div>


