import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DmsParams, EnaioEvent, EventService, SystemService, UserService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../eo-framework-core/empty-state/empty-state.service';
import {PendingChangesComponent} from '../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {CustomSortComponent} from '../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {SetFilterComponent} from '../../eo-framework/grid/extensions/filter/setFilters/set-filter.component';
import {ISetFilter} from '../../eo-framework/grid/extensions/filter/setFilters/set-filters.interface';
import {GridComponent} from '../../eo-framework/grid/grid.component';
@UntilDestroy()
@Component({
  selector: 'eo-recyclebin-state',
  templateUrl: './recyclebin-state.component.html',
  styleUrls: ['./recyclebin-state.component.scss']
})
export class RecyclebinStateComponent implements OnInit, PendingChangesComponent, OnDestroy {

  gridOptions: GridOptions;
  sortFields: ColDef[];
  typeFilterFields: ISetFilter[];
  gridData;
  inboxDmsObjectSelectionId = 'inbox_dms';
  emptyState: EmptyState = EmptyStateService.defaultEmptyState(); showActionsMenu = true;
  textFilterParams: any = {};

  @ViewChild('eoGrid') eoGrid: GridComponent;
  constructor(public selection: SelectionService,
    public translate: TranslateService,
    private system: SystemService,
    private empty: EmptyStateService,
    private pendingChanges: PendingChangesService,
    private eventService: EventService,
    private titleService: PageTitleService,
    private userService: UserService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.recyclebin.list.title'));
    this.selection.createNew(this.inboxDmsObjectSelectionId);
    this.eventService
      .on(EnaioEvent.DMS_OBJECT_RESTORED_OR_DELETED).pipe(untilDestroyed(this))
      .subscribe(() => this.refreshGrid());

  }

  set hasContent(hasContent: boolean) {
    this.showActionsMenu = hasContent;
  }

  get hasContent(): boolean {
    return this.showActionsMenu;
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  refreshGrid() {
    this.userService.getRecycleBin().subscribe();
  }

  parseDmsParams(data): DmsParams {
    return data ? {id: data.id, type: data.type, recyclebin: true} : null;
  }

  typeFilters() {
    const data = this.gridData;
    let uniqueByType = SetFilterComponent.uniqBy(data, 'type', this.system.getObjectTypes());
    this.typeFilterFields = uniqueByType.map(t => Object.assign({
      label: t.label,
      value: true,
      filter: (node) => node.data === t.qname,
      id: t.qname
    })).sort(Utils.sortValues('label'));
  }

  resetFilters() {
    this.typeFilters();
  }

  updateGrid(data): void {
    this.emptyState = this.empty.getEmptyState(data ? data.length : -1, undefined, 'ic_no-file.svg');
    if (this.eoGrid && this.eoGrid.isReady) {
      this.eoGrid.updateRowData(data);
      return this.resetFilters();
    }

    this.resetFilters();

    let colDefs: ColDef[] = [{
      headerName: '',
      field: '__custom',
      cellRenderer: this.cellRenderer,
      filter: false
    }];

    this.textFilterParams = {context: {defaultValue: ''}};
    this.sortFields = [
      {field: 'recycled', headerName: this.translate.instant('eo.inbox.sort.date'), sort: ''},
      {field: 'title', headerName: this.translate.instant('eo.inbox.sort.title'), sort: ''},
      {field: 'description', headerName: this.translate.instant('eo.inbox.sort.description'), sort: ''}
    ];

    const sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);

    this.gridOptions = {
      context: {count: data.length},
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80,
    };
  }

  cellRenderer(params) {
    let icon = params.context.cr.render('type', params, {
      value: params.data.type
    });
    let recycledTime = params.context.cr.render('dateTime', params, {value: params.data.recycled});

    return `<div class="list-item ${params.data.folder ? 'folder' : ''}" unselectable>
              ${icon}
              <div class="content">
                <div class="title">${params.data.title || ''}</div>
                <div class="meta">
                  <div class="description">
                    ${params.data.description}
                  </div>
                  <div class='description date'>
                    <span class="period">${recycledTime}</span>
                  </div>
                </div>
              </div>
            </div>`;
  }

  ngOnInit(): void {
    this.refreshGrid();
    this.userService.recyclesGridData$.pipe(
      untilDestroyed(this)
    )
      .subscribe(data => {
        this.gridData = data;
        this.updateGrid(this.gridData);
      });
  }

  ngOnDestroy() {
    this.selection.clear();
  }
}
