<div class="eo-subscription" eoTrapFocus>
  <h2>{{header}}</h2>
  <form #titleField [formGroup]="addSubscriptionForm">
    <eo-form-input [skipToggle]="true" [label]="titleFieldLabel" [required]="true" [invalid]="titleValidity" *ngIf="selection.length === 1">
      <eo-string formControlName="titleFieldFCN"></eo-string>
    </eo-form-input>
    <div class="err-msg" *ngIf="titleValidity">
      <div translate>eo.form.property.required</div>
    </div>
    <eo-form-input [skipToggle]="true" [label]="inboxCbLabel">
      <eo-checkbox formControlName="inboxCbFCN"></eo-checkbox>
    </eo-form-input>
    <eo-form-input [skipToggle]="true" [label]="emailCbLabel">
      <eo-checkbox formControlName="emailCbFCN" [readonly]="!hasEmail"></eo-checkbox>
    </eo-form-input>
    <eo-form-input *ngIf="!selection[0].isFolder" [skipToggle]="true" [label]="'eo.subscription.field.mode.label' | translate">
      <eo-codesystem formControlName="modeFieldFCN" [codesystem]="subscriptionModeCodesystem" [pickerTitle]="'eo.subscription.field.mode.label' | translate"></eo-codesystem>
    </eo-form-input>
  </form>

  <!-- error/validation messages -->
  <div class="errors">
    <div class="err-msg" *ngIf="addSubscriptionForm.errors?.addSubscriptionForm" translate>eo.subscription.error.msg</div>
  </div>

  <div class="form-buttons" *ngIf="!processing; else indicator">
    <button class="secondary" (click)="cancel()"translate>eo.subscription.cancel</button>
    <button class="primary" [disabled]="addSubscriptionForm.errors || titleValidity" (click)="createSubscription()"translate>eo.subscription.add</button>
  </div>

  <ng-template #indicator>
    <div class="process-indicator"><eo-icon [iconSrc]="'assets/_default/svg/ic_kitt-loader.svg'"></eo-icon></div>
  </ng-template>
</div>

