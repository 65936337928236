import {UtilitiesService} from './../../../util/services/utilities.service';
import {Component, Input, EventEmitter, Output} from '@angular/core';
import {ISummaryEntryDiffValue} from '../indexdata-summary-entry.interface';

@Component({
  selector: 'eo-indexdata-summary-entry',
  templateUrl: './indexdata-summary-entry.component.html',
  styleUrls: ['./indexdata-summary-entry.component.scss']
})
export class IndexdataSummaryEntryComponent {

  value1: string;
  value2: string;
  showDiff = false;

  @Input() label: string;
  @Input() item: any;

  @Input() set value(v: any | ISummaryEntryDiffValue) {
    this.showDiff = v && typeof v === 'object' && v.hasOwnProperty('val1');
    this.value1 = this.showDiff ? v.val1 : v;
    this.value2 = this.showDiff && v.val2;
  }

  @Input() className: string;
  @Output() onValueClicked = new EventEmitter();

  isEmpty = v => UtilitiesService.isEmpty(v);

  classes = (v1, v2) => ({
    entry: true,
    diffActive: this.showDiff,
    new: this.showDiff && this.isEmpty(v1) && !this.isEmpty(v2),
    removed: this.showDiff && !this.isEmpty(v1) && this.isEmpty(v2),
    modified: this.showDiff && !this.isEmpty(v1) && !this.isEmpty(v2)
  })

}
