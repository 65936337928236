import { Component, Input } from '@angular/core';

@Component({
  selector: 'eo-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent {


  @Input() roles: Array<any> = [];
}
