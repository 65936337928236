<div class="query-scope">
    <button type="button" class="query-scope__button" (click)="selectQueryScope(QueryScope.ALL)" 
        [ngClass]="{'query-scope__button--active': !selectedQueryScope || (selectedQueryScope === QueryScope.ALL)}"
        translate>eo.search.scope.all.label</button>
    <button type="button" class="query-scope__button" (click)="selectQueryScope(QueryScope.CONTENT)" 
        [ngClass]="{'query-scope__button--active': selectedQueryScope === QueryScope.CONTENT}"
        translate>eo.search.scope.content.label</button>
    <button type="button" class="query-scope__button" (click)="selectQueryScope(QueryScope.INDEX_DATA)" 
        [ngClass]="{'query-scope__button--active': selectedQueryScope === QueryScope.INDEX_DATA}" 
        translate>eo.search.scope.indexdata.label</button>
</div>