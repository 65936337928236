<div class="eo-tree" eoRtlAware *ngIf="tree.length; else noTrees">
  <eo-tree-node *ngFor="let node of tree" [node]="node"
                (onNodeExpandedChanged)="toggleExpanded($event)"
                (onNodeSelected)="onNodeSelected($event)"></eo-tree-node>
</div>

<ng-template #noTrees>
  <div class="tree tree-panel-error eo-body error">
    <eo-icon class="error error__icon" [iconSrc]="'ic_error.svg'"></eo-icon>
    <p translate>{{emptyMessage}}</p>
  </div>
</ng-template>
