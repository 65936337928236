<section class="not-found">
  <header class="header">
    <h3 class="title">
      <span translate>eo.status.404.title</span>
      <span class="description" translate>eo.status.404.description</span>
    </h3>
    <eo-icon class="icon" [iconSrc]="'assets/_default/svg/ic_error.svg'"></eo-icon>
  </header>
</section>

