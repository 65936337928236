import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {
  BackendService, CapabilitiesService,
  NotificationsService,
  ObjectType,
  PrepareService,
  PreparedItem,
  PreparedItemTemplate,
  PreparedItemType,
  SystemService,
  TranslateService,
  Utils,
  DmsObject
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionConfig, SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PreviewFile} from '../media/media.component';
import {FormStatusChangedEvent} from '../object-form/form-status-changed-event.interface';
import {ObjectFormOptions} from '../object-form/index';
import {ObjectFormComponent} from '../object-form/object-form/object-form.component';

export interface PreparePhase {
  name: string;
  data: {
    previewIndex?: number,
    previewFile?: PreviewFile,
    file?: boolean,
    withoutFile?: boolean,
    templates?: any[]
  };
}
@UntilDestroy()
@Component({
  selector: 'eo-prepare-details',
  templateUrl: './prepare-details.component.html',
  styleUrls: ['./prepare-details.component.scss']
})
export class PrepareDetailsComponent implements OnInit {
  gridOptions: GridOptions;

  // ID set by pendingChanges service when editing indexdata
  // Used to finish the pending task when editing is done
  private pendingTaskId: string;
  private selectedTypeObject;

  // hidden file input to trigger file uploads
  @ViewChild('file') file: ElementRef;
  @ViewChild('form') objectForm: ObjectFormComponent;
  @ViewChild('confirmDelete') confirmDeleteButton: ElementRef;
  @ViewChild('confirmContentDelete') confirmContentDeleteButton: ElementRef;

  // preparation is divided into several phases
  // select an object type
  PHASE_TYPE = 'type';
  // add content
  PHASE_CONTENT = 'content';
  // set indexdata
  PHASE_INDEXDATA = 'indexdata';

  _preparedItem: PreparedItem;
  pseudoDmsObject: DmsObject;

  @Input('preparedItem')
  set preparedItem(item: PreparedItem) {
    const objectToStringifiedValueArray = (obj) => JSON.stringify(Object.keys(obj || {}).sort().map(key => obj[key]));
    const isProcessingAllowed = objectToStringifiedValueArray(this._preparedItem) !== objectToStringifiedValueArray(item);
    if (item && item.types) {
      item.types = item.types.sort(Utils.sortValues('label'));
    }
    this._preparedItem = item;
    if (this.preparedItem && this.preparedItem.id && isProcessingAllowed) {
      this.process();
      // we need this for the O365 integration TUK-3576
      this.pseudoDmsObject = new DmsObject({id: this.preparedItem.id, version: 0});
    }
  }

  get preparedItem() {
    return this._preparedItem;
  }


  @Input() emptyState = {icon: 'assets/_default/svg/ic_error.svg', text: '', className: 'details-error'};

  preparePhase: PreparePhase;
  selectedTemplate: PreparedItemTemplate;
  selectedTemplateFile: PreviewFile;
  formOptions: ObjectFormOptions;
  formState: FormStatusChangedEvent;
  chooseTemplate: boolean;
  uploadInProgress: boolean;
  committing: boolean;
  loading = false;
  tagFilterOptions: any;
  _showDeleteDialog = false;
  _showContentDeleteDialog = false;
  deletedContentIndex: number;

  // title to be displayed in the details header
  header = {
    title: null,
    location: {
      label: null,
      link: null
    },
    subtitle: null
  };

  colDefs: ColDef[] = [{
    headerName: '',
    field: 'title',
    cellRenderer: this.cellRenderer,
    filter: false
  }];

  @Input() applySelection: SelectionConfig;

  @Output() onItemChanged = new EventEmitter<PreparedItem>();
  @Output() onCommit = new EventEmitter<string>();
  @Output() onItemRemove = new EventEmitter<PreparedItem>();

  @HostBinding('attr.data-type')
  get dataType() {
    return this.preparedItem && this.preparedItem.selectedtype ? this.preparedItem.selectedtype.name : 'none';
  }

  set showDeleteDialog(val: boolean) {
    this._showDeleteDialog = val;

    if (this._showDeleteDialog) {
      setTimeout(() => this.confirmDeleteButton.nativeElement.focus(), 0);
    }
  }

  get showDeleteDialog() {
    return this._showDeleteDialog;
  }

  set showContentDeleteDialog(val: boolean) {
    this._showContentDeleteDialog = val;

    if (this._showContentDeleteDialog) {
      setTimeout(() => this.confirmContentDeleteButton.nativeElement.focus(), 0);
    }
  }

  get showContentDeleteDialog() {
    return this._showContentDeleteDialog;
  }

  constructor(private prepareService: PrepareService,
    private router: Router,
    private pendingChanges: PendingChangesService,
    private translate: TranslateService,
    private notification: NotificationsService,
    private systemService: SystemService,
    private selection: SelectionService,
    private backend: BackendService,
    private capabilites: CapabilitiesService) {
  }

  cellRenderer(params) {
    return `<div class="template">
              <div class="title">${params.data.title || ''}${params.data.content ? '<span class="title">' + ' (' + params.data.content.mimegroup + ')' + '</span>' : ''}</div>
              <div class="description">${params.data.description || ''}</div>
              <div class="tags">
                ${params.data.tags.map(tag => `<span class="chip">${tag}</span>`).join('')}
              </div>
            </div>
          `;
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskId || ' ')) {
      this.pendingTaskId = this.pendingChanges.startTask();
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskId);
  }

  prepareFormContent(formData?) {
    const data = formData || this.selectedTypeObject.data;
    this.formOptions = {
      formModel: this.selectedTypeObject.form,
      data: data
    };
    if (this.preparedItem.parent) {
      this.formOptions.context = {
        id: this.preparedItem.parent.id,
        title: this.preparedItem.parent.title,
        typeName: this.preparedItem.parent.type
      };
    }
  }

  resetObjectType() {
    this.prepareService.updateChildType('dms:sysobject', this.preparedItem.id).subscribe(updatedPrepareItem => {
      this.preparedItem = updatedPrepareItem;
      this.onItemChanged.emit(updatedPrepareItem);
      this.initPreparePhase(this.PHASE_TYPE);
    });
  }

  /**
   * Commits a prepared item. This will cause the temporary prepared object to be converted to a real DMS-Object.
   * @param openLocationAfterCommit - when set to true, application will navigate to the objects location
   */
  commit(openLocationAfterCommit?: boolean) {
    setTimeout(() => {
      if (this.formState && !this.formState.invalid) {
        const formData = this.objectForm.getFormData();
        this.committing = true;
        this.prepareService
          .commitPreparedItem(this.preparedItem.id, this.preparedItem.getSelectedTypeObject().name, formData)
          .subscribe((res) => {
            this.onCommit.emit(res);
            this.finishPending();
            if (openLocationAfterCommit) {
              this.router.navigate(['/object', res]);
            }
          }, Utils.throw(error => {
            const {key, cause, uniquefields} = error.error;
            if (key && key === 'DMS_METADATA_UNIQUE_VIOLATION_ERROR') {
              this.notification.error(null, this.translate.instant('eo.unique.validation.error.hint', {field: uniquefields.toString()}));
            } else if (cause) {
              this.notification.error(null, cause.messages[0]);
            }
            this.committing = false;
            this.finishPending();
            this.prepareFormContent(formData);
          },
            this.translate.instant('eo.prepare.created.error.title'),
            this.translate.instant('eo.prepare.created.error.message')
          ));
      }
    }, 500);
  }

  removeItem(item: PreparedItem) {
    this.finishPending();
    this.onItemRemove.emit(item);
    this._showDeleteDialog = false;
  }

  removeItemContent(item: PreparedItem, deletedContentIndex: number) {
    this.prepareService.deletePreparedItemContent(item.id, deletedContentIndex)
      .subscribe((updatedItem: PreparedItem) => {
        this.preparedItem = updatedItem;
        this.showContentDeleteDialog = false;
      });
  }

  getFileNames(item: PreparedItem) {
    return (item.contents || []).map(c => c.path).join(', ');
  }

  /**
   * Select a target object type for the current prepared item.
   * @param type - the object types name to be set as target
   */
  selectObjectType(type: PreparedItemType) {
    this.preparedItem.types
      .forEach((t) => t.selected = (t.name === type.name));

    this.prepareService
      .updateChildType(type.name, this.preparedItem.id)
      .subscribe(updatedPreparedItem => {
        this.preparedItem = updatedPreparedItem;
        this.onItemChanged.emit(updatedPreparedItem);
      });
  }

  /**
   * Add a template to the current prepared item.
   * @param template - the template to be added
   */
  addTemplate(template: PreparedItemTemplate) {

    this.prepareService
      .addTemplate(this.preparedItem.id, template.id)
      .subscribe((updatedPreparedItem) => {
        this.preparedItem = updatedPreparedItem;
        this.onItemChanged.emit(updatedPreparedItem);
      }, Utils.throw(null,
        this.translate.instant('eo.prepare.template.error.title'),
        this.translate.instant('eo.prepare.template.error.message')));
  }

  /**
   * Select a template. Will show preview for the selected template.
   * @param template - the template to be selected
   */
  selectTemplate(template: PreparedItemTemplate) {
    this.selectedTemplate = template;
    this.selectedTemplateFile = !(template && template.content) ? null : {
      uri: this.prepareService.getTemplatePreviewUri(this.selectedTemplate.id),
      uriPdf: this.prepareService.getTemplatePreviewUri(this.selectedTemplate.id, 'PDF'),
      mimetype: template.content.mimetype,
      mimegroup: template.content.mimegroup,
      size: template.content.size
    };
  }

  setPreviewUri(index: number) {
    Object.assign(this.preparePhase.data, this.getPreviewData(index));
  }

  // skip content selection for the current prepared item
  // and continue setting indexdata
  noFile() {
    this.initPreparePhase(this.PHASE_INDEXDATA);
  }

  // trigger file upload
  upload() {
    // after the user selected a file fileChangeListener() will continue
    this.file.nativeElement.click();
  }

  // onChange listener on hidden file input
  fileChangeListener(event) {
    const file: File = event[0];
    this.uploadInProgress = true;
    this.prepareService
      .uploadContent(this.preparedItem.id, file)
      .subscribe(res => {
        // upload succeeded so we can go on to the next prepare phase
        this.preparedItem = new PreparedItem(res);
        this.onItemChanged.emit(this.preparedItem);
        this.initPreparePhase(this.PHASE_INDEXDATA);
        this.uploadInProgress = false;
      }, Utils.throw(error => {
        if (error.error && error.error.cause) {
          this.notification.error(null, error.error.cause.messages[0]);
        } else if (error.error) {
          this.notification.error(null, error.error.message);
        } else {
          this.notification.error(this.translate.instant('eo.prepare.created.error.title'));
        }
        this.file.nativeElement.value = null;
        this.committing = false;
        this.uploadInProgress = false;
      },
        this.translate.instant('eo.upload.global.error.title'),
        this.translate.instant('eo.upload.global.error.description')
      ));
  }

  onFormStatusChanged(evt) {
    this.formState = evt;

    if (this.formState.dirty) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }

  private process() {
    // todo: reset stuff
    this.formState = null;
    this.formOptions = null;
    this.getPreparePhase();
  }

  /**
   * Determine the prepare phase to be initialized based on the conditions
   * of the current prepared item.
   */
  private getPreparePhase() {
    if (!this.preparedItem.getSelectedTypeObject()) {
      // Step #1 select an object type if not yet set
      this.initPreparePhase(this.PHASE_TYPE);
    } else if (!this.preparedItem.hasContent()) {
      // Step #2 add content if not yet contained
      this.initPreparePhase(this.PHASE_CONTENT);
    } else {
      // Step #3 edit indexdata and save
      this.initPreparePhase(this.PHASE_INDEXDATA);
    }
  }

  private createTemplate(selectedTypeObject) {
    const type: ObjectType = this.systemService.getObjectType(selectedTypeObject.name);
    this.loading = true;
    this.prepareService
      .getTemplates(selectedTypeObject.name)
      .subscribe((templates: PreparedItemTemplate[]) => {
        const fileRequired = this.preparedItem.state.contentstate === 'REQUIRED';
        const data = {
          file: type.maxFiles > 0,
          withoutFile: !(type.minFiles > 0) && !fileRequired,
          templates: templates || []
        };

        // if the current object type does not support file upload or template contents
        // we skip content phase and move on to indexdata phase
        if (!data.file) {
          this.initPreparePhase(this.PHASE_INDEXDATA);
        } else {
          this.preparePhase = {
            name: this.PHASE_CONTENT,
            data: data
          };
          this.setupTitle();
        }
        this.tagFilterOptions = this.createTagFilterOptions(templates);
        this.gridOptions = {
          context: {count: this.preparePhase.data.templates.length},
          rowData: this.preparePhase.data.templates.sort(Utils.sortValues('title')),
          columnDefs: this.colDefs,
          rowHeight: 80
        };
        this.loading = false;
      });
  }

  createTagFilterOptions(templates: PreparedItemTemplate[]) {
    let tagFilterOptions = [];
    templates.forEach(template => {
      template.tags.forEach(tag => {
        tagFilterOptions.push({label: tag, filter: (node) => node.data.tags.includes(tag), value: true});
      });
    });
    return Utils.uniqBy(tagFilterOptions, 'label');
  }

  /**
   * Initialize a specific prepare phase.
   * @param phase - phase to init (PHASE_TYPE, PHASE_CONTENT, PHASE_INDEXDATA)
   */
  private initPreparePhase(phase: string) {
    this.preparePhase = null;
    this.selectedTypeObject = this.preparedItem.getSelectedTypeObject();

    switch (phase) {
      // init prepare phase for selecting the target object type
      case this.PHASE_TYPE: {

        this.preparePhase = {
          name: this.PHASE_TYPE,
          data: this.getPreviewData()
        };
        this.setupTitle();
        break;
      }

      // init prepare phase for setting up content
      case this.PHASE_CONTENT: {

        this.chooseTemplate = false;
        this.selectedTemplate = null;

        // check whether or not the preparedItem already contains a file or template
        if (this.preparedItem.hasContent()) {
          if (!this.preparedItem.state.typeselectedallowed) {
            // if the selected type is not allowed, we'll go back to the type select state ...
            this.initPreparePhase(this.PHASE_TYPE);
          } else {
            // ... otherwise we go on setting indexdata
            this.initPreparePhase(this.PHASE_INDEXDATA);
          }
        } else {

          if (this.preparedItem.state.contentstate === 'NOCONTENTALLOWED') {
            this.initPreparePhase(this.PHASE_INDEXDATA);
          } else {
            this.createTemplate(this.selectedTypeObject);
          }
        }
        break;
      }
      // init prepare phase for adding indexdata
      case this.PHASE_INDEXDATA: {

        this.chooseTemplate = false;

        if (!this.preparedItem.state.typeselectedallowed) {
          // if the selected type is not allowed, we'll go back to the type select state ...
          this.initPreparePhase(this.PHASE_TYPE);
        } else {

          this.prepareFormContent();

          this.preparePhase = {
            name: this.PHASE_INDEXDATA,
            data: this.getPreviewData()
          };
          this.setupTitle();
        }
        break;
      }
    }
  }

  private getPreviewData(index?: number): any {
    if (this.preparedItem && this.preparedItem.contents && this.preparedItem.contents.length) {
      index = index || 0;
      return {
        previewIndex: index,
        previewFile: {
          uri: this.prepareService.getPreviewUri(this.preparedItem.id, index),
          uriPdf: this.prepareService.getPreviewUri(this.preparedItem.id, index, 'PDF'),
          mimetype: this.preparedItem.contents[index].mimetype,
          mimegroup: this.preparedItem.contents[index].mimegroup,
          path: this.preparedItem.contents[index].path,
          size: this.preparedItem.contents[index].size
        }
      };
    }

    return {previewFile: null};
  }

  /**
   * Setup title for the current prepared item based on the
   * current prepare phase. Should be called after prepare phase is identified.
   */
  private setupTitle() {

    this.translate.get([
      'eo.prepare.location.title.sysroot',
      'eo.prepare.location.title.prefix',
      'eo.prepare.details.type.title',
      'eo.prepare.details.type.subtitle',
      'eo.prepare.details.content.subtitle',
      'eo.prepare.details.indexdata.subtitle'
    ]).subscribe(t => {

      let header = {
        title: null,
        location: {
          label: null,
          link: null
        },
        subtitle: null
      };
      switch (this.preparePhase.name) {
        case this.PHASE_TYPE: {
          header.title = t['eo.prepare.details.type.title'];
          header.subtitle = t['eo.prepare.details.type.subtitle'];
          break;
        }
        case this.PHASE_CONTENT: {
          const typeObject = this.preparedItem.getSelectedTypeObject();
          header.title = typeObject ? typeObject.label : '';
          header.subtitle = t['eo.prepare.details.content.subtitle'];
          break;
        }
        case this.PHASE_INDEXDATA: {
          const typeObject = this.preparedItem.getSelectedTypeObject();
          header.title = typeObject ? typeObject.label : '';
          header.subtitle = t['eo.prepare.details.indexdata.subtitle'];
          break;
        }
      }

      // set up location info
      if (this.preparedItem.parent.type === 'sysroot') {
        header.location = {
          label: t['eo.prepare.location.title.sysroot'],
          link: null
        };
      } else {
        const parentType = this.systemService.getObjectType(this.preparedItem.parent.type);
        header.location = {
          label: ` ${parentType.label}: ${this.preparedItem.parent.title}`,
          link: `/object/${this.preparedItem.parent.id}`
        };
      }
      this.header = header;
    });
  }

  showTemplateSelector(show: boolean) {
    this.chooseTemplate = show;
    this.selectTemplate(this.preparePhase.data.templates[0]);
  }

  download() {
    if (this.preparePhase) {
      const file = this.preparePhase.name === 'content' ? this.selectedTemplateFile : this.preparePhase.data.previewFile;
      if (file) {
        this.backend.download(file.uri);
      }
    }
  }

  hasTemplateCapability() {
    return this.capabilites.hasCapability('template');
  }

  public trackByIdFn(index, item) {
    return item.id;
  }

  public trackByIndexFn(index, item) {
    return index;
  }

  ngOnInit() {
    if (this.applySelection) {
      this.loading = true;
      this.selection
        .find(this.applySelection.in).focus$
        .pipe(
          untilDestroyed(this)
        )
        .subscribe((res: PreparedItem) => {
          this.loading = false;
          this.committing = false;
          this.preparedItem = res;
        });
    }
  }

}
