import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {QuickSearchComponent} from './quick-search/quick-search.component';
import {UiModule} from '../ui/ui.module';
import {PipesModule} from '../../eo-framework-core/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,    
    ReactiveFormsModule,
    UiModule,
    PipesModule
  ],
  exports: [QuickSearchComponent],
  declarations: [QuickSearchComponent]
})
export class QuickSearchModule {
}
