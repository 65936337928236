import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {Icharts} from '../icharts';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {StoredQueriesService, StoredQuery, TranslateService, UserService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EMPTY, skip, tap} from 'rxjs';
import {CodesystemComponent} from '../../../../../eo-framework';

@UntilDestroy()
@Component({
  selector: 'eo-charts-setup',
  templateUrl: './charts-setup.component.html',
  styleUrls: ['./charts-setup.component.scss']
})
export class ChartsSetupComponent implements IWidgetComponent, OnInit {
  private _widgetConfig: Icharts;
  @Output() widgetConfigChange = new EventEmitter<Icharts>();
  storedQueries: StoredQuery[];
  setupForm: UntypedFormGroup = this.fb.group({
    storedQuery: [null],
    chartType: [],
    size: ['', [Validators.max(100), Validators.min(3)]],
    aggregation: [],
    histogramType: [],
  });

  storedQueriesPicker = {
    title: this.translate.instant('eo.search.title.storedqueries'),
    codesystem: null,
  };

  aggregationPicker = {
    title: this.translate.instant('eo.workspace.widget.form.label.aggregationField'),
    codesystem: null,
  };

  chartTypePicker = {
    title: this.translate.instant('eo.workspace.widget.form.label.chartTypeField'),
    codesystem: null,
  };

  histogramTypePicker = {
    title: this.translate.instant('eo.workspace.widget.form.label.histogram'),
    codesystem: null,
  };

  isDateTimeType = false;
  selectedSavedSearchName = '';
  storedQuery: StoredQuery;

  @ViewChild('aggregationCodesystem') aggregationCodesystem: CodesystemComponent;
  @ViewChild('histogramCodesystem') histogramCodesystem: CodesystemComponent;

  @Input() set widgetConfig(wc: Icharts) {
    this._widgetConfig = wc;
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private storedQueriesService: StoredQueriesService,
    private translate: TranslateService,
    private userService: UserService) {
    this.setupForm.valueChanges.pipe(skip(1), untilDestroyed(this)).subscribe((_) => {
      this.setWidgetConfig();
    });
    this.getStoredQuerys();
    this.getChartFiled();
    this.getHistogramFiled();
  }

  private setWidgetConfig() {
    if (this.setupForm.valid && this.storedQueries) {
      this.storedQuery = this.storedQueries.find((sq) => sq.name === this.setupForm.get('storedQuery').value);
      this.widgetConfig = {
        storedQuery: this.storedQuery?.id,
        formValue: this.setupForm.value,
        aggregationType: ''
      };
      if (this.setupForm.get('storedQuery').value !== this.selectedSavedSearchName) {
        if (this.storedQuery) {
          setTimeout(() => {
            this.aggregationPicker.codesystem = null;
            if (this.selectedSavedSearchName !== '') {
              this.setupForm.patchValue({
                aggregation: 'sysobject.created',
              });
            }
            if (this.storedQuery.types.length !== 1) {
              this.getAggregationField('sysdocument');
            } else {
              this.getAggregationField(this.storedQuery.types[0].qname);
            }
            this.selectedSavedSearchName = this.setupForm.get('storedQuery').value;
            this.isDateTimeType = this.widgetConfig?.formValue.aggregation === 'sysobject.created' || this.widgetConfig?.aggregationType === 'DATETIME';
          }, 100);
        }
      } else {
        this.widgetConfig.aggregationType = this.aggregationCodesystem?.selectedNodes?.data?.type;
        this.isDateTimeType = this.widgetConfig?.aggregationType === 'DATETIME';
      }
      this.widgetConfigChange.emit(this._widgetConfig);
    }
  }

  getStoredQuerys() {
    this.storedQueriesService
      .fetchStoredQueries().pipe(
        untilDestroyed(this),
      ).subscribe(storedQueries => {
        this.storedQueries = storedQueries;
        this.storedQueriesPicker.codesystem = {
          entries: this.storedQueries.map(storedQuery => ({
            id: storedQuery.id,
            label: storedQuery.name,
            defaultrepresentation: storedQuery.name,
            data: storedQuery.name
          })).sort(Utils.sortValues('label')),
        };
      });
  }

  getStoredQuery(id: string) {
    return this.storedQueriesService.getStoredQuery(id).pipe(
      untilDestroyed(this),
      tap(storedQuery => {
        this.storedQuery = storedQuery;
      })
    );
  }

  getAggregationField(type) {
    return this.userService.getFieldDefinition(type).pipe(untilDestroyed(this)).subscribe(definition => {
      let exclude = new Set(['filename', 'mimetype', 'modifiertitle', 'version', 'creatortitle', 'title', 'description']);
      if (type !== 'sysdocument') {
        exclude.add('type');
      }
      this.aggregationPicker.codesystem = {
        entries: definition.elements.filter(field => !exclude.has(field.hitname)).map(field => ({
          id: field.label,
          defaultrepresentation: field.label,
          data: field.qname,
          type: field.type
        })).sort(Utils.sortValues('defaultrepresentation')),
      };
    });
  }

  getChartFiled() {
    return this.chartTypePicker.codesystem = {
      entries: [
        {
          id: 'bar',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.type.bar'),
          label: this.translate.instant(
            'eo.workspace.widget.charts.type.bar'
          ),
          value: 'bar',
        },
        {
          id: 'pie',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.type.pie'),
          label: this.translate.instant(
            'eo.workspace.widget.charts.type.pie'
          ),
          value: 'pie',
        },
        {
          id: 'donut',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.type.donut'
          ),
          label: this.translate.instant(
            'eo.workspace.widget.charts.type.donut'
          ),
          value: 'donut',
        },
      ].map((res) => ({
        id: res.id,
        label: res.label,
        defaultrepresentation: res.defaultrepresentation,
        data: res.value
      })).sort(Utils.sortValues('defaultrepresentation')),
    };
  }

  getHistogramFiled() {
    return this.histogramTypePicker.codesystem = {
      entries: [
        {
          id: 'standard',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.histogram.standard'),
          label: this.translate.instant(
            'eo.workspace.widget.charts.histogram.standard'
          ),
          value: 'standard',
        },
        {
          id: 'daily',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.histogram.daily'
          ),
          label: this.translate.instant(
            'eo.workspace.widget.charts.histogram.daily'
          ),
          value: 'daily'
        },
        {
          id: 'weekly',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.histogram.weekly'
          ),
          label: this.translate.instant(
            'eo.workspace.widget.charts.histogram.weekly'
          ),
          value: 'weekly'
        },
        {
          id: 'monthly',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.histogram.monthly'),
          label: this.translate.instant(
            'eo.workspace.widget.charts.histogram.monthly'
          ),
          value: 'monthly'
        },
        {
          id: 'yearly',
          defaultrepresentation: this.translate.instant(
            'eo.workspace.widget.charts.histogram.yearly'),
          label: this.translate.instant(
            'eo.workspace.widget.charts.histogram.yearly'
          ),
          value: 'yearly'
        }
      ].map((res) => ({
        id: res.id,
        label: res.label,
        defaultrepresentation: res.defaultrepresentation,
        data: res.value
      })),
    };
  }

  ngOnInit(): void {
    if (this._widgetConfig?.storedQuery) {
      this.getStoredQuery(this._widgetConfig?.storedQuery).subscribe(() => {
        this.setupForm.patchValue({
          storedQuery: this.storedQuery ? this.storedQuery.name : '',
          chartType: this._widgetConfig ? this._widgetConfig?.formValue?.chartType : 'pie',
          size: this._widgetConfig?.formValue?.size,
          aggregation: this._widgetConfig ? this._widgetConfig.formValue.aggregation : 'sysobject.created',
          histogramType: this._widgetConfig && this._widgetConfig?.formValue?.histogramType ? this._widgetConfig?.formValue?.histogramType : 'standard',
        });
        if (this.storedQuery) {
          this.selectedSavedSearchName = this.setupForm.get('storedQuery').value;
          if (this.storedQuery.types.length !== 1) {
            this.getAggregationField('sysdocument');
          } else {
            this.getAggregationField(this.storedQuery.types[0].qname);
          }
          setTimeout(() => {
            this.setupForm.patchValue({
              aggregation: this._widgetConfig ? this._widgetConfig.formValue.aggregation : 'sysobject.created',
            });
            this.isDateTimeType = this.widgetConfig?.formValue.aggregation === 'sysobject.created' || this.widgetConfig?.aggregationType === 'DATETIME';
          }, 100);
        }
      }, (error) => {
        this.isDateTimeType = false;
        this._widgetConfig.aggregationType = null;
        if (error.status === 404) {
          this.setupForm.patchValue({
            chartType: 'pie',
            aggregation: this._widgetConfig ? this._widgetConfig.formValue.aggregation : 'sysobject.created',
            histogramType: 'standard',
            size: '',
          });
          return EMPTY;
        }
      });
    } else {
      this.setupForm.patchValue({
        chartType: this._widgetConfig ? this._widgetConfig?.formValue?.chartType : 'pie',
        aggregation: this._widgetConfig ? this._widgetConfig.formValue.aggregation : 'sysobject.created',
        histogramType: this._widgetConfig && this._widgetConfig?.formValue?.histogramType ? this._widgetConfig?.formValue?.histogramType : 'standard',
        size: this._widgetConfig?.formValue?.size,
      });
    }
  }
}
