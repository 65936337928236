import {Directive, Renderer2, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[eoInputFocus]'
})
export class InputFocusDirective {
  formInput: ElementRef = this.element.nativeElement;
  @Input() disabled = false;

  constructor(private renderer: Renderer2, private element: ElementRef) {
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(evt) {
    if (!this.disabled) {
      this.renderer.addClass(this.formInput, 'focused');
    }
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(evt) {
    this.renderer.removeClass(this.formInput, 'focused');
  }
}
