import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {YvcTabsModule} from '@yuuvis/components/tabs';
import {PipesModule} from '../eo-framework-core/pipes/pipes.module';
import {AccordionModule} from './accordion/accordion.module';
import {ActionModule} from './actions/action.module';
import {EoAppShellModule} from './app-shell/app-shell.module';
import {CtaModule} from './cta/cta.module';
import {ErrorModule} from './error/error.module';
import {FormElementsModule} from './form-elements/form-elements.module';
import {FrameComponent} from './frame/frame.component';
import {GridModule} from './grid/grid.module';
import {DuetimeInfoComponent} from './inbox-details/duetimeInfo/duetimeInfo.component';
import {InboxDetailsComponent} from './inbox-details/inbox-details.component';
import {ListContainerModule} from './list-container/list-container.module';
import {MediaModule} from './media/media.module';
import {ObjectDetailsModule} from './object-details/object-details.module';
import {ObjectFormModule} from './object-form/object-form.module';
import {ObjectStateDetailsComponent} from './object-state-details/object-state-details.component';
import {PluginsModule} from './plugins/plugins.module';
import {PrepareContentExistsInfoComponent} from './prepare-details/prepare-content-exists-info/prepare-content-exists-info.component';
import {PrepareDetailsComponent} from './prepare-details/prepare-details.component';
import {ProcessDetailsComponent} from './process/details/process-details.component';
import {ProcessHistoryComponent} from './process/history/process-history.component';
import {ProcessFileComponent} from './process/process-file/process-file.component';
import {QuickSearchModule} from './quick-search/quick-search.module';
import {ResultListModule} from './result-list/result-list.module';
import {SettingsModule} from './settings/settings.module';
import {ShortcutsModule} from './shortcuts/shortcuts.module';
import {StoredQueryModule} from './stored-query/stored-query.module';
import {TreeModule} from './tree/tree.module';
import {UiModule} from './ui/ui.module';
import {UploadOverlayComponent} from './upload-overlay/upload-overlay.component';
import {UtilModule} from './util/util.module';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';

@NgModule({
  imports: [
    CommonModule,
    EoAppShellModule,
    ObjectFormModule,
    UiModule,
    ObjectDetailsModule,
    FormsModule,
    ReactiveFormsModule,
    MediaModule,
    TreeModule,
    ShortcutsModule,
    QuickSearchModule,
    UtilModule,
    StoredQueryModule,
    GridModule,
    PipesModule,
    ListContainerModule,
    CtaModule,
    ErrorModule,
    SettingsModule,
    AccordionModule,
    PluginsModule,
    RouterModule,
    ActionModule,
    YvcTabsModule,
    YvcSplitViewModule
  ],
  declarations: [
    UploadOverlayComponent,
    PrepareDetailsComponent,
    InboxDetailsComponent,
    ProcessHistoryComponent,
    ProcessDetailsComponent,
    ProcessFileComponent,
    FrameComponent,
    ObjectStateDetailsComponent,
    DuetimeInfoComponent,
    PrepareContentExistsInfoComponent
  ],
  exports: [
    MediaModule,
    FormsModule,
    ReactiveFormsModule,
    ObjectFormModule,
    EoAppShellModule,
    UiModule,
    PipesModule,
    ResultListModule,
    ObjectDetailsModule,
    TreeModule,
    ShortcutsModule,
    QuickSearchModule,
    UtilModule,
    StoredQueryModule,
    GridModule,
    UploadOverlayComponent,
    FormElementsModule,
    PrepareDetailsComponent,
    InboxDetailsComponent,
    ListContainerModule,
    ErrorModule,
    ProcessDetailsComponent,
    ProcessHistoryComponent,
    ProcessFileComponent,
    SettingsModule,
    AccordionModule,
    PluginsModule,
    FrameComponent,
    ObjectStateDetailsComponent
  ]
})
export class EoFrameworkModule {
}
