import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {UploadRegistryService} from '@eo-sdk/core';
import {Observable} from 'rxjs';

@Injectable()
export class UploadOverlayGuard implements CanActivate {

  constructor(private uploadRegistry: UploadRegistryService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return !this.uploadRegistry.isOverlayActive()
  }
}
