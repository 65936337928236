<eo-dialog [title]="headerTitle"
           [subtitle]="headerDescription"
           [visible]="true"
           [dirtyCheck]="pendingTaskIds"
           [minWidth]="1000"
           [styleClass]="'process-form-dialog'" #dialog>

  <section class="process-form form-container">
    <div class="form">
      <eo-object-form [formOptions]="formOptions" #bpmForm
                      (statusChanged)="onIndexDataChanged($event)">
      </eo-object-form>
    </div>

    <div class="actions">
      <div *ngIf="form?.invalid" class="invalid" translate>eo.object.indexdata.form.invalid</div>
      <eo-cta [active]="true" class="cta-wrapper">
        <button *ngIf="form?.dirty" (click)="bpmForm.resetForm()" class="button-reset" translate>eo.object.indexdata.reset</button>
        <button (click)="cancel()" class="button-cancel" translate>eo.resubmission.cancel</button>
        <button (click)="saveForm()" [disabled]="form?.invalid || processingRequest" class="primary" translate>eo.executable.processes.execute</button>
      </eo-cta>
    </div>

  </section>
</eo-dialog>
