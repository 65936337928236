<div class="setup">
  <form [formGroup]="setupForm">

    <eo-form-input *ngIf="storedQueriesPicker?.codesystem?.entries" [skipToggle]="true"
      [label]="'eo.search.title.storedqueries' | translate" [required]="true" [description]="'eo.workspace.widget.stored.queries.description'">
      <eo-codesystem formControlName="storedQuery" [pickerTitle]="storedQueriesPicker.title"
        [codesystem]="storedQueriesPicker.codesystem" [emptyMessage]="'eo.workspace.widget.stored.queries.list.notAvailable'"></eo-codesystem>
    </eo-form-input>


    <div class="checkboxCountTitle">
      <eo-form-input *ngIf="this.widgetConfig?.storedQuery" class="label checkbox"
        [label]="'eo.workspace.widget.form.label.countType' | translate" [skipToggle]="true">
        <eo-checkbox formControlName="countTile" [tristate]="false"></eo-checkbox>
      </eo-form-input>
    </div>

    <div class="listOptions" *ngIf="this.widgetConfig?.storedQuery && !setupForm.value.countTile">

      <eo-form-input [skipToggle]="true" [label]="'eo.workspace.widget.form.label.size' | translate">
        <eo-number [scale]="0" formControlName="size"></eo-number>
      </eo-form-input>

      <eo-form-input [skipToggle]="true" *ngIf="sortFiledPicker?.codesystem?.entries"
        [label]="'eo.workspace.widget.form.label.sortField'|translate">
        <eo-codesystem formControlName="sortField" [pickerTitle]="sortFiledPicker.title"
          [codesystem]="sortFiledPicker.codesystem"></eo-codesystem>
      </eo-form-input>

      <eo-form-input class="label checkbox" [label]="'eo.workspace.widget.form.label.sortRevert'|translate"
        [skipToggle]="true">
        <eo-checkbox formControlName="sortRevert" [tristate]="false"></eo-checkbox>
      </eo-form-input>
    </div>
  </form>

</div>

<div class="preview" *ngIf="this.widgetConfig?.storedQuery; else tplEmpty">
  <eo-hitlist-widget [widgetConfig]="widgetConfig" [editMode]="true"></eo-hitlist-widget>
</div>

<ng-template #tplEmpty>
  <div class="empty">
    <eo-error-message
      [emptyState]="{icon: 'ic_saved_search.svg', text: 'eo.workspace.widget.stored.queries.list.empty'}"></eo-error-message>
  </div>
</ng-template>
