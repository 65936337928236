import {Injectable} from '@angular/core';
import {DmsObject, Config} from '@eo-sdk/core';
import {Title} from '@angular/platform-browser';
import {SelectionService} from '../selection/selection.service';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  baseTitle: string;
  private DEFAULT_TITLE: string;

  constructor(private titleService: Title, private selection: SelectionService, private config: Config) {
    this.DEFAULT_TITLE = this.config.getRaw('dashboardTitle') || 'yuuvis® RAD client';
    this.selection.focus$
      .subscribe((item: DmsObject) => {
        if (this.baseTitle) {
          this.titleService.setTitle((item && item.title) ? `${this.baseTitle}: ${item.title}` : this.baseTitle);
        } else if (item && item.title) {
          this.titleService.setTitle(item.title);
        } else {
          this.setDefaultTitle();
        }
      });
  }

  setBaseTitle(base: string) {
    this.baseTitle = base;
    this.titleService.setTitle(this.baseTitle || this.DEFAULT_TITLE);
  }

  setDefaultTitle() {
    this.baseTitle = null;
    this.titleService.setTitle(this.DEFAULT_TITLE);
  }
}
