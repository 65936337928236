
import {of as observableOf, Observable} from 'rxjs';
import {PreparedItemTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {SimpleAction} from '../../interfaces/action.interface';
import {Router} from '@angular/router';
import {PrepareService, PreparedItem} from '@eo-sdk/core';

@Component({
  selector: 'eo-prepared-delete',
  template: ``
})
export class DeletePreparedActionComponent extends PreparedItemTarget implements SimpleAction {
  header: string;
  label: string;
  description: string;
  priority = 0;
  iconSrc = 'assets/_default/svg/ic_trash.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;

  constructor(private translate: TranslateService, private prepareService: PrepareService, private router: Router) {
    super();
    this.label = this.translate.instant('eo.action.delete.dms.object.label');
    this.description = this.translate.instant('eo.action.delete.dms.object.description');
  }

  isExecutable(element: PreparedItem) {
    return observableOf(this.isAllowedState());
  };

  run(selection: PreparedItem[]) {
    selection.forEach(item => {
      this.prepareService.deletePreparedItem(item.id).subscribe();
    });
  }

  isAllowedState() {
    const enabledStates = ['/prepare'];
    return enabledStates.some(s => this.router.url.startsWith(s));
  }

}
