import { Injectable } from '@angular/core';

@Injectable()
export class UtilitiesService {
  public static isEdge(): boolean {
    return !!navigator.userAgent && navigator.userAgent.indexOf('Edge') > -1;
  }

  public static isEmpty(obj) {
    if (obj == null || obj === '') {
      return true;
    }

    if (typeof obj === 'number') {
      return isNaN(obj);
    }

    return typeof obj === 'boolean' ? false : !Object.keys(obj).length;
  }

  public static isEmptyOrFalse(val) {
    return typeof val === 'boolean' ? !val : UtilitiesService.isEmpty(val);
  }

}
