
import {of as observableOf, Observable} from 'rxjs';
import {SimpleAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {UserService, DmsObject, DmsService} from '@eo-sdk/core';

@Component({
  selector: 'eo-definalize-action',
  template: ``
})
export class DefinalizeActionComponent extends DmsObjectTarget implements SimpleAction {
  label: string;
  description: string;
  priority = 13;
  iconSrc = 'assets/_default/svg/ic_finalized.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;

  constructor(private translate: TranslateService,
              private userService: UserService,
              private dmsService: DmsService) {
    super();
    this.label = this.translate.instant('eo.action.definalize.label');
    this.description = this.translate.instant('eo.action.definalize.description');
  }

  isExecutable(item: DmsObject) {
    const hasPrivilege = this.userService.getCurrentUser().hasPrivilege('DEFINALIZE_OBJECTS');
    return observableOf((item.rights && item.rights.finalize) && !item.lock && item.isFinalized && hasPrivilege && !window.location.href.includes('/versions'));
  };

  run(selection: DmsObject[]) {
    selection.forEach(item => {
      this.dmsService.definalize(item).subscribe();
    });
  }
}
