import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AppCacheService, BackendService, CoreConfig, CORE_CONFIG, DmsObject, Logger, NotificationsService, SystemService, TranslateService, UserService, Utils} from '@eo-sdk/core';
import {BehaviorSubject, forkJoin, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {TreeNode} from '../../eo-framework/tree/tree.component.interface';
import {LockSettings} from './../../eo-client/settings/agent-lock-settings.enum';
import {CustomEmail} from './agent.interface';

export enum agentConfigKeys {
  AUTOCONNECT = 'autoconnect',
  LOCKSETTINGS = 'locksettings'
}
export interface ExtendedConfig {
  agent: {
    [agentConfigKeys.AUTOCONNECT]?: boolean;
    [agentConfigKeys.LOCKSETTINGS]?: LockSettings
  }
}
export type AgentConnectionConfig = ExtendedConfig['agent']


@Injectable()
export class AgentService {

  private ENAIO_PROTOCOL = 'yuuvis://';

  agentAvailability = {
    CONNECTED: 'eo.agent.availability.connected',
    AVAILABLE: 'eo.agent.availability.available',
    UNAVAILABLE: 'eo.agent.availability.unavailable'
  };

  private _isConnected = false;
  private isConnectedSource = new ReplaySubject<boolean>(1);
  public isConnected$: Observable<boolean> = this.isConnectedSource.asObservable();
  private agentConfig = new BehaviorSubject<AgentConnectionConfig>(null);
  public agentConfig$: Observable<AgentConnectionConfig> = this.agentConfig.asObservable();

  constructor(
    @Inject(CORE_CONFIG) public coreConfig: CoreConfig,
    private logger: Logger,
    private http: HttpClient,
    private systemService: SystemService,
    private backendService: BackendService,
    private userService: UserService,
    private translate: TranslateService,
    private notifications: NotificationsService,
    private appCacheService: AppCacheService) {
    this.getAgentConnectionStatus();
  }

  private getAgentConnectionStatus() {
    forkJoin([this.appCacheService.getItem('eo.agent.connected'), this.getAgentConfig()])
      .pipe(
        tap(([storage, config]) => this.isConnected = (config && config.hasOwnProperty(agentConfigKeys.AUTOCONNECT) ? config.autoconnect : storage !== null ? storage : null))
      ).subscribe();
  }

  getAgentConfig(): Observable<AgentConnectionConfig> {
    return this.http.get<ExtendedConfig>('assets/_default/config/extend.json', {withCredentials: this.coreConfig.withCredentials}).pipe(
      catchError(() => of(null)),
      tap((config: ExtendedConfig) => this.agentConfig.next({...config?.agent})),
      map((config: ExtendedConfig) => config?.agent))
  }

  set isConnected(isConnected: boolean) {
    this._isConnected = isConnected;
    this.isConnectedSource.next(isConnected);
    this.appCacheService.setItem('eo.agent.connected', isConnected);
  }

  get isConnected(): boolean {
    return this._isConnected;
  }

  setAgentStatus(connectagent): Observable<boolean> {
    if (typeof connectagent !== 'undefined') {
      this.isConnected = connectagent;
      return of(true);
    } else {
      return of(false);
    }
  }

  /**
   * Adds a location entry to the agent services location history.
   * @param contextFolder The locations context folder
   * @param treeNode Node from the context folders structure tree
   */
  updateUserLocation(contextFolder: DmsObject, treeNode?: TreeNode) {

    if (treeNode && treeNode.data && treeNode.data.type) {

      // if a given treeNode contains a target type, we'll only add a user location
      // when the type is able to accept content (otherwise useless for office AddIns)
      const targetType = this.systemService.getObjectType(treeNode.data.type);
      if (targetType.maxFiles === 0) {
        return;
      }
    }

    let title = contextFolder.title;
    if (treeNode) {
      title += ' > ' + treeNode.path.join(' > ');
    }

    let id = contextFolder.id;
    if (treeNode) {
      id += '_' + treeNode.data.key;
    }

    this.backendService.post('/locations', {
      id: id,
      title: title,
      type: contextFolder.type.name,
      childType: (treeNode && treeNode.data) ? treeNode.data.type : undefined,
      data: (treeNode && treeNode.data) ? treeNode.data.data : undefined
    }, this.backendService.getAgentBase()).subscribe(r => {
      this.logger.debug(`added location '${title}' to agent service`);
    });
  }

  /**
   * Open a document via agent.
   *
   * @param document A document to open.
   * @param lock Boolean, if the dms item should be locked.
   */
  openDocument(document, lock) {
    const id = document.id;
    if (lock) {
      this.backendService.put('/dms/lock/' + id).subscribe();
      document.lock = {
        by: {
          me: true,
          name: this.userService.getCurrentUser().name,
          other: false
        },
        on: new Date().toISOString()
      };
    }
    this.sendMessage('openDocument', id);
  }

  /**
   * Download content via agent.
   * On completion, the agent will visualize the downloaded items.
   *
   * @param documents A document to open.
   */
  downloadContent(documents) {
    let docs = documents.map(doc => {
      return {id: doc.id, title: doc.title, type: doc.type.name || '', version: doc.version};
    });
    this.sendMessage('downloadContent', docs);
  }

  /**
   * Copy items to clipboard.
   *
   * @param documents List of documents with id, title, type and rendition.
   */
  copyToClipboard(documents) {
    if (!documents || documents.length < 1) {
      this.logger.error('Agent copyToClipboard error: documents does not contain elements');
      return Utils.throw(null, 'documents does not contain elements').call(this, {});
    } else {
      this.sendMessage('copyToClipboard', documents);
    }
  }

  /**
   * Send items as email.
   *
   * @param CustomEmail contain attachemnts and message. Message my contains details about the mail.
   */
  sendAsMail({attachments, message}: CustomEmail) {
    if (!attachments?.length) {
      this.logger.error('Agent sendAsMail error: documents array does not contain elements. Attachments could not be found.');
      this.notifications.error(this.translate.instant('eo.document.open.error.title'), this.translate.instant('eo.document.open.error.message'));
      return Utils.throw(null, 'documents array does not contain elements').call(this, {});
    } else {
      this.sendMessage('sendAsMail', {attachments, message});
    }
  }

  sendExternalAction(executable, args) {
    this.sendMessage('external', {executable, args});
  }

  private sendMessage(action, data) {
    const payload = {
      data: data,
      user: this.userService.getCurrentUser().name,
      host: this.getHost()
    };
    window.location.href = this.ENAIO_PROTOCOL + action + '/' + encodeURIComponent(JSON.stringify(payload));
  }

  private getHost() {
    return window.location.origin;
  }

}
