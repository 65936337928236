import { IFilterAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, IDoesFilterPassParams } from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';
import { EnaioEvent, EventService, TranslateService } from '@eo-sdk/core';
import { filter } from 'rxjs/operators';

import { DynamicListComponent } from '../../form-elements/dynamic-list/dynamic-list.component';
import { EoDialogComponent } from '../../ui/eo-dialog/eo-dialog.component';


@Component({
  selector: 'eo-dynamic-list-filter',
  templateUrl: '../../form-elements/dynamic-list/dynamic-list.component.html',
  styleUrls: ['../../form-elements/dynamic-list/dynamic-list.component.scss']
})
export class DynamicListFilterComponent extends DynamicListComponent implements IFilterAngularComp {
  params: any = {};
  column: any;
  multiselect = true;
  situation = 'SEARCH';

  @ViewChild(EoDialogComponent) eoDialog: EoDialogComponent;

  constructor(translate: TranslateService, private eventService: EventService) {
    super();
  }

  onValueChange() {
    if (!this.params.filterParams || !this.params.filterParams.applyButton) {
      this.params.api.onFilterChanged();
      // TODO: still necessary?
      // setTimeout(() => {
      //   this.autoCompleteButton.nativeElement.style.top = this.autoCompleteInput.multiInputEL.nativeElement.offsetTop + 'px';
      // }, 0);
    }
  }

  onAutoCompleteSelect(node) {
    super.onAutoCompleteSelect(node);
    this.onValueChange();
  }

  onAutoCompleteUnselect(node) {
    super.onAutoCompleteUnselect(node);
    this.onValueChange();
  }

  onTreeSelectionChanged(evt) {
    super.onTreeSelectionChanged(evt);
    this.onValueChange();
  }

  isFilterActive() {
    return !!(this.value && this.value.length);
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    if (this.selectedNodes && this.selectedNodes.length) {
      const value = params.data[this.params.colDef.field];
      return (Array.isArray(value) ? value : [value])
        .some(val => !!~this.selectedNodes.map(v => v.name).indexOf(val));
    }
    return false;
  }

  getModel() {
    return this.value && this.value.length ? {
      filterType: 'DynamicListFilterComponent',
      filter: this.value
    } : null;
  }

  setModel(value: any) {
    this.writeValue(value);
  }

  agInit(params: any) {
    this.params = params;
    this.listObject = {entries: (this.params.colDef.filterParams.values || []).map(value => ({value}))};
    this.pickerTitle = this.params.colDef.headerName;

    setTimeout(() => {
      this.column = this.params.api.columnController.gridColumns.find(col => col.menuVisible);
    }, 0);

    this.eventService.on(EnaioEvent.EO_DIALOG_STACK_CHANGED).pipe(
      filter((event: any) => event && this.params.column && this.params.column.isMenuVisible())
    ).subscribe((event: any) => {
        let elements = this.params.api['filterManager'].popupService.popupList;
        if (event.data.active) {
          elements.push({element: document.body});
        } else {
          setTimeout(() => {
            this.params.api['filterManager'].popupService.popupList = elements.filter(el => el.element !== document.body);
          }, 0);
        }
      });
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    // this.autoCompleteInput.multiInputEL.nativeElement.focus();
  }
}
