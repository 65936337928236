import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DmsParams, SystemService, TranslateService, UserService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../eo-framework-core/empty-state/empty-state.service';
import {PendingChangesComponent} from '../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {CustomSortComponent} from '../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {SetFilterComponent} from '../../eo-framework/grid/extensions/filter/setFilters/set-filter.component';
import {ISetFilter} from '../../eo-framework/grid/extensions/filter/setFilters/set-filters.interface';
import {GridComponent} from '../../eo-framework/grid/grid.component';
@UntilDestroy()
@Component({
  selector: 'eo-favorite-state',
  templateUrl: './favorite-state.component.html',
  styleUrls: ['./favorite-state.component.scss']
})
export class FavoriteStateComponent implements OnInit, PendingChangesComponent, OnDestroy {

  gridOptions: GridOptions;
  sortFields: ColDef[];
  typeFilterFields: ISetFilter[];
  gridData;
  emptyState: EmptyState = {icon: '', text: '', className: ''};
  showActionsMenu = true;
  textFilterParams: any = {};

  @ViewChild('eoGrid') eoGrid: GridComponent;

  constructor(public selection: SelectionService,
              public translate: TranslateService,
              private system: SystemService,
              private empty: EmptyStateService,
              private pendingChanges: PendingChangesService,
              private titleService: PageTitleService,
              private userService: UserService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.favorites.list.title'));
  }

  set hasContent(hasContent: boolean) {
    this.showActionsMenu = hasContent;
  }

  get hasContent(): boolean {
    return this.showActionsMenu;
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  refreshGrid() {
    this.userService.getFavorites().subscribe();
  }

  parseDmsParams(data): DmsParams {
    return data && data.target ? {id: data.target.id, type: data.target.type} : null;
  }

  typeFilters() {
    const data = this.gridData;
    let uniqueByType = SetFilterComponent.uniqBy(data, 'target.type', this.system.getObjectTypes());
    this.typeFilterFields = uniqueByType.map(t => Object.assign({
      label: t.label,
      value: true,
      filter: (node) => node.data.target.type === t.qname,
      id: t.qname
    })).sort(Utils.sortValues('label'));
  }

  resetFilters() {
    this.typeFilters();
  }

  updateGrid(data) {
    this.emptyState = this.empty.getEmptyState(data ? data.length : -1);
    if (this.eoGrid && this.eoGrid.isReady) {
      this.eoGrid.updateRowData(data);
      return this.resetFilters();
    }

    this.resetFilters();

    let colDefs: ColDef[] = [
      {
        headerName: '',
        field: '__custom',
        cellRenderer: this.cellRenderer,
        filter: false
      }
    ];

    this.textFilterParams = {context: {defaultValue: ''}};
    this.sortFields = [
      {field: 'created', headerName: this.translate.instant('eo.inbox.sort.date'), sort: ''},
      {field: 'title', headerName: this.translate.instant('eo.inbox.sort.title'), sort: ''}
    ];

    const sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);

    this.gridOptions = {
      context: {count: data.length},
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80
    };
  }

  cellRenderer(params) {
    let icon = params.context.cr.render('type', params, {
      value: params.data.target.type
    });
    let createdTime = params.context.cr.render('dateTime', params, {value: params.data.created});

    return `<div class="list-item ${params.data.target.folder ? 'folder' : ''}" unselectable>
              ${icon}
              <div class='content'>
                <div class='title'>${params.data.title || ''}</div>
                <div class='description date'>
                  ${params.data.created ? '<span class="period">' + createdTime + '</span>' : ''}
                </div>
              </div>
              <div class="meta">
              </div>
           </div>
          `;
  }

  remove() {
    let focused = this.selection.getFocus();
    if (focused) {
      this.userService.removeFavorite(focused.id).subscribe();
    }
  }

  ngOnInit() {
    this.refreshGrid();
    this.userService.favoritesGridData$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.gridData = data;
        this.updateGrid(this.gridData);
      });
  }

  ngOnDestroy() {
    this.selection.clear();
  }
}
