import {forkJoin as observableForkJoin, of as observableOf, Observable, ReplaySubject} from 'rxjs';
import {switchMap, catchError, map} from 'rxjs/operators';
import {
  ComponentFactoryResolver,
  Inject,
  Injectable,
  InjectionToken,
  ViewContainerRef
} from '@angular/core';
import {Action} from '../interfaces/action.interface';
import {SelectionRange} from '../selection-range.enum';
import {Router} from '@angular/router';
import {ActionListEntry} from '../interfaces/action-list-entry';
import {WorkflowActionComponent} from '../actions/workflow-action/workflow-action.component';
import {CustomActionsComponent} from '../actions/custom-actions/custom-actions.component';
import {SimpleWorkflowActionComponent} from '../actions/workflow-action/simple-workflow-action.component';
import {BpmService, ExecutableProcess, DmsObject, CapabilitiesService, BackendService, CustomAction, Utils} from '@eo-sdk/core';
import {WorkflowComponent} from '../actions/workflow-action/workflow/workflow.component';

export const ACTIONS = new InjectionToken<any[]>('ACTIONS');
export const CUSTOM_ACTIONS = new InjectionToken<any[]>('CUSTOM_ACTIONS');

// command broadcasted to listening components
export interface ActionShowCommand {
  show: boolean;
  target?: string;
  selection: any[];
}

@Injectable()
export class ActionService {

  private allActionComponents: any[] = [];
  private workflowActionClass;
  private customActionClass;
  private simpleWorkflowActionClass;

  private actionsShowingSource = new ReplaySubject<ActionShowCommand>(1);
  public actionsShowing$: Observable<ActionShowCommand> = this.actionsShowingSource.asObservable();

  constructor(@Inject(ACTIONS) actions: any[] = [],
              @Inject(CUSTOM_ACTIONS) custom_actions: any[] = [],
              private _componentFactoryResolver: ComponentFactoryResolver,
              private bpmService: BpmService,
              private backend: BackendService,
              private router: Router,
              private capabilitiesService: CapabilitiesService) {

    this.allActionComponents = actions.concat(custom_actions)
      .filter(entry => entry.target && !entry.isSubAction && !entry.disabled);

    this.workflowActionClass = WorkflowActionComponent;
    this.customActionClass = CustomActionsComponent;
    this.simpleWorkflowActionClass = SimpleWorkflowActionComponent;
  }

  getActionsList(selection: any[], viewContainerRef: ViewContainerRef): Observable<ActionListEntry[]> {

    // todo: find better solution to exclude components for actions that need to be initialized later
    return this.getExecutableActionsListFromGivenActions(
      this.allActionComponents.filter(ac => ac !== WorkflowComponent && ac !== SimpleWorkflowActionComponent && ac !== CustomActionsComponent && ac !== WorkflowActionComponent),
      selection,
      viewContainerRef
    );
  }

  getMoreActions(selection: any[], viewContainerRef?): Observable<ActionListEntry[]> {
    if (!this.isAllowedType(selection, ['sysemail'])) {
      return observableOf([]);
    }
    return observableForkJoin([
      this.collectProcessActions(selection, viewContainerRef),
      this.collectCustomActions(selection, viewContainerRef)
    ]).pipe(map(res => [...res[0], ...res[1]]));
  }

  collectProcessActions(selection: any[], viewContainerRef): Observable<ActionListEntry[]> {
    const enableWorkflow = this.capabilitiesService.hasCapability('bpm');

    if (this.router.url.startsWith('/versions')) {
      return observableOf([]);
    }

    return enableWorkflow ? this.bpmService.getExecutableProcessesForDmsObjects(selection)
      .pipe(
        map((res: ExecutableProcess[]) => {
          const all = [];
          res.forEach((executableProcess: ExecutableProcess) => {

            let actionComponent = this.simpleWorkflowActionClass;
            if (executableProcess.form) {
              actionComponent = this.workflowActionClass;
            }
            const actionListEntry = this.createExecutableActionListEntry(actionComponent, selection, viewContainerRef);
            actionListEntry.action['executableProcess'] = executableProcess;
            all.push(actionListEntry);
          });

          return all;
        }),
        catchError(e => {
          return observableOf([]);
        })
      ) : observableOf([]);
  }

  collectCustomActions(selection: any[], viewContainerRef): Observable<ActionListEntry[]> {

    const types = selection.map(i => i.typeName);
    return this.getCustomActions(types).pipe(map(customActions => {
      return customActions.length ?
        customActions.map(customAction => {
          const actionsRef: ViewContainerRef = this.customActionClass;
          const actionListEntry = this.createExecutableActionListEntry(actionsRef, selection, viewContainerRef);
          actionListEntry.action['action'] = customAction;
          return actionListEntry;
        }) : [];
    }))
      .pipe(
        switchMap(targetActionsList => {
          const observables = targetActionsList.map((actionListEntry: ActionListEntry) => actionListEntry.action.isExecutable(selection).pipe(
            map(executable => Array.isArray(executable) ? selection.filter((s, i) => executable[i]) : executable ? selection : []),
            map(available => {
              actionListEntry.availableSelection = available;
              return actionListEntry;
            })
          ));

          return  observables.length ? observableForkJoin(observables).pipe(
            map((actionListEntrys: ActionListEntry[]) => actionListEntrys.filter( a => this.isRangeAllowed(a.action, a.availableSelection.length))),
            catchError(e => {
              console.log(e);
              return observableOf([]);
            })
          ) : observableOf([]);
        }),
        catchError(e => {
          return observableOf([]);
        })
      );
  }

  private createExecutableActionListEntry(actionComponent: any, selection: any[], viewContainerRef: ViewContainerRef): ActionListEntry {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(actionComponent);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const entry: ActionListEntry = {
      id: componentRef.location.nativeElement.localName,
      action: componentRef.instance as Action,
      target: actionComponent.target,
      availableSelection: selection
    };
    return entry;
  }


  getExecutableActionsListFromGivenActions(allActionComponents: any[], selection: any[], viewContainerRef: ViewContainerRef): Observable<ActionListEntry[]> {
    if (selection && selection.length) {

      const allActionsList: ActionListEntry[] = allActionComponents
        .filter(actionComponent => selection[0] instanceof actionComponent.target)
        .map((actionComponent: any) => this.createExecutableActionListEntry(actionComponent, [], viewContainerRef));

      const targetActionsList = allActionsList.filter((actionListEntry: any) => selection[0] instanceof actionListEntry.target);

      const observables = targetActionsList.map((actionListEntry: ActionListEntry) => {
        const obs = selection.map(item => actionListEntry.action.isExecutable(item));
        return observableForkJoin(obs).pipe(
          map(executable => Array.isArray(executable) ? selection.filter((s, i) => executable[i]) : executable ? selection : []),
          map(available => {
            actionListEntry.availableSelection = available;
            return actionListEntry;
          })
        );
      });

      return observables.length ? observableForkJoin(observables).pipe(
        map((actionListEntrys: ActionListEntry[]) => actionListEntrys.filter( a => this.isRangeAllowed(a.action, a.availableSelection.length))
        .sort(Utils.sortValues('action.priority'))),
        catchError(e => {
          console.log(e);
          return observableOf([]);
        })
      ) : observableOf([]);
    } else {
      return observableOf([]);
    }
  }

  /**
   * Checks if the action is allowed for single ot multiple selection
   * @param action
   * @param itemsCount
   * @returns
   */
  private isRangeAllowed(action, itemsCount) {
    let isRangeAllowed = true;
    switch (action.range) {
      case SelectionRange.SINGLE_SELECT:
        isRangeAllowed = itemsCount === 1;
        break;
      case SelectionRange.MULTI_SELECT:
        isRangeAllowed = itemsCount >= 1;
        break;
      case SelectionRange.MULTI_SELECT_ONLY:
        isRangeAllowed = itemsCount > 1;
        break;
      default:
        break;
    }
    return isRangeAllowed;
  }


  /**
   * Getter for the Custom Actions
   *
   * @param types List of types to fetch custom actions for
   * @returns List of available custom actions
   */
  getCustomActions(types: string[] = []): Observable<CustomAction[]> {
    return this.backend.get(`/ui/actions?types=${types.join(',')}`)
      .pipe(
        map(res => res as CustomAction[])
      );
  }

  /**
   * Show global actions panel
   */
  public showActions(selection: any[], target = 'DMS_OBJECT') {
    this.actionsShowingSource.next({show: true, selection, target});
  }

  /**
   * Hide global actions panel
   */
  public hideActions() {
    this.actionsShowingSource.next({show: false, selection: []});
  }

  isAllowedType(items: DmsObject[], rejectedType = []): boolean {
    const disabledType = rejectedType;
    return !!items.find(item => !disabledType.some(type => item.typeName === type));
  }
}
