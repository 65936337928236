import * as _ from 'lodash';

// This class will be injected as scope into form scripts
export class ObjectFormScriptingScope {

  // List of all form fields (elements)
  public model: any = {};

  // all data values including invisible elements (READONLY)
  public data: any = {};

  // The id of the current DMS-Object (READONLY)
  public objectId: string;

  // actions passed by BPM forms
  public actions: any = {};
  // additional objects provided by the object form
  // used for example to pass dms objects attached to a work item
  // to the script (READONLY)
  // may contain: FormScriptDmsObject, FileEntry
  public objects: any[] = [];
  public context: { id: string, title: string, typeName: string } = {id: '', title: '', typeName: ''};


  /**
   * Constructor
   * @param situation - Current form model situation (could be CREATE, SEARCH or EDIT)
   * @param onScriptingModelChange - callback function to be called when the scripting model changed
   * Be aware to provide an instance method as callback for the scripting scope.
   * @see: https://blog.johnnyreilly.com/2014/04/typescript-instance-methods.html
   *
   * @param api - Plugin API reference
   * @param isTableRowScope - set this to true if the current scripting scope is created for a
   * row editing form from a table element. It will then provide the right parameters for the scripting functions
   */
  constructor(public situation: string,
              private onScriptingModelChange,
              public api: any,
              private isTableRowScope?: boolean) {
  }

  /**
   * Sets the internal model and wraps every model element with an observer to be
   * able to track their changes from within a form script
   * @param model
   */
  public setModel(model: any) {
    this.model = {};
    for (let k of Object.keys(model)) {
      this.model[k] = new ScopeElement(model[k], this.onScriptingModelChange);
    }
  }

  /**
   * Returns the observed model. Used for row editing forms of table elements that
   * create their own scripting scope in context of their parent form.
   * @returns the observed scripting scope model
   */
  public getModel() {
    return this.model;
  }

  /**
   * Called by the object form when the form model changes.
   * @param change - object with key equals name of the element
   * that changes and value of the new value
   */
  public modelChanged(change: any) {

    // find the changed element in the scopes model
    let propertyName = Object.keys(change)[0];
    if (change[propertyName] === undefined) {
      return;
    }

    if (this.model[propertyName]) {
      let el = this.model[propertyName];
      el.update(change[propertyName], this.model);
    }
  }
}

class ScopeElement {
  private element;

  constructor(private originalElement: any, private onScriptingModelChange: Function) {
    this.element = _.cloneDeep(originalElement);
    if (this.element.value === undefined) {
      this.element.value = null;
    }
    if (this.isProxyable(this.element.value)) {
      this.element.value = this.createProxy(this.element.value);
    }
  }

  private isProxyable(value, key?: string) {
    return (typeof value === 'object' || Array.isArray(value))
      && value !== null
      && !(value instanceof Date)
      && !value.isProxy
      && (key ? !key.includes('_meta') : true);
  }

  private createProxy(value) {
    const handler = {
      set: (target, key, val) => {
        const previousValue = _.cloneDeep(this.element.value);
        target[key] = val;
        if (this.hasValueChanged(this.element.value, previousValue) && !key.includes('_meta')) {
          this.onScriptingModelChange(this.element.name, {newValue: _.cloneDeep(this.element.value), name: 'value'});
        }
        return true;
      },
      // This get trap is only used to find out, if an object is a proxy or not.
      get: (target, key) => {
        if (key === 'isProxy') {
          return true;
        }
        return target[key];
      }
    };

    return new Proxy(value, handler);
  }

  private hasValueChanged(value1, value2) {
    let v1 = value1;
    let v2 = value2;
    if(Array.isArray(value1) && typeof value1[0] === 'object' && Array.isArray(value2) && typeof value2[0] === 'object'){// this is only the case in tables
      v1 = value1.map(entry => this.createEntryWithoutMetadata(entry));// removing metadata from every row, because it's insignificant for change detection
      v2 = value2.map(entry => this.createEntryWithoutMetadata(entry));// and can cause false "true"-results
    }
    return JSON.stringify(v1) !== JSON.stringify(v2);
  }

  private createEntryWithoutMetadata(entry){
    let entryWithoutMetadata = {...entry};
    Object.keys(entryWithoutMetadata).forEach(key => {
      delete entryWithoutMetadata[key + '_meta'];
    });
    return entryWithoutMetadata;
  }

  update(value, model) {
    const newValue = _.cloneDeep(value);
    if (this.hasValueChanged(this.element.value, value)) {
      if (this.isProxyable(newValue)) {
        this.element.value = this.createProxy(newValue);
      } else {
        this.element.value = newValue;
      }
      if (this.element.onchange) {
        this.element.onchange(this, model);
      }
    }
  }

  set value(value) {
    if (this.isProxyable(value)) {
      this.element.value = this.createProxy(value);
    } else {
      this.element.value = value;
    }
    this.onScriptingModelChange(this.element.name, {newValue: _.cloneDeep(this.element.value), name: 'value'});
  }

  get value() {
    return this.element.value;
  }

  set onchange(onchange) {
    this.element.onchange = onchange;
    this.onScriptingModelChange(this.element.name, {newValue: onchange, name: 'onchange'});
  }

  get onchange() {
    return this.element.onchange;
  }

  set required(required) {
    if(this.hasValueChanged(this.element.required, required)){
      this.element.required = required;
      this.onScriptingModelChange(this.element.name, {newValue: required, name: 'required'});
    }
  }

  get required() {
    return this.element.required;
  }

  set readonly(readonly) {
    this.element.readonly = readonly;
    this.onScriptingModelChange(this.element.name, {newValue: readonly, name: 'readonly'});
  }

  get readonly() {
    return this.element.readonly;
  }

  set error(error) {
    if(this.hasValueChanged(this.element.error, error)){
      this.element.error = error;
      this.onScriptingModelChange(this.element.name, {newValue: error, name: 'error'});
    }
  }

  get error() {
    return this.element.error;
  }

  set onrowedit(onrowedit) {
    this.element.onrowedit = onrowedit;
    this.onScriptingModelChange(this.element.name, {newValue: onrowedit, name: 'onrowedit'});
  }

  get onrowedit() {
    return this.element.onrowedit;
  }

  set contextId(contextId) {
    this.element.contextId = contextId;
    this.onScriptingModelChange(this.element.name, {newValue: contextId, name: 'contextId'});
  }

  get contextId() {
    return this.element.contextId;
  }

  setList(obj) {
    if (this.originalElement.setList) {
      this.originalElement.setList(obj);
    }
  }

  applyFilter(func) {
    if (this.originalElement.applyFilter) {
      this.originalElement.applyFilter(func);
    }
  }

  applyDisablingFilter(func) {
    if (this.originalElement.applyDisablingFilter) {
      this.originalElement.applyDisablingFilter(func);
    }
  }

  setFilter(obj) {
    if (this.originalElement.setFilter) {
      this.originalElement.setFilter(obj);
    }
  }

  setQueryFilters(queryFiltersObject: {[fieldQname: string]: {o: string, v1: any, v2: any}}, objectTypes?: string[]) {
    if (this.originalElement.setQueryFilters) {
      this.originalElement.setQueryFilters(queryFiltersObject, objectTypes);
    }
  }

  get name() {
    return this.element.name;
  }

  get qname() {
    return this.element.qname;
  }

  get label() {
    return this.element.label;
  }

  get description() {
    return this.element.description;
  }

  get type() {
    return this.element.type;
  }

  get multiselect() {
    return this.element.multiselect;
  }

  get codesystem() {
    return this.element.codesystem;
  }

  get minlen() {
    return this.element.minlen;
  }

  get maxlen() {
    return this.element.maxlen;
  }

  get classification() {
    return this.element.classification;
  }

  get scale() {
    return this.element.scale;
  }

  get precision() {
    return this.element.precision;
  }

  get withtime() {
    return this.element.withtime;
  }

  get elements() {
    return this.element.elements;
  }

  get aliases() {
    return this.element.aliases;
  }
}
