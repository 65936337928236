import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'eo-app-add',
  templateUrl: './app-add.component.html',
  styleUrls: ['./app-add.component.scss']
})
export class AppAddComponent {

  constructor(private router: Router) {
  }

  public close(evt?) {
    this.router
      .navigate([{outlets: {modal: null}}], {replaceUrl: true});
  }
}
