
import {of as observableOf, Observable} from 'rxjs';
import {ComponentAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {Router} from '@angular/router';
import {TranslateService} from '@eo-sdk/core';
import {DmsObject, DmsService} from '@eo-sdk/core';
import {DeleteContentComponent} from './delete-content/delete-content.component';

@Component({
  selector: 'eo-delete-content-action',
  template: ``
})
export class DeleteContentActionComponent extends DmsObjectTarget implements ComponentAction {
  label: string;
  description: string;
  priority = 8.1;
  iconSrc = 'assets/_default/svg/ic_delete_sweep.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;
  component = DeleteContentComponent;

  constructor(private router: Router, private translate: TranslateService) {
    super();
    this.label = this.translate.instant('eo.action.delete.content.label');
    this.description = this.translate.instant('eo.action.delete.content.description');
  }

  isExecutable(item: DmsObject): Observable<boolean> {
    const isLocked = item.lock && item.lock.by.other;
    const hasContent = item.content && item.content.id === item.id;
    return observableOf(this.isAllowedState() && !item.isFinalized && hasContent && item.rights.edit && !isLocked && item.type.minFiles === 0);
  }

  isAllowedState(): boolean {
    const disabledStates = ['/versions'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }
}
