import {agentConfigKeys, AgentService} from './../../../eo-framework-core/agent/agent.service';
import {DmsObject, UserService} from '@eo-sdk/core';
import { Component, OnInit, Input } from '@angular/core';
import {LockSettings} from '../../../eo-client/settings/agent-lock-settings.enum';
import {take, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'eo-edit-icon',
  templateUrl: './edit-icon.component.html',
  styleUrls: ['./edit-icon.component.scss']
})
export class EditIconComponent implements OnInit {

  @Input() item: DmsObject;

  display = false;
  agentAlwayslock: string;

  constructor(private userService: UserService, private agentService: AgentService) { }

  toggle(event) {
    this.display = !this.display;
    event.preventDefault();
    event.stopPropagation();
  }

  editOriginalContent() {
    const lock = this.agentAlwayslock && this.agentAlwayslock === 'always';
    this.agentService.openDocument(this.item, lock);
  }

  fetchAgentAlwayslock() {
    this.agentService.isConnected$.pipe(take(1), withLatestFrom(this.agentService.agentConfig$)).subscribe(([isConnected, config]) => {
      if (config.hasOwnProperty(agentConfigKeys.LOCKSETTINGS) && Object.values(LockSettings).includes(config[agentConfigKeys.LOCKSETTINGS])) {
        this.agentAlwayslock = config[agentConfigKeys.LOCKSETTINGS];
      } else {
        this.agentAlwayslock = this.userService.getCurrentUser().userSettings.alwayslock;
      }
    });
  }

  ngOnInit() {
    this.fetchAgentAlwayslock();
  }

}
