import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Dialog} from '@yuuvis/components/dialog';

@Component({
  selector: 'eo-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent {

  @ViewChild('op') op: Dialog;
  @Input() iconSrc: string;
  @Input() badge: string;
  @Input() iconClass = '';
  @Input() styleClass = '';
  @Input() active = false;
  @Input() iconTitle: string;
  hostEl?: HTMLElement;
  dialogIsOpen = false;

  constructor(private elRef: ElementRef) {
    this.hostEl = this.elRef.nativeElement;
  }

  public onActiveChanged(event) {
    this.active = event;
  }

  toggle(e: any) {
    if (this.dialogIsOpen) this.op.close();
    else this.op.open();
  }

  close() {
    if (this.op) {
      this.op.close();
    }
  }
}
