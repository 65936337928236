import {
  Component,
  HostListener,
  forwardRef,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  HostBinding, ElementRef, OnInit
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TreeNode, TreeOptions} from './tree.component.interface';



@Component({
  selector: 'eo-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class TreeComponent implements ControlValueAccessor, OnInit {

  @HostBinding('attr.tabindex') _tabIndex = 0;

  private _expandedNodes: string[] = [];
  private _highlightedNodes: string[] = [];
  private selectedNodes: any[] = [];
  private visibleNodes: any[] = [];
  private focusedNode: TreeNode;

  tree: TreeNode[];

  @Input() options: TreeOptions = {};
  @Input() isDisplayed: boolean;
  @Input() readonly: boolean;
  @Input() emptyMessage = 'eo.error.structure.tree.not.available';

  @Input() selectionKey = ' '; // default is Space

  // the nodes that are selected. In case of `multiselect` set to true, this will be an
  @Output() expandedNodesChanged = new EventEmitter();
  @Output() onSelected = new EventEmitter();

  propagateChange = (_: any) => {
  }

  constructor(public elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  @Input('highlightedNodes')
  set highlightedNodes(nodes: string[]) {
    this._highlightedNodes = nodes || [];
    this.visibleNodes.forEach(n => (n.highlighted = !!this._highlightedNodes.find(id => id === n.id)));
  }

  @Input('tree')
  set treeInput(tree: TreeNode[]) {
    // this.tree = tree;
    this.selectedNodes = [];
    this._expandedNodes = [];
    this.focusedNode = null;
    this.updateTree(tree);
  }

  @Input('treeUpdate')
  set treeUpdate(tree: TreeNode[]) {
    if (tree) {
      this.updateTree(tree);
    }
  }


  @HostListener('focusin', ['$event.target'])
  onFocusIn(target: any) {
    if (!this.focusedNode || !this.focusedNode.focused) {
      let node = this.selectedNodes[0] || this.visibleNodes[0];
      this.updateFocus(node);
    }
  }

    @HostListener('focusout')
    onFocusOut() {
      this.updateFocus();
    }

  onFocus(evt) {
  }

  onBlur(evt) {
  }

  onToggle(evt) {

  }

  @HostListener('keydown', ['$event'])
  onKey(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp': {
        this.focusNode(false);
        break;
      }
      case 'ArrowDown': {
        this.focusNode(true);
        break;
      }
      case 'ArrowRight': {
        this.toggleExpanded(this.focusedNode);
        break;
      }
      case 'ArrowLeft': {
        this.toggleExpanded(this.focusedNode);
        break;
      }
      case this.selectionKey: {
        // toggle selection of the focused node
        if (this.focusedNode.selectable) {
          // this.focusedNode.selected = !this.focusedNode.selected;
          this.onNodeSelected(this.focusedNode);
        }
        break;
      }
      case 'Enter': {
        // toggle selection of the focused node
        if (this.focusedNode.selectable) {
          // this.focusedNode.selected = !this.focusedNode.selected;
          this.onNodeSelected(this.focusedNode);
        }
        break;
      }
    }

    if (['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }


  writeValue(selectedNodes: TreeNode[]): void {
    let _selectedNodes = !selectedNodes || Array.isArray(selectedNodes) ? selectedNodes || [] : [selectedNodes];

    if (this.selectedNodes.length !== _selectedNodes.length || this.selectedNodes.some((n, i) => n.id !== _selectedNodes[i].id)) {
      this.selectedNodes = _selectedNodes;
      this.updateTree(this.tree);
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onNodeSelected(node) {
    // if the selected node isn't allowed to be selected, we'll instead try to
    // toggle expanded flag for parent nodes
    this.onSelected.emit(node);
    this.updateFocus(node, this.focusedNode);

    if (!node.selectable) {
      this.toggleExpanded(node);
    } else {
      node.selected = !node.selected;
      if (node.selected) {
        if (!this.options.multiselect && this.selectedNodes.length) {
          this.selectedNodes[0].selected = false;
          this.selectedNodes = [];
        }
        this.selectedNodes.push(node);
      } else {
        if (!this.options.disableDeselection) {
          this.selectedNodes = this.selectedNodes.filter(
            n => n.id !== node.id
          );
        } else {
          node.selected = true;
        }
      }
      this.propagateChange(this.options.multiselect ? this.selectedNodes : this.selectedNodes[0]);
    }
  }


  public onNodeExpanded(node: TreeNode) {
    this.collectVisibleNodes(node);
    this.expandedNodesChanged.emit(this._expandedNodes);
  }


  public toggleExpanded(node: TreeNode) {
    if (node.children && node.children.length) {
      node.expanded = !node.expanded;
      this.onNodeExpanded(node);
    }
  }


  // Update the tree according to the selected nodes
  private updateTree(tree?: any) {
    if (tree || this.tree) {
      this.tree = (tree || this.tree).map(node => this.updateTreeNode(node, []));
      this.collectVisibleNodes();
    }
  }


  // Recursive function walking the tree nodes and setting the nodes `selected` flags
  private updateTreeNode(node: TreeNode, parents: TreeNode[]) {
    node.selected = !!this.selectedNodes.find(n => node.id === n.id || (!n.id && node.data?.value === n.value));
    node.expanded = this._expandedNodes.length ? !!this._expandedNodes.find(id => id === node.id) : node.expanded;
    node.highlighted = !!this._highlightedNodes.find(id => id === node.id);
    node.focused = this.focusedNode && this.focusedNode.id === node.id ? this.focusedNode.focused : false;
    // node.level = parents.length;

    // expand all parent nodes as well
    if (node.selected && parents.length) {
      parents.forEach(p => (p.expanded = true));
    }
    if (node.children) {
      node.children.forEach(n => this.updateTreeNode(n, parents.concat([node])));
    }
    return node;
  }

  // Collect all visible nodes in the right order to be used to fetch the previous or
  // next node using keyboard navigation.
  private collectVisibleNodes(node?: TreeNode) {
    this.visibleNodes = this.collectVisibleChildren(this.tree);
    this.selectedNodes = this.visibleNodes.filter(n => n.selected);
  }

  // recursive part of collectChildNodes()
  private collectVisibleChildren(nodes: TreeNode[] = []) {
    let flat = [];
    this._expandedNodes = [];
    nodes.forEach(node => {
      flat.push(node);
      if (node.expanded) {
        this._expandedNodes.push(node.id);
        flat = flat.concat(this.collectVisibleChildren(node.children));
      }
    });
    return flat;
  }

  private updateFocus(node?: TreeNode, old?: TreeNode) {
    if (old) {
      if (node !== old) {
        old.focused = false;
      }
    } else {
      this.visibleNodes.forEach(n => (n.focused = false));
    }
    if (node) {
      const nodes = document.querySelectorAll('eo-tree-node');
      nodes.forEach(n => {
        if (n.querySelector('.label span').textContent === node.name) {
          n.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        }
      });
      node.focused = true;
      this.focusedNode = node;
    }
  }

  private focusNode(next?: boolean) {
    if (this.visibleNodes.length) {
      let index = 0, shift = next ? 1 : -1;
      const node = this.visibleNodes.find((n, i) => {
        index = i;
        return n.focused;
      });

      const nextIdx = node ? index + shift : 0;
      const idx = nextIdx >= this.visibleNodes.length ? 0 : (nextIdx < 0 ? this.visibleNodes.length - 1 : nextIdx);
      this.updateFocus(this.visibleNodes[idx], node);
    }
  }
}
