import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {YvcMasterDetailsModule} from '@yuuvis/components/master-details';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';

import {EoFrameworkModule} from '../eo-framework/eo-framework.module';
import {GridModule} from '../eo-framework/grid/grid.module';
import {AboutStateComponent} from './about-state/about-state.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {EoClientRoutingModule} from './eo-client-routing.module';
import {FavoriteStateComponent} from './favorite-state/favorite-state.component';
import {InboxStateModule} from './inbox-state/inbox-state.module';
import {LoginComponent} from './login/login.component';
import {NotificationsStateComponent} from './notifications-state/notifications-state.component';
import {ObjectStateModule} from './object-state/object-state.module';
import {PrepareStateModule} from './prepare-state/prepare-state.module';
import {ProcessStateComponent} from './process-state/process-state.component';
import {ResultStateComponent} from './result-state/result-state.component';
import {SettingsComponent} from './settings/settings.component';
import {StoredQueriesStateComponent} from './stored-queries-state/stored-queries-state.component';
import {VersionStateComponent} from './version-state/version-state.component';
import {RecyclebinStateComponent} from './recyclebin-state/recyclebin-state.component';
import {YvcDropdownModule} from '@yuuvis/components/dropdown';
import {YuvWidgetGridModule} from '@yuuvis/widget-grid';
import {HttpClientModule} from '@angular/common/http';
import {IframeSetupComponent} from './dashboard/widgets/iframe-widget/iframe-setup/iframe-setup.component';
import {InfoInboxSetupComponent} from './dashboard/widgets/info-inbox-widget/info-inbox-setup/info-inbox-setup.component';
import {InfoIntraySetupComponent} from './dashboard/widgets/info-intray-widget/info-intray-setup/info-intray-setup.component';
import {IframeWidgetComponent, SafeUrlPipe} from './dashboard/widgets/iframe-widget/iframe-widget.component';
import {InfoInboxWidgetComponent} from './dashboard/widgets/info-inbox-widget/info-inbox-widget.component';
import {InfoIntrayWidgetComponent} from './dashboard/widgets/info-intray-widget/info-intray-widget.component';
import {LastEditedAndCreatedComponent} from './dashboard/widgets/last-edited-widget/last-edited-and-created.component';
import {SearchWidgetComponent} from './dashboard/widgets/search-widget/search-widget.component';
import {YvcTabsModule} from '@yuuvis/components/tabs';
import {HitlistSetupComponent} from './dashboard/widgets/hitlist-widget/hitlist-setup/hitlist-setup.component';
import {HitlistWidgetComponent} from './dashboard/widgets/hitlist-widget/hitlist-widget.component';
import {ChartsWidgetComponent} from './dashboard/widgets/charts-widget/charts-widget.component';
import {ChartsSetupComponent} from './dashboard/widgets/charts-widget/charts-setup/charts-setup.component';
import {ChartsModule} from '@carbon/charts-angular';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ObjectStateModule,
    InboxStateModule,
    PrepareStateModule,
    EoClientRoutingModule,
    EoFrameworkModule,
    GridModule,
    YvcMasterDetailsModule,
    YvcTabsModule,
    YvcSplitViewModule,
    YvcDropdownModule,
    YuvWidgetGridModule,
    HttpClientModule,
    ChartsModule
  ],
  declarations: [
    DashboardComponent,
    SettingsComponent,
    LoginComponent,
    ResultStateComponent,
    FavoriteStateComponent,
    NotificationsStateComponent,
    AboutStateComponent,
    StoredQueriesStateComponent,
    ProcessStateComponent,
    VersionStateComponent,
    RecyclebinStateComponent,
    IframeSetupComponent,
    InfoInboxSetupComponent,
    InfoIntraySetupComponent,
    IframeWidgetComponent,
    InfoInboxWidgetComponent,
    InfoIntrayWidgetComponent,
    SearchWidgetComponent,
    SafeUrlPipe,
    LastEditedAndCreatedComponent,
    HitlistSetupComponent,
    HitlistWidgetComponent,
    ChartsWidgetComponent,
    ChartsSetupComponent
  ],
  exports: [RouterModule, EoFrameworkModule]
})
export class EoClientModule {

  static forRoot(): ModuleWithProviders<EoClientModule> {
    return {
      ngModule: EoClientModule
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: EoClientModule) {
    if (parentModule) {
      throw new Error(
        'EoClientModule is already loaded. Import it in the AppModule only');
    }
  }
}
