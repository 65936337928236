import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SimpleAccordionComponent} from './simple-accordion/simple-accordion.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [SimpleAccordionComponent],
  exports: [SimpleAccordionComponent]
})
export class AccordionModule {
}
