
import {of as observableOf, Observable} from 'rxjs';
import {LinkAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {DmsObject} from '@eo-sdk/core';

@Component({
  selector: 'eo-open-versions-action',
  template: ``
})
export class OpenVersionsActionComponent extends DmsObjectTarget implements LinkAction {
  label: string;
  description: string;
  priority = 6;
  iconSrc = 'assets/_default/svg/ic_format_list_numbered.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  constructor(private translate: TranslateService, private router: Router) {
    super();
    this.label = this.translate.instant('eo.action.menu.show.versions.label');
    this.description = this.translate.instant('eo.action.menu.show.versions.description');
  }

  isExecutable(item: DmsObject) {
    const oneVersion = item.version  > 1;
    return observableOf(this.isAllowedState()  && oneVersion);
  };

  getParams(selection: DmsObject[]) {
    const item = selection[0];
    return {type: item.typeName};
  }

  getLink(selection: DmsObject[]) {
    const item = selection[0];
    return `/versions/${item.id}`;
  }

  isAllowedState() {
    const disabledStates = ['/versions'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }
}
