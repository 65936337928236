import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {NavigationExtras, Router} from '@angular/router';
import {
  IStoredQueryParam,
  OrgRole,
  QueryScope,
  SearchFilter,
  SearchQuery,
  SearchService,
  StoredQueriesService,
  StoredQuery,
  SystemService,
  TranslateService,
  UserService,
  Utils
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable, forkJoin, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';
import {LocaleDatePipe} from '../../../eo-framework-core/pipes/locale-date.pipe';
import {SelectionService} from '../../../eo-framework-core/selection/selection.service';
import {ObjectFormHelperService} from '../../object-form/object-form-helper.service';
import {ObjectFormControl} from '../../object-form/object-form/object-form-control';
import {ObjectFormControlWrapper} from '../../object-form/object-form/object-form-control-wrapper';
import {ObjectFormGroup} from '../../object-form/object-form/object-form-group.model';
import {UtilitiesService} from '../../util/services/utilities.service';
import {EditForm, NotifyList, Restriction} from '../stored-query.interface';
import {StoredQueryComponent} from '../stored-query/stored-query.component';

@UntilDestroy()
@Component({
  selector: 'eo-stored-query-details',
  templateUrl: './stored-query-details.component.html',
  styleUrls: ['./stored-query-details.component.scss']
})
export class StoredQueryDetailsComponent implements OnInit {

  private FORM_INPUT_TITLE = 'storedQueryTitle';
  private FORM_INPUT_FULLTEXT = 'storedQueryFulltext';
  private BASE_PARAMS_FIELDS = Object.keys(SearchQuery.BASE_PARAMS).map(k => SearchQuery.BASE_PARAMS[k]);
  private datePipe: LocaleDatePipe;
  private pendingTaskId: string;

  @ViewChild('storedQueryForm') storedQueryForm: StoredQueryComponent;

  iconTitles: any;
  storedQuery: StoredQuery;
  queryScope: QueryScope;
  editForm: EditForm;
  restrictions: Restriction[];
  editingShare: boolean;
  isNewQuery: boolean;
  isOwnQuery: boolean;
  enableSharing: boolean;
  systemRoles: OrgRole[];
  rolesPicker = {
    title: this.translate.instant('eo.storedquery.roles'),
    codesystem: null,
    value: null
  };

  @Input()
  set query(q: StoredQuery) {
    this.setQuery(q);
  }

  @Output() notifyList: EventEmitter<NotifyList> = new EventEmitter<NotifyList>();

  constructor(private router: Router,
    private searchService: SearchService,
    private userService: UserService,
    private systemService: SystemService,
    private selection: SelectionService,
    private pendingChanges: PendingChangesService,
    private storedQueriesService: StoredQueriesService,
    private formHelperService: ObjectFormHelperService,
    private elemRef: ElementRef,
    private translate: TranslateService) {

    this.datePipe = new LocaleDatePipe(translate);
    this.fetchRoles();
  }

  public setQueryScope(scope: QueryScope) {
    this.storedQuery.scope = scope;
    this.queryScope = scope;
  }

  setQuery(q: StoredQuery) {
    this.editingShare = false;
    this.resetEditForm();
    // grab meta information about the query
    this.storedQuery = q;
    if (q instanceof StoredQuery) {
      this.queryScope = q.scope;
      const user = this.userService.getCurrentUser();
      this.isNewQuery = q.isNew();
      this.isOwnQuery = this.isNewQuery || q.user.toLowerCase() === user.name.toLowerCase();
      this.restrictions = this.getRestrictions();
      this.rolesPicker.value = q.roles;
      this.enableSharing = user.hasPrivilege('SHARE_STORED_QUERIES') && this.isOwnQuery && !this.isNewQuery;

      if (q.isNew()) {
        this.storedQuery.name = this.translate.instant('eo.search.storedqueries.new.title');
        this.edit();
        this.startPending();
        this.notifyList.emit({unselect: true, update: false});
        setTimeout(() => {
          this.elemRef.nativeElement.querySelector('[name="storedQueryTitle"]').focus()
        }, 500);
      }
    }
  }

  private startPending() {
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskId || ' ')) {
      this.pendingTaskId = this.pendingChanges.startTask();
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskId);
  }

  edit() {
    if (!this.editForm.form) {
      setTimeout(() => {
        this.createEditForm();
        this.notifyList.emit({unselect: false, update: true});
      }, 0);
    }
  }

  onFormStatusChanged(formState) {
    formState.dirty ? this.startPending() : this.finishPending();
  }

  toggleShareEdit() {
    this.editingShare = !this.editingShare;
  }

  share() {
    this.storedQuery.roles = this.rolesPicker.value;
    this.storedQueriesService.saveStoredQuery(this.storedQuery).subscribe(
      _ => {
      }, Utils.throw(null, this.translate.instant('eo.search.storedquery.save.error'))
    );
    this.editingShare = false;
  }

  cancelShare() {
    this.rolesPicker.value = this.storedQuery.roles;
    this.editingShare = false;
  }

  delete() {
    this.storedQueriesService
      .removeStoredQuery(this.storedQuery.id)
      .subscribe(() => {
        this.query = null;
        this.notifyList.emit({unselect: false, update: false});
      }, Utils.throw(null, this.translate.instant('eo.search.storedquery.delete.error')));
  }

  toggleFavoriteState() {
    this.storedQuery.favorite = !this.storedQuery.favorite;
    this.storedQueriesService
      .saveStoredQuery(this.storedQuery)
      .subscribe(_ => {
      }, Utils.throw(null, this.translate.instant('eo.search.storedquery.save.error')));
  }

  cancelEdit() {
    this.resetEditForm();
    this.finishPending();
    if (this.storedQuery.isNew()) {
      this.query = null;
    }
    this.notifyList.emit({unselect: false, update: false, payload: this.storedQuery});
  }

  setQueryToAppSearch() {
    this.storedQueryForm.load();
  }

  executeQuery() {
    this.storedQueryForm.execute();
  }

  // Save the current stored query
  updateStoredQuery() {

    const formData = this.getFormData();
    this.storedQuery.filters = this.storedQuery.filters.filter(f => ~this.BASE_PARAMS_FIELDS.indexOf(f.property));
    Object.keys(formData)
      .forEach(k => {
        if (k === this.FORM_INPUT_TITLE) {
          this.storedQuery.name = formData[k];
        } else if (k === this.FORM_INPUT_FULLTEXT) {
          this.storedQuery.term = formData[k];
        } else {
          let filters: SearchFilter[] = this.searchService.getSearchFilter(this.storedQuery.types, k, formData[k]);
          filters.forEach(f => this.storedQuery.addFilter(f));
        }
      });

    this.storedQueriesService
      .saveStoredQuery(this.storedQuery)
      .subscribe(updatedStoredQuery => {
        this.query = updatedStoredQuery;
        this.resetEditForm();
        this.finishPending();
        this.notifyList.emit({unselect: false, update: false, payload: updatedStoredQuery});
      }, Utils.throw(null, this.translate.instant('eo.search.storedquery.save.error')));
  }

  /**
   * Toggle form elements to be a dynamic parameter or not
   * @param event
   */
  parameterChanged(event: {control: string, value: boolean}) {

    let m = this.storedQuery.parameter.find(p => p.qname === event.control);
    if (m && !event.value) {
      // remove existing parameter
      this.storedQuery.parameter = this.storedQuery.parameter.filter(p => p.qname !== event.control);
    } else if (!m && event.value) {
      // add parameter element
      let param = this.formFieldToParameter(event.control);
      if (param) {
        this.storedQuery.parameter.push(param);
      }
    }
  }

  private getFormData() {

    const properties = Object.keys(this.editForm.form.value);
    let data = {};


    properties.forEach(property => {

      const fc: ObjectFormControlWrapper = this.editForm.form.controls[property] as ObjectFormControlWrapper;
      let formElement;
      if (fc.controls && fc.controls[property]) {
        const fe: ObjectFormControl = fc.controls[property] as ObjectFormControl;
        formElement = fe._eoFormElement;
      }
      if (formElement && formElement.isNotSetValue) {
        data[property] = null;
      } else {

        switch (property) {
          case this.FORM_INPUT_TITLE: {
            data[property] = this.editForm.form.value[property] || '';
            break;
          }
          case this.FORM_INPUT_FULLTEXT: {
            data[property] = this.editForm.form.value[property][StoredQueriesService.FULLTEXT] || '';
            break;
          }
          default: {
            const v = this.editForm.form.value[property][property];
            if (!UtilitiesService.isEmpty(v)) {
              data[property] = v;
            }
          }
        }
      }
    });
    return data;
  }

  /**
   * Create a dynamic stored query parameter for a form element
   * @param qname The form elements qname
   * @returns
   */
  private formFieldToParameter(qname: string): IStoredQueryParam {

    try {
      let param: IStoredQueryParam;
      // special handling for fulltext fields
      if (qname === StoredQueriesService.FULLTEXT) {
        param = {
          qname: qname,
          type: qname,
        };
      } else {
        const fc = ((this.editForm.form.controls[qname] as ObjectFormControlWrapper).controls[qname] as ObjectFormControl);
        const fe = fc._eoFormElement;
        param = {
          qname: fe.qname,
          type: fe.type
        };
      }
      return param;
    } catch (e) {
      return null;
    }
  }

  private resetEditForm() {
    this.editForm = {
      form: null,
      fulltextFormControl: null,
      controls: [],
      contextControls: []
    };
  }

  onFormChanges(): void {
    this.editForm
      .form
      .valueChanges
      .subscribe(() => this.onFormStatusChanged(this.editForm.form));
  }

  private createEditForm() {

    this.resetEditForm();
    this.setupFormControls().subscribe(_ => {

      let form = new ObjectFormGroup({});
      let fcTitle = new UntypedFormControl('', Validators.required);
      fcTitle.setValue(this.storedQuery.name);

      let fcFulltext = this.toFormControl(this.storedQueriesService.getFulltextFormElement(
        this.translate.instant('eo.storedquery.search.term'),
        this.storedQuery.term
      ));
      this.editForm.fulltextFormControl = fcFulltext;

      // add title and fulltext field
      form.addControl(this.FORM_INPUT_TITLE, fcTitle);
      form.addControl(this.FORM_INPUT_FULLTEXT, fcFulltext);

      if (this.editForm.controls.length || this.editForm.contextControls.length) {
        this.editForm.controls
          .forEach((c) => form.addControl(c._eoFormControlWrapper.controlName, c));
        this.editForm.contextControls
          .forEach((c) => form.addControl(c._eoFormControlWrapper.controlName, c));
      }

      this.editForm.form = form;
      this.onFormChanges();
    })
  }

  private setupFormControls(): Observable<any> {
    const tasks = [];
    // setting up object type form only makes sense with one target type
    if (this.storedQuery.types.length === 1) {
      tasks.push(this.systemService.getFormElementsFromFormModel(this.storedQuery.types[0].qname, 'SEARCH').pipe(
        tap((elements: any[]) => {
          elements.forEach(e => {
            // prevent tables and binary types from being rendered in the stored query form
            if (e.type !== 'BINARY') {
              if (e.required) {
                e.required = false;
              }
              if (e.readonly) {
                e.readonly = false;
              }
              const fc = this.toFormControl(e);
              if (fc) {
                this.editForm.controls.push(fc);
              }
            }
          })
        })
      ))
    }
    // add context form controls as well
    if (this.storedQuery.contextFolderTypes.length) {
      tasks.push(this.systemService.getFormElementsFromFormModel(this.storedQuery.contextFolderTypes[0].qname, 'SEARCH', true).pipe(
        tap((elements: any[]) => {
          elements.forEach(e => {
            const fc = this.toFormControl(e);
            if (fc) {
              this.editForm.contextControls.push(fc);
            }
          })
        })
      ))
    }

    return tasks.length ? forkJoin(tasks) : of(tasks);
  }

  private fetchRoles() {
    if (!this.systemRoles || this.systemRoles.length === 0) {
      this.systemService
        .getRoles()
        .subscribe(res => {
          this.systemRoles = res;
          this.rolesPicker.codesystem = {
            entries: this.systemRoles.map(role => ({
              id: role.id,
              label: role.name,
              defaultrepresentation: role.name,
              data: role.name
            })).sort(Utils.sortValues('label'))
          }
        }
        )
    }
  }

  getRestrictions(): Restriction[] {
    let restrictions: Restriction[] = [];

    // type restriction
    if (this.storedQuery.types && this.storedQuery.types.length) {
      restrictions.push({
        field: SearchQuery.BASE_PARAMS.TYPE,
        label: this.translate.instant('eo.storedquery.restriction.type'),
        items: this.storedQuery.types.map(t => t.label)
      });
    }

    // add base params restrictions
    (this.storedQuery.filters || []).forEach(filter => {
      if (filter && ~this.BASE_PARAMS_FIELDS.indexOf(filter.property)) {

        const field = filter.property;
        const label = this.translate.instant('eo.storedquery.restriction.' + field);

        switch (field) {
          case SearchQuery.BASE_PARAMS.MIMETYPEGROUP:
            restrictions.push({
              field, label, items: Array.isArray(filter.firstValue) ? filter.firstValue : [filter.firstValue]
            });
            break;
          case SearchQuery.BASE_PARAMS.FILENAME:
            restrictions.push({
              field, label, items: [filter.firstValue]
            });
            break;
          case SearchQuery.BASE_PARAMS.FILESIZE:
            restrictions.push({
              field, label, items: [this.translate.instant('eo.search.agg.filesize.' + filter.firstValue)]
            });
            break;
          case SearchQuery.BASE_PARAMS.CREATED:
          case SearchQuery.BASE_PARAMS.MODIFIED:
            restrictions.push({
              field, label, items: [this.createDateRestriction(filter)]
            });
            break;
          case SearchQuery.BASE_PARAMS.CREATOR:
          case SearchQuery.BASE_PARAMS.MODIFIER:
            this.systemService.getOrganizationObjects(filter.firstValue)
              .subscribe(orgUsers => restrictions.push({
                field, label, items: orgUsers.map(u => u.title)
              }));
            break;
        }
      }

    });

    return restrictions;
  }

  createDateRestriction(filter: SearchFilter) {
    switch (filter.operator) {
      case SearchFilter.OPERATOR.RANGE:
        return this.translate.instant(`eo.search.agg.time.${filter.firstValue}`);
      case SearchFilter.OPERATOR.EQUAL:
        return `= ${this.datePipe.transform(filter.firstValue, 'eoShortDate')}`;
      case SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH:
        return `${this.datePipe.transform(filter.firstValue, 'eoShortDate')} - ${this.datePipe.transform(filter.secondValue, 'eoShortDate')}`;
      case SearchFilter.OPERATOR.GREATER_OR_EQUAL:
        return `${this.translate.instant('eo.search.agg.time.span.since')} ${this.datePipe.transform(filter.firstValue, 'eoShortDate')}`;
      case SearchFilter.OPERATOR.LESS_OR_EQUAL:
        return `${this.translate.instant('eo.search.agg.time.span.until')} ${this.datePipe.transform(filter.firstValue, 'eoShortDate')}`;
      default:
        return `(${filter.operator}) ${this.datePipe.transform(filter.firstValue, 'eoShortDate')}`;
    }
  }

  /**
   * Creates a form control from a given form element
   * @param formElement
   * @returns
   */
  private toFormControl(formElement): ObjectFormControlWrapper {

    // the `name` property of stored query form element has to be `qname` of form element,
    // because we may combine multiple forms (context form) and in this case there may
    // be conflicts in namings
    formElement.name = formElement.qname;

    if (formElement.type === 'TABLE') {
      let filters = this.storedQuery.getTableFilters(formElement.qname);
      if (filters && filters.length) {
        formElement.value = this.searchService.tableFiltersToElementValue(filters, formElement.elements);
      } else {
        formElement.value = [{}];
        delete formElement.isNotSetValue;
      }
    } else {
      // grab value for the control from corresponding query filters
      let filter = this.storedQuery.getFilter(formElement.qname);
      // we may have params that are not part of the queries filters. This may be the
      // case when we mark form elements as dynamic fields without providing a value
      if (filter) {

        // filter to have no value
        if (filter.operator === SearchFilter.OPERATOR.EQUAL && filter.firstValue === null) {
          formElement.isNotSetValue = true;
        } else {
          delete formElement.isNotSetValue;
        }

        formElement.value = this.searchService.filterToElementValue(
          this.storedQuery.getFilter(formElement.qname),
          formElement.type
        );
      } else if (formElement.qname !== StoredQueriesService.FULLTEXT) {
        formElement.value = undefined;
        delete formElement.isNotSetValue;
      }
    }
    // sync with params
    const match: any = this.storedQuery.parameter.find(param => {
      return (param.qname === formElement.qname) || (param.type === StoredQueriesService.FULLTEXT && formElement.qname === StoredQueriesService.FULLTEXT)
    });
    // every field that is part of the params is supposed to be dynamic
    formElement._dynamic = !!match;

    // create actual form control that could be used with
    // FormElementComponent to then render for elements
    // wrapper may be null in case of an error
    return this.formHelperService.elementToFormControl(formElement, 'SEARCH');
  }

  onStoredQueryLoaded() {
    this.router.navigate([{outlets: {modal: 'search'}}]);
  }

  onStoredQueryExecute(evt) {
    const uriParam = encodeURIComponent(JSON.stringify(evt.queryJson));
    const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};
    this.router.navigate(['/result'], uriParamQuery);
  }

  setIconTitles() {
    this.iconTitles = {
      share: this.translate.instant('eo.storedquery.share.title'),
      shared: this.translate.instant('eo.storedquery.sharedfor.title'),
      favor: this.translate.instant('eo.storedquery.favor.title'),
      favored: this.translate.instant('eo.storedquery.favoredfor.title')
    }
  }


  ngOnInit() {
    this.selection.focus$
      .pipe(untilDestroyed(this))
      .subscribe(q => {
        this.query = q;
      });
    this.setIconTitles();
  }

  trackByQName(index, item) {
    return item.qname;
  }

  trackByIndex(index, item) {
    return index;
  }
}
