
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService, CapabilitiesService} from '@eo-sdk/core';

@Injectable()
export class CapabilitiesGuard implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private capabilities: CapabilitiesService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const notFoundRoute = '/not-found';
    return this.auth.authenticated$.pipe(map(
      (authenticated) => {

        if (authenticated) {
          if (!this.capabilities.hasCapability('intray') && state.url === '/prepare') {
            this.router.navigate([notFoundRoute]);
            return (false);
          } else if (!this.capabilities.hasCapability('inbox') && state.url === '/inbox') {
            this.router.navigate([notFoundRoute]);
            return (false);
          } else if (!this.capabilities.hasCapability('notifications') && state.url === '/notifications') {
            this.router.navigate([notFoundRoute]);
            return (false);
          } else if (!this.capabilities.hasCapability('bpm') && state.url === '/process') {
            this.router.navigate([notFoundRoute]);
            return (false);
          } else if (!this.capabilities.hasCapability('favorites') && state.url === '/favorites') {
            this.router.navigate([notFoundRoute]);
            return (false);
          } else if (!this.capabilities.hasCapability('storedqueries') && state.url === '/stored-queries') {
            this.router.navigate([notFoundRoute]);
            return (false);
          } else {
            return (true);
          }
        } else {
          return (true);
        }
      }
    ));
  }
}
