<div class="tree-node"
     [ngClass]="{selected: node.selected,
          expanded: node.expanded,
          notSelectable: !node.selectable && !(node.children?.length),
          branch: node.children && node.children.length > 0}">
  <div class="node-line"></div>
  <div class="node-content">

    <div class="node-item">
      <div class="spacer"></div>
      <div class="ico" (click)="node.children?.length ? onNodeExpandedChanged.emit(node) : onNodeSelected.emit(node)"></div>
      <div class="values">
        <div class="label" (click)="onNodeSelected.emit(node)" [ngClass]="{focused: node.focused}">
          <span>{{node.name}}</span>
        </div>
        <div class="badge" *ngIf="node.badges && node.badges.length > 0">
              <span *ngFor="let badge of node.badges"
                    class="{{badge.cssClass}}"
                    [ngClass]="{highlighted: node.highlighted}">
                    {{badge.value}}
              </span>
        </div>
      </div>
    </div>

    <div class="node-children" *ngIf="node.children?.length">
      <eo-tree-node [node]="_node" *ngFor="let _node of node.children; trackBy: trackById"
                    (onNodeExpandedChanged)="onNodeExpandedChanged.emit($event)"
                    (onNodeSelected)="onNodeSelected.emit($event)"></eo-tree-node>
    </div>
  </div>
</div>
