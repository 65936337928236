import {CellRenderer} from './../../eo-framework-core/api/grid.service';
import {forkJoin as observableForkJoin, of as observableOf, AsyncSubject, Subscription} from 'rxjs';
import {Component, ContentChild, Input, AfterViewInit} from '@angular/core';
import {SelectionConfig, SelectionService} from '../../eo-framework-core/selection/selection.service';
import {Router, NavigationExtras} from '@angular/router';
import {GridComponent} from '../grid/grid.component';
import {ActionService} from '../actions/action-service/action.service';
import {DmsObject, DmsService, Utils} from '@eo-sdk/core';

@Component({
  selector: 'eo-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.scss']
})
export class ListContainerComponent implements AfterViewInit {

  public gridCount: any;
  eoGrid: GridComponent;
  eoGridSubject = new AsyncSubject<GridComponent>();

  @ContentChild(GridComponent)
  set contentGrid(eoGrid: GridComponent) {
    if (eoGrid) {
      this.eoGrid = eoGrid;
      this.eoGridSubject.next(this.eoGrid);
      this.eoGridSubject.complete();
    }
  }

  @Input() applySelection: SelectionConfig = {};
  @Input() applyVersion = false;
  @Input() loading = true;
  @Input() tabs = true;
  @Input() parseDmsParams: (data) => any = (data: any) => data;

  constructor(private selection: SelectionService,
              private router: Router, private dmsService: DmsService, private actionService: ActionService) {
  }

  ngAfterViewInit() {
  }

  onCountChanged(data: any) {
    this.loading = false;
    this.gridCount = data;
  }

  onFocusChanged(data: any) {
    this.selection
      .find(this.applySelection.out)
      .focus(data);
  }

  onDoubleClick(event: any) {

    const {id, type, version, intent} = this.parseDmsParams(event.data);
    if (id) {
      return this.openObject(id, type, version, intent, event.event.ctrlKey);
    }
  }

  onSelectionChanged(selectedData: any[]) {
    this.selection.find(this.applySelection.out).set(selectedData);
  }

  onContextMenu(selectedData?: any[], target?: string) {
    this.showActions(selectedData, target);
  }

  openObject(id, _type?, version?, intent?, newTab = false) {
    if (this.router.url.startsWith('/object')) {
      return;
    }
    version = this.applyVersion ? version : undefined;
    const queryParams: NavigationExtras = {queryParams: {'type': _type, version, intent}};
    if (newTab) {
      window.open(CellRenderer.windowURI(this.router.createUrlTree(['object', id], queryParams)));
    } else {
      this.router.navigate(['/object', id], queryParams);
    }
  }

  loadObject(id, _type?, version?, intent?): Subscription {
    version = this.applyVersion ? version : undefined;
    return this.dmsService
      .getDmsObject(id, _type, version, intent)
      .subscribe((res: DmsObject) => {
        this.selection.find(this.applySelection.out).focus(res);
      });
  }

  showActions(selectedData?: any[], target = 'DMS_OBJECT') {
    let selection = selectedData || this.selection.find(this.applySelection.out).get();
    let observables = selection.map(data => target === 'DMS_OBJECT' ? this.dmsService.getDmsObjectByParams(this.parseDmsParams(data)) : observableOf(data));
    observableForkJoin(observables).subscribe((res: any[]) => this.actionService.showActions(res, target));
  }

}
