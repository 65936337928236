<div class="prepareDetails__wrapper" *ngIf="!loading; else mainSpinner">
  <section class="prepareDetails" *ngIf="preparedItem && preparePhase; else noItem"
    [ngClass]="{multifile: preparedItem.contentcount > 1, committing: committing}">
    <div class="eo-head">

      <div class="header-info">
        <div class="header-title">{{header.title}}</div>
        <div class="header-sub-title h-location" *ngIf="!header.location.link; else linked">{{header.location.label}}
        </div>
        <div class="header-sub-title h-subtitle">{{header.subtitle}}</div>

        <ng-template #linked>
          <div class="header-sub-title h-subtitle-detailed">
            <span translate>eo.prepare.location.title.prefix</span>
            <a [routerLink]="header.location.link">{{header.location.label}}</a>
          </div>
        </ng-template>
      </div>

      <div class="actions">
        <eo-icon class="btn btn-download"
          *ngIf="preparePhase.name === 'content' ? selectedTemplateFile : preparePhase.data.previewFile"
          [iconSrc]="'assets/_default/svg/ic_content-download.svg'"
          [iconTitle]="('eo.action.download.dms.object.content.label' | translate)" (click)="download()"></eo-icon>
        <eo-icon class="btn btn-delete" [iconSrc]="'assets/_default/svg/ic_trash.svg'"
          (click)="showDeleteDialog = true;"></eo-icon>
        <!-- buttons while choosing a template -->
        <ng-container *ngIf="chooseTemplate">
          <button class="btn-tpl-abort" (click)="showTemplateSelector(false)"
            translate>eo.prepare.template.abort</button>
          <button class="primary btn-tpl-apply" (click)="addTemplate(selectedTemplate)" [disabled]="!selectedTemplate"
            translate>eo.prepare.template.select</button>
        </ng-container>

        <ng-container *ngIf="preparePhase.name === 'indexdata'">
          <button (click)="resetObjectType()" translate>eo.prepare.resetObject</button>
          <button (click)="commit()" class="btn-idx-save"
            [ngClass]="{primary: preparedItem.state.parentisroot && preparedItem.contentcount > 1}"
            [disabled]="formState?.invalid || committing" translate>eo.prepare.save</button>

          <button (click)="commit(true)" [disabled]="formState?.invalid || committing" class="primary btn-idx-saveopen"
            [hidden]="preparedItem?.state.parentisroot && preparedItem?.contentcount > 1"
            translate>eo.prepare.saveopen</button>

        </ng-container>
      </div>

    </div>

    <div class="eo-body" [ngSwitch]="preparePhase.name" *ngIf="!committing; else spinner">

      <!-- PHASE TYPE -->
      <div class="phase type" *ngSwitchCase="'type'">

        <!-- with preview -->
        <yvc-split-view *ngIf="preparePhase.data.previewFile; else nopreview"
          [layoutSettingsID]="'prepare.details.phase.type'">
          <ng-template yvcSplitArea [size]="30">
            <div class="form-files">
              <div class="object-type-select empty" *ngIf="preparedItem.types.length == 0" translate>
                eo.prepare.details.type.empty</div>
              <div class="object-type-select empty"
                *ngIf="!preparedItem.state.typeselectedallowed && preparedItem.state.typeselected"
                [translateParams]="{type: preparedItem.selectedtype.label, filename: getFileNames(preparedItem)}"
                translate>eo.prepare.details.type.notallowed</div>

              <div class="object-type-select">
                <div class="object-type" *ngFor="let type of preparedItem.types; trackBy: trackByIdFn"
                  (click)="selectObjectType(type)">
                  <div class="type-icon">
                    <eo-icon [iconId]="type.icon.id"></eo-icon>
                  </div>
                  <div class="content">
                    <div class="title">{{type.label}}</div>
                    <div class="description">{{type.description}}</div>
                  </div>
                </div>
              </div>


              <div class="content-select">
                <h2 translate>eo.prepare.details.contents.title</h2>
                <div class="content"
                  *ngFor="let content of preparedItem.contents; trackBy: trackByIndexFn; index as idx;"
                  [ngClass]="{selected: preparePhase.data.previewIndex == idx}" (click)="setPreviewUri(idx)">
                  <span>{{content.path}}</span>
                  <eo-icon class="btn btn-delete-content" [iconSrc]="'assets/_default/svg/ic_trash.svg'"
                    (click)="showContentDeleteDialog = true; deletedContentIndex = idx;"></eo-icon>
                  <div *ngIf="content.existscount > 0" class="attention"
                    title="{{'eo.prepare.content.existscount.message'|translate: ({count: content.existscount})}}">!
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template yvcSplitArea [size]="70">
            <div class="eo-media-wrap">
              <eo-prepare-content-exists-info [content]="preparedItem.contents[preparePhase.data.previewIndex]">
              </eo-prepare-content-exists-info>
              <eo-media [dmsObject]="pseudoDmsObject" [previewFile]="preparePhase.data.previewFile"></eo-media>
            </div>
          </ng-template>
        </yvc-split-view>

        <!-- without preview -->
        <ng-template #nopreview>
          <div class="form-files">
            <div class="object-type-select">
              <div class="object-type" *ngFor="let type of preparedItem.types; trackBy: trackByIdFn"
                (click)="selectObjectType(type)">
                <div class="type-icon">
                  <eo-icon [iconId]="type.icon.id"></eo-icon>
                </div>
                <div class="content">
                  <div class="title">{{type.label}}</div>
                  <div class="description">{{type.description}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

      </div>

      <!-- PHASE CONTENT -->
      <div class="phase content" *ngSwitchCase="'content'">

        <!-- panel for selecting a template to be used as items content -->
        <yvc-split-view *ngIf="chooseTemplate; else attach" [layoutSettingsID]="'prepare.details.phase.content'">
          <ng-template yvcSplitArea [size]="40">
            <!-- list of available templates -->
            <eo-list-container #eoList [loading]="false" class="templates">
              <div class="eo-header">
                <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_template.svg'"></eo-icon>
                <div class="eo-header-info">
                  <div class="eo-header-title" translate>eo.prepare.template.list.title</div>
                </div>
                <div class="eo-header-actions">

                  <eo-overlay #oFilter [iconSrc]="'assets/_default/svg/ic_filter.svg'" [iconClass]="'primary'"
                    class="overlay-filter" [title]="'eo.list.filter' | translate"
                    (active)="oFilter.onActiveChanged($event)">
                    <eo-custom-filter #cFilter (change)="cFilter.updateGrid(eoList.eoGridSubject, $event)"
                      (active)="oFilter.onActiveChanged($event)">
                      <eo-text-filter [title]="'eo.prepare.template.filter.text.title' | translate"
                        [matchFields]="['title', 'description', 'tags']"
                        [placeholder]="'eo.prepare.template.filter.text.title'"></eo-text-filter>
                      <eo-set-filter [operator]="'OR'" [title]="'eo.prepare.template.filter.set.title' | translate"
                        *ngIf="tagFilterOptions?.length" [options]="tagFilterOptions"></eo-set-filter>
                    </eo-custom-filter>
                  </eo-overlay>

                </div>
              </div>
              <div class="eo-body">
                <eo-grid #eoGrid *ngIf="gridOptions?.rowData" [gridOptions]="gridOptions" [fullWidth]="true"
                  [showHeader]="true" [showFooter]="false" [selectionLimit]="1"
                  (eoGridSelectionChanged)="selectTemplate($event[0])">
                </eo-grid>
              </div>
            </eo-list-container>
          </ng-template>

          <ng-template yvcSplitArea [size]="60">
            <!-- preview of selected template -->
            <eo-media [dmsObject]="pseudoDmsObject" [previewFile]="selectedTemplateFile"></eo-media>
          </ng-template>
        </yvc-split-view>

        <!-- add content general overview (upload, template, none) -->
        <ng-template #attach>

          <div class="info" *ngIf="!uploadInProgress" translate>eo.prepare.attachment.info</div>
          <input type="file" #file (change)="fileChangeListener($event.target.files)">

          <div class="attachments" *ngIf="!uploadInProgress; else uploadspinner">

            <!-- upload a file -->
            <div class="attachment att-file" *ngIf="preparePhase.data.file" (click)="upload()">
              <div class="img">
                <eo-icon [iconSrc]="'assets/_default/svg/ic_file_upload.svg'"></eo-icon>
              </div>
              <h3 translate>eo.prepare.attachment.upload.title</h3>
              <p translate>eo.prepare.attachment.upload.desc</p>
            </div>

            <!-- select a template -->
            <div class="attachment att-tmpl" *ngIf="preparePhase.data.templates.length > 0 && hasTemplateCapability()"
              (click)="showTemplateSelector(true)">
              <div class="img">
                <eo-icon [iconSrc]="'assets/_default/svg/ic_prepare_add.svg'"></eo-icon>
              </div>
              <h3 translate>eo.prepare.attachment.template.title</h3>
              <p translate>eo.prepare.attachment.template.desc</p>
            </div>

            <!-- no file -->
            <div class="attachment att-none" *ngIf="preparePhase.data.withoutFile" (click)="noFile()">
              <div class="img">
                <eo-icon [iconSrc]="'assets/_default/svg/ic_no-file.svg'"></eo-icon>
              </div>
              <h3 translate>eo.prepare.attachment.nofile.title</h3>
              <p translate>eo.prepare.attachment.nofile.desc</p>
            </div>

          </div>

        </ng-template>

        <!-- upload progress indicator -->
        <ng-template #uploadspinner>
          <div class="upload-indicator">
            <eo-loading-spinner [size]="'large'"></eo-loading-spinner>
          </div>
        </ng-template>
      </div>

      <!-- PHASE INDEXDATA -->
      <div class="phase indexdata" *ngSwitchCase="'indexdata'">

        <!-- form and preview -->
        <yvc-split-view *ngIf="preparedItem.contents; else justform" [layoutSettingsID]="'prepare.state'">
          <ng-template yvcSplitArea [size]="50">
            <div class="form-files">

              <!-- indexdata form -->
              <ng-container *ngTemplateOutlet="justform"></ng-container>

              <!-- list of contents (in case of bulk upload) that can be previewed -->
              <div class="content-select">

                <h2 translate>eo.prepare.details.contents.title</h2>
                <div class="content"
                  *ngFor="let content of preparedItem.contents; trackBy: trackByIndexFn; index as idx"
                  [ngClass]="{selected: preparePhase.data.previewIndex === idx}" (click)="setPreviewUri(idx)">
                  {{content.path}} <span *ngIf="content.existscount > 0" class="attention"
                    title="{{'eo.prepare.content.existscount.message'|translate: ({count: content.existscount})}}">!</span>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template yvcSplitArea [size]="50">
            <div class="eo-media-wrap">
              <eo-prepare-content-exists-info [content]="preparedItem.contents[preparePhase.data.previewIndex]">
              </eo-prepare-content-exists-info>
              <eo-media [dmsObject]="pseudoDmsObject" [previewFile]="preparePhase.data.previewFile"></eo-media>
            </div>
          </ng-template>
        </yvc-split-view>

        <!-- just the form -->
        <ng-template #justform>
          <div class="form-container situation-create" *ngIf="formOptions">
            <eo-object-form #form [formOptions]="formOptions" (statusChanged)="onFormStatusChanged($event)">
            </eo-object-form>
          </div>
        </ng-template>
      </div>
    </div>
  </section>

  <ng-template #spinner>
    <div class="eo-body">
      <eo-loading-spinner size="medium"></eo-loading-spinner>
    </div>
  </ng-template>

</div>

<ng-template #mainSpinner>
  <div class="prepareDetails__main-spinner">
    <eo-loading-spinner size="medium"></eo-loading-spinner>
  </div>
</ng-template>


<ng-template #noItem>
  <eo-error-message [emptyState]="{icon: emptyState.icon, text: emptyState.text, className: emptyState.className}">
    <ng-content select=".error"></ng-content>
  </eo-error-message>
</ng-template>

<eo-dialog [title]="'eo.prepare.details.delete.dialog.title' | translate" [(visible)]="showDeleteDialog"
  [focusOnShow]="false" [minWidth]="400" [styleClass]="'prepare-delete__dialog'">

  <div>{{'eo.prepare.details.delete.dialog.message' | translate}}</div>

  <div class="action-buttons prepare-delete--action-buttons flex-row">
    <button type="button" class="button cancel" (click)="showDeleteDialog = false"
      translate>eo.prepare.details.delete.dialog.cancel</button>
    <button type="button" #confirmDelete (click)="removeItem(preparedItem)" class="button primary"
      translate>eo.prepare.details.delete.dialog.ok</button>
  </div>
</eo-dialog>

<eo-dialog [title]="'eo.prepare.details.delete.content.dialog.title' | translate" [(visible)]="showContentDeleteDialog"
  [minWidth]="400" [styleClass]="'prepare-delete__dialog'">

  <div>{{'eo.prepare.details.delete.content.dialog.message' | translate: ({contentPath: preparedItem?.contents ?
    preparedItem?.contents[deletedContentIndex]?.path : ''})}}</div>

  <div class="action-buttons prepare-delete--action-buttons flex-row">
    <button type="button" class="button cancel" (click)="showContentDeleteDialog = false"
      translate>eo.prepare.details.delete.dialog.cancel</button>
    <button type="button" #confirmContentDelete (click)="removeItemContent(preparedItem, deletedContentIndex)"
      class="button primary" translate>eo.prepare.details.delete.dialog.ok</button>
  </div>
</eo-dialog>
