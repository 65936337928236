<div class="empty-container" *ngIf="emptyState">{{'eo.indexdata.summary.no.change' | translate}}</div>
<div class="indexdata-summary">

  <div class="core-section" *ngIf="coreValues?.length">

    <eo-indexdata-summary-entry *ngFor="let item of coreValues"
                                [label]="item.label"
                                [value]="item._value"
                                (onValueClicked)="onValueClick($event, item)">
    </eo-indexdata-summary-entry>

     <eo-indexdata-summary-entry *ngIf="item && item.contextFolder"
                                [label]="'eo.indexdata.summary.folder' | translate"
                                [className]="'baseparams-section'"
                                [value]="getLink(item.contextFolder.title ? item.contextFolder.title : item.contextFolder.id)"
                                (onValueClicked)="onValueClick($event, item)">
    </eo-indexdata-summary-entry>
  </div>
  <div class="data-section" *ngIf="dataValues && dataValues.length">
    <eo-simple-accordion #dataAccordion
                         [header]="('eo.indexdata.summary.more' | translate)"
                         [selected]="moreInfoAcc"
                         (selectChange)="onMoreInfoToggle($event)">
      <div class="data">

        <eo-indexdata-summary-entry *ngFor="let item of dataValues"
                                    [label]="item.label"
                                    [value]="item._value"
                                    (onValueClicked)="onValueClick($event, item)">
        </eo-indexdata-summary-entry>
      </div>
    </eo-simple-accordion>
  </div>

  <div class="baseparams-section" *ngIf="baseparams">

    <eo-indexdata-summary-entry *ngIf="baseparams.modifiedOn && !diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.modified' | translate"
                                [value]="baseparams.modifiedOn | localeDate">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="!diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.modifier' | translate"
                                [value]="baseparams.modifiedBy">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="baseparams.createdOn && !diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.created' | translate"
                                [value]="baseparams.createdOn | localeDate">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="!diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.creator' | translate"
                                [value]="baseparams.createdBy">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="!diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.object.summary.version' | translate"
                                [value]="getVersionLink(baseparams.version)"
                                (onValueClicked)="onValueClick($event, {version: baseparams.version})">
    </eo-indexdata-summary-entry>


    <eo-indexdata-summary-entry [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.filename' | translate"
                                [value]="baseparams.contentFileName">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="baseparams.contentFileSize"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.filesize' | translate"
                                [value]="diffActive ? {val1: baseparams.contentFileSize['val1'] | fileSize, val2: baseparams.contentFileSize['val2'] | fileSize} : (baseparams.contentFileSize | fileSize)">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.mimegroup' | translate"
                                [value]="baseparams.mimeGroup">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.mimetype' | translate"
                                [value]="baseparams.mimeType">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="!diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.id' | translate"
                                [value]="baseparams.id">
    </eo-indexdata-summary-entry>

    <eo-indexdata-summary-entry *ngIf="baseparams.contentId !== baseparams.id && !diffActive"
                                [className]="'baseparams-section'"
                                [label]="'eo.global.baseparam.contentId' | translate"
                                [value]="baseparams.contentId">
    </eo-indexdata-summary-entry>
  </div>

  <div class="storageinfo" *ngIf="storageInfos?.length">
    <eo-simple-accordion #storageAccordion
                         [header]="('eo.state.settings.storageinfo' | translate)"
                         [selected]="dataInfoAcc"
                         (selectChange)="onStorageInfoToggle($event)">

      <div class="storageinfo-entry baseparams-section">
        <eo-indexdata-summary-entry [className]="'storageinfo-subentry'"
                                    [label]="'eo.global.baseparam.digest' | translate"
                                    [value]="baseparams.digest">
        </eo-indexdata-summary-entry>
      </div>

      <div class="storageinfo-entry baseparams-section" *ngFor="let stInfo of storageInfos; trackBy: trackByIndex">
        <eo-indexdata-summary-entry [className]="'storageinfo-subentry'"
                                    [label]="'eo.object.storageinfo.retention' | translate"
                                    [value]="stInfo.retention | localeDate : 'eoShortDate'">
        </eo-indexdata-summary-entry>

        <eo-indexdata-summary-entry [className]="'storageinfo-subentry'"
                                    [label]="'eo.object.storageinfo.permanence' | translate"
                                    [value]="stInfo.permanence">
        </eo-indexdata-summary-entry>

        <eo-indexdata-summary-entry [className]="'storageinfo-subentry'"
                                    [label]="'eo.object.storageinfo.setname' | translate"
                                    [value]="stInfo.setname">
        </eo-indexdata-summary-entry>

        <eo-indexdata-summary-entry [className]="'storageinfo-subentry'"
                                    [label]="'eo.object.storageinfo.storagename' | translate"
                                    [value]="stInfo.storagename">
        </eo-indexdata-summary-entry>

        <eo-indexdata-summary-entry [className]="'storageinfo-subentry'"
                                    [label]="'eo.object.storageinfo.location' | translate"
                                    [value]="stInfo.location">
        </eo-indexdata-summary-entry>
      </div>
    </eo-simple-accordion>
  </div>

</div>
