<eo-dialog #dialog [title]="'eo.bar.button.file.tooltip' | translate"
           [visible]="true"
           [minWidth]="270"
           [minHeight]="580"
           [styleClass]="'add'"
           (hide)="close($event)">

  <eo-app-add-dialog (onClose)="(dialog.visible = false)"></eo-app-add-dialog>

</eo-dialog>
