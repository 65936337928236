import {GridService} from './../../../eo-framework-core/api/grid.service';
import {Component, Input, OnInit, ViewChild, HostListener} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {ReferenceService} from '../../../eo-framework-core/references/reference.service';
import {TranslateService, DmsObject, AppCacheService} from '@eo-sdk/core';
import {ColDef, GridOptions, Module} from '@ag-grid-community/core';
import {forkJoin} from 'rxjs';
import {SystemService, BackendService, Utils} from '@eo-sdk/core';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {RowGroupingModule} from '../../grid/row-grouping/row-grouping.module';

@Component({
  selector: 'eo-object-links',
  templateUrl: './object-links.component.html',
  styleUrls: ['./object-links.component.scss']
})
export class ObjectLinksComponent implements OnInit {

  public modules: Module[] = [ClientSideRowModelModule, RowGroupingModule];

  item: DmsObject;
  columnDefs: ColDef[];
  searchIcon;
  gridOptions: GridOptions;
  gridOptions2: GridOptions;
  inReferences = [];
  outReferences = [];
  queries;
  superQueryParams = {};

  showInReferences = true;
  showOutReferences = false;

  @ViewChild('gridIn') gridIn;
  @ViewChild('gridOut') gridOut;

  @Input('dmsObject')
  set dmsObject(dmsObject: DmsObject) {
    if (dmsObject) {
      this.item = dmsObject;
      this.init(dmsObject);
    }
  }

  @HostListener('click', ['$event']) onClick(event) {
    this.gridApi.openRouterLink(event, 'ag-row');
  }

  constructor(private router: Router,
    private referenceService: ReferenceService,
    private backend: BackendService,
    private cache: AppCacheService,
    private translate: TranslateService,
    private gridApi: GridService,
    private systemService: SystemService) {

    this.prepareGrid();
  }

  private prepareGrid() {
    this.columnDefs = [
      {
        field: 'metaData.groupField',
        rowGroupIndex: 0,
        sort: 'asc',
        comparator: (a, b, nodeA, nodeB) => {
          const label = (val: string, node: any) => val || Utils.getProperty(node, 'allLeafChildren.0.data.metaData.objectType.label') || '';
          return label(a, nodeA).localeCompare(label(b, nodeB));
        },
        hide: true
      },
      {
        field: 'title',
        sort: 'asc',
        cellClass: 'router-link-cell',
        cellRenderer: this.referenceCellRenderer.bind(this)
      }
    ];

    const options: GridOptions = {
      groupRowInnerRenderer: this.typeCellRenderer.bind(this),
      columnDefs: this.columnDefs,
      headerHeight: 0,
      rowHeight: 60,
      rowBuffer: 20,
      multiSortKey: 'ctrl',
      rowSelection: 'single',
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      accentedSort: true,
      suppressContextMenu: true
    };

    this.gridOptions = {
      ...options,
      context: {
        count: 0,
        gridName: 'inReferences'
      }
    };

    this.gridOptions2 = {
      ...options,
      context: {
        count: 0,
        gridName: 'outReferences'
      }
    };

  }

  private referenceCellRenderer(param) {
    const uriParamQuery: NavigationExtras = {queryParams: {'type': param.data.metaData.objectType.qname}};
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/object', param.data.id], uriParamQuery));
    const url = this.router.createUrlTree(['object', param.data.id], uriParamQuery).toString().replace(/^\/+/g, '');
    if (param.data) {
      return `
        <div class="ref-list-item">
          <a class="router-link" href="${url}" target="_blank" onclick="return false;">
            <div class="content">
              <div class="title">${param.data.title}</div>
              <div class="description">${param.data.description}</div>
            </div>
          </a>
        </div>`;
    } else {
      return '';
    }
  }

  private typeCellRenderer(param) {
    let val = '';
    if (param.value) {
      const gridName = param.api.gridCore.gridOptions.context.gridName;
      const remoteElement = param.node.allLeafChildren[0].data.metaData.remoteElement;
      const link = this.generateLinkUrl(param.node.allLeafChildren[0].data.metaData.objectType, remoteElement, gridName);
      const iconUrl = `${this.backend.getBaseWithContext(this.backend.getServiceBase())}/ui/icon/${param.node.allLeafChildren[0].data.metaData.objectType.iconId}.svg`;
      const label = param.node.allLeafChildren[0].data.metaData.objectType.label;
      const tooltip = this.translate.instant('eo.references.open.resultlist');
      const description = remoteElement ? remoteElement.label : '';

      val = `<img class="object-type" src="${iconUrl}" title="${label}">
            <div class="content">
              <div class="title">${label}</div>
              <div class="description">${description}</div>
            </div>
            <a class="router-link search-link" title="${tooltip}" href="${link}" target="_blank" onclick="return false;">${this.searchIcon}</a>`;
    }
    return val;
  }

  private generateLinkUrl(objectType, remoteElement, gridName) {
    let linkUrl;
    let query;
    if (gridName === 'outReferences') {
      if (remoteElement) {
        const queries = this.queries.outReferenceQueries.filter((q) => q.types[0] === objectType.qname && !!q.filters && !!q.filters[remoteElement.qname]);
        query = this.createSuperQueryFromQueries({outReferenceQueries: queries, inReferenceQueries: []});
        query.types = queries[0].types;
      } else {
        // if there is no remoteElement, than we want to find the query without any filters
        const queries = this.queries.outReferenceQueries.filter((q) => q.types[0] === objectType.qname && !q.filters);
        // there can be more than one field, that references to the same object type, that's why it's necessary to merge the terms.
        const mergedTerm = queries.map(q => q.term).join(' || ');
        query = queries[0];
        query.term = mergedTerm;
      }
    } else {
      query = this.queries.inReferenceQueries.find((q) => q.types[0] === objectType.qname && !!q.filters[remoteElement.qname]);
    }
    linkUrl = `result?query=${encodeURIComponent(JSON.stringify(query))}`;
    return linkUrl;
  }

  switchListVisibilityTo(list: 'in' | 'out'){
    if(list === 'in'){
      this.showInReferences = true;
      this.showOutReferences = false;
    }else{
      this.showInReferences = false;
      this.showOutReferences = true;
    }
    this.cache.setItem('yuv.reference.list.show.' + this.item.typeName, list);
  }

  private init(item) {
    this.cache.getItem('yuv.reference.list.show.' + item.typeName).subscribe(res => this.switchListVisibilityTo(res));
    const obs = [
      this.referenceService.getInReferences(item),
      this.referenceService.getOutReferences(item)
    ];
    forkJoin(obs)
      .subscribe(results => {
        [this.inReferences, this.outReferences] = results;

        if (this.inReferences.length > 20) {
          this.gridOptions.groupDefaultExpanded = 0;
        }
        if (this.outReferences.length > 20) {
          this.gridOptions2.groupDefaultExpanded = 0;
        }

        if (this.gridIn && this.inReferences.length) {
          this.gridOptions.api.setRowData(this.inReferences);
        } else {
          this.gridOptions.rowData = this.inReferences;
          this.gridOptions.context.count = this.inReferences.length;
        }

        if (this.gridOut && this.outReferences.length) {
          this.gridOptions2.api.setRowData(this.outReferences);
        } else {
          this.gridOptions2.rowData = this.outReferences;
          this.gridOptions2.context.count = this.outReferences.length;
        }
      }, (error) => console.error({error}));

    this.referenceService
      .getQueries(item)
      .subscribe(queries => {
        this.queries = queries;
        const superQuery = this.createSuperQueryFromQueries(this.queries);
        this.superQueryParams = {'query': encodeURIComponent(JSON.stringify(superQuery)), 'silent': true};
      });
  }

  private createSuperQueryFromQueries(queries) {
    let superQuery = {
      term: '',
      options: {
        expertmode: true
      }
    };

    queries.outReferenceQueries.forEach(query => {
      if (query.filters) {
        Object.keys(query.filters)
          .forEach(filterName => superQuery.term += this.createSuperQueryTermPart(query, filterName));
      } else {
        superQuery.term += '(' + query.term + ')' + ' OR ';
      }
    });

    queries.inReferenceQueries.forEach((query) => {
      if (query.filters) {
        Object.keys(query.filters)
          .forEach(filterName => superQuery.term += this.createSuperQueryTermPart(query, filterName));
      } else {
        superQuery.term += query.term;
      }
    });

    superQuery.term = superQuery.term.replace(new RegExp(',', 'g'), ' '); // replace all ',' with ' '
    superQuery.term = superQuery.term.replace(new RegExp(' OR ' + '$'), ''); // remove last ' OR '

    return superQuery;
  }

  private createSuperQueryTermPart(query, filterName) {
    // reserved characters of elastic search
    // todo: add '||'
    const reservedChars = ['\\', '/', '\+', '-', '=', '&&', '>', '<', '!', '\(', '\)', '\{', '\}', '\[', '\]', '\^', '"', '\~', '\*', '\?', ':'];

    const filter = query.filters[filterName];
    let value = '' + filter.v1 + '';
    reservedChars.forEach(char => {
      if (value.indexOf(char) > -1) {
        value = value.replace(new RegExp('\\' + char, 'g'), '\\' + char); // escapes all reserved characters
      }
    });
    let types = this.getSubTypes(query.types[0]);
    types.push(query.types[0]);
    let indexName = this.getIndexNameByQName(filterName);
    if (!indexName) {
      indexName = 'str_' + filterName.replace('.', '_');
    }
    return '(' + indexName + ':(' + value + ') AND key_type:(' + types + ')) OR ';
  }

  private getIndexNameByQName(qname: string): string {
    const types = this.systemService.getObjectTypes();
    for (let i = 0; i < types.length; i++) {
      const element = types[i].elements.find(el => el.qname === qname);
      if (element) {
        return element.indexname;
      }
    }
    return null;
  }

  private getSubTypes(typeName) {
    let types = this.systemService.getObjectTypes();
    let subTypes = [];
    types.forEach(objectType => {
      const validator = !!objectType.supertypes.find((t: any) => t.name === typeName);
      if (validator) {
        subTypes.push(objectType.qname);
      }
    }
    );
    return subTypes;
  }

  ngOnInit() {
    this.backend
      .getViaCache('assets/_default/svg/ic_search.svg')
      .subscribe(response => this.searchIcon = response.replace('<svg', '<svg focusable="false" class="search-icon"'));
  }
}
