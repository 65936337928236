<div class="loader-overlay__mask" *ngIf="showLoader">
  <eo-loading-spinner class="result-list__loader" [size]="'medium'"></eo-loading-spinner>
</div>
<eo-column-configurator *ngIf="visibleConfig" [visible]="visibleConfig"
                        [configType]="configType" [configContext]="configContext" [eoGrid]="grid"
                        (onConfigChanged)="visibleConfig = false; onConfigChanged($event)" ></eo-column-configurator>

<eo-list-container #eoList [applySelection]="{}" [loading]="false" [parseDmsParams]="parseDmsParams">
  <div class="eo-header">
    <eo-icon class="eo-header-icon" *ngIf="hasIcon" [iconSrc]="'assets/_default/svg/ic_search.svg'"></eo-icon>
    <div class="eo-header-info">
      <div class="eo-header-title">{{title || ('eo.search.title' | translate)}}</div>
      <div class="eo-header-subtitle">
        <span *ngIf="!storedQuery; else storedQueryText" class="eo-header-subtitle__label" [title]="querySubtitle">{{querySubtitle}}</span>
        <ng-template #storedQueryText>
          <ng-container *ngIf="storedQuery.parameter.length === 0; else storedQueryDialog">
            <span class="eo-header-subtitle__label" [title]="('eo.search.aside.summary.storedquery' | translate) + ': ' + storedQuery.name">
              {{'eo.search.aside.summary.storedquery' | translate}}: {{storedQuery.name}}
            </span>
          </ng-container>
        </ng-template>
        <ng-template #storedQueryDialog>
          <div class="stored-query">
            <span>
              {{'eo.search.aside.summary.storedquery' | translate}}:
            </span>
            <button class="sq" (click)="savedSearchClicked = true" (keyup.enter)="savedSearchClicked = true" tabindex="0">
              <eo-icon [iconSrc]="'assets/_default/svg/ic_stored_query.svg'"></eo-icon>
              <span>{{storedQuery.name}}</span>
            </button>
          </div>
        </ng-template>
        <span *ngIf="query?.contextFolderTypes.length === 1" translate
              [translateParams]="{type: query.contextFolderTypes[0].label}">eo.search.objecttype.form.context</span>
        <span class="term" *ngIf="!storedQuery && query?.term && !clearAfterExecute" [title]="('eo.search.result.term' | translate) + ': ' + query.term">{{'eo.search.result.term' | translate}} : "{{query.term}}"</span>
        <div class="suggest" *ngIf="searchResult?.suggests">{{'eo.search.suggest' | translate}}<a href="javascript:void(0)" (click)="executeSuggestSearch()">{{searchResult.suggests[0]}}</a> ?
        </div>
      </div>
    </div>
    <div class="eo-header-actions">
      <eo-icon class="button refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'" (click)="refreshGrid()"
               [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>
      <eo-icon class="button export-button" [hidden]="!query" [iconSrc]="'assets/_default/svg/ic_content-download.svg'" [iconTitle]="('eo.resultlist.export.csv' | translate)"
               (click)="exportCSV()" *ngIf="!csvLoadingSpinnerVisibility"></eo-icon>
      <eo-loading-spinner [size]="'small'" *ngIf="csvLoadingSpinnerVisibility" class="spinner"></eo-loading-spinner>
      <eo-icon class="button config-button" [iconSrc]="'assets/_default/svg/ic_settings.svg'" [iconTitle]="('eo.column.config.title' | translate)"
               (click)="visibleConfig = !visibleConfig"></eo-icon>
      <eo-icon class="button actions-button" (click)="eoList.showActions()" [iconTitle]="('eo.object.actions.title' | translate)"
               [iconSrc]="'assets/_default/svg/ic_more.svg'" *ngIf="!reference"></eo-icon>
    </div>
  </div>
  <div class="eo-body">
    <eo-grid #eoGrid [gridOptions]="resultGridOptions" *ngIf="hasGridOptions; else error"
             [showFooter]="true" (eoGridColumnResized)="onColumnResized($event)" [selectFirst]="selectFirst"
             (eoGridCountChanged)="eoList.onCountChanged($event);onCountChanged.emit($event);"
             (eoGridContextMenuClick)="eoList.onContextMenu($event);onContextMenu.emit($event)"
             (eoGridSelectionChanged)="eoList.onSelectionChanged($event);onSelectionChanged.emit($event)"
             (eoGridDoubleClick)="eoList.onDoubleClick($event);onDoubleClick.emit($event)"
             (eoGridFocusChanged)="eoList.onFocusChanged($event);onFocusChanged.emit($event)">

      <div class="footer">
        <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
        <!--<eo-quick-filter [eoGrid]="eoGrid"></eo-quick-filter>-->
        <eo-pagination [settings]="settings" (pageChanged)="onPageChanged($event)"></eo-pagination>
      </div>

      <div class="empty">
        <eo-error-message [emptyState]="{text: emptyMessage || 'eo.search.result.expired'}"></eo-error-message>
      </div>
    </eo-grid>
  </div>
  <ng-template #error>

    <eo-error-message *ngIf="hasGridOptions" [emptyState]="{text:'eo.search.mode.expert.result.empty'}">
    </eo-error-message>

  </ng-template>
</eo-list-container>

<eo-dialog [visible]="savedSearchClicked" (hide)="savedSearchClicked = false" [title]="'eo.search.aside.summary.storedquery' | translate">
  <eo-stored-query [query]="storedQuery"
                   (onQueryLoaded)="onStoredQueryLoaded()"
                   (onQueryExecute)="onStoredQueryExecute($event)"></eo-stored-query>
</eo-dialog>

<eo-dialog [title]="'eo.resultlist.export.csv.threshold.dialog.title' | translate"
           [(visible)]="showWarnExportCSVThresholdDialog" [focusOnShow]="false" [minWidth]="400" [styleClass]="'threshold__dialog'">

  <div>{{'eo.resultlist.export.csv.threshold.dialog.message' | translate}}</div>

  <div class="action-buttons flex-row">
    <button type="button" class="button cancel" (click)="showWarnExportCSVThresholdDialog = false"
            translate>eo.resultlist.export.csv.threshold.dialog.cancel</button>
    <button type="button" cdkFocusInitial (click)="exportCSV(true)" class="button primary"
            translate>eo.resultlist.export.csv.threshold.dialog.export</button>
  </div>
</eo-dialog>

