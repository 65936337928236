<div class="object-form-edit" [ngClass]="{invalid: formState?.invalid === true}" [eoShortcuts]="shortcuts">
  <eo-object-form [formOptions]="formOptions" *ngIf="formOptions"
                  (statusChanged)="onFormStatusChanged($event)"></eo-object-form>

  <div class="form-controls" *ngIf="controls.visible">

    <div class="err-msg" *ngIf="formState?.invalid" translate>eo.object.indexdata.form.invalid</div>

    <div class="buttons">

      <button class="secondary" (click)="reset()" [disabled]="controls.saving" translate>eo.object.indexdata.reset</button>
      <button class="primary" [ngClass]="{spinner: controls.saving}"
              [disabled]="formState?.invalid || controls.saving"
              (click)="save()" translate>eo.object.indexdata.save</button>
    </div>
  </div>
</div>

<eo-dialog [title]="('eo.object.indexdata.save.conflict.dialog.title' | translate : {modifier: conflictError?.dmsobject.modified.by.firstname + ' ' + conflictError?.dmsobject.modified.by.lastname})"
  (hide)="controls.saving = false;" [(visible)]="showConflictDialog" [minWidth]="400" [styleClass]="'conflict__dialog'">

  <div class="action-buttons conflict--action-buttons flex-row">
    <button type="button" class="button cancel" (click)="cancelSaving()"
      translate>eo.object.indexdata.save.cancel</button>
    <button type="button" class="button cancel" (click)="showConflictChanges()"
      translate>eo.object.indexdata.save.changes</button>
    <button type="button" (click)="save(true); closeConflictDialog();" class="button primary"
      translate>eo.object.indexdata.save</button>
  </div>
</eo-dialog>
