<div class="eo-checkout">

  <h2>{{'eo.checkout.message'|translate}}</h2>

  <div>
    <eo-form-element [element]="checkout"></eo-form-element>
    <div class="form-buttons">
      <button class="secondary" (click)="cancel()">{{'eo.checkout.cancel'|translate}}</button>
      <button class="primary" (click)="edit(true)">{{'eo.checkout.yes'|translate}}</button>
      <button class="primary" (click)="edit(false)">{{'eo.checkout.no'|translate}}</button>
    </div>
  </div>
</div>

