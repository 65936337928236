import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@eo-sdk/core';
import {ActionComponent} from '../../interfaces/action-component.interface';
import {AgentService} from '../../../../eo-framework-core/agent/agent.service';
import {ObjectFormHelperService} from '../../../object-form/object-form-helper.service';
import {UserService} from '@eo-sdk/core';

@Component({
  selector: 'eo-open-document',
  templateUrl: './open-document-action.component.html',
  styleUrls: ['./open-document-action.component.scss']
})
export class OpenDocumentComponent implements OnInit, ActionComponent {

  checkout: any;

  @Input() selection: any[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private userService: UserService, private agentService: AgentService, private translate: TranslateService, private objectFormHelperService: ObjectFormHelperService) {
    const checkoutLabel = translate.instant('eo.checkout.save');
    this.checkout = objectFormHelperService.elementToFormControl({
      label: checkoutLabel,
      name: 'checkbox',
      type: 'BOOLEAN',
      required: true,
      classic: true,
      value: false
    });
  }

  ngOnInit() {
    let agentAlwayslock = this.userService.getCurrentUser().userSettings.alwayslock;
    if ((agentAlwayslock && agentAlwayslock === 'never') || this.selection[0].lock) {
      this.agentService.openDocument(this.selection[0], false);
      setTimeout(() => {
        this.finished.emit();
      }, 0);
    } else if (agentAlwayslock && agentAlwayslock === 'always') {
      this.agentService.openDocument(this.selection[0], true);
      setTimeout(() => {
        this.finished.emit();
      }, 0);
    }
  }

  edit(lock) {
    if (this.checkout.controls.checkbox.value) {
      if (lock) {
        this.userService.changeLockSetting('always').subscribe();
      } else {
        this.userService.changeLockSetting('never').subscribe();
      }
    }
    this.agentService.openDocument(this.selection[0], lock);
    this.finished.emit();
  }

  cancel() {
    this.finished.emit();
  }

}
