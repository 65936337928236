import {UntypedFormControl} from '@angular/forms';

// extensions of the default angular form control

export class ObjectFormControl extends UntypedFormControl {
  private __eoFormElement;

  public set _eoFormElement(v) {
    this.__eoFormElement = v;
  };

  public get _eoFormElement() {
    return this.__eoFormElement;
  }
}
