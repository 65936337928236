import {PendingChangesService} from './../../../../../eo-framework-core/pending-changes/pending-changes.service';
import {mergeMap} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {EoLocation, LocationService} from '../../../../../eo-framework-core/location/location.service';
import {ObjectType, Utils, PrepareService, SystemService} from '@eo-sdk/core';
import {TranslateService} from '@eo-sdk/core';

@Component({
  selector: 'eo-app-add-dialog',
  templateUrl: './app-add-dialog.component.html',
  styleUrls: ['./app-add-dialog.component.scss']
})
export class AppAddDialogComponent implements OnInit, OnDestroy {

  @Output() onClose = new EventEmitter();

  rootTypes: ObjectType[] = [];
  locationTypes: ObjectType[] = [];
  locationParent: any;
  private activeLocationSubscription: Subscription;
  subfolder: any;
  subFolderDescription: string;
  autocompleteList: any;
  autocompleteRes: any;
  selectedNode: any;

  constructor(private prepareService: PrepareService,
              private router: Router,
              private systemService: SystemService,
              private translate: TranslateService,
              private pendingChanges: PendingChangesService,
              private locationService: LocationService) {
  }

  public prepare(type: ObjectType, isRootType = false) {
    if (this.pendingChanges.check()) {
      return;
    }
    let pid = null;
    if (!isRootType && this.locationParent) {
      pid = this.locationParent.id;
    }

    this.prepareService
      .createPreparedItem(pid, type.name)
      .subscribe(() => {
          this.router.navigateByUrl('/prepare');
        }, Utils.throw(null, this.translate.instant('eo.add.prepare.fail.title'), this.translate.instant('eo.add.prepare.fail.msg'))
      );
    this.onClose.emit();
  }

  public autocompleteFn(term: string){
    this.autocompleteRes = this.autocompleteList.filter(type => {
      return type.label.toLowerCase().indexOf(term.toLowerCase()) != -1;
    })
  }

  onSelect(node) {
    if(node.isSubfolder){
      this.addToSubfolder();
    } else {
      this.prepare(node.type, node.isRootType);
    }
  }

  preparedItemData(payload) {
    const typeElement = this.systemService
      .getObjectType(this.subfolder.type)
      .elements
      .filter(val => this.subfolder.data ? val.name === Object.keys(this.subfolder.data)[0] : null);

    if (typeElement.length && typeElement[0].multiselect) {
      Object.entries(payload).map(([key, value]) => payload[key] = [value]);
    }
    return payload;
  }

  addToSubfolder() {
    if (this.pendingChanges.check()) {
      return;
    }
    let pid = this.locationParent.id;
    const {data, type} = this.subfolder;
    const payload = this.preparedItemData(data);

    this.prepareService
      .createPreparedItem(pid, type ? type : null)
      .pipe(
        mergeMap(res => this.prepareService.updatePreparedItemIndexData(res.id, payload))
      )
      .subscribe(() => {
        this.router.navigateByUrl('/prepare');
      }, Utils.throw(null, this.translate.instant('eo.add.prepare.fail.title'), this.translate.instant('eo.add.prepare.fail.msg')));
    this.onClose.emit();
  }

  private fetchRootTypes() {
    this.prepareService
      .getPrepareRootTypes()
      .subscribe((types) => {
        this.rootTypes = this.sortTypes(types);
        this.createAutocompleteList();
      });
  }

  private createAutocompleteList() {
    this.autocompleteList = this.rootTypes.map(type => {
      return {
        label: type.label,
        location: this.translate.instant('eo.add.title.location.common'),
        isRootType: true,
        isSubfolder: false,
        type
      };
    }).concat(this.locationTypes.map(type => {
      return {
        label: type.label,
        location: `${this.translate.instant('eo.add.title.location')} ${this.locationParent.title}`,
        isRootType: false,
        isSubfolder: false,
        type
      };
    }));
    if(this.subfolder) {
      this.autocompleteList.push({
        label: this.subfolder.title,
        location: `${this.translate.instant('eo.add.title.location')} ${this.locationParent.title}`,
        isRootType: false,
        isSubfolder: true,
      });
    }
  }

  private sortTypes(types) {
    return types.sort((a, b) => {

      if (a.isContextFolder && !b.isContextFolder) {
        return -1;
      }
      if (!a.isContextFolder && b.isContextFolder) {
        return 1;
      }
      return Utils.sortValues('label').call(this, a, b);
    });
  }

  private fetchAllowedChildTypes(loc: EoLocation) {
    this.prepareService
      .getPrepareChildTypes(loc.id)
      .subscribe((res) => {
        const {types, parent} = res;
        this.locationParent = Utils.truncateString(parent, 30);
        this.locationTypes = this.sortTypes(types);
        this.locationParent.title = Utils.truncateString(parent['title'], 30);
        if (loc.subFolder && this.isSubFolderAllowed(loc.subFolder, this.locationTypes)) {
          this.subfolder = loc.subFolder;
          this.subFolderDescription = loc.title + '; ' + this.subfolder.title;
        }
        this.createAutocompleteList();
      });
  }

  isSubFolderAllowed(subFolder: any, locationTypes: ObjectType[]) {
    return !!locationTypes.find(lt => this.systemService.implementsType(lt, subFolder.type));
  }

  ngOnInit() {
    this.fetchRootTypes();
    this.activeLocationSubscription = this.locationService.activeLocation$
      .subscribe((loc: EoLocation) => {
        if (loc) {
          this.fetchAllowedChildTypes(loc);
        } else {
          this.locationTypes = [];
          this.locationParent = null;
        }
      });
  }

  ngOnDestroy() {
    if (this.activeLocationSubscription) {
      this.activeLocationSubscription.unsubscribe();
    }
  }

  public trackByFn(index, item) {
    return index;
  }
}
