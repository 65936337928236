import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {GridComponent} from './../grid.component';
import {combineLatest} from 'rxjs/operators';
import {Component, EventEmitter, HostBinding, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@eo-sdk/core';
import {UserService, SearchService, EoUser, FieldDefinition, SearchQuery, ObjectType, Utils} from '@eo-sdk/core';
import {GridService} from '../../../eo-framework-core/api/grid.service';

@Component({
  selector: 'eo-column-configurator',
  templateUrl: './column-configurator.component.html',
  styleUrls: ['./column-configurator.component.scss']
})
export class ColumnConfiguratorComponent implements OnInit, OnChanges {
  isDefaultConfig = true;
  hasPrivilege = false;
  info: string;
  @Input() configType: ObjectType | null;
  @Input() configContext: ObjectType | null;
  currentFields: any[];
  availableFields: any[];

  @Output() onConfigChanged = new EventEmitter();

  @HostBinding('class.visible')
  @Input() visible = false;

  @Input() eoGrid: GridComponent;
  private originalFields: any[];
  alignmentValue = 'Automatic';

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    public translate: TranslateService) {

    this.userService
      .user$
      .subscribe((user: EoUser) => {
        this.hasPrivilege = user.hasPrivilege('EDIT_PROFILE_SET');
      });
  }

  // reset a column configuration to its default
  resetDefault() {
    //this.initConfig(true);
    this.searchService.removeResultFieldDefinition(this.configType, this.configContext).subscribe(() => this.toggleConfigMode(true));

  }


  /**
   * save the currentFields configuration
   *
   * @param boolean asDefault
   */
  saveConfig(asDefault = false): void {
    let data = new FieldDefinition([], [], [], [], []);
    data.elements = this.currentFields.map(col => {
      if (col.confmeta.sort) {
        data.sortorder.push({
          qname: col.qname,
          direction: col.confmeta.sort
        });
      }
      if (col.confmeta.group) {
        data.grouporder.push({
          qname: col.qname
        });
      }
      if (col.confmeta.pinned) {
        data.pinned.push({
          qname: col.qname
        });
      }
      if (col.confmeta.alignmentx) {
        data.alignmentx.push({
          qname: col.qname,
          value: col.confmeta.alignmentx
        });
      }

      return {
        qname: col.qname
      };
    });

    this.searchService
      .saveResultFieldDefinition(this.configType, this.configContext, data, asDefault && this.hasPrivilege)
      .subscribe(() => this.toggleConfigMode(true));
  }

  /**
   * Initialize the lists columns for the currentFields config.
   *
   * @param loadDefaults - if set to true, the configs default configuration will be loaded
   * instead of the one that was configured by the user.
   */
  initConfig(loadDefaults) {

    this.createConfigInfo(this.configType, this.configContext);

    this.searchService.fetchResultFieldDefinition(this.configType, this.configContext, loadDefaults ? 'DEFAULT' : null).pipe(combineLatest(
      this.searchService.fetchResultFieldDefinition(this.configType, this.configContext, 'ALL'),
      (current: FieldDefinition, available: FieldDefinition) => {
        return {current, available};
      })).subscribe((res: any) => {
        res.current.elements.forEach((el: any) => {
          let sortEntry = res.current.sortorder.find((a: any) => el.qname === a.qname);
          // let groupEntry = res.current.grouporder.find((a: any) => el.qname === a.qname);
          let pinnedEntry = res.current.pinned.find((a: any) => el.qname === a.qname);
          let alignmentEntry = res.current.alignmentx.find((a: any) => el.qname === a.qname);
          el.confmeta = {
            sort: sortEntry ? (sortEntry.direction || '').toLowerCase() : null,
            // group: !!groupEntry,
            pinned: !!pinnedEntry,
            modified: false,
            alignmentx: alignmentEntry ? alignmentEntry.value : null
          };
        });

        this.isDefaultConfig = res.current.mode !== 'USER';
        this.originalFields = res.current.elements;

        this.currentFields = this.eoGrid.columns.filter(column => column.isVisible()).map(({colDef, sort, pinned}: any, index) => {
          const i = this.originalFields.findIndex(f => GridService.qnameMatch(f.qname, colDef.refData.qname)), field = this.originalFields[i];
          return {...field, confmeta: {sort, pinned, alignmentx: field.confmeta.alignmentx,
              modified: i !== index || (field.confmeta.sort || '') !== (sort || '') || !!field.confmeta.alignmentx}};
        });

        this.availableFields = res.available.elements.filter((col: any) => {
          return !this.currentFields.find((c: any) => c.qname === col.qname);
        }).map(el => {
          el.confmeta = {modified: !!this.originalFields.find((c: any) => c.qname === el.qname)};
          return el;
        }).sort(Utils.sortValues('label'));
      }
    );
  }

  /**
   * Creates the info object that will be displayed to the user indicating what particular result list
   * is going to be configured. If none of the params is provided, we got a general fulltext result.
   *
   * @param [type] - the configurations target object type
   * @param [context] - the context object type
   */
  createConfigInfo(type, context) {
    if (type && type.label && context) {
      this.translate.get('eo.column.config.info.type.context', {
        type: type.label,
        context: context.label
      }).subscribe(text => this.info = text);
    } else if (type && type.label) {
      this.translate.get('eo.column.config.info.type', {
        type: type.label
      }).subscribe(text => this.info = text);
    } else {
      this.translate.get('eo.column.config.info.fulltext').subscribe(text => this.info = text);
    }
  }

  markItem(field, group) {
    if (field && group) {
      field.confmeta.modified = true;
    }
  }

  drop(event: CdkDragDrop<any>, group: 'current' | 'available') {
    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    this.markItem(event.item.data, group);
  }

  toggleConfigMode(refresh = false) {
    this.onConfigChanged.emit(refresh);
  }

  togglePrevent(item, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.markItem(item, 'current');
  }

  toggleSort(item, $event) {
    this.togglePrevent(item, $event);
    if (!item.confmeta.sort) {
      item.confmeta.sort = 'asc';
    } else if (item.confmeta.sort === 'asc') {
      item.confmeta.sort = 'desc';
    } else if (item.confmeta.sort === 'desc') {
      item.confmeta.sort = null;
    }
  }

  toggleAlignment(item, $event) {
    this.togglePrevent(item, $event);
    if (!item.confmeta.alignmentx) {
      item.confmeta.alignmentx = 'right';
    } else if (item.confmeta.alignmentx === 'right') {
      item.confmeta.alignmentx = 'center';
    } else if (item.confmeta.alignmentx === 'center') {
      item.confmeta.alignmentx = 'left';
    } else if (item.confmeta.alignmentx === 'left') {
      item.confmeta.alignmentx = null;
    }
  }

  // toggleGroup(item, $event) {
  //   this.togglePrevent(item, $event);
  //   item.confmeta.group = !item.confmeta.group;
  // }

  togglePinned(item, $event) {
    this.togglePrevent(item, $event);
    item.confmeta.pinned = !item.confmeta.pinned;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.visible && (changes.configType || changes.configContext)) {
      this.initConfig(false);
    }
  }

}
