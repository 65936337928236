export interface SigningPayload {
  documentName: string;
  emailSubject: string;
  signers: Signer[];
}

export interface Signer {
  name: string;
  email: string;
  phone: string;
}

export enum ChangeMode {
  EDIT = 'edit',
  DELETE = 'delete',
  INSERT = 'insert',
  CREATE = 'create'
}

export interface ChangeOutput {
  index: number;
  mode: ChangeMode;
}
