<header class="eo-head">
  <div class="eo-header dark">
    <div class="eo-header-info eo-header__content">
      <!-- <h2 translate>eo.state.object.frontpage.title</h2> -->
      <h2 class="eo-header-title">{{ title }}</h2>
      <h3 class="eo-header-subtitle flex-row">{{context.title}}</h3>
      <div class="sizing" *ngIf="frontPageData">
        <div translate [translateParams]="{doccount: frontPageData?.documentCount.value}">eo.state.object.frontpage.doccount</div>
        <div translate [translateParams]="{filesize: frontPageData?.totalFilesize | fileSize}">eo.state.object.frontpage.filesize</div>
      </div>
    </div>
    <div class="eo-header-actions"></div>
  </div>
</header>

<yvc-tabs (tabChange)="onTabChange.emit($event)" layoutSettingsID="eo.object.state.details.tabs">
  <ng-template [yvcTab]="{id: 'last-changes', label: 'eo.state.object.frontpage.lastchanges' | translate}">
    <div class="panel last-changes">
        <div class="doc" *ngFor="let doc of frontPageData?.lastModifications"
          [ngClass]="{selected: selectedItem && doc.id === selectedItem.id}" (click)="selectFrontPageDoc(doc)"
          (contextmenu)="onContextMenuClicked($event, doc)">
          <div class="marker"></div>
          <div class="content">
            <div class="date">{{doc.modified | localeDate}}</div>
            <strong>{{doc.title}}</strong>
            <div class="desc">{{doc.description}}</div>
          </div>
        </div>
      </div>
  </ng-template>

  <ng-template [yvcTab]="{id: 'last-seen', label: 'eo.state.object.frontpage.lastseen' | translate}">
   <div class="panel last-seen">
        <div class="doc" *ngFor="let doc of seenItems"
          [ngClass]="{selected: selectedItem && doc.id === selectedItem.id}" (click)="selectFrontPageDoc(doc)"
          (contextmenu)="onContextMenuClicked($event, doc)">
          <div class="marker"></div>
          <div class="content">
            <div class="date">{{doc.modified.on | localeDate}}</div>
            <strong>{{doc.title}}</strong>
            <div class="desc">{{doc.description}}</div>
          </div>
        </div>
    </div> 
  </ng-template>

  <!-- plugin tabs -->
  <ng-template *ngFor="let p of plugins" [yvcTab]="{id: p.id, label: p.id | translate}">
    <eo-plugin [id]="p.id" [parent]="this"></eo-plugin>
  </ng-template>
  
</yvc-tabs>