<eo-icon class="button favorite"
         [ngClass]="{active: item.isFavorite}"
         [iconTitle]="tooltip"
         [iconSrc]="'assets/_default/svg/ic_favorite.svg'"
         (click)="toggle($event)">
</eo-icon>

<eo-dialog
  [title]="('eo.favorite.icon.dialog.title' | translate)"
  [(visible)]="display"
  [minWidth]="400"
  [styleClass]="'favorite-form__dialog'">

  <form name="favoriteForm" (ngSubmit)="save()">
    <eo-form-input [label]=" 'eo.favorite.icon.dialog.input.label' | translate">
      <input [(ngModel)]="value" maxlength="250" [ngModelOptions]="{standalone: true}" #favName />
    </eo-form-input>
    <div class="action-buttons favorite-form__dialog--action-buttons flex-row">
      <button type="button" class="button cancel" (click)="toggle($event, true)" translate>eo.favorite.icon.dialog.cancel</button>
      <button type="submit" [disabled]="!value" class="button primary" translate>eo.favorite.icon.dialog.save</button>
    </div>
  </form>
</eo-dialog>
