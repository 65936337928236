import { Injectable } from '@angular/core';

@Injectable()
export class ContentPreviewService {
  static UNDOCK_WINDOW_NAME = 'eoViewer';

  static undockWin(src: string) {
    const w = window[ContentPreviewService.UNDOCK_WINDOW_NAME] = window.open(
      src || '',
      ContentPreviewService.UNDOCK_WINDOW_NAME,
      'directories=0, titlebar=0, toolbar=0, location=0, status=0, menubar=0, resizable=1, top=10, left=10'
    );
    return w;
  }

  static closeWin() {
    return this.getUndockWin() && this.getUndockWin().close();
  }

  static getUndockWin(): Window {
    return window[ContentPreviewService.UNDOCK_WINDOW_NAME];
  }

  static undockWinActive(): boolean {
    return !!ContentPreviewService.getUndockWin() && !ContentPreviewService.getUndockWin().closed;
  }

  static mapLang(lang: string) {
    switch (lang) {
      case 'en':
        return 'en-US';
      case 'es':
        return 'es-ES';
      case 'pt':
        return 'pt-PT';
      case 'zh':
        return 'zh-CN';
      case 'hi':
        return 'hi-IN';
      case 'bn':
        return 'bn-BD';
      default:
        return lang;
    }
  }
}
