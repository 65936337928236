 <div class="process-files" *ngIf="processFile.length; else noFile">


  <!-- section for adding new items to the work items attachments -->
  <div class="content-paste"
       *ngIf="permissions.add && clipboard?.elements.length && !hasAlreadyAllCopiedFiles()"
       (click)="addFromClipboard()"
       [ngClass]="{disabled: isDisabled}"
  >
    <eo-icon [iconSrc]="'assets/_default/svg/ic_content_paste.svg'"></eo-icon>
    <span class="label ct" translate>eo.process.file.add.label</span>
  </div>

  <!-- list of files attached to the work item -->
  <div class="file-item"
       *ngFor="let file of processFile; trackBy: trackByIndex"
       [ngClass]="{selected: file.id === selectedContentFileId}"
       (dblclick)="openWorkItemContentInContext(file, $event)"
       (click)="openWorkItemContent(file)">

    <eo-icon [iconSrc]="'assets/_default/svg/ic_attachment.svg'"></eo-icon>
    <div>
      <div *ngIf="file.title; else tplNoTitle" class="title">{{file.title}}</div>
      <ng-template #tplNoTitle>
        <div class="title no-dms-object" translate>eo.bpm.dmsobject.notavailable</div>
      </ng-template>
      <div class="description">{{file.description}}</div>
    </div>
    <eo-icon class="button"
             *ngIf="editable && permissions.remove"
             [iconSrc]="'assets/_default/svg/ic_clear.svg'"
             (click)="removeWorkItemContent(file, $event)">
    </eo-icon>
  </div>

</div>

<!-- no files attached to the work item -->
<ng-template #noFile>
  <div class="no-file">
    <!-- section for adding new items to the work items attachments -->
    <div class="content-paste"
         *ngIf="permissions.add && clipboard?.elements.length"
         (click)="addFromClipboard()"
         [ngClass]="{disabled: isDisabled}">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_content_paste.svg'"></eo-icon>
      <span class="label ct" translate>eo.process.file.add.label</span>
    </div>
    <div class="eo-error-message-wrapper">
      <eo-error-message [emptyState]="{icon: 'ic_no-file.svg', text: 'eo.process.file.empty.label', className: 'no-files history empty'}">
      </eo-error-message>
    </div>
  </div>
</ng-template>

