<section class="autocomplete">
  <yvc-autocomplete [(ngModel)]="selectedNode" 
  #autocomplete
    [autocompleteValues]="autocompleteRes" field="label"
    [forceSelection]="true"
    (suggestionSelect)="onSelect($event)"
    (autocompleteFnc)="autocompleteFn($event)">
    
    <ng-template let-item #chipTemplate>
      <span class="ui-autocomplete-token-label" title="{{item.label}} ({{item.location}})">
        <strong>{{item.label}}</strong>
        <span>{{item.location}}</span>
      </span>
    </ng-template>
  </yvc-autocomplete>
</section>

<section class="type-lists">
  <section class="section location" *ngIf="locationParent">
    <div class="subhead"><span translate>eo.add.title.location</span><span> "{{locationParent.title}}"</span></div>
    <div class="type sub" (click)="addToSubfolder()" *ngIf="subfolder">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_create_new_folder.svg'"></eo-icon>
      <div>
        <div class="label">{{'eo.add.title.location.subfolder'|translate: ({folder: subfolder.title})}}</div>
        <div class="group">{{subFolderDescription}}</div>
      </div>
    </div>
    <div class="type" *ngFor="let type of locationTypes; trackBy: trackByFn" (click)="prepare(type)">
      <eo-icon [objectType]="type"></eo-icon>
      <div>
        <div class="label">{{type.label}}</div>
        <div class="group">{{type.group}}</div>
      </div>
    </div>
  </section>
  
  <section class="section general" *ngIf="rootTypes.length; else noRootTypes">
    <div class="subhead"><span translate>eo.add.title.location.common</span></div>
      <div class="type" *ngFor="let type of rootTypes; trackBy: trackByFn"
           [ngClass]="{context: type.isContextFolder}"
           (click)="prepare(type, true)">
        <eo-icon [objectType]="type"></eo-icon>
        <div>
          <div class="label">{{type.label}}</div>
          <div class="group">{{type.group}}</div>
        </div>
      </div>
  </section>
</section>

<ng-template #noRootTypes>
  <div *ngIf="!locationParent">
    <span class="empty__root-types" translate>eo.add.empty.root.types</span>
  </div>
</ng-template>
