import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PrepareStateComponent} from './prepare-state/prepare-state.component';
import {AuthGuard} from '../../eo-framework-core/route-guards/auth/auth-guard.service';
import {CapabilitiesGuard} from '../../eo-framework-core/route-guards/capabilities/capabilities-guard.service';
import {PendingChangesGuard} from '../../eo-framework-core/pending-changes/pending-changes-guard.service';
import {UploadOverlayGuard} from '../../eo-framework/upload-overlay/upload-overlay-guard.service';

const routes: Routes = [{
  path: 'prepare',
  component: PrepareStateComponent,
  canActivate: [AuthGuard, UploadOverlayGuard, CapabilitiesGuard], canDeactivate: [PendingChangesGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrepareStateRoutingModule {
}
