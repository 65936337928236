import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ObjectFormComponent} from '../object-form/object-form/object-form.component';
import {ObjectFormOptions} from '../object-form/object-form-options.interface';
import {FormStatusChangedEvent} from '../object-form';

@Component({
  selector: 'eo-process-form',
  templateUrl: './process-form.component.html',
  styleUrls: ['./process-form.component.scss']
})
export class ProcessFormComponent {

  @ViewChild('bpmForm') bpmForm: ObjectFormComponent;
  formData: any;
  form: any;

  @Input() formOptions: ObjectFormOptions;
  @Output() onSaveForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResetForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() statusChanged = new EventEmitter<FormStatusChangedEvent>();

  saveForm() {
    setTimeout(() => {
      if (this.form && !this.form.invalid) {
        this.formData = this.bpmForm.getFormData();
        this.onSaveForm.emit(this.formData);
      }
    }, 500);
  }

  cancel() {
    this.onCancel.emit(true);
  }

  onIndexDataChanged(event) {
    this.form = event;
    this.formData = event.data;
    this.statusChanged.emit(event);
  }

}
