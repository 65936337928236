import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventService, InboxItem, InboxService, InboxState, LocalStorageService, TranslateService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EmptyState} from '../../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../../eo-framework-core/empty-state/empty-state.service';
import {PendingChangesComponent} from '../../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../../eo-framework-core/title/page-title.service';
import {CustomSortComponent} from '../../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {ISetFilter} from '../../../eo-framework/grid/extensions/filter/setFilters/set-filters.interface';
import {GridComponent} from '../../../eo-framework/grid/grid.component';
import {InboxTypes, InboxTypesFilter} from './inbox.types.enum';
@UntilDestroy()
@Component({
  selector: 'eo-inbox-state',
  templateUrl: './inbox-state.component.html',
  styleUrls: ['./inbox-state.component.scss']
})
export class InboxStateComponent implements OnInit, OnDestroy, PendingChangesComponent {

  gridOptions: GridOptions;
  sortFields: ColDef[];
  inboxFilterFields: ISetFilter[] = [];
  typeFilterFields: ISetFilter[] = [];
  inboxSelectionId = 'inbox';
  inboxDmsObjectSelectionId = 'inbox_dms';
  inboxSelection: SelectionService;
  selectFirst = true;
  idMatch = true;
  gridData;
  defaultFilterParams: any = {};
  emptyState: EmptyState = {icon: 'ic_no-file.svg', text: '', className: ''};
  inboxChanged = false;
  storageKeyLastItemID = 'eo.inbox.lastItemID';

  @ViewChild('eoGrid') eoGrid: GridComponent;

  constructor(public translate: TranslateService,
    public selection: SelectionService,
    private titleService: PageTitleService,
    public inboxService: InboxService,
    private pendingChanges: PendingChangesService,
    private empty: EmptyStateService,
    private eventService: EventService,
    private route: ActivatedRoute,
    private storageService: LocalStorageService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.inbox.list.title'));
    //secondary selection instance for inbox items
    this.inboxSelection = this.selection.createNew(this.inboxSelectionId);
    this.selection.createNew(this.inboxDmsObjectSelectionId);

    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: any) => {
        this.defaultFilterParams = {context: {defaultValue: params.id}};
      });

    this.inboxService.inboxState$
      .pipe(untilDestroyed(this))
      .subscribe((state: InboxState) => {
        if (this.eoGrid) {
          // todo: temporary fix until status service update
          // this.inboxChanged = this.eoGrid.contextCount !== state.totalmessages;
        }
      });
  }

  onCountChanged(evt: any) {
    if (this.defaultFilterParams.context && this.defaultFilterParams.context.defaultValue) {
      if (evt.rowCount === 0) {
        this.idMatch = false;
        this.defaultFilterParams.context.defaultValue = '';
        this.inboxSelection.clear();
        this.inboxSelection.disable();
        this.eoGrid.gridOptions.context.count = 0;
      } else {
        this.defaultFilterParams.context.defaultValue = '';
      }
    }
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  refreshGrid() {
    this.inboxChanged = false;
    this.inboxSelection.disable(false);
    this.inboxService.getItems().subscribe();
    this.inboxService.refreshInboxState();
  }

  itemFilterFields(node, item) {
    const value = node.data[InboxTypesFilter[item]];
    const acceptedAndUnacceptedCombined = this.inboxFilterFields.find(filter => filter.id === 'acceptedTasks').value
      && this.inboxFilterFields.find(filter => filter.id === 'unacceptedTasks').value;
    switch (InboxTypesFilter[item]) {
      case InboxTypesFilter.overdue:
        return node.data.isOverdue();
      case InboxTypesFilter.absent:
        return !!value;
      case InboxTypesFilter.acceptedTasks:
        return acceptedAndUnacceptedCombined ? node.data.type === 'BPM' : node.data.type === 'BPM' && !!node.data.accepted;
      case InboxTypesFilter.unacceptedTasks:
        return acceptedAndUnacceptedCombined ? node.data.type === 'BPM' : node.data.type === 'BPM' && !node.data.accepted;
    }
  }

  fieldFilters() {
    this.typeFilterFields = Object.keys(InboxTypes).map(item => ({
      label: this.translate.instant(`eo.inbox.${item}`),
      filter: (node) => node.data.type === item,
      value: true,
      id: item
    }));

    this.inboxFilterFields = Object.keys(InboxTypesFilter).map(item => ({
      label: this.translate.instant(`eo.inbox.filter.${item}`),
      filter: (node) => this.itemFilterFields(node, item),
      value: false,
      id: item
    }));
  }

  resetFilters() {
    this.fieldFilters();
  }

  updateGrid(data: InboxItem[]): void {
    this.emptyState = this.empty.getEmptyState(data ? data.length : -1, undefined, 'ic_no-file.svg');

    if (this.eoGrid && this.eoGrid.isReady) {
      return this.eoGrid.updateRowData(data);
    }

    this.resetFilters();

    let colDefs: ColDef[] = [{
      headerName: '',
      field: '__custom',
      cellRenderer: this.cellRenderer,
      filter: false
      // cellRendererFramework: InboxRendererComponent
    }];

    this.sortFields = [
      {
        field: 'received', headerName: this.translate.instant('eo.inbox.sort.date'), sort: '',
        valueGetter: (p) => (p.data[p.colDef.field] || '').match(/\+\d\d:\d\d/) ? new Date(p.data[p.colDef.field]).toISOString() : p.data[p.colDef.field]
      },
      {field: 'title', headerName: this.translate.instant('eo.inbox.sort.title'), sort: ''},
      {field: 'description', headerName: this.translate.instant('eo.inbox.sort.description'), sort: ''}
    ];

    let sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);

    this.gridOptions = {
      context: {count: data.length},
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80,
      rowSelection: 'single'
    };
  }

  cellRenderer(params) {

    let label = '';
    if (Object.keys(InboxTypes).includes(params.data.type)) {
      label = params.context.translate.instant(`eo.inbox.${params.data.type}`);
    }

    const url = `assets/_default/svg/ic_${InboxTypes[params.data.type]}.svg`;

    let icon = params.context.cr.render('icon', params, {value: {url, label}});
    let shortDate = params.context.cr.render('dateTime', params, {value: params.data.received, pattern: 'eoShortDate'});
    let shortTime = params.context.cr.render('dateTime', params, {value: params.data.received, pattern: 'eoShortTime'});
    let duetime = params.context.cr.render('dateTime', params, {value: params.data.duetime});
    const titleClass = params.data.accepted ? 'title' : 'title-bold';

    return `<div class="list-item${params.data.folder ? ' folder' : ''}" unselectable>
              ${icon}
              <div class='content'>
                <div class='${titleClass}'>${params.data.title || ''}</div>
                <div class='description'>${params.data.description || ''}</div>
                <div class='notes${params.data.isOverdue() ? ' pastDue' : ''}'>
                  ${params.data.duetime ? '<span class="period">' + duetime + '</span>' : ''}
                  ${params.data.isdeputy ? '<span>' + params.context.translate.instant('eo.inbox.item.note.absence') + '</span>' : ''}
                </div>
              </div>
              <div class="meta">
                <div class="date">${shortDate}</div>
                <div class="date">${shortTime}</div>
              </div>
           </div>
          `;
  }

  ngOnInit() {
    this.refreshGrid();
    this.inboxService
      .inboxItemsGridData$
      .pipe(untilDestroyed(this))
      .subscribe((data: InboxItem[]) => {

        this.gridData = data;
        this.updateGrid(this.gridData);

        const lastInboxItemID = this.storageService.getItem(this.storageKeyLastItemID);
        if (lastInboxItemID) {
          const item = this.gridData.find(i => i.id === lastInboxItemID);
          if (item) {
            this.selectFirst = false;
            setTimeout(() => this.eoGrid.selectRow(item), 500);
          }
        }
      });
  }

  ngOnDestroy() {
    this.selection.clear();
  }
}
