<div class="eo-form-reference" [ngClass]="{acInputHidden: !multiselect && !!innerValue.length}">

  <yvc-autocomplete [ngModel]="innerValue" #autocomplete (suggestionSelect)="onSelect($event)"
    (suggestionUnselect)="onUnselect($event)" [disabled]="readonly" [multiple]="true"
    [autocompleteValues]="autocompleteRes" [field]="'value'" [forceSelection]="true" (blur)="onAutoCompleteBlur()"
    (keyup.enter)="onKeyUpEnter($event)" (autocompleteFnc)="autocompleteFn($event)">

    <!-- template for the suggest list -->
    <ng-template let-item #autocompleteOptionTemplate>
      <span class="yvc-autocomplete-token-label" title="{{item.value}} ({{item.title}})">
        <strong>{{item.value}}</strong>
        <span>{{item.title}}</span>
      </span>
    </ng-template>

    <!-- template for the selected items -->
    <ng-template let-item #chipTemplate>
      <a class="link router-link" [routerLink]="['/result']" [queryParams]="getItemLinkParams(item)">
        <svg focusable="false" class="ref-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
          viewBox="0 0 24 24">
          <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8
          13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z">
          </path>
        </svg>
      </a>
      <span class="yvc-autocomplete-token-label">{{item.value}}</span>

    </ng-template>
  </yvc-autocomplete>
</div>
