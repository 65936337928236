<div class="eo-organization" [ngClass]="{acInputHidden: !multiselect && innerValue.length}">

  <yvc-autocomplete [(ngModel)]="innerValue" #autocomplete (suggestionSelect)="onSelect($event)"
    [placeholder]="placeholder" (suggestionUnselect)="onUnselect($event)" (blur)="onAutoCompleteBlur()"
    [disabled]="readonly" [autocompleteValues]="autocompleteRes" field="id"
    [forceSelection]="true"
    (autocompleteFnc)="autocompleteFn($event)" [multiple]="true">

    <ng-template #autocompleteOptionTemplate let-item>
      <div class="org-item">
        <yvc-icon [svg]="item.type === 'group' ?  icon.group : icon.user"></yvc-icon>
        <span *ngIf="(item.firstname?.length && item.lastname?.length) || item.type === 'group'">{{item.title}}
          ({{item.name}})</span>
        <span *ngIf="!item.firstname?.length && !item.lastname?.length && item.type !== 'group'">({{item.name}})</span>
        <span *ngIf="!item.firstname?.length && item.lastname?.length">{{item.lastname}} ({{item.name}})</span>
        <span *ngIf="item.firstname?.length && !item.lastname?.length">{{item.firstname}} ({{item.name}})</span>
      </div>
    </ng-template>

    <ng-template #chipTemplate let-item>
      <div class="org-item">
        <yvc-icon [svg]="item.type === 'group' ?  icon.group : icon.user"></yvc-icon>
        <span
          *ngIf="(item.firstname?.length && item.lastname?.length && item.active) || item.type === 'group'">{{item.title}}
          ({{item.name}})</span>
        <span *ngIf="(!item.firstname?.length && !item.lastname?.length && !item.active && !item.type)"
          class="deleted-user-label">{{'eo.form.property.organization.error.usernotfound'|translate}}</span>
        <span
          *ngIf="!item.firstname?.length && !item.lastname?.length && item.active && item.type !== 'group'">({{item.name}})</span>
        <span *ngIf="!item.firstname?.length && item.lastname?.length">{{item.lastname}} ({{item.name}})</span>
        <span *ngIf="item.firstname?.length && !item.lastname?.length">{{item.firstname}} ({{item.name}})</span>
      </div>
    </ng-template>
    
  </yvc-autocomplete>
</div>