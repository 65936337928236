import {AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AppCacheService, DmsObject, DmsService, EnaioEvent, EventService} from '@eo-sdk/core';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {ActionService} from '../actions/action-service/action.service';
import {Tabs} from '@yuuvis/components/tabs';

@UntilDestroy()
@Component({
  selector: 'eo-object-state-details',
  templateUrl: './object-state-details.component.html',
  styleUrls: ['./object-state-details.component.scss']
})
export class ObjectStateDetailsComponent implements OnInit, AfterViewInit {

  static LAST_SEEN_LIMIT = 10;

  @ViewChild(Tabs) eoTabs: Tabs;

  @Input() plugins: any[];

  @Input() context: DmsObject;
  @Input() selectedItem: DmsObject;
  @Input() seenItems: any[] = [];
  @Input() frontPageData;

  @HostBinding('class.visible')
  @Input() visible = true;

  @Output() onTabChange = new EventEmitter();

  @HostBinding('attr.data-type') get dataType() {
    return this.context ? this.context.typeName : 'none';
  }

  @HostBinding('attr.data-id') get dataId() {
    return this.context ? this.context.id : 'none';
  }

  @HostBinding('style.height') get visibility() {
    return !this.visible ? 0 : '100%';
  }

  get title() {
    return this.eoTabs?.tabs?.find(t => t.id === this.eoTabs.selectedTab)?.label || '';
  }

  constructor(private selection: SelectionService, private actionService: ActionService, private appCache: AppCacheService,
    private dmsService: DmsService, private eventService: EventService, private pendingChanges: PendingChangesService) {
    this.eventService
      .on(EnaioEvent.DMS_OBJECT_LOADED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => this.onFocus(event.data));

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => this.onFocus(event.data));

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_DELETED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => (this.onRemove(event.data), this.onRemoveModification(event.data)));
  }

  onFocus(item: DmsObject) {
    this.selectedItem = item;
    if (item && item.id !== this.context.id) {
      const notSeenTab = false //!this.lastSeenTab TODO: enable again?
      const notModified = this.seenItems.find(i => i.id === item.id)?.modified?.on === item.modified.on;
      let {id, typeName, title, description, modified} = item;
      if(notSeenTab || !notModified) this.onRemove(item, [{id, type: typeName, title, description, modified}]);
    }
  }

  onRemove(item: DmsObject, seen = []) {
    if (item && item.id) {
      this.seenItems = seen.concat(this.seenItems.filter(i => i.id !== item.id)).slice(0, ObjectStateDetailsComponent.LAST_SEEN_LIMIT);
      this.appCache.setItem('eo.last.seen.objects-' + this.context.id, this.seenItems).subscribe();
    }
  }

  onRemoveModification(item: DmsObject) {
    if (item && item.id && this.frontPageData) {
      this.frontPageData.lastModifications = this.frontPageData.lastModifications && this.frontPageData.lastModifications.filter(i => i.id !== item.id);
    }
  }

  selectFrontPageDoc(doc) {
    if (!this.pendingChanges.check()) {
      this.selection.focus(doc);
    }
  }

  onContextMenuClicked(evt, doc?) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.pendingChanges.check()) {
      this.dmsService.getDmsObjectByParams(doc || this.context).subscribe(o => this.actionService.showActions([o], 'DMS_OBJECT'));
    }
  }

  ngOnInit() {
    this.appCache.getItem('eo.last.seen.objects-' + this.context.id)
      .subscribe(ls => this.seenItems = ls || []);
  }

  ngAfterViewInit() {
  }

}
