import {ObjectType} from '@eo-sdk/core';
import {ObjectFormOptions} from '../../../object-form/object-form-options.interface';

export interface IdxSearch {
    type: ObjectType;
    contextFolderType: ObjectType;
    contextModels?: any[];
    contextAggregations?: {};
    contextFormOptions?: ObjectFormOptions;
    formOptions?: ObjectFormOptions;
  }
  
  export enum ContextType {
    'sysroot' = 'sysroot'
  }