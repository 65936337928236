
import {of as observableOf, Observable} from 'rxjs';
import {SimpleAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService, UserService,DmsObject, BackendService} from '@eo-sdk/core';

@Component({
  selector: 'eo-unlock-action',
  template: ``
})
export class UnlockActionComponent extends DmsObjectTarget implements SimpleAction {
  label: string;
  description: string;
  priority = 12;
  iconSrc = 'assets/_default/svg/ic_lock_open.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  constructor(private translate: TranslateService,
              private userService: UserService,
              private backendService: BackendService) {
    super();
    this.label = this.translate.instant('eo.action.unlock.label');
    this.description = this.translate.instant('eo.action.unlock.description');
  }

  isExecutable(item: DmsObject): Observable<boolean> {    
    return observableOf(item.lock && (item.lock.by.me || this.userService.getCurrentUser().hasPrivilege('BREAK_LOCK')));
  };

  run(selection: DmsObject[]) {
    this.backendService.del(`/dms/lock/${selection[0].id}${this.userService.getCurrentUser().hasPrivilege('BREAK_LOCK') ? '?breaklock=true' : ''}`).subscribe(() => delete selection[0].lock);
  }
}
