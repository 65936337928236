import {Component, Input} from '@angular/core';
import {IFilterParams, RowNode} from '@ag-grid-community/core';
import {Utils} from '@eo-sdk/core';
import {AbstractFilterComponent} from '../abstract-filter.component';
import {ListSettingsService} from '../../services/list-settings.service';

// @dynamic
@Component({
  selector: 'eo-set-filter',
  templateUrl: `./set-filter.component.html`,
  styleUrls: ['./set-filter.component.scss']
})
export class SetFilterComponent extends AbstractFilterComponent {

  private _options: any[] = [];
  @Input() title = '';
  @Input() searchLimit = 10;
  @Input() operator = 'OR'; // AND | OR

  @Input() set options(opts: any[]) {
    this._options = this.defaultValue ? opts || [] : this.settingsService.setupSettings(this.id, opts);
    if (this.options && this.params && this.isFilterActive()) {
      setTimeout(() => this.params.filterChangedCallback(), 0);
    }
  }

  @Input() set filterParams(params: IFilterParams) {
    super.filterParams = params;
  }

  get options() {
    return this._options;
  }

  constructor(private settingsService: ListSettingsService) {
    super();
    this.id = '#set';
  }

  static uniqBy(list: any[], prop: string, options: any[]) {
    const u = {};
    list.forEach(a => u[Utils.getProperty(a, prop)] = true);
    return options.filter(t => u[t.qname]);
  }

  static uniqByProperty<T>(arr: Array<T>, prop: string): Array<T> {
    return arr.filter((element, index, array) => array.map(a => Utils.getProperty(a, prop)).indexOf(Utils.getProperty(element, prop)) === index);
  }

  doesExternalFilterPass(rowNode: RowNode): boolean {
    return this.operator !== 'OR' ? this.options.every(opt => !opt.value || opt.filter(rowNode)) : this.options.some(opt => opt.value ? opt.filter(rowNode) : false);
  }

  isFilterActive(): boolean {
    return this.options.some(f => this.operator !== 'OR' ? f.value : !f.value);
  }

  onChange(newValue: any, item?: any): void {
    if (item) {
      item.value = newValue;
    } else {
      this.options.forEach(o => (o.value = newValue));
    }
    if (!this.defaultValue) {
      this.settingsService.saveSettings(this.id, this.options);
    }
    this.params.filterChangedCallback();
  }

  onSearchChange(newValue: any): void {
    const parse = html => (new DOMParser).parseFromString(html, 'text/html').documentElement.textContent;
    this.options.forEach(o => (o.hidden = newValue.trim() ? !parse(o.label).toLowerCase().match(newValue.trim().toLowerCase()) : false));
  }

  reset() {
    this.settingsService.saveSettings(this.id);
    this.options.forEach(o => (o.value = this.operator === 'OR'));
  }

}
