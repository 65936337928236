import {RouterModule, Routes} from '@angular/router';
import {AppSearchComponent} from './app-bar/app-search/app-search.component';
import {AppAddComponent} from './app-bar/app-add/app-add.component';
import {NgModule} from '@angular/core';
import {AppProcessComponent} from './app-bar/app-process/app-process.component';

const routes: Routes = [
  {
    path: 'search',
    component: AppSearchComponent,
    outlet: 'modal'
  },
  {
    path: 'add',
    component: AppAddComponent,
    outlet: 'modal'
  },
  {
    path: 'actions',
    component: AppProcessComponent,
    outlet: 'modal'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppShellRoutingModule {
}
