import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {
  BpmService,
  Clipboard,
  ClipboardAction,
  ClipboardService,
  EnaioEvent,
  EventService,
  FileEntry,
  InboxItem,
  InboxService,
  LocalStorageService,
  NotificationsService,
  TranslateService,
  UserService,
  Utils,
  WorkItem,
  WorkItemAction,
  WorkItemHistoryEntry
} from '@eo-sdk/core';
import {Observable, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionConfig, SelectionService} from '../../eo-framework-core/selection/selection.service';
import {FormStatusChangedEvent} from '../object-form/form-status-changed-event.interface';
import {ObjectFormOptions} from '../object-form/object-form-options.interface';
import {ObjectFormComponent} from '../object-form/object-form/object-form.component';
import {CellRenderer} from './../../eo-framework-core/api/grid.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IndexData} from '../ui/indexdata-summary/indexdata-summary.component';
@UntilDestroy()
@Component({
  selector: 'eo-inbox-details',
  templateUrl: './inbox-details.component.html',
  styleUrls: ['./inbox-details.component.scss']
})
export class InboxDetailsComponent  implements OnInit, AfterViewInit {
  @ViewChild('bpmForm') bpmFormEl: ObjectFormComponent;

  private defaultWorkItemAction: WorkItemAction;

  // ID set by pendingChanges service when editing indexdata
  // Used to finish the pending task when editing is done
  private pendingTaskId: string;

  private _item: InboxItem;
  workItem: WorkItem;
  history: WorkItemHistoryEntry[];
  // flag indicating whether or not the work item is locked by the user
  locked: boolean;
  // COOL-11506
  preventClickThrough: boolean;
  // current clipboard contents
  clipboard: Clipboard;

  // used for handling SUBSCRIPTIONS and RESUBMISSIONS
  settings: {
    description: string;
    actionTitle: string;
  };
  formState: FormStatusChangedEvent;
  formOptions: ObjectFormOptions;
  selectedContentFileId: string;
  workItemIndexdata: IndexData;
  performerShow: boolean;
  substituteShow: boolean;
  actionProcessing: Subscription | any = {closed: true};
  initializing: Subscription | any = {closed: true};
  filesAdding: Subscription | any = {closed: true};
  loadError: boolean;
  storageKeyLastItemID = 'eo.inbox.lastItemID';

  @Input() plugins: any[];

  @Input() emptyState: EmptyState;
  @Input() applySelection: SelectionConfig;

  @Input('item')
  set item(i: InboxItem) {
    this._item = i;
    if (i) {
      // reset values
      this.workItem = null;
      this.history = [];
      this.locked = false;
      setTimeout(() => this.process());
    } else {
      this.selection.find(this.applySelection.out).focus(null);
    }
  }

  get item() {
    return this._item;
  }

  @HostBinding('attr.data-type') get dataType() {
    return this.item ? this.item.type : 'none';
  }

  constructor(
    private bpmService: BpmService,
    private userService: UserService,
    private inboxService: InboxService,
    private selection: SelectionService,
    private router: Router,
    private eventService: EventService,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private pendingChanges: PendingChangesService,
    private notify: NotificationsService,
    private storageService: LocalStorageService
  ) {
    this.defaultWorkItemAction = {
      code: 0,
      title: this.translate.instant('eo.process.task.actions.default.title'),
      type: 'FORWARD'
    };
  }

  refresh() {
    if (this.item.type === InboxItem.TYPE_BPM) {
      this.createBPMWorkItem();
    } else {
      this.item = this._item;
    }
  }

  onFormStatusChanged(evt: FormStatusChangedEvent) {
    this.formState = evt;
    if (this.formState.dirty) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskId || ' ')) {
      this.pendingTaskId = this.pendingChanges.startTask();
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskId);
  }

  /**
   * Process the current inbox item
   */
  private process(): void {
    this.loadError = false;
    this.initializing.closed = false;

    switch (this.item.type) {
      case InboxItem.TYPE_BPM: {
        this.initializing = this.createBPMWorkItem();
        break;
      }
      case InboxItem.TYPE_SUBSCRIPTION: {
        this.createDMSItem('subscription');
        break;
      }
      case InboxItem.TYPE_RESUBMISSION: {
        this.createDMSItem('resubmission');
        break;
      }
    }
  }

  createDMSItem(dmsType) {
    this.settings = {
      description: this.translate.instant(
        `eo.process.details.task.${dmsType}.description`
      ),
      actionTitle: this.translate.instant(
        `eo.process.details.button.${dmsType}.remove`
      )
    };
    this.openWorkItemContent(this.item.target);
    this.initializing.closed = true;
  }

  private addDefaultActionIfEmpty = (res: WorkItem): WorkItem => {
    if (!res.actions?.length) {
      res.actions = [this.defaultWorkItemAction];
    }
    return res;
  }

  // for items of type 'BPM' fetch work item for more informations
  createBPMWorkItem() {
    return this.bpmService
      .getWorkItem(this.item.sourceId, this.item.id)
      .pipe(map(this.addDefaultActionIfEmpty))
      .subscribe(
        res => {
          this.workItem = res;
          this.setupWorkItem();
          this.setFormOptions(
            this.workItem.form,
            this.workItem.data,
            this.workItem.actions
          );
          // auto-select first attached content
          this.openWorkItemContent(
            this.workItem.file.length ? this.workItem.file[0] : null
          );
        },
        () => {
          this.loadError = true;
          this.notify.error(
            this.translate.instant('eo.process.details.workitem.load.fail'),
            this.translate.instant(
              'eo.process.details.workitem.load.fail.description'
            )
          );
        }
      );
  }

  private setFormOptions(form, data, actions: WorkItemAction[]) {
    if (form) {
      form.situation = 'EDIT';
      this.formOptions = {
        ...{
          formModel: form,
          data: data,
          actions: actions ? this.transformActions(actions) : {},
          // form script will be provided with the list of file entries
          // attached to the current work item
          objects: this.workItem.file
        }
      };
    } else {
      this.transformActions(actions);
    }
  }

  private updateInboxSate() {
    this.inboxService.refreshInboxState();
  }

  private transformActions(actions) {
    let transformedActions = {};
    actions.forEach(a => {
      if (a.feasible === undefined) {
        a.feasible = true;
      }
      a.feasibility = value => {
        if (value !== undefined) {
          a.feasible = value;
        }
        return a.feasible;
      };
      transformedActions[a.code.toString()] = a;
    });
    return transformedActions;
  }

  /* Locks a work item (personalize) */
  lockWorkItem() {
    this.actionProcessing = this.bpmService
      .lockWorkItem(this.workItem)
      .pipe(map(this.addDefaultActionIfEmpty))
      .subscribe(res => {
        const {actions, data, form} = res;
        this.workItem = res;
        this.setupWorkItem();
        this.setFormOptions(form, data, actions);
        this.updateInboxSate();
      }, error => {
        if (error.status === 409) {
          Utils.throw(null, this.translate.instant('eo.bpm.event.script.execution.error'), this.workItem.title).call(this, {});
        } else {
          Utils.throw(null, this.translate.instant('eo.process.activity.lock.denied'), this.workItem.title).call(this, {});
        }
      });
  }

  /**
   * Un-Locks a work item (de-personalize)
   */
  unlockWorkItem() {
    if (!this.pendingChanges.check()) {
      this.actionProcessing = this.bpmService
        .unlockWorkItem(this.workItem)
        .subscribe(res => {
          this.workItem = res;
          this.setupWorkItem();
          this.updateInboxSate();
        }, Utils.throw(null, this.translate.instant('eo.process.activity.unlock.denied'), this.workItem.title));
    }
  }

  /**
   * Executes a work items action.
   * @param action The action to be executes
   */
  executeWorkItemAction(action: WorkItemAction) {
    this.preventClickThrough = true;
    setTimeout(() => {
      this.preventClickThrough = false;
    }, 1000);
    setTimeout(() => {
      if (!this.formState || !this.formState.invalid) {
        if (action.url) {
          // process external action by saving work item and redirect to actions url
          if (this.bpmFormEl) {
            this.actionProcessing = this.saveWorkItem().subscribe(() => {
              this.openActionUrl(action.url);
            });
          } else {
            this.openActionUrl(action.url);
          }
        } else {
          if (this.bpmFormEl) {
            this.workItem.data = this.bpmFormEl.getFormData();
            // we need to remove pending task because the form will be destroyed without saving
            this.finishPending();
          }
          this.bpmService
            .forwardWorkItem(this.workItem, action)
            .subscribe(() => {
              this.notify.success(
                this.translate.instant('eo.bpm.activity.forward.success'),
                this.item.title
              );
              this.item = undefined;
            }, Utils.throw(null, this.translate.instant('eo.bpm.activity.forward.error'), this.workItem.title));
        }
      }
    }, 500);
  }

  private openActionUrl(url: string) {
    if (url.substr(0, 4) === 'http') {
      window.location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
  }

  /**
   * Selects a work items file entry.
   * @param file FileEntry element to be selected
   */
  openWorkItemContent(file?: FileEntry) {
    if (!file) {
      this.selectedContentFileId = null;
      if (this.applySelection) {
        this.selection.find(this.applySelection.out).focus(null);
      }
    } else {
      this.selectedContentFileId = file.id;
      let params = {
        id: file.id,
        type: file.type
      };
      if (this.applySelection) {
        this.selection.find(this.applySelection.out).focus(params);
      }
    }
  }

  /**
   * Opens the file (attached DMSObject) in object state
   * @param file
   */
  openWorkItemContentInContext(evt) {
    const file: FileEntry = evt.data || {};
    const fileType: NavigationExtras = {queryParams: {type: file.type}};
    if (evt.ctrlKey) {
      window.open(CellRenderer.windowURI(this.router.createUrlTree(['object', file.id], fileType)));
    } else {
      this.router.navigate(['/object', file.id], fileType);
    }
  }

  /**
   * Adds clipboard content to the work item
   */
  addFromClipboard() {
    this.bpmService
      .addFileElementsFromClipboard(this.workItem, this.clipboard)
      .subscribe(workItem => {
        this.workItem = workItem;
        this.clipboardService.clear();

        if (!this.selectedContentFileId) {
          // open first item when nothing is selected
          this.openWorkItemContent(this.workItem.file[0]);
        }
      }, Utils.throw(null, this.translate.instant('eo.process.file.notification.error'), this.translate.instant('eo.process.file.notification.add.message')));
  }

  /**
   * Remove a file entry from the work items file
   * @param file The file entry to be removed
   * @param evt The click event
   */
  removeWorkItemContent(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (evt.data) {
      const file: FileEntry = evt.data;
      this.bpmService
        .removeFileElement(this.workItem.processId, this.workItem.id, file.id)
        .subscribe(() => {
          this.workItem.file = this.workItem.file.filter(f => f.id !== file.id);
          if (this.selectedContentFileId === file.id) {
            this.openWorkItemContent(
              this.workItem.file.length ? this.workItem.file[0] : null
            );
          }
        }, Utils.throw(null, this.translate.instant('eo.process.file.notification.remove'), this.translate.instant('eo.process.file.notification.remove.message')));
    }
  }

  /**
   * Save the work items form data
   */
  saveWorkItemData() {
    setTimeout(() => {
      if (this.formState && !this.formState.invalid && this.formState.dirty) {
        this.actionProcessing = this.saveWorkItem().subscribe(() => {
          this.finishPending();
          this.updateInboxSate();
        });
      }
    }, 500);
  }

  prepareNewWorkItem(workItem: WorkItem): boolean {
    this.workItem = workItem;
    const {actions, form, data} = this.workItem;
    this.setupWorkItem();
    this.setFormOptions(form, data, actions);
    this.notify.success(
      this.translate.instant('eo.bpm.activity.indexdata.save.success')
    );
    return true;
  }

  /**
   * Saves the current work item
   */
  private saveWorkItem(): Observable<any> {
    this.workItem.data = this.bpmFormEl.getFormData();
    return this.bpmService.saveWorkItem(this.workItem).pipe(
      map(this.addDefaultActionIfEmpty),
      map(workItem => this.prepareNewWorkItem(workItem)),
      catchError(
        Utils.catch(
          null,
          this.translate.instant('eo.bpm.activity.indexdata.save.error')
        )
      )
    );
  }

  /**
   * Removes the current item from the inbox.
   */
  confirmInboxItem() {
    this.preventClickThrough = true;
    setTimeout(() => {
      this.preventClickThrough = false;
    }, 1000);
    this.actionProcessing = this.inboxService
      .removeItem(this.item)
      .subscribe(() => {
        this.notify.success(
          this.translate.instant('eo.inbox.activity.confirm.success')
        );
        this.eventService.trigger(EnaioEvent.INBOX_ITEM_CONFIRMED, this.item);
        this.item = undefined;
        this.inboxService.refreshInboxState();
      }, Utils.catch(null, this.translate.instant('eo.process.item.remove.fail')));
  }

  /**
   * Sets up state and data for the current work item.
   */
  private setupWorkItem() {
    this.locked = this.workItem.state === 'PERSON';
    this.preventClickThrough = true;
    setTimeout(() => this.preventClickThrough = false, 1000);
    const {form, data} = this.workItem;
    const workItem = {form, data};
    this.workItemIndexdata = form && data ? workItem : null;

    // fetch history
    this.bpmService
      .getProcessHistory(this.workItem.processId)
      .pipe(map(res => {
        res.forEach(item => {
          if (item.editor) {
            item.data.image = this.userService.getUserImageUri(item.editor.id);
          }
        });
        // sort by number because this is the auto-incrementing
        // index of added history entries
        this.history = res.sort((a, b) => b.number - a.number);
      }))
      .subscribe();
  }

  trackByCode(index, item) {
    return item.code;
  }

  trackById(index, item) {
    return item.id;
  }

  trackByIndex(index, item) {
    return index;
  }

  ngOnInit() {
    // subscribe for changes to the clipboard to enable adding contents to the work item
    this.clipboardService.clipboard$
      .pipe(untilDestroyed(this))
      .subscribe(
        (clipboard: Clipboard) =>
        (this.clipboard =
          clipboard.action === ClipboardAction.COPY ? clipboard : null)
      );
    // component is only interested in copied items

    if (this.applySelection) {
      this.selection
        .find(this.applySelection.in)
        .focus$.pipe(untilDestroyed(this))
        .subscribe((item: any) => {
          if (item) {
            this.item = item;
            this.storageService.setItem(this.storageKeyLastItemID, this.item.id);
          }
        });
    }
  }

  ngAfterViewInit() {
  }
}
