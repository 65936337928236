import {WorkItem, InboxItem, DmsObject, PreparedItem, Process, StoredQuery} from '@eo-sdk/core';

export const ActionTarget = {
  DMS_OBJECT: DmsObject,
  INBOX_ITEM: InboxItem,
  PROCESS_ITEM: Process,
  PREPARED_ITEM: PreparedItem,
  WORK_ITEM: WorkItem,
  STORED_QUERY: StoredQuery
};

export class DmsObjectTarget {
  static target = ActionTarget.DMS_OBJECT;
}

export class InboxItemTarget {
  static target = ActionTarget.INBOX_ITEM;
}

export class ProcessItemTarget {
  static target = ActionTarget.PROCESS_ITEM;
}

export class PreparedItemTarget {
  static target = ActionTarget.PREPARED_ITEM;
}

export class WorkItemTarget {
  static target = ActionTarget.WORK_ITEM;
}

export class StoredQueryTarget {
  static target = ActionTarget.STORED_QUERY;
}
