<eo-icon class="button favorite"
         [iconTitle]="('eo.action.menu.edit.document.label' | translate)"
         [iconSrc]="'assets/_default/svg/ic_edit.svg'"
         (click)="agentAlwayslock === 'ask' && !item.lock ? toggle($event) : editOriginalContent()">
</eo-icon>

<eo-dialog
  [title]="('eo.action.menu.edit.document.label' | translate)"
  [(visible)]="display"
  [minWidth]="400">

  <eo-open-document *ngIf="display" [selection]="[item]" (finished)="fetchAgentAlwayslock(); display = false" (canceled)="display = false"></eo-open-document>
</eo-dialog>
