import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {DmsObject, CapabilitiesService, TranslateService, SigningService, EventService, DmsService, EnaioEvent} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {BackendService, NotificationsService} from '@eo-sdk/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'eo-cancel-signing',
  templateUrl: './cancel-signing.component.html',
  styleUrls: ['./cancel-signing.component.scss']
})
export class CancelSigningComponent implements ActionComponent {

  cancelling = false;
  commentLabel: string;
  cancelSigningForm: FormGroup;
  commentField: FormControl;

  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private backend: BackendService,
              private toast: NotificationsService,
              private router: Router,
              private capabilitiesService: CapabilitiesService,
              private signingService: SigningService,
              private elemRef: ElementRef,
              private eventService: EventService,
              private dmsService: DmsService) {
    this.commentLabel = this.translate.instant('eo.action.cancel.signing.reason');
    this.cancelSigningForm = new FormGroup({});
    this.commentField = new FormControl();
    this.cancelSigningForm.addControl('commentField', this.commentField);
    this.commentField.setValidators(Validators.required);
  }

  get commentValidity() {
    return this.cancelSigningForm.get('commentField').invalid;
  }

  cancelSigning(id: string, comment: string) {
    this.cancelling = true;
    this.signingService
      .cancelSigning(id, comment)
      .pipe(
        switchMap(() => this.dmsService.getDmsObject(this.selection[0].id))
      )
      .subscribe((dmsObject) => {
        this.cancelling = false;
        this.toast.success(null,
          this.translate.instant('eo.action.cancel.signing.success.message')
        );
        this.eventService.trigger(EnaioEvent.DMS_OBJECT_UPDATED, dmsObject);
        this.finished.emit();
      }, (error) => {
        this.toast.error(this.translate.instant('eo.action.cancel.signing.error.message'));
        this.finished.emit();
      }
    );
  }

  run() {
    this.cancelSigning(this.selection[0].id, this.commentField.value);
  }

  cancel() {
    this.canceled.emit();
  }
}
