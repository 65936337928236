import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {TranslateService} from '@eo-sdk/core';
import {Subscription, forkJoin, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {EmptyState} from '../../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../../eo-framework-core/empty-state/empty-state.service';
import {CellRenderer} from './../../../eo-framework-core/api/grid.service';
import {SelectionConfig, SelectionService} from '../../../eo-framework-core/selection/selection.service';
import {FormStatusChangedEvent} from '../../object-form/form-status-changed-event.interface';
import {ObjectFormOptions} from '../../object-form/object-form-options.interface';
import {ObjectFormComponent} from '../../object-form/object-form/object-form.component';

import {
  BpmService,
  Clipboard,
  ClipboardAction,
  ClipboardService,
  DmsParams,
  FileEntry,
  Process,
  SystemService,
  UserService,
  Utils,
  WorkItem,
  WorkItemHistoryEntry
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eo-process-details',
  templateUrl: './process-details.component.html',
  styleUrls: ['./process-details.component.scss']
})
export class ProcessDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('bpmForm') bpmFormEl: ObjectFormComponent;

  private _item: Process;
  workItem: WorkItem;
  history: WorkItemHistoryEntry[];
  organisation: any;
  // flag indicating whether or not the work item is locked by the user
  locked: boolean;

  formState: FormStatusChangedEvent;
  formOptions: ObjectFormOptions;
  selectedContentFileId: string;
  actionProcessing: Subscription | any = {closed: true};
  initializing: Subscription | any = {closed: true};
  loadError: boolean;
  loading: boolean;
  clipboard: Clipboard;

  
  @Input() plugins: any[];

  @Input() emptyState: EmptyState = EmptyStateService.defaultEmptyState();
  @Input() applySelection: SelectionConfig;

  @Input('item')
  set item(i: Process) {
    this._item = i;

    if (i) {
      // reset values
      this.workItem = null;
      this.history = [];
      this.locked = false;
      this.loading = true;

      setTimeout(() => this.process(), 0);
    }
  }

  get item() {
    return this._item;
  }

  @Output() onDmsItemSelected = new EventEmitter<DmsParams>();

  constructor(private bpmService: BpmService,
    private userService: UserService,
    private systemService: SystemService,
    private selection: SelectionService,
    private clipboardService: ClipboardService,
    private translate: TranslateService,
    private router: Router) {
  }

  trackByCode(index, item) {
    return item.code;
  }

  trackByIndex(index, item) {
    return index;
  }

  onFormStatusChanged(evt) {
    this.formState = evt;
  }

  private process(): void {

    this.loadError = false;
    this.initializing.closed = false;

    const tasks = [
      this.systemService.getOrganizationObjectById(this.item.creatorid).pipe(
        // return default because otherwise forkJoin will fail
        catchError(e => of({
          name: 'unknown'
        }))
      ),
      this.bpmService.getProcessHistory(this.item.processid)
    ];

    forkJoin(tasks)
      .subscribe(tasksResult => {

        this.loading = false;
        const [organisation, history] = tasksResult;
        this.organisation = organisation;
        this.getProcessFile(this.item.processid);
        if (history) {
          history.forEach((item) => {
            if (item.editor) {
              item.data.image = this.userService.getUserImageUri(item.editor.id);
            }
          });

          console.log({history});

          this.history = history.sort((a, b) => b.number - a.number);
        }
      });
  }


  getProcessFile(processId) {
    this.bpmService
      .getProcessFile(processId)
      .subscribe((res: FileEntry[]) => {
        this.openProcessItemContent(res[0]);
        this._item.setFile(res);
      }, Utils.throw(() => {
        this.loadError = true;
      }, this.translate.instant('eo.process.details.workitem.load.fail')))
  }

  /**
   * Selects a work items file entry an emits onDmsItemSelected event.
   * @param file FileEntry element to be selected
   */
  openProcessItemContent(file?: FileEntry) {
    if (!file) {
      this.selectedContentFileId = null;
      this.onDmsItemSelected.emit(null);
      if (this.applySelection) {
        this.selection.find(this.applySelection.out).focus(null);
      }
    } else if (file.id !== this.selectedContentFileId) {
      this.selectedContentFileId = file.id;
      let params = {
        id: file.id,
        type: file.type
      };
      this.onDmsItemSelected.emit(params);
      if (this.applySelection) {
        this.selection.find(this.applySelection.out).focus(params);
      }
    }
  }

  removeWorkItemContent(event: [FileEntry, MouseEvent]) {
    const [file, evt] = event;
    const {processid, elementid} = this.workItem['content'][0];
    evt.preventDefault();
    evt.stopPropagation();

    this.actionProcessing = this.bpmService
      .removeFileElement(processid, elementid, file.id)
      .subscribe(res => {
        this.workItem.file = this.workItem.file.filter(f => f.id !== file.id);
        if (this.selectedContentFileId === file.id) {
          this.openProcessItemContent(this.workItem.file.length ? this.workItem.file[0] : null);
        }
      }, Utils.throw(null,
        this.translate.instant('eo.process.file.notification.remove'),
        this.translate.instant('eo.process.file.notification.remove.message'))
      );
  }

  addPastedProcessFile(event) {
    if (event) {
      this.bpmService.addFileElementsFromClipboard(this.workItem, this.clipboard)
        .pipe(
          catchError(Utils.catch(null,
            this.translate.instant('eo.process.file.notification.add.message'),
            this.translate.instant('eo.process.file.notification.error')))
        )
        .subscribe(workItem => {
          this.workItem = workItem;
          this.clipboardService.clear();
          if (!this.selectedContentFileId) {
            // open first item when nothing is selected
            this.openWorkItemContent(this.workItem.file.length ? this.workItem.file[0] : null);
          }
        }, Utils.throw(null,
          this.translate.instant('eo.process.file.notification.error'),
          this.translate.instant('eo.process.file.notification.add.message')));
    }
  }

  /**
   * Opens the file (attached DMSObject) in object state
   * @param file
   */
  openWorkItemContentInContext(evt) {
    const file: FileEntry = evt.data || {};
    const fileType: NavigationExtras = {queryParams: {'type': file.type}};
    if (evt.ctrlKey) {
      window.open(CellRenderer.windowURI(this.router.createUrlTree(['object', file.id], fileType)));
    } else {
      this.router.navigate(['/object', file.id], fileType);
    }
  }

  /**
   * Selects a work items file entry an emits onDmsItemSelected event.
   * @param file FileEntry element to be selected
   */
  openWorkItemContent(file?: FileEntry) {
    if (!file) {
      this.selectedContentFileId = null;
      this.onDmsItemSelected.emit(null);
      if (this.applySelection) {
        this.selection.find(this.applySelection.out).focus(null);
      }
    } else if (file.id !== this.selectedContentFileId) {
      this.selectedContentFileId = file.id;
      let params = {
        id: file.id,
        type: file.type
      };
      this.onDmsItemSelected.emit(params);
      if (this.applySelection) {
        this.selection
          .find(this.applySelection.out)
          .focus(params);
      }
    }
  }

  ngOnInit() {
    if (this.applySelection) {
      this.selection
        .find(this.applySelection.in)
        .focus$.pipe(untilDestroyed(this))
        .subscribe((item: any) => this.item = item);
    }

    this.clipboardService
      .clipboard$.pipe(untilDestroyed(this))
      .subscribe((clipboard: Clipboard) => this.clipboard = clipboard.action === ClipboardAction.COPY ? clipboard : null);

  }

  ngAfterViewInit() {
  }
}
