import { Component, Input, Output, EventEmitter } from '@angular/core';
import {IVisibleFilter} from './history-filter';

@Component({
  selector: 'eo-history-filter',
  templateUrl: './history-filter.component.html',
  styleUrls: ['./history-filter.component.scss']
})
export class HistoryFilterComponent {

  filterterm: string;

  @Input() visibleFilter: IVisibleFilter = {select: true, input: true};
  @Input() filters: any;
  @Output() onToggleFilter: EventEmitter<any> = new EventEmitter<any>();

  @Input() history: any;
  @Output() termChange = new EventEmitter<any>();

  @Input()
  get term() {
    return this.filterterm;
  }

  set term(val) {
    this.filterterm = val;
    this.termChange.emit(this.filterterm);
  }

  typeinput(event) {
    this.term = event.target.value;
  }

  resetFilter() {
    this.filterterm = null;
    this.term = null;
  }

  hasFilter(filter: string) {
    return this.filters.indexOf(filter) !== -1;
  }

  toggleFilter(type) {
    const filter = this.hasFilter(type);
    this.onToggleFilter.emit([type, filter]);
  }

}
