import {NavigationExtras, Router} from '@angular/router';
import {finalize, catchError} from 'rxjs/operators';
import {Component, Input, ViewChild, Output, EventEmitter, ElementRef} from '@angular/core';
import {EoError, TranslateService} from '@eo-sdk/core';
import {ObjectFormComponent} from '../object-form/object-form.component';
import {GlobalShortcutComponent} from '../../shortcuts/global-shortcut-component.interface';
import {ObjectFormOptions} from '../object-form-options.interface';
import {FormStatusChangedEvent} from '../form-status-changed-event.interface';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';
import {DmsObject, SystemService, DmsService, NotificationsService, Utils} from '@eo-sdk/core';


@Component({
  selector: 'eo-object-form-edit',
  templateUrl: './object-form-edit.component.html',
  styleUrls: ['./object-form-edit.component.scss']
})
export class ObjectFormEditComponent {

  showConflictDialog = false;
  conflictError: any;

  // ID set by pendingChanges service when editing indexdata
  // Used to finish the pending task when editing is done
  private pendingTaskId: string;

  // fetch a reference to the opbject form component to be able to
  // get the form data
  @ViewChild(ObjectFormComponent) objectForm: ObjectFormComponent;

  @Input() formDisabled: boolean;

  @Input('dmsObject')
  set dmsObject(dmsObject: DmsObject) {
    if (dmsObject) {
      this._dmsObject = dmsObject;

      // reset the state of the form
      this.formState = null;
      this.controls.saving = false;
      this.controls.visible = false;

      this.createObjectForm(dmsObject);
    }
  }

  get dmsObject() {
    return this._dmsObject;
  }

  @Output() indexDataSaved = new EventEmitter<any>();

  formOptions: ObjectFormOptions;
  formState: FormStatusChangedEvent;
  controls = {
    visible: false,
    saving: false
  };

  private _dmsObject: DmsObject;
  private messages = {
    formSuccess: null,
    formError: null
  };

  // shortcut actions
  shortcuts: GlobalShortcutComponent = {
    id: 'eo.app.form.edit',
    label: this.translate.instant('eo.shortcuts.form.title'),
    labelKey: 'eo.shortcuts.form.title',
    actions: [{
      name: this.translate.instant('eo.shortcuts.form.action.save'),
      nameKey: 'eo.shortcuts.form.action.save',
      shortcut: 'S',
      onExecuteAction: () => {
        this.save();
      },
      isHidden: () => {
        return !this.formState || !this.formState.dirty || this.formState.invalid || !Utils.isVisible(this.element.nativeElement.parentElement);
      }
    }, {
      name: this.translate.instant('eo.shortcuts.form.action.reset'),
      nameKey: 'eo.shortcuts.form.action.reset',
      shortcut: 'R',
      onExecuteAction: () => {
        this.reset();
      },
      isHidden: () => {
        return !this.formState || !this.formState.dirty || !Utils.isVisible(this.element.nativeElement.parentElement);
      }
    }, {
      name: this.translate.instant('eo.shortcuts.form.action.edit'),
      nameKey: 'eo.shortcuts.form.action.edit',
      shortcut: 'E',
      onExecuteAction: () => {
        this.objectForm.focusForm();
      },
      isHidden: (target) => {
        return !this.formOptions || !Utils.isVisible(this.element.nativeElement.parentElement) || this.element.nativeElement.contains(target);
      }
    }]
  };

  constructor(private systemService: SystemService,
    private dmsService: DmsService,
    private element: ElementRef,
    private notication: NotificationsService,
    private pendingChanges: PendingChangesService,
    private router: Router,
    public translate: TranslateService) {

    this.translate.get([
      'eo.object.indexdata.save.success',
      'eo.object.indexdata.save.error'
    ]).subscribe((res) => {
      this.messages.formSuccess = res['eo.object.indexdata.save.success'];
      this.messages.formError = res['eo.object.indexdata.save.error'];
    });
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskId || ' ')) {
      this.pendingTaskId = this.pendingChanges.startTask();
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskId);
  }

  onFormStatusChanged(evt) {
    this.formState = evt;
    this.controls.visible = this.formState.invalid || this.formState.dirty;
    if (this.formState.dirty) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }

  // save the current dms object
  save(ignoreConflicts?: boolean) {
    setTimeout(() => {
      if (this.formState.dirty && !this.formState.invalid) {
        this.controls.saving = true;
        const formData = this.objectForm.getFormData();
        const {id, typeName, version} = this._dmsObject;

        this.dmsService.updateObject(id, formData, typeName, ignoreConflicts ? null : version, false)
          .pipe(finalize(() => this.finishPending()))
          .subscribe((updatedObject) => {
            this._dmsObject = updatedObject;
            this.formOptions.data = updatedObject.data;
            this.formOptions.object = updatedObject;
            this.controls.saving = false;
            this.controls.visible = false;
            this.objectForm.setFormPristine();
            this.indexDataSaved.emit(formData);
          }, (err) => {
            this.controls.saving = false;
            if(err.status === 409){
              this.conflictError = err.error;
              this.openConflictDialog();
            }
            else {
              throw new EoError(err, this._dmsObject.title, this.messages.formError, false);
            }
          }
          );
      }
    }, 500);
  }

  openConflictDialog(){
    this.showConflictDialog = true;
  }

  closeConflictDialog(){
    this.showConflictDialog = false;
  }

  showConflictChanges(){
    const navigationExtras: NavigationExtras = {
      queryParams: {
        version: this._dmsObject.version,
        version2: this.conflictError.dmsobject.version
      }
    };
    const url = this.router.createUrlTree([`versions/${this._dmsObject.id}`], navigationExtras).toString().replace(/^\/+/g, '');
    window.open(url, '_blank');
  }

  cancelSaving(){
    this.closeConflictDialog();
  }

  // reset the form to its initial state
  reset() {
    this.objectForm.resetForm();
  }

  // create the formOptions required by object form component
  private createObjectForm(dmsObject: DmsObject) {

    this.systemService
      .getObjectTypeForm(dmsObject.type.name, 'EDIT')
      .subscribe((model) => {
        this.addExceptionIDsForIDReferenceFields(model);
        this.formOptions = {
          layoutSettingsID: dmsObject.type.name,
          formModel: model,
          data: dmsObject.data,
          object: dmsObject,
          objectId: dmsObject.id,
          disabled: this.formDisabled || !this.isEditable(dmsObject)
        };
        if (dmsObject.contextFolder) {
          this.formOptions.context = {
            id: dmsObject.contextFolder.id,
            title: dmsObject.contextFolder.title,
            typeName: dmsObject.contextFolder.typeName
          }
        }
      });
  }

  private addExceptionIDsForIDReferenceFields(model: any) {
    if (model.type === 'REFERENCE') {
      model.exceptionIDs = [this._dmsObject.id];
    } else if(model.elements) {
      model.elements.forEach(el => this.addExceptionIDsForIDReferenceFields(el));
    }
  }

  private isEditable(dmsObject: DmsObject): boolean {
    return dmsObject.hasOwnProperty('rights') && dmsObject.rights.edit && !dmsObject.isFinalized;
  }
}
