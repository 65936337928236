import {Component, OnInit, Input} from '@angular/core';
import {InboxItem} from '@eo-sdk/core';

@Component({
  selector: 'eo-duetimeInfo',
  templateUrl: './duetimeInfo.component.html',
  styleUrls: ['./duetimeInfo.component.scss']
})
export class DuetimeInfoComponent implements OnInit {

  _item: InboxItem;
  isOverDue: boolean;
  @Input()
  set item(item: InboxItem) {
    this._item = item;
    this.isOverDue = item.duetime && item.isOverdue();
  }

  get item(): InboxItem {
    return this._item;
  }

  constructor() {}

  ngOnInit() {
  }

}
