<div class="file" [ngClass]="{ready: context || context === null}" *ngIf="!error; else errorTmpl">

  <div class="file-head" *ngIf="context && context.isContextFolder">
    <div class="left">
      <eo-icon class="type-icon" [objectType]="context.type"></eo-icon>
      <div class="title" (click)="setupFrontPage()" (contextmenu)="onContextMenuClicked($event)">
        <div class="label">{{context.title || '&nbsp;'}}</div>
        <div class="description">{{context.description || '&nbsp;'}}</div>
      </div>
    </div>
    <div class="right">
      <div class="fold">
        <span class="created-date">{{context.created.on | localeDate}}</span>
        <span class="created-title">{{context.created.by.title}}</span>
      </div>
      <div class="actions">
      </div>
    </div>
  </div>

  <div class="file-body">
    <div class="file-body-wrap">

      <ng-container *ngIf="context !== null; else tplDetailsOnly">

        <yvc-split-view *ngIf="cacheLayout" [layoutSettingsID]="cacheLayout">
          <ng-template yvcSplitArea [panelClass]="'tree'" [size]="20" [visible]="true">
            <div class="tree-panel-error" *ngIf="loadingState.tree.isError(); else tplTree" translate>
              eo.state.object.tree.error</div>

            <ng-template #tplTree>

              <eo-list-container #eoList [loading]="!contextTree" [tabs]="false">
                <div class="eo-header dark">
                  <div class="eo-header-info">
                    <h2 class="eo-header-title" translate>eo.state.object.tree.title</h2>
                    <form name="structureForm" class="tree-filter-form" [ngClass]="{active: !!termFilter}">
                      <eo-form-input [skipToggle]="true" [label]=" 'eo.state.object.tree.filter.label' | translate">
                        <input [(ngModel)]="termFilter" maxlength="250" [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="onTermFilterChange.emit($event)" />
                      </eo-form-input>
                    </form>
                  </div>
                </div>
                <div class="eo-body tree">
                  <eo-tree [tree]="contextTree" [options]="{multiselect: false, disableDeselection: true}"
                    [@panelLoading]="loadingState.tree.state" *ngIf="contextTree" [highlightedNodes]="highlightedNodes"
                    [treeUpdate]="contextTreeUpdate" [(ngModel)]="selectedNodes"
                    (ngModelChange)="onTreeSelectionChanged($event)"></eo-tree>
                  <h3 *ngIf="contextTreeRef?.length" title="{{'eo.state.object.tree.references.tooltip'|translate}}"
                    translate>eo.state.object.tree.references</h3>
                  <eo-tree [tree]="contextTreeRef" [options]="{multiselect: false, disableDeselection: true}"
                    [@panelLoading]="loadingState.treeRef.state" *ngIf="contextTreeRef?.length"
                    [highlightedNodes]="highlightedNodes" [treeUpdate]="contextTreeRefUpdate"
                    [(ngModel)]="selectedNodes" (ngModelChange)="onTreeSelectionChanged($event)"
                    class="reference"></eo-tree>
                </div>
              </eo-list-container>

            </ng-template>
          </ng-template>

          <ng-template yvcSplitArea [panelClass]="'tree'" [size]="35" [visible]="true">
            <eo-object-state-details *ngIf="context" [context]="context" [frontPageData]="frontPageData"
              [visible]="frontPage" (onTabChange)="setupFrontPage(true)" [plugins]="'object-state-details-tab.object' | plugins">
            </eo-object-state-details>

            <eo-result-list *ngIf="!frontPage && searchResult" [selectFirst]="false" [title]="gridTitle"
              [reference]="selectedNode?.isReference" [searchResult]="searchResult" [configType]="configType"
              [configContext]="" [emptyMessage]="'eo.state.object.list.error'" (onRefresh)="refreshList()">
            </eo-result-list>
          </ng-template>

          <ng-template yvcSplitArea [size]="45" [visible]="true">
            <ng-container *ngTemplateOutlet="tplObjectDetails"></ng-container>
          </ng-template>
        </yvc-split-view>

      </ng-container>

      <ng-template #tplDetailsOnly><ng-container *ngTemplateOutlet="tplObjectDetails"></ng-container></ng-template>

      <ng-template #tplObjectDetails>
        <eo-object-details [applySelection]="{}" [reference]="isReferenceActive"
          [cacheLayout]="'object.state--' + typeName + '.object-details'" [plugins]="'object-details-tab.object' | plugins">
        </eo-object-details>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #errorTmpl>
  <eo-error404></eo-error404>
</ng-template>