import {map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, forkJoin, of as observableOf} from 'rxjs';
import {SearchService, SystemService, DmsObject} from '@eo-sdk/core';
import {UtilitiesService} from '../../eo-framework/util/services/utilities.service';

@Injectable()
export class ReferenceService {

  constructor(private searchService: SearchService,
              private systemService: SystemService) {
  }


  getQueries(dmsObject: DmsObject): Observable<any> {
    const result = [this.getOutReferenceQueries(dmsObject), this.getInReferenceQueries(dmsObject)];
    return forkJoin(result).pipe(map(([outReferenceQueries, inReferenceQueries]) => ({outReferenceQueries, inReferenceQueries})));
  }

  private getOutReferenceQueries(dmsObject: DmsObject): Observable<any> {
    return this.getOutReferenceMetaDatas(dmsObject).pipe(
      map(res => res.map(metaData => {
          let query = this.createOutReferenceQuery(metaData);
          delete query.fields;
          return query;
        })));
  }

  private getInReferenceQueries(dmsObject: DmsObject) {
    return this.getInReferenceMetaDatas(dmsObject)
      .pipe(
        map(refTypes => refTypes.map(referencedType => {
            let query = this.createInReferenceQuery(dmsObject, referencedType);
            delete query.fields;
            return query;
          })));
  }

  getOutReferences(dmsObject: DmsObject): Observable<any> {
    let obs = [];
    let outReferenceMetaDatas;
    return this.getOutReferenceMetaDatas(dmsObject).pipe(
      mergeMap(response => {
          outReferenceMetaDatas = response;
          outReferenceMetaDatas.forEach(metaData => {
            let query = this.createOutReferenceQuery(metaData);
            let promise = this.executeQuery(query);
            obs.push(promise);
          });
          return obs.length > 0 ? forkJoin(obs) : observableOf([]);
        }
      ),
      map(results => this.mergeQueryResults(results, outReferenceMetaDatas)));
  }

  private mergeQueryResults(results, metaDatas) {
    let mergedResult = [];
    results.forEach((result, index) => {
      result.forEach((hit) => hit._source.metaData = metaDatas[index]);
      mergedResult = mergedResult.concat(result);
    });
    return mergedResult.map(mR => mR._source);
  }

  private createOutReferenceQuery(outReferenceMetaData) {
    let query;
    if (outReferenceMetaData.remoteElement) {
      query = {
        filters: {},
        fields: ['title', 'description', 'type'],
        types: [outReferenceMetaData.objectType.name],
        options: {
          suggest: true,
          searchmode: 'idxs'
        }
      };

      query.filters[outReferenceMetaData.remoteElement.qname] = {
        o: Array.isArray(outReferenceMetaData.value) ? 'in' : 'eq',
        v1: outReferenceMetaData.value
      };
    } else {
      query = {
        term: '',
        fields: ['title', 'description'],
        options: {expertmode: true},
        types: [outReferenceMetaData.objectType.name]
      };
      outReferenceMetaData.value.forEach((value, index, arr) => {
        query.term += '_id:' + value + (index === arr.length - 1 ? '' : ' || ');
      });
    }
    return query;
  }

  private getOutReferenceMetaDatas(dmsObject: DmsObject): Observable<any> {
    let metaDatas = [];
    let types = this.systemService.getObjectTypes();

    let formElements = types.find(t => t.name === dmsObject.typeName).elements;
    let outReferenceFields = this.getOutReferenceFields(formElements);

    outReferenceFields.forEach(field => {
      let objectType = types.find(t => t.qname === field.reference.type);
      if (objectType && objectType.isAbstract) {
        let subTypes = this.getSubTypes(objectType.name, types);
        subTypes.forEach(subType => {
            let remoteElement = field.type === 'STRING' ? subType.elements.find(el => el.qname === field.reference.element) : null;
            let values = dmsObject.data[field.name];
            if (values) {
              if (!Array.isArray(values)) {
                values = [values];
              }
              let metaData = {
                groupField: objectType.name + (remoteElement ? remoteElement.label : 'ID'),
                remoteElement: remoteElement,
                objectType: subType,
                element: field,
                value: values
              };
              metaDatas.push(metaData);
            }
          }
        )
      } else if (objectType) {
        let remoteElement = field.type === 'STRING' ? objectType.elements.find(el => el.qname === field.reference.element) : null;
        let values = dmsObject.data[field.name];
        if (values) {
          if (!Array.isArray(values)) {
            values = [values];
          }
          let metaData = {
            groupField: objectType.name + (remoteElement ? remoteElement.label : 'ID'),
            remoteElement: remoteElement,
            objectType: objectType,
            element: field,
            value: values
          };
          metaDatas.push(metaData);
        }
      }
    });

    return observableOf(metaDatas.filter(metaData => !UtilitiesService.isEmpty(metaData.value)));
  }

  private getOutReferenceFields(elements) {
    let outReferenceFields = [];
    elements.forEach(elem => {
      if (elem.reference) {
        outReferenceFields.push(elem);
      }
    });
    return outReferenceFields;
  }

  getInReferences(dmsObject: DmsObject): Observable<any> {
    let obs = [];
    let inReferenceMetaDatas = [];
    return this.getInReferenceMetaDatas(dmsObject)
      .pipe(
        mergeMap(metaDatas => {
            metaDatas.forEach(metaData => {
              if (metaData.remoteElement.type === 'REFERENCE' || dmsObject.data[metaData.remoteElement.reference.element.split('.')[1]]) {
                let query = this.createInReferenceQuery(dmsObject, metaData);
                let ob = this.executeQuery(query);
                inReferenceMetaDatas.push(metaData);
                obs.push(ob);
              }
            });
            return obs.length > 0 ? forkJoin(obs) : observableOf([]);
          }
        ),
        map(results => this.mergeQueryResults(results, inReferenceMetaDatas))
      );
  }

  private executeQuery(query: object) {
    return this.searchService
      .executeQuery(query, undefined, 10000)
      .pipe(map(res => res.hits.hits));
  }

  private createInReferenceQuery(dmsObject: DmsObject, referencedType) {
    let query;
    query = {
      filters: {},
      fields: ['title', 'description', 'type'],
      types: [referencedType.objectType.qname],
      options: {
        suggest: true,
        searchmode: 'idxs'
      }
    };

    const {multiselect, qname} = referencedType.remoteElement;
    if (referencedType.remoteElement.type === 'STRING') {
      const reference = referencedType.remoteElement.reference.element.split('.')[1];
      query.filters[qname] = {
        o: multiselect ? 'in' : 'eq',
        v1: multiselect && !Array.isArray(dmsObject.data[reference]) ? [dmsObject.data[reference]] : dmsObject.data[reference]
      };
    } else {
      query.filters[qname] = {
        o: multiselect ? 'in' : 'eq',
        v1: multiselect ? [dmsObject.id] : dmsObject.id
      };
    }
    return query;
  }

  private getInReferenceMetaDatas(dmsObject: DmsObject): Observable<any> {
    let types = this.systemService.getObjectTypes();
    let superTypes = this.getSuperTypes(dmsObject.typeName, types);
    let metaDatas = [];

    types.forEach(objectType => {
      if (!objectType.isAbstract) {
        objectType.elements
          .forEach(element => {
            if (element.reference && (element.reference.type === dmsObject.type.name || this.isReferenceOnSuperType(element.reference.type, superTypes))) {
              let metaData = {
                groupField: objectType.name + element.label,
                objectType: objectType,
                remoteElement: element
              };
              metaDatas.push(metaData);
            }
          });
      }
    });
    return observableOf(metaDatas);
  }

  private isReferenceOnSuperType(typeName, superTypes) {
    let isReferenceOnSuperType = false;
    superTypes.forEach(superType => {
        if (superType.qname === typeName) {
          isReferenceOnSuperType = true;
        }
      }
    );
    return isReferenceOnSuperType;
  }

  private getSuperTypes(typeName, types) {
    let superTypes = [];
    let typeDefinition = types.find(t => t.qname === typeName);

    if (typeDefinition && typeDefinition.supertypes) {
      typeDefinition.supertypes
        .forEach(sType => {
          superTypes = superTypes.concat(this.getSuperTypes(sType, types));
          let superType = types.find(t => t.qname === sType);
          if (superType) {
            superTypes.push(superType);
          }
        });
    }
    return superTypes;
  }

  private getSubTypes(typeName, types) {
    let subTypes = [];
    types.forEach(objectType => {
      if (!!objectType.supertypes.find(sT => sT === typeName)) {
        subTypes.push(objectType);
      }
    });
    return subTypes;
  }

  fetchIDReferenceMetaData(values): Observable<any> {
    let query = {
      term: '',
      fields: ['title', 'description', 'type'],
      options: {expertmode: true}
    };
    values.forEach((val, index, arr) => {
      query.term += '_id:' + val + (index === arr.length - 1 ? '' : ' || ');
    });
    return !query.term ? observableOf([]) : this.executeQuery(query).pipe(
      map(hits => {
        return hits.map(hit => {
          return {
            id: hit._source.id,
            title: hit._source.title,
            description: hit._source.description,
            type: hit._source.type
          };
        });
      })
    );
  }
}
