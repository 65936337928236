
import {of as observableOf, Observable} from 'rxjs';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {SimpleAction} from '../../interfaces/action.interface';
import {DmsObject, UserService, DmsService} from '@eo-sdk/core';

@Component({
  selector: 'eo-remove-subscription-action',
  template: ``
})
export class RemoveSubscriptionActionComponent extends DmsObjectTarget implements SimpleAction {
  label: string;
  description: string;
  priority = 4;
  iconSrc = 'assets/_default/svg/ic_subscription.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  constructor(private translate: TranslateService, private userService: UserService, private dms: DmsService) {
    super();
    this.label = this.translate.instant('eo.action.delete.subscription.label');
    this.description = this.translate.instant('eo.action.delete.subscription.description');
  }

  isExecutable(element: DmsObject) {
    const hasPrivilege = this.userService.getCurrentUser().hasPrivilege('MANAGE_SUBSCRIPTIONS');
    if (hasPrivilege && !window.location.href.includes('/versions')) {
      return observableOf(!!element.subscriptions.length);
    } else {
      return observableOf(false);
    }
  };

  run(selection) {
    selection.forEach(item => {
      this.dms.removeSubscriptionById(item.subscriptions[0].id, item).subscribe();
    });
  }

}
