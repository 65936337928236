import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import {ObjectFormControlWrapper} from '../../../object-form/object-form/object-form-control-wrapper';
import {ObjectFormControl} from '../../../object-form/object-form/object-form-control';

@Component({
  selector: 'eo-dynamic-property-switch',
  templateUrl: './dynamic-property-switch.component.html',
  styleUrls: ['./dynamic-property-switch.component.scss']
})
export class DynamicPropertySwitchComponent {

  value: boolean;
  controlName: string;
  @HostBinding('class.disabled') disabled: boolean;

  @Input()
  set parentFormControl(fc: ObjectFormControlWrapper) {
    if (fc) {
      let formControl: ObjectFormControl = fc.controls[fc._eoFormControlWrapper.controlName] as ObjectFormControl;
      if (formControl && formControl._eoFormElement) {
        this.value = formControl._eoFormElement._dynamic;
        this.controlName = fc._eoFormControlWrapper.controlName;
        this.disabled = formControl._eoFormElement.type === 'TABLE'; // Form elements of type 'TABLE' make no sense.
      }
    }
  }

  /**
   * control: qname of the target form element
   * value: based on whether or not the element should be dynamic
   */
  @Output() onPropertyChanged = new EventEmitter<{control: string, value: boolean}>();

  constructor() { }

  toggleValue() {
    if (!this.disabled) {
      this.value = !this.value;
      this.onPropertyChanged.emit({
        control: this.controlName,
        value: this.value
      });
    }
  }
}
