<div class="configurator">

  <div class="bar">
    <div class="title" translate>eo.column.config.title</div>
    <button (click)="resetDefault()" *ngIf="!isDefaultConfig" translate>eo.column.config.action.resetdefault</button>
    <button (click)="toggleConfigMode()" translate>eo.column.config.action.close</button>
    <button (click)="saveConfig(true)" class="primary" [disabled]="!currentFields?.length" *ngIf="hasPrivilege" translate>eo.column.config.action.save.default</button>
    <button (click)="saveConfig()" class="primary" [disabled]="!currentFields?.length" translate>eo.column.config.action.save</button>
  </div>

  <div class="info" *ngIf="info">{{info}}</div>

  <div class="column-config" cdkDropListGroup>

    <div class="scroller current">
      <div class="headline" translate>eo.column.config.headline.current</div>
      <div class="items" cdkDropList (cdkDropListDropped)="drop($event, 'current')" [cdkDropListData]="currentFields">
        <div class="item" cdkDragHandle cdkDrag [cdkDragData]="field" *ngFor="let field of currentFields; index as i">
          <div class="entry" [ngClass]="{modified: field.confmeta.modified, sfe: field.selectedforenrichment}">
            <div class="label" [ngClass]="{baseparam: field.baseparameter}">{{field.label}}</div>
            <div class="group" [ngClass]="{on: field.confmeta.pinned}" (click)="togglePinned(field, $event)" translate>
              <span class="config-label" translate>eo.column.config.pinned</span>
            </div>
            <!--<div class="group" [ngClass]="{on: field.confmeta.group}" (click)="toggleGroup(field, $event)" translate>eo.column.config.group</div>-->
            <div class="sort" [ngClass]="{on: field.confmeta.sort, asc: field.confmeta.sort === 'asc', desc: field.confmeta.sort === 'desc', disabled: !field.sortable}"
            (click)="field.sortable && toggleSort(field, $event)"><span class="config-label" translate>eo.column.config.sort</span></div>
            <div class="alignment" [ngClass]="{on: field.confmeta.alignmentx, left: field.confmeta.alignmentx === 'left',
            right: field.confmeta.alignmentx === 'right', center: field.confmeta.alignmentx === 'center'}"
                 (click)="toggleAlignment(field, $event)"><span class="config-label" translate>eo.column.config.alignment</span>
              <img *ngIf="field.confmeta.alignmentx === 'center'" class="center-second" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAGFBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAB5sPD/AAAACHRSTlMAAAEnK2xwihv2e6MAAAAkSURBVAgdBcGBAQAgDMMgmvn/zcIGMO6xUBHXIqLkYkmQDfABCHAAOCt/xSYAAAAASUVORK5CYII=">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="scroller available">
      <div class="headline" translate>eo.column.config.headline.available</div>
      <div class="items" cdkDropList (cdkDropListDropped)="drop($event, 'available')" [cdkDropListData]="availableFields">
        <div class="item" cdkDragHandle cdkDrag [cdkDragData]="field" *ngFor="let field of availableFields; index as i">
          <div class="entry" [ngClass]="{modified: field.confmeta.modified, sfe: field.selectedforenrichment}">
            <div class="label" [ngClass]="{baseparam: field.baseparameter}">{{field.label}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
