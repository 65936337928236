<div id="eo-upload-overlay" #overlay class="dark" *ngIf="fileOver" tabindex="1"
     [ngClass]="{hasFiles: queue.length, invalid: invalidInput, singleFile: queue.length === 1, uploading: isUploading}">

  <eo-icon class="button white close" [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="close()"></eo-icon>

  <div class="count">
    <div>{{queue.length}}</div>
    <div class="queueSize">{{queueSize | fileSize}}</div>
  </div>

  <h1 translate>eo.upload.global.headline</h1>
  <eo-icon class="uploady" [iconSrc]="'assets/_default/svg/ic_upload.svg'"></eo-icon>

  <!-- batch upload dialog -->
  <div class="batch-dialog" *ngIf="!invalidInput && selectedUploadTarget && !isUploading">

    <h2 translate>eo.upload.global.choose.title</h2>
    <p translate>eo.upload.global.choose.message</p>
    <div class="actions">
      <button (click)="resetSelectedUploadTarget()" translate>eo.upload.global.cancel</button>
      <button (click)="proceedWithSelectedTarget(false)" class="primary" translate>eo.upload.global.choose.single</button>
      <button (click)="proceedWithSelectedTarget(true)" class="primary" translate>eo.upload.global.choose.multi</button>
    </div>
  </div>

  <!-- invalid input dialog -->
  <div class="batch-dialog" *ngIf="hasValidUploadTargets && invalidInput">

    <h2 translate>eo.upload.global.invalid.title</h2>
    <p translate>eo.upload.global.invalid.message</p>
    <div class="actions">
      <button (click)="close()" translate>eo.upload.global.close</button>
    </div>
  </div>

  <!-- no upload targets available -->
  <div class="batch-dialog" *ngIf="!hasValidUploadTargets">

    <h2 translate>eo.upload.global.notarget.title</h2>
    <p translate>eo.upload.global.notarget.message</p>
    <div class="actions">
      <button (click)="close()" translate>eo.upload.global.close</button>
    </div>
  </div>

  <div class="contents" *ngIf="hasValidUploadTargets && !invalidInput && !selectedUploadTarget && !isUploading">

    <!-- list of available upload targets -->
    <div class="targets">

      <div class="target-list">

        <h2 translate>eo.upload.global.target</h2>

        <div class="upload-target" *ngFor="let t of validUploadTargets; trackBy: targetTrackByFn"
             (click)="proceedWithUpload(t)"
             [ngSwitch]="t.type">

          <!-- upload to root -->
          <ng-container *ngSwitchCase="'root'" >
            <eo-icon [iconSrc]="'assets/_default/svg/ic_create_document.svg'"></eo-icon>
            <div class="upload-target-general">
              <div class="name" translate>eo.upload.global.root.title</div>
              <div class="description" translate>eo.upload.global.root.description</div>
            </div>
          </ng-container>

          <!-- replace file of a specific dms object -->
          <ng-container *ngSwitchCase="'object'">
            <eo-icon [iconSrc]="'assets/_default/svg/ic_add_document_file_as_new_version.svg'"></eo-icon>
            <div class="upload-target-replace">
              <div class="name" *ngIf="t.referenceObject.content;else nocontent" translate>eo.upload.global.object.title</div>
              <ng-template #nocontent>
                <div class="name" translate>eo.upload.global.object.title.nocontent</div>
              </ng-template>
              <div class="description">{{t.description}}</div>
            </div>
          </ng-container>

          <!-- add new files to a context folder -->
          <ng-container *ngSwitchCase="'context'">
            <eo-icon [iconSrc]="'assets/_default/svg/ic_create_document_in_folder.svg'"></eo-icon>
            <div class="upload-target-context">
              <div class="name">{{'eo.upload.global.context.title'|translate:({context:t.referenceObject.type.label})}}</div>
              <div class="description">{{t.description}}</div>
            </div>
          </ng-container>

          <!-- add new files to a context folder structure tree entry -->
          <ng-container *ngSwitchCase="'contexttree'">
            <eo-icon [iconSrc]="'assets/_default/svg/ic_create_document_in_folder.svg'"></eo-icon>
            <div class="upload-target-contexttree">
              <div class="name">{{'eo.add.title.location.subfolder'|translate: ({folder: t.subFolder.name})}}</div>
            </div>
          </ng-container>

          <!-- custom upload target (e.g. from plugin component) -->
          <ng-container *ngSwitchCase="'custom'">
            <eo-icon [iconSrc]="'assets/_default/svg/ic_upload.svg'"></eo-icon>
            <div class="upload-target-custom">
              <div class="name">{{t.name}}</div>
              <div class="description">{{t.description}}</div>
            </div>
          </ng-container>
        </div>

      </div>

    </div>

    <!-- list of files dragged to the component -->
    <div class="files">
      <div class="files-list">
        <div class="file-item" *ngFor="let i of queue; trackBy: trackByFn">
          <div>
            <div class="name">{{i.name}}</div>
            <div class="desc"><span class="size">{{i.size | fileSize}}</span><span class="desc-text">&nbsp;{{i.type}}</span></div>
          </div>
          <eo-icon class="button white" (click)="removeFileItem(i)" [iconSrc]="'assets/_default/svg/ic_clear.svg'"></eo-icon>
        </div>
      </div>
    </div>

  </div>

  <div class="loading" *ngIf="isUploading"><eo-loading-spinner [size]="'large'"></eo-loading-spinner></div>


</div>
