<div class="eo-settings" *ngIf="user" eoRtlAware>

  <div class="header">

    <div #headerBg class="bg"></div>
    <div class="fill">
      <div class="meta username">{{user.name}}</div>
      <h1>{{user.firstname}} {{user.lastname}}</h1>
      <div class="meta email">{{user.email}}</div>

      <div class="presence" *ngIf="capabilities.inbox">
        <button class="toggle dark present" id="presence" (click)="setPresence(true)" [ngClass]="{active: user.present}"
         translate>eo.state.settings.presence.present</button>
        <button class="toggle dark absent" (click)="setPresence(false)" (keydown.tab)="tabAction($event.target)" [ngClass]="{active: !user.present}"
          translate>eo.state.settings.presence.absent</button>
      </div>
    </div>
    <div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (keydown.tab)="mouseEnter()">

      <eo-user-avatar #userAvatar class="userImage" tabindex="-1" eoRtlAware="full">
          <input type="file" accept=".jpg, .png" style="display:none;" #files (change)="selectImage($event.target)" (click)="$event.target.value=null">
      </eo-user-avatar>
      <eo-dialog  [styleClass]="'view'" [minWidth]="'300px'" [title]="'eo.state.settings.details.upload.dialog.title' | translate"
        [(visible)]="showPreviewDialog" [focusOnShow]="false">
        <ng-container>
          <div class="contain">
            <div class="attachments">
              <div class="atta">
                <img loading="lazy" [src]="preview">
              </div>
              <div class="user-avatar" *ngIf="preview">
                <eo-user-avatar class="preview-round" [previewUri]="preview"></eo-user-avatar>
              </div>
            </div>
          </div>
          <div class="button-block">
            <button type="button" (click)="closePreviewDialog()" class="button danger"
              translate>eo.state.settings.details.delete.dialog.cancel</button>
            <button type="button" cdkFocusInitial (click)="uploadNewUserImage()" class="button primary"
              translate>eo.state.settings.details.dialog.save</button>
          </div>
        </ng-container>
      </eo-dialog>

      <div class="overlay" #profileImageOverlay tabindex="0" eoRtlAware="full">
        <span class="updateMessage" *ngIf="hoverSelector">
          <eo-icon tabindex="0" (keydown.enter)="files.click()" [iconSrc]="'assets/_default/svg/ic_edit.svg'"
            (click)="files.click()"></eo-icon>
          <eo-icon tabindex="0" (keydown.enter)="trashIconClick()" *ngIf="showDeleteIconTrash"
            [iconSrc]="'assets/_default/svg/ic_trash.svg'" aria-disabled="true" (click)="trashIconClick()"></eo-icon>
        </span>
      </div>
    </div>

  </div>

  <div class="body" (keydown.tab)="tabAction($event.target)">

    <div class="body-wrap">

      <div class="section section-deputies" *ngIf="capabilities.bpm && hasPrivilege('MANAGE_DEPUTY_LIST')">
        <h3 translate>eo.state.settings.deputies</h3>

        <!-- deputies -->
        <div class="entry deputies">
          <div class="label" translate>eo.state.settings.deputies.label</div>
          <div class="values">
            <form #deputiesForm="ngForm" [ngClass]="{dirty: deputiesForm.dirty}">
              <eo-organization name="deputies" [dataMeta]="deputies.dataMeta" [filterObject]="{type: 'USER'}"
                [exceptions]="[user.name]" [multiselect]="true" tabindex="0" [(ngModel)]="deputies.data"></eo-organization>
              <div class="actions" *ngIf="deputiesForm.valid && deputiesForm.dirty">
                <button class="secondary" (click)="resetDeputies()" translate>eo.state.settings.deputies.cancel</button>
                <button class="primary" (click)="saveDeputies()" translate>eo.state.settings.deputies.save</button>
              </div>
            </form>
          </div>
        </div>

        <!-- substitute of -->
        <div class="entry substitute">
          <div class="label" translate>eo.state.settings.deputies.subsituteof.label</div>
          <div class="values">
            <span class="chip" *ngFor="let sub of user.substitutesOf" [ngClass]="{present: sub.present}">
              <eo-icon class="chip substitute substitute--present" *ngIf="sub.present"
                [iconSrc]="'assets/_default/svg/ic_done.svg'"></eo-icon>
              <eo-icon class="chip substitute substitute--away" *ngIf="!sub.present"
                [iconSrc]="'assets/_default/svg/ic_clear.svg'"></eo-icon>
              <span>{{sub.title}} ({{sub.name}})</span>
            </span>
          </div>
        </div>
      </div>

      <!-- password -->
      <section class="section section-password">
        <div class="entry">
          <div class="label" translate>eo.password.reset</div>
          <div class="values">
            <eo-simple-accordion #simpleAcc [header]="('eo.password.reset' | translate)"
              [styles]="'setting__change-password'" [headerClass]="'setting__change-password-header'">

              <eo-change-password-form (onFormSumbit)="simpleAcc.selected = false"></eo-change-password-form>
            </eo-simple-accordion>
          </div>
        </div>
      </section>

      <!-- language -->
      <div class="section section-lang">
        <h3 translate>eo.state.settings.language</h3>
        <div class="entry lang-app">
          <div class="label" translate>eo.state.settings.language.client</div>
          <div class="values">
            <button class="toggle" (click)="changeClientLocale(locale.iso)"
              [ngClass]="{active: translate.currentLang === locale.iso}"
              *ngFor="let locale of clientLocales">{{locale.label}}
            </button>
          </div>
        </div>

        <div class="entry lang-def">
          <div class="label" translate>eo.state.settings.language.schema</div>
          <div class="values">
            <button class="toggle" (click)="changeSchemaLocale(locale.code)"
              [ngClass]="{active: activeSchema === locale.code}"
              *ngFor="let locale of schemaLocales">{{locale.displayname}}</button>
          </div>
        </div>
      </div>

      <!-- permissions -->
      <div class="section section-perm">
        <h3 translate>eo.state.settings.permission</h3>
        <div class="entry perm-roles">
          <div class="label" translate>eo.state.settings.roles</div>
          <div class="values roles">
            <eo-simple-accordion [header]="('eo.state.settings.roles' | translate)">
              <eo-permissions [roles]="user.roles"></eo-permissions>
            </eo-simple-accordion>
          </div>
        </div>
      </div>

      <!-- agent -->
      <div class="section section-others">
        <h3 translate>eo.state.settings.others</h3>
        <ng-container *ngIf="showAgentConfig">
          <div class="entry">
            <div class="label" translate>eo.state.settings.agent</div>
            <div class="values">
              <div class="use-agent">
                <eo-checkbox [formControl]="useAgentControl" [readonly]="useAgentControl.disabled"
                  *ngIf="useAgentControl"></eo-checkbox>
                <span class="use-agent-label" translate>eo.state.settings.agent.use</span>
                <!-- <a href="https://help.optimal-systems.com/yuuvisRAD/v60/user/client/de/client/integration/cln_tsk_agent_global.htm?Highlight=agent">
          <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'"></eo-icon>
        </a> -->
              </div>
            </div>
          </div>


          <div class="entry lock-settings" [ngClass]="{'lock-settings--show': (agentStatus | async)}">
            <div class="label" translate>eo.state.settings.lock</div>
            <div class="values">
              <button class="toggle button-enabled" (click)="changeLockSettings(LockSettings.always)"
                [ngClass]="{active: agentLockSettings === LockSettings.always}" [disabled]="defaultLockSetting"
                translate>eo.state.settings.lock.enable</button>
              <button class="toggle button-disabled" (click)="changeLockSettings(LockSettings.never)"
                [ngClass]="{active: agentLockSettings === LockSettings.never}" [disabled]="defaultLockSetting"
                translate>eo.state.settings.lock.disable</button>
              <button class="toggle button-ask" (click)="changeLockSettings(LockSettings.ask)"
                [ngClass]="{active: agentLockSettings === LockSettings.ask}" [disabled]="defaultLockSetting"
                translate>eo.state.settings.lock.ask</button>
            </div>
          </div>


        </ng-container>

        <div class="entry">
          <div class="label" translate>eo.state.settings.cache</div>
          <div class="values">
            <div class="flex-row">
              <button translate (click)="clearCache()">eo.state.settings.cache.clear</button>
              <div class="cache">
                <eo-form-input class="checkbox" [skipToggle]="true"
                  [label]="'eo.state.settings.config.cache.system' | translate">
                  <eo-checkbox [(ngModel)]="cache.system" [ngModelOptions]="{ standalone: true }"></eo-checkbox>
                </eo-form-input>
                <eo-form-input class="checkbox" [skipToggle]="true"
                  [label]="'eo.state.settings.config.cache.history' | translate">
                  <eo-checkbox [(ngModel)]="cache.history" [ngModelOptions]="{ standalone: true }"></eo-checkbox>
                </eo-form-input>
                <eo-form-input class="checkbox" [skipToggle]="true"
                  [label]="'eo.state.settings.config.cache.layout' | translate">
                  <eo-checkbox [(ngModel)]="cache.layout" [ngModelOptions]="{ standalone: true }"></eo-checkbox>
                </eo-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="entry">
          <div class="label" translate>eo.state.settings.localSettings</div>
          <div class="values">
            <div class="flex-row">
              <button translate (click)="saveLocalSettings()">eo.state.settings.localSettings.save</button>
              <button translate (click)="loadLocalSettings()">eo.state.settings.localSettings.load</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <eo-dialog [title]="'eo.state.settings.details.delete.dialog.title' | translate" [(visible)]="showDeleteDialog"
    [focusOnShow]="false" [minWidth]="400" [styleClass]="'delete__dialog'">
    <div>{{'eo.state.settings.details.delete.dialog.message' | translate}}</div>

    <div class="action-buttons image-delete--action-buttons flex-row" >
      <button type="button" class="button cancel" (click)="showDeleteDialog = false"
      translate>eo.state.settings.details.delete.dialog.cancel</button>
      <button type="button" cdkFocusInitial #confirmDeleteBtn (click)="deleteUserImage()" class="button primary"
        translate>eo.state.settings.details.delete.dialog.ok</button>
    </div>
  </eo-dialog>

  <eo-dialog [title]="'eo.state.settings.details.upload.dialog.title' | translate" [(visible)]="showErrorDialog"
    [focusOnShow]="false" [minWidth]="400" [styleClass]="'delete__dialog'">

    <div>
      <p>{{fileName}}</p>
      <p *ngIf="viewMode ==='size'" translate>eo.state.settings.details.upload.dialog.sizeError</p>
      <p *ngIf="viewMode ==='image'" translate>eo.state.settings.details.upload.dialog.fileError </p>
    </div>

    <div class="action-buttons image-delete--action-buttons flex-row">
      <button type="button" class="button cancel" (click)="showErrorDialog = false"
        translate>eo.state.settings.details.delete.dialog.cancel</button>
      <button type="button" cdkFocusInitial (click)="files.click(); showErrorDialog = false" class="button primary"
        translate>eo.state.settings.details.upload.dialog.back</button>
    </div>
  </eo-dialog>
</div>
