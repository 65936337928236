import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiModule} from '../ui/ui.module';
import {ObjectDetailsComponent} from './object-details.component';
import {FormsModule} from '@angular/forms';
import {MediaModule} from '../media/media.module';
import {ShortcutsModule} from '../shortcuts/shortcuts.module';
// import {TabContainerModule} from '../tab-container/tab-container.module';
import {ObjectFormModule} from '../object-form/object-form.module';
import {PipesModule} from '../../eo-framework-core/pipes/pipes.module';
import {ObjectHistoryComponent} from './object-history/object-history.component';
import {ObjectLinksComponent} from './object-links/object-links.component';
import {FavoriteIconComponent} from './favorite-icon/favorite-icon.component';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {RouterModule} from '@angular/router';
import {HistoryFilterPipe} from './object-history/history-filter/history-filter-pipe/history-filter.pipe';
import {HistoryFilterComponent} from './object-history/history-filter/history-filter.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {EditIconComponent} from './edit-icon/edit-icon.component';
import {ActionModule} from '../actions/action.module';
import {YvcTabsModule} from '@yuuvis/components/tabs';
import {YvcSplitTabsModule} from '@yuuvis/components/split-tabs';
import {RecyclebinDetailsComponent} from '../recyclebin-details/recyclebin-details.component';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';
import {PluginsModule} from '../plugins/plugins.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FormElementsModule,
    ObjectFormModule,
    MediaModule,
    ShortcutsModule,
    UiModule,
    PipesModule,
    RouterModule,
    PipesModule,
    AgGridModule,
    ActionModule,
    YvcTabsModule,
    YvcSplitTabsModule,
    YvcSplitViewModule,
    PluginsModule
  ],
  declarations: [
    ObjectDetailsComponent,
    RecyclebinDetailsComponent,
    ObjectHistoryComponent,
    ObjectLinksComponent,
    FavoriteIconComponent,
    HistoryFilterPipe,
    HistoryFilterComponent,
    EditIconComponent
  ],
  exports: [ObjectDetailsComponent, RecyclebinDetailsComponent, ObjectHistoryComponent, ObjectLinksComponent, FavoriteIconComponent, HistoryFilterPipe]
})
export class ObjectDetailsModule {
}
