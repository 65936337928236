<div class="eo-global-shortcuts" *ngIf="shortcutsVisible" #shortcuts tabindex="-1">
  <eo-icon class="button white close" [iconSrc]="'assets/_default/svg/ic_clear.svg'" [iconTitle]="('eo.search.storedquery.close' | translate)"
           (click)="hideShortcutsPanel()">
  </eo-icon>
  <h1 translate>eo.shortcuts.title</h1>
  <div class="sections" tabindex="-1">
    <eo-global-shortcuts-section [component]="cmp"
                                 [target]="target"
                                 (onActionExecuted)="onActionExecuted($event)"
                                 *ngFor="let cmp of items"></eo-global-shortcuts-section>
  </div>
</div>
