import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {DmsObject, CapabilitiesService, TranslateService} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {BackendService, NotificationsService, DmsService} from '@eo-sdk/core';

@Component({
  selector: 'eo-delete-content',
  templateUrl: './delete-content.component.html',
  styleUrls: ['./delete-content.component.scss']
})
export class DeleteContentComponent implements ActionComponent, AfterViewInit {

  deleting = false;

  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private backend: BackendService,
              private toast: NotificationsService,
              private router: Router,
              private capabilitiesService: CapabilitiesService,
              private dmsService: DmsService,
              private elemRef: ElementRef) {
  }

  run() {
    this.deleting = true;
    this.selection.forEach(item => {
      this.dmsService.removeContent(item).subscribe(() => {
        this.toast.success(item.title, this.translate.instant('eo.action.delete.content.success'));
        this.finished.emit();
      }, () => {
        this.toast.error(item.title, this.translate.instant('eo.action.delete.content.error'));
        this.finished.emit();
      });
    });
  }

  cancel() {
    this.canceled.emit();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('.primary').focus();
    });
  }
}
