import {tap} from 'rxjs/operators';
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {NavigationExtras, Router} from '@angular/router';
import {TranslateService} from '@eo-sdk/core';
import {AuthService, Logger, NotificationsService, EoError} from '@eo-sdk/core';
import {Observable} from 'rxjs';

@Injectable()
export class ErrorHandlerService implements ErrorHandler, HttpInterceptor {

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get(AuthService);
    const router = this.injector.get(Router);
    // need to use location here, because the router may not be ready
    const currentRouteUrl = location.href.substr(location.origin.length);

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (error: any) => {
      if (error instanceof HttpErrorResponse || error.isHttpErrorResponse) {
        if (error.status === 401) {
          auth.logout();
          if (!currentRouteUrl.includes('/enter')) {
            const returnUrl = currentRouteUrl;
            const uriParamQuery: NavigationExtras = {queryParams: returnUrl ? {returnUrl} : {}};
            router.navigate(['/enter'], uriParamQuery).then(() => {
              router.navigate([{outlets: {modal: null}}], <NavigationExtras>{queryParamsHandling: 'preserve'});
            });
          }
        }
      }
    }));
  }

  handleError(error) {
    if (error) {
      const logger = this.injector.get(Logger);
      const notificationsService = this.injector.get(NotificationsService);
      const translate = this.injector.get(TranslateService);

      const title = error.name ? error.name : error.toString();
      const message = error.message ? error.message : '';

      if (!message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
        logger.error(error);
      } else {
        logger.warn(error);
      }

      if (error instanceof EoError && error.skipNotification) {
        // do nothing: error is logged in console
      } else if (error instanceof HttpErrorResponse || error.isHttpErrorResponse) {

        if (error.status === 401) {
          // do nothing: interceptor handles the error
        } else if (error.status === 503 || error.status === 500) {

          // todo: is there a better way (Searchservice should not return 500)
          if (error.url.match(/\/aggregate|\/autocomplete|\/attachments/)) {
            // notificationsService.error(title, message);
          } else {
            notificationsService.error(
              translate.instant('eo.error.connection.interrupted.title'),
              translate.instant('eo.error.connection.interrupted.text'));
          }
          // TODO: remove once Agent 5 is relesed
        } else if (error.status === 404 && error.url.includes('/agent/pair/')) {
        } else {
          notificationsService.error(title, message);
        }

      } else if (error instanceof EoError) {
        notificationsService.error(title, message);
      } else if (error instanceof TypeError) {
        // notificationsService.error(title, message);
      } else if (error instanceof Error && !message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
        // notificationsService.error(title, message);
      }
    }
  }
}
