import {Component, forwardRef, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import {
  UntypedFormControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';
import {AutoComplete} from '@yuuvis/components/autocomplete';
import {TreeNode} from '../../tree/tree.component.interface';


@Component({
  selector: 'eo-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicListComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DynamicListComponent),
      multi: true,
    }
  ]
})
export class DynamicListComponent implements ControlValueAccessor, Validator, OnInit {

  @ViewChild('autocomplete') autoCompleteInput: AutoComplete;
  @ViewChild('button') autoCompleteButton;

  @Input() situation: string;
  @Input() multiselect: boolean;
  @Input() pickerTitle: string;
  @Input() readonly: boolean;

  private _filterFunc: Function;

  @Input('filterFunction')
  set filterFunction(func: Function) {
    this._filterFunc = func;
    if (this._list) {
      this.buildTree();
    }
  }

  private _list: any;

  @Input('listObject')
  set listObject(lo: any) {
    if (lo) {
      this._list = lo;
      if (lo.config && lo.config.valueField) {
        if(this.selectedNodes){
          if (this.multiselect) {
            this.selectedNodes =  this.selectedNodes.map(sn => {
              sn[lo.config.valueField] = sn[this.valueField];
              return sn;
            });
          } else {
            this.selectedNodes[lo.config.valueField] = this.selectedNodes[this.valueField];
          }
        }
        this.valueField = lo.config.valueField;
      }
      if (lo.config && lo.config.descriptionField) {
        this.descriptionField = lo.config.descriptionField;
      }
      if (lo.config && lo.config.subEntriesField) {
        this.subEntriesField = lo.config.subEntriesField;
      }
      this.buildTree();
    }
  }

  get list() {
    return this._list;
  }

  valueField = 'value';
  subEntriesField = 'entries';
  descriptionField = 'description';
  display = false;
  private dirty = false;
  private isValid = true;
  value;
  tree: TreeNode[];
  private _selectedNodes: any;
  set selectedNodes(n: any) {
    this._selectedNodes = structuredClone(n);
  }
  get selectedNodes() {
    return this._selectedNodes;
  }
  autocompleteRes;
  autocompleteValues: TreeNode[] = [];

  constructor() {
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: any): void {
    this.dirty = false;
    this.value = value || null;
    const values = !this.value || Array.isArray(this.value) ? this.value || [] : [this.value];
    if (value === null || value === undefined) {
      if (this.multiselect) {
        this.selectedNodes = [];
      } else {
        this.selectedNodes = null;
      }
    } else {
      if (this.multiselect) {
        this.selectedNodes = values.map(v => {
          let node = {};
          node[this.valueField] = v;
          return node;
        });
      } else {
        let node = {};
        node[this.valueField] = value;
        this.selectedNodes = node;
      }
    }
    const nodes = !this.selectedNodes || Array.isArray(this.selectedNodes) ? this.selectedNodes || [] : [this.selectedNodes];

    if (this._list && (nodes.length !== values.length || nodes.some((n, i) => n[this.valueField] !== values[i]))) {
      this.buildTree();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onAutoCompleteSelect(node?) {
    const wasInvalid = !this.isValid;
    this.isValid = true;
    this.setFormControlValue(wasInvalid);
  }

  onAutoCompleteUnselect(node?) {
    this.selectedNodes = this.selectedNodes.filter((sNode) => sNode.value !== node.value);
    this.setFormControlValue();
  }

  onTreeSelectionChanged(evt) {
    if (!this.multiselect) {
      this.display = false;
    }
    const wasInvalid = !this.isValid;
    this.isValid = true;
    this.setFormControlValue(wasInvalid);
  }

  onClear() {
    if (!this.multiselect) {
      this.selectedNodes = null;
    }
    this.setFormControlValue();
  }

  /**
   * Sets and propagates the form controls value based on the components inner values. Propagates only
   * when the value has changed.
   * @param forcePropagation - forces propagation even if the value hasn't been changed
   */
  private setFormControlValue(forcePropagation?: boolean) {
    let v;
    let changed: boolean;
    if (this.multiselect) {
      v = this.selectedNodes ? this.selectedNodes.map((node) => node[this.valueField]) : [];
      changed = !this.value || !(v.length === this.value.length && v.every((val) => this.value.some((curVal) => curVal === val)));
    } else {
      v = this.selectedNodes ? this.selectedNodes.data[this.valueField] : null;
      changed = v !== this.value;
    }
    if (changed || forcePropagation) {
      this.value = v;
      this.propagateChange(this.value);
    }
  }

  private buildTree() {
    this.autocompleteValues = [];
    let tree: TreeNode[] = [];
    for (let i = 0; i < this._list.entries.length; i++) {
      this.addTreeNode(tree, this._list.entries[i]);
    }
    this.tree = tree;
  }

  private addTreeNode(parentNode: TreeNode[], listEntry) {
    if (this.filterFunction && !this.filterFunction(listEntry)) {
      return;
    }
    let node: TreeNode = this.listEntryToTreeNode(listEntry);
    if (node.selectable) {
      this.autocompleteValues.push(node);
    }
    if (listEntry[this.subEntriesField]) {
      node.children = [];
      for (let i = 0; i < listEntry[this.subEntriesField].length; i++) {
        this.addTreeNode(node.children, listEntry[this.subEntriesField][i]);
      }
    }
    this.checkSelected(node);
    parentNode.push(node);
  }

  private checkSelected(node: TreeNode) {
    if (!this.value) {
      return;
    }
    if (this.multiselect) {
      for (let i = 0; i < this.value.length; i++) {
        const isTreeNodeValueInValue = node.data[this.valueField] === this.value[i];
        if (isTreeNodeValueInValue) {
          const selectedNodeThatHasValue = this.selectedNodes.find(sN => sN[this.valueField] === this.value[i]);
          if(!selectedNodeThatHasValue) {
            node.selected = true;
            this.selectedNodes.push(node);
          } else {
            selectedNodeThatHasValue.selected = true;
          }
        }
      }
    } else {
      if (node.data[this.valueField] === this.value) {
        node.selected = true;
        this.selectedNodes = node;
      }
    }
  }

  autocompleteFn(term: string) {

    this.autocompleteRes = this.autocompleteValues.filter((acNode) => {
      if (this.filterFunction && !this.filterFunction(acNode.data)) {
        return false;
      }
      if (this.multiselect) {
        if (this.selectedNodes.find((node) => acNode.data[this.valueField] === node.data[this.valueField])) {
          return false;
        }
      }
      return acNode.data[this.valueField].toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
        (acNode.data[this.descriptionField] && acNode.data[this.descriptionField].toLowerCase().indexOf(term.toLowerCase()) !== -1);
    });
  }

  private listEntryToTreeNode(listEntry): TreeNode {
    let selectable = false;
    if (this._list && this._list.config && this._list.config.allelementsselectable) {
      selectable = true;
    } else {
      selectable = !(listEntry[this.subEntriesField] && listEntry[this.subEntriesField].length > 0);
    }

    let treeNode = {
      id: listEntry.id ? listEntry.id : listEntry[this.valueField],
      name: listEntry[this.descriptionField] ? listEntry[this.valueField] + ' (' + listEntry[this.descriptionField] + ')' : listEntry[this.valueField],
      children: [],
      expanded: false,
      selected: false,
      selectable: selectable,
      data: listEntry
    };
    treeNode[this.valueField] = listEntry[this.valueField];
    treeNode[this.descriptionField] = listEntry[this.descriptionField];
    treeNode[this.subEntriesField] = listEntry[this.subEntriesField];

    return treeNode;
  }

  showDialog(event?, display = true) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
      //ignore synthetized events on enter
      if (event.type === 'click' && event.detail === 0) {
        return;
      }
    }
    this.display = !!display;
  }

  ngOnInit() {
    if (this.situation === 'SEARCH') {
      this.multiselect = true;
    }
  }

  public validate(c: UntypedFormControl) {
    return (this.isValid) ? null : {
      dynamiclist: {
        valid: false,
      },
    };
  }
}
