import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TreeNodeComponent} from './tree-node/tree-node.component';
import {TreeComponent} from './tree.component';
import {UiModule} from '../ui/ui.module';

@NgModule({
  imports: [
    CommonModule,
    UiModule
  ],
  exports: [TreeComponent],
  declarations: [TreeComponent, TreeNodeComponent]
})
export class TreeModule {
}
