<section class="history-filter">

  <div class="filter history-filter__input" *ngIf="visibleFilter.input">
    <div class="filter__input" [ngClass]="{inactive: !history?.length}">
      <eo-icon class="filter__input--icon"[iconSrc]="'assets/_default/svg/ic_filter.svg'"></eo-icon>
      <input class="filter__input--field" type="text" (keyup)="typeinput($event)" [(ngModel)]="filterterm">

    </div>
    <div class="history-filter__input--clear">
      <eo-icon class="history-filter__input--clear-icon" [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="resetFilter()" [ngClass]="{reset: filterterm}"></eo-icon>
    </div>
  </div>

  <div class="filter history-filter__selection" *ngIf="visibleFilter.select">
    <div (click)="toggleFilter('MODIFICATION')" class="selection-filter mod"
         [ngClass]="{inactive: hasFilter('MODIFICATION')}"
         translate>eo.object.history.filter.modification</div>
    <div (click)="toggleFilter('PROCESS')" class="selection-filter bpm"
         [ngClass]="{inactive: hasFilter('PROCESS')}"
         translate>eo.object.history.filter.process</div>
    <div (click)="toggleFilter('INFORMATIONAL')" class="selection-filter inf"
         [ngClass]="{inactive: hasFilter('INFORMATIONAL')}"
         translate>eo.object.history.filter.informational</div>
  </div>

</section>
