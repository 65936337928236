<div class="body" eoTrapFocus>
    <div class="navigation">
        <div class="group-title" translate>eo.email.title</div>
        <div class="nav-item">
            <a tabindex="0" (keydown.enter)="runLink()" (click)="runLink()">{{'eo.action.menu.send.email.link.label'|
                translate}}</a>
            <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'"
                (click)="showDescription('link', $event)"></eo-icon>
            <div class="description" *ngIf="showDescriptionLink" translate>eo.action.menu.send.email.link.description
            </div>
        </div>
        <div class="nav-item" *ngIf="isExecutable">
            <a tabindex="0" (keydown.enter)="runOriginal()" (click)="runOriginal()">{{'eo.action.menu.send.email.file.label'|
                translate}}</a>
            <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'"
                (click)="showDescription('original', $event)"></eo-icon>
            <div class="description" *ngIf="showDescriptionOrginal" translate>eo.action.menu.send.email.file.description</div>
        </div>
        <div class="nav-item" *ngIf="isExecutable">
            <a tabindex="0" (keydown.enter)="runPdf()" (click)="runPdf()">{{'eo.action.menu.send.email.file.pdf.label' |
                translate}}</a>
            <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'"
                (click)="showDescription('pdf', $event)"></eo-icon>
            <div class="description" *ngIf="showDescriptionPdf" translate>eo.action.menu.send.email.file.pdf.description</div>
        </div>
    </div>
    <div class="form-buttons">
        <button (click)="cancel()" translate>eo.manage.rights.cancel</button>
    </div>
</div>