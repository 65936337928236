import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {TreeNode} from '../tree.component.interface';

@Component({
  selector: 'eo-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeNodeComponent {
  @Input() node: TreeNode;
  @Output() onNodeSelected = new EventEmitter();
  @Output() onNodeExpandedChanged = new EventEmitter();

  constructor() {
  }

  trackById(index, item) {
    return item.id;
  }
}
