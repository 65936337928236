<div class="setup">
    <form [formGroup]="setupForm">
        <eo-form-input [label]="'eo.workspace.widget.setup.title' | translate" [skipToggle]="true">
            <eo-string formControlName="title"></eo-string>
        </eo-form-input>
        <eo-form-input [label]="'eo.workspace.widget.iframe.setup.url'| translate" [required]="true">
            <eo-string formControlName="url"></eo-string>
        </eo-form-input>
        <div class="err-msg" *ngIf="(setupForm.get('url').invalid && (setupForm.get('url').dirty || setupForm.get('url').touched)) || (setupForm.get('title').invalid && (setupForm.get('title').dirty || setupForm.get('title').touched))">
            <div *ngIf="setupForm.get('url').hasError('pattern')" translate> eo.form.property.string.error.classification.url </div>
            <div *ngIf="setupForm.get('title').hasError('maxlength')" [translateParams]="{maxlength: 50}" translate>eo.form.property.maxlength</div>
        </div>
    </form>
</div>
<div class="preview" *ngIf="!setupForm.get('url').invalid && !setupForm.get('title').invalid; else tpl">
    <eo-iframe-widget [widgetConfig]="widgetConfig"></eo-iframe-widget>
</div>
<ng-template #tpl>
    <div class="empty">
        <eo-error-message
          [emptyState]="{icon: 'ic_noUrl.svg', text: 'eo.form.property.string.error.classification.url'}"></eo-error-message>
      </div>
</ng-template>
