import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EoSharedModule} from '@eo-sdk/core';
import {ShortcutsService} from './shortcuts.service';
import {GlobalShortcutsComponent} from './global-shortcuts/global-shortcuts.component';
import {GlobalShortcutsSectionComponent} from './global-shortcuts/global-shortcuts-section/global-shortcuts-section.component';
import {ShortcutsDirective} from './shortcuts.directive';
import {UiModule} from '../ui/ui.module';

@NgModule({
  imports: [
    CommonModule,
    EoSharedModule,
    UiModule
  ],
  exports: [GlobalShortcutsComponent, ShortcutsDirective],
  declarations: [GlobalShortcutsComponent, GlobalShortcutsSectionComponent, ShortcutsDirective],
  providers: [ShortcutsService]
})
export class ShortcutsModule {
}
