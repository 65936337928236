import {NgModule} from '@angular/core';
import {UiModule} from '../ui/ui.module';
import {GridModule} from '../grid/grid.module';
import {ResultListComponent} from './result-list.component';
import {ListContainerModule} from '../list-container/list-container.module';
import {StoredQueryModule} from '../stored-query/stored-query.module';

@NgModule({
  imports: [
    UiModule,
    ListContainerModule,
    GridModule,
    StoredQueryModule
  ],
  exports: [ResultListComponent],
  declarations: [ResultListComponent]
})
export class ResultListModule {
}
