import {Component, Input, EventEmitter, Output} from '@angular/core';
import {QueryScope} from '@eo-sdk/core';

@Component({
  selector: 'eo-query-scope-select',
  templateUrl: './query-scope-select.component.html',
  styleUrls: ['./query-scope-select.component.scss']
})
export class QueryScopeSelectComponent {
  QueryScope = QueryScope;
  
  private _selectedQueryScope: QueryScope;

  @Input('selection')
  set selectedQueryScope(scope: QueryScope) {
    this._selectedQueryScope = scope;
  }

  get selectedQueryScope() {
    return this._selectedQueryScope;
  }

  @Output()
  select: EventEmitter<QueryScope> = new EventEmitter<QueryScope>();

  constructor() { }

  selectQueryScope(scope: QueryScope) {
    this.selectedQueryScope = scope;
    this.select.emit(this.selectedQueryScope);
  }

}
