import {EventEmitter, Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {AppCacheService} from '@eo-sdk/core';
import {filter} from 'rxjs/operators';
// import {SplitAreaComponent} from '../../eo-framework/split/split-area/split-area.component';
import {PendingChangesService} from '../pending-changes/pending-changes.service';

// export class AreaState {

//   hidden;
//   dock;

//   constructor(public area: SplitAreaComponent, visible = true, hidden = false, dock = true) {
//     this.visible = visible;
//     this.hidden = hidden;
//     this.dock = dock;
//   }

//   get visible() {
//     return this.area.visible;
//   }

//   set visible(v: boolean) {
//     this.area.visible = v;
//   }

// }

@Injectable()
export class LayoutService {

  // public onUndockAreaChanged = new EventEmitter<AreaState>();

  private CACHE_DISABLED_KEY = 'eo.layout.cache.disabled';
  private _disableCache = false;
  private _history = {};

  set disableCache(cache: boolean) {
    this._disableCache = cache;
    if (this._disableCache) {
      this.clearHistory();
    } else {
      Object.keys(this._history).forEach(k => this.cacheLayout(k, this._history[k]));
    }
    this.cache.setItem(this.CACHE_DISABLED_KEY, this._disableCache).subscribe();
  }

  get disableCache() {
    return this._disableCache;
  }

  // private _undockArea: AreaState;
  // private areas: AreaState[] = [];
  // private areasSource = new ReplaySubject<AreaState[]>(1);
  // public areas$: Observable<AreaState[]> = this.areasSource.asObservable();

  constructor(private pendingChanges: PendingChangesService, private cache: AppCacheService, private router: Router) {
    this.cache.getItem(this.CACHE_DISABLED_KEY).subscribe(val => this.disableCache = typeof val === 'boolean' ? val : false);
    this.router.events.pipe(filter(r => r instanceof NavigationStart))
      .subscribe(() => this.clearHistory());
  }

  // getUndockArea() {
  //   return this._undockArea;
  // }

  clearHistory() {
    this._history = {};
  }

  // updateArea(area: AreaState, props: any) {
  //   let _area = this.areas.find(a => a === area);
  //   if (_area) {
  //     Object.assign(_area, props);
  //     this.areasSource.next(this.areas);
  //   }
  // }

  // setAreas(areas: AreaState[], undockArea: AreaState = null) {
  //   this._undockArea = undockArea;
  //   this.areas = areas;
  //   this.areasSource.next(this.areas);
  // }

  // hideAreas(condition: (area, index) => {}) {
  //   this.areas.forEach((a, i) => Object.assign(a, {visible: !condition.call(this, a, i), hidden: condition.call(this, a, i)}));
  //   this.areasSource.next(this.areas);
  // }

  // toggleArea(area: AreaState) {
  //   this.updateArea(area, {visible: !area.visible});
  // }

  // undockArea() {
  //   if (this._undockArea && !this.pendingChanges.check()) {
  //     this.updateArea(this._undockArea, {dock: !this._undockArea.dock});
  //     this.onUndockAreaChanged.emit(this._undockArea);
  //   }
  // }

  // visibilityUpdate() {
  //   this.areasSource.next(this.areas);
  // }

  cacheLayout(key: string, layout: any) {
    // if (!this.isUndocked()) {
      if (this.disableCache) {
        this._history[key] = layout;
      } else {
        this.cache.setItem(key, layout).subscribe();
      }
    // }
  }

  getLayout(key: string) {
    return this.cache.getItem(key);
  }

  // isUndocked() {
  //   return this._undockArea && !this._undockArea.dock;
  // }

}
