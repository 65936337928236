<div class="inbox-details" *ngIf="item; else noItem" [ngClass]="{bpm: item.type === 'BPM'}">

  <div class="eo-head" [ngClass]="{'eo-head-tabs': item.type === 'BPM'}">
    <header class="eo-header">
      <eo-icon class="eo-header-icon" *ngIf="!item.iconId" title="{{item?.type}}"
        [iconSrc]="'assets/_default/svg/ic_loop.svg'"></eo-icon>
      <eo-icon class="eo-header-icon" *ngIf="item.iconId" title="{{item?.type}}" [iconId]="item.iconId"></eo-icon>
      <div class="eo-header-info">
        <h2 class="eo-header-title">{{item.title}}</h2>
        <h3 *ngIf="item.description" class="eo-header-subtitle">{{item.description}}</h3>
        <h3 *ngIf="workItem && !item.description" class="eo-header-subtitle">{{workItem.qmodelname}}</h3>
      </div>
      <div class="eo-header-actions">
        <eo-icon class="button refresh-button" *ngIf="initializing.closed" (click)="refresh()"
          [iconSrc]="'assets/_default/svg/ic_refresh.svg'" [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>
      </div>
    </header>
  </div>

  <div class="eo-body" *ngIf="initializing.closed">

    <div class="load-error" *ngIf="loadError">
      <eo-error-message></eo-error-message>
    </div>

    <!-- details for BPM items -->
    <yvc-tabs *ngIf="item.type === 'BPM'; else dmsTask" layoutSettingsID="eo.inbox.details.tabs">
      <!-- task -->
      <ng-template [yvcTab]="{id: 'task', label: 'eo.process.details.tab.task' | translate}">
        <div class="tab-panel">
          <div class="panel task">

            <div class="process-description" *ngIf="workItem?.description">
              <h3 translate>eo.process.details.tab.task.description</h3>
              <div>{{workItem.description}}</div>
            </div>

            <eo-duetimeInfo *ngIf="item?.duetime" [item]="item"></eo-duetimeInfo>

            <div class="process-performer" *ngIf="workItem?.inSubstituteOf?.length">
              <div class="head" [ngClass]="{performerShow: substituteShow}">
                <div class="label" translate>eo.process.substitute</div>
                <div class="action" (click)="substituteShow = !substituteShow">
                  <div class="count">{{workItem.inSubstituteOf.length}}</div>
                  <eo-icon [iconSrc]="'assets/_default/svg/ic_arrow_down.svg'"></eo-icon>
                </div>
              </div>
              <div class="body" *ngIf="substituteShow">
                <div class="performer" *ngFor="let u of workItem.inSubstituteOf">
                  <div class="icon-wrap">
                    <eo-icon [iconSrc]="'assets/_default/svg/ic_orga_user.svg'"></eo-icon>
                  </div>
                  <div class="name">{{u.firstname}} {{u.lastname}} <span class="qname">{{u.name}}</span></div>
                </div>
              </div>
            </div>

            <ng-container *ngIf="locked; else unlocked">

              <div class="process-form">
                <eo-object-form [formOptions]="formOptions" *ngIf="workItem?.form"
                  (statusChanged)="onFormStatusChanged($event)" #bpmForm></eo-object-form>
              </div>
            </ng-container>

            <ng-template #unlocked>

              <div class="notice" translate>eo.process.task.continue</div>

              <div class="process-performer" *ngIf="workItem">
                <div class="head" [ngClass]="{performerShow: performerShow}">
                  <div class="label" translate>eo.process.recipients</div>
                  <div class="action" (click)="performerShow = !performerShow">
                    <div class="count">{{workItem.performer.users.length + workItem.performer.roles.length +
                      workItem.performer.groups.length}}</div>
                    <eo-icon [iconSrc]="'assets/_default/svg/ic_arrow_down.svg'"></eo-icon>
                  </div>
                </div>
                <div class="body" *ngIf="performerShow">

                  <div class="performer" *ngFor="let g of workItem.performer.groups">
                    <div class="icon-wrap">
                      <eo-icon [iconSrc]="'assets/_default/svg/ic_orga_group.svg'"></eo-icon>
                    </div>
                    <div class="name">{{g.name}}</div>
                  </div>

                  <div class="performer" *ngFor="let r of workItem.performer.roles">
                    <div class="icon-wrap">
                      <eo-icon [iconSrc]="'assets/_default/svg/ic_orga_role.svg'"></eo-icon>
                    </div>
                    <div class="name">{{r.name}}</div>
                  </div>

                  <div class="performer" *ngFor="let u of workItem.performer.users">
                    <div class="icon-wrap">
                      <eo-icon [iconSrc]="'assets/_default/svg/ic_orga_user.svg'"></eo-icon>
                    </div>
                    <div class="name">{{u.firstname}} {{u.lastname}} <span class="qname">{{u.name}}</span></div>
                  </div>
                </div>
              </div>

              <div class="indexdata" *ngIf="workItemIndexdata?.data">
                <h3 translate>eo.process.details.tab.task.indexdata</h3>
                <eo-indexdata-summary [indexdata]="workItemIndexdata"></eo-indexdata-summary>
              </div>

            </ng-template>
          </div>

          <ng-container *ngIf="locked; else unlockedActions">
            <div class="process-actions" [ngClass]="{disabled: !actionProcessing.closed}">

              <div class="secondary">
                <button class="btn-unlock" (click)="unlockWorkItem()" translate>eo.process.depersonalize</button>
                <button class="btn-save" (click)="saveWorkItemData()"
                  *ngIf="workItem?.form && !formState?.invalid && formState?.dirty"
                  translate>eo.object.indexdata.save</button>
              </div>

              <div class="primary" [ngClass]="{disabled: preventClickThrough}">
                <button class="primary btn-execute" *ngFor="let action of workItem.actions; trackBy: trackByCode"
                  (click)="executeWorkItemAction(action)" [ngClass]="{ext: action.url}"
                  [disabled]="(!!formState && formState?.invalid) || !action.feasible">{{action.title}}</button>
              </div>
            </div>
          </ng-container>

          <ng-template #unlockedActions>
            <div class="process-actions" [ngClass]="{disabled: !actionProcessing.closed || !workItem}">
              <div class="primary">
                <button class="primary btn-lock" (click)="lockWorkItem()" translate>eo.process.personalize</button>
              </div>
            </div>
          </ng-template>

        </div>
      </ng-template>

      <!-- history -->
      <ng-template [yvcTab]="{id: 'history', label: 'eo.process.details.tab.progress' | translate}">
        <div class="tab-panel">
          <div class="panel process-history">
            <eo-process-history [history]="history"></eo-process-history>
          </div>
        </div>
      </ng-template>

      <!-- attachments -->
      <ng-template [yvcTab]="{id: 'file', label: 'eo.process.details.tab.attachments' | translate}">
        <div class="tab-panel">
          <div class="panel process-files">
            <eo-process-file [processFile]="workItem?.file" [permissions]="workItem?.fileEntryPermissions"
              [isDisabled]="!filesAdding.closed" [clipboard]="clipboard" [editable]="true"
              [selectedContentFileId]="selectedContentFileId"
              (onOpenWorkItemContentInContext)="openWorkItemContentInContext($event)"
              (onOpenWorkItemContent)="openWorkItemContent($event)"
              (onRemoveWorkItemContent)="removeWorkItemContent($event)" (onPasteProcessFile)="addFromClipboard()">
            </eo-process-file>
          </div>
        </div>
      </ng-template>

      <!-- plugin tabs -->
      <ng-template *ngFor="let p of plugins" [yvcTab]="{id: p.id, label: p.id | translate}">
        <eo-plugin [id]="p.id" [parent]="this"></eo-plugin>
      </ng-template>
    </yvc-tabs>

    <!-- body when we have a subscription or a resubmission -->
    <ng-template #dmsTask>
      <div class="tab-panel">
        <div class="panel">
          <div class="process-description">
            <h3 translate>eo.process.details.tab.task.description</h3>
            <div>{{settings?.description}}</div>
          </div>
        </div>

        <!-- actions for the current process item -->
        <div class="process-actions" [ngClass]="{disabled: !actionProcessing.closed}">
          <div class="primary">
            <button class="primary btn-confirm" [disabled]="preventClickThrough"
              (click)="confirmInboxItem()">{{settings?.actionTitle}}</button>
          </div>
        </div>

      </div>
    </ng-template>

  </div>
</div>
<ng-template #noItem>
  <eo-error-message [emptyState]="{icon: emptyState.icon, text: emptyState.text, className: emptyState.className}">
    <ng-content select=".error"></ng-content>
  </eo-error-message>
</ng-template>
