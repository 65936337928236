import {Directive, Input, OnDestroy, HostListener, HostBinding} from '@angular/core';
import {GlobalShortcutComponent} from './global-shortcut-component.interface';
import {ShortcutsService} from './shortcuts.service';

/**
 * Directive to add a component to the global shortcuts service. Just apply
 * `[eoShortcuts]="shortcutsCmp"` to the template of your component, where `shortcutsCmp`
 * is an object implementing the `GlobalShortcutComponent` interface. This will then add
 * the component itself and its provided actions to the global shortcuts overlay. It will
 * also take care of binding and unbinding of the components shortcuts.
 */
@Directive({
  selector: '[eoShortcuts]'
})
export class ShortcutsDirective implements OnDestroy {

  private _shortcutCmp: GlobalShortcutComponent;

  // set tabindex to the host component so it is focusable
  @HostBinding('attr.tabindex') _tabIndex = 0;

  @Input('eoShortcuts')
  set shortcuts(shortcutComponent: GlobalShortcutComponent) {
    this._shortcutCmp = shortcutComponent;
    this.shortcutsService.bindComponent(this._shortcutCmp);
  }

  // bind to fosusin event to also set focus on the shortcut component.
  // Using 'focusin' instead of 'focus' enables the parent component to receive
  // the event when a child component gets focused (because this event bubbles).
  @HostListener('focusin', ['$event']) focus(event) {
    this.shortcutsService.focusComponent(this._shortcutCmp.id);
    event.preventDefault();
    event.stopPropagation();
  }

  constructor(private shortcutsService: ShortcutsService) {
  }

  ngOnDestroy() {
    this.shortcutsService.unbindComponent(this._shortcutCmp.id);
  }
}
