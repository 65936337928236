import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './not-found/not-found.component';
import {Error404Component} from './error404/error404.component';
import {UiModule} from '../ui/ui.module';

@NgModule({
  imports: [
    CommonModule,
    UiModule
  ],
  declarations: [
    NotFoundComponent,
    Error404Component
  ],
  exports: [
    NotFoundComponent,
    Error404Component
  ]
})
export class ErrorModule {
}
