<div class="eo-codesystem" *ngIf="codesystem"
  [ngClass]="{full: selectedNodes && selectedNodes.length === autocompleteValues.length, readonly: readonly}">

  <yvc-autocomplete #autocomplete (suggestionSelect)="onAutoCompleteSelect($event)"
    (suggestionUnselect)="onAutoCompleteUnselect($event)" [autocompleteValues]="autocompleteRes" field="name"
    [disabled]="readonly" [placeholder]="placeholder" [forceSelection]="true" (autocompleteFnc)="autocompleteFn($event)"
    [multiple]="multiselect" (ngModelChange)="onAutocompleteValueChange($event)" [(ngModel)]="selectedNodes">
  </yvc-autocomplete>
  <button type="button" #button *ngIf="!readonly"
  (click)="setDialogVisible(true, $event)" 
  (keydown.enter)="setDialogVisible(true, $event)" 
  >
    <eo-icon [iconSrc]="'assets/_default/svg/ic_arrow_down.svg'"></eo-icon>
  </button>
</div>

<eo-dialog [title]="pickerTitle || ''" [visible]="display" (hide)="display = false">
  <eo-tree [tree]="tree" [isDisplayed]="display" *ngIf="display" [options]="{multiselect: multiselect}"
    [(ngModel)]="selectedNodes" (ngModelChange)="onTreeSelectionChanged($event)" [readonly]="readonly"
    [emptyMessage]="emptyMessage">
  </eo-tree>
</eo-dialog>