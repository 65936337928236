import {Component, Input, EventEmitter, Output} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn} from '@angular/forms';
import {NotificationsService, UserService} from '@eo-sdk/core';
import {TranslateService} from '@eo-sdk/core';

@Component({
  selector: 'eo-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent {


  @Input() userRegex = '';
  @Output() onFormSumbit: EventEmitter<any> = new EventEmitter<any>();

  changePasswordForm: UntypedFormGroup;
  passwordIsVisible = false;
  passwordValidation = {type: 'valid', amount: null};

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private translate: TranslateService,
              private toaster: NotificationsService) {
    this.generateForm();
  }

  generateForm() {
    const {compose, required, minLength} = Validators;
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', compose([required, minLength(1)])],
      password: ['', compose([required, minLength(1)])],
      confirmPassword: ['', compose([required, minLength(1)])]
    }, {
      validator: compose([
        this.newPasswordValidator('currentPassword', 'password'),
        this.matchingPasswordsValidator('password', 'confirmPassword')
      ])
    });

    this.onChanges();
  }

  newPasswordValidator(current, password): ValidatorFn {
    return (group: UntypedFormGroup) => {
      if (group.controls[current].value !== group.controls[password].value) {
        return null;
      }
      return {'SAME_PASSWORD': true};
    }
  }

  matchingPasswordsValidator(password, confirm): ValidatorFn {
    return (group: UntypedFormGroup) => {
      if (group.controls[password].value === group.controls[confirm].value) {
        return null;
      }
      return {'matchingPasswords': true}
    }
  }

  onChanges(): void {
    this.changePasswordForm.valueChanges
      .subscribe(value => {
        if (value.password) {
          this.passwordValidation = this.userService.validatePassword(value.password);
        }
        return this.matchingPasswords;
      });
  }

  showPasswords() {
    this.passwordIsVisible = !this.passwordIsVisible;
  }

  get passwordIsNew() {
    const {errors, controls} = this.changePasswordForm;
    let passwordIsNewError = false;
    if (errors) {
      const currentPassword = controls.currentPassword.touched && controls.currentPassword.dirty;
      const password = controls.password.touched && controls.password.dirty;
      passwordIsNewError = errors.SAME_PASSWORD && currentPassword && password;
    }
    return passwordIsNewError;
  }

  get matchingPasswords() {
    const {errors, controls} = this.changePasswordForm;
    let matchingPasswordsError = false;
    if (errors) {
      const password = controls.password.touched && controls.password.dirty;
      const confirmPassword = controls.confirmPassword.touched && controls.confirmPassword.dirty;
      matchingPasswordsError = errors.matchingPasswords && password && confirmPassword;
    }
    return matchingPasswordsError;
  }


  changePasswordOnSuccess() {
    this.changePasswordForm.reset();
    this.changePasswordForm.markAsPristine();
    this.changePasswordForm.markAsUntouched();
    this.onFormSumbit.emit(true);
    this.toaster.success(this.translate.instant('eo.password.reset.success'));
  }

  changePasswordOnError(error) {
    const errorTitle = this.translate.instant('eo.password.reset.error.password.title');
    if (error.error.key === 'USER_OLD_PASSWORD_WRONG') {
      this.toaster.error(errorTitle, this.translate.instant('eo.password.reset.error.old.password.message'));
    } else if (error.error.key === 'USER_NEW_PASSWORD_INVALID') {
      this.toaster.error(errorTitle, this.translate.instant('eo.password.reset.error.new.password.message'));
    } else {
      this.toaster.error(errorTitle);
    }
  }

  onSubmit() {
    const {currentPassword, password} = this.changePasswordForm.value;
    const payload = {
      old: currentPassword,
      new: password
    };

    this.userService
      .changePassword(payload)
      .subscribe(() => this.changePasswordOnSuccess(), (error) => this.changePasswordOnError(error));
  }
}
