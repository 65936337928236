import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {DmsObjectHistoryEntry, DmsParams, DmsService, EnaioEvent, EventService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {map} from 'rxjs/operators';
import {IVisibleFilter} from './history-filter/history-filter';

@UntilDestroy()
@Component({
  selector: 'eo-object-history',
  templateUrl: './object-history.component.html',
  styleUrls: ['./object-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectHistoryComponent {

  _params: DmsParams;
  private _history: DmsObjectHistoryEntry[] = [];
  completeHistory: DmsObjectHistoryEntry[] = [];
  filters: string[] = [];
  filterterm: string;
  visibleFilter: IVisibleFilter = {select: true, input: true};

  set history(historyObj: DmsObjectHistoryEntry[]) {
    this._history = historyObj;
  }

  get history(): DmsObjectHistoryEntry[] {
    return this._history;
  }

  @Input()
  set params(p: DmsParams) {
    this._params = p;
    this.fetchHistory(p.id, p.type);
  }

  get params() {
    return this._params;
  }

  constructor(private dmsService: DmsService, private cd: ChangeDetectorRef, private eventService: EventService) {
    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED).pipe(
        untilDestroyed(this))
      .subscribe(event => {
        if (event.data && this.params.id === event.data.id) {
          this.params = this.params;
        }
      });
  }

  toggleFilter(filter) {
    const [type, hasFilter] = filter;
    if (hasFilter) {
      this.filters = this.filters.filter(s => s !== type);
    } else {
      this.filters.push(type);
    }
    this.history = this.completeHistory.filter(entry => this.filters.indexOf(entry.group) === -1);
  }

  trackByIndex(index, item) {
    return index;
  }

  private fetchHistory(id: string, type?: string) {
    this.dmsService
      .getHistory(id, type, true)
      .pipe(map((res: DmsObjectHistoryEntry[]) => {
        const reversedRes = res.reverse();
        this.completeHistory = reversedRes;
        this.history = reversedRes;
        this.cd.markForCheck();
      }))
      .subscribe();
  }
}
