<div class="eo-references">
  <div class="object-not-found" *ngIf="!inReferences?.length && !outReferences?.length; else refContainer">
    <div>{{'eo.references.object.notfound' | translate}}</div>
  </div>

  <ng-template #refContainer>
    <div class="form-buttons">
      <div class="gridlabels">
          <div class="gridlabel" [ngClass]="{'active': showInReferences}" (click)="switchListVisibilityTo('in')"
            title="{{'eo.references.to.label' | translate}}">
              <span>{{'eo.references.to.label' | translate}}</span>
          </div>
          <div class="gridlabel" [ngClass]="{'active': showOutReferences}" (click)="switchListVisibilityTo('out')"
            title="{{'eo.references.from.label' | translate}}">
              <span>{{'eo.references.from.label' | translate}}</span>
          </div>
      </div>
      <a class="button primary" [routerLink]="'/result'" [queryParams]="superQueryParams" title="{{'eo.references.open.resultlist' | translate}}">
        <span>{{'eo.references.open.resultlist' | translate}}</span>
      </a>
    </div>

    <div class="eo-references-lists">

      <!-- list of inReferences -->
      <div class="resultlist-container" [ngClass]="{'hidden': !showInReferences}">
        <div class="notfound" *ngIf="(!inReferences?.length)">{{'eo.references.object.notfound' | translate}}</div>

        <div class="grid-wrapper" *ngIf="inReferences?.length">
          <ag-grid-angular #gridIn [modules]="modules" [gridOptions]="gridOptions" class="ag-result"></ag-grid-angular>
        </div>
      </div>

      <!-- list of outReferences -->
      <div class="resultlist-container" [ngClass]="{'hidden': !showOutReferences}">
        <div class="notfound" *ngIf="(!outReferences?.length)">{{'eo.references.object.notfound' | translate}}</div>

        <div class="grid-wrapper" *ngIf="outReferences?.length">
          <ag-grid-angular #gridOut [modules]="modules" [gridOptions]="gridOptions2" class="ag-result"></ag-grid-angular>
        </div>
      </div>

    </div>
  </ng-template>
</div>