import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {AgentService} from '../agent/agent.service';
import {AppCacheService, UploadRegistryService, DmsObject, Utils, UploadTarget} from '@eo-sdk/core';
import {TreeNode} from '../../eo-framework/tree/tree.component.interface';

export interface EoLocation {
  id: string;
  type: string;
  title?: string;
  subFolder?: any;
}

/**
 * LocationService keeps track of the location a user visits inside enaio.
 * This service will be used by the AppAdd component for fetching objects allowed
 * to be added, because they may be restricted to a certain location.
 *
 * For components that display a location inside enaio use `LocationService.enter(id, type)`
 * in the components `OnInit` method and `LocationService.leave()` in its `OnDestroy` method.
 * See applications `ObjectStateComponent` for an example.
 */

@Injectable()
export class LocationService {

  // number of entries to keep in the history
  public HISTORY_SIZE = 5;

  // location that is active (currently opened by the user)
  private activeLocation: EoLocation;
  private locationSource = new ReplaySubject<EoLocation>(1);
  public activeLocation$: Observable<any> = this.locationSource.asObservable();

  // history of locations visited by the user
  private locationHistory: EoLocation[] = [];

  private currentUploadTargets: UploadTarget[] = [];

  constructor(private appCache: AppCacheService,
              private agentService: AgentService,
              private uploadRegistry: UploadRegistryService,
              private router: Router) {
    // load history from local storage
    appCache
      .getItem(appCache.LOCATION_HISTORY)
      .subscribe(h => this.locationHistory = h || []);
    // if (this.activeLocation) {
    //   // if we already hav an active location we'll add it to the history right away
    //   this.addHistoryEntry(this.activeLocation);
    // }
  }

  /**
   * Enter a specific location. This will add the location to the local history
   * and set the active location.
   * @param dmsObject
   * @param treeNode Node from the context folders structure tree
   */
  // enter(id: string, type: string, item?: DmsObject, treeNode?: TreeNode) {
  enter(dmsObject: DmsObject, treeNode?: TreeNode) {
    let loc;
    this.currentUploadTargets = [];
    // having a context folder means we could add an upload target for it
    if (dmsObject.isContextFolder) {
      let contextTarget = new UploadTarget(Utils.uuid(), UploadTarget.CONTEXT);
      contextTarget.referenceObject = dmsObject;
      contextTarget.onUploadSuccess = (target) => this.router.navigate([target]);
      this.currentUploadTargets.push(contextTarget);
    }


    if (treeNode && dmsObject.isContextFolder) {

      // having an additional tree node means, that a dynamic folder was selected
      // so we'll add an upload target for that as well
      let treeNodeTarget = new UploadTarget(Utils.uuid(), UploadTarget.CONTEXT_TREE);
      treeNodeTarget.referenceObject = dmsObject;
      treeNodeTarget.subFolder = treeNode;
      treeNodeTarget.onUploadSuccess = (target) => this.router.navigate([target]);
      this.currentUploadTargets.push(treeNodeTarget);
      loc = {id: dmsObject.id, title: dmsObject.title, type: dmsObject.type.name, subFolder: treeNode.data};

    } else if (dmsObject.isContextFolder) {
      loc = {id: dmsObject.id, title: dmsObject.title, type: dmsObject.type.name};
    } else {
      loc = {id: dmsObject.id, title: dmsObject.title, type: dmsObject.type.name};
    }

    if (this.currentUploadTargets.length) {
      this.currentUploadTargets.forEach(ut => {
        this.uploadRegistry.register(ut);
      })
    }

    this.activeLocation = loc;
    this.locationSource.next(this.activeLocation);
    this.addHistoryEntry(dmsObject, treeNode);
    this.appCache.setItem(this.appCache.LOCATION_HISTORY, this.locationHistory).subscribe();
  }

  /**
   * Leave a location. This will remove the active location.
   */
  leave() {
    if (this.currentUploadTargets.length) {
      this.currentUploadTargets.forEach(ut => {
        this.uploadRegistry.unregister(ut.id);
      });
    }
    this.activeLocation = null;
    this.locationSource.next(this.activeLocation);
  }

  /**
   * Returns the location history
   * @return
   */
  getHistory(): EoLocation[] {
    return this.locationHistory.reverse();
  }

  getActiveLocation() {
    return this.activeLocation;
  }

  /**
   * Adds a location entry to the history.
   * @param contextFolder The locations context folder
   * @param treeNode Node from the context folders structure tree
   */
  private addHistoryEntry(contextFolder: DmsObject, treeNode?: TreeNode) {

    // if the last history entry equals the one that should be added, we'll skip it
    if (this.isSameAsPrevious(contextFolder, treeNode)) {
      return false;
    }

// remove the first item if we would exceed the configured history size
    if (this.locationHistory.length === this.HISTORY_SIZE) {
      this.locationHistory.pop();
    }
    this.locationHistory.push({
      id: contextFolder.id,
      type: contextFolder.type.name,
      subFolder: treeNode ? treeNode.data : null
    });

// also send location to agent service
    this.agentService.updateUserLocation(contextFolder, treeNode);
  }

  private isSameAsPrevious(contextFolder: DmsObject, treeNode?: TreeNode): boolean {

    if (!this.locationHistory.length) {
      return false
    }
    const lastEntry = this.locationHistory[this.locationHistory.length - 1];
    if (lastEntry.id === contextFolder.id) {

      const treeLeaf = treeNode ? treeNode.data.key : null;
      const subFolder = lastEntry.subFolder ? lastEntry.subFolder.key : null;
      return treeLeaf === subFolder;
    } else {
      return false;
    }
  }
}
