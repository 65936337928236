import {Injectable} from '@angular/core';
import {EmptyState} from './empty-state.interface';

/**
 * Handles empty states. Empty states are special objects that can be passed to some
 * components indicating an empty state (error while fetching data, empty data, ...)
 */

@Injectable()
export class EmptyStateService {

  constructor() {
  }

  /**
   * Creates a new EmptyState.
   * @param listSize The number of items inside the lists result set (if)
   * @param message Optional message to be displayed
   * @param icon If a special icon should be used provide its uri here otherwise
   * the default icons will be used
   * @returns an empty state or null in case of a listSize > 0
   */
  getEmptyState(listSize: number, message?: string, icon?: string): EmptyState {

    if (listSize === -1) {
      // error while fetching data
      return {
        icon: icon || 'ic_error.svg',
        text: message || '',
        className: 'error'
      }

    } else if (listSize === 0) {
      // empty result
      return {
        icon: icon || 'ic_no-file.svg',
        text: message || '',
        className: 'empty'
      }

    } else {
      // no empty state
      return EmptyStateService.defaultEmptyState();
    }
  }

  static defaultEmptyState(): EmptyState {
    return {
      icon: undefined,
      text: undefined,
      className: undefined
    }
  }
}
