import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import {Router} from '@angular/router';
import {DmsObject, BackendService} from '@eo-sdk/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';

@Component({
  selector: 'eo-download-object-action',
  templateUrl: './download-object-action.component.html',
  styleUrls: ['./download-object-action.component.scss']
})
export class DownloadObjectActionComponent implements ActionComponent, AfterViewInit {

  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  actionDescription: string;
  showDescriptionOrginal: boolean;
  showDescriptionPdf: boolean;

  constructor(private backend: BackendService,
              private router: Router,
              private elemRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('[tabindex="0"]').focus();
    });
  }

  run() {
    const isVersionState = this.router.url.startsWith('/versions');
    this.backend.downloadContent(this.selection, null, isVersionState);
    this.finished.emit();
  }

  runPdf() {
    const isVersionState = this.router.url.startsWith('/versions');
    this.backend.downloadContent(this.selection, 'PDF', isVersionState);
    this.finished.emit();
  }

  showDescription(i, event) {
    event.stopPropagation();
    event.preventDefault();
    this.actionDescription = i === this.actionDescription ? null : i;
    this.showDescriptionOrginal = this.actionDescription != null && this.actionDescription == 'original' ? true : false;
    this.showDescriptionPdf = this.actionDescription != null  && this.actionDescription == 'pdf' ? true : false;
  }

  allowedItemType(contents) {
    return !!contents && contents.length && (!contents[0].mimegroup || !contents[0].mimegroup.match(/^audio|^video/));
  }
  
  cancel() {
    this.canceled.emit();
  }
 
}