import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IWidgetSetupComponent} from '@yuuvis/widget-grid';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Iframe} from '../iframe-widget.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'eo-iframe-setup',
  templateUrl: './iframe-setup.component.html',
  styleUrls: ['./iframe-setup.component.scss']
})
export class IframeSetupComponent implements IWidgetSetupComponent {

  private _widgetConfig: Iframe | undefined;
  @Output() widgetConfigChange = new EventEmitter<Iframe>();

  pattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

  setupForm: FormGroup = this.fb.group({
    title: ['', [Validators.maxLength(50)]],
    url: ['', [Validators.required, Validators.pattern(this.pattern)]],
  });
  @Input() set widgetConfig(wc: Iframe | undefined) {
    this._widgetConfig = wc;
    this.setupForm.patchValue({
      title: wc ? wc.title : '',
      url: wc ? wc.url : ''
    });
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private fb: FormBuilder
  ) {
    this.setupForm.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => {
      this.setWidgetConfig();
    });
  }

  private setWidgetConfig() {
    if (this.setupForm.valid) {
      this._widgetConfig = {
        ... this.setupForm.value,
      };
      this.widgetConfigChange.emit(this._widgetConfig);
    }
  }
}
