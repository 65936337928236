import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {GlobalShortcutComponent} from './global-shortcut-component.interface';
import {Logger} from '@eo-sdk/core';

@Injectable()
export class ShortcutsService {

  // list of componets that are currently registered to provide global keyboard shortcuts
  private _boundComponents: GlobalShortcutComponent[] = [];
  private boundComponentsSource = new ReplaySubject<any>();
  public components$: Observable<any> = this.boundComponentsSource.asObservable();

  constructor(private logger: Logger) {
  }

  /**
   * Add a components to the list of tracked keyboard aware components
   */
  bindComponent(cmp: GlobalShortcutComponent) {
    // only add if its not yet registered
    if (!this._boundComponents.find((c) => c.id === cmp.id)) {
      this.logger.debug('Adding shortcut component ' + cmp.label);
      this._boundComponents.push(cmp);
      this.boundComponentsSource.next(this._boundComponents);
    }
  }

  unbindComponent(id: string) {
    this.logger.debug('Removing shortcut component ' + id);
    this._boundComponents = this._boundComponents.filter((c) => c.id !== id);
    this.boundComponentsSource.next(this._boundComponents);
  }

  /**
   * Sets focus to a shortcut component
   * @param id - id of the component to receive focus
   */
  focusComponent(id) {
    this._boundComponents.forEach((c) => {
      c.id === id ? c.focused = true : c.focused = false;
    });
    this.boundComponentsSource.next(this._boundComponents);
  }
}
