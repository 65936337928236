<div *ngIf="query" class="eo-stored-query" [ngClass]="{hasForm: queryFormControls?.length > 0}">

  <div class="title" *ngIf="!_formOnly">{{query.name}}</div>
  <div class="qform">

    <div class="query-form" [formGroup]="queryForm" *ngIf="queryForm">

      <div class="form-field fulltext" *ngIf="fulltextFormControl">
        <eo-query-scope-select [selection]="queryScope" (select)="setQueryScope($event)"></eo-query-scope-select>
        <eo-form-element [element]="fulltextFormControl" [situation]="'SEARCH'"></eo-form-element>
      </div>

      <div class="form-field" *ngFor="let element of queryFormControls"
           [ngClass]="{sfe: element.controls[element._eoFormControlWrapper.controlName]._eoFormElement.selectedforenrichment}">
        <eo-form-element [element]="element" [situation]="'SEARCH'"></eo-form-element>
      </div>
    </div>

    <div class="actions">
      <div class="state">
        <span class="count">{{query.state?.totalCount | localeDecimal}}</span>
        <span translate>eo.storedquery.res.count.label</span>
      </div>
      <div class="buttons" *ngIf="!_formOnly">
        <button class="secondary" (click)="load()" translate>eo.storedquery.load</button>
        <button class="primary" [disabled]="(queryForm && queryForm.invalid) || query.state?.isEmpty"
                (click)="execute()" translate>eo.storedquery.run</button>
      </div>
    </div>

  </div>
</div>
