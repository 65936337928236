import {NumberFilter} from '@ag-grid-community/core';

export class FilesizeFilter extends NumberFilter {
  filter = 'eoFilesizeFilter';
}

FilesizeFilter.prototype['stringToFloat'] = function(value) {
  const number = this.simpleFilterParams.context.fileSizePipe.stringToNumber(value);
  return isNaN(number) ? null : number;
};
