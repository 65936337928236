import {Injectable} from '@angular/core';
import {SimpleFilter, ColDef, Utils as AgUtils, CsvExportParams, RowNode, Column, GridOptions} from '@ag-grid-community/core';
import {TranslateService, EnvironmentEnaio, SearchQuery} from '@eo-sdk/core';
import {FileSizePipe} from '../pipes/filesize.pipe';
import {LocaleNumberPipe} from '../pipes/locale-number.pipe';
import {LocaleDatePipe} from '../pipes/locale-date.pipe';
import {Router} from '@angular/router';
import {AppSearchService} from '../search/app-search.service';
import {
  SystemService,
  BackendService,
  UserService,
  EoUser,
  Utils,
  RangeValue,
  SearchFilter,
  SearchState
} from '@eo-sdk/core';
import {UtilitiesService} from '../../eo-framework/util';
import {FilesizeFilter} from '../../eo-framework/grid/filters/filesize-filter.component';

@Injectable()
export class GridService {
  context: any;

  get csvExportParams(): CsvExportParams {
    return {
      fileName: 'export',
      columnSeparator: this.context?.numberPipe.decimalSeparator === ',' ? ';' : ',',
      processCellCallback: (params) => {
        return (params.column.getColDef().cellClass as any || '').includes('col-number')
          ? (params.column.getColDef().cellRenderer as any)(params).replace(new RegExp(`\\` + this.context?.numberPipe.separator, 'g'), '') // removed grouping
          : params.value;
      }
    };
  }

  static escapeHtml(str) {
    str = str ? str : '';
    const entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      '\'': '&#39;',
      '/': '&#x2F;'
    };
    return String(str).replace(/[&<>"'\/]/g, s => entityMap[s]);
  }

  static qnameFormatter(qname: string) {
    return (qname || '').replace(new RegExp('sysobject.|sysdocument.'), '');
  }

  static qnameMatch(qname: string, qname2: string) {
    return GridService.qnameFormatter(qname) === GridService.qnameFormatter(qname2);
  }

  constructor(
    public translate: TranslateService,
    private router: Router,
    private appSearchService: AppSearchService,
    private system: SystemService,
    private backend: BackendService,
    private userService: UserService
  ) {
    this.context = {
      translate,
      system,
      backend,
      router,
      fileSizePipe: new FileSizePipe(translate),
      numberPipe: new LocaleNumberPipe(translate),
      datePipe: new LocaleDatePipe(translate),
      cr: CellRenderer,
      baseHref: EnvironmentEnaio.isWebEnvironment() ? this.backend.getHost() : './',
      mimetypegroupOpts: Object.keys(SearchQuery.MIMETYPEGROUP_BUCKETS).map(k => k.toLowerCase()),
      typeOpts: []
    };

    this.userService.user$.subscribe(
      (user: EoUser) =>
        (this.context.defaultPosition =
          user.uiDirection === 'rtl' ? 'right' : 'left')
    );

    this.appSearchService.queryState$.subscribe((state: SearchState) => this.updateContext(Array.from(state.aggregations.type.keys())));
  }

  // copy content of either row or table cell to clipboard
  public copyToClipboard(event: KeyboardEvent, gridOptions: GridOptions) {
    event.preventDefault();
    event.stopPropagation();

    const viewport = gridOptions.api['gridPanel'].eCenterViewport;
    const scrollLeft = viewport.scrollLeft;

    const focusedCell = gridOptions.api.getFocusedCell();
    const rows: RowNode[] = event.shiftKey ? gridOptions.api.getSelectedNodes() : [gridOptions.api.getDisplayedRowAtIndex(focusedCell.rowIndex)];
    const cols: Column[] = event.shiftKey ? gridOptions.columnApi.getAllDisplayedColumns().filter((c: any) => c.colId !== '__selectionField') : [focusedCell.column];
    const getCell = (col, row) => gridOptions.api['gridPanel'].eGui.querySelector(`div[row-index="${row.rowIndex}"] [col-id="${col.colId}"]`);
    const value = (col, row) => {
      gridOptions.api.ensureColumnVisible(col);
      const cell = getCell(col, row);
      if (!cell) return '';
      const chips = cell.querySelectorAll('.chip') || [];
      const val = Array.from(chips.length ? chips : [cell]).map((c: any) => (c && c.textContent && c.textContent.trim()) || '');
      const value = val.toString() || gridOptions.api.getValue(col, row);
      return !UtilitiesService.isEmpty(value) ? value.toString().replace(new RegExp('\n', 'g'), ' ') : '';
    };

    let content = '';
    if (event.altKey) content += cols.map((col: Column) => col.getColDef().headerName).join('	') + '\n';
    content += rows.map((row) => cols.map((col: Column) => value(col, row)).join('	')).join('\n');

    viewport.scrollLeft = scrollLeft;
    setTimeout(
      () =>
        rows.map((row) =>
          cols.map((col: Column) => {
            const cell = getCell(col, row);
            cell && cell.classList.add('copy-cell');
            cell && setTimeout(() => cell && cell.classList.remove('copy-cell'), 4000);
          })
        ),
      100
    );

    // navigator.clipboard.writeText(content); // only if client runs https
    const textArea = document.createElement('textarea');
    textArea.value = content;
    document.body.appendChild(textArea);
    textArea.select();
    const copySuccess = document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  public openLink(uri: string, newTab = false) {
    if (newTab) {
      window.open(CellRenderer.windowURI(uri));
    } else {
      // force reload in case of same URL
      const extras = CellRenderer.windowURI(this.router.url) === CellRenderer.windowURI(uri) ? `${uri.includes('?') ? '&' : '?'}reload=true` : '';
      this.router.navigateByUrl(uri + extras);
    }
  }

  public getRowIndex(el, parentClass: string) {
    return el && !el.classList.contains(parentClass)
      ? el.getAttribute('row-index')
        ? parseInt(el.getAttribute('row-index'), 10)
        : this.getRowIndex(el.parentElement, parentClass)
      : null;
  }

  public getRouterLink(el, parentClass) {
    return el && !el.classList.contains(parentClass)
      ? el.localName === 'a' && el.classList.contains('router-link')
        ? el
        : this.getRouterLink(el.parentElement, parentClass)
      : null;
  }

  public openRouterLink(event: MouseEvent, parentClass: string) {
    const link = this.getRouterLink(
      event.target,
      parentClass
    ) as HTMLAnchorElement;
    if (link && link.href) {
      this.openLink(link.getAttribute('href'), event.ctrlKey);
    }
  }

  public getContext() {
    return {...this.context, count: 0, rowCount: 0};
  }

  public updateContext(typeOpts: string[], options = {}) {
    Object.assign(this.context, {typeOpts: (typeOpts && typeOpts.length ? typeOpts : this.system.getObjectTypes().map(o => o.qname))}, options);
  }

  public getColumnDefs(
    elements: any[],
    sortorder: any[] = [],
    grouporder: any[] = [],
    pinned: any[] = [],
    alignmentx: {qname: string, value: string}[] = [],
    enableRowGroup = true,
    cachedColumns = [],
    mode?
  ): ColDef[] {
    return elements.map(f =>
      this.getColumnDefinition(
        f,
        sortorder,
        grouporder,
        pinned,
        alignmentx,
        enableRowGroup,
        cachedColumns,
        mode
      )
    );
  }

  /**
   * Renders the visual output of form elements based on its type etc. (see IndexdataSummaryComponent for usage details)
   * @param elements - The form elements to be rendered
   * @returns The rendered HTML for the given elements
   */
  public getResolvedDefs(elements: any[]) {
    return elements.map(el => {
      let colDef: ColDef = this.getColumnDefinition(el);
      let value = el.value;
      let data = {};
      data[colDef.field] = value;
      if (el.meta) {
        data[colDef.field + '_meta'] = el.meta;
      }
      // invoke valueGetter if provided
      if (colDef.valueGetter) {
        value = (colDef.valueGetter as Function).call(this, {
          context: this.context,
          data: data,
          node: {},
          colDef: colDef
        });
      }
      // run the cell renderer with the output generated by the valueGetter
      if (colDef.cellRenderer) {
        let params = {
          context: this.context,
          value: value,
          node: {},
          colDef: colDef,
          data: data
        };
        el._value = (colDef.cellRenderer as Function).call(this, params);
      } else {
        el._value = value;
      }
      return el;
    });
  }

  /**
   * creates the column definition for a given result field.
   *
   * @param resultField - the result field (as retrieved from ../user/config/result/*) to create the
   * column definition object for
   *
   * @param sortFields - Array of fields to be sorted
   */
  public getColumnDefinition(
    resultField,
    sortFields: any[] = [],
    groupFields: any[] = [],
    pinnedFields: any[] = [],
    alignmentFields: {qname: string, value: string}[] = [],
    enableRowGroup = false,
    cachedColumns = [],
    mode?
  ) {
    let colDef = <ColDef>{};
    colDef.headerName = resultField.label;
    colDef.field = resultField.hitname || resultField.name;
    colDef.sortable = true;
    colDef.resizable = true;
    colDef.filter = 'agTextColumnFilter';
    colDef.cellRenderer = params => GridService.escapeHtml(params.value);
    colDef.valueGetter = params => {
      return params.node.group || !params.data ? '' : params.data[colDef.field];
    };
    colDef.refData = {qname: resultField.qname || ''};

    /**
     * headerClass
     * add special header class for fields from the contextfolder
     */
    if (resultField.selectedforenrichment && mode !== 'COMMON') {
      colDef.headerClass = 'contextfolder';
    }
    this.addColDefAttrsByType(colDef, resultField);
    this.addColDefAttrsByField(colDef, colDef.field);

    colDef.filterParams = {
      ...colDef.filterParams,
      debounceMs: 800,
      clearButton: false,
      newRowsAction: 'keep',
      filterOptions: []
    };

    if (colDef.filter === 'agTextColumnFilter') {
      colDef.filterParams.filterOptions = colDef.refData.qname.match(/(^sysobject\.|^sysdocument\.)(title$|description$)/) ? ['equals'] : [
        'contains',
        'equals',
        'startsWith',
        'endsWith'
      ];

    } else if (
      colDef.filter === 'agNumberColumnFilter' ||
      colDef.filter === 'agDateColumnFilter'
    ) {
      colDef.filterParams.filterOptions = [
        'equals',
        'lessThanOrEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'inRange'
      ];
    }

    // mimetype filter supports only equals option
    if (colDef.field === 'mimetype') {
      colDef.filterParams.defaultOption = 'equals';
      colDef.filterParams.filterOptions = ['equals'];
    }

    // filesize filter supports only interval options
    if (colDef.field === 'filesize') {
      colDef.filterParams.defaultOption = 'lessThanOrEqual';
      colDef.filterParams.filterOptions = [
        'lessThanOrEqual',
        'greaterThanOrEqual',
        'inRange'
      ];
    }

    if (!resultField.sortable) {
      colDef.sortable = false;
    }
    if (!resultField.searchable) {
      colDef.suppressMenu = true;
      colDef.filter = false;
    }

    let sortField = sortFields.find(f => f.qname === resultField.qname);
    if (sortField) {
      colDef.sort = sortField.direction.toLowerCase();
    }

    if (enableRowGroup) {
      let groupFieldIndex = groupFields.findIndex(
        f => f.qname === resultField.qname
      );
      if (~groupFieldIndex) {
        colDef.rowGroupIndex = groupFieldIndex;
      }
    }

    let pinnedField = pinnedFields.find(f => f.qname === resultField.qname);
    if (pinnedField) {
      colDef.pinned = pinnedField.position || this.context.defaultPosition;
    }

    let cachedColumn = cachedColumns.find(f => f.qname === resultField.qname);
    if (cachedColumn) {
      colDef.width = cachedColumn.width;
    }

    let alignmentField = alignmentFields.find(f => f.qname === resultField.qname);
    if (alignmentField && alignmentField.value) {
      colDef.cellStyle = {'justify-content': alignmentField.value};
    } else {
      colDef.cellStyle = {'justify-content': resultField.alignmentx ? resultField.alignmentx : ''};
    }

    return colDef;
  }

  /**
   * add type specific column definition attributes
   *
   * @param colDef - the column definition object to be extended by type specific attributes
   * @param resultField - object defining the result field
   *
   * @returns enriched column definition object
   */
  public addColDefAttrsByType(colDef: ColDef, resultField) {
    switch (resultField.type) {
      case 'REFERENCE': {
        colDef.filter = 'agTextColumnFilter';
        colDef.cellClass = resultField.multiselect
            ? 'multiCell router-link-cell'
            : 'router-link-cell';
        colDef.cellRenderer = this.customContext(
          CellRenderer.referenceCellRenderer,
          {reference: resultField.reference || {}, colDef: colDef}
        );
        colDef.valueGetter = param => {
          const value =
            param.node.group || !param.data ? '' : param.data[colDef.field];

          const refMeta = param && param.data && param.data._references;
          // use object type label when meta is missing (search svc does not support meta)
          return UtilitiesService.isEmpty(value)
            ? '' : refMeta
              ? refMeta[colDef.field]
              : this.metaValueGetter(v => v.title)(param);
        };
        break;
      }
      case 'STRING': {
        colDef.filter = 'agTextColumnFilter';
        colDef.cellRenderer = CellRenderer.stringCellrenderer;
        if (resultField.multiselect) {
          colDef.cellRenderer = CellRenderer.multiSelectCellRenderer;
        }
        colDef.cellClass = resultField.multiselect
          ? 'multiCell string'
          : 'string';
        this.addColDefAttrsByClassification(colDef, resultField.classification);
        if (resultField.reference) {
          colDef.cellClass = resultField.multiselect
              ? 'multiCell router-link-cell'
              : 'router-link-cell';
          colDef.cellRenderer = this.customContext(
            CellRenderer.linkCellRenderer,
            {reference: resultField.reference}
          );
        }
        colDef.comparator = (a, b) => AgUtils.defaultComparator(a, b, true);
        break;
      }
      case 'DATETIME': {
        colDef.width = 150;
        colDef.filter = 'agDateColumnFilter';
        colDef.cellRenderer = this.customContext(
          CellRenderer.dateTimeCellRenderer,
          {pattern: resultField.withtime ? 'eoShort' : 'eoShortDate'}
        );
        colDef.getQuickFilterText = this.customContext(
          CellRenderer.dateTimeCellRenderer,
          {pattern: resultField.withtime ? 'eoShort' : 'eoShortDate'}
        );
        colDef.filterParams = {
          comparator: this.dateComparator(this.context.datePipe),
          valueGetter: this.dateFilterValueGetter(colDef.field),
          withTime: resultField.withtime,
          inRangeInclusive: true
        };
        break;
      }
      case 'CODESYSTEM': {
        colDef.filter = 'CodesystemFilterComponent';
        colDef.colId = resultField.field;
        colDef.filterParams = {multi: resultField.multiselect};
        colDef.cellRenderer = CellRenderer.stringCellrenderer;
        if (resultField.multiselect) {
          colDef.cellRenderer = CellRenderer.multiSelectCellRenderer;
        }
        colDef.valueGetter = this.metaValueGetter(v => v.defaultrepresentation);
        colDef.cellClass = resultField.multiselect
          ? 'multiCell codesystem'
          : 'codesystem';
        break;
      }
      case 'NUMBER': {
        colDef.cellClass = 'col-number';
        colDef.headerClass = 'col-header-number';
        colDef.filter = 'agNumberColumnFilter';
        colDef.width = 150;
        const {scale, grouping, pattern} = resultField;
        colDef.cellRenderer = this.customContext(
          CellRenderer.numberCellRenderer,
          {scale, grouping, pattern}
        );
        colDef.getQuickFilterText = this.customContext(
          CellRenderer.numberCellRenderer,
          {scale, grouping, pattern}
        );
        colDef.filterParams = {inRangeInclusive : true};
        break;
      }
      case 'ORGANIZATION': {
        colDef.filter = 'OrganizationFilterComponent';
        colDef.colId = resultField.qname;
        colDef.filterParams = {multi: resultField.multiselect};
        colDef.cellRenderer = CellRenderer.stringCellrenderer;
        if (resultField.multiselect) {
          colDef.cellRenderer = CellRenderer.multiSelectCellRenderer;
        }
        colDef.valueGetter = this.metaValueGetter(v =>
          v.title && v.name ? `${v.title} (${v.name})` : `${v.id}`
        );
        colDef.cellClass = resultField.multiselect
          ? 'multiCell organization'
          : 'organization';
        break;
      }
      case 'BOOLEAN': {
        colDef.filter = 'ListFilterComponent';
        colDef.cellClass = 'col-boolean';
        colDef.cellRenderer = CellRenderer.booleanCellRenderer;
        colDef.filterParams = {
          cellRenderer: this.customContext(CellRenderer.booleanCellRenderer),
          cellHeight: 30,
          values: [false, true],
          suppressMiniFilter: true
        };
        colDef.width = 100;
        colDef.getQuickFilterText = () => '';
        break;
      }
    }
    return colDef;
  }

  /**
   * add classification specific column definition attributes
   *
   * @param colDef - the column definition object to be extended
   * @param classification - the classification to evaluate
   *
   * @returns enriched column definition object
   */
  private addColDefAttrsByClassification(colDef: ColDef, classification) {
    switch (classification) {
      case 'email': {
        colDef.cellRenderer = CellRenderer.emailCellRenderer;
        break;
      }
      case 'url': {
        colDef.cellRenderer = CellRenderer.urlCellRenderer;
        break;
      }
    }
    return colDef;
  }

  private addColDefAttrsByField(colDef: ColDef, resultFieldName) {
    switch (resultFieldName) {
      case 'type': {
        colDef.filter = 'ListFilterComponent';
        colDef.cellRenderer = CellRenderer.typeCellRenderer;
        colDef.width = 80;
        colDef.cellClass = 'res-ico';
        colDef.getQuickFilterText = () => '';
        colDef.filterParams = {
          cellRenderer: this.customContext(CellRenderer.typeCellRenderer),
          values: this.context.typeOpts
        };
        break;
      }
      case 'mimetypegroup': {
        colDef.filter = 'ListFilterComponent';
        colDef.width = 101;
        colDef.filterParams = {
          values: this.context.mimetypegroupOpts
        };
        break;
      }
      case 'version': {
        colDef.width = 80;
        break;
      }
      case 'filesize': {
        colDef.headerClass = 'col-header-filesize';
        colDef.filter = FilesizeFilter;
        colDef.width = 100;
        colDef.cellRenderer = CellRenderer.filesizeCellRenderer;
        colDef.getQuickFilterText = this.customContext(
          CellRenderer.filesizeCellRenderer
        );
        break;
      }
    }
    return colDef;
  }

  private customContext(fnc, mixin?) {
    return param => {
      return fnc(Object.assign({}, param, {context: this.context}, mixin));
    };
  }

  public metaValueGetter(titleFnc: Function, mapFnc: Function = v => v) {
    return params => {
      // data could be empty on first rendering a cell while infinite scrolling
      if (params.node.group || !params.data) {
        return '';
      }

      // todo: super hotfix - COOL-8989 - solves problem with resolving of ORGANIZATION value (via qname)
      const fieldMeta = params.colDef.field + '_meta';
      const value = !params.data.hasOwnProperty(fieldMeta)
        ? mapFnc(
          (params.data[params.colDef.field] !== undefined) ? params.data[params.colDef.field] :
          params.data[params.colDef.refData.qname]
        )
        : Array.isArray(params.data[fieldMeta])
          ? params.data[fieldMeta].map(v => titleFnc(v || {}))
          : titleFnc(params.data[fieldMeta] || {});
      return value;
    };
  }

  public dateComparator(datePipe) {
    return (a: Date, b) => {
      if (!b || !b.value) {
        return null;
      }

      if (b.filter) {
        const _date = !b.activeRange ? b.filter.dateFrom : b.filter.dateTo;
        b.activeRange = b.filter.type === SimpleFilter.IN_RANGE;
        const length = b.filter.withTime ? 16 : 10;

        return (
          AgUtils.defaultComparator(
            _date && _date.slice(0, length),
            b.value.slice(0, length)
          ) * -1
        );
      }

      let date = b.length === 10 ? b : datePipe.transform(b, 'yyyy-MM-dd');
      return (
        AgUtils.defaultComparator(datePipe.transform(a, 'yyyy-MM-dd'), date) *
        -1
      );
    };
  }

  public dateFilterValueGetter(field: string) {
    return node => {
      let _filter = node.gridOptionsWrapper.gridOptions.api.getFilterInstance(
        field
      );
      let value = node.data[field];
      return {filter: _filter['_filterModel'], value};
    };
  }
}

export class CellRenderer {

  static situation = '';

  static windowURI(uri: any) {
    return uri.toString().replace(new RegExp('^\/|^\\\\'), '');
  }

  static render(type: string, param: any, newParam?: any) {
    return CellRenderer[type + 'CellRenderer'](
      Object.assign({}, param, newParam)
    );
  }

  static filesizeCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    return param.value ? param.context.fileSizePipe.transform(param.value) : '';
  }

  static numberCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    if (param.value || param.value === 0) {
      if (param.value.operator) {
        // range value from search form table
        return param.value.operator ===
          SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH
          ? CellRenderer.numberCellRendererTemplate(
            param.value.firstValue,
            param.context,
            param.grouping,
            param.pattern,
            param.scale
          ) +
          ' ' +
          RangeValue.getOperatorLabel(param.value.operator) +
          ' ' +
          CellRenderer.numberCellRendererTemplate(
            param.value.secondValue,
            param.context,
            param.grouping,
            param.pattern,
            param.scale
          )
          : RangeValue.getOperatorLabel(param.value.operator) +
          ' ' +
          CellRenderer.numberCellRendererTemplate(
            param.value.firstValue,
            param.context,
            param.grouping,
            param.pattern,
            param.scale
          );
      } else {
        return CellRenderer.numberCellRendererTemplate(
          param.value,
          param.context,
          param.grouping,
          param.pattern,
          param.scale
        );
      }
    } else {
      return '';
    }
  }

  static numberCellRendererTemplate(
    value,
    context,
    grouping?,
    pattern?,
    scale?
  ) {
    return context.numberPipe.transform(
      value,
      grouping,
      pattern,
      scale
    );
  }

  static stringCellrenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    return GridService.escapeHtml(param.value);
  }

  static typeCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    let val = '';
    if (param.value) {
      let objectType = param.context.system.getObjectType(param.value);
      if (!objectType) {
        return val;
      }
      return CellRenderer.iconCellRenderer(
        Object.assign({}, param, {value: objectType})
      );
    }
    return val;
  }

  static iconCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    let val = '';
    if (param.value && (param.value.url || param.value.iconId)) {
      let iconUrl = param.value.url
        ? param.context.baseHref + param.value.url
        : param.context.backend.getBaseWithContext(param.context.backend.getServiceBase()) +
        '/ui/icon/' +
        param.value.iconId +
        '.svg';
      let label = param.value.label;
      val = `<img class="object-type" src="${iconUrl}" title="${label}"><span class="object-type-label">${label}</span>`;
    }
    return val;
  }

  static emailCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    return param.value
      ? `<a href="mailto:${param.value}">${GridService.escapeHtml(
        param.value
      )}</a>`
      : '';
  }

  static urlCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    return param.value
      ? `<a target="_blank " href="${param.value}">${GridService.escapeHtml(
        param.value
      )}</a>`
      : '';
  }

  static dateTimeCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    if (param.value) {
      if (param.value.operator) {
        // range value from search form table
        return param.value.operator ===
          SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH
          ? CellRenderer.dateTimeCellRendererTemplate(
            param.value.firstValue,
            param.context,
            param.pattern
          ) +
          ' ' +
          RangeValue.getOperatorLabel(param.value.operator) +
          ' ' +
          CellRenderer.dateTimeCellRendererTemplate(
            param.value.secondValue,
            param.context,
            param.pattern
          )
          : RangeValue.getOperatorLabel(param.value.operator) +
          ' ' +
          CellRenderer.dateTimeCellRendererTemplate(
            param.value.firstValue,
            param.context,
            param.pattern
          );
      } else {
        return CellRenderer.dateTimeCellRendererTemplate(
          param.value,
          param.context,
          param.pattern
        );
      }
    } else {
      return '';
    }
  }

  static dateTimeCellRendererTemplate(value, context, pattern) {
    if (value === null && CellRenderer.situation === 'SEARCH') {
      return `<span class="no-value">${context.translate.instant('eo.form.input.null')}</span>`;
    }
    return `<span date="${value}">${context.datePipe.transform(
      value,
      pattern
    )}</span>`;
  }

  static booleanCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    let val = `<path class="outline" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>
                      <path d="M 5,19 L 19,5" stroke="rgba(0, 0, 0, 0.54)" stroke-width="2"/>`;

    if (param.value === true || param.value === 'true') {
      val = `<path class="outline" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>
             <polyline class="checkmark" points="6.3,11.8 7.8,10.3 10.8,13.3 16.3,7.8 17.8,9.3 10.8,16.3 6.3,11.8"/>`;
    } else if (param.value === false || param.value === 'false') {
      val = `<path class="outline" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>`;
    }
    return `<svg class="checkbox" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">${val}</svg>`;
  }

  static multiSelectCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    let val = '';
    if (param.value) {
      (Array.isArray(param.value) ? param.value : [param.value]).forEach(
        value => {
          val += `<div class="chip">${GridService.escapeHtml(value)}</div>`;
        }
      );
    }
    return val;
  }

  static linkCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    let val = '';
    if (param.value) {
      (Array.isArray(param.value) ? param.value : [param.value]).forEach(
        value => {
          const query = {
            types: [param.reference.type],
            filters: {}
          };
          query.filters[param.reference.element] = {
            o: 'eq',
            v1: value
          };
          const link = Utils.buildUri('result', {
            query: encodeURIComponent(JSON.stringify(query))
          });
          val += `<div class="chip">
          <a class="link router-link" href="${link}" target="_blank" onclick="return false;">
          <svg focusable="false" class="ref-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8
          13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path>
          </svg>
          </a><span>${GridService.escapeHtml(value)}</span></div>`;
        }
      );
    }
    return val;
  }

  static referenceCellRenderer(param) {
    if (param.value === null && CellRenderer.situation === 'SEARCH') {
      return CellRenderer.noValueRenderer(param);
    }
    let text = '';
    const value = param.data ? param.data[param.colDef.field] : '';
    const type = param.context.system.getObjectType(param.reference.type);
    if (!UtilitiesService.isEmpty(value) && type) {
      (Array.isArray(value) ? value : [value]).forEach((val, index) => {
        const link = 'object/' + val + '?type=' + param.reference.type;
        const iconUrl =
          param.context.backend.getBaseWithContext(param.context.backend.getServiceBase()) +
          '/ui/icon/' +
          type.iconId +
          '.svg';
        let title = Array.isArray(param.value)
          ? param.value[index]
          : param.value;

        // If the user is not allowed to see the reference object or the object was deleted, we don't show the link.
        text += `<div class="chip">
        ${
          !title
            ? ''
            : `<a class="link router-link" href="${link}" target="_blank" onclick="return false;">
          <svg focusable="false" class="ref-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8
          13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path>
          </svg>
          </a>`
          }
        <img class="type-icon" src="${iconUrl}" title="${type.label}">
        <span>${GridService.escapeHtml(title || type.label)}</span></div>`;
      });
    }

    return text || param.value;
  }

  static noValueRenderer(param) {
    return `<span class="no-value">${param.context.translate.instant('eo.form.input.null')}</span>`;
  }

}
