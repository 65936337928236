import {Component, Input, ViewEncapsulation, inject} from '@angular/core';
import {EoFormGroupWrapper, ObjectFormControlWrapper} from '../object-form-control-wrapper';
import {EoFormGroup, ObjectFormGroup} from '../object-form-group.model';
import {ObjectFormHelperService} from '../../object-form-helper.service';

@Component({
  selector: 'eo-form-group',
  templateUrl: './object-form-group.component.html',
  styleUrls: ['./object-form-group.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ObjectFormGroupComponent {

  private formHelperService = inject(ObjectFormHelperService);

  private types = {
    STACK: 'stack',
    GROUP: 'group',
    FIELDSET: 'fieldset'
  };

  group: ObjectFormGroup;
  groupType: string;
  isCore: boolean;
  isData: boolean;
  layoutSettingsID?: string;

  // form situation, if not set default will be 'EDIT'
  @Input() situation = 'EDIT';
  @Input() skipToggle: boolean;

  @Input('group')
  set groupd(g: ObjectFormGroup) {
    if (g) {
      this.group = g;
      this.isCore = this.group._eoFormGroup && this.group._eoFormGroup.label === 'core';
      this.isData = this.group._eoFormGroup && this.group._eoFormGroup.label === 'data';
      this.groupType = this.getGroupType();
    } else {
      this.group = null;
    }
  }

  @Input() noGroupLabels: boolean;

  getObjectFormGroup(control): EoFormGroup {
    return (control as ObjectFormGroup)._eoFormGroup;
  }

  getObjectFormControlWrapper(control): EoFormGroupWrapper {
    return (control as ObjectFormControlWrapper)._eoFormControlWrapper;
  }


  private getGroupType() {
    // may as well be an ObjectFormControlWrapper so check first
    // if we got a real group
    if (this.group._eoFormGroup) {
      if (this.group._eoFormGroup.type === 'o2mGroupStack') {

        // Group stacks are able to preserve their state. 
        // They are rendered as tabs and should remember the selected tab, so we'll set an 
        // ID and register them for cleanup
        this.layoutSettingsID = 'eo.form.stack.' + this.group._eoFormGroup.id;
        this.formHelperService.addTemporaryStorageEntry(this.layoutSettingsID);

        // for data section only render as stack (tabs) if we have more than one child
        if (this.isData && Object.keys(this.group.controls).length === 1) {
          return this.types.GROUP;
        }
        return this.types.STACK;
      }
      if (this.group._eoFormGroup.type === 'o2mGroup') {
        return (this.group._eoFormGroup.label && !(this.isCore || this.isData) && !this.noGroupLabels && !this.parentIsStack(this.group)) ? this.types.FIELDSET : this.types.GROUP;
      }
      return this.types.GROUP;
    }
  }

  private parentIsStack(group: ObjectFormGroup): boolean {
    let parentFormElement = group.parent['_eoFormGroup'];
    return parentFormElement && parentFormElement.type === 'o2mGroupStack';
  }
}
