<div class="eo-side-bar" eoTrapFocus>
  <eo-icon (click)="close()" (keydown.enter)="close()" tabindex="0" class="button white close"
    [iconSrc]="'assets/_default/svg/ic_clear.svg'" focusable></eo-icon>

  <div class="head" [style.background-image]="backgroundImage"></div>

  <div class="body">
        <div class="navigation">
          <div class="nav-item" *ngIf="capabilities.favorites">
            <a routerLink="/favorites" routerLinkActive="active-link"
               translate>eo.view.navigation.favorites</a>
          </div>
          <div class="nav-item" *ngIf="capabilities.inbox">
            <a routerLink="/inbox" routerLinkActive="active-link"
               translate>eo.view.navigation.inbox</a>
          </div>

          <div class="nav-item" *ngIf="capabilities.intray">
            <a routerLink="/prepare" routerLinkActive="active-link"
               translate>eo.view.navigation.prepare</a>
          </div>

          <div class="nav-item" *ngIf="capabilities.bpm">
            <a routerLink="/process" routerLinkActive="active-link"
               translate>eo.view.navigation.process</a>
          </div>

          <div class="nav-item" *ngIf="capabilities.notifications">
            <a routerLink="/notifications" routerLinkActive="active-link"
              translate>eo.view.navigation.notifications</a>
          </div>

          <div class="nav-item" *ngIf="capabilities.storedqueries">
            <a routerLink="/stored-queries" routerLinkActive="active-link"
              translate>eo.view.navigation.storedqueries</a>
          </div>

          <div class="nav-item" *ngIf="capabilities.recyclebin">
            <a routerLink="/recyclebin" routerLinkActive="active-link"
               translate>eo.view.navigation.recyclebin</a>
          </div>
          <ng-content select=".navi"></ng-content>
          <div class="nav-item" >
            <a routerLink="/about" translate>eo.sidebar.help.about.tooltip</a>
          </div>
        </div>
  </div>
</div>
