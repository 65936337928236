import {BrowserModule} from '@angular/platform-browser';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {EoCoreModule} from '@eo-sdk/core';
import {EoClientModule} from './eo-client';
import {EoFrameworkCoreModule} from './eo-framework-core';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    // temporary: extra import to provide ToastrService in production mode (https://github.com/scttcper/ngx-toastr/issues/549)
    ToastrModule.forRoot(),
    EoCoreModule.forRoot({environment: environment}),
    EoFrameworkCoreModule.forRoot(),
    EoClientModule.forRoot()
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
