import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@eo-sdk/core';
import {OpenDocumentComponent} from './open-document-action.component';
import {ComponentAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {SelectionRange} from '../../selection-range.enum';
import {AgentService} from '../../../../eo-framework-core/agent/agent.service';
import {Observable, of} from 'rxjs';
import {DmsObject} from '@eo-sdk/core';

@Component({
  selector: 'eo-open-document-action',
  template: ``
})
export class OpenDocumentActionComponent extends DmsObjectTarget implements ComponentAction {

  label: string;
  description: string;
  iconSrc = 'assets/_default/svg/ic_edit.svg';
  priority = 1;
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  component = OpenDocumentComponent;

  constructor(private translate: TranslateService, private agentService: AgentService, private router: Router) {
    super();
    this.label = this.translate.instant('eo.action.menu.edit.document.label');
    this.description = this.translate.instant('eo.action.menu.edit.document.description');
  }

  isExecutable(item: DmsObject): Observable<boolean> {
    const {isFinalized, content, rights, lock} = item;
    const itemConditions = (item && (isFinalized || !(content && content.count) || content.count === 0 || !rights.edit || (lock && lock.by.other)));
    const isExecutable = this.agentService.isConnected && this.isAllowedState() && !itemConditions;
    return of(isExecutable);
  }

  isAllowedState() {
    const disabledStates = ['/versions'];
    return !disabledStates.some(s => this.router.url.includes(s));
  }
}
