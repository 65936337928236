<div tabindex="-1" class="resultsList" *ngIf="list" [class.disabled]="list?.disabled" [class.selected]="list?.selected" [class.marked]="marked">
    <a class="link" [href]="link" target="_blank">
      <eo-icon class="link" [iconSrc]="'ic_link.svg'"  [iconTitle]="'eo.object.details' | translate"></eo-icon>
    </a>
    <div class="content" [class.singleselect]="!multiselect">
      <div class="select" *ngIf="multiselect" (click)="select(list, false, !list?.disabled)" [class.selected]="list?.selected" [class.disabled]="list?.disabled">
        <svg fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
        </svg>
      </div>
      
      <div class="details" [class.width-clipboard]="list?.clipboard"
      (click)="select(list, false, !list?.disabled && !list?.selected)" (dblclick)="select(null, true, !list?.disabled)">
        
        <eo-icon class="icon" [objectType]="list?.objectType"  [iconTitle]="list?.objectType?.label"></eo-icon>
        <div class="title">
          {{list?.title}}
        </div>
        <span class="extra" *ngIf="list?.contextFolder">{{'eo.references.search.contextfolder' | translate}}: {{list?.contextFolder?.title}} - {{list?.contextFolder?.description}}</span>
        <div class="description">
         {{list?.description}}
        </div>
      </div>
    </div>
  </div>