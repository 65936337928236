
import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {EnaioEvent, EventService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

/**
 * This directive adds an outside click listener to each element it is applied to.
 * The directive will also execute the provided handler function, when the user hits ESC.
 *   <... (eoOutSideClick)="doSomethingOnOutsideClick($event)"></...>
 */

@UntilDestroy()
@Directive({
  selector: '[eoOutsideClick]'
})
export class OutsideClickDirective {

  active = true;

  @Output()
  public eoOutsideClick = new EventEmitter<any>();

  constructor(private _elementRef: ElementRef, private eventService: EventService) {

    this.eventService
      .on(EnaioEvent.EO_DIALOG_STACK_CHANGED).pipe(
        untilDestroyed(this))
      .subscribe((event: any) => {
        this.active = !event.data.active;
      });
  }

  @HostListener('document:keydown', ['$event', '$event.target'])
  public keyboardInput(event: KeyboardEvent, targetElement: HTMLElement) {
    if (this.active && !event.defaultPrevented && event.key === 'Escape') {
      this.onOutsideEvent(event);
    }
  }

  @HostListener('document:mousedown', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement) {
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (this.active && !this._elementRef.nativeElement.contains(targetElement)
      && (overlayContainer && overlayContainer.hasChildNodes() ? !overlayContainer.contains(targetElement) : true)) {
      this.onOutsideEvent(event);
    }
  }

  private onOutsideEvent(event: Event) {
    this.eoOutsideClick.emit(event);
  }
}
