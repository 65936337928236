
<eo-icon class="button white" *ngIf="clipboard.elements.length"
         [iconTitle]="iconTitle"
         (click)="overlayShow = !overlayShow"
         [badge]="clipboard.elements.length" [iconSrc]="iconSrc"></eo-icon>

<div class="clipboard-overlay dark" *ngIf="overlayShow && clipboard.elements.length" (eoOutsideClick)="hide()">
  <div class="title" *ngIf="type === 'cut'; else copy">
    <span translate>eo.clipboard.type.cut</span>
    <eo-icon class="button white" (click)="removeAll()" [iconSrc]="'assets/_default/svg/ic_clear.svg'"></eo-icon>
  </div>
  <ng-template #copy>
    <div class="title">
      <span translate>eo.clipboard.type.copy</span>
      <eo-icon class="button white" (click)="removeAll()" [iconSrc]="'assets/_default/svg/ic_clear.svg'"></eo-icon>
    </div>
  </ng-template>

  <div class="cb-item" *ngFor="let i of clipboardItems">
    <eo-icon [objectType]="i.type" [iconTitle]="i.type.label"></eo-icon>
    <div class="cb-item-info">
      <div class="name">{{i.title}}</div>
      <div class="name context">{{i.contextFolder?.title}}</div>
    </div>
    <eo-icon class="button white" (click)="removeItem(i)" [iconSrc]="'assets/_default/svg/ic_clear.svg'"></eo-icon>
  </div>

  <div class="more" *ngIf="clipboardItems.length < clipboard.elements.length"
       translate [translateParams]="{moreCount: clipboard.elements.length - clipboardItems.length}">eo.clipboard.more</div>

  <div class="actions">
    <button (click)="pasteAsContentlink()" [disabled]="!isPasteAsContentlinkAllowed" *ngIf="this.clipboard.action === 0" translate>eo.clipboard.paste.as.contentlink.label</button>
    <button class="primary" (click)="paste()" [disabled]="!isPasteAllowed" translate>eo.clipboard.paste.label</button>
  </div>
</div>
