export enum InboxTypesFilter {
  acceptedTasks = 'acceptedtasks',
  unacceptedTasks = 'unacceptedtasks',
  absent = 'isdeputy',
  overdue = 'overdue',
}

export enum InboxTypes {
  RESUBMISSION = 'resubmission',
  SUBSCRIPTION = 'subscription',
  BPM = 'bpm'
}
