import {Component, OnInit, Input} from '@angular/core';
import {PreparedItemContent} from '@eo-sdk/core';

@Component({
  selector: 'eo-prepare-content-exists-info',
  templateUrl: './prepare-content-exists-info.component.html',
  styleUrls: ['./prepare-content-exists-info.component.scss']
})
export class PrepareContentExistsInfoComponent {

  queryParam: any;

  private _content;
  @Input() set content(c: PreparedItemContent) {
    this._content = c;
    this.queryParam = encodeURIComponent(JSON.stringify({
      "filters": {
        "digest": {
          "o": "eq",
          "v1": c.digest
        }
      }
    }));
  }

  get content() {
    return this._content;
  }
}
