import {of as observableOf, Observable} from 'rxjs';
import {Component, Input} from '@angular/core';
import {DmsObjectTarget} from '../../action-target';
import {SelectionRange} from '../../selection-range.enum';
import {
  ExecutableProcess,
  TranslateService,
  DmsObject,
  NotificationsService,
  BpmService,
  Utils
} from '@eo-sdk/core';
import {SimpleProcessAction} from '../../interfaces/action.interface';


@Component({
  selector: 'eo-simple-workflow-actions',
  template: ''
})
export class SimpleWorkflowActionComponent extends DmsObjectTarget implements SimpleProcessAction {

  label: string;
  description: string;

  priority = 0;
  iconSrc = 'assets/_default/svg/ic_play.svg';
  group = 'further';
  range = SelectionRange.SINGLE_SELECT;
  _executableProcess: ExecutableProcess;

  @Input()
  set executableProcess(executableProcess: ExecutableProcess) {
    this._executableProcess = executableProcess;
    this.label = executableProcess.title;
    this.description = executableProcess.description;
    this.range = 1;
  }

  get action() {
    return this._executableProcess;
  }

  constructor(private toast: NotificationsService,
              private translate: TranslateService,
              private bpmService: BpmService) {
    super();
  }

  isExecutable(element: DmsObject): Observable<boolean> {
    return observableOf(this.isAllowedType(element));
  }

  isAllowedType(item: DmsObject): boolean {
    const disabledType = ['sysemail'];
    const {typeName} = item;
    return !disabledType.some(type => typeName === type);
  }

  run(items: DmsObject[]): void {
    const contents = items.map(i => {
      return {
        id: i.id,
        type: i.typeName
      }
    });

    this.bpmService
      .startProcess(this._executableProcess.id, {}, contents)
      .subscribe((res) => {
        this.toast.success(this._executableProcess.title);
      }, Utils.throw(() => {
      }));
  }
}
