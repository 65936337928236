
import {of as observableOf} from 'rxjs';
import {SimpleAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {Router} from '@angular/router';
import {TranslateService} from '@eo-sdk/core';
import {DmsObject, DmsService, EventService, EnaioEvent} from '@eo-sdk/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'eo-restore-version-action',
  template: ``
})
export class RestoreVersionActionComponent extends DmsObjectTarget implements SimpleAction {
  label: string;
  description: string;
  priority = 0;
  iconSrc = 'assets/_default/svg/ic_refresh.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  constructor(private translate: TranslateService,
              private router: Router,
              private eventService: EventService,
              private dmsService: DmsService) {
    super();
    this.label = this.translate.instant('eo.action.menu.versioning.version.restore.label');
    this.description = this.translate.instant('eo.action.menu.versioning.version.restore.description');
  }

  isExecutable(item: DmsObject): Observable<boolean> {
    return observableOf(!item.isActiveVersion && this.isAllowedState());
  };

  run(selection: DmsObject[]) {
    const {id, version, typeName} = selection[0];
    this.dmsService
      .restoreVersion(id, version, typeName)
      .subscribe(res => this.eventService.trigger(EnaioEvent.DMS_OBJECT_VERSION_RESTORED, res.id));
    // const typeName: NavigationExtras = {queryParams: {type: item.typeName}};
    // this.router.navigate(['/object', item.id], typeName);
  }

  isAllowedState() {
    const enabledStates = ['/versions'];
    return enabledStates.some(s => this.router.url.startsWith(s));
  }
}
