<div class="setup">
    <form [formGroup]="setupForm">
        <eo-form-input  tabindex="0" [label]="'eo.workspace.widget.setup.title' | translate"  [skipToggle]="true" description="eo.dashboard.info.inbox.msg">
            <eo-string autocomplete="true" formControlName="title" ></eo-string>
        </eo-form-input>
        <div class="err-msg"
            *ngIf="setupForm.get('title').invalid && (setupForm.get('title').dirty || setupForm.get('title').touched)">
            <div *ngIf="setupForm.get('title').errors" [translateParams]="{maxlength: 50}" translate>eo.form.property.maxlength</div>
        </div>
    </form>
</div>
<div class="preview">
    <eo-info-inbox-widget [widgetConfig]="widgetConfig"></eo-info-inbox-widget>
</div>