import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Clipboard, EnaioEvent, EventService, FileEntry} from '@eo-sdk/core';
import {FileEntryPermissions} from './file-entry-permissions.interface';



@Component({
  selector: 'eo-process-file',
  templateUrl: './process-file.component.html',
  styleUrls: ['./process-file.component.scss'],
  animations: []
})
export class ProcessFileComponent {

  private defaultFileEntryPermissions = {add: false, remove: false};

  private _processFile: FileEntry[];
  private _permissions: FileEntryPermissions = this.defaultFileEntryPermissions;

  @Input()
  set processFile(file: FileEntry[]) {
    this._processFile = file  || [];
  };
  get processFile() {
    return this._processFile;
  }
  @Input()
  set permissions(permission: FileEntryPermissions) {
    this._permissions = permission || this.defaultFileEntryPermissions;
  }
  get permissions() {
    return this._permissions;
  }

  @Input() clipboard: Clipboard;
  @Input() selectedContentFileId: string;
  @Input() isDisabled: boolean;
  @Input() editable: boolean;

  @Output() onOpenWorkItemContentInContext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpenWorkItemContent: EventEmitter<FileEntry> = new EventEmitter<FileEntry>();
  @Output() onRemoveWorkItemContent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPasteProcessFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private eventService: EventService){
    this.eventService.on(EnaioEvent.PROCESS_FILE_CHANGED).subscribe(event => {
      this.processFile = event.data;
    });
  }

  openWorkItemContentInContext(file, $event) {
    $event.data = file;
    this.onOpenWorkItemContentInContext.emit($event);
  }

  openWorkItemContent(file) {
    this.onOpenWorkItemContent.emit(file);
  }

  addFromClipboard() {
    this.onPasteProcessFile.emit(true);
  }

  removeWorkItemContent(file, $event) {
    $event.data = file;
    this.onRemoveWorkItemContent.emit($event);
  }

  trackByIndex(index, item) {
    return index;
  }

  hasAlreadyAllCopiedFiles() {
    const attachmentIds = this.processFile.map(e => e.id);
    return this.clipboard.elements.filter(e => attachmentIds.indexOf(e.id) === -1).length === 0;
  }
}
