import {ANALYZE_FOR_ENTRY_COMPONENTS, ModuleWithProviders, NgModule} from '@angular/core';
import {EventService, EnaioEvent, Config} from '@eo-sdk/core';
import {UiModule} from '../ui/ui.module';
import {AgGridModule} from '@ag-grid-community/angular';

import {GridComponent} from './grid.component';
import {ColumnConfiguratorComponent} from './column-configurator/column-configurator.component';
import {UtilModule} from '../util/util.module';
import {TotalCountComponent} from './extensions/total-count/total-count.component';
import {QuickFilterComponent} from './extensions/quick-filter/quick-filter.component';
import {CustomSortComponent} from './extensions/custom-sort/custom-sort.component';
import {CustomFilterComponent} from './extensions/filter/custom/custom-filter.component';
import {TextFilterComponent} from './extensions/filter/text/text-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SetFilterComponent} from './extensions/filter/setFilters/set-filter.component';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {CtaModule} from '../cta/cta.module';
import {DatetimeFilterComponent} from './filters/datetime-filter.component';
import {OrganizationFilterComponent} from './filters/organization-filter.component';
import {CodesystemFilterComponent} from './filters/codesystem-filter.component';
import {DynamicListFilterComponent} from './filters/dynamic-list-filter.component';
import {TreeModule} from '../tree/tree.module';
import {ResetFilterComponent} from './extensions/reset-filter/reset-filter.component';
import {EmptyComponent} from './extensions/empty/empty.component';
import { PaginationComponent } from './extensions/pagination/pagination.component';
import {ListFilterComponent} from './filters/list-filter.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {YvcAutocompleteModule} from '@yuuvis/components/autocomplete';
import {YvcIconModule} from '@yuuvis/components/icon';
import {YvcDatepickerModule} from '@yuuvis/components/datepicker';


@NgModule({
  imports: [
    UiModule,
    UtilModule,
    FormsModule,
    ReactiveFormsModule,
    FormElementsModule,
    TreeModule,
    CtaModule,
    YvcAutocompleteModule,
    YvcIconModule,
    YvcDatepickerModule,
    DragDropModule,
    AgGridModule.withComponents([TextFilterComponent, SetFilterComponent, DatetimeFilterComponent, CodesystemFilterComponent, OrganizationFilterComponent,
      DynamicListFilterComponent, ListFilterComponent])
  ],
  declarations: [GridComponent, ColumnConfiguratorComponent, TotalCountComponent, QuickFilterComponent,
    CustomSortComponent, CustomFilterComponent, TextFilterComponent, SetFilterComponent, DatetimeFilterComponent,
    CodesystemFilterComponent, OrganizationFilterComponent, ResetFilterComponent, EmptyComponent, PaginationComponent, DynamicListFilterComponent, ListFilterComponent],
  exports: [GridComponent, ColumnConfiguratorComponent, TotalCountComponent, QuickFilterComponent,
    CustomSortComponent, CustomFilterComponent, TextFilterComponent, SetFilterComponent, DatetimeFilterComponent,
    CodesystemFilterComponent, OrganizationFilterComponent, ResetFilterComponent, EmptyComponent, PaginationComponent, DynamicListFilterComponent, ListFilterComponent]
})
export class GridModule {

  constructor(private event: EventService, private config: Config) {
    this.event
      .on(EnaioEvent.EO_LICENSE_GRID)
      .subscribe(() => {});
  }

  static withComponents(components: any[] = []): ModuleWithProviders<AgGridModule> {
    return {
      ngModule: AgGridModule,
      providers: [{
          provide: ANALYZE_FOR_ENTRY_COMPONENTS,
          useValue: components,
          multi: true,
        }]
    };
  }
}
