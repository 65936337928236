import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IWidgetSetupComponent} from '@yuuvis/widget-grid';
import {InfoInboxConfig} from '../info-inbox.model';

@UntilDestroy()
@Component({
  selector: 'eo-info-inbox-setup',
  templateUrl: './info-inbox-setup.component.html',
  styleUrls: ['./info-inbox-setup.component.scss'],
})
export class InfoInboxSetupComponent implements IWidgetSetupComponent, OnInit {

  private _widgetConfig: InfoInboxConfig;
  @Output() widgetConfigChange = new EventEmitter<InfoInboxConfig>();

  setupForm: UntypedFormGroup = this.fb.group({
    title: [this.translate.instant('eo.dashboard.info.inbox.msg'), [Validators.maxLength(50)]],
  });
  @Input() set widgetConfig(wc: InfoInboxConfig) {
    this._widgetConfig = wc;
    this.setupForm.patchValue({
      title: wc ? wc.title : '',
    });
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {
    this.setupForm.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => {
      this.setWidgetConfig();
    });
  }
  
  ngOnInit(): void {
    this.setWidgetConfig();
  }

  private setWidgetConfig() {
    if(this.setupForm.valid){
      this._widgetConfig = {
        ... this.setupForm.value,
      };
      this.widgetConfigChange.emit(this._widgetConfig);
    }
  }
}
