import {CdkPortalOutlet} from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';

import {YvcOverlayRef, YvcOverlayService} from '@yuuvis/components/overlay';

@Component({
  selector: 'eo-dialog',
  templateUrl: './eo-dialog.component.html',
  styleUrls: ['./eo-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EoDialogComponent
  implements AfterViewInit, OnDestroy {

  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;
  @ViewChild('dialogContent') dialogContent: TemplateRef<any>;

  private overlayRef: YvcOverlayRef;
  private _visible = false;

  @Input() hasPreviewFile = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() styleClass = '';

  // array of pendingTask IDs to be checked before the dialog closes
  @Input() dirtyCheck: string | string[] = [];

  @Input() minWidth: number | string = 200;
  @Input() minHeight = 'auto';
  @Input() height: string;
  @Input() width: string;
  @Input() focusOnShow = true;
  @Input() align: 'center' | 'start' = 'center';
  @Input() isFormTable = false;
  @Input() showPreview = false;

  @Output() onTogglePreview: EventEmitter<any> = new EventEmitter();
  @Output() visibleChange: EventEmitter<any> = new EventEmitter();
  @Output() hide: EventEmitter<any> = new EventEmitter();
  @Output() show: EventEmitter<any> = new EventEmitter();

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(val) {
    if (val !== this._visible && val) this.open()
    if (val !== this._visible && this._visible) this.closeDialog()
    this._visible = !!val;
    this.visibleChange.emit(this._visible);
    this._visible ? this.show.emit(val) : this.hide.emit(val);
  }



  canClose = (): boolean => {
    if (this.dirtyCheck) {
      return !this.pendingChanges.checkForPendingTasks(this.dirtyCheck)
    } else {
      return true;
    }
  }

  constructor(
    private pendingChanges: PendingChangesService,
    private overlay: YvcOverlayService,
  ) { }

  private open() {
    if(!this.dialogContent) return;
    // We create the overlay
    this.overlayRef = this.overlay.open(this.dialogContent, null, {
      disposeOnNavigation: true,
      minHeight: this.minHeight,
      minWidth: this.minWidth,
      height: this.height,
      width: this.width,
      canClose: this.canClose
    })
    this.overlayRef.afterClosed$.subscribe(() => this.hide.emit())
  }

  closeDialog() {
    if(this.overlayRef) this.overlayRef.close();
  }

  togglePreview() {
    this.showPreview = !this.showPreview;
    this.onTogglePreview.emit();
  }

  ngAfterViewInit(): void {
    if (this._visible) {this.open();}
  }

  ngOnDestroy(): void {
      this.closeDialog()
  }
}
