import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {WorkItemHistoryEntry} from '@eo-sdk/core';

@Component({
  selector: 'eo-process-history',
  templateUrl: './process-history.component.html',
  styleUrls: ['./process-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessHistoryComponent {

  @Input() history: WorkItemHistoryEntry[];
  filterterm: string;

  trackByIndex(index, item) {
    return index;
  }
}
