import {Component, Input, ViewChild, EventEmitter} from '@angular/core';
import {Utils, BpmService, NotificationsService, ExecutableProcess, DmsObject, TranslateService} from '@eo-sdk/core';
import {ObjectFormComponent} from '../../../../object-form/object-form/object-form.component';
import {EoDialogComponent} from '../../../../ui/eo-dialog/eo-dialog.component';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {PendingChangesService} from '../../../../../eo-framework-core/pending-changes/pending-changes.service';
import {ObjectFormOptions} from '../../../../object-form';

@Component({
  selector: 'eo-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements ActionComponent {
  selection: any[];
  finished: EventEmitter<any> = new EventEmitter();
  canceled: EventEmitter<any> = new EventEmitter();
  processingRequest = false;
  headerTitle: string;
  headerDescription: string;
  selected: any;
  // workItem: any;
  formOptions: ObjectFormOptions;
  contents: {id: string, type: string}[];

  // ID set by pendingChanges service when editing indexdata
  // Used to finish the pending task when editing is done
  pendingTaskIds: string[] = [];
  private _executableProcess: ExecutableProcess;

  @ViewChild('dialog') dialog: EoDialogComponent;
  @ViewChild('bpmForm') bpmForm: ObjectFormComponent;

  @Input()
  set executableProcess(process: ExecutableProcess) {
    this._executableProcess = process;
    if (process) {

      this.headerTitle = process.title;
      this.headerDescription = process.description;

      process.form.situation = 'CREATE';
      this.formOptions = {
        formModel: process.form,
        data: process.data,
        actions: process.actions,
        objects: this.selection.filter(s => (s instanceof DmsObject))
      };
      this.contents = this.formOptions.objects.map(obj => ({id: obj.id, type: obj.typeName}));
    }
  }

  get executableProcess() {
    return this._executableProcess;
  }

  formData: any;
  form: any;

  constructor(private bpmService: BpmService,
    private pendingChanges: PendingChangesService,
    private translate: TranslateService,
    private toaster: NotificationsService) {
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskIds[0] || ' ')) {
      this.pendingTaskIds = [this.pendingChanges.startTask()];
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskIds[0]);
  }

  saveForm() {
    this.processingRequest = true;
    setTimeout(() => {
      if (this.form && !this.form.invalid) {
        this.formData = this.bpmForm.getFormData();
        this.bpmService
          .startProcess(this._executableProcess.id, this.formData, this.contents)
          .subscribe(() => {
            this.processingRequest = false;
            this.toaster.success(this.translate.instant('eo.process.started', {processName: this._executableProcess.title}));
            this.finish();
          }, Utils.throw(error => {
            this.toaster.error(error);
            this.processingRequest = false;
          }));
      }
    }, 500);
  }

  finish() {
    this.finishPending();
    this.dialog.visible = false;
    this.finished.emit();
  }

  cancel() {
    if (!this.pendingChanges.checkForPendingTasks(this.pendingTaskIds)) {
      this.finishPending();
      this.dialog.visible = false;
      this.canceled.emit();
    }
  }

  onIndexDataChanged(event) {
    this.form = event;
    this.formData = event.data;
    if (this.form.dirty) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }
}
