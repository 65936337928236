<div class="process-history">

  <div class="filter">
    <div class="input" [ngClass]="{inactive: !history?.length}">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_filter.svg'"></eo-icon>
      <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="filterterm">

    </div>
    <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="filterterm = null" *ngIf="filterterm"></eo-icon>
  </div>


  <ng-template #empty>
    <eo-error-message [emptyState]="{icon: 'ic_no-file.svg', text: 'eo.timeline.empty', className: 'history empty'}"></eo-error-message>
  </ng-template>


  <div class="history eo-timeline" [ngClass]="{single: history?.length === 1}" *ngIf="history?.length; else empty">

    <div class="timeline-entry"
         *ngFor="let entry of history | historyFilter:filterterm; trackBy: trackByIndex"
         [ngClass]="{user: entry.editor, error: entry.type === 'error' || entry?.errorType}">

      <div class="when">
        <div class="date">{{entry.time | localeDate: 'eoShortDate'}}</div>
        <div class="time">{{entry.time | localeDate: 'eoShortTime'}}</div>
      </div>
      <div class="marker">
        <img *ngIf="entry.editor"
             title="{{entry.editor.lastname}}, {{entry.editor.firstname}} ({{entry.editor.name}})"
             [src]="entry.data?.image">
      </div>
      <div class="what">
        <div class="title">{{entry.title}}</div>

        <div class="description" *ngIf="entry.data?.periodFireTime && entry.type === 'DEADLINE_START'; else description">
          <div class="deadline-fire">
            <eo-icon [iconSrc]="'assets/_default/svg/ic_timer.svg'"></eo-icon>
            <span>{{entry.data.periodFireTime | localeDate}}</span>
          </div>
        </div>
        <ng-template #description>
          <div *ngIf="entry.type !== 'USERDEFINED'; else userDefined" class="description">
            {{'eo.process.details.history.entry.type.' + entry.type + '.title'|translate}}
          </div>

          <ng-template #userDefined>
            <div class="description">
              {{entry.description}}
            </div>
          </ng-template>
        </ng-template>

        <div class="performer" *ngIf="entry.editor">
          <span *ngIf="entry.editor.lastname">{{entry.editor.lastname}}, </span>
          <span *ngIf="entry.editor.firstname">{{entry.editor.firstname}}</span>
          ({{entry.editor.name}})
        </div>

        <div class="meta" *ngIf="entry.performer?.length">
          <span *ngFor="let p of entry.performer; trackBy: trackByIndex">
            {{p.label}}
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
