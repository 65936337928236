<section class="change-password">
  <form #changePasswordFormElement [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-item">

      <eo-form-input [label]="('eo.password.reset.current-password' | translate)" [required]="true" [invalid]="passwordIsNew">
        <eo-password [parent]="changePasswordForm"
                     [element]="'currentPassword'"
                     [canChangepasswordVisiblety]="false"
                     [passwordVisible]="passwordIsVisible"
                     [placeholder]="('eo.password.reset.current-password' | translate)"></eo-password>
      </eo-form-input>

      <eo-form-input [label]="('eo.password.reset.new-password' | translate)" [required]="true" [invalid]="matchingPasswords || passwordIsNew">
        <eo-password [parent]="changePasswordForm"
                     [element]="'password'"
                     [canChangepasswordVisiblety]="false"
                     [passwordVisible]="passwordIsVisible"
                     [placeholder]="('eo.password.reset.new-password' | translate)"></eo-password>
      </eo-form-input>

      <eo-form-input [label]="('eo.password.reset.confirm-password' | translate)" [required]="true" [invalid]="matchingPasswords">
        <eo-password [parent]="changePasswordForm"
                     [element]="'confirmPassword'"
                     [canChangepasswordVisiblety]="false"
                     [passwordVisible]="passwordIsVisible"
                     [placeholder]="('eo.password.reset.confirm-password' | translate)"></eo-password>
      </eo-form-input>

      <div class="invalid error" *ngIf="passwordIsNew">
        {{'eo.password.reset.error.same.password' | translate}}
      </div>

      <div class="invalid error" *ngIf="matchingPasswords">
        {{'eo.password.reset.error.matiching.password' | translate}}
      </div>

      <div class="invalid error" translate [translateParams]="{amount: passwordValidation.amount}" *ngIf="passwordValidation.type === 'short'">
        eo.password.reset.error.new.password.short
      </div>

      <div class="invalid error" translate [translateParams]="{amount: passwordValidation.amount}" *ngIf="passwordValidation.type === 'number'">
       eo.password.reset.error.new.password.number
      </div>

      <div class="invalid error" translate [translateParams]="{amount: passwordValidation.amount}" *ngIf="passwordValidation.type === 'specialchars'">
       eo.password.reset.error.new.password.specialchars
      </div>

      <section class="change-password__cta">
        <button class="change-password__submit-button" type="submit"
                [disabled]="changePasswordForm.invalid || passwordValidation.type !== 'valid'" translate>eo.password.reset.confirm-button</button>

        <a (click)="showPasswords()" tabindex="0" (keydown.enter)="showPasswords()" >
          <eo-icon class="change-password__icon-show"
                   [iconSrc]="'assets/_default/svg/ic_eye.svg'"
                   [iconTitle]="('eo.password.reset.toogle.icon' | translate)"
                   [ngClass]="{'visible': passwordIsVisible}"></eo-icon>
        </a>
      </section>
    </div>
  </form>

</section>
