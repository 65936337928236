import {Directive, EventEmitter, HostListener, OnInit, Output, Input, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[eoPreventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 500;
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(throttleTime(this.debounceTime)).subscribe(e => this.debounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    //TODO: does not support LinkAction (ctrl + click)
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  @HostListener('keydown.enter', ['$event'])
  onkeyDown(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}

