import {catchError, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ReplaySubject, Observable} from 'rxjs';
import {TreeNode} from '../../../eo-framework/tree/tree.component.interface';
import {FieldDefinition, SearchResult, Utils, BackendService, SearchService, DmsObject} from '@eo-sdk/core';
import {TranslateService} from '@eo-sdk/core';
import {TreeData, ObjectSearchResult, FrontPageData, TreeQuery, ViewURI} from './object-state.interface';


@Injectable()
export class ObjectStateService {

  // component communication
  private treeData: TreeData;
  private treeDataSource = new ReplaySubject<any>();
  public tree$: Observable<any> = this.treeDataSource.asObservable();

  private treeRefData: TreeData;
  private treeRefDataSource = new ReplaySubject<any>();
  public treeRef$: Observable<any> = this.treeRefDataSource.asObservable();

  // result list subscribes to this observable to fetch its data
  private _list: ObjectSearchResult;
  private listSource = new ReplaySubject<ObjectSearchResult>();
  public list$: Observable<ObjectSearchResult> = this.listSource.asObservable();

  constructor(private searchService: SearchService,
    private translate: TranslateService,
    private backend: BackendService) {
  }


  selectFrontPage(context: DmsObject): Observable<FrontPageData> {

    // ... and clear the result list
    this._list = null;
    this.listSource.next(this._list);

    // todo: grab additional informations about the context
    let metaDataQuery = {
      'fields': [
        'title',
        'description',
        'filesize',
        'modified',
        'modifiertitle',
        'type'
      ],
      'filters': {
        'contextfolderid': {
          'o': 'eq',
          'v1': context.id
        }
      },
      'contextfoldertypes': [context.type.qname],
      'types': []
    };

    let q = this.searchService.buildQuery(metaDataQuery);
    q.addSortOption('modified', 'desc');

    return this.searchService.search(q)
      .pipe(
        map((res) => {
          let size = 0;
          let lastModified = null;

          if (res.hits.length) {
            size = res.hits.map(a => a.filesize || 0).reduce((acc, cur) => acc + cur);
            lastModified = res.hits.sort(Utils.sortValues('modified', 'desc')).slice(0, 100);
          }

          return <FrontPageData>{
            documentCount: {value: res.count.value},
            totalFilesize: size,
            lastModifications: lastModified
          };
        }),
        catchError(Utils.catch(null, this.translate.instant('eo.search.result.error')))
      );
  }

  updateTreeByQuery({id: objId, typeName}: DmsObject, options: TreeQuery = {}, uri?: ViewURI): Observable<TreeData> {
    return this.loadTreeByQuery(objId, typeName, options, uri)
      .pipe(
        tap((res: TreeData) => {
          if (uri && uri.match('referenceview')) {
            this.treeRefData = res;
            this.treeRefDataSource.next(this.treeRefData);
          } else {
            this.treeData = res;
            this.treeDataSource.next(this.treeData);
          }
        })
      );
  }

  loadTreeByQuery(objId: string, typeName: string, options: TreeQuery = {}, uri?: ViewURI): Observable<TreeData> {
    const _uri = uri || 'contextview';
    const postData: TreeQuery = {
      ...(_uri.match('contextview') ? {
        contextid: objId,
        contexttype: typeName
      } : {
          targetid: objId,
          targettype: typeName
        }),
      mode: 'tree',
      timezone: Utils.getTimezoneOffset(),
      ...options
    };
    return this.backend
      .post(`/${_uri}`, postData, this.backend.getContextBase())
      .pipe(map((res: any) => ({...res, folder: (res && res.folder) || []})));
  }

  /**
   * Select a node from the context tree. This will execute the nodes query
   * and update the result list subject.
   */
  selectTreeNode({id: objId, typeName}: DmsObject, treeNode?: TreeNode, options: TreeQuery = {}, uri?: ViewURI): Observable<SearchResult> {
    const _uri = uri || 'contextview';
    const postData: TreeQuery = {
      ...(_uri.match('contextview') ? {
        contextid: objId,
        contexttype: typeName
      } : {
          targetid: objId,
          targettype: typeName
        }),
      mode: 'result',
      filter: treeNode && treeNode.data ? [treeNode.data.key] : '',
      timezone: Utils.getTimezoneOffset(),
      ...options
    };

    return this.backend
      .post(`/${_uri}?size=10000`, postData, this.backend.getContextBase())
      .pipe(
        map((res) => {
          let result = new SearchResult();
          let sortorder = (res.sortorder || []).map(s => ({qname: s.qname || s.field, direction: s.direction.toUpperCase()}));
          let grouporder = (res.grouporder || []).map(s => ({qname: s.qname || s.field}));
          let pinned = (res.pinned || []).map(s => ({qname: s.qname || s.field}));
          result.fields = new FieldDefinition(res.fields, sortorder, grouporder, pinned);
          result.hits = res.dms;
          result.count = res.total;
          result.typeName = res.type;

          this._list = {
            name: treeNode.name,
            result
          };
          this.listSource.next(this._list);

          return result;
        })
      );
  }

}
