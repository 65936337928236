<eo-dialog #listDialog [title]="pickerTitle" [visible]="display" [ngClass]="{empty: !(list?.entries?.length)}"
  [styleClass]="'dynlist-dialog'" (hide)="display = false">

  <eo-tree [tree]="tree" *ngIf="display && tree" [isDisplayed]="display" [options]="{multiselect: multiselect}"
    [(ngModel)]="selectedNodes" (keyup.enter)="showDialog($event, false)"
    (ngModelChange)="onTreeSelectionChanged($event)">
  </eo-tree>

  <div class="empty" *ngIf="!(list?.entries?.length)">
    <div translate>eo.form.property.dynamic.list.empty</div>
  </div>
</eo-dialog>

<div class="eo-dynamic-list" [ngClass]="{full: selectedNodes && selectedNodes.length === autocompleteValues.length}">

  <yvc-autocomplete [(ngModel)]="selectedNodes" #autocomplete (suggestionSelect)="onAutoCompleteSelect($event)"
    [disabled]="readonly" (suggestionUnselect)="onAutoCompleteUnselect($event)" [forceSelection]="true"
    [autocompleteValues]="autocompleteRes" field="{{valueField}}" (autocompleteFnc)="autocompleteFn($event)"
    [multiple]="multiselect">

    <ng-template let-item #chipTemplate>
      <div>
        <div class="value">{{item[valueField]}}</div>
      </div>
    </ng-template>

    <ng-template #autocompleteOptionTemplate let-item>
      <div>
        <div class="value">{{item[valueField]}}</div>
        <div class="description" style="font-style: italic; color: var(--text-color-caption)">{{item[descriptionField]}}</div>
      </div>
    </ng-template>
  </yvc-autocomplete>

  <button #button class="ui-button" *ngIf="!readonly" [disabled]="readonly" (click)="showDialog($event, $event.detail)"
    (keyup.arrowDown)="showDialog($event)" (keyup.enter)="showDialog($event)" (keyup.space)="showDialog($event)">
    <eo-icon [iconSrc]="'assets/_default/svg/ic_arrow_down.svg'"></eo-icon>
  </button>
</div>