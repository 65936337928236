<form class="eo-number-range" [formGroup]="rangeForm">

  <eo-number *ngIf="searchOption === 'gtelte'"
             [readonly]="readonly"
             [scale]="scale"
             [precision]="precision"
             [grouping]="grouping"
             [pattern]="pattern"
             formControlName="numberValueFrom"></eo-number>

  <yvc-dropdown class="no-trigger" [(ngModel)]="searchOption"
              (ngModelChange)="onValueChange()"
              [disabled]="readonly"
              [disableClearButton]="true"
              [ngModelOptions]="{standalone: true}"
              [options]="availableSearchOptions"></yvc-dropdown>

  <eo-number [scale]="scale"
             [readonly]="readonly"
             [precision]="precision"
             [grouping]="grouping"
             [pattern]="pattern"
             formControlName="numberValue"></eo-number>

</form>
