import {Injectable} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';
import {EnaioEvent, EventService} from '@eo-sdk/core';

export interface SelectionConfig {
  in?: string;
  out?: string;
  useSelection?: boolean;
}

@Injectable()
export class SelectionService {

  private children: any = {};

  private selection: any[] = [];
  private selectionSource = new ReplaySubject<any[]>(1);
  public selection$: Observable<any[]> = this.selectionSource.asObservable().pipe(debounceTime(200));

  private _focus: any = {};
  private focusSource = new ReplaySubject<any>(1);
  public focus$: Observable<any> = this.focusSource.asObservable().pipe(debounceTime(200));

  private _disabled = false;

  constructor(private eventService: EventService) {
    this.eventService
      .on(EnaioEvent.LOGOUT)
      .subscribe(() => {
        this.clear();
        Object.keys(this.children).forEach(k => this.children[k].clear());
      });
  }

  public focus(focus: any) {
    if (!this._disabled) {
      this._focus = focus;
      this.focusSource.next(this._focus);
    }
  }

  public set(selection: any[]) {
    if (!this._disabled) {
      this.selection = selection;
      this.selectionSource.next(this.selection);
    }
  }

  public disable(val = true) {
    this._disabled = val;
  }

  public get() {
    return this.selection;
  }

  public clear() {
    this.set([]);
    this.focus({});
  }

  public getFocus() {
    return this._focus;
  }

  public find(id: string): SelectionService {
    return (this.children[id] || this) as SelectionService;
  }

  public createNew(id: string): SelectionService {
    this.children[id] = new SelectionService(this.eventService);
    return this.find(id);
  }

}
