import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'eo-loading-spinner',
  template: `
    <div class="eo-loading">
      <div class="eo-loader" [ngClass]="{small: size === 'small', medium: size === 'medium', large: size === 'large', dark: mode === 'dark'}"></div>
    </div>`,
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() size: string;
  @Input() mode: string;

  constructor() {
  }

  ngOnInit() {
  }

}
