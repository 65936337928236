
<section class="reference-finder-search--input">
  <input #searchInput autofocus tabindex="1" (input)="searchTerm$.next($event.target.value)" class="search" type="text" placeholder="{{('eo.search.result.term' | translate)}}"/>
</section>

<section class="reference-finder--content" *ngIf="!searching; else loading">
  <div class="results">
    <eo-simple-accordion *ngIf="clipboardItems.length && !copyOnly"
    [header]="'eo.clipboard.type.copy' | translate"
    [selected]="clipboardOpen"
    (selectChange)="clipboardOpen = $event;"
    class="results-accordion">
        <eo-reference-finder-entry *ngFor="let list of clipboardItems" #entry
          [@fadeInOut]
          [list]="list"
          [multiselect]="multiselect"
          (selectReference)="selectReference($event)"
          [marked]="list.id === markedItem?.id"
        >
      </eo-reference-finder-entry>
    </eo-simple-accordion>

    <section *ngIf="clipboardItems.length && copyOnly">
      <eo-reference-finder-entry *ngFor="let list of clipboardItems" #entry
        [@fadeInOut]
        [list]="list"
        [multiselect]="multiselect"
        (selectReference)="selectReference($event)"
        [marked]="list.id === markedItem?.id"
      >
      </eo-reference-finder-entry>
    </section>
    <div class="padding"></div>

    <eo-simple-accordion *ngIf="resultList.length && !searchOnly" [header]="'eo.search.title' | translate" class="results-accordion">
      <eo-reference-finder-entry *ngFor="let list of resultList" #entry
        [@fadeInOut]
        [list]="list"
        [multiselect]="multiselect"
        (selectReference)="selectReference($event)"
        [marked]="list.id === markedItem?.id"
      >
      </eo-reference-finder-entry>
    </eo-simple-accordion>

    <section *ngIf="resultList.length && searchOnly">
      <eo-reference-finder-entry *ngFor="let list of resultList" #entry
        [@fadeInOut]
        [list]="list"
        [multiselect]="multiselect"
        (selectReference)="selectReference($event)"
        [marked]="list.id === markedItem?.id"
      >
      </eo-reference-finder-entry>
    </section>
  </div>

  <div class="cta">
    <button type="button" *ngIf="multiselect" (click)="selectAllEntries()">{{('eo.cta.button.select.all' | translate)}}</button>
    <button type="button" class="add" (click)="addItems()" *ngIf="!disableAdd" [@fadeInOut]  [class.active]="!disableAdd" [disabled]="disableAdd" [title]="'eo.cta.button.add' | translate">
      {{('eo.cta.button.add' | translate)}}
    </button>
  </div>
</section>

<ng-template #loading>
  <div class="loader-overlay__mask">
    <eo-loading-spinner class="reference__loader" [size]="'large'"></eo-loading-spinner>
  </div>
</ng-template>
