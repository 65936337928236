import {Component} from '@angular/core';
import {OrganizationComponent} from '../../form-elements/organization/organization.component';
import {IFilterAngularComp} from '@ag-grid-community/angular';
import {IAfterGuiAttachedParams, IDoesFilterPassParams} from '@ag-grid-community/core';
import {SystemService, BackendService, UserService} from '@eo-sdk/core';
import {TranslateService} from '@eo-sdk/core';

@Component({
  selector: 'eo-organization-filter',
  templateUrl: '../../form-elements/organization/organization.component.html',
  styleUrls: ['../../form-elements/organization/organization.component.scss']
})
export class OrganizationFilterComponent extends OrganizationComponent implements IFilterAngularComp {
  params: any = {};
  multiselect = true;

  constructor(backend: BackendService, systemService: SystemService, translate: TranslateService, userService: UserService) {
    super(backend, translate, systemService, userService);
    this.placeholder = translate.instant('eo.filter.input.placeholder');
  }

  onValueChange() {
    if (!this.params.filterParams || !this.params.filterParams.applyButton) {
      this.params.api.onFilterChanged();
    }
  }

  onSelect(value) {
    super.onSelect(value);
    this.onValueChange();
  }

  onUnselect(value) {
    super.onUnselect(value);
    this.onValueChange();
  }

  isFilterActive() {
    return !!(this.value && this.value.length);
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    if (this.innerValue && this.innerValue.length) {
      // todo: super hotfix - COOL-8989 - solves problem with resolving of ORGANIZATION value (via qname)
      const value = params.data[this.params.colDef.field] || params.data[this.params.colDef.refData.qname];
      return (Array.isArray(value) ? value : [value])
        .some(val => !!~this.innerValue.map(v => v.title).indexOf(val));
    }
    return false;
  }

  getModel() {
    return this.value && this.value.length ? {
      filterType: 'OrganizationFilterComponent',
      filter: this.value
    } : null;
  }

  setModel(value: any) {
    this.writeValue(value);
  }

  agInit(params: any) {
    this.params = params;
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    // this.autoCompleteInput.multiInputEL.nativeElement.focus();
  }
}
