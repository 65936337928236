<div class="quick-search" eoRtlAware>

  <form class="search-box" (ngSubmit)="executeSearch()"
        [ngClass]="{invalid: invalidTerm}"
        [formGroup]="searchForm">

    <eo-icon (click)="executeSearch()" [iconSrc]="'assets/_default/svg/ic_search.svg'"></eo-icon>
    <div class="input">
      <label>{{'eo.quicksearch.label' | translate}}</label>
      <input autofocus="autofocus" formControlName="searchInput">
    </div>
    <eo-loading-spinner *ngIf="loading" [size]="'small'"></eo-loading-spinner>
    <eo-icon class="small" [iconSrc]="'assets/_default/svg/ic_clear.svg'" [iconTitle]="('eo.search.clear.search' | translate)"
    *ngIf="query.term" [ngClass]="{active: query.term}" (click)="setTerm()"></eo-icon>
    <eo-icon class="small" [iconSrc]="'assets/_default/svg/ic_refresh.svg'" [iconTitle]="('eo.search.result.term' | translate) + ' : &quot;' + lastTerm + '&quot;'"
    *ngIf="lastTerm && !query.term" (click)="setTerm(lastTerm)"></eo-icon>

    <div class="count" *ngIf="showResults && !queryState.isEmpty">
      <span class="count-total">{{queryState.totalCount | localeDecimal}}</span><span translate>eo.quicksearch.hits</span>
    </div>
  </form>

  <div class="result" *ngIf="showResults && !queryState.isEmpty">
    <div class="r"><div class="ri">
      <div class="group" *ngFor="let key of resultGroups | keys; index as i">
        <h2 *ngIf="key !== '0'; else maingroup">{{key}}</h2>
        <ng-template #maingroup>
          <h2 translate>eo.quicksearch.result.group.global</h2>
        </ng-template>

        <div class="type" *ngFor="let type of resultGroups[key]" (click)="executeSearch(type.data)" [ngClass]="{selected: selected.name === type.data.name}">
          <span class="count">{{type.count}}</span>
          <span class="label">{{type.label}}</span>
        </div>
      </div></div>
      </div>
  </div>
</div>
