import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {EoSharedModule} from '@eo-sdk/core';
import {EoIconComponent} from './eo-icon/eo-icon.component';
import {OutsideClickDirective} from './outside-click/outside-click.directive';
import {RtlAwareDirective} from './rtl-aware/rtl-aware.directive';
import {PipesModule} from '../../eo-framework-core/pipes/pipes.module';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClipboardComponent} from './clipboard/clipboard.component';
import {IndexdataSummaryComponent} from './indexdata-summary/indexdata-summary.component';
import {UserAvatarComponent} from './user-avatar/user-avatar.component';
import {OverlayComponent} from './overlay/overlay.component';
import {ErrorMessageComponent} from './error-message/error-message.component';
import {EoDialogComponent} from './eo-dialog/eo-dialog.component';
import {RouterModule} from '@angular/router';
import {OverlayModule} from '@angular/cdk/overlay';
import {AccordionModule} from '../accordion/accordion.module';
import {IndexdataSummaryEntryComponent} from './indexdata-summary/indexdata-summary-entry/indexdata-summary-entry.component';
import {RouterLinkDirective} from './router-link/router-link.directive';
import { QueryScopeSelectComponent } from './query-scope-select/query-scope-select.component';
import {YvcDialogModule} from '@yuuvis/components/dialog';
import {A11yModule} from '@angular/cdk/a11y';
import {TrapFocusDirective} from '../util/directive/trap-focus.directive';
import { SignatureTabComponent } from './signature-tab/signature-tab.component';
import {YvcOverlayModule} from '@yuuvis/components/overlay';

/**
 * Shared module for all ui components that are used across the whole app
 * and in other components.
 */

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    EoSharedModule,
    PipesModule,
    BrowserAnimationsModule,
    RouterModule,
    AccordionModule,
    OverlayModule,
    YvcDialogModule,
    YvcOverlayModule,
    A11yModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    EoSharedModule,
    PipesModule,
    EoIconComponent,
    OutsideClickDirective,
    RouterLinkDirective,
    RtlAwareDirective,
    LoadingSpinnerComponent,
    ClipboardComponent,
    IndexdataSummaryComponent,
    UserAvatarComponent,
    OverlayComponent,
    ErrorMessageComponent,
    EoDialogComponent,
    QueryScopeSelectComponent,
    TrapFocusDirective,
    SignatureTabComponent
  ],
  declarations: [
    EoIconComponent,
    OutsideClickDirective,
    RouterLinkDirective,
    RtlAwareDirective,
    LoadingSpinnerComponent,
    ClipboardComponent,
    IndexdataSummaryComponent,
    UserAvatarComponent,
    OverlayComponent,
    ErrorMessageComponent,
    EoDialogComponent,
    IndexdataSummaryEntryComponent,
    QueryScopeSelectComponent,
    TrapFocusDirective,
    SignatureTabComponent
  ]
})
export class UiModule {
}
