import {ANALYZE_FOR_ENTRY_COMPONENTS, ModuleWithProviders, NgModule} from '@angular/core';
import {PreventDoubleClickDirective} from '../util/directive/prevent-double-click.directive';
import {ACTIONS, CUSTOM_ACTIONS, ActionService} from './action-service/action.service';
import {ActionMenuComponent} from './action-menu/action-menu.component';
import {CommonModule} from '@angular/common';
import {UiModule} from '../ui/ui.module';
import {ActionComponentAnchorDirective} from './action-menu/action-component-anchor/action-component-anchor.directive';
import {CopyActionComponent} from './actions/copy-action/copy-action';
import {OpenContextActionComponent} from './actions/open-context-action/open-context-action';
import {DownloadActionComponent} from './actions/download-action/download-action';
import {OpenDocumentComponent} from './actions/open-document-action/open-document-action.component';
import {OpenDocumentActionComponent} from './actions/open-document-action/open-document-action';
import {ObjectFormModule} from '../object-form/object-form.module';
import {EmailActionComponent} from './actions/email/email-action';
import {ClipboardActionComponent} from './actions/clipboard/clipboard-action';
import {FavoriteActionComponent} from './actions/favorite-action/favorite-action';
import {DeleteActionComponent} from './actions/delete-action/delete-action';
import {DeleteComponent} from './actions/delete-action/delete/delete.component';
import {OpenVersionsActionComponent} from './actions/open-versions-action/open-versions-action';
import {RestoreVersionActionComponent} from './actions/restore-version-action/restore-version-action';
import {DeletePreparedActionComponent} from './actions/delete-action/delete-prepared-action';
import {RouterModule} from '@angular/router';
import {AddSubscriptionComponent} from './actions/add-subscription-action/add-subscription/add-subscription.component';
import {AddSubscriptionActionComponent} from './actions/add-subscription-action/add-subscription-action';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RemoveSubscriptionActionComponent} from './actions/remove-subscription-action/remove-subscription-action';
import {WorkflowActionComponent} from './actions/workflow-action/workflow-action.component';
import {WorkflowComponent} from './actions/workflow-action/workflow/workflow.component';
import {CustomActionsComponent} from './actions/custom-actions/custom-actions.component';
import {CtaModule} from '../cta/cta.module';
import {AddResubmissionActionComponent} from './actions/add-resubmission-action/add-resubmission-action';
import {AddResubmissionComponent} from './actions/add-resubmission-action/add-resubmission/add-resubmission.component';
import {UpdateResubmissionActionComponent} from './actions/update-resubmission-action/update-resubmission-action';
import {ShareObjectActionComponent} from './actions/share-object-action/share-object-action';
import {ShareObjectComponent} from './actions/share-object-action/share-object/share-object.component';
import {CutActionComponent} from './actions/cut-action/cut-action';
import {FinalizeActionComponent} from './actions/finalize/finalize-action';
import {DefinalizeActionComponent} from './actions/finalize/definalize-action';
import {DeleteContentActionComponent} from './actions/delete-content-action/delete-content-action';
import {UnlockActionComponent} from './actions/unlock-action/unlock-action';
import {SimpleWorkflowActionComponent} from './actions/workflow-action/simple-workflow-action.component';
import {ProcessFormModule} from '../process-form/process-form.module';
import { FinalizeObjectActionComponent } from './actions/finalize/finalize-object-action/finalize-object-action.component';
import {DeleteContentComponent} from './actions/delete-content-action/delete-content/delete-content.component';
import {SignatureActionComponent} from './actions/signature-action/signature-action.component';
import {SignatureComponent} from './actions/signature-action/signature/signature.component';
import {CancelSigningActionComponent} from './actions/cancel-signing-action/cancel-signing-action';
import {CancelSigningComponent} from './actions/cancel-signing-action/cancel-signing/cancel-signing.component';
import {YvcDatepickerModule} from '@yuuvis/components/datepicker';
import {YvcAutofocusChild} from '@yuuvis/components/common';
import { DownloadObjectActionComponent } from './actions/download-action/download-object-action/download-object-action.component';
import { ClipboardObjectActionComponent } from './actions/clipboard/clipboard-object-action/clipboard-object-action.component';
import { EmailComponent } from './actions/email/email/email.component';
import {SequenceListComponent} from './actions/signature-action/sequence-list/sequence-list.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SequenceListItemComponent} from './actions/signature-action/sequence-list-item/sequence-list-item.component';

export const entryComponents = [
  CopyActionComponent,
  OpenContextActionComponent,
  DownloadActionComponent,
  OpenDocumentActionComponent,
  OpenDocumentComponent,
  EmailActionComponent,
  ClipboardActionComponent,
  DeleteActionComponent,
  DeleteComponent,
  OpenVersionsActionComponent,
  RestoreVersionActionComponent,
  DeletePreparedActionComponent,
  AddSubscriptionActionComponent,
  AddSubscriptionComponent,
  RemoveSubscriptionActionComponent,
  WorkflowActionComponent,
  WorkflowComponent,
  CustomActionsComponent,
  AddResubmissionActionComponent,
  AddResubmissionComponent,
  UpdateResubmissionActionComponent,
  ShareObjectActionComponent,
  ShareObjectComponent,
  CutActionComponent,
  FinalizeActionComponent,
  FinalizeObjectActionComponent,
  DefinalizeActionComponent,
  DeleteContentComponent,
  DeleteContentActionComponent,
  UnlockActionComponent,
  SimpleWorkflowActionComponent,
  SignatureActionComponent,
  SignatureComponent,
  CancelSigningActionComponent,
  CancelSigningComponent,
  DownloadObjectActionComponent,
  ClipboardObjectActionComponent,
  EmailComponent,
  SequenceListComponent,
  SequenceListItemComponent
];

/**
 * @module
 * @description
 * Module for the action menu
 */
@NgModule({
  imports: [
    YvcAutofocusChild,
    CommonModule,
    UiModule,
    RouterModule,
    ObjectFormModule,
    FormsModule,
    ReactiveFormsModule,
    FormElementsModule,
    ObjectFormModule,
    ProcessFormModule,
    CtaModule,
    YvcDatepickerModule,
    DragDropModule
  ],
  exports: [ActionMenuComponent, OpenDocumentComponent],
  providers: [ActionService, {
    provide: ACTIONS,
    useValue: entryComponents
  }, {
    provide: CUSTOM_ACTIONS,
    useValue: []
  }],
  declarations: [
    ActionMenuComponent,
    ActionComponentAnchorDirective,
    CopyActionComponent,
    OpenContextActionComponent,
    DownloadActionComponent,
    OpenDocumentActionComponent,
    OpenDocumentComponent,
    EmailActionComponent,
    ClipboardActionComponent,
    FavoriteActionComponent,
    DeleteActionComponent,
    DeleteComponent,
    OpenVersionsActionComponent,
    RestoreVersionActionComponent,
    DeletePreparedActionComponent,
    AddSubscriptionActionComponent,
    AddSubscriptionComponent,
    AddResubmissionActionComponent,
    AddResubmissionComponent,
    RemoveSubscriptionActionComponent,
    WorkflowActionComponent,
    WorkflowComponent,
    CustomActionsComponent,
    UpdateResubmissionActionComponent,
    ShareObjectActionComponent,
    ShareObjectComponent,
    CutActionComponent,
    FinalizeActionComponent,
    FinalizeObjectActionComponent,
    DefinalizeActionComponent,
    DeleteContentComponent,
    DeleteContentActionComponent,
    UnlockActionComponent,
    SimpleWorkflowActionComponent,
    PreventDoubleClickDirective,
    SignatureActionComponent,
    SignatureComponent,
    CancelSigningActionComponent,
    CancelSigningComponent,
    DownloadObjectActionComponent,
    ClipboardObjectActionComponent,
    EmailComponent,
    SequenceListComponent,
    SequenceListItemComponent
  ],
  entryComponents
})
export class ActionModule {
  static forRoot(components: any[] = []): ModuleWithProviders<ActionModule> {
    return {
      ngModule: ActionModule,
      providers: [{
        provide: ANALYZE_FOR_ENTRY_COMPONENTS,
        useValue: components,
        multi: true,
      }, {
        provide: ACTIONS,
        useValue: entryComponents
      }, {
        provide: CUSTOM_ACTIONS,
        useValue: components
      }]
    };
  }
}
