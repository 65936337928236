import {Injectable} from '@angular/core';
import {LocalStorageService} from '@eo-sdk/core';
import {UtilitiesService} from '../../../util/services/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ListSettingsService {
  constructor(private storageService: LocalStorageService) {
  }

  get prefix(): string {
    return 'eo.list.settings.';
  }

  setupSettings(id: string, options: any[], idKey = 'id', valueKey = 'value') {
    const storedSettings = this.storageService.getItem(this.prefix + id);
    if (storedSettings) {
      (options || []).forEach(o => o[valueKey] = storedSettings.hasOwnProperty(o[idKey]) ? storedSettings[o[idKey]] : o[valueKey]);
    }
    return options || [];
  }

  saveSettings(id: string, options?: any, idKey = 'id', valueKey = 'value') {
    if (!UtilitiesService.isEmpty(options)) {
      this.storageService.setItem(this.prefix + id, !Array.isArray(options) ? options : options.reduce((obj, o) => {
        obj[o[idKey]] = o[valueKey];
        return obj;
      }, {}));
    } else {
      this.storageService.removeItem(this.prefix + id);
    }
  }

  clearSettings() {
    this.storageService.clear((key: string) => key.startsWith(this.prefix));
  }

}
