import {of as observableOf} from 'rxjs';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {ComponentAction} from '../../interfaces/action.interface';
import {AddResubmissionComponent} from '../add-resubmission-action/add-resubmission/add-resubmission.component';
import {DmsObject, UserService} from '@eo-sdk/core';

@Component({
  selector: 'eo-update-resubmission-action',
  template: ``
})
export class UpdateResubmissionActionComponent extends DmsObjectTarget implements ComponentAction {
  component = AddResubmissionComponent;
  label: string;
  description: string;
  priority = 5;
  iconSrc = 'assets/_default/svg/ic_resubmission.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;

  constructor(private translate: TranslateService, private userService: UserService) {
    super();
    this.label = this.translate.instant('eo.action.update.resubmission.label');
    this.description = this.translate.instant('eo.action.update.resubmission.description');
  }

  isExecutable(element: DmsObject) {
    const hasPrivilege = this.userService.getCurrentUser().hasPrivilege('MANAGE_RESUBMISSIONS');
    if (hasPrivilege && !window.location.href.includes('/versions') && !window.location.href.includes('/inbox')) {
      return observableOf(!!element.resubmissions.length);
    } else {
      return observableOf(false);
    }
  }
}
