import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProcessFormComponent} from './process-form.component';
import {ObjectFormModule} from '../object-form/object-form.module';
import {CtaModule} from '../cta/cta.module';
import {TranslateModule} from '@eo-sdk/core';

@NgModule({
  imports: [
    CommonModule,
    ObjectFormModule,
    CtaModule,
    TranslateModule
  ],
  declarations: [ProcessFormComponent],
  exports: [ProcessFormComponent]
})
export class ProcessFormModule { }
