import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YvcSplitViewModule} from '@yuuvis/components/split-view';
import {ObjectStateComponent} from './object-state/object-state.component';
import {EoFrameworkModule} from '../../eo-framework/eo-framework.module';
import {ObjectStateRoutingModule} from './object-state-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ObjectStateRoutingModule,
    EoFrameworkModule,
    YvcSplitViewModule
  ],
  declarations: [ObjectStateComponent]
})
export class ObjectStateModule {
}
