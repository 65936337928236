import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {UiModule} from '../ui/ui.module';
import {AppBarComponent} from './app-bar/app-bar.component';
import {ContextSearchComponent} from './app-bar/context-search/context-search.component';
import {SideBarComponent} from './app-bar/side-bar/side-bar.component';
import {AppSearchComponent} from './app-bar/app-search/app-search.component';
import {AppAddComponent} from './app-bar/app-add/app-add.component';
import {EoSharedModule} from '@eo-sdk/core';
import {YvcTabsModule} from '@yuuvis/components/tabs';

import {AppShellRoutingModule} from './app-shell-routing.module';
import {ShortcutsModule} from '../shortcuts/shortcuts.module';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {ObjectFormModule} from '../object-form/object-form.module';
import {AppAddDialogComponent} from './app-bar/app-add/app-add-dialog/app-add-dialog.component';
import {ActionModule} from '../actions/action.module';
import {UtilModule} from '../util/util.module';
import {CtaModule} from '../cta/cta.module';
import {AppProcessComponent} from './app-bar/app-process/app-process.component';
import {ProcessFormModule} from '../process-form/process-form.module';
import {YvcAutocompleteModule} from '@yuuvis/components/autocomplete';
import {YvcDialogModule} from '@yuuvis/components/dialog';

/**
 * Module holding the base components of the application shell like application bar, side, search etc.
 */

@NgModule({
  imports: [
    AppShellRoutingModule,
    CommonModule,
    FormsModule,
    UiModule,
    UtilModule,
    EoSharedModule,
    YvcTabsModule,
    FormElementsModule,
    ObjectFormModule,
    ShortcutsModule,
    ActionModule,
    CtaModule,
    ProcessFormModule,
    YvcAutocompleteModule,
    YvcDialogModule,
  ],
  exports: [AppBarComponent],
  declarations: [
    AppBarComponent,
    SideBarComponent,
    AppSearchComponent,
    AppAddComponent,
    AppAddDialogComponent,
    ContextSearchComponent,
    AppProcessComponent,
  ]
})
export class EoAppShellModule {
}
