<div class="action-menu dark" *ngIf="showMenu" (eoOutsideClick)="hide()">

  <div class="head">
    <div class="left">
      <div class="title" translate>eo.actions.sticky.title</div>
      <div class="sub-title" *ngIf="selection?.length > 1; else multi" translate>eo.actions.sticky.title.sub.selection</div>
      <ng-template #multi>
        <div class="sub-title" *ngIf="selection && selection.length"><span translate>eo.actions.sticky.title.sub.for</span> '{{selection[0].title}}'</div>
      </ng-template>
    </div>
    <div class="right">
      <span class="count">{{subSelection?.length || selection?.length || ''}}</span>
      <eo-icon class="button" [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="hide()"></eo-icon>
    </div>
  </div>

  <div class="actions" [hidden]="subActionsList || showComponent">

    <ng-template #action let-entry let-id>

      <a *ngIf="isLinkAction(entry.action); else default" class="link"
         [routerLink]="entry.action.getLink(entry.availableSelection)"
         [queryParams]="entry.action.getParams(entry.availableSelection, true)">
        <ng-container *ngTemplateOutlet="default"></ng-container>
      </a>

      <ng-template #default>
        <div>
          <eo-icon [iconSrc]="entry.action.iconSrc" [iconId]="entry.action.iconId"
          [badge]="entry.availableSelection?.length != selection?.length ? entry.availableSelection?.length : null"></eo-icon>
          <div class="label">{{entry.action.label}}</div>
          <!-- <div class="available-count" *ngIf="entry.availableSelection?.length != selection?.length">{{entry.availableSelection?.length}}</div> -->
          <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'" (click)="showActionDescription(id, $event)"></eo-icon>
        </div>
        <div class="description" [hidden]="id !== actionDescription">{{entry.action.description}}</div>
      </ng-template>
    </ng-template>

    <div class="group" *ngIf="actionLists?.common.length > 0">
      <div class="group-title" translate>eo.actions.group.common</div>
      <div class="action" id="{{actionListEntry.id}}" (keydown)="isArrowUpOrDownPressed($event)" [attr.tabindex]="0" *ngFor="let actionListEntry of actionLists.common; let i = index"
        (click)="onClick(actionListEntry)" [ngClass]="{active: activeActionIndex === i}">

        <ng-container *ngTemplateOutlet="action; context: {$implicit: actionListEntry, id: +i}"></ng-container>

      </div>
    </div>

    <div class="group" *ngIf="!loading; else loader">
      <div *ngIf="actionLists?.further.length > 0">
        <div class="group-title" translate>eo.actions.group.further_actions</div>
        <div class="action" id="{{actionListEntry.id}}" (keydown)="isArrowUpOrDownPressed($event)" *ngFor="let actionListEntry of actionLists.further; index as i"
          eoPreventDoubleClick [ngClass]="{active: activeActionIndex === i + actionLists?.common.length }"
          (debounceClick)="onClick(actionListEntry)">

          <ng-container *ngTemplateOutlet="action; context: {$implicit: actionListEntry, id: 'f'+i}"></ng-container>

        </div>
      </div>
    </div>

    <div class="group" *ngIf="!loading && !actionLists?.further.length && !actionLists?.common.length" [style.textAlign]="'center'">
      {{ 'eo.action.not.available' | translate}}
    </div>

    <ng-template #loader>
      <div class="loading-container">
        <eo-loading-spinner [size]="'medium'"></eo-loading-spinner>
      </div>
    </ng-template>
  </div>

  <!-- sub actions -->
  <div  class="group sub" *ngIf="subActionsList && !showComponent">
    <div class="sub-actions">
      <div class="group-title">{{subActionsListHeader}}</div>
      <div class="action sub" *ngFor="let actionListEntry of subActionsList; index as i" (click)="onClick(actionListEntry)">
        <ng-container *ngTemplateOutlet="action; context: {$implicit: actionListEntry, id: 's'+i}"></ng-container>
      </div>
    </div>
    <div class="group-buttons">
      <button (click)="onCancel()" translate>eo.export.cancel</button>
    </div>
  </div>

  <div>
    <ng-template eoActionComponentAnchor></ng-template>
  </div>

  <div>
    <ng-template eoExtActionComponentAnchor></ng-template>
  </div>

</div>
