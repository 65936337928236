import {
  AfterContentChecked,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {BackendService, SystemService, TranslateService, UserService, Utils} from '@eo-sdk/core';
import {AutoComplete} from '@yuuvis/components/autocomplete';
import {forkJoin as observableForkJoin, of as observableOf, Subscription} from 'rxjs';

export interface OrganizationFilter {
  type?: string;        // The type of the organization unit ('USER' or 'GROUP') to be displayed
  groups?: string[];    // an array of group names to filter autocomplete results with
  roles?: string[];     // an array of role names to filter autocomplete results with
  activeonly?: boolean; // whether or not to fetch only active organization objects
}

@Component({
  selector: 'eo-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrganizationComponent),
      multi: true
    }
  ]
})
export class OrganizationComponent implements ControlValueAccessor, AfterContentChecked, Validator {

  @ViewChild('autocomplete') autoCompleteInput: AutoComplete;

  icon = {
    user: '<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M480-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42ZM192-192v-96q0-23 12.5-43.5T239-366q55-32 116.5-49T480-432q63 0 124.5 17T721-366q22 13 34.5 34t12.5 44v96H192Z"/></svg>',
    group: '<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M96-192v-92q0-26 12.5-47.5T143-366q54-32 114.5-49T384-432q66 0 126.5 17T625-366q22 13 34.5 34.5T672-284v92H96Zm648 0v-92q0-42-19.5-78T672-421q39 8 75.5 21.5T817-366q22 13 34.5 34.5T864-284v92H744ZM384-480q-60 0-102-42t-42-102q0-60 42-102t102-42q60 0 102 42t42 102q0 60-42 102t-102 42Zm336-144q0 60-42 102t-102 42q-8 0-15-.5t-15-2.5q25-29 39.5-64.5T600-624q0-41-14.5-76.5T546-765q8-2 15-2.5t15-.5q60 0 102 42t42 102Z"/></svg>'
  }

  remoteUrl = '/organization/query?q=';
  minLength = 2;
  wildCard = '*';
  @HostBinding('class.readonly') _readonly: boolean;

  @Output()
  onValueResolved = new EventEmitter();
  // emitted when value changed holding the resolved data_meta
  @Output()
  onDataMetaChanged = new EventEmitter();

  autocompleteRes: any[];
  // model value
  value;
  currentUserId: string;
  // inner ng-model value
  innerValue: any[] = [];
  error: any = {};
  deletedItemID: string;
  private _removeCurrentUser: boolean = false;
  @Input() set removeCurrentUser(b: boolean) {
    this._removeCurrentUser = b;
    this.currentUserId = b ? this.userService.getCurrentUser()?.id : undefined;
  }
  get removeCurrentUser() {
    return this._removeCurrentUser
  }
  @Input() situation: string;
  @Input() multiselect: boolean;
  @Input() set readonly(r: boolean) {
    this._readonly = r;
  }
  get readonly() {
    return this._readonly;
  }
  @Input() set dataMeta(dM: any) {
    if (dM) {
      this.innerValue = Array.isArray(dM) ? dM : [dM];
    }
  }
  @Input() placeholder: string;
  // list of values that should not be selectable
  @Input() exceptions: string[] = [];
  @Input() filterObject: OrganizationFilter;

  constructor(private backend: BackendService,
    private translate: TranslateService,
    private systemService: SystemService,
    public userService: UserService,
  ) { }

  public validate() {
    return null;
  }

  private buildAutocompleteUri(query: string): string {
    const params = {
      q: query,
      activeonly: (this.situation !== 'SEARCH') ? 'true' : 'false',
      details: true
    };

    if (this.filterObject) {
      if (this.filterObject.type) {
        params['scope'] = this.filterObject.type;
      }
      if (this.filterObject.groups) {
        params['groupfilter'] = this.filterObject.groups;
      }
      if (this.filterObject.roles) {
        params['rolefilter'] = this.filterObject.roles;
      }
      if (this.filterObject.hasOwnProperty('activeonly')) {
        params['activeonly'] = this.filterObject.activeonly ? 'true' : 'false';
      }
    }
    return Utils.buildUri('/organization/query', params);
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
      this.resolveFn(value);
    } else {
      this.value = null;
      this.innerValue = [];
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  private propagate() {
    this.propagateChange(this.value);
    this.onDataMetaChanged.emit(this.innerValue);
  }

  autocompleteFn(term: string) {
    if (term.length >= this.minLength || term === this.wildCard) {
      if (this.multiselect || (!this.multiselect && this.innerValue.length === 0)) {
        this.backend.getJson(this.buildAutocompleteUri(term))
          .subscribe((res) => {
            // autocomplete values should be unique and not part of the exceptions
            this.autocompleteRes = res.filter(v => (!this.value || this.value.indexOf(v.name) === -1) && this.exceptions.indexOf(v.name) === -1 && (!this.removeCurrentUser || this.currentUserId !== v.id))
              .sort(Utils.sortValues('title'));
          },
            Utils.throw(null,
              this.translate.instant('eo.form.property.organization.request.error.title'),
              this.translate.instant('eo.form.property.organization.request.error.msg')
            )
          );
      } else {
        this.autocompleteRes = [];
      }
    } else {
      this.autocompleteRes = [];
    }
  }

  resolveFn(value: any): Subscription {
    let map = (value instanceof Array ? value : [value]).map(v => {
      let match = this.innerValue.find(iv => iv.name === v);
      return match ? observableOf(match) : this.systemService.getOrganizationObject(v);
    });
    return observableForkJoin(map).subscribe(data => {
      this.innerValue = data;
      this.onValueResolved.emit(this.innerValue);
    });
  }

  // handle selection changes to the model
  onUnselect(value) {
    this.innerValue = this.innerValue.filter(v => v.name !== value.name);
    let _value = this.innerValue.map(v => v.name);
    this.value = this.multiselect ? _value : null;
    if (!this.multiselect) {
      this.clearInnerInput();
    }
    this.autoCompleteInput.focusInput();
    this.propagate();
  }

  // handle selection changes to the model
  onSelect(value) {
    if (this.multiselect) {
      this.value = this.innerValue.map(v => v.name);
    } else {
      // internal autocomplete control is always set to multiselect
      // so the resolved value is always an array
      this.value = this.innerValue[0].name;
    }
    this.propagate();
  }

  onAutoCompleteBlur() {
    this.clearInnerInput();
  }

  private clearInnerInput() {
    this.autoCompleteInput.clearInput();
  }

  ngAfterContentChecked() {
    if (this.situation === 'SEARCH') {
      this.multiselect = true;
    }
  }
}
