<eo-dialog [title]="headerTitle"
           [subtitle]="headerDescription"
           [visible]="true"
           [dirtyCheck]="pendingTaskIds"
           [minWidth]="600"
           [minHeight]="600"
           [styleClass]="'process-form-dialog'" #dialog>

  <section class="signature-form form-container">
    <form class="signing-form" [formGroup]="signingForm">
      <eo-sequence-list class="entry_form" form-open="true" [MAX_SIGNERS]="maxSigners" (itemEdit)="mode = $event" [phoneRequired]="signingForm.get('yuvsigtype').value === 'advanced'" [formControlName]="'yuvsigners'">
      </eo-sequence-list>
      <eo-form-input *ngIf="yuvsigtypeCodesystem.entries.length > 1" [required]="true" [label]="'eo.action.signature.yuvsigning.yuvsigtype' | translate">
        <eo-codesystem
          [formControlName]="'yuvsigtype'"
          [codesystem]="yuvsigtypeCodesystem"
          [required]="true"
        ></eo-codesystem>
      </eo-form-input>
      <eo-form-input [skipToggle]="true" [label]="'eo.action.signature.yuvsigners.parallel' | translate">
        <eo-checkbox formControlName="parallelSigning"></eo-checkbox>
      </eo-form-input>
      <eo-form-input [label]="'eo.action.signature.yuvsigning.yuvsigemailsubject' | translate" [required]="true">
        <eo-string
          [formControlName]="'yuvsigemailsubject'"
          [minLength]="3"
          [maxLength]="100"
          [required]="true"
        ></eo-string>
      </eo-form-input>
      <small class="errors" *ngIf="signingForm.get('yuvsigemailsubject').hasError('required')" translate>eo.form.property.required</small>
      <eo-form-input [label]="'eo.action.signature.yuvsigning.yuvsigemailbody' | translate" [required]="true">
        <eo-string
          [formControlName]="'yuvsigemailbody'"
          [multiline]="true"
          [size]="'small'"
          [minLength]="3"
          [maxLength]="4000"
          [required]="true"
        ></eo-string>
      </eo-form-input>
      <small class="errors" *ngIf="signingForm.get('yuvsigemailbody').hasError('required')" translate>eo.form.property.required</small>
    </form>

    <div class="actions">
      <div *ngIf="signingForm?.invalid" class="invalid" translate>eo.object.indexdata.form.invalid</div>
      <eo-cta [active]="!processingRequest" class="cta-wrapper">
        <button (click)="cancel()" class="button-cancel" translate>eo.action.signature.cancel</button>
        <button (click)="saveForm()" [disabled]="signingForm?.invalid || processingRequest || !signingForm.get('yuvsigners').value || !signingForm.get('yuvsigners').value.length" class="primary" translate>eo.action.signature.request</button>
      </eo-cta>
    </div>
  </section>
  <div class="loader-overlay__mask" *ngIf="processingRequest">
    <eo-loading-spinner class="signature__loader" [size]="'large'"></eo-loading-spinner>
  </div>
</eo-dialog>
