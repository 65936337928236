import {Component, Input, OnInit} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {
  UserService, Capabilities, CapabilitiesService, PrepareService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {InfoIntrayConfig} from './info-intray.model';

@UntilDestroy()
@Component({
  selector: 'eo-info-intray',
  templateUrl: './info-intray-widget.component.html',
  styleUrls: ['./info-intray-widget.component.scss']
})
export class InfoIntrayWidgetComponent implements IWidgetComponent, OnInit {

  capabilities: Capabilities;
  preparedItemsCount: number;

  private _widgetConfig: InfoIntrayConfig;
  @Input() set widgetConfig(c: InfoIntrayConfig | undefined) {
    this._widgetConfig = c ;
  }
  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(public userService: UserService,
    private capabilityService: CapabilitiesService,
    private prepareService: PrepareService,
  ) {}

  ngOnInit(): void {
    this.capabilities = this.capabilityService.getCapabilities();
    if (this.capabilities.intray) {
      this.prepareService.preparedItemCount$
        .pipe(untilDestroyed(this))
        .subscribe(count => this.preparedItemsCount = count);
    }
  }
}

