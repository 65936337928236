import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {DmsObject, NotificationsService, TranslateService} from '@eo-sdk/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {Location} from '@angular/common';
import {AgentService} from '../../../../../eo-framework-core/agent/agent.service';


@Component({
  selector: 'eo-clipboard-object-action',
  templateUrl: './clipboard-object-action.component.html',
  styleUrls: ['./clipboard-object-action.component.scss']
})
export class ClipboardObjectActionComponent implements ActionComponent, AfterViewInit {


  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  actionDescription: string;
  showDescriptionOrginal: boolean;
  showDescriptionPdf: boolean;
  showDescriptionLink: boolean;
  isExecutable: boolean


  constructor(
    private translate: TranslateService,
    private toast: NotificationsService,
    private location: Location,
    private agentService: AgentService,
    private elemRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('[tabindex="0"]').focus();
      this.isExecutable = this.agentService.isConnected && !this.selection[0].isFolder && this.selection[0].content && (this.selection[0].content.count !== 0 || this.selection[0].content.linked)

    });
  }

  runLink() {

    let urlPrefix = window.location.href.replace(this.location.path(), '');
    let clipboardText = '';

    this.selection.forEach(element => {
      if (window.location.href.includes('/versions')) {
        clipboardText += urlPrefix + '/versions/' + element.id + '?type=' + element.type.name + '&version=' + element.version + '\n';
      } else {
        clipboardText += urlPrefix + '/object/' + element.id + '?type=' + element.type.name + '\n';
      }
    });

    // Copy to clipboard via invisible textarea
    let textArea = document.createElement('textarea');
    textArea.value = clipboardText;
    document.body.appendChild(textArea);
    textArea.select();
    let copySuccess = document.execCommand('copy');
    document.body.removeChild(textArea);

    if (copySuccess) {
      this.toast.success(this.translate.instant('eo.action.menu.clipboard.link.successful'));
    } else {
      this.toast.error(this.translate.instant('eo.action.menu.clipboard.link.error'));
    }
    this.finished.emit();
  }

  runOriginal() {
    let documents = this.selection.map((element) => ({
      id: (element.content && element.content.linked && element.content.id) ? element.content.id : element.id,
      title: element.title,
      type: (element.content && element.content.linked && element.content.type) ? element.content.type : element.type.name,
      version: window.location.href.includes('/versions') ? element.version : null,
      rendition: ''
    }),
    this.finished.emit()
    );

    this.agentService.copyToClipboard(documents);
  }

  runPdf() {
    let documents = this.selection.map((element) => ({
      id: (element.content && element.content.linked && element.content.id) ? element.content.id : element.id,
      title: element.title,
      type: (element.content && element.content.linked && element.content.type) ? element.content.type : element.type.name,
      version: window.location.href.includes('/versions') ? element.version : null,
      rendition: 'PDF'
    }),
    this.finished.emit()
    );

    this.agentService.copyToClipboard(documents);
  }

  showDescription(i, event) {
    this.actionDescription = i === this.actionDescription ? null : i;
    this.showDescriptionOrginal = this.actionDescription != null && this.actionDescription == 'original' ? true : false;
    this.showDescriptionPdf = this.actionDescription != null  && this.actionDescription == 'pdf' ? true : false;
    this.showDescriptionLink = this.actionDescription != null  && this.actionDescription == 'link' ? true : false;
    event.stopPropagation();
    event.preventDefault();
  }


  cancel() {
    this.canceled.emit();
  }
}
