import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { YvcAutocompleteModule } from '@yuuvis/components/autocomplete';
import { YvcCheckboxModule } from '@yuuvis/components/checkbox';
import { YvcChipsModule } from '@yuuvis/components/chips';
import { YvcDatepickerModule } from '@yuuvis/components/datepicker';
import { YvcDropdownModule } from '@yuuvis/components/dropdown';
import { YvcIconModule } from '@yuuvis/components/icon';

import { AccordionModule } from '../accordion/accordion.module';
import { TreeModule } from '../tree/tree.module';
import { UiModule } from '../ui/ui.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CodesystemComponent } from './codesystem/codesystem.component';
import { DateComponent } from './date/date.component';
import { DatetimeRangeComponent } from './datetime-range/datetime-range.component';
import { YearRangeDirective } from './date/year-range/year-range.directive';
import { DynamicListComponent } from './dynamic-list/dynamic-list.component';
import { FormInputComponent } from './form-input/form-input.component';
import { InputFocusDirective } from './form-input/input-focus/input-focus.directive';
import { IdReferenceComponent } from './id-reference/id-reference.component';
import { ReferenceFinderEntryComponent } from './id-reference/reference-finder-entry/reference-finder-entry.component';
import { ReferenceFinderComponent } from './id-reference/reference-finder/reference-finder.component';
import { NumberRangeComponent } from './number-range/number-range.component';
import { NumberComponent } from './number/number.component';
import { OrganizationComponent } from './organization/organization.component';
import { PasswordComponent } from './password/password.component';
import { ReferenceComponent } from './reference/reference.component';
import { StringComponent } from './string/string.component';

@NgModule({
  imports: [
    YvcIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    TreeModule,
    // InputTextModule,
    // DialogModule,
    // InputMaskModule,
    // CheckboxModule,
    // TriStateCheckboxModule,
    // InputTextareaModule,
    // ChipsModule,
    // DropdownModule,
    RouterModule,
    // SharedModule,
    AccordionModule,
    YvcChipsModule,
    YvcCheckboxModule,
    YvcAutocompleteModule,
    YvcDropdownModule,
    YvcDatepickerModule
  ],
  declarations: [
    FormInputComponent,
    CodesystemComponent,
    DatetimeRangeComponent,
    NumberComponent,
    NumberRangeComponent,
    OrganizationComponent,
    CheckboxComponent,
    StringComponent,
    ReferenceComponent,
    DynamicListComponent,
    InputFocusDirective,
    PasswordComponent,
    IdReferenceComponent,
    YearRangeDirective,
    ReferenceFinderComponent,
    ReferenceFinderEntryComponent,
    DateComponent
  ],
  exports: [
    FormInputComponent,
    CodesystemComponent,
    DateComponent,
    DatetimeRangeComponent,
    NumberComponent,
    NumberRangeComponent,
    OrganizationComponent,
    CheckboxComponent,
    StringComponent,
    ReferenceComponent,
    DynamicListComponent,
    PasswordComponent,
    IdReferenceComponent,
    YearRangeDirective
  ]
})
export class FormElementsModule { }
