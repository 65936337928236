
import {of as observableOf, Observable, of} from 'rxjs';
import {LinkAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {DmsObject} from '@eo-sdk/core';

@Component({
  selector: 'eo-open-context-action',
  template: ``
})
export class OpenContextActionComponent extends DmsObjectTarget implements LinkAction {
  label: string;
  description: string;
  priority = 3.5;
  iconSrc = 'assets/_default/svg/ic_context.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  constructor(private translate: TranslateService, private router: Router) {
    super();
    this.label = this.translate.instant('eo.shortcuts.action.open.context');
    this.description = this.translate.instant('eo.shortcuts.action.open.context.description');
  }

  isExecutable(item: DmsObject): Observable<boolean> {
    const isExecutable = (!this.isFolder(item) && this.isNotAlreadyOpened(item.id)) || (this.isFolder(item) && this.isNotInObjectState());
    return of(isExecutable);
  }

  getParams(selection: DmsObject[]): { type: string } {
    const item = selection[0];
    return {type: item.typeName};
  }

  getLink(selection: DmsObject[]): string {
    const {id, isContextFolder, contextFolder} = selection[0];
    if (isContextFolder || !contextFolder) {
      this.label = this.translate.instant('eo.shortcuts.aside.action.open');
    }
    return `/object/${id}`;
  }

  isFolder(item: DmsObject): boolean {
    return item.isFolder;
  }

  isNotInObjectState(): boolean {
    const disabledStates = ['/object'];
    return !disabledStates.some(s => this.router.url.includes(s));
  }

  isNotAlreadyOpened(id: string): boolean {
    const disabledStates = ['/object/' + id];
    return !disabledStates.some(s => this.router.url.includes(s));
  }
}
