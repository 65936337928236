<yvc-split-view [layoutSettingsID]="'prepare.state'">
  <ng-template yvcSplitArea [size]="30">
    <eo-list-container #eoList [applySelection]="{}">
      <div class="eo-header">
        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_prepare.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.prepare.list.title</div>
          <div class="eo-header-subtitle" translate>eo.prepare.list.title.sub</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button primary refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
            (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"
            [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter class="overlay-filter" [iconSrc]="'assets/_default/svg/ic_filter.svg'"
            [iconClass]="'primary'" [iconTitle]="'eo.list.filter' | translate">
            <eo-custom-filter #cFilter (change)="cFilter.updateGrid(eoList.eoGrid, $event)"
              (active)="oFilter.onActiveChanged($event)">
              <!--<eo-text-filter [title]="'eo.timeline.filter.action' | translate" [matchFields]="['selectedtype.label']"></eo-text-filter>-->
              <eo-set-filter [operator]="'OR'" [id]="'prepare.object.type'"
                [title]="'eo.favorites.filter.section.objecttype' | translate"
                [options]="typeFilterFields"></eo-set-filter>
            </eo-custom-filter>
          </eo-overlay>

          <eo-overlay #oSort class="overlay-sort" [iconSrc]="'assets/_default/svg/ic_sort.svg'" [iconClass]="'primary'"
            [title]="'eo.list.sort' | translate">
            <eo-custom-sort #cSort [id]="'prepare.sort'" (change)="cSort.updateGrid(eoList.eoGrid, $event)"
              (active)="oSort.onActiveChanged($event)" [sortFields]="sortFields"></eo-custom-sort>
          </eo-overlay>

          <eo-icon class="button actions-button" (click)="eoList.showActions(null, 'PREPARED_ITEM')"
            [iconTitle]="'eo.object.actions.title' | translate" [iconSrc]="'assets/_default/svg/ic_more.svg'"></eo-icon>
        </div>
      </div>
      <div class="eo-body dark">
        <eo-grid #eoGrid *ngIf="gridOptions?.rowData" [gridOptions]="gridOptions" [fullWidth]="true" [showHeader]="true"
          [showFooter]="true" (eoGridCountChanged)="eoList.onCountChanged($event);"
          (eoGridContextMenuClick)="eoList.onContextMenu($event, 'PREPARED_ITEM')"
          (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
          (eoGridFocusChanged)="eoList.onFocusChanged($event)">

          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
          </div>

          <div class="empty">
            <eo-error-message
              [emptyState]="{icon: 'ic_prepare.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.prepare.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active"
              (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"></eo-reset-filter>
          </div>
        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>
  <ng-template yvcSplitArea [size]="70">
    <eo-prepare-details [applySelection]="{}" [emptyState]="emptyState" (onItemChanged)="updateList($event)"
      (onItemRemove)="remove()">
      <div class="error" *ngIf="gridData?.length">
        <button class="button secondary" (click)="remove()" translate>eo.prepare.details.item.gone.remove</button>
      </div>
    </eo-prepare-details>
  </ng-template>
</yvc-split-view>