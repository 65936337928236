<div class="loader-overlay__mask" *ngIf="showLoader">
    <eo-loading-spinner class="recyclebin-detail__loader"></eo-loading-spinner>
</div>
<ng-container *ngIf="item && !hasError">

    <div class="eo-head eo-head-tabs">
        <header class="eo-header">
            <eo-icon class="eo-header-icon" [objectType]="item.type" [iconTitle]="item.type.label"></eo-icon>

            <div class="eo-header-info">
                <h2 class="eo-header-title">{{item.title}}</h2>
                <h3 class="eo-header-subtitle">{{item.description}}</h3>
            </div>

            <div class="actions">
                <eo-icon *ngIf="item.content"
                         class="button"
                    [iconSrc]="'assets/_default/svg/ic_content-download.svg'"
                    [iconTitle]="('eo.action.download.dms.object.content.label' | translate)"
                    (click)="downloadOriginalContent()"></eo-icon>
                <eo-icon class="button" [ngClass]="{disabled: !this.item.rights.delete}" [iconSrc]="'assets/_default/svg/ic_trash.svg'"
                    [iconTitle]="this.item.rights.delete ? ('eo.recyclebin.details.delete.tooltip' | translate) : ('eo.recyclebin.details.delete.disabled.tooltip' | translate)"
                    (click)="showDialog();"></eo-icon>
                <button (click)="restoreFile()" translate>eo.recyclebin.details.restore</button>
                <button (click)="restoreFile(true)" class="primary"
                    translate>eo.recyclebin.details.restoreopen</button>
            </div>
        </header>
    </div>
    <div class="eo-body">
        <yvc-split-tabs>

            <ng-template [yvcTab]="{id: 'summary', label: 'eo.object.summary.title' | translate}">
                <eo-indexdata-summary [indexdata]="indexDataPreview" [baseparams]="baseparams"
                    [dmsObject]="item"></eo-indexdata-summary>
            </ng-template>

            <ng-template [yvcTab]="{id: 'preview', label: 'eo.object.content.title' | translate}">
                <eo-media *ngIf="item" [useVersion]="item.id === item.content?.id" [dmsObject]="item" #viewer></eo-media>
            </ng-template>

            <ng-template *ngIf="isVisible('history')"
                [yvcTab]="{id: 'history', label: 'eo.object.history.title' | translate}">
                <eo-object-history [params]="{id: item.id, type: item.type?.qname}">
                </eo-object-history>
            </ng-template>

            <!-- plugin tabs -->
            <ng-template *ngFor="let p of plugins" [yvcTab]="{id: p.id, label: p.id | translate}">
                <eo-plugin [id]="p.id" [parent]="this"></eo-plugin>
            </ng-template>
        </yvc-split-tabs>
    </div>
</ng-container>

<eo-dialog [title]="'eo.recyclebin.details.delete.dialog.title' | translate" [(visible)]="showDeleteDialog"
    [focusOnShow]="false" [minWidth]="400" [styleClass]="'recyclebin-delete__dialog'">

    <div>
        <span>{{'eo.recyclebin.details.delete.dialog.message' | translate}}</span>
        <br>
        <span class="recyclebin-delete__dialog_warning">{{'eo.recyclebin.details.delete.dialog.message.warning' |
            translate}}</span>
    </div>
    <div class="action-buttons recyclebin-delete--action-buttons flex-row">
        <button type="button" class="button cancel" (click)="showDeleteDialog = false"
            translate>eo.recyclebin.details.delete.dialog.cancel</button>
        <button type="button" #confirmDelete (click)="deleteDmsObject()" class="button primary"
            translate>eo.recyclebin.details.delete.dialog.delete</button>
    </div>
</eo-dialog>
