import {debounceTime} from 'rxjs/operators';
import {Component, HostBinding, Input, AfterContentInit} from '@angular/core';
import {GridComponent} from '../../grid.component';

@Component({
  selector: 'eo-total-count',
  templateUrl: './total-count.component.html',
  styleUrls: ['./total-count.component.scss']
})
export class TotalCountComponent implements AfterContentInit {

  @Input() eoGrid: GridComponent;

  @HostBinding('class.outside-grid')
  @Input() outsideGrid = false;
  @Input() gridCount: any;

  ngAfterContentInit() {
    if (this.eoGrid) {
      this.eoGrid
        .eoGridCountChanged
        .asObservable()
        .pipe(
          debounceTime(100)
        )
        .subscribe(gridCount => this.gridCount = gridCount);
    }
  }

}
