<div class="row-edit">
  <div class="edit-header" *ngIf="!queryEnabled">
    <div class="new-indicator" *ngIf="isNewRow" translate>eo.form.table.options.indicator.new</div>
    <div></div>
    <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="cancel()" class="button cancel-icon"
      [iconTitle]="('eo.form.table.options.btn.cancel' | translate)"></eo-icon>
  </div>

  <div class="form" [formGroup]="editForm" *ngIf="editForm">
    <eo-form-element *ngFor="let c of editFormControls" [element]="c" [situation]="_row?.situation" [skipToggle]="false">
    </eo-form-element>
  </div>

  <div class="actions">
    <eo-checkbox *ngIf="isNewRow" title="{{'eo.form.table.options.checkbox.new.title' | translate}}"
      [formControl]="createNewCheckbox"></eo-checkbox>
    <span *ngIf="isNewRow" class="add-label" translate>eo.form.table.options.checkbox.new</span>
    <button type="button" *ngIf="deleteEnabled && !isNewRow && !_row?.form.disabled && !queryEnabled"
      (click)="showDeleteDialog = true;" title="{{'eo.form.table.options.btn.delete' | translate}}">
      <eo-icon [iconSrc]="'assets/_default/svg/ic_trash.svg'"></eo-icon>
    </button>
    <button type="button" (click)="saveCopy()" class="seco" *ngIf="copyEnabled && !isNewRow && !_row?.form.disabled"
      [disabled]="limitReached || saving || formState?.invalid" translate>eo.form.table.options.btn.copy</button>
    <button type="button" (click)="save()" class="primary" *ngIf="saveEnabled && !_row?.form.disabled"
      [disabled]="(limitReached && isNewRow) || saving || formState?.invalid || (!formState?.dirty && !isNewRow)">{{
      'eo.form.table.options.btn.save' + (isNewRow ? '.new' : '') | translate}}</button>
    <!-- query button for SEARCH situation -->
    <button type="button" (click)="save()" class="primary" *ngIf="queryEnabled"
      [disabled]="formState?.invalid || !formState?.indexdataChanged" translate>eo.form.table.options.btn.save</button>
  </div>

</div>

<eo-dialog [title]="('eo.form.table.options.confirm.delete' | translate)" [(visible)]="showDeleteDialog"
  [minWidth]="400">
  <div class="action-buttons flex-row" style="padding: 8px;">
    <button type="button" class="button cancel" (click)="showDeleteDialog = false"
      translate>eo.form.table.options.btn.cancel</button>
    <button type="button" #confirmDelete (click)="delete()" class="button primary"
      translate>eo.form.table.options.btn.ok</button>
  </div>
</eo-dialog>