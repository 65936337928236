
import {of as observableOf, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Component} from '@angular/core';
import {TranslateService} from '@eo-sdk/core';
import {DmsObjectTarget} from '../../action-target';
import {SelectionRange} from '../../selection-range.enum';
import {ComponentAction} from '../../interfaces/action.interface';
import {AddResubmissionComponent} from './add-resubmission/add-resubmission.component';
import {ActionService} from '../../action-service/action.service';
import {DmsObject, UserService} from '@eo-sdk/core';

@Component({
  selector: 'eo-add-resubmission-action',
  template: ``
})
export class AddResubmissionActionComponent extends DmsObjectTarget implements ComponentAction {
  component = AddResubmissionComponent;
  label: string;
  description: string;
  priority = 5;
  iconSrc = 'assets/_default/svg/ic_resubmission.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;

  constructor(private translate: TranslateService,
              private userService: UserService,
              private router: Router,
              private actionService: ActionService) {
    super();

    this.label = this.translate.instant('eo.action.create.resubmission.label');
    this.description = this.translate.instant('eo.action.create.resubmission.description');
  }

  isExecutable(element: DmsObject): Observable<boolean> {
    const hasPrivilege = this.userService.getCurrentUser().hasPrivilege('MANAGE_RESUBMISSIONS');
    const isAllowedType = this.actionService.isAllowedType([element], ['sysemail']);
    if (hasPrivilege && this.isAllowedState() && isAllowedType && !element.isFinalized) {
      return observableOf(!element.resubmissions.length);
    } else {
      return observableOf(false);
    }
  };

  isAllowedState(): boolean {
    const disabledStates = ['/versions', '/notifications'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }
}
