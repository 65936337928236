import {Component, Input} from '@angular/core';
import {GridComponent} from '../../grid.component';

@Component({
  selector: 'eo-quick-filter',
  template: `
    <div class="qSearch flex-row" *ngIf="eoGrid?.gridOptions?.rowData" [style.alignItems]="'center'">
      <label>
        <eo-icon class="small" [iconSrc]="'assets/_default/svg/ic_quickfilter.svg'" [style.margin.px]="4"></eo-icon>
      </label>
      <eo-form-input>
        <input (keyup)="eoGrid.onQuickFilterChanged($event)" class="qInput" [style.border]="'none'"
               placeholder="{{'eo.filter.input.placeholder' | translate}}"/>
      </eo-form-input>
    </div>
  `,
  styles: []
})
export class QuickFilterComponent {

  @Input() eoGrid: GridComponent;
  value = '';
}
