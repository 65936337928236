<ng-container *ngIf="dmsObject.data.yuvsigstatus_meta; else tplNoSignature">

  <section class="info">
    <div class="row">
      <div class="label">{{'eo.object.signature-tab.label.status'|translate}}</div>
      <div class="value">
        {{dmsObject.data.yuvsigstatus_meta.label}}</div>
    </div>
    <div class="row">
      <div class="label">{{'eo.object.signature-tab.label.type'|translate}}</div>
      <div class="value">
        {{dmsObject.data.yuvsigtype}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigerror">
      <div class="label">{{'eo.object.signature-tab.label.error'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigerror}}</div>
    </div>
    <div class="row">
      <div class="label">{{'eo.object.signature-tab.label.date'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigdate | localeDate: 'eoShortDate'}}</div>
    </div>
    <div class="row">
      <div class="label">{{'eo.object.signature-tab.label.requestdate'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigreqdate | localeDate:'eoShortDate'}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigrequester_meta?.title">
      <div class="label">{{'eo.object.signature-tab.label.requester'|translate}}</div>
      <div class="value">
        {{dmsObject.data.yuvsigrequester_meta.title}} </div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigemailsubject">
      <div class="label">{{'eo.object.signature-tab.label.subject'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigemailsubject}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigemailbody">
      <div class="label">{{'eo.object.signature-tab.label.body'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigemailbody}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigcanceledby">
      <div class="label">{{'eo.object.signature-tab.label.canceledby'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigcanceledby_meta.title}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigreason">
      <div class="label">{{'eo.object.signature-tab.label.reason'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigreason}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigplatform">
      <div class="label">{{'eo.object.signature-tab.label.signingPlatform'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigplatform}}</div>
    </div>
    <div class="row" *ngIf="dmsObject.data.yuvsigplatformid">
      <div class="label">{{'eo.object.signature-tab.label.signingPlatformId'|translate}}</div>
      <div class="value">{{dmsObject.data.yuvsigplatformid}}</div>
    </div>
    <div class="row">
      <div class="label">{{'eo.object.signature-tab.label.signers'|translate}}</div>
      <div class="value">
        <div class="signer-container" *ngFor="let s of dmsObject.data.yuvsigners">
          <span class="circle" [title]="'eo.object.signature-tab.order.tooltip' | translate">{{s.yuvsignerorder}}</span>
          <div class="signer">
            <div class="date" *ngIf="s.yuvsignerdate">{{s.yuvsignerdate | localeDate: 'eoShort'}}</div>
            <div class="name" *ngIf="s.yuvsignername">{{s.yuvsignername}}</div>
            <div class="email" *ngIf="s.yuvsigneremail">{{s.yuvsigneremail}}</div>
            <div class="phone" *ngIf="s.yuvsignerphone">{{s.yuvsignerphone}}</div>
            <div class="state {{s.yuvsignerstatus}}"><span *ngIf="s.yuvsignerstatus_meta?.label">{{s.yuvsignerstatus_meta.label}}</span></div>
            <div class="reason" *ngIf="s.yuvsignercomment">{{s.yuvsignercomment}}</div>
          </div>

        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-template #tplNoSignature>
  <div class="signature-not-found">
    <eo-error-message
      [emptyState]="{icon: 'ic_signature.svg', text:'eo.object.signature-tab.no-signatures'}"></eo-error-message>
  </div>
</ng-template>
