import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {Attribute, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import {ChangeMode, ChangeOutput, Signer} from '../signature.model';
import {TranslateService} from '@eo-sdk/core';

@Component({
  selector: 'eo-sequence-list',
  templateUrl: './sequence-list.component.html',
  styleUrls: ['./sequence-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SequenceListComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SequenceListComponent),
      multi: true
    }
  ],
  host: {
    tabindex: '0'
  }
})
export class SequenceListComponent implements ControlValueAccessor, Validator, OnInit {
  entryForm: FormGroup;
  entries: Signer[] = [];
  editIndex: number;
  addTargetIndex: number;
  lengthValidator = { max: 100, min: 3 };
  changeMode = ChangeMode;
  mode: ChangeMode;

  @Input() MAX_SIGNERS: number;
  @Input() phoneRequired: boolean;
  @Output() itemEdit = new EventEmitter<boolean>();

  @HostListener('keydown.control.+', ['$event'])
  controlPlusHandler(event: KeyboardEvent) {
    this.showEntryForm();
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('keydown.enter', ['$event'])
  controlEnterHandler(event: KeyboardEvent) {
    if (this.entryForm?.dirty && this.entryForm.valid) {
      this.addSigner();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('keydown.escape', ['$event'])
  escapeHandler(event: KeyboardEvent) {
    if (this.entryForm) {
      this.hideEntryForm();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  get maxSigners(): boolean {
    return this.entries.length === this.MAX_SIGNERS;
  }

  constructor(@Attribute('form-open') public formOpen: string,
              public elRef: ElementRef,
              public fb: FormBuilder,
              public translate: TranslateService) {}

  propagateChange = (_: any) => {};

  private propagate() {
    this.propagateChange(this.entries);
  }

  writeValue(value: Signer[]): void {
    this.entries = value || [];
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    return this.entryForm?.dirty ? { routinglist: { pending: true } } : null;
  }

  dragDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.entries, event.previousIndex, event.currentIndex);
    this.propagate();
  }

  showEntryForm(index?: number, addAtIndex?: boolean) {
    let entry;
    if (addAtIndex) {
      this.addTargetIndex = index;
    } else {
      this.editIndex = index;
      entry = index !== undefined ? this.entries[index] : undefined;
    }

    this.entryForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      phone: ['', [Validators.maxLength(100)]]
    });

    if (entry) {
      this.entryForm.patchValue({ name: entry.name, email: entry.email, phone: entry.phone });
    }
    this.itemEdit.emit(true);
  }

  private removeEntry(index: number) {
    const entry = this.entries[index];
    if (!entry) { return; }
    this.entries.splice(index, 1);
    this.mode = null;
    this.propagate();
  }

  changeEntry({ index, mode }: ChangeOutput) {
    this.mode = mode;
    if (mode === ChangeMode.EDIT) {
      this.showEntryForm(index);
      this.addTargetIndex = index;
    } else if (mode === ChangeMode.DELETE) {
      this.removeEntry(index);
    } else if (mode === ChangeMode.INSERT && !this.maxSigners) {
      this.showEntryForm();
      this.addTargetIndex = index;
    }
  }

  addSigner() {
    if (this.editIndex !== undefined) {
      this.entries.splice(this.editIndex, 1, this.entryForm.value);
    } else if (this.addTargetIndex !== undefined) {
      this.entries.splice(this.addTargetIndex, 0, this.entryForm.value);
    } else {
      this.entries.push(this.entryForm.value);
    }
    this.hideEntryForm();
    this.propagate();
  }

  hideEntryForm() {
    this.addTargetIndex = undefined;
    this.editIndex = undefined;
    this.entryForm = undefined;
    this.mode = undefined;
    this.itemEdit.emit(false);

    setTimeout(() => this.elRef.nativeElement.querySelector('#add-item ')?.focus());
  }

  cancel() {
    this.hideEntryForm();
  }

  ngOnInit(): void {
    this.showEntryForm();
  }
}
