<div class="object-form-table" [ngClass]="{medium: _params.size === 'medium', large: _params.size === 'large'}">
  <div class="object-form-table-header">
    <span>{{_params.element.label}}</span>
    <ng-container *ngIf="_params.situation !== 'SEARCH'">

      <eo-icon class="stf" [iconSrc]="'assets/_default/svg/ic_expand.svg'"
        [iconTitle]="('eo.form.table.options.btn.open'| translate)" (click)="openDialog()"></eo-icon>
      <eo-icon class="stf" [iconSrc]="'assets/_default/svg/ic_size_to_fit.svg'"
        [iconTitle]="('eo.form.table.options.btn.fit'| translate)" (click)="sizeToFit()"></eo-icon>
      <eo-icon class="stf" *ngIf="gridOptions.rowData?.length" [iconSrc]="'assets/_default/svg/ic_export.svg'"
        [iconTitle]="('eo.form.table.options.btn.csv'| translate)" (click)="exportCSV()"></eo-icon>
      <eo-icon *ngIf="!_params.element.readonly" class="stf" [iconSrc]="'assets/_default/svg/ic_import.svg'"
        [iconTitle]="('eo.form.table.options.btn.upload'| translate)" (click)="file.click()"></eo-icon>
      <input type="file" accept=".csv" style="display:none;" #file (change)="importCSV($event.target)"
        (click)="$event.target.value = null">
      <eo-icon class="stf add-row" *ngIf="!_params.element.readonly" [iconSrc]="'assets/_default/svg/ic_add.svg'"
        [iconTitle]="('eo.form.table.options.btn.add'| translate)" (click)="addRow()"></eo-icon>

    </ng-container>
  </div>
  <div class="grid-body size-{{ params.size }}">
    <ag-grid-angular #agGrid *ngIf="gridReady" class="ag-theme-balham" [modules]="modules" [gridOptions]="gridOptions"
      (rowDoubleClicked)="editRow($event)" (cellClicked)="onCellClicked($event)" (sortChanged)="onSortChanged()"
      (cellValueChanged)="onEditComplete($event)" (columnResized)="onColumnResized($event.column)">
    </ag-grid-angular>
  </div>
</div>


<eo-dialog [title]="params.element.label" [visible]="showDialog" [dirtyCheck]="rowEdit?.pendingTaskId"
  [minWidth]="_params.situation !== 'SEARCH' ? minWidth : 200" [width]="_params.situation !== 'SEARCH' ? '95vw' : 500"
  [height]="height" [styleClass]="'object-form-table-dialog'" (hide)="onClose()" [isFormTable]="true"
  [hasPreviewFile]="hasPreviewFile" [showPreview]="showPreview" (onTogglePreview)="togglePreview()">

  <div class="body" *ngIf="showDialog">

    <!-- <yvc-split-view [layoutSettingsID]="cacheLayoutKey"> -->
    <yvc-split-view>

      <!-- the table itself-->
      <ng-template yvcSplitArea [size]="40" [visible]="_params.situation !== 'SEARCH'">
        <div class="table-body">
          <div class="object-form-table">
            <div class="object-form-table-header">
              <span></span>
              <ng-container>
                <eo-icon class="stf" [iconSrc]="'assets/_default/svg/ic_size_to_fit.svg'"
                  [iconTitle]="('eo.form.table.options.btn.fit'| translate)" (click)="sizeToFit(true)"></eo-icon>
                <eo-icon class="stf add-row" *ngIf="!_params.element.readonly"
                  [iconSrc]="'assets/_default/svg/ic_add.svg'"
                  [iconTitle]="('eo.form.table.options.btn.add'| translate)" (click)="addRow()"></eo-icon>
              </ng-container>
            </div>
            <div class="grid-body">
              <ag-grid-angular #agGrid2 *ngIf="gridReady" class="ag-theme-balham" [modules]="modules"
                [gridOptions]="overlayGridOptions" (cellClicked)="onCellClickedRowEdit($event)"
                (mousedown)="onMouseDown($event)" (sortChanged)="onSortChanged()"
                (cellValueChanged)="onEditComplete($event)" (columnResized)="onColumnResized($event.column, true)">
              </ag-grid-angular>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- table edit component-->
      <ng-template yvcSplitArea [size]="20" [visible]="!!editingRow">
        <div class="edit-body">
          <eo-row-edit [row]="editingRow" [limitReached]="limit <= overlayGridOptions.rowData.length" #rowEdit
            (onSave)="updateRow($event)" (onSaveCopy)="copyRow($event)" (onDelete)="deleteRow($event)"
            (onCancel)="cancelRowEdit()"></eo-row-edit>
        </div>
      </ng-template>

      <!-- viewer-->
      <ng-template yvcSplitArea [size]="40" [visible]="showPreview && hasPreviewFile">
        <eo-media *ngIf="hasPreviewFile" [undockDisabled]="true"
          [useVersion]="params.object?.id === params.object?.content?.id" [dmsObject]="params.object"></eo-media>
      </ng-template>

    </yvc-split-view>
  </div>
</eo-dialog>

<eo-dialog [title]="('eo.form.table.csv.import.report.title' | translate)" [visible]="showImportReportDialog"
  (hide)="cancelImportReport()" [styleClass]="'import-report__dialog'">
  <div class="body">
    <h4 translate [translateParams]="{readRows: mappedRows.length}">eo.form.table.csv.import.report.readRows</h4>
    <div *ngIf="mappingErrorFields.length !== 0" class="flex-col">
      <h4 translate>eo.form.table.csv.import.report.errors</h4>
      <table>
        <thead>
          <tr>
            <th translate>eo.form.table.csv.import.report.errors.row</th>
            <th translate>eo.form.table.csv.import.report.errors.csvColumn</th>
            <th translate>eo.form.table.csv.import.report.errors.tableColumn</th>
            <th translate>eo.form.table.csv.import.report.errors.value</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let error of mappingErrorFields; let i = index">
            <tr>
              <td>{{error.row + 1}}</td>
              <td>{{error.uploadedName}}</td>
              <td>{{error.technicalName}}</td>
              <td>{{error.value}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="actions">
    <button (click)="cancelImportReport()" class="button-cancel" translate>eo.resubmission.cancel</button>
    <button (click)="addImportValuesToTable()" class="primary" translate>eo.form.table.csv.import.report.import</button>
  </div>
</eo-dialog>
