import {style, transition, trigger, animate, stagger, query, AnimationTriggerMetadata} from '@angular/animations'

// animating entry of list items
export const listAnimation: AnimationTriggerMetadata = trigger('listAnimation', [
  transition('* => *', [
    query(':enter', style({transform: 'translateX(-32px)', opacity: 0}), {optional: true}),
    query(':enter', stagger('50ms', [
      animate('100ms ease-in', style('*'))
    ]), {optional: true})
  ])
]);
