<ng-template #dialogContent>
  <div class="eo-dialog" [ngClass]="styleClass || ''" eoRtlAware="dir">
    <header class="eo-dialog-header" [ngClass]="styleClass ? styleClass + '--header ' : ''">
      <h5 class="headline">{{title}}</h5>
      <h6 *ngIf="subtitle" class="subtitle">{{subtitle}}</h6>
      <eo-icon [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="closeDialog()"
        class="button white eo-dialog-close"></eo-icon>
      <ng-container *ngIf="isFormTable && hasPreviewFile">
        <button *ngIf="showPreview" (click)="togglePreview()" class="button-preview-toggle">
          <div>
            <eo-icon [iconSrc]="'assets/_default/svg/ic_preview_close.svg'">
            </eo-icon>
            <span class="title-preview-toggle" translate>
              eo.form.table.dialog.preview.hide
            </span>
          </div>
        </button>
        <button *ngIf="!showPreview" (click)="togglePreview()" class="button-preview-toggle">
          <div>
            <eo-icon [iconSrc]="'assets/_default/svg/ic_preview_show.svg'"></eo-icon>
            <span class="title-preview-toggle" translate>
              eo.form.table.dialog.preview.show
            </span>
          </div>
        </button>
      </ng-container>
    </header>
    <div class="eo-dialog-content" [ngClass]="styleClass ? styleClass + '--content ' : ''" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
