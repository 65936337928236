import {AgentService} from './../../agent/agent.service';

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, NavigationExtras} from '@angular/router';
import {AuthService} from '@eo-sdk/core';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private agentService: AgentService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.agentService.setAgentStatus(route.queryParams['connectagent']).subscribe();
    return this.authService.authenticated$.pipe(
      map((authenticated: boolean) => {
        if (!authenticated) {
          const uriParamQuery: NavigationExtras = {queryParams: {returnUrl: state.url}};
          this.router.navigate(['/enter'], uriParamQuery);
        }
        return authenticated;
      })
    );
  }
}
