import {Component, Input, ViewChild} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeUrl'})
export class SafeUrlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
export interface Iframe {
    title: string;
    url: string;
}
@Component({
  selector: 'eo-iframe-widget',
  templateUrl: './iframe-widget.component.html',
  styleUrls: ['./iframe-widget.component.scss']
})
export class IframeWidgetComponent implements IWidgetComponent {

  @ViewChild('iframe', {static: true}) iframe;
  private _widgetConfig: Iframe | undefined;

  @Input() set widgetConfig(c: Iframe | undefined) {
    this._widgetConfig = c;
  }
  get widgetConfig() {
    return this._widgetConfig;
  }
}

