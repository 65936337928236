import {Injectable, Inject, InjectionToken, ComponentFactoryResolver} from '@angular/core';

export const ENTRY_COMPONENTS = new InjectionToken<any[]>('ENTRY_COMPONENTS');
export const ENTRY_LINKS = new InjectionToken<any[]>('ENTRY_LINKS');


export interface EoPlugin {
  id: string;
  matchType: RegExp;
}

export interface EoLinkPlugin extends EoPlugin {
  path: any;
  queryParams?: any;
}

@Injectable()
export class PluginService {

  public links: any[] = [];
  public components: any[] = [];

  constructor(@Inject(ENTRY_COMPONENTS) components: EoPlugin[],
              @Inject(ENTRY_LINKS) links: EoLinkPlugin[],
              private _componentFactoryResolver: ComponentFactoryResolver) {
    this.components = components || [];
    this.links = links || [];
  }

  loadComponent(plugin, viewContainerRef, data = {}) {
    let componentFactory = this._componentFactoryResolver.resolveComponentFactory(plugin);

    // viewContainerRef.clear();
    viewContainerRef.createComponent(componentFactory);
    // (<PluginComponent>componentRef.instance).data = data;
  }

  loadByType(type: string, viewContainerRef: any) {
    return this.components.filter(p => p.matchType && type.match(p.matchType))
      .forEach(m => this.loadComponent(m, viewContainerRef));
  }

  loadById(id: string, viewContainerRef: any) {
    return this.components.filter(p => p.id && id === p.id)
      .forEach(m => this.loadComponent(m, viewContainerRef));
  }

  loadTabs(type: string) {
    return this.components.filter(p => p.matchType && type.match(p.matchType));
  }

  loadLinks(type: string) {
    return this.links.filter(p => p.matchType && type.match(p.matchType))
  }

}
