
import { forkJoin as observableForkJoin, Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@eo-sdk/core';
import { EoLocation, LocationService } from '../../../eo-framework-core/location/location.service';
import {
  Clipboard, ClipboardAction, ClipboardService, DmsObject, PrepareService, DmsService,
  NotificationsService, EnaioEvent, EventService, Utils
} from '@eo-sdk/core';

@Component({
  selector: 'eo-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipboardComponent implements OnInit {

  overlayShow: boolean;
  clipboard: Clipboard;
  clipboardItems: DmsObject[];
  type: string;
  iconSrc: string;
  iconTitle: string;
  activeLocation: EoLocation;
  isPasteAllowed: boolean;
  isPasteAsContentlinkAllowed: boolean;

  constructor(private translate: TranslateService,
    private locationService: LocationService,
    private prepareService: PrepareService,
    private dmsService: DmsService,
              private cd: ChangeDetectorRef,
    private notificationService: NotificationsService,
    private eventService: EventService,
    private clipboardService: ClipboardService) {
  }

  getClipboardContent() {
    this.clipboardService
      .clipboard$
      .subscribe((clipboard) => {
        this.clipboard = clipboard;
        this.clipboardItems = clipboard.elements.slice(0, 4);
        this.type = clipboard.action === ClipboardAction.COPY ? 'copy' : 'cut';
        this.iconSrc = clipboard.action === ClipboardAction.COPY ?
          'assets/_default/svg/ic_content-copy.svg' :
          'assets/_default/svg/ic_content-cut.svg';
        this.iconTitle = clipboard.action === ClipboardAction.COPY ?
          this.translate.instant('eo.bar.button.copy.tooltip') :
          this.translate.instant('eo.bar.button.cut.tooltip');
        this.checkAllowedActions();
      });
  }

  getActiveLocation() {
    this.locationService.activeLocation$
      .subscribe(activeLocation => {
        this.activeLocation = activeLocation;
        this.checkAllowedActions();
      });
  }

  /**
   * Removes all items from the clipboard
   */
  removeAll() {
    this.hide();
    this.clipboardService.clear();
  }

  /**
   * Removes a single item from the clipboard
   * @param clipboardItem The item to be removed
   */
  removeItem(clipboardItem: DmsObject) {
    const filtered = this.clipboard.elements.filter(e => e.id !== clipboardItem.id);
    if (filtered.length === 0) {
      this.hide();
    }
    this.clipboardService.set(filtered, this.clipboard.action);
  }

  hide() {
    this.overlayShow = false;
  }

  paste() {
    if (this.clipboard.action === ClipboardAction.CUT) {
      this.move(this.activeLocation, this.clipboard.elements);
    } else {
      this.copy(this.activeLocation, this.clipboard.elements, false);
    }
  }

  pasteAsContentlink() {
    this.copy(this.activeLocation, this.clipboard.elements, true);
  }

  triggerUpdate(elements) {
    this.eventService.trigger(EnaioEvent.DMS_OBJECT_PASTED, elements);
  }

  move(activeLocation, elementsToMove: any[]) {
    this.prepareService.getPrepareChildTypes(activeLocation.id).subscribe((res) => {
      if (this.allTypesAllowed(res.types, elementsToMove)) {
        let obs = elementsToMove.map(el => {
          return this.dmsService.move(el.id, el.typeName, activeLocation.id, activeLocation.type).pipe(
            catchError(Utils.catch(() => {
            }, el.title, this.translate.instant('eo.action.clipboard.move.error'))));
        });
        observableForkJoin(obs).subscribe(() => {
          this.clipboardService.clear();
          this.triggerUpdate(elementsToMove);
        });
      } else {
        this.notificationService.error(this.translate.instant('eo.clipboard.paste.label'), this.translate.instant('eo.clipboard.move.not.allowed'));
      }
    });
    this.hide();
  }

  copy(activeLocation, elementsToMove: any[], createContentLink) {
    this.prepareService.getPrepareChildTypes(activeLocation.id).subscribe((res) => {
      if (this.allTypesAllowed(res.types, elementsToMove)) {
        if (elementsToMove.length) {
          let obs = elementsToMove.map(el => {
            return this.dmsService.copy(el.id, el.typeName, activeLocation.id, activeLocation.type, createContentLink && !this.isContentlink(el)).pipe(
              catchError(Utils.catch(() => {
              }, el.title, this.translate.instant('eo.action.clipboard.copy.error'))));
          });
          observableForkJoin(obs).subscribe(results => {
            results.forEach((r, i) => {// replace the old ID with the new one
              elementsToMove[i].id = r.id;
            });
            this.triggerUpdate(elementsToMove);
          });
        }
      } else {
        this.notificationService.error(this.translate.instant('eo.clipboard.paste.label'), this.translate.instant('eo.clipboard.copy.not.allowed'));
      }
    });
    this.hide();
  }

  allTypesAllowed(locationTypes, elementsToMove) {
    let allTypesAllowed = true;
    elementsToMove.forEach((el) => {
      let match = locationTypes.find(t => t.name === el.typeName);
      if (!match) {
        allTypesAllowed = false;
      }
    });
    return allTypesAllowed;
  }

  isContentlink(item) {
    return item.content && item.content.id !== item.id;
  }

  contentAvailableForAllItems(items: DmsObject[]): boolean {
    return !items.find(item => !item.content);
  }

  checkAllowedActions() {
    const { elements, action } = this.clipboard;
    const isCopy = (action === ClipboardAction.COPY);
    const isCut = (action === ClipboardAction.CUT);
    const cutAction = (isCut && this.activeLocation && elements.length && elements[0].contextFolder ? this.activeLocation.id !== elements[0].contextFolder.id : true);
    const baseCondition = this.clipboard && this.activeLocation && elements.length && !elements.some(element => element.isFolder);

    this.isPasteAllowed = baseCondition && cutAction;
    this.isPasteAsContentlinkAllowed = baseCondition && elements[0].contextFolder && isCopy && this.contentAvailableForAllItems(elements);
    // trigger change detction here because this method is called from all subscriptions
   this.cd.markForCheck();
  }

  ngOnInit() {
    this.getClipboardContent();
    this.getActiveLocation();
  }
}
