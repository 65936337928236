import {AfterViewInit, Component} from '@angular/core';
import {Config} from '@eo-sdk/core';

@Component({
  selector: 'eo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  constructor(private config: Config) {
  }

  ngAfterViewInit() {
    let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = this.config.getRaw('favicon');
  }
}
