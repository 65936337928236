<div class="body" eoTrapFocus>
    <div class="navigation">
        <div class="group-title" translate>eo.export.title</div>
        <div class="nav-item">
            <a tabindex="0" (keydown.enter)="run()" (click)="run()">{{'eo.export.action.original.label' |
                translate}}</a>
            <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'"
                (click)="showDescription('original', $event)"></eo-icon>
            <div class="description" *ngIf="showDescriptionOrginal" translate>eo.export.action.original.description
            </div>
        </div>
        <div class="nav-item">
            <a tabindex="0" (keydown.enter)="runPdf()" (click)="runPdf()">{{'eo.export.action.pdf.label' |
                translate}}</a>
            <eo-icon class="info-icon" [iconSrc]="'assets/_default/svg/ic_info.svg'"
                (click)="showDescription('pdf', $event)"></eo-icon>
            <div class="description" *ngIf="showDescriptionPdf" translate>eo.export.action.pdf.description </div>
        </div>
    </div>
    <div class="form-buttons">
        <button (click)="cancel()" translate>eo.manage.rights.cancel</button>
    </div>
</div>