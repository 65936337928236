<div class="eo-delete" eoTrapFocus>

  <h2 translate [translateParams]="{item: selection[0].title}">eo.action.delete.content.confirm.message</h2>

  <div *ngIf="!deleting">
    <div class="form-buttons">
      <button (click)="cancel()" translate>eo.checkout.cancel</button>
      <button class="primary" (click)="run()">OK</button>
    </div>
  </div>

  <div class="spinner-container" *ngIf="deleting">
    <eo-loading-spinner></eo-loading-spinner>
  </div>
</div>
