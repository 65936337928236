<yvc-split-view [layoutSettingsID]="'inbox.state'">
  <!-- list -->
  <ng-template yvcSplitArea [size]="30">
    <eo-list-container #eoList [applySelection]="{out: processSelectionId}">
      <div class="eo-header">
        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_bpm.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.state.process</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
                   (click)="refreshList()"
                   [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter [iconSrc]="'assets/_default/svg/ic_filter.svg'"
                      class="overlay-filter"
                      [iconTitle]="'eo.list.filter' | translate" [active]="hasChanged(defaultFilterFormValue, filterForm?.value)">
            <div class="sort-title" translate>eo.filter.by</div>
            <form [formGroup]="filterForm" *ngIf='filterForm'>
              <eo-form-input [label]="('eo.state.process.filter.labels.model' | translate)" *ngIf="processModelsListObject.entries.length">
                <eo-dynamic-list formControlName="modelFCN" [pickerTitle]="'Models'" [listObject]="processModelsListObject"></eo-dynamic-list>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.running' | translate)">
                <eo-checkbox formControlName="runningFCN" [tristate]="false"></eo-checkbox>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.paused' | translate)">
                <eo-checkbox formControlName="pausedFCN" [tristate]="false"></eo-checkbox>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.created' | translate)">
                <eo-checkbox formControlName="createdFCN" [tristate]="false"></eo-checkbox>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.canceled' | translate)">
                <eo-checkbox formControlName="canceledFCN" [tristate]="false"></eo-checkbox>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.failed' | translate)">
                <eo-checkbox formControlName="failedFCN" [tristate]="false"></eo-checkbox>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.completed' | translate)">
                <eo-checkbox formControlName="completedFCN" [tristate]="false"></eo-checkbox>
              </eo-form-input>
              <eo-form-input [label]="('eo.state.process.filter.labels.starttime' | translate)">
                <eo-datetime-range formControlName="startTimeFCN" [withTime]="false"></eo-datetime-range>
              </eo-form-input>
            </form>
            <button class="reset-filter" (click)="resetFilters()" *ngIf="hasChanged(defaultFilterFormValue, filterForm?.value)">Reset filter</button>
          </eo-overlay>

          <eo-overlay #oSort
                      class="overlay-sort"
                      [iconSrc]="'assets/_default/svg/ic_sort.svg'"
                      [iconTitle]="'eo.list.sort' | translate"
                      [active]="hasChanged(defaultSortParams, sortParams)">
                      <div class="sort">
                        <div class="sort-title" translate>eo.sort.by</div>
                        <div class="sort-option" [ngClass]="{asc: sortParams.startTime === 'asc', desc: sortParams.startTime === 'desc'}">
                          <eo-icon class="asc" [iconTitle]="'eo.sort.asc' | translate" [iconSrc]="'assets/_default/svg/ic_arrow_up.svg'" (click)="sort('startTime', 'asc')"></eo-icon>
                          <eo-icon class="desc" [iconTitle]="'eo.sort.desc' | translate" [iconSrc]="'assets/_default/svg/ic_arrow_down.svg'" (click)="sort('startTime', 'desc')"></eo-icon>
                          <span class="title" translate>eo.grid.sort.date</span>
                          <eo-icon class="clear" [iconTitle]="'eo.state.process.sort.clear' | translate" [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="sort('startTime', '')"></eo-icon>
                        </div>
                        <div class="sort-option" [ngClass]="{asc: sortParams.modelName === 'asc', desc: sortParams.modelName === 'desc'}">
                          <eo-icon class="asc" [iconTitle]="'eo.sort.asc' | translate" [iconSrc]="'assets/_default/svg/ic_arrow_up.svg'" (click)="sort('modelName', 'asc')"></eo-icon>
                          <eo-icon class="desc" [iconTitle]="'eo.sort.desc' | translate" [iconSrc]="'assets/_default/svg/ic_arrow_down.svg'" (click)="sort('modelName', 'desc')"></eo-icon>
                          <span class="title" translate>eo.process.sort.type</span>
                          <eo-icon class="clear" [iconTitle]="'eo.state.process.sort.clear' | translate" [iconSrc]="'assets/_default/svg/ic_clear.svg'" (click)="sort('modelName', '')"></eo-icon>
                        </div>
                      </div>
          </eo-overlay>

        </div>
      </div>
      <div class="eo-body">
        <eo-grid #eoGrid
                 *ngIf="gridOptions"
                 [gridOptions]="gridOptions"
                 [fullWidth]="true"
                 [showHeader]="true"
                 [showFooter]="true"
                 [selectFirst]="true"
                 (eoGridCountChanged)="eoList.onCountChanged($event)"
                 (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
                 (eoGridFocusChanged)="eoList.onFocusChanged($event)">
          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
            <eo-pagination [settings]="settings" (pageChanged)="onPageChanged($event)"></eo-pagination>
          </div>

          <div class="empty">
            <eo-error-message [emptyState]="{icon: 'ic_bpm.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.process.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active" (click)="resetFilters()"></eo-reset-filter>
          </div>
        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>

  <!-- process details -->
  <ng-template yvcSplitArea [size]="30">
    <eo-process-details [applySelection]="{in: processSelectionId}" [emptyState]="emptyState1" 
    [plugins]="'process-details-tab.process' | plugins">
    </eo-process-details>
  </ng-template>

  <!-- object details -->
  <ng-template yvcSplitArea [size]="40">
    <eo-object-details [applySelection]="{}"
                           [cacheLayout]="'process.state.object-details'"
                           [emptyState]="emptyState2" 
                           [plugins]="'object-details-tab.process' | plugins">
        </eo-object-details>
  </ng-template>
</yvc-split-view>
