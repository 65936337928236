import {of as observableOf, forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Renderer2, ViewChild, ElementRef, OnDestroy, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {
  ObjectType,
  EventService,
  EnaioEvent,
  UploadRegistryService,
  UploadTarget,
  UploadFileItem,
  PrepareService,
  Utils,
  TranslateService,
  EnvironmentEnaio, SystemService
} from '@eo-sdk/core';

@Component({
  selector: 'eo-upload-overlay',
  templateUrl: './upload-overlay.component.html',
  styleUrls: ['./upload-overlay.component.scss']
})
export class UploadOverlayComponent implements OnInit, OnDestroy {

  @ViewChild('overlay') overlayEl: ElementRef;

  queue: UploadFileItem[] = [];
  fileOver: boolean;
  isUploading: boolean;
  hasValidUploadTargets: boolean;
  invalidInput: boolean;
  queueSize: number;
  validUploadTargets: UploadTarget[] = [];
  selectedUploadTarget: UploadTarget;

  private uploadTargets: UploadTarget[] = [];
  private dragLeaveListener: Function;
  private dropListener: Function;
  private dragOverListener: Function;
  private escKeyListener: Function;
  private dragEnterListener: Function;
  private pasteListener: Function;
  private dragEventCount = 0;

  constructor(private router: Router,
              private renderer: Renderer2,
              private prepareService: PrepareService,
              private translate: TranslateService,
              private systemService: SystemService,
              private eventService: EventService,
              private uploadRegistry: UploadRegistryService) {
  }

  private init() {

    // global drag&drop upload will only be available on desktop (web or electron)
    if (EnvironmentEnaio.isMobileEnvironment()) {
      return false;
    }

    // subscribe for the list of registered upload targets
    this.uploadRegistry.uploadTargets$.subscribe((uploadTargets: UploadTarget[]) => {
      this.uploadTargets = uploadTargets;
    });

    this.pasteListener = this.renderer.listen('document', 'paste', (e: ClipboardEvent) => {
      this.onPaste(e);
    });

    this.dragEnterListener = this.renderer.listen('document', 'dragenter', (e: DragEvent) => {
      this.onDragEnter(e);
    });
  }

  private onPaste(e: ClipboardEvent) {
    if (this.isUploading) {
      return false;
    }
    this.hasValidUploadTargets = true;
    this.invalidInput = false;
    if (!this.fileOver && this.pasteContainsFiles(e)) {
      let transfer = e.clipboardData;
      if (!transfer) {
        return;
      }

      // check for directories
      for (let i = 0; i < transfer.items.length; i++) {
        if (transfer.items[i].kind === 'string') {
          this.invalidInput = true;
          return;
        }
        const fe = transfer.items[i].webkitGetAsEntry();
        if (fe && fe.isDirectory) {
            this.invalidInput = true;
            return;
        }
      }
      if (!this.invalidInput) {
        this._preventAndStop(e);
        this.setFileOver(true);
        this.onFilesDropped(Array.from(transfer.files));
      }
    }
  }

  private onDragEnter(e: DragEvent) {
    if (this.isUploading) {
      return false;
    }
    this.hasValidUploadTargets = true;
    if (!this.fileOver && this.dragContainsFiles(e)) {
      this.dragEventCount = 0;
      this.setFileOver(true);


      // use timeout here to force angular digest cycle before continuing, because we are accessing dom elements
      // that are not there (*ngIf) until fileOver is set to true.
      setTimeout(() => {
        // todo: replace by renderer solution when available (https://github.com/angular/angular/issues/15674)
        this.overlayEl.nativeElement.focus();
        // bind late (only, when needed) to dragleave event to avoid heavy processing on every drag event
        this.dragLeaveListener = this.renderer.listen(this.overlayEl.nativeElement, 'dragleave', (evt) => {
          this.dragEventCount--;
          if (this.dragEventCount === 0) {
            this.close();
          }
        });

        this.dropListener = this.renderer.listen(this.overlayEl.nativeElement, 'drop', (evt) => {
          let transfer = this._getTransfer(evt);
          if (!transfer) {
            return;
          }
          this._preventAndStop(evt);

          // check for directories
          for (let i = 0; i < transfer.items.length; i++) {
            const fe = transfer.items[i].webkitGetAsEntry();
            if (fe && fe.isDirectory) {
              this.invalidInput = true;
            }
          }
          if (!this.invalidInput) {
            this.onFilesDropped(transfer.files);
          }
        });

        this.dragOverListener = this.renderer.listen(this.overlayEl.nativeElement, 'dragover', (evt) => {
          let transfer = this._getTransfer(evt);
          if (!transfer) {
            return;
          }
          transfer.dropEffect = 'copy';
          this._preventAndStop(evt);
        });
      }, 0);
    } else {
      this.dragEventCount++;
    }
  }

  private _getTransfer(event: any): any {
    return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer; // jQuery fix;
  }

  private _preventAndStop(event: any): any {
    event.preventDefault();
    event.stopPropagation();
  }

  private onFilesDropped(fileItems: File[]) {
    // clear queue when new files are added
    // todo: remove in future releases to support collective file adding
    this.queue = [];
    for (let file of fileItems) {
      this.queue.push(new UploadFileItem(file));
    }

    // bind ESC key listener for closing the dialog as late as possible
    if (!this.escKeyListener) {
      this.escKeyListener = this.renderer.listen('document', 'keyup', (e) => {
        e.stopPropagation();
        if (e.keyCode === 27) {
          this.close();
        }
      });
    }
    // calculate total size of queue
    this.setQueueSize();
    // filter available targets
    this.validateUploadTargets();
  }

  /**
   * Filter list of registered upload targets, so only valid targets will be shown.
   */
  private validateUploadTargets() {
    this.hasValidUploadTargets = true;
    observableForkJoin(this.uploadTargets.map(t => this.validateUploadTarget(t)))
      .subscribe(
        (res: { target: UploadTarget, valid: boolean }[]) => {
          this.validUploadTargets = res.filter(r => r.valid).map(filtered => filtered.target).reverse().sort((a, b) => {
            return a.type === 'context' ? -1 : b.type === 'context' ? 1 : 0; // Context should be on top
          });
        }, err => {
          this.validUploadTargets = [];
        }, () => {
          this.hasValidUploadTargets = this.validUploadTargets.length > 0;
        }
      );
  }

  private validateUploadTarget(target: UploadTarget): Observable<{ target: UploadTarget, valid: boolean }> {

    if (!this.queue) {
      return observableOf({target: target, valid: false});
    }

    return Observable.create(o => {
      let valid = false;

      if (target.type === UploadTarget.ROOT) {

        // todo: are there other restrictions on root target?
        this.prepareService.getPrepareChildTypes(null, true)
          .subscribe(
            (res: { parent: string, types: ObjectType[] }) => {
              valid = this.queue.length > 0 && res.types.length > 0;
              o.next({target: target, valid: valid});
              o.complete();
            }, err => {
              o.next({target: target, valid: false});
              o.complete();
            }
          );
      } else if (target.type === UploadTarget.OBJECT) {

        // todo: check if the reference object supports the given file type
        valid = target.referenceObject &&
          target.referenceObject.type.maxFiles > 0 &&
          !target.referenceObject.lock &&
          !target.referenceObject.isFinalized &&
          (target.referenceObject.rights && target.referenceObject.rights.edit) &&
          this.queue.length === 1;
        o.next({target: target, valid: valid});
        o.complete();
      } else if (target.type === UploadTarget.CONTEXT || target.type === UploadTarget.CONTEXT_TREE) {

        // todo: add checks related to the reference object (e.g. allowed file types)
        this.prepareService.getPrepareChildTypes(target.referenceObject.id, true)
          .subscribe(
            (res: { parent: string, types: ObjectType[] }) => {
              valid = target.referenceObject && this.queue.length > 0 && res.types.length > 0;

              // for CONTEXT_TREE targets also check if the desired node
              // type is part of the prepare child types
              if (target.type === UploadTarget.CONTEXT_TREE && target.subFolder) {
                const subFolder: any = target.subFolder;
                valid = !!res.types.find(t => this.systemService.implementsType(t, subFolder.data.type));
              }

              o.next({target: target, valid: valid});
              o.complete();
            }, err => {
              o.next({target: target, valid: false});
              o.complete();
            }
          );
      } else {
        o.next({target: target, valid: false});
        o.complete();
      }
    });
  }

  /**
   * Removes a file from the list of files to be uploaded.
   * @param fileItem - the file item to be removed
   */
  public removeFileItem(fileItem: UploadFileItem) {

    this.queue = this.queue.filter((f) => f.id !== fileItem.id);
    if (!this.queue.length) {
      this.close();
    } else {
      // update available upload targets because their validity
      // may be based on the queue size
      this.validateUploadTargets();
      this.setQueueSize();
    }
  }

  /**
   * Proceed upload with a selected upload target.
   * @param uploadTarget - the target to upload files to
   */
  public proceedWithUpload(uploadTarget: UploadTarget) {

    if (uploadTarget.type !== UploadTarget.CUSTOM && this.queue.length > 1) {
      // For more than one file to be uploaded to a none custom target, we let the user decide if
      // batch upload should be used or not ...


      this.selectedUploadTarget = uploadTarget;
    } else if (uploadTarget.type === UploadTarget.OBJECT) {
      // ... otherwise upload is started immediately
      this.startUpload(uploadTarget, [null, this.getStateRoute()]);
    } else {
      this.startUpload(uploadTarget, [null, '/prepare']);
    }
  }

  /**
   * Proceed upload using the selected upload target.
   * @param useBatchUpload - flag indicating whether or not to use batch upload
   */
  public proceedWithSelectedTarget(useBatchUpload: boolean) {
    this.startUpload(this.selectedUploadTarget, [useBatchUpload, '/prepare']);
  }

  /**
   * Resets the selected upload target.
   */
  public resetSelectedUploadTarget() {
    this.selectedUploadTarget = null;
  }

  getStateRoute() {
    return this.router.url;
  }


  private successfulUpload(target, uploadTarget) {
    this.eventService.trigger(EnaioEvent.DMS_OBJECT_UPDATED, uploadTarget.referenceObject);
    uploadTarget.onUploadSuccess(target);
  }

  /**
   * Start the actual upload.
   * @param uploadTarget - target to upload the files to
   * @param useBatchUpload - flag indicating whether or not to use batch upload
   */
  private startUpload(uploadTarget: UploadTarget, [...args]) {

    const [useBatchUpload, target] = args;

    this.isUploading = true;
    this.unbindListeners();
    this.prepareService
      .uploadToTarget(uploadTarget, this.queue, useBatchUpload)
      .subscribe((res) => {
          this.isUploading = false;
          if (uploadTarget.subFolder && uploadTarget.subFolder.data.data) {
            if (Array.isArray(res)) {
              let obs = [];
              res.forEach(r => {
                obs.push(this.prepareService.updatePreparedItemIndexData(r.id, uploadTarget.subFolder.data.data));
              });
              observableForkJoin(obs).subscribe(() => this.successfulUpload(target, uploadTarget));
            } else {
              this.prepareService
                .updatePreparedItemIndexData(res.id, uploadTarget.subFolder.data.data)
                .subscribe(() => this.successfulUpload(target, uploadTarget));
            }
          } else {
            this.successfulUpload(target, uploadTarget);
          }
          this.close();
        }, Utils.throw(() => {
          this.isUploading = false;
          uploadTarget.onUploadFail();
          this.close();
        },
        this.translate.instant('eo.upload.global.error.title'),
        this.translate.instant('eo.upload.global.error.description'))
      );
    this.resetSelectedUploadTarget();
  }

  private setQueueSize() {
    let s = 0;
    for (let f of this.queue) {
      s += f.size;
    }
    this.queueSize = s;
  }

  /**
   * Close the upload overlay and release all bound resources.
   */
  close() {
    // unbind bound event listener, when they are not needed anymore
    this.unbindListeners();
    // clear upload queue
    this.queue = [];
    // hide the overlay
    this.setFileOver(false);
    this.invalidInput = false;
  }

  private setFileOver(value: boolean) {
    this.fileOver = value;
    this.uploadRegistry.setOverlayActive(value);
  }

  private unbindListeners() {
    if (this.dragLeaveListener) {
      this.dragLeaveListener();
      this.dragLeaveListener = null;
    }
    if (this.dragOverListener) {
      this.dragOverListener();
      this.dragOverListener = null;
    }
    if (this.dropListener) {
      this.dropListener();
      this.dropListener = null;
    }
    if (this.escKeyListener) {
      this.escKeyListener();
      this.escKeyListener = null;
    }
  }

  /**
   * Indicates whether or not the current drag event contains one or more files.
   * @param event - the drag event to be checked
   * @returns number of files
   */
   private dragContainsFiles(event: DragEvent): number {
    return Array.from(event.dataTransfer.items || []).filter(i => i.kind === 'file').length;
  }

  /**
   * Indicates whether or not the current paste event contains one or more files.
   * @param event - the paste event to be checked
   * @returns number of files
   */
  private pasteContainsFiles(event: ClipboardEvent): number {
    return Array.from(event.clipboardData.items || []).filter(i => i.kind === 'file').length;
  }

  // ngFor tracking function for upload targets
  targetTrackByFn(index, item) {
    return item.id;
  }

  // ngFor tracking function for file items
  trackByFn(index, item) {
    return index;
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    if (this.dragEnterListener) {
      this.dragEnterListener();
    }
  }
}
