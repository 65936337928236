
import {of, from, Observable} from 'rxjs';
import {Component, Input} from '@angular/core';
import {DmsObjectTarget} from '../../action-target';
import {SelectionRange} from '../../selection-range.enum';
import {SimpleCustomAction} from '../../interfaces/action.interface';
import {TranslateService} from '@eo-sdk/core';
import {PluginsService} from '../../../../eo-framework-core/api/plugins.service';
import {NotificationsService} from '@eo-sdk/core';
import * as lodash from 'lodash';
import moment from 'moment';


@Component({
  selector: 'eo-custom-actions',
  template: ''
})
export class CustomActionsComponent extends DmsObjectTarget implements SimpleCustomAction {

  label: string;
  description: string;

  priority = 9998;
  iconSrc = 'assets/_default/svg/ic_play.svg';
  group = 'further';
  range = SelectionRange.SINGLE_SELECT;
  private _action: any;

  @Input()
  set action(act) {
    this._action = act;
    this.label = act.title;
    this.description = act.description;
    this.range = 1;
  }

  get action() {
    return this._action;
  }

  constructor(private toast: NotificationsService,
              private pluginService: PluginsService,
              private translate: TranslateService) {
    super();
  }

  isExecutable(objects) {
    return from(new Promise((resolve, reject) => {
      if (this._action.isexecutable && this._action.isexecutable.length > 0) {
        const scope = {
          action: this._action,
          api: this.pluginService.getApi(),
          objects
        };
        const executableFkt = new Function('scope, callback', this._action.isexecutable + '\n;//# sourceURL=' + this._action.id + '.isexecutable.js');
        try {
          executableFkt(scope, (isExecutable) => resolve(isExecutable));
        } catch (error) {
          this.toast.error(`${this.translate.instant('eo.action.menu.custom.error.message') }: ${error}`);
          return resolve(false);
        }
      } else {
        return resolve(true);
      }
    }).then((val) => !!val));
  }

  run(selection) {
    const action = this._action;
    this.runCustomActions(action, selection)
      .subscribe(isExecutable => {
        const text = isExecutable ? 'eo.action.menu.custom.run.success' : 'eo.action.menu.custom.run.error';
        this.translate.get(text, {title: action.title}).subscribe(val => this.toast.info(val));
      });
  }

  runCustomActions(action, objects = []): Observable<boolean> {
    const scope = {
      api: this.pluginService.getApi(),
      action,
      objects
    };
    try {
      const run = new Function('scope', '_', 'moment', action.script + '\n;//# sourceURL=' + action.id + '.script.js');
      run(scope, lodash, moment);
      return of(true);
    } catch (error) {
      this.toast.error(this.translate.instant('eo.action.menu.custom.error.message'));
      return of(false);
    }
  }
}
