import {AfterViewInit, Component, ContentChildren, ElementRef, Input, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {TranslateService} from '@eo-sdk/core';
import {ObjectFormControlWrapper} from '../object-form-control-wrapper';
import {FormElementTemplate} from './form-element-template.directive';

@Component({
  selector: 'eo-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class FormElementComponent implements AfterViewInit {
  @ViewChildren(FormElementTemplate, {emitDistinctChangesOnly: true}) elementTemplates: QueryList<FormElementTemplate>;
  @ContentChildren(FormElementTemplate, {emitDistinctChangesOnly: true}) contentElementTemplates: QueryList<FormElementTemplate>;


  
  elementTemplate: TemplateRef<any>;
  formElementRef: any;
  element: ObjectFormControlWrapper;
  focused;
  isNull: boolean;
  tag: {
    label: string,
    title: string
  };

  @Input() situation;
  @Input() skipToggle: boolean;

  // element is supposed to be a special UntypedFormGroup holding a single form element
  @Input('element')
  set elementSetter(el: ObjectFormControlWrapper) {
    if (el) {

      this.element = el;
      this.formElementRef = el.controls[el._eoFormControlWrapper.controlName];
      if (this.formElementRef._eoFormElement.isNotSetValue) {
        this.labelToggled(true);
      }
      if (this.formElementRef._eoFormElement.type === 'REFERENCE') {
        this.addDataToRender(this.formElementRef._eoFormElement);
      }
      if (this.formElementRef._eoFormElement.type === 'ID') {
        this.formElementRef._eoFormElement.minlen = 32;
        this.formElementRef._eoFormElement.maxlen = 32;
      }
      this.fetchTags();

      if (this.formElementRef._eoFormElement.readonly && this.formElementRef._eoFormElement.required) {
        this.formElementRef._eoFormElement.required = false;
      }
    }
  }

  @ViewChild('formField') formField: ElementRef;

  constructor(private translate: TranslateService, private renderer: Renderer2, private el: ElementRef) {
  }

  private addDataToRender(element) {
    const multiselect = element.multiselect;
    const dataMeta = element.dataMeta;
    if (multiselect && dataMeta) {
      element.dataToRender = dataMeta.map((dM, i) => {
        dM.id = element.value[i];
        return dM;
      });
    }
    if (!multiselect && dataMeta) {
      dataMeta.id = element.value;
      element.dataToRender = [dataMeta];
    }
  }

  labelToggled(toggled: boolean) {
    if (!this.skipToggle && this.element._eoFormControlWrapper.situation === 'SEARCH') {
      const toggleClass = 'label-toggled';
      this.isNull = toggled;
      if (toggled) {
        this.renderer.addClass(this.el.nativeElement, toggleClass);
      } else {
        this.renderer.removeClass(this.el.nativeElement, toggleClass);
      }
      this.formElementRef._eoFormElement.isNotSetValue = toggled;
      this.element.updateValueAndValidity();
    }
  }

  organizationDataMetaResolved(data) {
    // new meta data will always arrive as array even for single inputs, so we have to convert
    this.formElementRef._eoFormElement.dataMeta = this.formElementRef._eoFormElement.multiselect ? data : data[0];
  }

  fetchTags() {
    this.tag = null;
    if (this.situation === 'CREATE' &&
      (this.formElementRef._eoFormElement.hasOwnProperty('defaultvaluefunction') || this.formElementRef._eoFormElement.hasOwnProperty('defaultvalue'))) {
      this.tag = this.formElementRef._eoFormElement.defaultvaluefunction === 'EXTRACTION' ? {
        label: 'ex',
        title: this.translate.instant('eo.form.element.tag.ex')
      } : {
        label: 'df',
        title: this.translate.instant('eo.form.element.tag.df')
      };
    }
  }

  ngAfterViewInit(): void {
    const tpls = [...this.elementTemplates.toArray(), ...this.contentElementTemplates.toArray()];
    const dir = tpls.find(tpl => tpl.propertyType === this.formElementRef._eoFormElement.type);
    setTimeout(() => {
      this.elementTemplate = dir ? dir.template : undefined
    })
  }
}
