<div class="eo-object-form" *ngIf="form && formOptions" [formGroup]="form" eoRtlAware>
  <div class="form-element-wrap core">
    <eo-form-group [group]="form.get('core')" [situation]="formOptions.formModel.situation"></eo-form-group>
  </div>
  <div class="form-element-wrap data">
    <eo-form-group [group]="form.get('data')" [situation]="formOptions.formModel.situation"></eo-form-group>
  </div>
</div>




