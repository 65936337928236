import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {CellPosition, ColDef, Column, GridApi, GridOptions, Module, RowNode} from '@ag-grid-community/core';
import {Component, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {RowGroupingModule} from './row-grouping/row-grouping.module';

import {Router} from '@angular/router';
import {EoUser, TranslateService, UserService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {GridService} from '../../eo-framework-core/api/grid.service';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {CodesystemFilterComponent} from './filters/codesystem-filter.component';
import {DatetimeFilterComponent} from './filters/datetime-filter.component';
import {DynamicListFilterComponent} from './filters/dynamic-list-filter.component';
import {ListFilterComponent} from './filters/list-filter.component';
import {OrganizationFilterComponent} from './filters/organization-filter.component';
import {ILoader} from './loader.interface';
@UntilDestroy()
@Component({
  selector: 'eo-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridComponent {

  public modules: Module[] = [ClientSideRowModelModule, RowGroupingModule];

  overlayLoadingTemplate: string;
  frameworkComponents = {
    agDateInput: DatetimeFilterComponent,
    OrganizationFilterComponent: OrganizationFilterComponent,
    CodesystemFilterComponent: CodesystemFilterComponent,
    DynamicListFilterComponent: DynamicListFilterComponent,
    ListFilterComponent: ListFilterComponent
  };

  private _gridOptions: GridOptions;
  private _options: any = {};
  private _gridCount: any = {};
  private pinnedColumn: ColDef = {
    headerName: '',
    filter: false,
    sortable: false,
    resizable: false,
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true,
    suppressPaste: true,
    suppressSizeToFit: true,
    pinned: 'left',
    width: 1,
    field: '__selectionField',
    valueGetter: () => ''
  };

  @ViewChild('agGrid') agGrid;

  @Output() eoGridCountChanged = new EventEmitter<any>();
  @Output() eoGridSelectionChanged = new EventEmitter<any>();
  @Output() eoGridFocusChanged = new EventEmitter<any>();
  @Output() eoGridCellClick = new EventEmitter<any>();
  @Output() eoGridDoubleClick = new EventEmitter<any>();
  @Output() eoGridContextMenuClick = new EventEmitter<any>();
  @Output() eoGridColumnResized = new EventEmitter<any>();

  private colResizeDebouncer = new Subject();
  private calculateDebouncer = new Subject();
  private focusedRowID = '';

  @Input() selectFirst = true;
  @Input() selectionLimit: number;
  @Input() sizeToFit = false;
  @Input() fullWidth = false;
  @Input() showHeader = false;
  @Input() showFooter = false;
  @Input() loaderContent: ILoader = {show: false, image: null, text: null};
  @Input()
  set options(options: any) {
    this._options = options;
  }

  get options() {
    return this._options;
  }

  @Input()
  set gridOptions(gridOptions: GridOptions) {
    Object.assign(this._gridOptions, gridOptions, {
      context: Object.assign(this._gridOptions.context, gridOptions.context),
      columnDefs: gridOptions.columnDefs ? [this.pinnedColumn].concat(gridOptions.columnDefs) : null
    });
  }

  get gridOptions() {
    return this._gridOptions;
  }

  get contextCount() {
    return typeof this.gridOptions.context.count === 'number' ? this.gridOptions.context.count : this.gridOptions.context.count.value;
  }

  get contextRelation() {
    return typeof this.gridOptions.context.count === 'number' ? 'eq' : this.gridOptions.context.count.relation;
  }

  @HostListener('keydown.control.alt.shift.c', ['$event'])
  @HostListener('keydown.control.shift.c', ['$event'])
  @HostListener('keydown.control.alt.c', ['$event'])
  @HostListener('keydown.control.c', ['$event'])
  copyCellHandler(event: KeyboardEvent) {
    this.gridApi.copyToClipboard(event, this.gridOptions);
  }

  constructor(private translate: TranslateService,
    private userService: UserService,
    public gridApi: GridService,
    private router: Router,
    private pendingChanges: PendingChangesService
  ) {

    this._gridOptions = <GridOptions>{
      context: gridApi.getContext(),
      animateRows: true,
      headerHeight: 30,
      rowHeight: 48,
      rowBuffer: 20,
      rowSelection: 'multiple',
      rowDeselection: true,
      multiSortKey: 'ctrl',
      accentedSort: true,
      suppressContextMenu: true,
      overlayLoadingTemplate: ' ',
      overlayNoRowsTemplate: ' ',
      getRowNodeId: (data) => data.id || data,
      localeTextFunc: (key, defaultValue) => {
        let gridKey = 'eo.resultlist.grid.' + key;
        let value = translate.instant(gridKey);
        return ~value.indexOf(gridKey) ? defaultValue : value;
      }
    };

    this.loadingMessage();
    this.getUser();

    this.pendingChanges.tasks$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(tasks => (this.gridOptions.suppressCellSelection = !!tasks.length));

    this.colResizeDebouncer.pipe(
      debounceTime(1000))
      .subscribe(col => this.eoGridColumnResized.emit(col));

    this.calculateDebouncer.pipe(
      debounceTime(100))
      .subscribe(() => this.calculateRowCount());
  }

  loadingMessage() {
    let loaderContent = '';

    if (this.loaderContent.show) {
      if (this.loaderContent.image) {
        loaderContent = `<span class="ag-overlay-loading-center"><img [src]="${this.loaderContent.image}" alt="loader image"></span>`;
      } else {
        loaderContent = `<span class="ag-overlay-loading-center">${this.translate.instant(this.loaderContent.text)}</span>`;
      }
    }
    this.overlayLoadingTemplate = loaderContent;
  }

  private getUser() {
    this.userService.user$.subscribe((user: EoUser) => {
      (this.gridOptions || this._gridOptions).enableRtl = (user.uiDirection === 'rtl');
      this.pinnedColumn.pinned = (user.uiDirection === 'rtl') ? 'right' : 'left';
    });
  }

  private calculateRowCount() {
    let rowCount = 0;
    let totalCount = this.contextCount;
    let rowIndex = this.gridOptions.context.rowIndex;
    let selection = this.gridOptions.context.selection;

    if (this.api && this.gridOptions.rowData) {
      this.api.forEachNodeAfterFilter(n => n.group || rowCount++);
    } else {
      rowCount = this.gridOptions.context.rowCount || 0;
    }
    if (this.selectFirst && !this.api.getFocusedCell() && (rowIndex !== null || !this.focusedRowID)) {
      setTimeout(() => this.selectRow(rowIndex || 0, selection), 200);
    } else {
      this.updateFocus();
    }
    this._gridCount = {totalCount, rowCount, relation: this.contextRelation};
    this.eoGridCountChanged.emit(this._gridCount);
  }

  updateFocus(id = this.focusedRowID) {
    const cell = this.api.getFocusedCell();
    const row: RowNode = this.api.getRowNode(id);
    if (!this.options.filterActive && (cell && cell.rowIndex) !== (row && row.rowIndex)) {
      if (row) {
        this.api.setFocusedCell(row.rowIndex, (this.gridOptions.columnDefs[1] as ColDef).field);
      } else {
        this.api.clearFocusedCell();
      }
    }
  }

  onCellClicked($event) {

    if ($event.rowIndex !== null) {
      if (!$event.node.group && $event.data) {
        if ($event.colDef.cellClass === 'router-link-cell') {
          this.gridApi.openRouterLink($event.event, 'ag-row');
        }

        this.eoGridCellClick.emit($event);
      }
    }
  }

  onCellDoubleClicked($event) {

    if ($event.rowIndex !== null) {
      if (!$event.node.group && $event.data) {
        this.eoGridDoubleClick.emit($event);
      }
    }
  }

  onMouseDown($event: any) {
    if (this.gridOptions.suppressCellSelection) {
      if (!this.pendingChanges.check()) {
        this.gridOptions.suppressCellSelection = false;
        let rowIndex = this.gridApi.getRowIndex($event.target, 'ag-body');
        if (rowIndex !== null) {
          //select row only on left click
          this.selectRow(rowIndex, $event.button === 0 ? null : [], !$event.ctrlKey);
          if ($event.button === 2) {
            //open context menu on right click
            let node = this.api.getModel().getRow(rowIndex);
            this.onContextMenuClicked({rowIndex, node, data: node.data});
          }
        }
      } else {
        $event.preventDefault();
        $event.stopImmediatePropagation();
      }
    }
  }

  onCellFocused($event) {
    if (this.gridOptions.suppressCellSelection) {
      return this.updateFocus();
    }
    if ($event.rowIndex !== null) {
      $event.focusedNode = this.api.getModel().getRow($event.rowIndex);
      if ($event.focusedNode && !$event.focusedNode.group && $event.focusedNode.data) {
        this.focusedRowID = $event.focusedNode.id;
        this.eoGridFocusChanged.emit($event.focusedNode.data);
      }
    }
  }

  onContextMenuClicked($event) {
    if ($event.rowIndex !== null && !$event.node.group && $event.data) {
      let selectedNodes = this.api.getSelectedNodes().map(n => n.data).filter(d => d);
      if (selectedNodes.length && selectedNodes.some(n => n.id === $event.data.id)) {
        this.eoGridContextMenuClick.emit(selectedNodes);
      } else {
        this.eoGridContextMenuClick.emit([$event.data]);
      }
    }
  }

  onSelectionChanged($event) {
    let selectedNodes = this.api.getSelectedNodes().map(n => n.data).filter(d => d);
    if (this.selectionLimit && selectedNodes.length > this.selectionLimit) {
      this.selectRow(this.api.getFocusedCell().rowIndex);
    } else {
      this.eoGridSelectionChanged.emit(selectedNodes);
    }
  }

  onColumnResized($event) {
    if ($event.column) {
      this.colResizeDebouncer.next($event.column);
    }
  }

  onModelUpdated() {
    this.calculateDebouncer.next({});
  }

  onReady() {
    if (this.sizeToFit) {
      this.api.sizeColumnsToFit();
    }
  }

  onQuickFilterChanged($event) {
    this.api.setQuickFilter($event.target.value);
  }

  selectRow(row: any, selection?: number[], clearSelection = true, position = null) {
    if (!this.api) {
      return;
    }

    const rowNode = typeof row === 'number' ? this.api.getModel().getRow(row) : this.api.getRowNode(this.gridOptions.getRowNodeId(row));
    let index = rowNode ? rowNode.rowIndex : 0;

    if (rowNode && rowNode.data) {
      if (selection) {
        selection.forEach((rowIndex, i) => {
          this.api.getModel().getRow(rowIndex).setSelected(true, i === 0);
        });
        this.gridOptions.context.selection = null; //reset initial selection
      } else {
        rowNode.setSelected(true, clearSelection);
      }

      this.api.setFocusedCell(index, (this.gridOptions.columnDefs[1] as ColDef).field);
      this.scrollToRow(index, position);
      this.gridOptions.context.rowIndex = null; //reset initial rowIndex
    } else if (index > 0 && this.contextCount) {
      this.selectRow(--index, selection, clearSelection);
    } else {
      this.api.clearFocusedCell();
      this.eoGridFocusChanged.emit(null);
    }
  }

  scrollToRow(index?: number, position = null) {
    this.api.ensureIndexVisible(index === undefined ? (this.api.getFocusedCell() ? this.api.getFocusedCell().rowIndex : 0) : index, position);
  }

  selectRowById(id, position = '') {
    const rowNodeId = this.gridOptions.getRowNodeId(id);
    const rowNode = this.api.getRowNode(rowNodeId);
    if (rowNode) {
      rowNode.setSelected(true);
      this.selectRow(rowNode.rowIndex, [], true, position);
    }
  }

  setColumnDefs(columnDefs: ColDef[]) {
    this.api.setColumnDefs(columnDefs ? [this.pinnedColumn].concat(columnDefs) : null);
  }

  setRowData(data: any[] = [], row?: any) {
    this.gridOptions.context.count = data.length;
    this.gridOptions.context.rowIndex = row;
    this.api.clearFocusedCell();
    this.api.setRowData(data);
  }

  private processSoftData(data: any[] = []) {
    let remove = data.filter(i => i.__removed).map(i => {
      delete i.__removed;
      return i;
    });

    let update = data.filter(i => i.__updated).map(i => {
      delete i.__updated;
      return i;
    });

    // finally remove items from array
    remove.forEach(i => data.splice(data.indexOf(i), 1));

    let applyUpdate = remove.length || update.length;

    return {applyUpdate, remove, update};
  }

  updateRowData(data: any[] = [], row?: any, selection?: number[]) {

    let {applyUpdate, remove, update} = this.processSoftData(data);
    if (!applyUpdate) {
      return this.setRowData(data, row);
    }

    this.gridOptions.context.count = data.length;
    let lastFocusedCell: CellPosition = this.api.getFocusedCell();
    this.api.updateRowData({remove, update});
    this.api.redrawRows();
    setTimeout(_ => {
      this.selectRow(lastFocusedCell ? lastFocusedCell.rowIndex : (row || 0), selection);
    }, this.contextCount ? 500 : 0);
  }

  updateRows(data: any[] = []) {

    let {applyUpdate, remove, update} = this.processSoftData(data);
    if (!applyUpdate) {
      return false;
    }

    this.gridOptions.context.count = this.contextCount - remove.length;
    let lastFocusedIndex = this.api.getFocusedCell() ? this.api.getFocusedCell().rowIndex : 0;

    this.api.updateRowData({remove, update});

    setTimeout(_ => {
      this.selectRow(lastFocusedIndex);
    }, this.contextCount ? 500 : 0);
  }

  get columns(): Column[] {
    return this.gridOptions.columnApi.getAllGridColumns().filter(c => c.getColId() !== this.pinnedColumn.field);
  }

  get api(): GridApi {
    return this.gridOptions.api;
  }

  get isReady() {
    return !!this.api;
  }

  get isEmpty() {
    return !this._gridCount.totalCount && (!this.api || !Object.keys(this.api.getFilterModel()).length);
  }

  get isEmptyRows() {
    return !this._gridCount.rowCount;
  }

  public refreshView() {
    this.api.refreshView();
  }
}
