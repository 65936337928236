import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {Component, OnInit, EventEmitter, Output, Input, OnChanges} from '@angular/core';

@Component({
  selector: 'eo-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  pagingForm: UntypedFormGroup;
  pagination: {page: number, pages: number, relation: string};

  @Input() set settings(settings: {total: number, size: number, page?: number, relation?: string}) {
    this.pagination = settings && {
      pages: Math.ceil(settings.total / settings.size),
      page: settings.page || 1,
      relation: settings.relation || 'eq'
    };
    if (this.pagination) {
      this.pagingForm.get('page').setValue(this.pagination.page);
      this.pagingForm.get('page').setValidators(
        [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1), Validators.max(this.isGreater ? 100000 : this.pagination.pages)]);
    }
  }

  @Output() pageChanged = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) {
    this.pagingForm = this.fb.group({
      page: [1]
    });
  }

  onPagingFormSubmit() {
    if (this.pagingForm.valid) {
      this.goToPage(this.pagingForm.value.page);
    }
  }

  goToPage(page: number) {
    this.pagination.page = page;
    this.pagingForm.get('page').setValue(this.pagination.page);
    this.pageChanged.emit(page);
  }

  get isGreater() {
    return this.pagination.relation === 'gte';
  }

  ngOnInit() {
  }

}
