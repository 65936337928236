import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SearchResult, SearchService, SystemService, DmsObject} from '@eo-sdk/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ReferenceItem,
  ReferenceSearch
} from '../interface/reference-finder.interface';

@Injectable({
  providedIn: 'root'
})
export class ReferenceFinderService {
  private referencesLoader = new BehaviorSubject<boolean>(false);
  public referencesLoader$: Observable<boolean> = this.referencesLoader.asObservable();

  constructor(
    private searchService: SearchService,
    private systemService: SystemService,
    private router: Router
  ) {}

  private searchReferences(searchText: string, types: string[], contextId?: string, queryFilters?: any): Observable<SearchResult | string[]> {
    if (searchText) {
      let q: any = {
        fields: ['title', 'description', 'type'],
        types: types,
        term: searchText,
        filters: queryFilters,
        options: {withcontext: true, highlighting: false}
      };

      if (contextId) {
        if(q.filters){
          q.filters.contextfolderid = {v1: contextId, o: 'eq'};
        } else {
          q.filters = {contextfolderid: {v1: contextId, o: 'eq'}};
        }
      }

      return this.searchService
        .executeQuery(q)
        .pipe(
          map(item => item ? this.searchService.createResultFromResponse(item) : [])
        );
    }
    return of([]);
  }

  private createResultList(list: ReferenceSearch[], currentSelection, types): ReferenceItem[] {
    if (list.length) {
      return list.map(el =>
        this.generateResultElement(el, currentSelection, types)
      );
    }
  }

  private getReferenceUrl(id: string): string {
    if (id) {
      return this.router.createUrlTree(['object', id]).toString().replace(/\(modal:\w+\)$/g, '');
    }
  }

  selectOnMultisSelect(list: ReferenceItem[], item: ReferenceItem): ReferenceItem[] {
    const index = list.findIndex(el => el.id === item.id);
    list[index].selected = !list[index].selected;
    return list;
  }

  selectOnSingleSelect(list: ReferenceItem[], item: ReferenceItem): ReferenceItem[] {
    list.forEach(entry => entry.id === item.id && !entry.selected ? (entry.selected = true) : (entry.selected = false));
    return list;
  }

  generateSeletecItems(search: ReferenceItem[], clipboard: ReferenceItem[], selection: ReferenceItem[]): ReferenceItem[] {
    const selCB = clipboard.filter(el => el.selected && !selection.find(cS => cS.id === el.id));
    const searchRes = search.filter(el => el.selected && !selection.find(cS => cS.id === el.id));
    return [...selCB, ...searchRes];
  }

  findReferences(searchTerm: string, types = [], contextId?: string, queryFilters?: any) {
    return this.searchReferences(searchTerm, types, contextId, queryFilters);
  }

  generateResults(list, hits, currentSelection, types) {
    const results = [...list, ...this.createResultList(hits, currentSelection, types)];
    return results.filter((item, index) => (index === results.findIndex(obj => obj.id === item.id && !item.clipboard)));
  }

  generateReferenceItemsFromDmsObjects(dmsObjects: DmsObject[], currentSelection: ReferenceItem[], disabled = false): ReferenceItem[] {
    return dmsObjects
      .map(obj => ({
        ...obj,
        objectType: obj.type,
        clipboard: true,
        link: this.getReferenceUrl(obj.id),
        disabled: !disabled ? !!currentSelection.find(cS => cS.id === obj.id) : disabled,
        selected: !!currentSelection.find(cS => cS.id === obj.id)
      }));
  }

  generateResultElement(element: ReferenceSearch, currentSelection: ReferenceItem[], types: string): ReferenceItem {
    const objectType = this.systemService.getObjectType(element.type);
    const disabled = !!currentSelection.find(cS => cS.id === element.id);

    const {description, title, id} = element;

    return {
      description,
      title,
      id,
      contextFolder: element.context,
      objectType,
      selected: disabled,
      typeName: types,
      disabled,
      clipboard: false,
      link: this.getReferenceUrl(id)
    };
  }
}
