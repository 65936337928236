import {switchMap, tap, debounceTime} from 'rxjs/operators';
import {Component, AfterViewInit, ElementRef, HostListener, HostBinding, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import {Router, NavigationExtras} from '@angular/router';
import {UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {QuickSearchResult} from './quick-search-result.interface';
import {SearchQuery, SearchState, SearchService, SystemService, ObjectType, NotificationsService, Utils} from '@eo-sdk/core';
import {AppSearchService} from '../../../eo-framework-core/search/app-search.service';

/**
 * QuickSearchComponent displays a search box that the user can type his query to.
 * It returns a list of matching object types grouped by their object type group and the
 * number of hits for each type. Furthermore it'll provide you with the total number of
 * hits matching the users query.
 *
 * Setting the components `showResults` flag to true, will make the component render the
 * results on its own. If you do want to process results by yourself use the `onResult` callback.
 */

@Component({
  selector: 'eo-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements AfterViewInit, OnDestroy {

  private querySubscription: Subscription;
  queryState = new SearchState();
  autocompleteResults: string[];
  query = new SearchQuery();
  lastTerm = '';
  resultGroups;
  resultTypes = [];
  ctrlClicked: boolean;
  selected = {
    index: -1,
    name: null
  };

  searchForm: UntypedFormGroup;
  invalidTerm: boolean;
  loading: boolean;

  @HostBinding('class.results') resultsAvailable;

  // flag whether or not to render results by the component itself
  // by default this flas is false and the result will only be emitted by
  // the components onResult callback
  @Input() showResults: boolean;
  // triggered each time a result is fetched
  @Output() onResult: EventEmitter<QuickSearchResult> = new EventEmitter();
  // triggered when the search term has been changed
  @Output() onInputChange: EventEmitter<string> = new EventEmitter();

  // private objectTypes: ObjectType[];

  constructor(private searchService: SearchService,
    private router: Router,
    private elementRef: ElementRef,
    private toaster: NotificationsService,
    private appSearchService: AppSearchService,
    private systemService: SystemService,
    private fb: UntypedFormBuilder) {


    this.searchForm = this.fb.group({searchInput: ['']});

    this.searchForm
      .get('searchInput').valueChanges
      .pipe(
        tap((term) => {
          this.onInputChange.emit(term);
          this.query.term = term;
          this.resultGroups = [];
          this.resultTypes = [];
          this.resultsAvailable = false;
          this.queryState = new SearchState();
        }),
        debounceTime(1500),
        switchMap(term => {
          return this.aggregate();
        })
      )
      .subscribe((queryState: SearchState) => {

        if (this.query.term) {
          this.queryState = queryState;
          this.resultsAvailable = !queryState.isEmpty;

          // map aggregations to real types
          let aggTypes = [];
          queryState.aggregations.type.forEach((v, k) => {

            let type: ObjectType = this.systemService.getObjectType(k);
            if (type) {
              aggTypes.push({
                label: type.label || k,
                count: v,
                group: type.group || '0',
                data: type
              });
            }
          });
          // group by object type group
          this.resultGroups = _.groupBy(aggTypes.sort(Utils.sortValues('label')), function (t) {
            return t.group;
          });
          this.resultTypes = [];
          Object.keys(this.resultGroups).forEach((k) => {
            this.resultTypes = this.resultTypes.concat(this.resultGroups[k]);
          });
          this.onResult.emit({
            total: this.queryState.totalCount,
            groups: this.resultGroups
          });
        } else {
          this.onResult.emit({
            total: -1,
            groups: []
          });
        }
      });

    this.querySubscription = appSearchService.query$.subscribe((query: SearchQuery) => {
      if (query && query.term) {
        this.lastTerm = query.term;
      }
    });
  }

  setTerm(term?: string) {
    this.searchForm.get('searchInput').patchValue(term || '');
    this.focusInput();
  }

  private aggregate(): Observable<SearchState> {
    this.loading = true;
    return Observable.create(o => {
      this.invalidTerm = false;
      this.searchService
        .getSearchState({aggs: {type: {}}, ...this.query.getQueryJson()})
        .subscribe(res => {
          this.loading = false;
          o.next(res);
          o.complete();
        }, Utils.throw(_ => {
          this.invalidTerm = true;
          this.loading = false;
          o.next(new SearchState());
          o.complete();
        }));
    });
  }

  @HostListener('keyup', ['$event'])
  onKey(event) {
    if (this.showResults) {
      if (event.which === 38) {
        // up
        this.selected.index--;
        if (this.selected.index < 0) {
          this.selected.index = this.resultTypes.length - 1;
        }
        this.selected.name = this.resultTypes[this.selected.index].data.name;
      } else if (event.which === 40) {
        // down
        this.selected.index++;
        if (this.selected.index >= this.resultTypes.length) {
          this.selected.index = 0;
        }
        this.selected.name = this.resultTypes[this.selected.index].data.name;
      } else {
        // reset selection on any other key
        this.selected = {
          index: -1,
          name: null
        };
      }
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (event.which === 17) {
      this.ctrlClicked = true;
    }
  }

  public executeSearch(objectType?: ObjectType) {

    if (!this.invalidTerm) {
      if (objectType) {
        this.query.types = [objectType];
      } else if (this.selected.index !== -1) {
        this.query.types = [this.resultTypes[this.selected.index].data];
      } else {
        this.query.types = [];
      }

      const uriParam = encodeURIComponent(JSON.stringify(this.query.getQueryJson()));
      const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};

      if (this.ctrlClicked) {
        const queryParams = new URLSearchParams(uriParamQuery.queryParams).toString()
        const url = window.location.href.split('/dashboard')[0] + '/result?'+ queryParams;
        window.open(url, '_blank');
        this.ctrlClicked = false;
        this.focusInput();
      } else {
        this.router.navigate(['/result'], uriParamQuery);
      }

    }
  }

  private focusInput() {
    // focus search input
    setTimeout(() => {
      this.elementRef.nativeElement.querySelector('input').focus();
    }, 200);
  }

  ngAfterViewInit() {
    this.focusInput();
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
