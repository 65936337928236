
import {of as observableOf} from 'rxjs';
import {DmsObjectTarget} from '../../action-target';
import {ComponentAction} from '../../interfaces/action.interface';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {DmsObject} from '@eo-sdk/core';
import {ClipboardObjectActionComponent} from './clipboard-object-action/clipboard-object-action.component';

@Component({
  selector: 'eo-clipboard-action',
  template: ``
})
export class ClipboardActionComponent extends DmsObjectTarget implements ComponentAction {
  header: string;
  label: string;
  description: string;
  priority = 11;
  iconSrc = 'assets/_default/svg/ic_clipboard.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;
  component = ClipboardObjectActionComponent;

  constructor(private translate: TranslateService) {
    super();
    this.label = this.translate.instant('eo.action.menu.clipboard.label');
    this.description = this.translate.instant('eo.action.menu.clipboard.description');
    this.header = this.translate.instant('eo.clipboard.title');
  }

  isExecutable(element: DmsObject) {
    return observableOf(!!element);
  }
}
