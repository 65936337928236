
import {of as observableOf, Observable} from 'rxjs';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {ComponentAction} from '../../interfaces/action.interface';
import {ShareObjectComponent} from './share-object/share-object.component';
import {DmsObject, SystemService, ObjectType} from '@eo-sdk/core';

@Component({
  selector: 'eo-share-object-action',
  template: ``
})
export class ShareObjectActionComponent extends DmsObjectTarget implements ComponentAction {
  component = ShareObjectComponent;
  label: string;
  description: string;
  priority = 8;
  iconSrc = 'assets/_default/svg/ic_share.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;

  constructor(private translate: TranslateService, private systemService: SystemService) {
    super();
    this.label = this.translate.instant('eo.action.manage.rights.read.label');
    this.description = this.translate.instant('eo.action.manage.rights.read.description');
  }

  isExecutable(element: DmsObject) {
    const type: ObjectType = this.systemService.getObjectType(element.typeName);
    return observableOf((element.rights && element.rights.edit) && type.shareable);
  };

}
