import {of as observableOf} from 'rxjs';
import {ComponentAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component, Injectable} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {DmsObject, DmsService} from '@eo-sdk/core';
import {FinalizeObjectActionComponent} from './finalize-object-action/finalize-object-action.component';

@Component({
  selector: 'eo-finalize-action',
  template: ``
})
@Injectable({
  providedIn: 'root'
})
export class FinalizeActionComponent extends DmsObjectTarget implements ComponentAction {
  label: string;
  description: string;
  priority = 13;
  iconSrc = 'assets/_default/svg/ic_finalized.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;
  component = FinalizeObjectActionComponent;

  constructor(private translate: TranslateService, private dmsService: DmsService) {
    super();
    this.label = this.translate.instant('eo.action.finalize.label');
    this.description = this.translate.instant('eo.action.finalize.description');
  }

  isExecutable(item: DmsObject) {
    return observableOf((item.rights && item.rights.finalize) && !item.lock && !item.isFinalized && !window.location.href.includes('/versions'));
  }
}
