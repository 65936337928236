import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {Logger, Utils} from '@eo-sdk/core';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {FormStatusChangedEvent} from '../form-status-changed-event.interface';
import {FormGenResult, ObjectFormHelperService} from '../object-form-helper.service';
import {ObjectFormOptions} from '../object-form-options.interface';
import {ObjectFormScriptService} from './object-form-script/object-form-script.service';
import {ObjectFormScriptingScope} from './object-form-script/object-form-scripting-scope';


@Component({
  selector: 'eo-object-form',
  templateUrl: './object-form.component.html',
  styleUrls: ['./object-form.component.scss'],
  // define 'child only' services for the form without setting it up on the module
  // which would cause them to be propagated to the whole application
  providers: [ObjectFormScriptService],
})
export class ObjectFormComponent implements OnDestroy, AfterViewInit {

  @Input() isInnerTableForm: boolean;
  // triggered when the forms state has been changed
  @Output() statusChanged = new EventEmitter<FormStatusChangedEvent>();
  // handler to be executed after the form has been set up
  @Output() onFormReady = new EventEmitter();

  public formOptions: ObjectFormOptions;
  public defaultFormOptions: ObjectFormOptions;
  // the actual form instance
  public form;
  // property for holding the forms data used for comparison when a form-changed-event
  // is fetched to indicate wher or not the indexdata were changed or just the properties
  // of the form elements (eg. form script setting fields to readonly)
  private formData: any;
  private scriptingScope: ObjectFormScriptingScope;
  private subscriptions: Subscription[] = [];

  @Input('formOptions')
  set options(formOptions: ObjectFormOptions) {
    this.defaultFormOptions = formOptions;
    this.formOptions = _.cloneDeep(formOptions);
    this.init();
  }

  constructor(private logger: Logger,
    private elementRef: ElementRef,
    private formScriptService: ObjectFormScriptService,
    private formHelperService: ObjectFormHelperService,
    private cdRef: ChangeDetectorRef) {
  }

  // initialize the form based on the provided form options
  private init() {
    this.form = null;
    setTimeout(() => {
      this.unsubscribeAll();
      this.buildReactiveForm(this.formOptions || {});
    }, 0);
  }

  private initOptions() {
    this.options = this.defaultFormOptions;
  }

  public focusForm() {
    this.elementRef.nativeElement.querySelector('input').focus();
  }

  public setFormData(data) {
    this.formOptions.data = data;
    setTimeout(() => {
      this.init();
    }, 0);
  }

  /**
   * Extracts the values from the form model. Each form value is represented by one
   * property on the result object holding the fields value. The keys (properties) are the `name`
   * properties of the form element (in SEARCH situation the `qname` field is used).
   *
   * How values are extracted is influenced by the forms situation.
   *
   * @return object of key value pairs
   */
  public getFormData() {
    return this.formToData();
  }

  public setFormPristine() {
    this.form.markAsPristine();
  }

  // reset the form to its initial state
  public resetForm() {
    this.initOptions();
    this.emitFormChangedEvent();
  }

  /**
   * Returns the observed model that was passed to the current form script running. If there is
   * no form script, this method will return NULL.
   * @returns
   */
  public getObservedScriptModel() {
    return this.scriptingScope ? this.scriptingScope.getModel() : null;
  }

  // Create a reactive form from the enaio form model
  private buildReactiveForm(formOptions) {
    const formGenRes: FormGenResult = this.formHelperService.buildReactiveForm(
      formOptions, this.formScriptService, this.isInnerTableForm
    );
    this.form = formGenRes.form;
    this.scriptingScope = formGenRes.scriptingScope;
    this.subscriptions = formGenRes.controlValueSubscriptions;

    setTimeout(() => {
      if (!this.form) return;
      let formWatch = this.form.valueChanges.pipe(debounceTime(500))
        .subscribe(() => !formWatch.closed && this.emitFormChangedEvent());
      this.subscriptions.push(formWatch);
      this.onFormReady.emit();
      this.emitFormChangedEvent(false);
    }, 300);
  }

  private emitFormChangedEvent(compare = true) {
    // check if indexdata has been changed
    let currentFormData = this.getFormData();
    let idxChange = compare ? JSON.stringify(this.formData) !== JSON.stringify(currentFormData) : false;

    this.formData = Utils.formDataParse(Utils.formDataStringify(currentFormData));
    if (this.form) {
      this.statusChanged.emit({
        invalid: this.form.invalid,
        dirty: this.form.dirty,
        data: this.formData,
        indexdataChanged: idxChange
      });
    }
  }

  /**
   * Extract the values from the form
   */
  private formToData() {

    return this.formHelperService.extractFormData(
      this.form,
      this.formOptions.formModel.situation,
      this.formOptions.data,
      this.isInnerTableForm
    );
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  // unsubscribe from all value change listeners for the current form
  // to avoid memory leaks. This method will also be called every time
  // a new form is rendered to get rid of the old form element subscriptions
  private unsubscribeAll() {
    if (this.subscriptions.length) {
      this.logger.debug('unsubscribed from ' + this.subscriptions.length + ' value change listeners.');
      this.subscriptions.forEach(s => s.unsubscribe());
      this.subscriptions = [];
    }
  }
}
