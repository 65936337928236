import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotFoundComponent} from '../eo-framework/error/not-found/not-found.component';
import {SettingsComponent} from './settings/settings.component';
import {LoginComponent} from './login/login.component';
import {ResultStateComponent} from './result-state/result-state.component';
import {FavoriteStateComponent} from './favorite-state/favorite-state.component';
import {AboutStateComponent} from './about-state/about-state.component';
import {StoredQueriesStateComponent} from './stored-queries-state/stored-queries-state.component';
import {ProcessStateComponent} from './process-state/process-state.component';
import {NotificationsStateComponent} from './notifications-state/notifications-state.component';
import {VersionStateComponent} from './version-state/version-state.component';
import {PendingChangesGuard} from '../eo-framework-core/pending-changes/pending-changes-guard.service';
import {AuthGuard} from '../eo-framework-core/route-guards/auth/auth-guard.service';
import {CapabilitiesGuard} from '../eo-framework-core/route-guards/capabilities/capabilities-guard.service';
import {UploadOverlayGuard} from '../eo-framework/upload-overlay/upload-overlay-guard.service';
import {RecyclebinStateComponent} from './recyclebin-state/recyclebin-state.component';

// Configure applications main routes (e.g. 404).
// Child routes will be added by the feature modules link inbox etc.
const routes: Routes = [
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, UploadOverlayGuard]},
  {path: 'enter', component: LoginComponent},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, UploadOverlayGuard]},
  {path: 'result', component: ResultStateComponent, canActivate: [AuthGuard, UploadOverlayGuard], canDeactivate: [PendingChangesGuard]},
  {path: 'favorites', component: FavoriteStateComponent, canActivate: [AuthGuard, UploadOverlayGuard, CapabilitiesGuard], canDeactivate: [PendingChangesGuard]},
  {path: 'stored-queries', component: StoredQueriesStateComponent, canActivate: [AuthGuard, UploadOverlayGuard, CapabilitiesGuard], canDeactivate: [PendingChangesGuard]},
  {path: 'about', component: AboutStateComponent},
  {path: 'process', component: ProcessStateComponent, canActivate: [AuthGuard, UploadOverlayGuard, CapabilitiesGuard], canDeactivate: [PendingChangesGuard]},
  {path: 'notifications', component: NotificationsStateComponent, canActivate: [AuthGuard, UploadOverlayGuard, CapabilitiesGuard], canDeactivate: [PendingChangesGuard]},
  {path: 'recyclebin', component: RecyclebinStateComponent, canActivate: [AuthGuard, UploadOverlayGuard, CapabilitiesGuard], canDeactivate: [PendingChangesGuard]},
  {path: 'versions/:id', component: VersionStateComponent, canActivate: [AuthGuard, UploadOverlayGuard]},
  // default route
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  // 404 route
  {path: 'not-found', component: NotFoundComponent},
  // redirecting route
  {path: '**', component: NotFoundComponent}
  // modules that contain own routing like inbox|object will
  // automatically applied by importing their module in eo-client.module.ts
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class EoClientRoutingModule {
}
