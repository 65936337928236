import {Component, Input, OnInit} from '@angular/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {InboxState, Capabilities, CapabilitiesService, InboxService} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {InfoInboxConfig} from './info-inbox.model';

@UntilDestroy()
@Component({
  selector: 'eo-info-inbox-widget',
  templateUrl: './info-inbox-widget.component.html',
  styleUrls: ['./info-inbox-widget.component.scss']
})
export class InfoInboxWidgetComponent implements IWidgetComponent, OnInit {

  capabilities: Capabilities;
  inboxState: InboxState;
  private _widgetConfig: InfoInboxConfig;
  @Input() set widgetConfig(c: InfoInboxConfig | undefined) {
    this._widgetConfig = c ;
  }
  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private capabilityService: CapabilitiesService,
    private inboxService: InboxService,
  ) {
  }

  ngOnInit(): void {
    this.capabilities = this.capabilityService.getCapabilities();
    if (this.capabilities.inbox) {
      this.inboxService.inboxState$
        .pipe(untilDestroyed(this))
        .subscribe((inboxState: InboxState) => this.inboxState = inboxState);
    }
  }
}
