import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const fadeInOut: AnimationTriggerMetadata = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0
    })
  ),
  transition('void <=> *', animate(200))
]);
