import {Directive, Input, HostListener} from '@angular/core';

@Directive({
  selector: '[eoYearRange]'
})
export class YearRangeDirective {

  private _selectedYear: string;
  @Input() rangeMin = 0;
  @Input() rangeMax = 9999;

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const year = +input.value;

    if (year && year < this.rangeMin) {
      input.value = '' + this.rangeMin;
    } else if (year && year > this.rangeMax) {
      input.value = input.value.slice(0, -1);
    } else {
      this.selectedYear = `${year}`;
      input.value = this.selectedYear;
    }
  }

  set selectedYear(year: string) {
    this._selectedYear = year;
  }

  get selectedYear() {
    return this._selectedYear;
  }

  constructor() {
  }
}
