<div [hidden]="!open">
  <!-- attachments overlay -->
  <div class="attachments" *ngIf="attachments?.length"
    [ngClass]="{notmain: latestUri !== previewUri && !attachmentsShown, active: attachmentsShown}"
    (eoOutsideClick)="attachmentsShown = false">
    <div class="attachments-list" *ngIf="attachmentsShown">
      <div class="attachment main" (click)="selectAttachment(null)" [ngClass]="{current: latestUri === previewUri}">{{dmsObjectTitle}}</div>
      <div class="attachment" *ngFor="let a of attachments" (click)="selectAttachment(a)" [ngClass]="{current: latestUri === a.previewUri}">
        {{a.name}}
      </div>
    </div>

    <eo-icon class="button" [badge]="attachments?.length" (click)="attachmentsShown = !attachmentsShown"
      [iconSrc]="attachmentsShown ? 'assets/_default/svg/ic_clear.svg' : 'assets/_default/svg/ic_attachment.svg'"></eo-icon>
  </div>

  <yvc-popout
  [popoutWindowConfig]="popoutWindowConfig"
  [triggerPosition]="popoutTriggerPosition" [disabled]="undockDisabled">

    <div class="preview-container" [style.overflow]="'hidden'">
      <iframe [hidden]="isUndocked || loading" #iframe height="100%" width="100%" frameborder="0"></iframe>
      <div class="loader-overlay__mask" *ngIf="loading">
        <eo-loading-spinner class="iframe__loader"></eo-loading-spinner>
      </div>
      <div class="slide" *ngIf="isUndocked">
        <eo-icon *ngIf="mediaType" [objectType]="mediaType"></eo-icon>
      </div>
    </div>
  </yvc-popout>
</div>

<div [hidden]="open" class="empty-container">
  <eo-icon class="nofile" [iconSrc]="'assets/_default/svg/ic_no-file.svg'"></eo-icon>
</div>
