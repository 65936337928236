
import {of as observableOf, Observable} from 'rxjs';
import {SimpleAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {DmsObject, CapabilitiesService, UserService, EventService, EnaioEvent, Utils} from '@eo-sdk/core';

@Component({
  selector: 'eo-favorite-action',
  template: ``
})
export class FavoriteActionComponent extends DmsObjectTarget implements SimpleAction {
  label: string;
  description: string;
  priority = 12;
  iconSrc = 'assets/_default/svg/ic_favorite.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;

  constructor(private translate: TranslateService,
              private userService: UserService,
              private eventService: EventService,
              private capabilityService: CapabilitiesService,
              private router: Router) {
    super();
  }

  isExecutable(item: DmsObject) {
    if (this.isAllowedState() && this.capabilityService.getCapabilities().favorites
      && !item.type.supertypes.find(sT => sT === 'sysjournalobject')) {
      // differ between adding or removing favorite state
      if (item.isFavorite) {
        this.label = this.translate.instant('eo.action.delete.favorite.label');
        this.description = this.translate.instant('eo.action.delete.favorite.description');
      } else {
        this.label = this.translate.instant('eo.action.create.favorite.label');
        this.description = this.translate.instant('eo.action.create.favorite.description');
      }
      return observableOf(true);
    } else {
      return observableOf(false);
    }
  };

  run(selection: DmsObject[]) {
    selection.forEach(item => {
      const {id, typeName, title} = item;
      if (!item.isFavorite) {
        this.userService
          .createFavorite(id, typeName, title || id)
          .subscribe(() => {
            this.eventService.trigger(EnaioEvent.DMS_OBJECT_FAVORITE_ON, id)
          }, Utils.throw(null,
            this.translate.instant('eo.state.settings.presence.error.title'),
            this.translate.instant('eo.state.settings.presence.error.description')
          ));
        // todo: add proper error messages here
      } else {
        this.userService
          .removeFavoritesForTarget(id)
          .subscribe(() => {
            this.eventService.trigger(EnaioEvent.DMS_OBJECT_FAVORITE_OFF, id)
          }, Utils.throw(null,
            this.translate.instant('eo.state.settings.presence.error.title'),
            this.translate.instant('eo.state.settings.presence.error.description')
          ));
        // todo: add proper error messages here
      }
    });
  }

  isAllowedState() {
    const disabledStates = ['/versions'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }
}
