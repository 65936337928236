import {UntypedFormGroup} from '@angular/forms';

export interface EoFormGroupWrapper {
  // form control situation (CREATE, EDIT, SEARCH)
  situation: string;
  // the name of the wrapped form control
  controlName: string;
}

/**
 * Extend angulars default UntypedFormGroup for implementation of a form group wrapping
 * a form control.
 */
export class ObjectFormControlWrapper extends UntypedFormGroup {
  _eoFormControlWrapper: EoFormGroupWrapper;
}
