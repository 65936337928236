import {trigger, transition, state, style, animate, AnimationTriggerMetadata} from '@angular/animations';
import {Observable, Subscription} from 'rxjs';

export const STATE = {
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error'
};

export class PanelLoading {
  state = '';

  private _set(_state: string) {
    this.state = _state;
  }

  loading = () => this._set(STATE.LOADING);
  ready = () => this._set(STATE.READY);
  error = () => this._set(STATE.ERROR);
  isLoading = () => this.state === STATE.LOADING;
  isReady = () => this.state === STATE.READY;
  isError = () => this.state === STATE.ERROR;

  subscribe(observable: Observable<any>, success?: (res) => void, error?: (err) => void): Subscription {
    this.loading();
    return observable.subscribe((res) => {
      if (success) {
        success.call(this, res);
      }
      this.ready();
    }, (err) => {
      if (error) {
        error.call(this, err);
      }
      this.error();
    });
  }

}

export const panelLoadingAnimations: AnimationTriggerMetadata = trigger('panelLoading', [
  state(STATE.ERROR, style({opacity: 0})),
  state(STATE.LOADING, style({opacity: 0.5})),
  state(STATE.READY, style({opacity: 1})),
  transition(STATE.LOADING + ' <=> ' + STATE.READY, [
    animate(200)
  ])
]);
