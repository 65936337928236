import {Component, Input} from '@angular/core';
import {SearchQuery, SearchService, StoredQueriesService, StoredQuery, SystemService, TranslateService, Utils} from '@eo-sdk/core';
import {IWidgetComponent} from '@yuuvis/widget-grid';
import {Icharts} from './icharts';
import {AppSearchService} from '../../../../eo-framework-core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NavigationExtras, Router} from '@angular/router';
import {EMPTY, tap} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'eo-charts-widget',
  templateUrl: './charts-widget.component.html',
  styleUrls: ['./charts-widget.component.scss']
})
export class ChartsWidgetComponent implements IWidgetComponent {
  histogram: any;
  chartOptions = {

    color: {
      text: '#925699',
      label: '#925699'
    },
    legend: {
      'alignment': 'center'
    },
    resizable: true,
    donut: {
      'alignment': 'center'
    },

    toolbar: {
      enabled: false,
    },
    axes: {
      left: {
        mapsTo: 'value',
      },
      bottom: {
        mapsTo: 'group',
        scaleType: 'labels',
      },
    },
    height: '400px',
  };

  chartData: {group: string; value: any}[] = [];
  private _query: SearchQuery;
  refreshingRequest: boolean;
  storedQuery: StoredQuery;
  private _widgetConfig: Icharts | undefined;

  @Input() editMode = false;

  @Input() set widgetConfig(c: Icharts | undefined) {
    this._widgetConfig = c;
    if (this._widgetConfig?.storedQuery && this._widgetConfig?.formValue?.aggregation) {
      this.getStoredQuery(this._widgetConfig.storedQuery).subscribe(() => {
        this._query = this.searchService.buildQuery(this.storedQuery);
        this._widgetConfig.formValue.storedQuery = this.storedQuery.name;
        if (this._widgetConfig?.formValue?.aggregation) {
          this.generateChartData(this._widgetConfig?.formValue?.aggregation, this._widgetConfig?.aggregationType, this._widgetConfig?.formValue?.histogramType);
        }
      }, (error) => {
        if (error.status === 404) {
          return EMPTY;
        }
        throw error;
      });
    }
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private router: Router,
    private searchService: SearchService,
    private appSearchService: AppSearchService,
    private storedQueriesService: StoredQueriesService,
    private translate: TranslateService,
    private systemService: SystemService
  ) {}

  getStoredQuery(id: string) {
    return this.storedQueriesService.getStoredQuery(id).pipe(
      untilDestroyed(this),
      tap(storedQuery => {
        this.storedQuery = storedQuery;
      })
    );
  }

  private generateChartData(field, type, histogramType) {
    this.refreshingRequest = true;
    const queryName = this._query.name;
    delete this._query.name;
    this._query = this.searchService.buildQuery(this._query);
    if (type === 'DATETIME' && histogramType && histogramType !== 'standard') {
      const histogramConfig = {
        'yearly': {calendar_interval: 'year', format: 'yyyy'},
        'weekly': {calendar_interval: 'week', format: 'yyyy-ww'},
        'monthly': {calendar_interval: 'month', format: 'yyyy-MM'},
        'daily': {calendar_interval: 'day', format: 'yyyy-MM-dd'}
      };
      if (histogramConfig.hasOwnProperty(histogramType)) {
        this.histogram = histogramConfig[histogramType];
        this.histogram.size = this._widgetConfig?.formValue?.size ? this._widgetConfig?.formValue?.size : 10;
        const query = {
          aggs: {
            [field.replace('sysobject.', '')]: {
              histogram: this.histogram
            }
          },
          ...this._query.getQueryJson()
        };
        this.searchService.executeQuery(query, true).subscribe((res) => {
          this.refreshingRequest = false;
          const aggregationKey = Object.keys(res.aggregations)[0];
          this._query.name = queryName;
          this.chartData = res.aggregations[aggregationKey].buckets.map((e) => ({
            group: e.key_as_string,
            value: e.doc_count,
          }));
        },
          (error) => {
            this.chartData = [];
            this.refreshingRequest = false;
          });
      } else {
        this.refreshingRequest = false;
      }
    } else {
      this.searchService.executeQuery(
        {
          aggs: {
            [field.replace('sysobject.', '').replace('sysdocument.', '')]:
            {size: this._widgetConfig?.formValue?.size ? this._widgetConfig?.formValue?.size : 10}
          }, ...this._query.getQueryJson()
        }, true)
        .subscribe((res) => {
          this.refreshingRequest = false;
          this._query.name = queryName;
          if (res.aggregations[Object.keys(res.aggregations)[0]].buckets.length > 0) {
            this.chartData = res.aggregations[Object.keys(res.aggregations)[0]].buckets.map((e) => {
              if (type === 'BOOLEAN') {
                return {
                  group: e.key === 0 ? '\u2610' : '\u2611',
                  value: e.doc_count,
                };
              } else {
                return {
                  group: e.key,
                  value: e.doc_count,
                };
              }
            });
          } else {
            this.chartData = [];
            this.refreshingRequest = false;
          }
          if (this._widgetConfig?.formValue?.aggregation === 'sysobject.modified') {
            this.chartData = res.aggregations.modified.buckets.map((e) => {
              return {
                group: this.translate.instant('eo.search.agg.time.' + e.key),
                value: e.doc_count,
              };
            });
          }
          if (this._widgetConfig?.formValue?.aggregation === 'sysobject.created') {
            this.chartData = res.aggregations.created.buckets.map((e) => {
              return {
                group: this.translate.instant('eo.search.agg.time.' + e.key),
                value: e.doc_count,
              };
            });
          }
          if (this._widgetConfig?.formValue?.aggregation === 'sysdocument.mimetypegroup') {
            this.chartData = res.aggregations.mimetypegroup.buckets.map((e) => {
              return {
                group: e.key,
                value: e.doc_count,
              };
            });
          }
          if (this._widgetConfig?.formValue?.aggregation === 'sysobject.type') {
            this.chartData = res.aggregations.type.buckets.map((e) => {
              return {
                group: this.systemService.getObjectType(e.key).label,
                value: e.doc_count,
              };
            });
          }
          if (this._widgetConfig?.formValue?.aggregation === 'sysdocument.filesize') {
            this.chartData = res.aggregations.filesize.buckets.map((e) => {
              return {
                group: this.translate.instant('eo.search.agg.filesize.' + e.key),
                value: e.doc_count,
              };
            });
          }
        },
          (error) => {
            this.chartData = [];
            this.refreshingRequest = false;
          });
    }
  }

  setQueryToAppSearch() {
    this.appSearchService.setQuery(this.storedQuery);
    this.router.navigate([{outlets: {modal: 'search'}}]);
  }

  refresh() {
    this.getStoredQuery(this._widgetConfig.storedQuery).subscribe(() => {
      this._query = this.searchService.buildQuery(this.storedQuery);
      this._widgetConfig.formValue.storedQuery = this.storedQuery.name;
      if (this._widgetConfig?.formValue?.aggregation) {
        this.generateChartData(this._widgetConfig?.formValue?.aggregation, this._widgetConfig?.aggregationType, this._widgetConfig?.formValue?.histogramType);
      }
    }, (error) => {
      this.chartData = [];
      this.refreshingRequest = false;
    });
  }

  onEmitQueryClick(event) {
    delete this._query.fields;
    const uriParam = encodeURIComponent(Utils.formDataStringify(this._query.getQueryJson()));
    const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};
    const queryParams = new URLSearchParams(uriParamQuery.queryParams).toString();
    const url = window.location.href.split('/dashboard')[0] + '/result?' + queryParams;
    window.open(url, event?.ctrlKey || this.editMode ? '_blank' : '_self');
  }
}
