import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {PageTitleService} from '../../../eo-framework-core/title/page-title.service';

/**
 * Used as 404 state for apps routing
 */

@Component({
  selector: 'eo-not-found',
  template: '<eo-error404></eo-error404>'
})
export class NotFoundComponent {

  constructor(private titleService: PageTitleService) {
    this.titleService.setDefaultTitle();
  }


}
