import {GridOptions} from '@ag-grid-community/core';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {
  BackendService,
  Config,
  EnaioEvent,
  EventService,
  FieldDefinition,
  LocalStorageService,
  ObjectType,
  SearchQuery, SearchResult,
  SearchService,
  SortOption,
  StoredQueriesService, StoredQuery,
  TranslateService, Utils
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {of as observableOf} from 'rxjs';
import {GridService} from '../../eo-framework-core/api/grid.service';
import {AppSearchService} from '../../eo-framework-core/search/app-search.service';
import {GridFilter} from '../grid/grid-filter.model';
import {GridComponent} from '../grid/grid.component';
import {ListContainerComponent} from '../list-container';

@UntilDestroy()
@Component({
  selector: 'eo-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss']
})
export class ResultListComponent implements OnInit {

  @ViewChild('eoGrid') grid: GridComponent;
  @ViewChild('eoList') list: ListContainerComponent;

  // for lists that exceed the configured max result size, data will be fetched in chunks
  private VIRTUAL_LIST_CHUNK_SIZE: number;
  resolveReferencesCsv: boolean;
  resolveReferencesCsvThreshold: number;
  static COLUMNS_DEFINITION = 'eo.framework.cache.columns.definition';

  private _result: SearchResult;
  private _query: SearchQuery;
  private _cachedColumns: any[];
  private defaultQuery: SearchQuery;
  csvLoadingSpinnerVisibility = false;

  visibleConfig = false;
  showLoader = false;
  public totalHits: any;
  public limitedList = false;
  public resultGridOptions: GridOptions = {};
  settings = {};
  querySubtitle = '';
  savedSearchClicked = false;
  storedQuery: StoredQuery;
  showWarnExportCSVThresholdDialog = false;

  @Input() title = '';
  @Input() configType: ObjectType | null;
  @Input() configContext: ObjectType | null;

  // If set to true query will be treated like a temporary search.
  // That means, global search will not be affected (global query will be reset after execution)
  @Input() clearAfterExecute: boolean;
  @Input() emptyMessage = '';
  @Input() selectFirst = true;
  @Input() gridOptions: GridOptions;
  @Input() hasIcon = false;
  @Input() reference = false;
  @Output() onSelectionChanged = new EventEmitter();
  @Output() onFocusChanged = new EventEmitter();
  @Output() onDoubleClick = new EventEmitter();
  @Output() onContextMenu = new EventEmitter();
  @Output() onCountChanged = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Output() onResult = new EventEmitter();
  @Output() onQueryInvalid = new EventEmitter();

  @Input()
  set query(query: any) {
    this.storedQuery = undefined;
    if (!query) {
      this.invalidQuery();
    } else {
      if (query.name) {
        this.storedQueriesService.getStoredQueries$()
          .pipe(untilDestroyed(this))
          .subscribe(queriesSubscription => {
            this.storedQuery = queriesSubscription.queries.filter(q => q.name === query.name)[0];
          });
      }
      this._query = this.searchService.buildQuery(query);
      if (this._query) {
        this.defaultQuery = this.searchService.buildQuery(this._query.getQueryJson());
        this.appSearchService.setQuery(this.defaultQuery);
        this.appSearchService.aggregate();

        const config = this.searchService.getResultFieldDefinitionConfig(this.query);
        this.configType = config.type;
        this.configContext = config.contextType;
        this.showLoader = true;
        this.searchService
          .getChunkedResult(this._query, 0, this.VIRTUAL_LIST_CHUNK_SIZE)
          .subscribe(result => {
            this.showLoader = false;
            this.clearQuery(this.clearAfterExecute);
            this.searchResult = result;
          }, Utils.throw(() => {
            this.showLoader = false;
            this.clearQuery(true);
            this.invalidQuery();
          }));
        this.querySubtitle = '';
        for (let i = 0; i < this.query.types.length; i++) {
          this.querySubtitle += this.query.types[i].label;
          if (i !== this.query.types.length - 1) {
            this.querySubtitle += ', ';
          }
        }
      } else {
        this.invalidQuery();
      }
    }
  }

  get query() {
    return this._query;
  }

  @Input()
  set searchResult(result: SearchResult) {
    // todo: backend should resolve query.fields and return config in specific order
    const _fields = result.fields ? observableOf(result.fields) :
      this.searchService.fetchResultFieldDefinition(this.configType, this.configContext, 'ALL');
    _fields.subscribe(fields => {
      if (fields && this.query && this.query.fields && this.query.fields.length) {
        fields.elements = this.query.fields.map(f => fields.elements.find(e => f === e.hitname));
      }
      result.fields = fields || new FieldDefinition();
      this._result = result;
      this.processResults(this._result);
    });
  }

  get searchResult() {
    return this._result;
  }

  constructor(private config: Config, public translate: TranslateService, public gridApi: GridService, private backend: BackendService,
              private appSearchService: AppSearchService, private searchService: SearchService, private eventService: EventService,
              private storageService: LocalStorageService, private storedQueriesService: StoredQueriesService, private router: Router) {
    this.VIRTUAL_LIST_CHUNK_SIZE = this.config.getRaw('search.limit') as number;
    this.resolveReferencesCsv = this.config.getRaw('search.resolveReferencesCSV') as boolean || false;
    this.resolveReferencesCsvThreshold = this.config.getRaw('search.resolveReferencesCSVThreshold') as number || 10000;
    this._cachedColumns = this.storageService.getItem(ResultListComponent.COLUMNS_DEFINITION) || [];
  }

  clearQuery(clearAfterExecute) {
    if (clearAfterExecute) {
      this.appSearchService.clearQuery();
    }
  }

  invalidQuery() {
    this.onQueryInvalid.emit();
  }

  ngOnInit() {
    this.eventService
      .on(EnaioEvent.DMS_OBJECT_DELETED)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (this.grid) {
          this.grid.updateRows(this.backend.update([res.data], [{id: res.data.id}]));
        }
      });

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (this.grid) {
          // this.grid.updateRows(this.backend.update([res.data], [{id: res.data.id, item: res.data.data}]));
        }
      });
  }


  refreshGrid() {
    if (this.query) {
      this.query = this.defaultQuery;
    }
    this.onRefresh.emit(this.query);
  }

  onColumnResized(column: any) {
    let col = this._cachedColumns.find(c => c.qname === column.colDef.refData.qname);
    if (col) {
      col.width = column.actualWidth;
    } else {
      this._cachedColumns.push({qname: column.colDef.refData.qname, width: column.actualWidth});
    }
    this.storageService.setItem(ResultListComponent.COLUMNS_DEFINITION, this._cachedColumns);
  }

  /**
   * Event handler for grid configuration
   * @param $event
   */
  onConfigChanged(refresh) {
    if (refresh) {
      this.refreshGrid();
    }
  }

  /**
   * Process Search result & generate column definitions
   * @param result
   */
  private processResults(result: SearchResult) {
    this.visibleConfig = false;
    this.onResult.emit(result);
    this.totalHits = result.count.value;
    this.limitedList = this.totalHits > result.hits.length;
    let gridOptions: GridOptions = {context: {}, onSortChanged: () => this.onPageChanged(), onFilterChanged: () => this.onPageChanged()};
    const searchMode = result.fields.mode ? result.fields.mode : null;

    this.settings = this.limitedList && this.query ? {total: this.totalHits, size: this.VIRTUAL_LIST_CHUNK_SIZE, relation: result.count.relation} : null;
    gridOptions.rowData = result.hits;

    const {elements, sortorder, grouporder, pinned, alignmentx} = result.fields;

    gridOptions.context.count = result.count;
    gridOptions.columnDefs = this.gridApi.getColumnDefs(elements, sortorder, grouporder, pinned, alignmentx, false, this._cachedColumns, searchMode);

    const refreshGrid = this.hasGridOptions;
    Object.assign(this.resultGridOptions, gridOptions, this.gridOptions);

    if (refreshGrid) {
      // reload grid component
      this.resultGridOptions = {};
      setTimeout(() => Object.assign(this.resultGridOptions, gridOptions, this.gridOptions), 0);
    }
  }

  private postfixSort(id) {
    // todo: remove when it's fixed on backend
    return id.match(/(^sysobject\.|^sysdocument\.)(creator$|modifier$)/) ? 'title' : '';
  }

  onPageChanged(page: number = 1) {
    if (this.limitedList && this.query) {
      this.fetchRowData({
        startRow: this.VIRTUAL_LIST_CHUNK_SIZE * (page - 1),
        sortModel: this.grid.api.getSortModel(),
        filterModel: this.grid.api.getFilterModel(),
        successCallback: (rows, total, relation) => {
          this.settings = {total, relation, size: this.VIRTUAL_LIST_CHUNK_SIZE, page};
          this.grid.api.setRowData(rows);
        }
      });
    }
  }

  /**
   * Function for fetching the items to be displayed with a virtual row model.
   *
   * @param params - row model params (incl. startRow, endRow, sortModel and filterModel)
   */
  private fetchRowData(params: any) {

    if (this.query) {

      this.updateQuery(params);

      this.searchService
        .getChunkedResult(this.query, params.startRow, this.VIRTUAL_LIST_CHUNK_SIZE)
        .subscribe(result => {
          this.totalHits = this.grid.gridOptions.context.rowCount = result.count.value;
          this.grid.gridOptions.context.count = result.count;
          params.successCallback(result.hits, this.totalHits, result.count.relation);
        });
    }
  }

  private updateQuery(params: any) {
    this.query.sortOptions = (params.sortModel || this.grid.api.getSortModel() || [])
        .map(model => new SortOption(GridService.qnameFormatter(model.colId + this.postfixSort(model.colId)), model.sort));

    this.query.filters = this.defaultQuery.filters
      .concat((Object.keys(params.filterModel || this.grid.api.getFilterModel()) || [])
        .map(key => GridFilter.operator(GridService.qnameFormatter(key), (params.filterModel || this.grid.api.getFilterModel())[key])));

    this.query.searchMode = SearchQuery.DEFAULT_SEARCH_MODE;
  }

  executeSuggestSearch() {
    // todo: implement
  }

  private resultHasReferences(): boolean {
    return this._result.fields.elements.some(element => element.type === 'ORGANIZATION' || element.type === 'REFERENCE');
  }

  exportCSV(ignoreThreshold = false) {
    if (!ignoreThreshold && this.resolveReferencesCsv
      && (this.list.gridCount.totalCount > this.resolveReferencesCsvThreshold || this.list.gridCount.relation === 'gte')
      && this.resultHasReferences()) {
      this.showWarnExportCSVThresholdDialog = true;
      return;
    }
    this.csvLoadingSpinnerVisibility = true;
    this.updateQuery({});
    this.showWarnExportCSVThresholdDialog = false;
    this.searchService.downloadCsvFromQuery(this.query.getQueryJson(this.resolveReferencesCsv)).subscribe(() => {
      this.csvLoadingSpinnerVisibility = false;
    });
  }

  parseDmsParams(data) {
    return {id: data && data.id ? data.id : null, type: data && data.type ? data.type : null};
  }

  get hasGridOptions() {
    return !!(this.resultGridOptions.datasource || this.resultGridOptions.rowData);
  }

  onStoredQueryLoaded() {
    this.savedSearchClicked = false;
    this.router.navigate([{outlets: {modal: 'search'}}]);
  }

  onStoredQueryExecute(evt) {
    this.savedSearchClicked = false;
    const uriParam = encodeURIComponent(JSON.stringify(evt.queryJson));
    const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};
    this.router.navigate(['/result'], uriParamQuery);
  }
}
