import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {DmsObject, NotificationsService} from '@eo-sdk/core';
import {TranslateService} from '@ngx-translate/core';
import {AgentService} from '../../../../../eo-framework-core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {Location} from '@angular/common';


@Component({
  selector: 'eo-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements ActionComponent, AfterViewInit {

  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  actionDescription: string;
  showDescriptionOrginal: boolean;
  showDescriptionPdf: boolean;
  showDescriptionLink: boolean;
  isExecutable: boolean

  constructor(
    private location: Location,
    private agentService: AgentService,
    private elemRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('[tabindex="0"]').focus();
      this.isExecutable = this.agentService.isConnected && !this.selection[0].isFolder && this.selection[0].content && (this.selection[0].content.count !== 0 || this.selection[0].content.linked);

    });
  }

  runLink() {
    let subject = '';
    let body = '';
    let length = this.selection.length;
    let index = 0;

    this.selection.forEach((selectedItem) => {
      if (selectedItem.title !== '') {
        if (!this.endsWith(subject, '...')) {
          if (subject.length > 100) {
            subject += ' ...';
          } else {
            subject += encodeURIComponent(selectedItem.title);
          }
        }
        body += encodeURIComponent(selectedItem.title + ': \n');
      }
      if (index < length - 1) {
        subject = subject + '%3B%20';
      }

      body += encodeURIComponent(window.location.href.replace(this.location.path(), '')
        + (window.location.href.includes('/versions') ?
          `/versions/${selectedItem.id}type=${selectedItem.type.name}&version=${selectedItem.version}` :
          `/object/${selectedItem.id}?type=${selectedItem.type.name}\n`));
      index++;
    });

    window.location.href = 'mailto:?subject=' + subject + '&body=' + body;
  }

  endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  runOriginal() {
    const attachments = this.selection.map((element) => ({
      id: (element.content && element.content.linked && element.content.id) ? element.content.id : element.id,
      title: element.title,
      type: (element.content && element.content.linked && element.content.type) ? element.content.type : element.type.name,
      version: window.location.href.includes('/versions') ? element.version : null,
      rendition: ''
    }));

    this.agentService.sendAsMail({attachments});
  }

  runPdf() {
    const attachments = this.selection.map((element) => ({
      id: (element.content && element.content.linked && element.content.id) ? element.content.id : element.id,
      title: element.title,
      type: (element.content && element.content.linked && element.content.type) ? element.content.type : element.type.name,
      version: window.location.href.includes('/versions') ? element.version : null,
      rendition: 'PDF'
    }));

    this.agentService.sendAsMail({attachments});
  }

  showDescription(i, event) {
    this.actionDescription = i === this.actionDescription ? null : i;
    this.showDescriptionOrginal = this.actionDescription != null && this.actionDescription == 'original' ? true : false;
    this.showDescriptionPdf = this.actionDescription != null && this.actionDescription == 'pdf' ? true : false;
    this.showDescriptionLink = this.actionDescription != null && this.actionDescription == 'link' ? true : false;
    event.stopPropagation();
    event.preventDefault();
  }

  cancel() {
    this.canceled.emit();
  }
}
