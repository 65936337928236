import {GridOptions} from '@ag-grid-community/core';
import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DmsObject, DmsService, EnaioEvent, EventService, ObjectType, SearchResult, SystemService, TranslateService, Utils} from '@eo-sdk/core';
import {forkJoin} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GridService} from '../../../eo-framework-core/api/grid.service';
import {LayoutService} from '../../../eo-framework-core/layout/layout.service';
import {LocationService} from '../../../eo-framework-core/location/location.service';
import {PendingChangesComponent} from '../../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../../eo-framework-core/title/page-title.service';
import {ActionService} from '../../../eo-framework/actions/action-service/action.service';
import {GridComponent} from '../../../eo-framework/grid/grid.component';
import {TreeNode} from '../../../eo-framework/tree/tree.component.interface';
import {PanelLoading, panelLoadingAnimations} from '../../../eo-framework/ui/animations/panel-loading.animation';
import {FrontPageData, ObjectSearchResult} from '../object-state-service/object-state.interface';
import {ObjectStateService} from '../object-state-service/object-state.service';

@UntilDestroy()
@Component({
  selector: 'eo-object-state',
  templateUrl: './object-state.component.html',
  styleUrls: ['./object-state.component.scss'],
  providers: [ObjectStateService],
  animations: [panelLoadingAnimations]
})
export class ObjectStateComponent implements OnInit, OnDestroy, PendingChangesComponent {

  @ViewChild('eoGrid') eoGrid: GridComponent;

  error = false;
  context: DmsObject;
  selectedItem: DmsObject;
  frontPage: boolean;
  frontPageData: FrontPageData;
  contextTree: TreeNode[];
  contextTreeUpdate: TreeNode[];
  contextTreeRef: TreeNode[];
  contextTreeRefUpdate: TreeNode[];
  selectedNodes: TreeNode[];
  selectedNode: TreeNode;
  highlightedNodes: string[];
  isReferenceActive = false;
  private expandedNodeIds: string[] = [];
  termFilter: string;
  allTypes: string[] = [];
  configType: ObjectType;
  @Output() onTermFilterChange = new EventEmitter();

  gridOptions: GridOptions;
  gridTitle: string;
  emptyGrid = true;

  searchResult: SearchResult;
  typeName = '';
  cacheLayout = '';

  loadingState = {
    tree: new PanelLoading(),
    treeRef: new PanelLoading(),
    list: new PanelLoading(),
    details: new PanelLoading(),
  };

  constructor(private route: ActivatedRoute,
    private titleService: PageTitleService,
    private systemService: SystemService,
    private dmsService: DmsService,
    private selection: SelectionService,
    public translate: TranslateService,
    public objectState: ObjectStateService,
    private actionService: ActionService,
    private location: LocationService,
    private pendingChanges: PendingChangesService,
    private eventService: EventService,
    private gridService: GridService,
    private layoutService: LayoutService) {

    this.titleService.setBaseTitle(null);
    this.gridOptions = <GridOptions>{};

    this.onTermFilterChange
      .pipe(debounceTime(1000))
      .subscribe(v => this.setupFrontPage(true));

    // subscribe for data from the connected service
    this.objectState
      .list$
      .pipe(untilDestroyed(this))
      .subscribe((res: ObjectSearchResult) => {
        this.frontPage = !res;
        if (res) {
          // setup the grid with the new data
          let {name, result} = res;
          this.emptyGrid = result.count.value === 0;
          this.gridTitle = name;
          this.configType = this.systemService.getObjectType(result.typeName) || {qname: result.typeName} as ObjectType;
          this.searchResult = result;
        }

        // this.loadingState.list.ready();
      });

    this.objectState.tree$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(res => this.buildContextTree(res.folder));

    this.objectState.treeRef$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(res => this.buildContextTreeRef(res.folder));

    this.selection.focus$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((item: DmsObject) => {
        if (item) {
          this.highlight(item.id);
        }
        this.selectedItem = item;
        // this.loadingState.details.ready();
      });

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_DELETED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((evt) => {
        setTimeout(() => {
          if (this.context && this.context.id === evt.data.id) {
            this.context = null;
            this.error = true;
            return;
          }
          if (this.frontPage) {
            this.setupFrontPage();
          }
          this.updateContextTree();
        }, 1000);
      });

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(event => {
        if (this.context && event.data && this.context.id === event.data.id) {
          this.context = event.data;
        }
        if (this.frontPage) {
          this.setupFrontPage(true);
        }
        this.updateContextTree();
      });

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_PASTED)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe((evt) => {
        const name = this.translate.instant('eo.clipboard.paste.resultlist.headline');
        this.objectState
          .selectTreeNode(<any>{}, <any>{name}, {id: evt.data.map(d => d.id)}).subscribe();
        this.updateContextTree();
      });
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  onExpandedNodesChanged(expandedNodes: string[]) {
    this.expandedNodeIds = expandedNodes;
  }

  // select an entry from the tree
  onTreeSelectionChanged(node: TreeNode) {
    this.location.leave();
    this.selectedNode = node;
    if (node.data.type) {
      this.location.enter(this.context, this.selectedNode);
    } else {
      this.location.enter(this.context);
    }
    if (node) {
      this.gridService.updateContext(node.isReference || this.allTypes.includes(node.data.type) ? [node.data.type] : this.allTypes);
      this.loadingState.list
        .subscribe(node.isReference ?
          this.objectState.selectTreeNode(this.context, node, {term: this.termFilter}, 'referenceview') :
          this.objectState.selectTreeNode(this.context, node, {term: this.termFilter}));
    } else {
      this.setupFrontPage();
    }
  }

  onContextMenuClicked(evt, doc?) {
    evt.preventDefault();
    evt.stopPropagation();
    this.actionService.showActions([doc || this.context], 'DMS_OBJECT');
  }

  refreshList() {
    this.onTreeSelectionChanged(this.selectedNode);
  }

  private process(item: DmsObject, selectedId?) {
    if (item.isContextFolder) {
      // if there already is a context set, we need to leave location for the old context first
      // otherwise UploadTargets will not be unregistered correctly
      if (this.context) {
        this.location.leave();
      }
      this.context = item;
      this.cacheLayout = 'object.state--' + item.typeName;
      // add this location to the location service, which will track active locations
      // and handle a history of visited locations.
      this.location.enter(this.context, this.selectedNode);

      this.objectState.loadTreeByQuery(this.context.id, this.context.typeName, {definition: [{field: 'type', key: 'Types', size: 1000}]})
        .subscribe(types => (this.allTypes = (types.folder[0].folder || []).map(t => t.type)));

      this.fetchTreeData('', selectedId);
      this.setupFrontPage(true);

    } else {
      // fetch the context folder
      if (item.contextFolder) {
        this.cacheLayout = 'object.state--' + item.contextFolder.typeName;
        this.dmsService
          .getDmsObject(item.contextFolder.id, item.contextFolder.typeName, null, 'DETAILS')
          .subscribe((res: DmsObject) => {
            this.process(res, item.id);
          });
      } else {
        // just the document in root
        this.context = null;
        this.cacheLayout = '';
        // hide empty tree & list
        // this.layoutService.hideAreas((a, i) => i < 2);
      }
    }
    // this.layoutService.visibilityUpdate();
  }

  setupFrontPage(updateSelection = false, updateTree = false) {
    this.objectState
      .selectFrontPage(this.context)
      .subscribe((data) => {
        this.frontPage = true;
        this.frontPageData = data;
      });

    if (!updateSelection) {
      this.location.leave();
      this.selection.focus(this.context);
      this.location.enter(this.context);
    }

    if (!updateTree) {
      this.fetchTreeData(this.termFilter);
    }
  }

  private fetchTreeData(termFilter?: string, selectedId?: any) {
    this.highlight(selectedId);
    this.loadingState.tree.subscribe(this.objectState.updateTreeByQuery(this.context, {term: termFilter}));
    this.loadingState.treeRef.subscribe(this.objectState.updateTreeByQuery(this.context, {term: termFilter}, 'referenceview'));
  }

  private highlight(selectedId) {
    if (selectedId && this.context) {
      forkJoin([
        this.objectState.loadTreeByQuery(this.context.id, this.context.typeName, {id: selectedId}),
        this.objectState.loadTreeByQuery(this.context.id, this.context.typeName, {id: selectedId}, 'referenceview')
      ]).subscribe((res) => {
        this.highlightedNodes = this.highlightNodes([...res[0].folder, ...res[1].folder]);
        this.isReferenceActive = !!(res[1].total.value && !res[0].total.value);
      });
    } else {
      this.highlightedNodes = [];
    }
  }

  private updateContextTree() {
    if (this.context) {
      this.objectState
        .loadTreeByQuery(this.context.id, this.context.typeName, {term: this.termFilter})
        .subscribe(res => this.contextTreeUpdate = res.folder.map(f => this.addTreeNode(f, [])));
      this.objectState
        .loadTreeByQuery(this.context.id, this.context.typeName, {term: this.termFilter}, 'referenceview')
        .subscribe(res => this.contextTreeRefUpdate = res.folder.map(f => this.addTreeNode(f, [], true)));
    }
  }

  private highlightNodes(folder = [], nodes = []) {
    folder.forEach(f => {
      if (f.count) {
        nodes.push(f.key);
      }
      if (f.folder) {
        this.highlightNodes(f.folder, nodes);
      }
    });
    return nodes;
  }

  private buildContextTree(folder = []) {
    this.contextTreeUpdate = null;
    this.contextTree = folder.map(f => this.addTreeNode(f, []));
  }

  private buildContextTreeRef(folder = []) {
    this.contextTreeRefUpdate = null;
    this.contextTreeRef = folder.map(f => this.addTreeNode(f, [], true));

  }

  private addTreeNode(nodeData, path: string[], isReference = false) {
    const {key, title, folder, expanded, count, stats} = nodeData;
    // important to create clone here, because otherwise path will be messed up
    const p = path.concat([title]);
    let node: TreeNode = {
      id: key,
      name: title,
      path: p,
      children: (folder || []).map(f => this.addTreeNode(f, p, isReference)),
      expanded: expanded || !!~this.expandedNodeIds.indexOf(key),
      selected: false,
      selectable: true,
      isReference,
      badges: (stats || []).map(stat => Object.assign({
        value: stat.value + ' ' + stat.title,
        cssClass: 'stats'
      })),
      data: nodeData
    };

    if (count) {
      node.badges.push({value: count});
    }

    return node;
  }

  ngOnInit() {
    this.route.url
      .pipe(
        untilDestroyed(this))
      .subscribe(() => {
        this.cacheLayout = '';
        // this.layoutService.hideAreas(() => false);
        const {params, queryParams} = this.route.snapshot;
        if (params.id) {
          this.typeName = queryParams['type'] || '';
          this.selection.focus({id: params.id, typeName: this.typeName});
          this.dmsService
            .getDmsObject(params.id, this.typeName, null, 'DETAILS')
            .subscribe((res: DmsObject) => {
              this.error = false;
              this.process(res);
              this.selection.focus(res);
            }, Utils.throw(() => {
              // this.layoutService.setAreas([]);
              this.error = true;
            }));
        }
      });
  }

  ngOnDestroy() {
    // when the component is destroyed, we tell the location service that we
    // left the location
    this.location.leave();
    this.selection.clear();
  }

}
