import {Component, Input} from '@angular/core';
import {DmsObject, EoUser, UserService} from '@eo-sdk/core';

@Component({
  selector: 'eo-signature-tab',
  templateUrl: './signature-tab.component.html',
  styleUrls: ['./signature-tab.component.scss']
})
export class SignatureTabComponent {

  private _dmsObject: DmsObject;
  user: EoUser;

  @Input()
  set dmsObject(item: DmsObject) {
    if (this._dmsObject !== item) {
      this._dmsObject = item;
    }
  }

  get dmsObject(): DmsObject {
    return this._dmsObject;
  }

  constructor(private userService: UserService) {
    this.userService.user$.subscribe((user) => {
      this.user = user;
    });
  }
}
