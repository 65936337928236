import {forkJoin as observableForkJoin, from as observableFrom, of as observableOf, Subject, Subscription} from 'rxjs';
import {pluck, switchMap} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {PageTitleService} from '../../../../eo-framework-core/title/page-title.service';
import {ObjectFormComponent} from '../../../object-form/object-form/object-form.component';
import {
  ObjectFormOptions,
  FormStatusChangedEvent
} from '../../../object-form/index';
import {NgForm} from '@angular/forms';
import {LocaleDatePipe} from '../../../../eo-framework-core/pipes/locale-date.pipe';
import {AppSearchService} from '../../../../eo-framework-core/search/app-search.service';
import {
  Capabilities,
  CapabilitiesService,
  Logger,
  NotificationsService,
  ObjectType,
  PrepareService,
  QueryScope,
  RangeValue,
  SearchFilter,
  SearchQuery,
  SearchService,
  SearchState,
  StoredQueriesService,
  StoredQuery,
  SystemService,
  TranslateService,
  UserService,
  Utils
} from '@eo-sdk/core';
import {AutoComplete} from '@yuuvis/components/autocomplete';
import {ContextType, IdxSearch} from './app-search.interface';
import {YvcOverlayRef, YvcOverlayService} from '@yuuvis/components/overlay';
import {Dialog} from '@yuuvis/components/dialog';

@Component({
  selector: 'eo-app-search',
  templateUrl: './app-search.component.html',
  styleUrls: ['./app-search.component.scss'],
  encapsulation: ViewEncapsulation.None
  // todo: apply onPush change detection (agg search result right now doesn't update)
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  PARAMS = SearchQuery.BASE_PARAMS;
  OPERATOR = SearchFilter.OPERATOR;
  queryScope: QueryScope;

  @ViewChild('tplSearch', {read: TemplateRef}) tplSearch: TemplateRef<any>;
  @ViewChild('searchInput') searchInput: AutoComplete;
  @ViewChild('xpinput') expertInput: ElementRef;
  @ViewChild('idxform') indexdataForm: ObjectFormComponent;
  @ViewChild('ctxform') contextForm: ObjectFormComponent;

  private _overlayRef?: YvcOverlayRef;
  private querySubscription: Subscription;
  private queryStateSubscription: Subscription;
  query: SearchQuery;
  queryState: SearchState;
  capabilities: Capabilities;
  activeTabId?: string;
  loading: boolean;

  isStoredQuery: boolean;
  isOwnQuery = false;
  objectTypeGroups = [];
  typeGroupTree = [];
  autocompleteResults: string[];

  idxSearch: IdxSearch;
  currentContextFolderName;

  expertModeQueryEmpty = false;
  expertModeSupport = {
    baseParams: [],
    selectedGroup: null,
    selectedType: null
  };

  filtersModel = {};
  aggregationsModel = {};
  datePipe: LocaleDatePipe;
  sysRootContext = {
    id: null,
    name: ContextType.sysroot,
    label: this.translate.instant('eo.add.title.location.common'),
    situation: 'SEARCH',
    mode: 'CONTEXT',
    contextFormOptions: {
      formModel: {name: ContextType.sysroot}
    }
  };

  _selected: number = null;
  set selected(index) {
    this._selected = this._selected === index ? null : index;
  }

  get selected() {
    return this._selected;
  }

  @Output()
  public onExecuteSearch = new EventEmitter();

  get storedQuery(): StoredQuery {
    return this.query as StoredQuery;
  }

  constructor(
    private appSearchService: AppSearchService,
    private titleService: PageTitleService,
    private overlay: YvcOverlayService,
    private searchService: SearchService,
    private systemService: SystemService,
    private notifications: NotificationsService,
    public translate: TranslateService,
    private router: Router,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private storedQueriesService: StoredQueriesService,
    private capabilityService: CapabilitiesService,
    private userService: UserService,
    private prepareService: PrepareService,
    private logger: Logger
  ) {

    this.datePipe = new LocaleDatePipe(translate);

    this.expertModeSupport.baseParams = Object.keys(SearchQuery.BASE_PARAMS).map(k => {
      let data = SearchQuery.BASE_PARAMS[k];
      return {
        label: this.translate.instant('eo.global.baseparam.' + data),
        data: data
      };
    });

    this.aggregationsModel[SearchQuery.BASE_PARAMS.CREATED] = Object.keys(
      SearchQuery.TIME_PERIOD_BUCKETS
    ).map(k => {
      return {
        key: SearchQuery.TIME_PERIOD_BUCKETS[k],
        value: '',
        label: this.translate.instant(
          'eo.search.agg.time.' + SearchQuery.TIME_PERIOD_BUCKETS[k]
        )
      };
    });

    this.aggregationsModel[SearchQuery.BASE_PARAMS.MODIFIED] = Object.keys(
      SearchQuery.TIME_PERIOD_BUCKETS
    ).map(k => {
      return {
        key: SearchQuery.TIME_PERIOD_BUCKETS[k],
        value: '',
        label: this.translate.instant(
          'eo.search.agg.time.' + SearchQuery.TIME_PERIOD_BUCKETS[k]
        )
      };
    });

    this.aggregationsModel[SearchQuery.BASE_PARAMS.MIMETYPEGROUP] = Object.keys(
      SearchQuery.MIMETYPEGROUP_BUCKETS
    ).map(k => {
      return {
        key: SearchQuery.MIMETYPEGROUP_BUCKETS[k],
        value: '',
        label: SearchQuery.MIMETYPEGROUP_BUCKETS[k]
      };
    });

    this.aggregationsModel[SearchQuery.BASE_PARAMS.FILESIZE] = Object.keys(
      SearchQuery.FILE_SIZE_BUCKETS
    ).map(k => {
      return {
        key: SearchQuery.FILE_SIZE_BUCKETS[k],
        value: '',
        label: this.translate.instant(
          'eo.search.agg.filesize.' + SearchQuery.FILE_SIZE_BUCKETS[k]
        )
      };
    });
  }

  onTabChange(tabId: string) {
    this.activeTabId = tabId;
    if (tabId === 'restrictType') {
      this.appSearchService.setAggs({
        type: {}
      });
    } else if (tabId === 'restrictCreated') {
      this.appSearchService.setAggs({
        type: {},
        created: {}
      });
    } else if (tabId === 'restrictModification') {
      this.appSearchService.setAggs({
        type: {},
        modified: {}
      });
    } else if (tabId === 'restrictFile') {
      this.appSearchService.setAggs({
        type: {},
        mimetypegroup: {},
        filesize: {}
      });
    }
    this.appSearchService.aggregate();
  }

  private processQuery(query: SearchQuery) {
    // IMPORTANT: never change the local query directly
    // use searchService, so every components subscribed to the query
    // will get the new value
    this.query = query;
    this.currentContextFolderName = this.query.contextFolderTypes.length
      ? this.query.contextFolderTypes[0].name
      : null;
    this.isStoredQuery = query instanceof StoredQuery;

    if (this.isStoredQuery) {
      const user = this.userService.getCurrentUser();
      this.isOwnQuery =
        this.storedQuery.user.toLowerCase() === user.name.toLowerCase();
    }

    Object.keys(SearchQuery.BASE_PARAMS).forEach(k => this.processFilters(k));
    // if we have an indexdata search using a context but the context was removed
    if (
      !this.query.contextFolderTypes.length &&
      this.idxSearch &&
      this.idxSearch.contextFormOptions
    ) {
      this.idxSearch.contextFormOptions = null;
      this.idxSearch.contextFolderType = null;
      setTimeout(() => {
        this.updateSearchFilterFromFormData();
      }, 0);
    }

    // decide whether or not to setup an indexdata search
    const updateCausedByTypeToggle = this.query.__updateCause === SearchQuery.UPDATE_CAUSE.TOGGLED_TYPE;
    if (this.query.types.length === 1 && !updateCausedByTypeToggle) {
      // having just one target type and a context type setup means, that we are using indexdata search
      this.indexdataSearch(
        this.query.types[0],
        this.query.contextFolderTypes.length > 0 ? this.query.contextFolderTypes[0] : null
      );
    } else if (
      this.query.types.length === 1 &&
      this.appSearchService.getIndexSearchQueryFilters(this.query).length > 0
    ) {
      // having just one target type and indexdata filters also means, that we are using indexdata search
      this.indexdataSearch(this.query.types[0]);
    } else if (this.query.types.length > 1) {
      // reset indexdata search otherwise, because the current query may be reset from out side (e.g. by a stored query)
      this.idxSearch = null;
    }

    if (query.expertMode) {
      this.typeGroupTree = this.appSearchService.buildTypeGroupTree();
    }
    this.queryScope = query.scope;
  }

  public selectObjectType(type: ObjectType) {
    /** set up the right type filter if not already done */
    const queryTypesLength =
      this.query.types.length > 1 || this.query.types.length === 0;
    const queryTypesLengthAndName =
      this.query.types.length === 1 && this.query.types[0].name !== type.name;
    if (queryTypesLength || queryTypesLengthAndName) {
      // setting object types will emit a new query. This will then take care of
      // setting up the indexdata form
      this.appSearchService.setQueryTypes([type]);
    }
  }

  /**
   * Sets up indexdata search for a given object type
   * @param type - an object type
   * @param contextFolderType - context folder type
   */
  public indexdataSearch(type: ObjectType, contextFolderType?: ObjectType) {
    if (!type || this.query.types.length > 1) {
      return;
    }
    if (this.query.types.length === 1 && this.query.types[0].name === type.name) {
      /** even if we select a type that is already selected, we need to call aggregate as there may be context types involved */
      this.appSearchService.setAggs({
        type: {
          sub: {
            contextfoldertype: {}
          }
        }
      }, true);
      this.appSearchService.aggregate();
    }

    const isRootType = !!this.appSearchService.typesAllowedUnderSysroot.find(rT => rT.qname === type.qname);

    this.idxSearch = {
      type,
      contextFolderType,
      contextModels: isRootType ? [this.sysRootContext] : [],
      formOptions: null
    };

    /** fetch context type models */
    if (type.allowedcontexttypes.length) {
      const toFetch = [];
      type.allowedcontexttypes.forEach(contextType => {
        const objectType = this.systemService.getObjectType(contextType.name);
        if (objectType) {
          toFetch.push(
            this.systemService.getObjectTypeForm(
              contextType.name,
              'SEARCH',
              'CONTEXT'
            )
          );
        }
      });
      if (toFetch.length) {
        observableForkJoin(toFetch).subscribe(formModels => {
          /** abstract context types should not be used */
          this.addContextModel(formModels);
          /** if there was a context type provided by the query, we'll setup the right model as well */
          if (contextFolderType) {
            const model = this.idxSearch.contextModels.find(
              m => m.name === contextFolderType.name
            );

            if (model) {
              this.selectContext(model);
            } else if (contextFolderType.name === ContextType.sysroot) {
              this.selectContext(this.sysRootContext);
            } else {
              /** the provided context type does not exist any more, so just remove it */
              this.idxSearch.contextFolderType = null;
            }
          }
        });
      }
    }

    let formData;
    let idxFilters = this.appSearchService.getIndexSearchQueryFilters();
    if (idxFilters && idxFilters.length > 0) {
      // set up form data from indexdata query filters
      formData = idxFilters;
    }

    const objectType = this.systemService.getObjectType(type.name);
    if (objectType) {
      this.systemService
        .getObjectTypeForm(type.name, 'SEARCH')
        .subscribe(model => {
          this.idxSearch.formOptions = {
            formModel: model,
            data: formData
          };
        });
    }
  }

  private processQueryState(queryState: SearchState) {
    this.loading = false;
    this.queryState = queryState;
    if (this.idxSearch) {
      this.idxSearch.contextAggregations = {};
      if (queryState.aggregations.contextType.size) {
        this.idxSearch.contextAggregations = this.buildContextAggregations(queryState.aggregations.contextType);
      }
    }

    // save aggregations values
    if (!this.aggregationsModel[SearchQuery.BASE_PARAMS.TYPE] || !this.query.types.length || queryState.lastChange !== SearchQuery.BASE_PARAMS.TYPE) {
      this.aggregationsModel[SearchQuery.BASE_PARAMS.TYPE] = new Map(queryState.aggregations.type);
    }

    this.syncAggregations(SearchQuery.BASE_PARAMS.CREATED, SearchFilter.OPERATOR.RANGE);
    this.syncAggregations(SearchQuery.BASE_PARAMS.MODIFIED, SearchFilter.OPERATOR.RANGE);
    this.syncAggregations(SearchQuery.BASE_PARAMS.MIMETYPEGROUP, SearchFilter.OPERATOR.IN);
    this.syncAggregations(SearchQuery.BASE_PARAMS.FILESIZE, SearchFilter.OPERATOR.RANGE);
  }

  buildContextAggregations(contextType) {
    let contextAggregations = {};
    contextType.forEach((value, key) => contextAggregations[key] = value);
    return contextAggregations;
  }

  processFilters(k: string) {
    let f: SearchFilter = this.query.filters.find(ff => ff.property === SearchQuery.BASE_PARAMS[k]);
    if (!f) {
      this.filtersModel[SearchQuery.BASE_PARAMS[k]] = {
        value: null,
        multiValue: [],
        innerValue: []
      };
    } else if (
      !this.filtersModel[f.property] ||
      this.filtersModel[f.property].filter !== f
    ) {
      this.filtersModel[f.property] = {};
      this.filtersModel[f.property].filter = f;
      let value: any =
        typeof f.firstValue === 'string' &&
          f.operator === SearchFilter.OPERATOR.IN
          ? JSON.parse(f.firstValue)
          : typeof f.firstValue !== 'boolean' ? f.firstValue.concat([]) : f.firstValue;
      if (f.operator === SearchFilter.OPERATOR.RANGE) {
        this.filtersModel[f.property].multiValue = value;
        this.filtersModel[f.property].value = null;
      } else {
        if (
          f.property === SearchQuery.BASE_PARAMS.CREATED ||
          f.property === SearchQuery.BASE_PARAMS.MODIFIED
        ) {
          value = new RangeValue(f.operator, f.firstValue, f.secondValue);
        }
        this.filtersModel[f.property].value = value;
        this.filtersModel[f.property].multiValue = null;
      }
      this.filtersModel[f.property].innerValue =
        value instanceof Array ? value : [value];
      this.filtersModel[f.property].label = [];
      this.filtersModel[f.property].innerValue.forEach((v: any, i: number) => {
        if (
          f.property === SearchQuery.BASE_PARAMS.CREATOR ||
          f.property === SearchQuery.BASE_PARAMS.MODIFIER
        ) {
          this.filtersModel[f.property].label[i] = new Subject();
        } else {
          this.filtersModel[f.property].label[i] = observableOf(
            this.renderFilter(f, v)
          );
        }
      });
    }
  }

  syncAggregations(name, operator) {
    this.aggregationsModel[name].forEach(agg => {
      let a = this.queryState.aggregations[name].find(aa => aa.key === agg.key);
      if (a && a.value !== agg.value) {
        agg.value = a.value;
      } else if (!a && this.queryState.lastChange !== name) {
        agg.value = '';
      }
      agg.selected = this.isFilterActive(name, operator, agg.key);
      agg.saved = !a && this.queryState.lastChange === name;
    });
  }

  renderFilter(filter: SearchFilter, value: any) {
    if (value instanceof RangeValue) {
      return this.createDateRestriction(filter);
    } else if (typeof value === 'string' && this.aggregationsModel[filter.property]) {
      let agg = this.aggregationsModel[filter.property].find(a => a.key === value);
      return agg.label;
    }
    return value;
  }

  createDateRestriction(filter: SearchFilter) {
    switch (filter.operator) {
      case SearchFilter.OPERATOR.RANGE:
        return this.translate.instant(`eo.search.agg.time.${filter.firstValue}`);
      case SearchFilter.OPERATOR.EQUAL:
        return `= ${this.datePipe.transform(filter.firstValue, 'eoShortDate')}`;
      case SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH:
        return `${this.datePipe.transform(filter.firstValue, 'eoShort')} - ${this.datePipe.transform(filter.secondValue, 'eoShort')}`;
      case SearchFilter.OPERATOR.GREATER_OR_EQUAL:
        return `${this.translate.instant('eo.search.agg.time.span.since')} ${this.datePipe.transform(filter.firstValue, 'eoShort')}`;
      case SearchFilter.OPERATOR.LESS_OR_EQUAL:
        return `${this.translate.instant('eo.search.agg.time.span.until')} ${this.datePipe.transform(filter.firstValue, 'eoShort')}`;
      default:
        return `(${filter.operator}) ${this.datePipe.transform(filter.firstValue, 'eoShort')}`;
    }
  }

  resolveLabel(model: any, value: any[]) {
    model.label.forEach((l, i) => l.next(value[i].title));
  }

  resolveSelection(aggs: any[], key: string, selected: boolean) {
    return aggs
      .filter(a => selected ? a.key !== key && a.selected : a.key === key || a.selected)
      .map(a => a.key);
  }

  resetExpertModeInput() {
    this.query.term = '';
    this.appSearchService.aggregate();
  }

  public toggleExpertMode() {
    if (this.typeGroupTree.length === 0) {
      this.typeGroupTree = this.appSearchService.buildTypeGroupTree();
    }
    this.appSearchService.toggleExpertMode();
    this.cd.detectChanges();
    this.changeSearchInputFocus();
  }

  public setQueryScope(scope: QueryScope) {
    this.appSearchService.setQueryScope(scope);
  }

  public xpSelectGroup(group) {
    this.expertModeSupport.selectedGroup = group;
    this.expertModeSupport.selectedType = null;
  }

  public xpSelectType(type) {
    this.expertModeSupport.selectedType = type;
  }

  public xpAddToTerm(str) {
    let term;
    if (!this.query.term) {
      term = str;
    } else {
      term = this.query.term + ' ' + str;
    }
    this.query.term = term + ':';
    this.expertInput.nativeElement.focus();
  }

  public toggleType(_type: ObjectType) {
    // do not directly toggle _type on the query, instead use the
    // searchService to update the query store
    this.appSearchService.toggleQueryType(_type);
  }

  public isTypeActive(_type: ObjectType) {
    return !!this.query.types.find(t => t.id === _type.id);
  }

  public isTypeSaved(_type: ObjectType) {
    return (!this.queryState.aggregations.type.get(_type.name) && this.queryState.lastChange === SearchQuery.BASE_PARAMS.TYPE);
  }

  getAggregationCount(_type: ObjectType) {
    return ((this.isTypeSaved(_type) ? this.aggregationsModel[SearchQuery.BASE_PARAMS.TYPE].get(_type.name) : this.queryState.aggregations.type.get(_type.name)) || '');
  }

  private buildFilter(filterType, operator, key) {
    if (key instanceof RangeValue) {
      return new SearchFilter(filterType, key.operator, key.firstValue, key.secondValue, {time: key.operator !== this.OPERATOR.EQUAL, timezone: false});
    } else {
      return new SearchFilter(filterType, operator, key);
    }
  }

  public setFilter(filterType: string, operator: string, key: any, form?: NgForm) {
    let filter = this.buildFilter(filterType, operator, key);
    let empty = this.isEmpty(filter);
    let activeFilter = this.query.filters.find(ff => ff.property === filter.property);
    let duplicate = this.isFilterActive(filterType, operator, key);

    if (empty && activeFilter && activeFilter.operator !== SearchFilter.OPERATOR.RANGE) {
      this.appSearchService.removeQueryFilter(filter);
    } else if (!empty && !duplicate) {
      this.appSearchService.addQueryFilter(filter);
    }
  }

  public toggleFilter(filterType: string, operator: string, key: any, selected: boolean) {
    let filter = this.buildFilter(filterType, operator, key);
    if (filterType === this.PARAMS.FILESIZE) {
      let folderFilter = this.buildFilter('folder', 'eq', false);
      this.appSearchService.toggleQueryFilters([filter, folderFilter], !selected);
    } else {
      this.appSearchService.toggleQueryFilter(filter, !selected);
    }
  }

  public isEmpty(value: any) {
    return (
      ((value instanceof RangeValue || value instanceof SearchFilter) &&
        this.isEmpty(value.firstValue) &&
        this.isEmpty(value.secondValue)) ||
      (value == null || value.length === 0)
    );
  }

  public isFilterActive(filterType: string, operator: string, key: any) {
    let f = this.buildFilter(filterType, operator, key);
    return !!this.query.filters.find(ff => ff.match(f.property, f.operator, f.firstValue, f.secondValue));
  }

  public resetFilter(model: any, value: string) {
    if (model.innerValue instanceof Array && model.innerValue.length > 1) {
      let filter = this.buildFilter(model.filter.property, model.filter.operator, model.innerValue.filter(f => f !== value));
      this.appSearchService.addQueryFilter(filter);
    } else {
      this.appSearchService.removeQueryFilter(model.filter);
    }
  }

  public autocompleteSuggest(evt?) {
    setTimeout(() => {
      let v = [];
      // also trigger aggregations search
      this.autocompleteSelect();
      if (evt) {
        this.appSearchService
          .autocomplete(evt)
          .pipe(switchMap(res => observableFrom(res).pipe(pluck('value'))))
          .subscribe(res => v.push(res), () => { }, () => (this.autocompleteResults = v)
          );
      }
    }, 1000);
  }

  expertTermChanged() {
    this.expertModeQueryEmpty = false;
  }

  public autocompleteSelect() {
    this.loading = this.appSearchService.aggregate();
  }

  autocompletePaste(e) {
    setTimeout(() => {
      this.query.term = e.target.value;
      this.autocompleteSelect();
    }, 0);
  }

  public executeSearch() {
    this.expertModeQueryEmpty = false;
    if (this.query.expertMode) {
      // in expert search mode we pre-fetch results before executing the actual search
      this.appSearchService.getSearchState({aggs: {type: {}}, ...this.query.getQueryJson()}).subscribe((queryState: SearchState) => {
        // only execute the actual query when we've found something
        if (!queryState.isEmpty) {
          this.triggerSearch();
        } else {
          this.expertModeQueryEmpty = true;
        }
      }, Utils.throw(() => {
        this.logger.error('Error fetching search result');
        this.expertModeQueryEmpty = true;
      })
      );
    } else {
      this.triggerSearch();
    }
  }

  // execute the actual search by routing to the result state which then will execute the
  // query and display the results
  private triggerSearch() {
    this.query.resolveReference = true;
    const uriParam = encodeURIComponent(Utils.formDataStringify(this.query.getQueryJson()));
    this._overlayRef.close();
    this.router.navigate([{outlets: {modal: null}}]).then(() => {
      const uriParamQuery: NavigationExtras = {queryParams: {query: uriParam}};
      this.router.navigate(['/result'], uriParamQuery);
    });
  }

  public removeTerm() {
    this.appSearchService.setTerm('');
  }

  public removeType(type: ObjectType) {
    if (this.idxSearch) {
      this.exitIndexdataSearch();
    } else {
      this.appSearchService.toggleQueryType(type);
    }
  }

  public removeContextType() {
    this.appSearchService.setContextType(null);
  }

  public removeFilter(filter: SearchFilter) {
    this.appSearchService.removeQueryFilter(filter);

    // if we are in indesdata search mode, removing a filter that is no baseparam filter
    // requires the from model to be updated
    if (this.idxSearch && !this.appSearchService.isBaseParamFilter(filter)) {
      // todo: update form model/data
      let idxFilters = this.appSearchService.getIndexSearchQueryFilters();
      if (idxFilters && idxFilters.length > 0) {
        // set up form data from indexdata query filters
        this.idxSearch.formOptions.data = idxFilters;
        this.indexdataForm.setFormData(idxFilters);
      } else {
        // if no filters are present reset the form
        this.idxSearch.formOptions.data = {};
        this.indexdataForm.resetForm();
      }
    }
  }

  addContextModel(formModels): void {
    let models = [...formModels];
    this.idxSearch.contextModels.push(...models);
    this.idxSearch.contextModels.sort(Utils.sortValues('label'));
  }

  public exitIndexdataSearch() {
    this.appSearchService.exitIndexdataSearch();
    this.idxSearch = null;
  }

  setContext(contextFolderName: string) {
    if (contextFolderName && this.currentContextFolderName !== contextFolderName) {
      this.currentContextFolderName = contextFolderName;
      const contextType = this.systemService.getObjectType(contextFolderName);
      if (contextFolderName === ContextType.sysroot) {
        this.appSearchService.setContextType({name: ContextType.sysroot, label: ContextType.sysroot} as ObjectType);
      } else if (contextType) {
        this.appSearchService.setContextType(contextType);
      } else {
        this.logger.error(`Context type '${contextFolderName}' not found.`);
      }
    } else {
      this.appSearchService.setContextType(null);
      this.currentContextFolderName = null;
    }
  }

  /**
   * Selects a context type for the current search
   * @param ctx The context form model
   */
  private selectContext(ctx) {
    if (this.idxSearch.contextFormOptions && this.idxSearch.contextFormOptions.formModel.name === ctx.name) {
      this.appSearchService.setContextType(null);
    } else {
      let context;
      if (ctx.name === ContextType.sysroot) {
        context = ctx;
      } else {
        context = this.systemService.getObjectType(ctx.name);
        this.idxSearch.contextFolderType = context;
      }
      this.appSearchService.setContextType(context);

      let formData = {};
      let idxFilters = this.appSearchService.getIndexSearchQueryFilters();
      if (idxFilters && idxFilters.length > 0) {
        formData = idxFilters;
      }

      this.idxSearch.contextFormOptions = {formModel: ctx, data: formData};
    }
  }

  public onIndexDataChanged(event: FormStatusChangedEvent) {
    // only trigger when values are changed as changes may also appear when scripts
    // change other form element properties (eg. readonly, required flag)
    if (event.indexdataChanged) {
      this.updateSearchFilterFromFormData();
    }
  }

  private updateSearchFilterFromFormData() {
    if (!this.indexdataForm) {
      return;
    }

    // add metadata filters for each form data entry
    let idxFilters: SearchFilter[] = [];
    let formData = this.indexdataForm.getFormData();
    if (this.contextForm) {
      formData = Object.assign(formData, this.contextForm.getFormData());
    }

    for (let key of Object.keys(formData)) {
      const filters: SearchFilter[] = this.searchService.getSearchFilter(this.query.types, key, formData[key]).map(filter => {
        // the search service does not like newline chars in case of multiline fields
        if (Array.isArray(filter.firstValue)) {
          filter.firstValue = filter.firstValue.map(value => value.replace(/\n/g, ' '));
        } else if (typeof filter.firstValue === 'string') {
          filter.firstValue = filter.firstValue.replace(/\n/g, ' ');
        }
        return filter;
      });
      filters.forEach(f => idxFilters.push(f));
    }
    this.appSearchService.setIndexSearchQueryFilters(idxFilters);
  }

  /**
   * Gets the object type element for a filter, to be able to show the
   * label for a filter in the search summary.
   *
   * @param filter - the filter to fetch object type element for
   */
  public getObjectTypeElementLabel(filter: SearchFilter) {
    let ot = this.appSearchService.getTargetTypeElementByName(filter.property);
    return ot ? ot.label : filter.property + ' - ' + filter.firstValue;
  }

  public saveQuery() {
    // const queryParams: NavigationExtras = {queryParamsHandling : 'preserve'};
    this._overlayRef.close();
    this.router
      .navigate([{outlets: {modal: null}}], {replaceUrl: true})
      .then(() => this.router.navigate(['/stored-queries'], {queryParams: {create: true}}));
  }

  public updateStoredQuery() {
    if (this.query instanceof StoredQuery) {
      this.router.navigate([{outlets: {modal: null}}], {replaceUrl: true});
      this.storedQueriesService.saveStoredQuery(this.query).subscribe(res => {
        this._overlayRef.close();
        this.notifications.success(this.translate.instant('eo.search.storedquery.save.success'));
      }, Utils.throw(null, this.translate.instant('eo.search.storedquery.save.error')));
    }
  }

  public resetQuery() {
    this.appSearchService.reset();
    this.isOwnQuery = false;
    this.activeTabId = undefined;

    if (this.idxSearch) {
      this.exitIndexdataSearch();
    }
  }

  closeSearchMenu() {
    this._overlayRef.close();
  }

  private focusSearchInput() {
    if (this.searchInput) {
      // TODO: this.searchInput.inputEL.nativeElement.focus();
      this.cd.detectChanges();
    }
  }

  private changeSearchInputFocus() {
    if (this.query.expertMode) {
      this.renderer.selectRootElement('.expert-search__input').focus();
    } else {
      this.focusSearchInput();
    }
  }

  ngOnInit() {
    this.capabilities = this.capabilityService.getCapabilities();
    this.objectTypeGroups = this.appSearchService.objectTypeGroups;
    this.objectTypeGroups[0].types = this.objectTypeGroups[0].types.filter(type => type.qname !== 'sysfolder' && type.qname !== 'sysdocument' && type.qname !== 'sysobject');
    this.querySubscription = this.appSearchService.query$.subscribe(
      (query: SearchQuery) => this.processQuery(query));
    // subscribe to query state as well to fetch result count and aggregations as they change
    this.queryStateSubscription = this.appSearchService.queryState$.subscribe((queryState: SearchState) => this.processQueryState(queryState));
    this.appSearchService.aggregate();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.focusInput();
    });
    this._overlayRef = this.overlay.open(this.tplSearch, null, {
      centered: false
    });
    this._overlayRef.afterClosed$.subscribe({
      next: () => {
        const queryParams: NavigationExtras = {queryParamsHandling: 'preserve'};
        this.router.navigate([{outlets: {modal: null}}], queryParams);
      }
    })
  }

  ngOnDestroy() {
    this.appSearchService.resetAggs();
    this.querySubscription.unsubscribe();
    this.queryStateSubscription.unsubscribe();
  }
}
