import {LocaleDatePipe} from './../../../../../eo-framework-core/pipes/locale-date.pipe';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {DmsObject, CapabilitiesService, TranslateService} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {BackendService, NotificationsService, EventService, EnaioEvent, DmsService} from '@eo-sdk/core';

@Component({
  selector: 'eo-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit, ActionComponent {

  deleting = false;
  folder = '';
  count = '...';
  retentionTime: string;
  datePipe: LocaleDatePipe;
  showForceDeleteDialog = false;

  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private backend: BackendService,
              private toast: NotificationsService,
              private router: Router,
              private capabilitiesService: CapabilitiesService,
              private dmsService: DmsService,
              private elemRef: ElementRef,
              private eventService: EventService) {

    this.datePipe = new LocaleDatePipe(translate);
  }

  private isAllowedState(): boolean {
    const disabledStates = ['/result'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }

  deleteDmsObject(dmsObject: DmsObject, force = false) {
    const hardDelete: boolean = !this.capabilitiesService.hasCapability('recyclebin');
    this.dmsService
      .deleteDmsObject(dmsObject.id, dmsObject.type.name, hardDelete, force)
      .subscribe(() => {
          this.showForceDeleteDialog = false;
          const successMessage = this.capabilitiesService.hasCapability('recyclebin')
          ? this.translate.instant('eo.action.delete.dms.object.done.enabled.message', { title: dmsObject.title })
          : this.translate.instant('eo.action.delete.dms.object.done.message', { title: dmsObject.title });

        this.toast.success(this.translate.instant('eo.action.delete.dms.object.done.title'), successMessage);
          this.eventService.trigger(EnaioEvent.DMS_OBJECT_DELETED, this.selection[0]);

          if ((dmsObject.isContextFolder || !dmsObject.contextFolder) && this.isAllowedState()) {
            this.router.navigate(['/']);
          }

          this.finished.emit();
        }, (error) => {
          switch (error.status) {
            case 403:
              if (error.error.key === 'DMS_DOCUMENT_IN_PROCESS_FILE') {
                this.showForceDeleteDialog = true;
                break;
              }
              this.toast.error(this.translate.instant('eo.action.delete.dms.object.error.403'));
              this.finished.emit();
              break;
            case 409:
              this.toast.error(this.translate.instant('eo.action.delete.dms.object.error.409'));
              this.finished.emit();
              break;
            case 410:
              this.toast.error(this.translate.instant('eo.action.delete.dms.object.error.410'));
              this.finished.emit();
              break;
          }
        }
      );
  }

  run() {
    this.deleting = true;
    this.deleteDmsObject(this.selection[0] as DmsObject);
  }

  cancel() {
    this.canceled.emit();
  }

  isExpired(dateString: string): boolean {
    return new Date(dateString).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('.primary').focus();
    },);
  }

  ngOnInit() {
    if (this.selection[0].isContextFolder) {
      this.folder = this.selection[0].title;
      this.backend.get(`/count/${this.selection[0].id}`, this.backend.getContextBase())
        .subscribe(data => (this.count = typeof data.total === 'number' ? data.total : data.total.value));
    }
    this.dmsService.getDmsObjectByParams({id: this.selection[0].id, type: this.selection[0].typeName, withStorageInfo: true}).subscribe(res => {
      const latestRetentionTime = res.storageInfo.filter(info => info.retention).sort((a, b) => -a.retention.localeCompare(b.retention))[0]?.retention;
      if(!this.isExpired(latestRetentionTime)) {
        this.retentionTime = this.datePipe.transform(latestRetentionTime, 'eoShortDate');
      }
    });
  }
}
