import {Component, Input, OnInit, forwardRef, HostBinding} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlValueAccessor,
  Validator,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';
import {RangeValue, SearchFilter, TranslateService} from '@eo-sdk/core';
import {LocaleDatePipe} from '../../../eo-framework-core/pipes/locale-date.pipe';

@Component({
  selector: 'eo-datetime-range',
  templateUrl: './datetime-range.component.html',
  styleUrls: ['./datetime-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeRangeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatetimeRangeComponent),
      multi: true,
    }
  ],
  host: {
    class: 'form-element-range'
  }
})
export class DatetimeRangeComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() withTime: boolean;
  @Input() pickerTitle: string;
  @Input() operator = 'eq';

  @HostBinding('class.swap') swapInputs: boolean = false;

  rangeForm: UntypedFormGroup;
  value: RangeValue;
  private isValid = true;

  // options for search situation
  public availableSearchOptions = [
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.EQUAL), value: SearchFilter.OPERATOR.EQUAL},
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.GREATER_OR_EQUAL), value: SearchFilter.OPERATOR.GREATER_OR_EQUAL},
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.LESS_OR_EQUAL), value: SearchFilter.OPERATOR.LESS_OR_EQUAL},
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH), value: SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH}
  ];
  // the selected search option
  public searchOption: string;
  datePipe: LocaleDatePipe;

  constructor(private fb: UntypedFormBuilder, public translate: TranslateService) {
    this.datePipe = new LocaleDatePipe(translate);
  }

  createForm() {
    this.rangeForm = this.fb.group({
      dateValue: [],
      dateValueFrom: []
    });
  }

  onFormValueChange() {
    this.rangeForm.valueChanges.subscribe(() => this.onValueChange());
  }

  propagateChange = (_: any) => {}
  validationChange = () => {};

  writeValue(value: RangeValue): void {
    this._setValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.validationChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onValueChange() {

    let dateValue = this.formatDate(this.rangeForm.get('dateValue').value);
    if (this.searchOption === SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH) {
      let dateValueFrom = this.formatDate(this.rangeForm.get('dateValueFrom').value) || new Date().toISOString();

      if (dateValueFrom || dateValue) {
        this.isValid = this.rangeForm.valid && !!dateValueFrom && !!dateValue;
        this.swapInputs = dateValueFrom && dateValue && dateValueFrom > dateValue;
        this._setValue(new RangeValue(
          this.searchOption,
          this.swapInputs ? dateValue : dateValueFrom,
          this.swapInputs ? dateValueFrom : dateValue
        ));
        this.validationChange();
      }
    } else {
      this.swapInputs = false;
      this.isValid = this.rangeForm.valid;
      this._setValue(!this.isValid || !dateValue ? null : new RangeValue(this.searchOption, dateValue));
    }
    this.propagateChange(this.value);
  }

  private _setValue(value: RangeValue, emitEvent = false) {
    if (JSON.stringify(this.value) === JSON.stringify(value)) return;
    if (value && value instanceof RangeValue && (value.firstValue || value.secondValue)) {

      let match = this.availableSearchOptions.find((o) => o.value === value.operator);
      this.searchOption = match ? match.value : this.availableSearchOptions[0].value;

      const fV = value.firstValue ? new Date(value.firstValue) : null;
      const sV = value.secondValue ? new Date(value.secondValue) : null;
      this.value = value;

      if (!value.secondValue) {
        if (JSON.stringify(this.rangeForm.value.dateValue) !== JSON.stringify(fV)) {
          this.rangeForm.patchValue({
            dateValue: fV
          }, {emitEvent});
        }
      } else {
        if (JSON.stringify(this.rangeForm.value.dateValueFrom) !== JSON.stringify(fV)) {
          this.rangeForm.patchValue({
            dateValueFrom: fV
          }, {emitEvent});
        }
        if (JSON.stringify(this.rangeForm.value.dateValue) !== JSON.stringify(sV)) {
          this.rangeForm.patchValue({
            dateValue: sV
          }, {emitEvent});
        }
      }

    } else {
      this.value = null;
      this.rangeForm.reset();
    }
  }

  formatDate(value: Date): string {
    return !value ? null :
      this.withTime ? value.toISOString().replace(':00.000', '') : this.datePipe.transform(value, 'yyyy-MM-dd');
  }

  // returns null when valid else the validation object
  public validate(c: UntypedFormControl) {

    let err;
    if (this.searchOption === SearchFilter.OPERATOR.EQUAL) {
      err = {
        datecontrol: {
          valid: false,
        }
      };
    } else {
      err = {
        daterange: {
          valid: false,
        }
      };
    }
    return (this.isValid) ? null : err;
  }

  ngOnInit() {
    this.searchOption = this.operator;
    this.createForm();
    this.onFormValueChange();
  }
}
