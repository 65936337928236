import {Component, Input} from '@angular/core';
import {IFilterParams, RowNode} from '@ag-grid-community/core';
import {ListSettingsService} from '../../services/list-settings.service';
import {AbstractFilterComponent} from '../abstract-filter.component';
import {LocalStorageService, UserService, Utils} from '@eo-sdk/core';

@Component({
  selector: 'eo-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss']
})
export class TextFilterComponent extends AbstractFilterComponent {

  @Input() title = '';
  @Input() placeholder = 'eo.filter.input.placeholder';
  @Input() enableSave = false;
  private focused = false;
  savedFilters = [];

  @Input() set filterParams(params: IFilterParams) {
    super.filterParams = params;
  }

  @Input()
  set matchFields(fields: string[]) {
    this.valueGetter = (rowNode: RowNode) => fields.map(f => Utils.getProperty(rowNode.data, f)).join('\n');
    const value = this.defaultValue || this.settingsService.setupSettings(this.id, [{id: 'text', value: this.value}])[0].value;
    setTimeout(() => this.onChange(value), 0);
  }

  constructor(private settingsService: ListSettingsService,
              private storageService: LocalStorageService,
              private userService: UserService) {
    super();
    this.id = '#text';
    this.getSavedFilters();
  }

  onChange(newValue, oldValue?): void {
    if (this.value !== newValue) {
      this.value = newValue;
      if (!this.defaultValue) {
        this.settingsService.saveSettings(this.id, this.value ? {'text': this.value} : '');
      }
      this.params.filterChangedCallback();
    }
  }

  doesExternalFilterPass(rowNode: RowNode): boolean {
    const filterTerms = this.value.toLowerCase().split(' ');
    const rowValue = this.valueGetter(rowNode).toString().toLowerCase();
    const everyTermFound = !filterTerms.find(term => rowValue.indexOf(term) === -1);
    return everyTermFound;
  }

  reset() {
    super.reset();
    this.settingsService.saveSettings(this.id);
  }

  focusout () {
    this.focused = false;
  }

  getSavedFilters() {
    if (this.userService.getCurrentUser().userSettings.savedInboxFilter) {
      this.savedFilters = this.userService.getCurrentUser().userSettings.savedInboxFilter;
    } else {
      this.savedFilters = this.storageService.getItem('eo.inbox.filter.suggestions') ? this.storageService.getItem('eo.inbox.filter.suggestions') : [];
    }
  }

  saveFilter(term: string) {
    if (term && !this.savedFilters.includes(term)) {
      this.savedFilters.push(term);
      this.userService.changeSavedInboxFilters(this.savedFilters).subscribe();
    }
  }

  removeFilter(term: string) {
    this.savedFilters = this.savedFilters.filter(t => t !== term);
    this.userService.changeSavedInboxFilters(this.savedFilters).subscribe();
  }

  focus () {
    if (!this.focused) {
      this.focused = true;

      // Timeout for cross browser compatibility (Chrome)
      setTimeout(() => { document.execCommand('selectall', false, null); });
    }
  }
}
