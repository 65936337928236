
<header *ngIf="widgetConfig?.title">
    <a>{{widgetConfig?.title}}</a>
</header>
<div class="iframe" *ngIf="widgetConfig?.url === '' ; else tpl">
</div>
<ng-template #tpl>
    <div class="container"  [style.overflow]="'hidden'">
        <iframe class="responsive-iframe" [src]="widgetConfig?.url | safeUrl" #iframe></iframe>
    </div>
</ng-template>

