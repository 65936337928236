import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[eoFormElementTemplate]'
})
export class FormElementTemplate {

  @Input() propertyType: string;
  constructor(public template: TemplateRef<any>) { }

}
