import {AfterViewInit, Directive, ElementRef, Renderer2, Input} from '@angular/core';
import {UserService, EoUser} from '@eo-sdk/core';

/**
 * Apply this directive to elements or components that should be aware of the apps direction.
 * This can be either 'ltr' or 'rtl'. Using this directive will add a class of 'ltr' or 'rtl'
 * to the host element, which then can be used to apply special stylings for each direction.
 */

@Directive({
  selector: '[eoRtlAware]'
})
export class RtlAwareDirective implements AfterViewInit {

  // define what to do: Choose from `class`, `dir` or `full`
  // `class` (default): set the direction as a css class
  // `dir`: set the direction as dir attribute
  // `full`: set dir attribute as well as class
  @Input() eoRtlAware: string;

  constructor(private _elementRef: ElementRef,
              private _renderer: Renderer2,
              private userService: UserService) {
  }

  // set direction as dir attribute on the html element
  private setDir(dir: string) {
    this._renderer.setAttribute(this._elementRef.nativeElement, 'dir', dir);
  }

  // add direction as a css class on the html element
  private setClass(dir: string) {
    if (dir === 'rtl') {
      this._renderer.addClass(this._elementRef.nativeElement, 'rtl');
    } else {
      this._renderer.removeClass(this._elementRef.nativeElement, 'rtl');
    }
  }

  ngAfterViewInit() {
    this.userService.user$.subscribe((user: EoUser) => {

      switch (this.eoRtlAware) {
        case 'dir': {
          this.setDir(user.uiDirection);
          break;
        }
        case 'full': {
          this.setDir(user.uiDirection);
          this.setClass(user.uiDirection);
          break;
        }
        default: {
          this.setClass(user.uiDirection);
        }
      }

    });
  }

}
