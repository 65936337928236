import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ObjectStateComponent} from './object-state/object-state.component';
import {PendingChangesGuard} from '../../eo-framework-core/pending-changes/pending-changes-guard.service';
import {AuthGuard} from '../../eo-framework-core/route-guards/auth/auth-guard.service';
import {UploadOverlayGuard} from '../../eo-framework/upload-overlay/upload-overlay-guard.service';

const routes: Routes = [{
  path: 'object/:id',
  component: ObjectStateComponent,
  canActivate: [AuthGuard, UploadOverlayGuard], canDeactivate: [PendingChangesGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObjectStateRoutingModule {
}
