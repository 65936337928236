import {of as observableOf, Observable} from 'rxjs';
import {Component, Input} from '@angular/core';
import {WorkflowComponent} from './workflow/workflow.component';
import {DmsObjectTarget} from '../../action-target';
import {SelectionRange} from '../../selection-range.enum';
import {DmsObject, ExecutableProcess} from '@eo-sdk/core';
import {ExternalProcessComponentAction} from '../../interfaces/action.interface';


@Component({
  selector: 'eo-workflow-action',
  template: ''
})
export class WorkflowActionComponent extends DmsObjectTarget implements ExternalProcessComponentAction {

  label: string;
  description: string;
  priority = 9999;
  iconSrc = 'assets/_default/svg/ic_bpm.svg';
  iconId: string;
  group = 'further';
  range = SelectionRange.MULTI_SELECT;
  extComponents = WorkflowComponent;
  _executableProcess: ExecutableProcess;

  @Input()
  set executableProcess(executableProcess: ExecutableProcess) {
    this._executableProcess = executableProcess;
    this.label = executableProcess.title;
    this.description = executableProcess.description;
    this.iconId = executableProcess.iconid;
  }

  get executableProcess() {
    return this._executableProcess;
  }

  constructor() {
    super();
  }

  isExecutable(element: DmsObject): Observable<boolean> {
    return observableOf(this.isAllowedType(element));
  }

  isAllowedType(item: DmsObject): boolean {
    const disabledType = ['sysemail'];
    const {typeName} = item;
    return !disabledType.some(type => typeName === type);
  }
}
