import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

/**
 * This directive traps the focus inside the element it is applied to.
 * When applied, a users can't "tab out" of the element.
 * You need at least two focusable children in the element for it to work properly.
 * See the querySelector in the trapFocus method to know which elements count as focusable.
 * Additionally you can add the attribute "focusable" to an element if you are unsure.
 */

@Directive({
  selector: '[eoTrapFocus]'
})
export class TrapFocusDirective {

  focusableSelectors = 'a[href], a, button, textarea, input[type="text"],' +
  'input[type="radio"], input[type="checkbox"], select, [focusable]';

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.trapFocus(this.el.nativeElement);
  }

  trapFocus(element: any) {
    let focusableElements = element.querySelectorAll(
      this.focusableSelectors
    );
    focusableElements = Array.from(focusableElements)
      .filter((el: any) => !el.disabled && !el.classList.contains('disabled'));
    const firstFocusableElement: any = focusableElements[0];
    const lastFocusableElement: any = focusableElements[focusableElements.length - 1];

    element.addEventListener('keydown', ((event: any) => {
      const isTabPressed = event.keyCode === 9; // tab key code
      if (!isTabPressed) return;

      if (event.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          event.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          event.preventDefault();
        }
      }
    }));
  }

  @HostListener('keydown', ['$event'])
  isArrowUpOrDownPressed(event: KeyboardEvent) {

    const key = event.key
    if (key === 'ArrowUp' || key === 'ArrowDown') {

      event.preventDefault();
      let focusableElements = this.el.nativeElement.parentElement.querySelectorAll(
        this.focusableSelectors
      );
      const currentIndex = Array.from(focusableElements).indexOf(document.activeElement)
      if (key === 'ArrowDown' && currentIndex < focusableElements.length - 1) {
        focusableElements[currentIndex + 1].focus();
      }
      else if (key === 'ArrowUp' && currentIndex > 0) {
        focusableElements[currentIndex - 1].focus();

      }
    }
  }
}
