import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BackendService, DmsObject,
  DmsParams, DmsService, EnaioEvent,
  EventService, SystemService, TranslateService, UserService, Utils
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {catchError} from 'rxjs/operators';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../eo-framework-core/empty-state/empty-state.service';
import {PendingChangesComponent} from '../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {CustomSortComponent} from '../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {SetFilterComponent} from '../../eo-framework/grid/extensions/filter/setFilters/set-filter.component';
import {ISetFilter} from '../../eo-framework/grid/extensions/filter/setFilters/set-filters.interface';
import {GridComponent} from '../../eo-framework/grid/grid.component';
import {INotificationState, IResubmission, ISubscription} from './notifications-state.interface';
@UntilDestroy()
@Component({
  selector: 'eo-notifications-state',
  templateUrl: './notifications-state.component.html',
  styleUrls: ['./notifications-state.component.scss']
})
export class NotificationsStateComponent implements OnInit, PendingChangesComponent, OnDestroy {

  gridOptions: GridOptions;
  sortFields: ColDef[];
  typeFilterFields: ISetFilter[];
  objectTypeFilterFields: ISetFilter[];
  selectedItems: DmsObject[];
  gridData;
  emptyState: EmptyState = EmptyStateService.defaultEmptyState();
  textFilterParams: any = {};

  @ViewChild('eoGrid') eoGrid: GridComponent;

  constructor(public selection: SelectionService,
    public translate: TranslateService,
    public system: SystemService,
    private pendingChanges: PendingChangesService,
    private empty: EmptyStateService,
    private backend: BackendService,
    private eventService: EventService,
    private dmsService: DmsService,
    private titleService: PageTitleService,
    public userService: UserService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.notifications.list.title'));
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  getNotificationData() {
    this.userService
      .getNotifications()
      .subscribe(([sub, reSub]: [ISubscription[], IResubmission[]]) => {
        const data = [...sub, ...reSub];
        this.gridData = data;
        this.updateGrid(this.gridData);
      });
  }

  refreshGrid() {
    this.getNotificationData();
  }

  parseDmsParams(data): DmsParams {
    return data && data.target ? { id: data.target.id, type: data.target.type } : null;
  }

  remove() {
    const focused = this.selection.getFocus();
    if (focused) {
      this.userService.removeNotifications(focused.id, focused.messagetype)
        .subscribe(val => this.eventService.trigger(EnaioEvent.DMS_OBJECT_DELETED, focused));
    }
  }

  removeSubscriptionAndResubmissions(){
    const focused = this.selection.getFocus();
    if (focused) {
      this.dmsService.getDmsObject(focused.target.id, null, null, null, true).subscribe(dmsObject => {
        this.dmsService.removeResubmissionsById(focused.target.id, dmsObject).pipe(catchError(Utils.catchSkip(err => err.status === 404))).subscribe();
        this.dmsService.removeSubscriptionById(dmsObject.subscriptions[0].id, dmsObject).pipe(catchError(Utils.catchSkip(err => err.status === 404))).subscribe()
        this.eventService.trigger(EnaioEvent.DMS_OBJECT_DELETED, focused);
      });
    }
  }

  resetFilters() {
    this.typeFilters(this.gridData);
  }

  typeFilter(data) {
    const uniqueByType = SetFilterComponent.uniqBy(data, 'target.type', this.system.getObjectTypes());
    this.typeFilterFields = uniqueByType.map((type) => Object.assign({
      label: type.label,
      value: true,
      filter: (node) => node.data.target.type === type.qname,
      id: type.qname
    })).sort(Utils.sortValues('label'));
  }

  objectTypeFilter(data) {
    const uniqueByMessageType: INotificationState[] = SetFilterComponent.uniqByProperty(data, 'messagetype');
    this.objectTypeFilterFields = uniqueByMessageType.map(type => Object.assign({
      label: this.translate.instant(`eo.notifications.filter.${type.messagetype.toLowerCase()}.label`),
      value: true,
      filter: (node) => node.data.messagetype === type.messagetype,
      id: type.messagetype
    })).sort(Utils.sortValues('label'));
  }

  typeFilters(data) {
    this.typeFilter(data);
    this.objectTypeFilter(data);
  }

  updateGrid(data: INotificationState | any) {
    this.emptyState = this.empty.getEmptyState(data ? data.length : -1);

    if (this.eoGrid && this.eoGrid.isReady) {
      this.eoGrid.updateRowData(data);
      return this.resetFilters();
    }

    this.resetFilters();

    const colDefs: ColDef[] = [
      {
        headerName: '',
        field: '__custom',
        cellRenderer: this.cellRenderer,
        filter: false
      }];

    this.textFilterParams = { context: { defaultValue: '' } };
    this.sortFields = [
      { field: 'due', headerName: this.translate.instant('eo.notifications.sort.due'), sort: '' },
      { field: 'info', headerName: this.translate.instant('eo.notifications.sort.title'), sort: '' },
      { field: 'messagetype', headerName: this.translate.instant('eo.notifications.sort.type'), sort: '' }
    ];

    const sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);
    this.gridOptions = {
      context: { count: data.length },
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80
    };
  }

  cellRenderer(params) {

    const icon = params.context.cr.render('icon', params, {
      value: {
        url: `assets/_default/svg/ic_${params.data.messagetype.toLowerCase()}.svg`,
        label: params.context.translate.instant(`eo.notifications.filter.${params.data.messagetype.toLowerCase()}.label`)
      }
    });

    const folder = params.data.target.isfolder ? 'folder' : '';
    const createdTime = params.context.cr.render('dateTime', params, { value: params.data.due });

    return `<div class="list-item ${folder}" messagetype="${params.data.messagetype}" unselectable>
              ${icon}
              <div class="content">
                <div class="title">${params.data.info || ''}</div>
                <div class="meta">
                  <div class="description">
                    ${params.data.target.description}
                  </div>
                  <div class='description date'>
                    <span class="period">${createdTime}</span>
                  </div>
                </div>
              </div>
            </div>`;
  }

  ngOnInit() {
    this.refreshGrid();
    this.eventService
      .on(EnaioEvent.DMS_OBJECT_UPDATED)
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        let removedEntries = [];
        this.gridData.forEach((entry, i) => {
          if (entry.target.id === event.data.id) {
            if (entry.subscribedactions) {// if subscription entry
              if (!event.data.subscriptions.find(s => s.id === entry.id)) {
                removedEntries.push(entry);
              }
            } else {
              if (!event.data.resubmissions.find(s => s.id === entry.id)) {
                removedEntries.push(entry);
              }
            }
          }
        });
        event.data.resubmissions.concat(event.data.subscriptions).forEach(item => {
          const match = this.gridData.find(gD => gD.id === item.id);
          if (!match) {
            item.target = {
              description: event.data.description,
              id: event.data.id,
              isfolder: event.data.isfolder,
              title: event.data.title,
              type: event.data.typeName
            };
            this.gridData.push(item);
          }
        });
        this.gridData = this.gridData.filter(entry => !removedEntries.find(rEntry => rEntry.id === entry.id));
        if (this.eoGrid && this.eoGrid.isReady) {
          this.eoGrid.updateRowData(this.gridData);
          this.eoGrid.selectRow(this.gridData.length - 1, [this.gridData.length - 1]);
        }
      });

    this.eventService
      .on(EnaioEvent.DMS_OBJECT_DELETED)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (this.eoGrid) {
          this.eoGrid.updateRows(this.backend.update([res.data], [{ id: res.data.id }]));
        }
      })
  }

  ngOnDestroy() {
    this.selection.clear();
  }
}
