import {ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppSearchService} from './search/app-search.service';
import {LocationService} from './location/location.service';
import {SelectionService} from './selection/selection.service';
import {PluginsService} from './api/plugins.service';
import {GridService} from './api/grid.service';
import {LayoutService} from './layout/layout.service';
import {AgentService} from './agent/agent.service';
import {ReferenceService} from './references/reference.service';
import {PendingChangesGuard} from './pending-changes/pending-changes-guard.service';
import {PendingChangesService} from './pending-changes/pending-changes.service';
import {ErrorHandlerService} from './error-handler/error-handler.service';
import {EmptyStateService} from './empty-state/empty-state.service';
import {AuthGuard} from './route-guards/auth/auth-guard.service';
import {CapabilitiesGuard} from './route-guards/capabilities/capabilities-guard.service';
import {PageTitleService} from './title/page-title.service';
import {UploadOverlayGuard} from '../eo-framework/upload-overlay/upload-overlay-guard.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AppSearchService,
    AuthGuard,
    PluginsService,
    GridService,
    LocationService,
    SelectionService,
    LayoutService,
    CapabilitiesGuard,
    PendingChangesService,
    PendingChangesGuard,
    AgentService,
    UploadOverlayGuard,
    ReferenceService,
    EmptyStateService,
    PageTitleService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerService,
      multi: true
    },
    {
      // provide a error handling for the current platform
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ]
})
export class EoFrameworkCoreModule {

  static forRoot(): ModuleWithProviders<EoFrameworkCoreModule> {
    return {
      ngModule: EoFrameworkCoreModule,
      providers: []
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: EoFrameworkCoreModule) {
    if (parentModule) {
      throw new Error(
        'EoFrameworkCoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
