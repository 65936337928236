<yvc-split-view [layoutSettingsID]="'favorite.state'">

  <ng-template yvcSplitArea [size]="30">
    <eo-list-container #eoList [applySelection]="{}" [parseDmsParams]="parseDmsParams">
      <div class="eo-header">
        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_favorite.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.favorites.list.title</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
            (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"
            [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter class="overlay-filter" [iconSrc]="'assets/_default/svg/ic_filter.svg'"
            [iconTitle]="'eo.list.filter' | translate">
            <eo-custom-filter #cFilter (change)="cFilter.updateGrid(eoList.eoGridSubject, $event)"
              (active)="oFilter.onActiveChanged($event)">
              <eo-text-filter [title]="'eo.favorites.filter.title' | translate" [id]="'favorites.text'"
                [matchFields]="['title']" [filterParams]="textFilterParams">
              </eo-text-filter>
              <eo-set-filter [operator]="'OR'" [id]="'favorites.set'"
                [title]="'eo.favorites.filter.section.objecttype' | translate"
                [options]="typeFilterFields"></eo-set-filter>
            </eo-custom-filter>
          </eo-overlay>

          <eo-overlay #oSort class="overlay-sort" [iconSrc]="'assets/_default/svg/ic_sort.svg'"
            [iconTitle]="'eo.list.sort' | translate">
            <eo-custom-sort #cSort [id]="'favorites.sort'" (change)="cSort.updateGrid(eoList.eoGrid, $event)"
              (active)="oSort.onActiveChanged($event)" [sortFields]="sortFields"></eo-custom-sort>
          </eo-overlay>

          <eo-icon class="button" (click)="hasContent ? eoList.showActions() : false"
            [ngClass]="{'disabled': !hasContent, 'actions-button': hasContent}"
            [iconTitle]="'eo.object.actions.title' | translate" [iconSrc]="'assets/_default/svg/ic_more.svg'"></eo-icon>
        </div>
      </div>
      <div class="eo-body">
        <eo-grid #eoGrid *ngIf="gridOptions?.rowData" [gridOptions]="gridOptions" [fullWidth]="true" [showHeader]="true"
          [showFooter]="true" (eoGridCountChanged)="eoList.onCountChanged($event)"
          (eoGridContextMenuClick)="eoList.onContextMenu($event, 'DMS_OBJECT')"
          (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
          (eoGridDoubleClick)="eoList.onDoubleClick($event)" (eoGridFocusChanged)="eoList.onFocusChanged($event)">

          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
          </div>

          <div class="empty">
            <eo-error-message
              [emptyState]="{icon: 'ic_favorite.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.favorites.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active"
              (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"></eo-reset-filter>
          </div>

        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>

  <ng-template yvcSplitArea [size]="70">
    <eo-object-details [applySelection]="{}" [parseDmsParams]="parseDmsParams" [emptyState]="emptyState"
      [cacheLayout]="'favorite.state.object-details'" (hasContent)="hasContent = $event"
      [plugins]="'object-details-tab.favorite' | plugins">
      <div class="error" *ngIf="gridData?.length && !hasContent">
        <button class="button" (click)="remove()" translate>eo.favorite.details.item.gone.remove</button>
      </div>
    </eo-object-details>
  </ng-template>
</yvc-split-view>