import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SearchQuery} from '@eo-sdk/core';
import {IdxSearch} from '../app-search/app-search.interface';

@Component({
  selector: 'eo-context-search',
  templateUrl: './context-search.component.html',
  styleUrls: ['./context-search.component.scss']
})
export class ContextSearchComponent {

  @Input() ctx;
  @Input() query: SearchQuery;
  @Input() idxSearch: IdxSearch;
  @Input() selected = null;
  @Input() disabled = null;

  @Output() setContext: EventEmitter<String> = new EventEmitter<String>();

  get isSelected() {
    return this.selected;
  }

  get isDisabled() {
    return this.disabled || this.noResults;
  }

  get noResults() {
    const contextAggregations = this.idxSearch ? this.idxSearch.contextAggregations : null;
    const contextFolderTypes = this.query ? this.query.contextFolderTypes : null;
    return contextAggregations && !contextAggregations[this.ctx.name] && !(contextFolderTypes && contextFolderTypes.length)
  }

  settingContext(name) {
    if (!this.noResults) {
      this.setContext.emit(name);
    }
  }
}
