
import {of as observableOf, Observable} from 'rxjs';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {TranslateService, DmsObject, CapabilitiesService} from '@eo-sdk/core';
import {ComponentAction} from '../../interfaces/action.interface';
import {DeleteComponent} from './delete/delete.component';
import {Router} from '@angular/router';

@Component({
  selector: 'eo-delete',
  template: ``
})
export class DeleteActionComponent extends DmsObjectTarget implements ComponentAction {
  header: string;
  label: string;
  description: string;
  priority = 7;
  iconSrc = 'assets/_default/svg/ic_trash.svg';
  group = 'common';
  range = SelectionRange.SINGLE_SELECT;
  component = DeleteComponent;

  constructor(private translate: TranslateService, private router: Router, private capabilitiesService: CapabilitiesService) {
    super();
    this.label = this.translate.instant('eo.action.delete.dms.object.label');
    this.description = this.translate.instant('eo.action.delete.dms.object.description');
  }

  isExecutable(element: DmsObject) {
    const isLocked = element.lock && element.lock.by.other;
    return observableOf(element && !element.isFinalized && element.rights
      && (this.capabilitiesService.hasCapability('recyclebin') ? element.rights.recycle : element.rights.delete)
      && !isLocked && this.isAllowedState());
  };

  isAllowedState() {
    const disabledStates = ['/favorites', '/inbox', '/process', '/versions', '/notifications'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }

}
