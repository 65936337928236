import {Component, forwardRef, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validator,
  UntypedFormControl,
  NG_VALIDATORS
} from '@angular/forms';
import {SearchService, Utils} from '@eo-sdk/core';
import {AutoComplete} from '@yuuvis/components/autocomplete';

@Component({
  selector: 'eo-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferenceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReferenceComponent),
      multi: true
    }
  ]
})
export class ReferenceComponent implements ControlValueAccessor, Validator {

  @ViewChild('autocomplete') autoCompleteInput: AutoComplete;

  @Input() readonly: boolean;
  @Input() multiselect: boolean;
  /**
   * Property to search for, aswell as the object type
   */
  @Input() reference: {element: string, type: string};
  @Input() minLength: number;
  @Input() maxLength: number;

  @Output() onReferenceClicked = new EventEmitter();

  autocompleteRes: any[];
  // model value
  private value;
  // inner ng-model value
  innerValue: {value: string, title: string}[] = [];

  constructor(private searchService: SearchService) {
  }

  propagateChange = (value: any) => {
  }

  tranformValue(valueIsArray, value) {
    return valueIsArray ? value.map(v => {
      return {
        value: v,
        title: null
      }
    }) : [{
      value: value,
      title: null
    }];
  }

  writeValue(value: any): void {

    if (value) {
      const valueIsArray = Array.isArray(value);
      this.value = valueIsArray ? Array.from(new Set(value)) : value;

      // transform value to inner value
      this.innerValue = this.tranformValue(valueIsArray, this.value);
    } else {
      this.value = null;
      this.innerValue = [];
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  autocompleteResult(res = null, query = '') {
    const value = this.value ? this.value : [];
    if (res && res.hits && res.hits.total.value) {
      const results = res.hits.hits
        .filter(h => !value.includes(h._source[this.reference.element]))
        .map(h => ({value: h._source[this.reference.element], title: h._source.title}));
      this.autocompleteRes = Utils.uniqBy(results, 'value');
    } else {
      this.autocompleteRes = [];
    }
  }

  autocompleteFn(term: string) {
    if (this.multiselect || !this.innerValue.length) {
      const {type, element} = this.reference;
      const q = {
        filters: {},
        types: [type],
        fields: [
          'title',
          element
        ],
        options: {
          sort: {
            title: {
              order: 'asc'
            }
          }
        }
      };

      // todo: add condition to remove the values that are already set when regex operator is supported
      q.filters[element] = {
        o: 'eq',
        v1: `${term}*`
      };

      this.searchService
        .executeQuery(q)
        .subscribe(res => this.autocompleteResult(res, term), () => this.autocompleteResult(null, term))
    } else {
      this.autocompleteResult(null, term);
    }
  }

  // handle selection changes to the model
  onUnselect(item) {
    this.innerValue = this.innerValue.filter(iv => iv.value !== item.value);
    this.value = this.multiselect ? Utils.uniqBy(this.innerValue, 'value').map(iv => iv.value) : null;
    if (!this.multiselect) {
      this.clearInnerInput();
    }
    this.propagateChange(this.value);
  }

  // handle selection changes to the model
  onSelect(selectedItem) {

    if (typeof selectedItem === 'string') selectedItem = {
      value: selectedItem,
      title: selectedItem
    }

    this.innerValue.push(selectedItem);
    this.innerValue = Utils.uniqBy(this.innerValue, 'value');

    if (this.multiselect) {
      this.value = this.innerValue.map(iv => iv.value);
    } else {
      this.value = this.innerValue[0].value;
    }
    this.propagateChange(this.value);
  }

  onAutoCompleteBlur() {
    this.clearInnerInput();
  }

  onKeyUpEnter(event) {
    const input = event.target.value.trim();
    if (input.length) {
      this.onSelect({value: input, title: input});
    }
    this.clearInnerInput();
  }

  private clearInnerInput() {
    this.autoCompleteInput.clearInput();
    this.autocompleteResult();
  }

  getItemLinkParams(item) {
    const query = {
      types: [this.reference.type],
      filters: {}
    };
    query.filters[this.reference.element] = {
      o: 'eq',
      v1: item.value
    };
    return {
      query: encodeURIComponent(JSON.stringify(query))
    };
  }

  // returns null when valid else the validation object
  public validate(c: UntypedFormControl) {
    let err;

    if (this.value && this.value !== null) {
      if (this.multiselect) {
        if (this.value.length > 0 && !!this.value.find(v => v.length < this.minLength)) {
          err = {};
          err['minlength'] = {
            valid: false
          }
        }
        if (this.value.length > 0 && !!this.value.find(v => v.length > this.maxLength)) {
          err = {};
          err['maxlength'] = {
            valid: false
          }
        }
      } else {
        if (this.value.length > 0 && this.value.length < this.minLength) {
          err = {};
          err['minlength'] = {
            valid: false
          }
        }
        if (this.value.length > this.maxLength) {
          err = {};
          err['maxlength'] = {
            valid: false
          }
        }
      }
    }
    return err ? err : null;
  }
}
