<header class="filter__header">
  <h3 class="filter__title title" *ngIf="title">{{title}}</h3>
</header>
<div class="flex-row">
  <eo-form-input>
    <input (ngModelChange)="onChange($event)" [ngModel]="value" class="form-control"
           placeholder="{{ placeholder | translate}}" (focus)="focus()" (focusout)="focusout()">
  </eo-form-input>
  <eo-icon class="save-filter-icon" *ngIf="enableSave" [ngClass]="{'disable-save-filter': !value || savedFilters.includes(value)}"
           (click)="saveFilter(value)"
           [iconTitle]="'eo.filter.save' | translate"
           [iconSrc]="'assets/_default/svg/ic_add.svg'"></eo-icon>
</div>
<div *ngIf="enableSave && savedFilters && savedFilters.length !== 0">
  <h4 class="filter__title saved-filters-title" translate>eo.filter.save.title</h4>
  <div class="saved-filters-container">
    <div class="saved-filter flex-row" *ngFor="let savedFilter of savedFilters">
      <span class="saved-filter-text" (click)="onChange(savedFilter, value)">{{savedFilter}}</span>
      <eo-icon class="remove-filter-icon" (click)="removeFilter(savedFilter)"
               [iconTitle]="'eo.filter.delete' | translate"
               [iconSrc]="'assets/_default/svg/ic_trash.svg'"></eo-icon>
    </div>
  </div>
</div>
