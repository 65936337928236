import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'eo-simple-accordion',
  templateUrl: './simple-accordion.component.html',
  styleUrls: ['./simple-accordion.component.scss'],
})
export class SimpleAccordionComponent {

  @HostBinding('attr.role') role = 'tablist';
  _selected: boolean;

  @Input() header: string;
  @Input() styles: string;
  @Input() headerClass: string;

  @HostBinding('class.selected')
  @Input()
  set selected(val: boolean) {
    this._selected = val;
  }

  get selected(): boolean {
    return this._selected;
  }

  @Output() selectChange: EventEmitter<boolean> = new EventEmitter();
  // index: number = null;
  // lastIndex = -1;

  toggleOpen() {
    this.selected = !this.selected;
    this.selectChange.emit(this.selected);
  }

  // onTabOpen(e) {
  //   const index = e.index;
  //   this.selected = true;
  //   this.onOpen.emit(this.selected);
  // }

  // onTabClose(e = false) {
  //   this.index = this.lastIndex--;
  //   this.selected = false;
  //   this.onOpen.emit(this.selected);
  // }
}
