import {Component, Input} from '@angular/core';

@Component({
  selector: 'eo-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss']
})
export class CtaComponent {

  @Input() active = false;
}
