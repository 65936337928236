import {Component, forwardRef, HostBinding, Input} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS
} from '@angular/forms';
import {RangeValue, SearchFilter} from '@eo-sdk/core';

@Component({
  selector: 'eo-number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberRangeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberRangeComponent),
      multi: true,
    }
  ],
  host: {
    class: 'form-element-range'
  }
})
export class NumberRangeComponent implements ControlValueAccessor, Validator {

  @Input() scale;
  @Input() precision;
  @Input() grouping;
  @Input() pattern;
  @Input() readonly: boolean;

  @HostBinding('class.swap') swapInputs: boolean = false;

  public rangeForm = new UntypedFormGroup({
    numberValue: new UntypedFormControl(),
    numberValueFrom: new UntypedFormControl()
  });

  value: RangeValue;
  private isValid = true;

  // options for search situation
  public availableSearchOptions = [
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.EQUAL), value: SearchFilter.OPERATOR.EQUAL},
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.GREATER_OR_EQUAL), value: SearchFilter.OPERATOR.GREATER_OR_EQUAL},
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.LESS_OR_EQUAL), value: SearchFilter.OPERATOR.LESS_OR_EQUAL},
    {label: RangeValue.getOperatorLabel(SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH), value: SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH}
  ];
  // the selected search option
  public searchOption = this.availableSearchOptions[0].value;

  constructor() {
    this.rangeForm.valueChanges.forEach(() => {
        this.onValueChange();
      }
    );
  }

  propagateChange = (_: any) => {
  }

  writeValue(value: RangeValue): void {
    if (value && value instanceof RangeValue && (value.firstValue != null || value.secondValue != null)) {

      let match = this.availableSearchOptions.find((o) => o.value === value.operator);
      this.searchOption = match ? match.value : this.availableSearchOptions[0].value;

      this.value = value;
      if (value.secondValue == null) {
        this.rangeForm.setValue({
          numberValueFrom: null,
          numberValue: value.firstValue ? value.firstValue : null
        });
      } else {
        this.rangeForm.setValue({
          numberValueFrom: value.firstValue ? value.firstValue : null,
          numberValue: value.secondValue ? value.secondValue : null
        });
      }

    } else {
      this.value = null;
      this.rangeForm.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onValueChange() {

    this.isValid = this.rangeForm.valid;
    if (this.searchOption === SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH) {
      this.isValid = this.rangeForm.valid && this.rangeForm.get('numberValueFrom').value != null && this.rangeForm.get('numberValue').value != null;
      
      const fromVal = this.rangeForm.get('numberValueFrom').value;
      const toVal = this.rangeForm.get('numberValue').value;      
      this.swapInputs = fromVal && toVal && parseFloat(fromVal) > parseFloat(toVal);      
      this.value = !this.isValid ? null : new RangeValue(
        this.searchOption,
        this.swapInputs ? toVal : fromVal,
        this.swapInputs ? fromVal : toVal
      );
    } else {
      this.swapInputs = false;
      this.value = !this.isValid || this.rangeForm.get('numberValue').value === null ? null : new RangeValue(
        this.searchOption,
        this.rangeForm.get('numberValue').value
      );
    }
    this.propagateChange(this.value);
  }

  // returns null when valid else the validation object
  public validate(c: UntypedFormControl) {
    let err;
    if (this.searchOption === SearchFilter.OPERATOR.EQUAL) {
      err = {
        number: {
          valid: false,
        }
      };
    } else {
      err = {
        numberrange: {
          valid: false,
        }
      };
    }
    return (this.isValid) ? null : err;
  }
}
