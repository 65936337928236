<yvc-split-view [layoutSettingsID]="'version.state'">
  <!-- list of versions -->
  <ng-template yvcSplitArea [size]="30">
    <eo-list-container #eoList [applySelection]="{}" [parseDmsParams]="parseDmsParams">
      <div class="eo-header">
        <eo-icon class="eo-header-icon" [iconSrc]="'assets/_default/svg/ic_format_list_numbered.svg'"></eo-icon>
        <div class="eo-header-info">
          <div class="eo-header-title" translate>eo.versions.list.title</div>
          <div class="eo-header-subtitle">{{versionItem?.title}}</div>
        </div>
        <div class="eo-header-actions">
          <eo-icon class="button primary refresh-button" [iconSrc]="'assets/_default/svg/ic_refresh.svg'"
                   (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"
                   [iconTitle]="('eo.list.refresh' | translate)"></eo-icon>

          <eo-overlay #oFilter [iconSrc]="'assets/_default/svg/ic_filter.svg'" [iconClass]="'primary'" class="overlay-filter"
                      [iconTitle]="'eo.list.filter' | translate">
            <eo-custom-filter #cFilter
                              (change)="cFilter.updateGrid(eoList.eoGrid, $event)"
                              (active)="oFilter.onActiveChanged($event)">
              <!--<eo-text-filter [title]="'eo.versions.filter.title' | translate" [matchFields]="['title']"></eo-text-filter>-->
              <eo-set-filter [operator]="'OR'" [title]="'eo.versions.list.users' | translate"
                             [options]="userFilterFields" [id]="'versions.user'"></eo-set-filter>
              <eo-set-filter [operator]="'OR'" [title]="'eo.versions.list.types' | translate"
                             [options]="typeFilterFields" [id]="'versions.type'"></eo-set-filter>
            </eo-custom-filter>
          </eo-overlay>

          <eo-overlay #oSort [iconSrc]="'assets/_default/svg/ic_sort.svg'" [iconClass]="'primary'" class="overlay-sort"
                      [iconTitle]="'eo.list.sort' | translate">
            <eo-custom-sort #cSort
                            (change)="cSort.updateGrid(eoList.eoGrid, $event)"
                            (active)="oSort.onActiveChanged($event)"
                            [sortFields]="sortFields" [id]="'versions.sort'"></eo-custom-sort>
          </eo-overlay>

          <eo-icon class="button actions-button" (click)="eoList.showActions()" [iconTitle]="'eo.object.actions.title' | translate"
                   [iconSrc]="'assets/_default/svg/ic_more.svg'"></eo-icon>
        </div>
      </div>
      <div class="eo-body">
        <eo-grid #eoGrid [gridOptions]="gridOptions" *ngIf="gridOptions?.rowData"
                 [fullWidth]="true"
                 [showHeader]="true"
                 [showFooter]="true"
                 [selectionLimit]="2"
                 (eoGridCountChanged)="eoList.onCountChanged($event)"
                 (eoGridSelectionChanged)="eoList.onSelectionChanged($event)"
                 (eoGridFocusChanged)="eoList.onFocusChanged($event)">

          <div class="header">
          </div>

          <div class="footer">
            <eo-total-count [gridCount]="eoList.gridCount" [outsideGrid]="false" class="flex-row"></eo-total-count>
          </div>

          <div class="empty">
            <eo-error-message
              [emptyState]="{icon: 'ic_no-file.svg', text: oFilter.active ? 'eo.no.filter.result' : 'eo.versions.list.empty'}"></eo-error-message>
            <eo-reset-filter [isFilterActive]="oFilter.active" (click)="cFilter.resetFilter();cSort.reset();refreshGrid()"></eo-reset-filter>
          </div>
        </eo-grid>
      </div>
    </eo-list-container>
  </ng-template>

  <!-- version compare/details-->
  <ng-template yvcSplitArea [size]="70">
    <eo-object-details [enableCompare]="true" [applySelection]="{}" [parseDmsParams]="parseDmsParams"
                       [cacheLayout]="'version.state--' + typeName + '.object-details'" [plugins]="'object-details-tab.version' | plugins">
    </eo-object-details>
  </ng-template>
</yvc-split-view>
