import {Component, Input} from '@angular/core';

@Component({
  selector: 'eo-reset-filter',
  templateUrl: './reset-filter.component.html',
  styleUrls: ['./reset-filter.component.scss']
})
export class ResetFilterComponent {
  @Input() isFilterActive: boolean;
}
