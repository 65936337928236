import {Component, EventEmitter, Input, Output, HostListener, ViewChildren, QueryList, ElementRef, AfterViewInit, ViewChild} from '@angular/core';
import {Clipboard, SearchResult} from '@eo-sdk/core';
import {Subject} from 'rxjs';
import {fadeInOut} from '../animation/reference-finder.animation';
import {
  ReferenceItem,
  ReferenceSearchCounter
} from '../interface/reference-finder.interface';
import {ReferenceFinderService} from '../service/reference-finder.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'eo-reference-finder',
  templateUrl: './reference-finder.component.html',
  styleUrls: ['./reference-finder.component.scss'],
  animations: [fadeInOut]
})
export class ReferenceFinderComponent implements AfterViewInit {

  @ViewChildren('entry', {read: ElementRef}) entries: QueryList<ElementRef>;
  @ViewChild('searchInput') searchInput!: ElementRef;

  searchTerm$ = new Subject<string>();
  searchResultCount: ReferenceSearchCounter;
  selectedItems = [];
  resultList: ReferenceItem[] = [];
  private _currentSelection: ReferenceItem[];
  clipboardItems: ReferenceItem[] = [];
  clipboardOpen = true;
  _markedItem: ReferenceItem;
  searching = false;

  set markedItem(item: ReferenceItem) {
    this._markedItem = item;
    this.scrollItemIntoView(this._markedItem);
  }

  get markedItem() {
    return this._markedItem;
  }

  @Input() isDisabled: boolean;
  @Input() types: string;
  @Input() multiselect: boolean;
  @Input() contextId: string;
  @Input() exceptionIDs: string[];

  @Input() queryFilters: {[fieldQname: string]: {o: string, v1: any, v2: any}};

  @Input()
  set currentSelection(selection: ReferenceItem[]) {
    this._currentSelection = selection;
  }
  get currentSelection(): ReferenceItem[] {
    return this._currentSelection;
  }

  @Input()
  set clipboard(clipboard: Clipboard) {
    this.clipboardItems = this.referenceFinderService.generateReferenceItemsFromDmsObjects(clipboard.elements, this.currentSelection, this.isDisabled);
  }
  @Output() addDmsObjects: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('keydown', ['$event'])
  onKey(event) {
    if (event.which === 40) { // arrow down
      if (!this.markedItem && (this.clipboardItems.length || this.resultList.length)) {
        this.markedItem = (this.clipboardItems.length && this.clipboardOpen) ? this.clipboardItems[0] : this.resultList[0];
      } else {
        let index;
        if (this.clipboardItems && this.clipboardOpen) {
          index = this.clipboardItems.findIndex(item => item.id === this.markedItem.id);
          if (index >= 0) {
            index++;
            if (index === this.clipboardItems.length && !this.resultList.length) {
              index = 0;
              this.markedItem = this.clipboardItems[index]
            } else if (index === this.clipboardItems.length && this.resultList.length) {
              index = 0;
              this.markedItem = this.resultList[index];
            } else {
              this.markedItem = this.clipboardItems[index];
            }
          }
        }
        if ((index === undefined || index === -1) && this.resultList.length) {
          index = this.resultList.findIndex(item => item.id === this.markedItem.id);
          if (index >= 0) {
            index++;
            if (index === this.resultList.length && this.clipboardOpen) {
              index = 0;
              this.markedItem = this.clipboardItems[index]
            } else if (index === this.resultList.length && !this.clipboardOpen) {
              index = 0;
              this.markedItem = this.resultList[index];
            } else {
              this.markedItem = this.resultList[index];
            }
          } else {
            index = 0;
            this.markedItem = this.resultList[index];
          }
        }
      }
    }
    else if (event.which === 38) { // arrow up
      if (!this.markedItem && (this.clipboardItems.length || this.resultList.length)) {
        this.markedItem = (this.clipboardItems.length && this.clipboardOpen) ? this.clipboardItems[0] : this.resultList[0];
      } else {
        let index;
        if (this.clipboardItems && this.clipboardOpen) {
          index = this.clipboardItems.findIndex(item => item.id === this.markedItem.id);
          if (index >= 0) {
            index--;
            if (index === -1 && this.resultList.length) {
              index = this.resultList.length - 1;
              this.markedItem = this.resultList[index]
            } else if (index === -1 && !this.resultList.length) {
              index = this.clipboardItems.length - 1;
              this.markedItem = this.clipboardItems[index];
            } else {
              this.markedItem = this.clipboardItems[index];
            }
          }
        }
        if ((index === undefined || index === -1) && this.resultList.length) {
          index = this.resultList.findIndex(item => item.id === this.markedItem.id);
          if (index >= 0) {
            index--;
            if (index === -1 && this.clipboardOpen) {
              index = this.clipboardItems.length - 1;
              this.markedItem = this.clipboardItems[index]
            } else if (index === -1 && !this.clipboardOpen) {
              index = this.resultList.length - 1;
              this.markedItem = this.resultList[index];
            } else {
              this.markedItem = this.resultList[index];
            }
          } else {
            index = this.resultList.length - 1;
            this.markedItem = this.resultList[index];
          }
        }
      }
    }
    else if (event.which === 13 && event.ctrlKey) { // ctrl + enter
      if (this.markedItem && !this.markedItem.disabled) {
        this.selectReference({item: this.markedItem, add: true});
      }
    }
    else if (event.which === 13) { // enter
      if (this.markedItem && !this.markedItem.disabled) {
        this.selectReference({item: this.markedItem, add: !this.multiselect});
      }
    }
  }

  constructor(private referenceFinderService: ReferenceFinderService) {
    this.searching = true;
    this.searchTerm$.pipe(debounceTime(500)).subscribe(searchTerm => {
      this.referenceFinderService.findReferences(searchTerm, [this.types], this.contextId, this.queryFilters)
        .subscribe((searchResult: SearchResult) => {
          const hits = (this.exceptionIDs && searchResult.hits) ? searchResult.hits.filter(hit => !this.exceptionIDs.includes(hit.id)) : searchResult.hits;
          if (hits && hits.length) {
            this.clipboardOpen = false;
            this.resultList = this.referenceFinderService.generateResults(this.clipboardItems, hits, this.currentSelection, this.types);
          } else {
            this.searchTerm$.next('*');
            this.clipboardOpen = true;
          }
          this.markedItem = null;
          this.searching = false;
        });
    });
  }

  get loadingReferences(): boolean {
    return !!this.resultList.length;
  }

  get disableAdd(): boolean {
    return !this.selectedItems.length;
  }

  get copyOnly(): boolean {
    return this.clipboardItems.length && !this.resultList.length;
  }

  get searchOnly(): boolean {
    return this.resultList.length && !this.clipboardItems.length;
  }

  selectAllEntries(){
    [...this.clipboardItems, ...this.resultList].filter(item => !item.selected).forEach(item => this.selectReference({item: item, add: false}))
  }

  selectReference(selectReference: {item: ReferenceItem; add: boolean}) {
    const {item, add} = selectReference;
    if (item && !item.disabled) {
      if (this.multiselect) {
        !item.clipboard
          ? (this.resultList = this.referenceFinderService.selectOnMultisSelect(this.resultList, item))
          : (this.clipboardItems = this.referenceFinderService.selectOnMultisSelect(this.clipboardItems, item));
      } else {
        if (!item.clipboard) {
          this.resultList = this.referenceFinderService.selectOnSingleSelect(this.resultList, item);
          this.clipboardItems.forEach(item => item.selected = false);
        } else {
          this.clipboardItems = this.referenceFinderService.selectOnSingleSelect(this.clipboardItems, item);
          this.resultList.forEach(item => item.selected = false);
        }
      }

      this.selectedItems = this.referenceFinderService.generateSeletecItems(this.resultList, this.clipboardItems, this.currentSelection);
    }
    if (add) {
      this.addItems();
    }
  }

  addItems() {
    this.addDmsObjects.emit(this.selectedItems);
  }

  private scrollItemIntoView(item){
    if (item) {
      let index = this.clipboardItems.findIndex(i => i.id === item.id);
      if (index === -1 && this.resultList.length) {
        index = this.resultList.findIndex(i => i.id === item.id) + this.clipboardItems.length;
      }
      this.entries.toArray()[index].nativeElement.scrollIntoView(false);
    } else if (this.entries.toArray().length) {
      this.entries.toArray()[0].nativeElement.scrollIntoView(false);
    }
  }

  ngAfterViewInit() {
    // initially load everything and focus into the search field
    this.searchTerm$.next('*');
    this.searchInput.nativeElement.focus();
  }
}
