<div class="eo-form-string">

  <input *ngIf="!multiline && !multiselect && !autocomplete" type="text" (blur)="onBlur()" [readonly]="readonly"
    [disabled]="readonly" [(ngModel)]="value" (ngModelChange)="onValueChange($event)" />

  <!-- single line input with autocomplete -->
  <yvc-autocomplete *ngIf="!multiline && !multiselect && autocomplete" #autocomplete [(ngModel)]="value"
    [disabled]="readonly" [autocompleteValues]="autocompleteRes" (blur)="onBlur()"
    (suggestionSelect)="onValueChange($event)" (ngModelChange)="onValueChange($event)"
    (autocompleteFnc)="autocompleteFn($event)">
  </yvc-autocomplete>

  <!-- single line input with multiselect-->
  <yvc-chips *ngIf="!multiline && multiselect && !autocomplete" [(ngModel)]="value" [disabled]="readonly"
    (blur)="onBlur()" (ngModelChange)="onValueChange($event)">
  </yvc-chips>

  <!-- single line input with multiselect and autocomplete -->
  <yvc-autocomplete *ngIf="!multiline && multiselect && autocomplete" [autocompleteValues]="autocompleteRes"
    [multiple]="true" [disabled]="readonly" (blur)="onBlur()" (autocompleteFnc)="autocompleteFn($event)"
    [(ngModel)]="value" (ngModelChange)="onValueChange($event)">
  </yvc-autocomplete>

  <!-- multi line text inputs -->
  <textarea class="input-textarea size-{{size}}" *ngIf="multiline" (blur)="onBlur()" [(ngModel)]="value"
    [readonly]="readonly" [disabled]="readonly" (ngModelChange)="onValueChange($event)"></textarea>

  <div class="classify" [ngClass]="{empty: !value || situation === 'SEARCH'}">
    <a target="_blank" href="mailto:{{formatedValue}}" *ngIf="classification === 'email' && valid">
      <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
        <path
          d="M180.309-212.001q-27.008 0-45.658-18.662-18.65-18.662-18.65-45.686v-407.626q0-27.024 18.65-45.524t45.658-18.5h599.382q27.008 0 45.658 18.662 18.65 18.662 18.65 45.686v407.626q0 27.024-18.65 45.524t-45.658 18.5H180.309ZM480-449.694 168-633.309v357q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462h599.382q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847v-357L480-449.694ZM480-517l305.846-179H174.154L480-517ZM168-633.309V-696-276.309q0 5.385 3.462 8.847 3.462 3.462 8.847 3.462H168v-369.309Z" />
      </svg>
    </a>
    <a target="_blank" href="{{value}}" *ngIf="classification === 'url' && valid">
      <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
        <path
          d="M480-116.001q-75.154 0-141.499-28.462-66.346-28.461-115.962-78.076-49.615-49.616-78.076-115.962Q116.001-404.846 116.001-480q0-75.769 28.462-141.807 28.461-66.038 78.076-115.654 49.616-49.615 115.962-78.076Q404.846-843.999 480-843.999q75.769 0 141.807 28.462 66.038 28.461 115.654 78.076 49.615 49.616 78.076 115.654Q843.999-555.769 843.999-480q0 75.154-28.462 141.499-28.461 66.346-78.076 115.962-49.616 49.615-115.654 78.076Q555.769-116.001 480-116.001Zm0-53.845q21.616-21.616 40.539-70.424 18.923-48.808 29.077-108.808H410.384q10.923 61.539 29.462 110.347 18.538 48.808 40.154 68.885Zm-64.46-5q-19-30-34.308-75.539t-23.462-98.693H197.076q31.693 69.309 89.501 115.194 57.809 45.885 128.963 59.038Zm128.92 0q71.154-13.153 128.963-59.038 57.808-45.885 89.501-115.194H602.23Q592.153-295.539 576.845-250q-15.308 45.539-32.385 75.154Zm79.463-226.23h173.693q4.231-21.308 6.039-40.731 1.808-19.424 1.808-38.193t-1.808-38.693q-1.808-19.923-6.039-40.231H623.923q1.231 19.385 1.154 40.385-.077 21-.077 40.539t.077 39.539q.077 20-1.154 37.385Zm-218.308 0h148.77q2.769-22.308 3.462-41.347.692-19.038.692-37.577 0-18.539-.692-38.077-.693-19.539-3.462-40.847h-148.77q-2.769 21.308-3.462 40.847-.692 19.538-.692 38.077 0 18.539.692 38.077.693 19.539 3.462 40.847Zm-243.231 0h173.693q-1.231-19.385-1.154-40.385.077-21 .077-40.539t-.077-39.539q-.077-20 1.154-37.385H162.384q-4.231 21.308-6.039 40.731-1.808 19.424-1.808 38.193t1.808 38.193q1.808 19.423 6.039 40.731ZM602.23-610.922h160.694Q730.846-681 674-726.116q-56.847-45.115-129.54-59.423 19 31.923 33.923 76.885 14.924 44.962 23.847 97.732Zm-191.846 0h139.232q-10.923-61.154-30.039-110.924-19.115-49.769-39.577-68.308-20.462 18.539-39.577 68.308-19.116 49.77-30.039 110.924Zm-213.308 0H357.77q8.923-52.77 23.847-97.732 14.923-44.962 33.923-76.885-73.077 14.308-129.732 59.616-56.654 45.308-88.732 115.001Z" />
      </svg>
    </a>
  </div>

</div>
