<header>
    <div class="header-title">
        <div class="title" *ngIf="widgetConfig?.formValue.storedQuery">
            {{widgetConfig?.formValue.storedQuery}}</div>
        <div class="icons-title" *ngIf="this.widgetConfig?.storedQuery">
            <eo-icon class="button primary" [iconSrc]="'assets/_default/svg/ic_saved_search.svg'" (click)="setQueryToAppSearch()"
            [iconTitle]="('eo.widget.hitlist.tooltip.savedSearchIcon'| translate)"></eo-icon>
            <eo-icon class="button primary" [iconSrc]="'assets/_default/svg/ic_open_tab.svg'"
                (click)="onEmitQueryClick($event)"
                [iconTitle]="('eo.widget.hitlist.tooltip.openinresultlist'| translate)"></eo-icon>
            <eo-icon class="button primary" [iconSrc]="'assets/_default/svg/ic_refresh.svg'" (click)="refresh()"
                [iconTitle]="('eo.widget.hitlist.tooltip.refresh'| translate)"></eo-icon>
        </div>
    </div>
</header>

<main class="countTile" *ngIf="widgetConfig?.formValue.countTile; else tplResultList">
    <button class="count clr">{{this.countTileResult?.count}}</button>
</main>

<main class="loader-overlay__mask" *ngIf="refreshingRequest">
    <eo-loading-spinner [size]="'large'"></eo-loading-spinner>
</main>

<ng-template #tplResultList>
    <main class="hitlist" *ngIf="!refreshingRequest">
        <div *ngIf="hitlistResult; else tplEmpty">
            <div class="item" *ngFor="let i of hitlistResult" (click)="onItemClick(i, $event)">
                <div class="created">{{i.date | localeDate}}</div>
                <div class="title">{{i.title}}</div>
                <div class="description">{{i.description}}</div>
                <eo-icon [objectType]="i.type"></eo-icon>
            </div>
        </div>
        <ng-template #tplEmpty>
            <div class="empty">
                <eo-error-message
                    [emptyState]="{icon: 'ic_saved_search.svg', text: 'eo.workspace.widget.configuration.notOk'}"></eo-error-message>
            </div>
        </ng-template>
    </main>
</ng-template>
