import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReferenceItem} from '../interface/reference-finder.interface';

@Component({
  selector: 'eo-reference-finder-entry',
  templateUrl: './reference-finder-entry.component.html',
  styleUrls: ['./reference-finder-entry.component.scss']
})
export class ReferenceFinderEntryComponent {
  @Input() list: ReferenceItem;
  @Input() multiselect: boolean;
  @Input() marked: boolean;
  @Output() selectReference: EventEmitter<any> = new EventEmitter<any>();

  get link() {
    return this.list && this.list.link ? this.list.link.replace(new RegExp('^/'), '') : '';
  }

  constructor() {}

  select(item: ReferenceItem, add = false, selectedAble = true) {
    if (selectedAble) {
      this.selectReference.emit({ item, add });
    }
  }
}
