import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BackendService, PrepareService, PreparedItem, SystemService, TranslateService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EmptyState} from '../../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../../eo-framework-core/empty-state/empty-state.service';
import {PendingChangesComponent} from '../../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../../eo-framework-core/title/page-title.service';
import {ISetFilter} from '../../../eo-framework/grid';
import {CustomSortComponent} from '../../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {SetFilterComponent} from '../../../eo-framework/grid/extensions/filter/setFilters/set-filter.component';
import {GridComponent} from '../../../eo-framework/grid/grid.component';
@UntilDestroy()
@Component({
  selector: 'eo-prepare-state',
  templateUrl: './prepare-state.component.html',
  styleUrls: ['./prepare-state.component.scss']
})
export class PrepareStateComponent implements OnInit, PendingChangesComponent, OnDestroy {

  gridOptions: GridOptions;
  sortFields: ColDef[];
  typeFilterFields: ISetFilter[];
  gridData;
  idMatch = true;
  emptyState: EmptyState = {icon: '', text: '', className: ''};

  @ViewChild('eoGrid') eoGrid: GridComponent;

  preparedItems: PreparedItem[] = [];
  preparedItem: PreparedItem;

  constructor(public selection: SelectionService,
              public translate: TranslateService,
              private backend: BackendService,
              private titleService: PageTitleService,
              private prepareService: PrepareService,
              private empty: EmptyStateService,
              private pendingChanges: PendingChangesService,
              private system: SystemService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.prepare.list.title'));
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  removeItem(item: PreparedItem) {
    if (this.preparedItem && item.id === this.preparedItem.id) {
      this.preparedItem = null;
    }
    this.prepareService.deletePreparedItem(item.id).subscribe();
  }

  updateList(changedItem: PreparedItem) {
    this.updateGrid(this.backend.update(this.gridData, [{id: changedItem.id, item: changedItem}]));
  }

  refreshGrid() {
    this.prepareService.getPreparedItems$().subscribe();
  }

  typeFilters() {
    const data = this.gridData;
    let uniqueByType = SetFilterComponent.uniqBy(data, 'selectedtype.name', this.system.getObjectTypes());
    uniqueByType.push({qname: '', label: this.translate.instant('eo.prepare.list.item.type.undefined')});
    this.typeFilterFields = uniqueByType.map(t => Object.assign({
      label: t.label,
      value: true,
      id: '#' + t.qname,
      filter: (node) => node.data.selectedtype ? node.data.selectedtype.name === t.qname : '' === t.qname
    })).sort(Utils.sortValues('label'));
  }

  resetFilters() {
    this.typeFilters();
  }

  updateGrid(data) {
    this.emptyState = this.empty.getEmptyState(data ? data.length : -1);

    if (this.eoGrid && this.eoGrid.isReady) {
      this.eoGrid.updateRowData(data, data && data.length ? data[0].id : 0);
      // need to refresh filters based on new grid data
      return this.resetFilters();
    }

    this.resetFilters();

    let colDefs: ColDef[] = [
      {
        headerName: '',
        field: '__custom',
        cellRenderer: this.cellRenderer,
        filter: false
      }
    ];

    this.sortFields = [
      {field: 'created', headerName: this.translate.instant('eo.inbox.sort.date'), sort: ''},
      {field: 'selectedtype.label', headerName: this.translate.instant('eo.inbox.sort.title'), sort: ''}
    ];

    let sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);

    this.gridOptions = {
      context: {count: data.length},
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80
    };
  }

  cellRenderer(params) {
    let icon = params.data.selectedtype ? params.context.cr.render('type', params, {
      value: params.data.selectedtype.name
    }) : '<div class="iconempty"></div>';
    let createdTime = params.context.cr.render('dateTime', params, {value: params.data.created, pattern: 'eoNiceShort'});
    let label = params.data.title || params.context.translate.instant('eo.prepare.list.item.type.undefined');
    let location = params.data.parent && params.data.parent.type !== 'sysroot' ? params.context.translate.instant('eo.prepare.list.item.location.set') :
      !params.data.parent || params.data.parent.type === 'sysroot' ? params.context.translate.instant('eo.prepare.list.item.location.notset') : '';
    let template = !params.data.template ? '' :
      `<span>${params.context.translate.instant('eo.prepare.list.item.template')}</span> <span>${params.data.template.title}</span>`;
    let file = template || params.data.state.contentstate === 'NOCONTENTALLOWED' ? '' :
      params.data.contentcount === 0 ? `<span>${params.context.translate.instant('eo.prepare.list.item.file.none')}</span>` :
        params.data.contentcount === 1 ? `<span>${params.data.contents[0].path}</span>` :
          `<span>${params.data.contentcount}x ${params.context.translate.instant('eo.prepare.list.item.files')}</span>`;

    return `<div class="list-item" unselectable>
              ${icon}
             <div class="content">
              <div class="title">${label}</div>
              <div class="location">${location}</div>
              <div class="file">${template || file}</div>
            </div>
            <div class="meta">
                <div class="date">${createdTime}</div>
              </div>
          </div>
          `;
  }

  remove() {
    let focused = this.selection.getFocus();
    if (focused) {
      this.prepareService.deletePreparedItem(focused.id).subscribe();
    }
  }

  ngOnInit() {
    this.refreshGrid();
    this.prepareService.preparedItems$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.gridData = data;
        this.updateGrid(this.gridData);
      });
  }

  ngOnDestroy() {
    this.selection.clear();
  }
}
