<eo-dialog [title]="'eo.bar.button.execute.actions.tooltip' | translate"
           [visible]="true"
           [minWidth]="400"
           [styleClass]="'more-actions'"
           (hide)="close($event)">

  <section *ngIf="processes?.length; else loading">

    <div class="process-item" *ngFor="let process of processes" (click)="selectProcess(process)">
      <eo-icon [iconId]="process.iconid" *ngIf="process.iconid; else defaulticon"></eo-icon>
      <ng-template #defaulticon><eo-icon [iconSrc]="'assets/_default/svg/ic_bpm.svg'"></eo-icon></ng-template>
      <div class="pi-content">
        <div class="title">{{process.title}}</div>
        <div class="description">{{process.description}}</div>
      </div>
    </div>

  </section>

  <ng-container *ngIf="!processForm; else tplProcessForm" class="select-process"></ng-container>

  <ng-template #loading>
    <eo-loading-spinner [size]="'medium'"></eo-loading-spinner>
  </ng-template>


  <ng-template #tplProcessForm>

    <eo-dialog [title]="selectedProcess?.title"
               [subtitle]="selectedProcess?.description"
               [visible]="true"
               [dirtyCheck]="pendingTaskIds"
               [minWidth]="1000"
               [styleClass]="'process-form-dialog'"
               (hide)="cancelDialog()" #dialog>

      <eo-process-form [formOptions]="processForm"
                      (onResetForm)="formReset($event)"
                      (onCancel)="onCancel(dialog)"
                      (onSaveForm)="startProcessWithFormData($event)"
                      (statusChanged)="onIndexDataChanged($event)">
      </eo-process-form>
    </eo-dialog>
  </ng-template>

</eo-dialog>
