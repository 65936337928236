import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {DmsObject, DmsService} from '@eo-sdk/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';

@Component({
  selector: 'eo-finalize-object-action',
  templateUrl: './finalize-object-action.component.html',
  styleUrls: ['./finalize-object-action.component.scss']
})
export class FinalizeObjectActionComponent implements ActionComponent, AfterViewInit {

  @Input() selection: DmsObject[];
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elemRef: ElementRef,
              private dmsService: DmsService
             ) {}

  onClick() {
    this.dmsService.finalize(this.selection[0]).subscribe();
    this.finished.emit();
  }

  cancel() {
    this.canceled.emit();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.elemRef.nativeElement.querySelector('.primary').focus();
    },);
  }
}
