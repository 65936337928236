import {SimpleFilter} from '@ag-grid-community/core';
import {SearchFilter} from '@eo-sdk/core';

export class GridFilter {
  public static operator(property: string, filter: any) {
    let firstValue = Array.isArray(filter) ? filter : (filter.filter || filter.dateFrom);
    const secondValue = filter.filterTo || filter.dateTo;
    const op = Array.isArray(firstValue) ? SearchFilter.OPERATOR.IN :
      filter.type === SimpleFilter.GREATER_THAN ? SearchFilter.OPERATOR.GREATER_THAN :
        filter.type === SimpleFilter.GREATER_THAN_OR_EQUAL ? SearchFilter.OPERATOR.GREATER_OR_EQUAL :
          filter.type === SimpleFilter.LESS_THAN ? SearchFilter.OPERATOR.LESS_THAN :
            filter.type === SimpleFilter.LESS_THAN_OR_EQUAL ? SearchFilter.OPERATOR.LESS_OR_EQUAL :
              filter.type === SimpleFilter.IN_RANGE ? SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH :
                SearchFilter.OPERATOR.EQUAL;
    
    firstValue = firstValue && typeof firstValue === 'string' ? firstValue.replace(/\*/g, '\\*') : firstValue; // wildcard needs to be escaped

    firstValue = filter.type === SimpleFilter.CONTAINS ? `*${firstValue}*` :
      filter.type === SimpleFilter.STARTS_WITH ? `${firstValue}*` :
        filter.type === SimpleFilter.ENDS_WITH ? `*${firstValue}` :
          op === SearchFilter.OPERATOR.IN ? firstValue.filter(f => f !== '') :
            firstValue;

    return new SearchFilter(property, op, firstValue, secondValue, filter.filterType === 'date' ? {time: !!filter.withTime} : undefined);
  }
}
