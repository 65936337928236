<yvc-tabs *ngIf="modifiedByMe?.lately.length || createdByMe?.lately.length"
  layoutSettingsID="eo.lastEdited.widget.tabs">
  <ng-template [yvcTab]="{id: 'LastEdited', label: 'eo.dashboard.modified.title' | translate}">
    <div class="lastEdits" *ngIf="modifiedByMe?.lately.length">

      <main class="body">
        <div class="item" *ngFor="let item of modifiedByMe.lately" [routerLink]="['/object/', item.id]">
          <eo-icon [objectType]="item.type"></eo-icon>
          <div>
            <div class="modified">{{item.modified | localeDate}}</div>
            <div class="title">{{item.title}}</div>
            <div class="description">{{item.description}}</div>
          </div>
        </div>
      </main>
    </div>
  </ng-template>
  <ng-template [yvcTab]="{id: 'LastCreated', label: 'eo.dashboard.created.title' | translate}">
    <div class="lastEdits" *ngIf="createdByMe?.lately.length">

      <main class="body">
        <div class="item" *ngFor="let item of createdByMe.lately" [routerLink]="['/object/', item.id]">
          <eo-icon [objectType]="item.type"></eo-icon>
          <div>
            <div class="modified">{{item.created | localeDate}}</div>
            <div class="title">{{item.title}}</div>
            <div class="description">{{item.description}}</div>
          </div>
        </div>
      </main>
    </div>
  </ng-template>
</yvc-tabs>