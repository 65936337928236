import {UntypedFormGroup} from '@angular/forms';

export interface EoFormGroup {
  id?: string;
  // the label for the form group
  label?: string;
  // object holding properties defining layout properties of the group
  layout: any;
  // type of group indicating if the form is a stack, a fieldset or a simple group
  type: string;
  // object containing the groups child form components
  // key is the controls name, value the actual form control or -group
  //controls?: any;
}

// extensions of the default angular form groups

export class ObjectFormGroup extends UntypedFormGroup {
  public _eoFormGroup: EoFormGroup;
}
