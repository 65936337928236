
import {of as observableOf, Observable} from 'rxjs';
import {SimpleAction} from '../../interfaces/action.interface';
import {DmsObjectTarget} from '../../action-target';
import {Component} from '@angular/core';
import {SelectionRange} from '../../selection-range.enum';
import {Router} from '@angular/router';
import {TranslateService} from '@eo-sdk/core';
import {ActionService} from '../../action-service/action.service';
import {DmsObject, CapabilitiesService, ClipboardAction, ClipboardService} from '@eo-sdk/core';

@Component({
  selector: 'eo-cut-action',
  template: ``
})
export class CutActionComponent extends DmsObjectTarget implements SimpleAction {
  label: string;
  description: string;
  priority = 3.4;
  iconSrc = 'assets/_default/svg/ic_content-cut.svg';
  group = 'common';
  range = SelectionRange.MULTI_SELECT;

  constructor(private translate: TranslateService,
              private capabilityService: CapabilitiesService,
              private clipboard: ClipboardService,
              private router: Router,
              private actionService: ActionService) {
    super();
    this.label = this.translate.instant('eo.action.clipboard.cut.label');
    this.description = this.translate.instant('eo.action.clipboard.cut.description');
  }

  isExecutable(item: DmsObject): Observable<boolean> {
    const isAllowedType = this.actionService.isAllowedType([item], ['sysemail']);
    return observableOf(this.isAllowedState() && isAllowedType && this.capabilityService.getCapabilities().intray
      && !item.isFinalized && !item.isContextFolder && item.rights.edit);
  };

  run(selection: DmsObject[]) {
    this.clipboard.set(selection, ClipboardAction.CUT);
  }

  isAllowedState(): boolean {
    const disabledStates = ['/versions', '/favorites'];
    return !disabledStates.some(s => this.router.url.startsWith(s));
  }
}
