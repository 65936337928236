import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {UiModule} from '../ui/ui.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ChangePasswordFormComponent} from './change-password/change-password-form/change-password-form.component';
import {EoSharedModule} from '@eo-sdk/core';
import {PermissionsComponent} from './permissions/permissions.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormElementsModule,
    EoSharedModule,
    UiModule
  ],
  declarations: [ChangePasswordFormComponent, PermissionsComponent],
  exports: [ChangePasswordFormComponent, PermissionsComponent]
})
export class SettingsModule {
}
