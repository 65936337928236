import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import {ChangeMode, ChangeOutput} from '../signature.model';

@Component({
  selector: 'eo-sequence-list-item',
  templateUrl: './sequence-list-item.component.html',
  styleUrls: ['./sequence-list-item.component.scss']
})
export class SequenceListItemComponent {
  changeMode = ChangeMode;
  @Input() index: number;
  @Input() disableAdd = false;
  @Input() addTargetIndex: number = null;
  @Output() changeEntry: EventEmitter<ChangeOutput> = new EventEmitter<ChangeOutput>();
  @Output() edit: EventEmitter<number> = new EventEmitter<number>();
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  @Output() insert: EventEmitter<number> = new EventEmitter<number>();
  @ContentChild('content') content: TemplateRef<any> | undefined;

  constructor() {}

  changes(index: number, mode: ChangeMode) {
    this.changeEntry.emit({ index, mode });
    if (ChangeMode.EDIT === mode) { this.edit.emit(index); }
    if (ChangeMode.DELETE === mode) { this.delete.emit(index); }
    if (ChangeMode.INSERT === mode) { this.insert.emit(index); }
  }
}
