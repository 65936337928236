import {AfterViewInit, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {
  AppCacheService,
  Capabilities,
  CapabilitiesService,
  Config,
  EnaioEvent,
  EoUser,
  EventService,
  LocalStorageService,
  NotificationsService,
  SystemService,
  TranslateService,
  UserService,
  Utils
} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable, forkJoin, firstValueFrom} from 'rxjs';
import {take, withLatestFrom} from 'rxjs/operators';
import {AgentService, agentConfigKeys} from '../../eo-framework-core/agent/agent.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {ResultListComponent} from '../../eo-framework/result-list/result-list.component';
import {UserAvatarComponent} from '../../eo-framework/ui/user-avatar/user-avatar.component';
import {ListSettingsService} from './../../eo-framework/grid/extensions/services/list-settings.service';
import {LockSettings} from './agent-lock-settings.enum';
@UntilDestroy()
@Component({
  selector: 'eo-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements AfterViewInit {

  user: EoUser;
  capabilities: Capabilities;
  deputies: {
    data: string[],
    dataMeta: any[]
  };
  showChangePasswordForm: boolean;
  clientLocales: any;
  schemaLocales: any[];
  public LockSettings = LockSettings;
  agentLockSettings: LockSettings;
  useAgentControl: UntypedFormControl;
  showAgentConfig: boolean = true;
  defaultLockSetting: boolean = false;

  user$ = this.userService.user$;
  errorMessage: string;
  userId = '';
  showDeleteIconTrash: boolean = true;
  userImageUri = '';
  preview: string;
  file: any;
  hoverSelector: boolean = false;
  showPreviewDialog: boolean = false;
  showDeleteDialog: boolean = false;
  showErrorDialog: boolean = false;
  MAX_FILE_SIZE = 524288;
  viewMode: string = '';
  fileName: string;

  cache = {
    system: true,
    history: true,
    layout: true
  };

  @ViewChild('files') files: ElementRef = {} as ElementRef;
  @ViewChild('headerBg') headerBg;
  @ViewChild('userAvatar') userAvatar: UserAvatarComponent
  @ViewChild('confirmDeleteBtn') confirmDeleteBtn: ElementRef;
  @ViewChild('profileImageOverlay') profileImageOverlay: ElementRef;
  @ViewChild(NgForm) deputiesForm: NgForm;

  constructor(private renderer: Renderer2,
    public userService: UserService,
    public config: Config,
    private notify: NotificationsService,
    private systemService: SystemService,
    private eventService: EventService,
    private capabilitiesService: CapabilitiesService,
    private titleService: PageTitleService,
    private agentService: AgentService,
    private fb: UntypedFormBuilder,
    private cacheService: AppCacheService,
    private listSettingsService: ListSettingsService,
    private storageService: LocalStorageService,
    public translate: TranslateService,
    private elemRef: ElementRef,
    private notification: NotificationsService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.bar.button.settings.title'));
    this.clientLocales = config.getClientLocales();
    this.capabilities = this.capabilitiesService.getCapabilities();
    this.getSchemaLocales();
    this.getUserData();
    this.agentLockSettings = userService.getCurrentUser().userSettings.alwayslock;
    this.agentService.isConnected$.pipe(take(1), withLatestFrom(this.agentService.agentConfig$)).subscribe(([isConnected, config]) => {
      this.useAgentControl = this.fb.control(isConnected);
      if (config.hasOwnProperty(agentConfigKeys.AUTOCONNECT)) {
        this.showAgentConfig = config[agentConfigKeys.AUTOCONNECT]
        this.useAgentControl.disable()
      }
      if (config.hasOwnProperty(agentConfigKeys.LOCKSETTINGS) && Object.values(LockSettings).includes(config[agentConfigKeys.LOCKSETTINGS])) {
        this.defaultLockSetting = true;
        this.agentLockSettings = config[agentConfigKeys.LOCKSETTINGS];
      }
      this.useAgentControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.agentService.isConnected = this.useAgentControl.value);
    });
  }


  get isSSO() {
    return this.capabilities && this.capabilities.sso;
  }

  get agentStatus(): Observable<boolean> {
    return this.agentService.isConnected$;
  }

  get activeSchema(): string {
    return this.user && this.user.getSchemaLocale();
  }

  hasPrivilege(privilege: string): boolean {
    return this.userService.hasPrivilege(privilege);
  }

  changeLockSettings(lockSettings: LockSettings) {
    this.userService.changeLockSetting(lockSettings).subscribe(() => this.agentLockSettings = lockSettings);
  }

  getSchemaLocales() {
    this.systemService.system$.subscribe((systemDefinition) => {
      this.schemaLocales = systemDefinition.locales.sort(Utils.sortValues('displayname'));
    });
  }

  saveDeputies() {
    this.userService.setDeputies(this.deputies.data)
      .subscribe(res => this.deputiesForm.resetForm({deputies: this.deputies.data}));
  }

  resetDeputies() {
    this.deputiesForm.resetForm({deputies: this.user.deputies.map(d => d.name)})
  }

  getUserData() {
    this.userService.user$.subscribe((user) => {
      // this.user = Object.assign(user, {capabilities: this.capabilitiesService.getCapabilities()});
      this.user = user;
      // deputies
      this.deputies = {
        data: this.user.deputies.map(d => d.name),
        dataMeta: this.user.deputies
      };
    });
  }

  cancel(): void {
    this.showChangePasswordForm = false;
  }

  changeClientLocale(iso: string) {
    if (this.config.getSupportedClientLocales().includes(iso)) {
      this.userService.changeClientLocale(iso);
    } else {
      this.notify.error(this.translate.instant('eo.state.settings.language.error'));
    }
  }

  changeSchemaLocale(iso: string) {
    if (this.user.schemaLocale !== iso) {
      this.userService.changeSchemaLocale(iso).subscribe(
        _ => Utils.throw(null,
          this.translate.instant('eo.user.service.schema.locale.error.title'),
          this.translate.instant('eo.user.service.schema.locale.error.description'))
      );
    }
  }

  ngAfterViewInit() {
    this.elemRef.nativeElement.querySelector('#presence').focus();
    this.user$.subscribe(user => {
      this.userId = user.id;
      this.userImageUri = this.userService.getUserImageUri(this.userId);
      this.refreshUserImageHeaderBackground(this.userImageUri);
      this.showDeleteIconTrash = !!user.imageUri;
    });
  }

  setPresence(b: boolean) {
    this.userService.setPresence(b)
      .subscribe(_ => {
      }, Utils.throw(null,
        this.translate.instant('eo.state.settings.presence.error.title'),
        this.translate.instant('eo.state.settings.presence.error.description')
      )
      );
  }
  closePreviewDialog() {
    this.showPreviewDialog = false;
    this.profileImageOverlay.nativeElement.focus();
  }

  deleteUserImage() {
    this.userService
      .setUserImage(null).subscribe({
        next: () => {
          this.refreshUserImageHeaderBackground(this.userImageUri);
          this.eventService.trigger(EnaioEvent.SYSTEM_STATUS_IMAGE_CHANGE);
          this.showDeleteDialog = false;
          this.showDeleteIconTrash = false;
        },
        error: (err) => {
          this.notification.error('', err.error.cause.messages[0]);
          this.file = null;
          this.translate.instant('eo.upload.global.error.title'),
          this.translate.instant('eo.upload.global.error.description')
        },

      });

  }

  selectImage(event): void {
    this.file = event.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      switch (false) {

        case (this.file.type.includes('image/') && this.file.type.includes('jpeg') || this.file.type.includes('png')):
          this.viewMode = 'image';
          this.showErrorDialog = true;
          break;

        case (this.file.size <= this.MAX_FILE_SIZE):
          this.viewMode = 'size';
          this.showErrorDialog = true;
          break;

        default:

          this.preview = '';
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.preview = e.target.result;
            this.showPreviewDialog = true;
          };
          reader.readAsDataURL(this.file);
          break;
      }
    }
  }

  uploadNewUserImage() {

    this.userService
      .setUserImage(this.file).subscribe({
        next: () => {
          this.profileImageOverlay.nativeElement.focus();
          this.refreshUserImageHeaderBackground(this.userImageUri);
          this.eventService.trigger(EnaioEvent.SYSTEM_STATUS_IMAGE_CHANGE);
          this.closePreviewDialog();
          this.showDeleteIconTrash = true;
        },
        error: (err) => {
          this.notification.error('', err.error.cause.messages[0]);
          this.file = null;
          this.translate.instant('eo.upload.global.error.title'),
          this.translate.instant('eo.upload.global.error.description')
        },
  })
}

  tabAction(input: HTMLInputElement) {
    input.blur();
    this.hoverSelector = false;
  }
  mouseEnter() {
    this.hoverSelector = true;
  }
  mouseLeave() {
    this.hoverSelector = false;
  }
  trashIconClick() {
    this.showDeleteDialog = true;
  }

  refreshUserImageHeaderBackground(imageUri: string) {
    if (imageUri) {
      const timestamp = new Date().getTime().toString();
      this.renderer.setStyle(this.headerBg.nativeElement, 'background-image', `url(${imageUri + '&t=' + timestamp})`);
    }
  }

  clearCache() {
    const actions = [
      this.cache.history && this.listSettingsService.clearSettings(),
      this.cache.history && this.cacheService.clear((key: string) => !key.match(/^eo.+(layout)/) && !key.match(/^eo.framework.cache/)),
      this.cache.layout && this.storageService.clear((key: string) => key === ResultListComponent.COLUMNS_DEFINITION),
      this.cache.layout && this.cacheService.clear((key: string) => !!key.match(/^eo.+(layout)/)),
      this.cache.system && this.systemService.fetchSystemDefinition(this.user),
    ].filter(a => a);

    return actions.length ? forkJoin(actions).subscribe(
      () => this.notify.success(this.translate.instant('eo.state.settings.cache.clear.success')),
      () => this.notify.error(this.translate.instant('eo.state.settings.cache.clear.error'))
    ) && true : false;
  }

  async saveLocalSettings() {
    const localSettings = this.storageService.getStorage();
    const cacheSettings = await firstValueFrom(this.cacheService.getStorage());
    delete cacheSettings['eo.framework.cache.systemdefinition'];
    this.userService.saveLocalSettings(localSettings, cacheSettings).subscribe();
  }

  loadLocalSettings() {
    this.userService.loadLocalSettings().subscribe(settings => {
      this.storageService.setStorage(settings.localSettings);
      this.cacheService.replaceStorage(settings.cacheSettings);
    });
  }
}
