<ng-container *ngIf="iconSrc;else btn">
  <eo-icon class="overlayIcon button {{iconClass}}"
           (click)="toggle($event)"
           (keyup.enter)="toggle($event)"
           [iconSrc]="iconSrc"
           [badge]="badge"
           [ngClass]="{active: active}"
           tabindex="0"
           [iconTitle]="iconTitle"></eo-icon>
</ng-container>
<ng-template #btn>
  <div (click)="toggle($event)" (keyup.enter)="toggle($event)">
    <ng-content select=".btn"></ng-content>
  </div>
</ng-template>
<yvc-dialog #op [options]="{attachedTo: hostEl, lightDismiss: true}"
(opened)="dialogIsOpen = true" (closed)="dialogIsOpen = false">
  <ng-content></ng-content>
</yvc-dialog>
