import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AppCacheService, BackendService, BpmService, Process, RangeValue, SearchFilter, TranslateService, Utils} from '@eo-sdk/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EmptyState} from '../../eo-framework-core/empty-state/empty-state.interface';
import {EmptyStateService} from '../../eo-framework-core/empty-state/empty-state.service';
import {PendingChangesComponent} from '../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {GridComponent} from '../../eo-framework/grid/grid.component';

@Component({
  selector: "eo-process-state",
  templateUrl: "./process-state.component.html",
  styleUrls: ["./process-state.component.scss"]
})
export class ProcessStateComponent
  implements OnInit, OnDestroy, PendingChangesComponent {
  gridOptions: GridOptions;
  processSelectionId = "process";
  processSelection: SelectionService;
  emptyState1: EmptyState = { icon: "", text: "", className: "" };
  emptyState2: EmptyState = { icon: "", text: "", className: "" };
  filterCacheKey = "eo.process.list.filter";
  sortCacheKey = "eo.process.list.sort";
  VIRTUAL_LIST_CHUNK_SIZE = 50;
  filterForm: UntypedFormGroup;
  defaultFilterFormValue = {
    modelFCN: null,
    runningFCN: true,
    pausedFCN: true,
    createdFCN: true,
    canceledFCN: true,
    failedFCN: true,
    completedFCN: true,
    startTimeFCN: null
  };
  sortParams = {
    startTime: '',
    modelName: ''
  };
  defaultSortParams = {
    startTime: "",
    modelName: ""
  };
  settings = {};
  colDefs: ColDef[] = [
    {
      headerName: "",
      field: "__custom",
      cellRenderer: this.cellRenderer,
      filter: false
    }
  ];
  processModelsListObject = {
    entries: [],
    config: {
      valueField: "name",
      subEntriesField: "models"
    }
  };

  @ViewChild("eoGrid") eoGrid: GridComponent;

  constructor(
    public translate: TranslateService,
    public bpmService: BpmService,
    public selection: SelectionService,
    private empty: EmptyStateService,
    private backend: BackendService,
    private fb: UntypedFormBuilder,
    private titleService: PageTitleService,
    private appCache: AppCacheService,
    private pendingChanges: PendingChangesService
  ) {
    this.titleService.setBaseTitle(this.translate.instant('eo.state.process'));

    //secondary selection instance for inbox items
    this.processSelection = this.selection.createNew(this.processSelectionId);

    this.fetchProcessModels().subscribe(processModels => {
      this.processModelsListObject.entries = processModels;
    });

    this.appCache
      .getItem(this.filterCacheKey)
      .subscribe(
        filterValue =>
          this.initFilter(
            filterValue ? filterValue : this.defaultFilterFormValue
          ),
        () => this.initFilter(this.defaultFilterFormValue)
      );

    this.appCache.getItem(this.sortCacheKey).subscribe(sortParams => {
      if (sortParams) {
        this.sortParams = sortParams;
      }
    });
  }

  private fetchProcessModels(): Observable<any> {
    return this.backend.get("/bpm/management/project?includeModels=true");
  }

  private initFilter(filterValue) {
    this.filterForm = this.fb.group({
      modelFCN: [filterValue.modelFCN],
      runningFCN: [filterValue.runningFCN],
      pausedFCN: [filterValue.pausedFCN],
      createdFCN: [filterValue.createdFCN],
      canceledFCN: [filterValue.canceledFCN],
      failedFCN: [filterValue.failedFCN],
      completedFCN: [filterValue.completedFCN],
      startTimeFCN: [
        filterValue.startTimeFCN ?
        new RangeValue(
          filterValue.startTimeFCN.operator,
          filterValue.startTimeFCN.firstValue,
          filterValue.startTimeFCN.secondValue
        ) : null
      ]
    });
    this.filterForm.valueChanges.subscribe(() => {
      this.appCache.setItem(this.filterCacheKey, this.filterForm.value).subscribe();
      this.onPageChanged();
    });
    setTimeout(() => this.onPageChanged(), 100);
  }

  refreshList() {
    this.onPageChanged();
  }

  initGrid() {
    this.settings = {total: 0, size: this.VIRTUAL_LIST_CHUNK_SIZE};
    this.gridOptions = {
      context: { count: 0 },
      columnDefs: this.colDefs,
      rowBuffer: 20,
      rowHeight: 80,
      rowSelection: 'single',
      rowData: []
    };
  }

  resetFilters() {
    if (this.filterForm) {
      this.filterForm.setValue(this.defaultFilterFormValue);
      this.onPageChanged();
    }
  }

  sort(field, direction) {
    this.sortParams[field] = direction;
    this.appCache.setItem(this.sortCacheKey, this.sortParams).subscribe();
    this.onPageChanged();
  }

  onPageChanged(page: number = 1) {
    this.fetchRowData({
      startRow: this.VIRTUAL_LIST_CHUNK_SIZE * (page - 1),
      successCallback: (rows, total) => {
        this.eoGrid.api.setRowData(rows);
        this.settings = {total, size: this.VIRTUAL_LIST_CHUNK_SIZE, page};
      }
    });
  }

  private fetchRowData(params) {
    const page = Math.floor(params.startRow / this.VIRTUAL_LIST_CHUNK_SIZE);
    this.getProcesses({ size: this.VIRTUAL_LIST_CHUNK_SIZE, page }).subscribe((processes) => {
      if (params.startRow === 0) {
        this.emptyState1 = this.empty.getEmptyState(processes.content ? processes.content.length : -1, undefined, 'ic_no-file.svg');
        this.emptyState2 = this.empty.getEmptyState(processes.content ? processes.content.length : -1, undefined, 'ic_no-file.svg');
      }
      this.eoGrid.gridOptions.context.rowCount = processes.totalElements;
      this.eoGrid.gridOptions.context.count = processes.totalElements;
      params.successCallback(processes.processes, processes.totalElements);
    });
  }

  private getProcesses(options) {
    let url = Utils.buildUri(`/processes`, options || {});
    url = this.addFilterParamsToUrl(url);
    url = this.addSortParamsToUrl(url);
    return this.backend
      .getJson(url, this.backend.getBpmBase())
      .pipe(
        map(response => {
          let processes = response.content.map(item => {
            const process = new Process(item);
            return process;
          });
          response.processes = this.addIconUrl(processes);
          return response;
        })
      );
  }

  private addFilterParamsToUrl(url): string {
    if (!this.filterForm || !this.filterForm.value) {
      return url;
    }

    if (this.filterForm.value.createdFCN) {
      url += "&state=0";
    }
    if (this.filterForm.value.runningFCN) {
      url += "&state=20";
    }
    if (this.filterForm.value.completedFCN) {
      url += "&state=30";
    }
    if (this.filterForm.value.pausedFCN) {
      url += "&state=40";
    }
    if (this.filterForm.value.canceledFCN) {
      url += "&state=50";
    }
    if (this.filterForm.value.failedFCN) {
      url += "&state=60";
    }
    if (this.filterForm.value.modelFCN) {
      url +=
        "&modelid=" + this.getModelIdFromName(this.filterForm.value.modelFCN);
    }
    if (this.filterForm.value.startTimeFCN && this.filterForm.value.startTimeFCN.firstValue) {

      const {operator, firstValue, secondValue} = this.filterForm.value.startTimeFCN;
      const query: any = new SearchFilter('starttime', operator, firstValue, secondValue, {time: true, timezone: true}).toDateTimeQuery();

      if (query.o === SearchFilter.OPERATOR.EQUAL) {
        url += '&starttime_eq=' + query.v1;
      }
      if (query.o.match(SearchFilter.OPERATOR.GREATER_OR_EQUAL)) {
        url += "&starttime_gte=" + query.v1;
      }
      if (query.o.match(SearchFilter.OPERATOR.LESS_OR_EQUAL)) {
        url += "&starttime_lte=" + (query.o === SearchFilter.OPERATOR.INTERVAL_INCLUDE_BOTH ? query.v2 : query.v1);
      }
    }
    return url;
  }

  private addSortParamsToUrl(url): string {
    if (this.sortParams.startTime) {
      url += "&sort=starttime," + this.sortParams.startTime;
    }
    if (this.sortParams.modelName) {
      url += "&sort=name," + this.sortParams.modelName;
    }
    return url;
  }

  private getModelIdFromName(modelName): string {
    let id = "";
    this.processModelsListObject.entries.forEach(model => {
      if (model.name === modelName) {
        id = model.id;
      } else if (model.models) {
        model.models.forEach(submodel => {
          if (submodel.name === modelName) {
            id = submodel.id;
          }
        });
      }
    });
    return id;
  }

  addIconUrl(processes: Process[]) {
    processes.forEach((process: Process) => {
      if (!process.iconid) {
        if (process.state === 'ERRORSUSPENDED') {
          process.iconurl = 'assets/_default/svg/ic_bpm_warning.svg';
        } else if (process.state === 'COMPLETED') {
          process.iconurl = 'assets/_default/svg/ic_bpm_done.svg';
        } else {
          process.iconurl = "assets/_default/svg/ic_bpm.svg";
        }
      }
    });
    return processes;
  }

  cellRenderer(params) {
    if (params.data) {
      let icon = params.context.cr.render("icon", params, {
        value: {
          url: params.data.iconid ? "" : params.data.iconurl,
          iconId: !params.data.iconid ? "" : params.data.iconid,
          label: params.data.modelname
        }
      });

      const startTime = params.context.cr.render('dateTime', params, { value: params.data.starttime, pattern: 'eoShortDate' });
      const endTime = params.context.cr.render('dateTime', params, { value: params.data.endtime, pattern: 'eoShortDate' });
      const hasError = params.data.state === 'ERRORSUSPENDED' ? 'error' : '';
      const hasNotYetBeenStarted = params.data.state === 0 ? 'notstarted' : '';
      const endTimeMarkup: string = (endTime.length) ? `<div class="date end">
                                                          <span>${params.context.translate.instant('eo.process.end.time.label')}</span>
                                                          <div class="time"> ${endTime}</div>
                                                        </div>`
        : "";
      const startTimeMarkup: string = startTime.length
        ? `<div class="date">
                                                          <span>${params.context.translate.instant(
                                                            "eo.process.start.time.label"
                                                          )}</span>
                                                          <div class="time"> ${startTime}</div>
                                                        </div>`
        : "";

      return `<div class="list-item ${hasError} ${hasNotYetBeenStarted}" unselectable>
            ${icon}
            <div class='content'>
              <div class='title'>${params.data.title || "..."}</div>
              <div class='description'>${params.data.modelname || "..."}</div>
             </div>
            <div class="meta">
              <div class="datelabel">
                ${startTimeMarkup}
                ${endTimeMarkup}
              </div>
            </div>
         </div>
        `;
    }
  }

  ngOnInit() {
    this.initGrid();
  }

  ngOnDestroy() {
    this.selection.clear();
  }

  hasChanged(oV, nV): boolean {
    return JSON.stringify(oV) !== JSON.stringify(nV);
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }
}
