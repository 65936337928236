<div class="object-history">

  <eo-history-filter [filters]="filters"
                     [history]="history"
                     [(term)]="filterterm"
                     [visibleFilter]="visibleFilter"
                     (onToggleFilter)="toggleFilter($event)">
  </eo-history-filter>


  <ng-template #empty>
    <div class="history empty" translate>eo.timeline.empty</div>
  </ng-template>

  <div class="history eo-timeline" [ngClass]="{single: history?.length === 1}" *ngIf="history?.length; else empty">

    <div class="timeline-entry" [ngClass]="{user: !!entry.user, comment: entry.comment}"
         *ngFor="let entry of history | historyFilter: filterterm; trackBy: trackByIndex">

      <div class="when">
        <div class="date">{{entry.time | localeDate: 'eoShortDate'}}</div>
        <div class="time">{{entry.time | localeDate: 'eoShortTime'}}</div>
      </div>
      <div class="marker" [ngClass]="{bpm: entry.group === 'PROCESS', mod: entry.group === 'MODIFICATION'}"></div>
      <div class="what">

        <div class="title"><span [translate]="'eo.object.history.entry.type.' + entry.type + '.title'"></span>
          <a class="version" [routerLink]="'/versions/' + params.id" [queryParams]="{type: params.type, version: entry.version}">
            <span>{{'eo.object.history.version' | translate}} {{entry.version}}</span>
          </a>
        </div>
        <div class="description"
          [translateParams]="entry.parameter"
          [translate]="'eo.object.history.entry.type.' + (entry.intent ? entry.intent : entry.type) + '.description'"></div>
        <div class="comment">&quot;{{entry.comment}}&quot;</div>

        <div class="meta">
          <span *ngIf="entry.user.type === 'serveruser'; else user">{{entry.user.title}}</span>
          <ng-template #user>
            <span>{{entry.user.lastname}}, {{entry.user.firstname}} ({{entry.user.name}})</span>
          </ng-template>
        </div>

        <div class="bpm-meta" *ngIf="entry.parameter?.type === 'bpm'">
          <span *ngIf="entry.parameter.processName">
          <span translate>eo.object.history.workflowprocess</span>
          <span>'{{entry.parameter.processName}}'</span>
        </span>
          <span *ngIf="entry.parameter.activityName">
          <span translate>eo.object.history.activity</span>
          <span>'{{entry.parameter.activityName}}'</span>
        </span>
        </div>

      </div>
    </div>
  </div>
</div>
