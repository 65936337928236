import {forkJoin, of} from 'rxjs';
import {Component, OnInit, EventEmitter, ElementRef, ViewChild} from '@angular/core';
import {ActionComponent} from '../../../interfaces/action-component.interface';
import {Input, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import {TranslateService, DmsService, UserService, DmsObject, SubscriptionMode} from '@eo-sdk/core';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'eo-add-subscription',
  templateUrl: './add-subscription.component.html',
  styleUrls: ['./add-subscription.component.scss']
})
export class AddSubscriptionComponent implements OnInit, ActionComponent {

  subscriptionModeCodesystem;

  @Input() selection: DmsObject[];

  get hasEmail() {
    return this.userService.getCurrentUser().email && this.userService.getCurrentUser().email.length;
  }

  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('titleField', {static: true}) titleField : ElementRef<any>;

  header: string;
  addSubscriptionForm: UntypedFormGroup;
  titleFieldLabel: string;
  inboxCbLabel: string;
  emailCbLabel: string;
  processing: boolean;

  constructor(private translate: TranslateService,
              private dms: DmsService,
              private userService: UserService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.header = this.translate.instant('eo.object.inlinemenu.addsubscription');
    this.titleFieldLabel = this.translate.instant('eo.subscription.field.info');
    this.inboxCbLabel = this.translate.instant('eo.subscription.field.notification.inbox');
    this.emailCbLabel = this.translate.instant('eo.subscription.field.notification.email');

    const includesFolders = this.selection.find(item => item.isFolder);
    const includesDocuments = this.selection.find(item => !item.isFolder);

    this.addSubscriptionForm = this.fb.group({
      titleFieldFCN: [''],
      inboxCbFCN: [true],
      emailCbFCN: [false],
      modeFieldFCN: [includesFolders ? SubscriptionMode.ONLY_INDEX_DATA_CHANGED : SubscriptionMode.DMS_OBJECT_CHANGED]
    }, {validator: this.validator});

    if (this.selection.length === 1) {
      const titleFieldControl = this.addSubscriptionForm.controls['titleFieldFCN'];
      titleFieldControl.setValidators(Validators.required);
      titleFieldControl.setValue(this.createTitle(this.selection[0]));
    }

    if(includesFolders && includesDocuments){
      this.subscriptionModeCodesystem = {
        entries: [
          {id: 0, defaultrepresentation: this.translate.instant('eo.subscription.field.mode.entries.meta.label'), data: SubscriptionMode.ONLY_INDEX_DATA_CHANGED},
          {id: 2, defaultrepresentation: this.translate.instant('eo.subscription.field.mode.entries.both.label'), data: SubscriptionMode.DMS_OBJECT_CHANGED}
        ]
      }
    } else if(includesFolders && !includesDocuments) {
      this.subscriptionModeCodesystem = {
        entries: [
          {id: 0, defaultrepresentation: this.translate.instant('eo.subscription.field.mode.entries.meta.label'), data: SubscriptionMode.ONLY_INDEX_DATA_CHANGED}
        ]
      }
    } else {
      this.subscriptionModeCodesystem = {
        entries: [
          {id: 0, defaultrepresentation: this.translate.instant('eo.subscription.field.mode.entries.meta.label'), data: SubscriptionMode.ONLY_INDEX_DATA_CHANGED},
          {id: 1, defaultrepresentation: this.translate.instant('eo.subscription.field.mode.entries.content.label'), data: SubscriptionMode.ONLY_DOCUMENT_CONTENT_CHANGED},
          {id: 2, defaultrepresentation: this.translate.instant('eo.subscription.field.mode.entries.both.label'), data: SubscriptionMode.DMS_OBJECT_CHANGED}
        ]
      }
    }
    setTimeout(() => {
      this.titleField.nativeElement.getElementsByTagName('input')[0].select();
    },);
  }


  get titleValidity() {
    return this.addSubscriptionForm.get('titleFieldFCN').invalid;
  }

  private validator(control: UntypedFormControl) {
    if (control.value.inboxCbFCN || control.value.emailCbFCN) {
      return null;
    } else {
      return {
        addSubscriptionForm: {
          valid: false
        }
      };
    }
  }

  createSubscription() {
    const {titleFieldFCN, inboxCbFCN, emailCbFCN: sendEmail, modeFieldFCN} = this.addSubscriptionForm.value;
    const onlyEmail = this.hasEmail ? sendEmail && !inboxCbFCN : false;

    this.processing = true;
    const createTasks = [];
    this.selection.forEach(item => {
      const title = this.selection.length === 1 ? titleFieldFCN : this.createTitle(item);
      createTasks.push(this.dms.createSubscription(item, title, undefined, sendEmail, onlyEmail, modeFieldFCN)
        .pipe(catchError(e => of(null))));
    });

    if (createTasks.length) {
      forkJoin(createTasks).subscribe(() => {
          this.processing = false;
          this.finished.emit();
        }
      )
    } else {
      this.processing = false;
      this.finished.emit();
    }
  }

  private createTitle(item) {
    return item.isContextFolder && item.contextFolder ? item.contextFolder.title + ' > ' + item.title : item.title;
  }

  cancel() {
    this.canceled.emit();
  }

}