import {ColDef, GridOptions} from '@ag-grid-community/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {EoUser, StoredQueriesService, StoredQuery, TranslateService, UserService, Utils} from '@eo-sdk/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, first} from 'rxjs/operators';
import {PendingChangesComponent} from '../../eo-framework-core/pending-changes/pending-changes-component.interface';
import {PendingChangesService} from '../../eo-framework-core/pending-changes/pending-changes.service';
import {AppSearchService} from '../../eo-framework-core/search/app-search.service';
import {SelectionService} from '../../eo-framework-core/selection/selection.service';
import {PageTitleService} from '../../eo-framework-core/title/page-title.service';
import {CustomSortComponent} from '../../eo-framework/grid/extensions/custom-sort/custom-sort.component';
import {ISetFilter} from '../../eo-framework/grid/extensions/filter/setFilters/set-filters.interface';
import {GridComponent} from '../../eo-framework/grid/grid.component';
import {TypeFilter} from '../../eo-framework/stored-query/stored-query-type-filter.enum';
import {UtilitiesService} from '../../eo-framework/util/services/utilities.service';

@UntilDestroy()
@Component({
  selector: 'eo-stored-queries-state',
  templateUrl: './stored-queries-state.component.html',
  styleUrls: ['./stored-queries-state.component.scss']
})
export class StoredQueriesStateComponent implements OnInit, PendingChangesComponent, OnDestroy {

  storedQueries: StoredQuery[] = [];
  selectedQuery: StoredQuery;
  gridOptions: GridOptions;
  sortFields: ColDef[];
  typeFilterFields: ISetFilter[] = [];
  gridData: StoredQuery[];
  textFilterParams: any = {};

  user: EoUser;
  selectFirst = true;

  @ViewChild('eoGrid') eoGrid: GridComponent;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private pendingChanges: PendingChangesService,
    public translate: TranslateService,
    private selection: SelectionService,
    private storedQueriesService: StoredQueriesService,
    private titleService: PageTitleService,
    private userService: UserService,
    private appSearch: AppSearchService) {
    this.titleService.setBaseTitle(this.translate.instant('eo.search.title.storedqueries'));
  }

  select(query: StoredQuery) {
    this.selection.focus(query);
    if (this.eoGrid) {
      this.eoGrid.api.deselectAll();
      this.eoGrid.api.clearFocusedCell();
    }
  }

  hasPendingChanges() {
    return this.pendingChanges.hasPendingTask();
  }

  getStoredQuerys() {
    this.refreshGrid();
    this.storedQueriesService
      .storedQueries$.pipe(
        untilDestroyed(this),
        debounceTime(100)
      ).subscribe(queriesSubscription => {
        this.gridData = queriesSubscription.queries.sort(Utils.sortValues('name')).sort(Utils.sortValues('favorite', 'desc'));
        this.updateGrid(this.gridData, queriesSubscription.select && this.gridData ? queriesSubscription.select : null);
      });
  }

  refreshGrid() {
    this.storedQueriesService.refreshStoredQueries();
  }

  getRowIndex(id) {
    if (this.eoGrid) {
      const rowNodeId = this.eoGrid.gridOptions.getRowNodeId(id);
      const rowNode = this.eoGrid.api.getRowNode(rowNodeId);
      return rowNode ? rowNode.rowIndex : null;
    }
    return null;
  }

  notifyList(val) {
    if (this.eoGrid) {
      const {unselect, update, payload} = val;
      if (!unselect && !update && !payload) {
        setTimeout(() => this.eoGrid.selectRow(0), 500);
      }
    }
  }

  typeFilter(node, item: string) {
    switch (item) {
      case TypeFilter.favored:
      case TypeFilter.shared:
        return !UtilitiesService.isEmptyOrFalse(node.data[item]);
      case TypeFilter.others:
        return UtilitiesService.isEmptyOrFalse(node.data[TypeFilter.favored]) && UtilitiesService.isEmptyOrFalse(node.data[TypeFilter.shared]);
      default:
        return true;
    }
  }

  typeFilters() {
    this.typeFilterFields = Object.keys(TypeFilter).map(item => ({
      label: this.translate.instant(`eo.storedquery.${item}.filter.title`),
      filter: (node) => this.typeFilter(node, TypeFilter[item]),
      value: true,
      id: item
    }));
  }

  resetFilters() {
    this.typeFilters();
  }

  updateGrid(data, id) {

    if (this.eoGrid && this.eoGrid.isReady) {
      // todo: hotfix: enable auto selection in case it was disabled by new query
      setTimeout(() => (this.selectFirst = true), 1000);
      return this.eoGrid.updateRowData(data, id);
    }

    this.resetFilters();

    let colDefs: ColDef[] = [
      {
        headerName: '',
        field: '__custom',
        cellRenderer: this.cellRenderer,
        filter: false
      }
    ];

    this.textFilterParams = {context: {defaultValue: ''}};
    this.sortFields = [{field: 'name', headerName: this.translate.instant('eo.inbox.sort.title'), sort: ''}];
    let sortColDefs = CustomSortComponent.sortColDefs(this.sortFields);

    this.gridOptions = {
      context: {count: data.length, currentUser: this.userService.getCurrentUser()},
      rowData: data,
      columnDefs: colDefs.concat(sortColDefs),
      rowBuffer: 20,
      rowHeight: 80
    };
  }

  cellRenderer(params) {
    const own = params.context.currentUser.name.toLowerCase() === params.data.user.toLowerCase();

    const favIconTitle = !own ? 'eo.storedquery.favoredfor.title' : 'eo.storedquery.favored.title';
    const shareIconTitle = !own ? 'eo.storedquery.sharedfor.title' : 'eo.storedquery.shared.title';

    const favIcon = UtilitiesService.isEmptyOrFalse(params.data[TypeFilter.favored]) ? '' :
      params.context.cr.render('icon', params, {
        value: {label: params.context.translate.instant(favIconTitle), url: 'assets/_default/svg/ic_favorite.svg'}
      });

    const shareIcon = UtilitiesService.isEmptyOrFalse(params.data[TypeFilter.shared]) ? '' :
      params.context.cr.render('icon', params, {
        value: {label: params.context.translate.instant(shareIconTitle), url: 'assets/_default/svg/ic_share.svg'}
      });


    return `<div class="list-item" unselectable>
              <div class='content'>
                <div class='title'>${params.data.name || ''}</div>
                <div class='icons ${!own ? 'shared-with-me' : ''}'>
                  <span>${favIcon}</span>
                  <span>${shareIcon}</span>
                </div>
              </div>
              <div class="meta">
              </div>
           </div>
          `;
  }

  remove() {
    let focused = this.selection.getFocus();
    if (focused) {
      this.storedQueriesService.removeStoredQuery(focused.id).subscribe();
    }
  }

  ngOnDestroy() {
    this.selection.clear();
  }

  private createNewStoredQuery() {
    this.appSearch.query$
      .pipe(
        untilDestroyed(this),
        first()
      )
      .subscribe(query => {
        let sq = new StoredQuery();
        sq.setQuery(query);
        this.select(sq);
      }
      );
    // get rid of the query param
    this.router.navigate(['/stored-queries'], {replaceUrl: true});
  }

  ngOnInit() {

    this.getStoredQuerys();

    // in case of getting the create param, we'll fetch the latest query from
    // appSearchService and create a new StoredQuery from it
    if (this.route.snapshot.queryParams['create']) {
      this.selectFirst = false;
      this.createNewStoredQuery();
    }

    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        if (event instanceof NavigationEnd && event.urlAfterRedirects === '/stored-queries?create=true') {
          this.createNewStoredQuery();
        }
      });
  }

}
