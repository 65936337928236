import {Component, HostListener, ViewChild} from '@angular/core';
import {ShortcutsService} from '../shortcuts.service';
import {GlobalShortcutComponent} from '../global-shortcut-component.interface';
import {fromEvent} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';

@Component({
  selector: 'eo-global-shortcuts',
  templateUrl: './global-shortcuts.component.html',
  styleUrls: ['./global-shortcuts.component.scss']
})
export class GlobalShortcutsComponent {

  @ViewChild('shortcuts') container;

  private hotkeyTick = 0;
  target;
  shortcutsVisible: boolean;
  private _boundComponents: GlobalShortcutComponent[];
  items: GlobalShortcutComponent[];

  constructor(private shortcutsService: ShortcutsService) {
    shortcutsService.components$.subscribe((boundComponents) => {
      this._boundComponents = boundComponents;
    });

    let timeOut;
    // notice: it's important that global shortcuts listen on the windows object rather than on document
    // That's because other global listeners (like uploader) should be able to cancel keyboard events to
    // avoid the shortcuts overlay to be triggered
    fromEvent(window, 'keyup').pipe(
      debounceTime(100),
      tap((event: KeyboardEvent) => {
        if (event.key === 'Control') {
          this.hotkeyTick++;
          if (this.hotkeyTick === 2) {
            this.showShortcutsPanel(event);
            this.hotkeyTick = 0;
          } else {
            timeOut = setTimeout(() => this.hotkeyTick = 0, 1000);
          }
        } else {
          this.hotkeyTick = 0;
          if (timeOut) {
            clearTimeout(timeOut);
          }
        }

        if (this.shortcutsVisible) {
          switch (event.key) {
            case 'Control':
              break;
            case 'Escape':
              this.hideShortcutsPanel();
              clearTimeout(timeOut);
              break;
            case 'ArrowLeft':
              this.focusPrevious();
              break;
            case 'ArrowRight':
              this.focusNext();
              break;
          }
        }
      })
    ).subscribe()
  }

  // prevent setting focus from the shortcut component itself
  // while the shortcut panel is showing
  @HostListener('focusin', ['$event']) focusIn(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  showShortcutsPanel(evt) {
    if (!this.shortcutsVisible) {
      this.target = evt.target;
      this.items = this._boundComponents.filter(c => {
        // filter items to show only shortcut sections that have at least one visible action
        let visibleActions = c.actions.filter(a => !a['isHidden'] || !a.isHidden(this.target));
        return visibleActions.length > 0;
      });
      if (!~this.items.findIndex(c => c.focused)) {
        this.focusNext();
      }

      this.shortcutsVisible = true;
      setTimeout(() => this.container.nativeElement.focus());
    }
  }

  hideShortcutsPanel() {
    this.shortcutsVisible = false;
    if (this.target) {
      this.target.focus();
    }
  }

  // callback passed to the globalShortcutsSectionComponent to be executed
  // when an action was executed
  onActionExecuted(evt) {
    this.hideShortcutsPanel();
  }

  // focus next shortcut section
  focusNext() {
    let focusedIndex = this.items.findIndex(c => c.focused);
    let targetIndex;
    if (focusedIndex === -1 || focusedIndex === this.items.length - 1) {
      // nothing focused right now
      targetIndex = 0;
    } else {
      targetIndex = focusedIndex + 1;
    }
    this.shortcutsService.focusComponent(this.items[targetIndex].id);
  }

  // focus previous shortcut section
  focusPrevious() {
    let focusedIndex = this.items.findIndex(c => c.focused);
    let targetIndex;
    if (focusedIndex === -1) {
      targetIndex = 0;
    } else if (focusedIndex === 0) {
      // nothing focused right now
      targetIndex = this.items.length - 1;
    } else {
      targetIndex = focusedIndex - 1;
    }
    this.shortcutsService.focusComponent(this.items[targetIndex].id);
  }
}
