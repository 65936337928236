import {Component, OnInit} from '@angular/core';
import {BpmService, ExecutableProcess, NotificationsService, TranslateService, Utils} from '@eo-sdk/core';
import {Router} from '@angular/router';
import {ObjectFormOptions} from '../../../object-form';
import {PendingChangesService} from '../../../../eo-framework-core/pending-changes/pending-changes.service';

/**
 * Component rendering the app-bar action of starting executable processes.
 */

@Component({
  selector: 'eo-app-process',
  templateUrl: './app-process.component.html',
  styleUrls: ['./app-process.component.scss']
})
export class AppProcessComponent implements OnInit {

  processes: ExecutableProcess[];
  selectedProcess: ExecutableProcess;

  processForm: ObjectFormOptions;
  title: string;
  subtitle: string;
  pendingTaskIds: string[] = [];

  constructor(private router: Router,
              private toaster: NotificationsService,
              private translate: TranslateService,
              private bpmService: BpmService,
              private pendingChanges: PendingChangesService) {
  }

  close(evt?): void {
    this.router.navigate([{outlets: {modal: null}}], {replaceUrl: true});
  }

  /**
   * Select an executable process from the current list of processes.
   * If the process requires some user input, this will render the input form or
   * otherwise start the process right away.
   * @param executableProcess Process to be selected/started
   */
  selectProcess(executableProcess: ExecutableProcess): void {
    this.bpmService.getExecutableProcesses(null, false, true, executableProcess.id, true).subscribe(
      res => {
        executableProcess = res[0];
        this.selectedProcess = JSON.parse(JSON.stringify(executableProcess)); // Only copies of the original processes are used.
        if (this.selectedProcess.form) {
          // create form options from the execitable process to be passed to the rendering form component
          this.selectedProcess.form.situation = 'CREATE';
          this.processForm = {
            formModel: this.selectedProcess.form,
            data: this.selectedProcess.data || {}
          };
        } else {
          // start process right away
          this.executeProcess(
            this.selectedProcess,
            {},
            this.translate.instant('eo.process.started', {processName: this.selectedProcess.title})
          );
        }
      }
    );
  }

  /**
   * Starts the currently selected executable process with data provided by
   * the input form.
   * @param formData Data to be passed to the process to be created
   */
  startProcessWithFormData(formData: any): void {
    this.finishPending();
    this.executeProcess(
      this.selectedProcess,
      formData,
      this.translate.instant('eo.process.started', {processName: this.selectedProcess.title})
    );
  }

  private executeProcess(executableProcess: ExecutableProcess, data: any, successMessage = ''): void {
    this.cancelDialog();
    this.close();

    this.bpmService.startProcess(executableProcess.id, data, [])
      .subscribe(() => {
        this.toaster.success(successMessage);
      }, Utils.throw(() => {
          this.toaster.error(this.translate.instant(
            'eo.process.started.fail',
            {processName: executableProcess.title})
          );
        }
      ));
  }

  formReset(event): void {
    this.processForm = null;
  }

  cancelDialog(): void {
    this.processForm = null;
  }

  onCancel(dialog): void {
    if (!this.pendingChanges.checkForPendingTasks(this.pendingTaskIds)) {
      this.finishPending();
      dialog.visible = false;
    }
  }

  private startPending() {
    // because this method will be called every time the form status changes,
    // pending task will only be started once until it was finished
    if (!this.pendingChanges.hasPendingTask(this.pendingTaskIds[0] || ' ')) {
      this.pendingTaskIds = [this.pendingChanges.startTask()];
    }
  }

  private finishPending() {
    this.pendingChanges.finishTask(this.pendingTaskIds[0]);
  }

  onIndexDataChanged(event) {
    if (event.dirty) {
      this.startPending();
    } else {
      this.finishPending();
    }
  }

  ngOnInit() {
    this.bpmService.getExecutableProcesses(null, true, null, null, true)
      .subscribe(res => this.processes = res.sort(Utils.sortValues('title')));
  }
}
