import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtaComponent } from './component/cta.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [CtaComponent],
  exports: [CtaComponent]
})
export class CtaModule { }
