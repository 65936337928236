import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {IWidgetSetupComponent} from '@yuuvis/widget-grid';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Capabilities, CapabilitiesService, PrepareService, TranslateService} from '@eo-sdk/core';
import {InfoIntrayConfig} from '../info-intray.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@Component({
  selector: 'eo-info-intray-setup',
  templateUrl: './info-intray-setup.component.html',
  styleUrls: ['./info-intray-setup.component.scss']
})

@UntilDestroy()
export class InfoIntraySetupComponent implements IWidgetSetupComponent, OnInit {

  private _widgetConfig: InfoIntrayConfig;
  capabilities: Capabilities;
  preparedItemsCount: number;

  @Output() widgetConfigChange = new EventEmitter<InfoIntrayConfig>();

  setupForm: UntypedFormGroup = this.fb.group({
    title: [this.translate.instant('eo.dashboard.info.intray.msg'), [Validators.maxLength(50)]],
  });
  @Input() set widgetConfig(wc: InfoIntrayConfig) {
    this._widgetConfig = wc;
    this.setupForm.patchValue({
      title: wc ? wc.title : '',
    });
  }

  get widgetConfig() {
    return this._widgetConfig;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private capabilityService: CapabilitiesService,
    private prepareService: PrepareService) {
    this.setupForm.valueChanges.pipe(untilDestroyed(this)).subscribe((_) => {
      this.setWidgetConfig();
    });
  }

  private setWidgetConfig() {
    if(this.setupForm.valid){
      this._widgetConfig = {
        ... this.setupForm.value,
      };
      this.widgetConfigChange.emit(this._widgetConfig);
    }
  }

  ngOnInit(): void {
    this.setWidgetConfig();
    this.capabilities = this.capabilityService.getCapabilities();
    if (this.capabilities.intray) {
      this.prepareService.preparedItemCount$
        .pipe(untilDestroyed(this))
        .subscribe(count => this.preparedItemsCount = count);
    }
  }
}
