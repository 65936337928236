import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UtilitiesService} from './services/utilities.service';
import {OrderByPipe} from './sortable/order-by.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [OrderByPipe],
  providers: [UtilitiesService],
  exports: [OrderByPipe]
})
export class UtilModule {
}
