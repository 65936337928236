import {HttpClient} from '@angular/common/http';
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {
  Capabilities,
  CapabilitiesService,
  Config,
  EoUser,
  LocalStorageService,
  StoredQueriesService,
  StoredQuery,
  TranslateService,
  UserService,
  Utils
} from '@eo-sdk/core';
import {
  PictureWidgetComponent,
  PictureWidgetSetupComponent,
  TodoWidgetComponent,
  TodoWidgetSetupComponent,
  WidgetGridRegistry,
  WidgetGridWorkspaceConfig,
  WidgetGridWorkspaceOptions
} from '@yuuvis/widget-grid';
import {switchMap} from 'rxjs/operators';
import {PageTitleService, PendingChangesService} from '../../eo-framework-core';
import {listAnimation} from '../../eo-framework';
import {QuickSearchResult} from '../../eo-framework';
import {QuickSearchComponent} from '../../eo-framework';
import {SearchWidgetComponent} from './widgets/search-widget/search-widget.component';
import {TreeNode} from '../../eo-framework';
import {IframeSetupComponent} from './widgets/iframe-widget/iframe-setup/iframe-setup.component';
import {LastEditedAndCreatedComponent} from './widgets/last-edited-widget/last-edited-and-created.component';
import {IframeWidgetComponent} from './widgets/iframe-widget/iframe-widget.component';
import {InfoIntrayWidgetComponent} from './widgets/info-intray-widget/info-intray-widget.component';
import {InfoIntraySetupComponent} from './widgets/info-intray-widget/info-intray-setup/info-intray-setup.component';
import {InfoInboxWidgetComponent} from './widgets/info-inbox-widget/info-inbox-widget.component';
import {InfoInboxSetupComponent} from './widgets/info-inbox-widget/info-inbox-setup/info-inbox-setup.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {HitlistSetupComponent} from './widgets/hitlist-widget/hitlist-setup/hitlist-setup.component';
import {HitlistWidgetComponent} from './widgets/hitlist-widget/hitlist-widget.component';
import {AfterViewInit} from '@angular/core';
import {ChartsWidgetComponent} from './widgets/charts-widget/charts-widget.component';
import {ChartsSetupComponent} from './widgets/charts-widget/charts-setup/charts-setup.component';

@UntilDestroy()
@Component({
  selector: 'eo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    listAnimation
  ]
})
export class DashboardComponent implements OnInit, AfterViewInit {

  workspaceConfig: WidgetGridWorkspaceConfig | undefined;
  workspaceOptions: WidgetGridWorkspaceOptions = {
    gridConfig: {
      rows: 30,
      newItemWidth: 2,
      newItemHeight: 5
    }
  };
  backgroundImage: string;
  capabilities: Capabilities;
  storedQueries: StoredQuery[] = [];
  selectedQuery: StoredQuery;
  quickSearchResult: QuickSearchResult;
  tree = {};
  STORAGE_KEY = 'eo.client.dashboard.currentWorkspace';
  toolbarVisible: boolean;
  toolbarIconSrc: string;
  toolbarIconTooltip: string;
  pendingTaskID = '';

  user: EoUser;
  @ViewChild('quickSearch') quickSearchEl: QuickSearchComponent;

  @HostListener('window:keyup', ['$event'])
  onKey(event) {
    if (event.which === 27) {
      this.selectStoredQuery(null);
    }
  }

  constructor(public userService: UserService,
    private widgetGridRegistry: WidgetGridRegistry,
    private titleService: PageTitleService,
    private router: Router,
    private http: HttpClient,
    private config: Config,
    private storageService: LocalStorageService,
    public translate: TranslateService,
    private capabilityService: CapabilitiesService,
    private storedQueriesService: StoredQueriesService,
    private pendingChanges: PendingChangesService) {
    this.titleService.setDefaultTitle();
    this.backgroundImage = `url(${this.config.getDashboardBackgroundImage()})`;
    this.setWidgetGridLabels();
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe((_) => {
      this.setWidgetGridLabels();
    });
  }

  onWorkspacesConfigChange(c: WidgetGridWorkspaceConfig) {
    this.saveWorkspacesConfig(c);
  }

  private setWidgetGridLabels() {
    this.widgetGridRegistry.updateRegisteredWidgetsLabels({
      "yuv.widget.picture": this.translate.instant('eo.workspace.widget.picture.label'),
      "yuv.widget.todo": this.translate.instant('eo.workspace.widgetGrid.widgetTodoHeadlineLabel'),
      "yuv.widget.LastEdited": this.translate.instant('eo.workspace.widget.lastEditedOrLastCreated.label'),
      "yuv.widget.iframe": this.translate.instant('eo.workspace.widget.iframe.label'),
      "yuv.widget.infoIntray": this.translate.instant('eo.workspace.widget.infoIntray.label'),
      "yuv.widget.infoInbox": this.translate.instant('eo.workspace.widget.infoInbox.label'),
      "yuv.widget.quickSearch": this.translate.instant('eo.workspace.widget.quicksearch.label'),
      "yuv.widget.hitlist": this.translate.instant('eo.workspace.widget.hitlist.label'),
      "yuv.widget.chart": this.translate.instant('eo.workspace.widget.chart.label'),
    });

    this.widgetGridRegistry.updateWidgetGridLabels({
      widgetPickerTitle: this.translate.instant('eo.workspace.widgetGrid.widgetPickerTitle'),
      addWorkspaceTooltip: this.translate.instant('eo.workspace.widgetGrid.addWorkspace'),
      noopWidgetLabel: this.translate.instant('eo.workspace.widgetGrid.noopWidgetLabel'),
      workspacesEmptyMessage: this.translate.instant('eo.workspace.widgetGrid.workspacesEmptyMessage'),
      newWorkspaceDefaultLabel: this.translate.instant('eo.workspace.widgetGrid.newWorkspaceDefaultLabel'),
      workspaceLabelPlaceholder: this.translate.instant('eo.workspace.widgetGrid.newWorkspaceDefaultLabel'),
      workspaceRemoveConfirmMessage: this.translate.instant('eo.workspace.widgetGrid.workspaceRemoveConfirmMessage'),
      workspaceEditDone: this.translate.instant('eo.workspace.widgetGrid.workspaceEditDone'),
      workspaceEditCancel: this.translate.instant('eo.workspace.widgetGrid.cancel'),
      save: this.translate.instant('eo.workspace.widgetGrid.save'),
      cancel: this.translate.instant('eo.workspace.widgetGrid.cancel'),
      confirm: this.translate.instant('eo.workspace.widgetGrid.confirm'),
      widgetTodoHeadlineLabel: this.translate.instant('eo.workspace.widgetGrid.widgetTodoHeadlineLabel'),
      widgetTodoTaskTitleLabel: this.translate.instant('eo.workspace.widgetGrid.widgetTitleLabel')
    });
  }

  private registerWidgets() {
    
    this.widgetGridRegistry.registerGridWidgets([
      {
        name: 'yuv.widget.picture',
        label: this.translate.instant('eo.workspace.widget.picture.label'),
        setupComponent: PictureWidgetSetupComponent,
        widgetComponent: PictureWidgetComponent,
      },
      {
        name: 'yuv.widget.todo',
        label: this.translate.instant('eo.workspace.widgetGrid.widgetTodoHeadlineLabel'),
        setupComponent: TodoWidgetSetupComponent,
        widgetComponent: TodoWidgetComponent,
      },
      {
        name: 'yuv.widget.LastEdited',
        label: this.translate.instant('eo.workspace.widget.lastEditedOrLastCreated.label'),
        widgetComponent: LastEditedAndCreatedComponent,
      },
      {
        name: 'yuv.widget.iframe',
        label: this.translate.instant('eo.workspace.widget.iframe.label'),
        widgetComponent: IframeWidgetComponent,
        setupComponent: IframeSetupComponent,
      },
      {
        name: 'yuv.widget.infoIntray',
        label: this.translate.instant('eo.workspace.widget.infoIntray.label'),
        widgetComponent: InfoIntrayWidgetComponent,
        setupComponent: InfoIntraySetupComponent
      },
      {
        name: 'yuv.widget.infoInbox',
        label: this.translate.instant('eo.workspace.widget.infoInbox.label'),
        widgetComponent: InfoInboxWidgetComponent,
        setupComponent: InfoInboxSetupComponent
      },
      {
        name: 'yuv.widget.quickSearch',
        label: this.translate.instant('eo.workspace.widget.quicksearch.label'),
        widgetComponent: SearchWidgetComponent,
      },
      {
        name: 'yuv.widget.hitlist',
        label: this.translate.instant('eo.workspace.widget.hitlist.label'),
        setupComponent: HitlistSetupComponent,
        widgetComponent: HitlistWidgetComponent,
      },
      {
        name: 'yuv.widget.chart',
        label: this.translate.instant('eo.workspace.widget.chart.label'),
        setupComponent:  ChartsSetupComponent,
        widgetComponent: ChartsWidgetComponent,
      }
    ]);
  }

  selectStoredQuery(q: StoredQuery) {
    this.selectedQuery = q;
  }

  // user changed input of quickSearch component
  onQuickSearchInputChange(term) {
    this.quickSearchResult = null;
  }

  // quickSearch component is returning results
  onQuickSearchResult(result: QuickSearchResult) {
    this.quickSearchResult = result;
    Object.keys(this.quickSearchResult.groups).map(key => (this.tree[key] = this.getTreeNodes(key)));
  }

  onStoredQueryLoaded() {
    this.selectStoredQuery(null);
    this.router.navigate([{outlets: {modal: 'search'}}]);
  }

  onStoredQueryExecute(evt) {
    this.selectStoredQuery(null);
    const uriParam = encodeURIComponent(JSON.stringify(evt.queryJson));
    const uriParamQuery: NavigationExtras = {queryParams: {'query': uriParam}};
    this.router.navigate(['/result'], uriParamQuery);
  }
  getTreeNodes(key): TreeNode[] {
    return this.quickSearchResult.groups[key].map(group => ({
      id: group.data.id,
      name: group.label,
      selectable: true,
      data: group.data,
      badges: [{value: group.count}]
    }));
  }

  ngOnInit() {
    this.loadWorkspacesConfig();
    this.registerWidgets();
    this.capabilities = this.capabilityService.getCapabilities();
    if (this.capabilities.storedqueries) {
      this.storedQueriesService.getStoredQueries$()
        .pipe(untilDestroyed(this))
        .subscribe(queriesSubscription => this.storedQueries = queriesSubscription.queries.filter(q => q.favorite).sort(Utils.sortValues('name')));
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.toolbarVisible = this.userService.getCurrentUser().userSettings.toolbarVisibility;
      const toolbar = document.querySelector('.toolbar') as HTMLElement;
      if (this.toolbarVisible === false) {
        this.hideToolbar(toolbar);
      } else {
        this.showToolbar(toolbar);
      }
      const showDashboardButton = document.getElementById('dashboardButton');
      showDashboardButton.addEventListener('click', () => {
        this.hideOrShowOnClick(toolbar);
      });
    }, 200);

  }

  private hideToolbar(toolbar: HTMLElement) {
    this.toolbarIconSrc = 'assets/_default/svg/ic_arrow_up.svg';
    this.toolbarIconTooltip = 'eo.dashboard.button.show.footer.tooltip';
    toolbar.style.display = 'none';
    this.toolbarVisible = false;
  }

  private showToolbar(toolbar: HTMLElement) {
    this.toolbarIconSrc = 'assets/_default/svg/ic_arrow_down.svg';
    this.toolbarIconTooltip = 'eo.dashboard.button.hide.footer.tooltip';
    toolbar.style.display = 'flex';
    this.toolbarVisible = true;
  }

  private hideOrShowOnClick(toolbar: HTMLElement) {
    if (this.toolbarVisible) {
      this.hideToolbar(toolbar);
      this.userService.saveToolbarState(this.toolbarVisible).subscribe();
    } else {
      this.showToolbar(toolbar);
      this.userService.saveToolbarState(this.toolbarVisible).subscribe();
    }
  }

  private loadWorkspacesConfig() {
    if (!this.userService.getCurrentUser().userSettings.workspaceOptions || this.userService.getCurrentUser().userSettings.workspaceOptions.workspaces.length === 0) {
      this.http.get('assets/dashboard.json').subscribe({
        next: (res: WidgetGridWorkspaceConfig) => {
          this.workspaceConfig = res;
          this.workspaceConfig.workspaces[0].label = this.translate.instant(this.workspaceConfig.workspaces[0].label);
          this.workspaceConfig.workspaces[0].grid.forEach(grid => {
            if (grid.widgetConfig) {
              grid.widgetConfig.title = this.translate.instant(grid.widgetConfig.title);
            }
          });
        },
        error: () => {
          // no config file found
        }
      });
    } else {
      this.workspaceConfig = this.userService.getCurrentUser().userSettings.workspaceOptions;
      const currentWorkspace = this.storageService.getItem(this.STORAGE_KEY);
      if (currentWorkspace && this.workspaceConfig.workspaces.map((w) => w.id).includes(currentWorkspace)) {
        this.workspaceConfig.currentWorkspace = currentWorkspace;
      } else if (!!this.workspaceConfig.workspaces.length) {
        this.workspaceConfig.currentWorkspace = this.workspaceConfig.workspaces[0].id;
      }
    }
  }

  private saveWorkspacesConfig(c: WidgetGridWorkspaceConfig) {
    // the selected workspace is saved on the device while the workspaces themselves are stored on the user service
    if (c) {
      this.userService.saveChangeWorkspace(c).pipe(switchMap(async () => this.storageService.setItem(this.STORAGE_KEY, c.currentWorkspace))).subscribe();
    }
  }

  onWorkspaceEditMode(onEdit: boolean) {
    if (onEdit) {
      this.pendingTaskID = this.pendingChanges.startTask();
    } else {
      this.pendingChanges.finishTask(this.pendingTaskID)
    }
  }
}
