import { Input, ViewChild, ViewContainerRef, Injectable } from '@angular/core';

import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from '@ag-grid-community/core';
import {IFilterAngularComp} from '@ag-grid-community/angular';

@Injectable()
export abstract class AbstractFilterComponent implements IFilterAngularComp {
  params: IFilterParams;
  valueGetter: (rowNode: RowNode) => any;
  public value: any;

  @ViewChild('input', {read: ViewContainerRef}) public input;

  @Input() id = '#filter';

  @Input()
  set filterParams(params: IFilterParams) {
    this.params = Object.assign({}, this.params, params);
    if (this.defaultValue) {
      setTimeout(() => this.onChange(this.defaultValue), 0);
    }
  }
  get filterParams() {
    return this.params;
  }

  get defaultValue() {
    return this.params && this.params.context && this.params.context.defaultValue;
  }

  onChange(newValue, oldValue?): void {
    if (this.value !== newValue) {
      this.value = newValue;
      this.params.filterChangedCallback();
    }
  }

  agInit(params: IFilterParams): void {
    if (!this.valueGetter) {
      this.valueGetter = params.valueGetter;
    }
    this.filterParams = params;
  }

  reset() {
    this.value = null;
  }

  isFilterActive(): boolean {
    return this.value !== null && this.value !== undefined && this.value.length;
  }

  doesExternalFilterPass(rowNode: RowNode): boolean {
    return true;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return this.doesExternalFilterPass(params.node);
  }

  getModel(): any {
    return {value: this.value};
  }

  setModel(model: any): void {
    this.value = model ? model.value : '';
  }

  afterGuiAttached(params: IAfterGuiAttachedParams): void {
    this.input.element.nativeElement.focus();
  }

}
