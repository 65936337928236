import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListContainerComponent} from './list-container.component';
import {UiModule} from '../ui/ui.module';

@NgModule({
  imports: [
    CommonModule,
    UiModule
  ],
  declarations: [ListContainerComponent],
  exports: [ListContainerComponent]
})
export class ListContainerModule {
}
