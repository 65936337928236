<header>
    <div class="header-title">
        <div class="title" *ngIf="widgetConfig?.formValue?.storedQuery">
            {{widgetConfig?.formValue?.storedQuery}}</div>
        <div class="icons-title" *ngIf="this.widgetConfig?.storedQuery">
            <eo-icon class="button primary" [iconSrc]="'assets/_default/svg/ic_saved_search.svg'" (click)="setQueryToAppSearch()"
            [iconTitle]="('eo.widget.hitlist.tooltip.savedSearchIcon'| translate)"></eo-icon>
            <eo-icon class="button primary" [iconSrc]="'assets/_default/svg/ic_open_tab.svg'"
                (click)="onEmitQueryClick($event)"
                [iconTitle]="('eo.widget.hitlist.tooltip.openinresultlist'| translate)"></eo-icon>
            <eo-icon class="button primary" [iconSrc]="'assets/_default/svg/ic_refresh.svg'" (click)="refresh()"
                [iconTitle]="('eo.widget.hitlist.tooltip.refresh'| translate)"></eo-icon>
        </div>
    </div>
</header>

<main class="loader-overlay__mask" *ngIf="refreshingRequest">
    <eo-loading-spinner [size]="'large'"></eo-loading-spinner>
</main>

<main class="chart" *ngIf="chartData.length > 0 && !refreshingRequest; else tplEmpty">
    <div class="item" [ngSwitch]="widgetConfig!.formValue.chartType">
        <ibm-simple-bar-chart *ngSwitchCase="'bar'" [data]="chartData" [options]="chartOptions"></ibm-simple-bar-chart>
        <ibm-pie-chart *ngSwitchCase="'pie'" [data]="chartData" [options]="chartOptions"></ibm-pie-chart>
        <ibm-donut-chart *ngSwitchCase="'donut'" [data]="chartData" [options]="chartOptions"></ibm-donut-chart>
    </div>
</main>
<ng-template #tplEmpty>
    <div class="empty" *ngIf="!refreshingRequest">
        <eo-error-message
            [emptyState]="{icon: 'ic_saved_search.svg', text: 'eo.workspace.widget.configuration.notOk'}"></eo-error-message>
    </div>
</ng-template>
