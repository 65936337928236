<div class="setup">
    <form [formGroup]="setupForm">
        <eo-form-input [required]="true" [skipToggle]="true"
            [label]="'eo.workspace.widget.form.label.chartTypeField' | translate">
            <eo-codesystem formControlName="chartType" [pickerTitle]="chartTypePicker.title"
                [codesystem]="chartTypePicker.codesystem"></eo-codesystem>
        </eo-form-input>

        <eo-form-input [required]="true" [skipToggle]="true" *ngIf="storedQueriesPicker?.codesystem?.entries"
            [label]="'eo.search.title.storedqueries' | translate" [description]="'eo.workspace.widget.stored.queries.description'">
            <eo-codesystem formControlName="storedQuery" [pickerTitle]="storedQueriesPicker.title"
                [codesystem]="storedQueriesPicker.codesystem"
                [emptyMessage]="'eo.workspace.widget.stored.queries.list.notAvailable'"></eo-codesystem>
        </eo-form-input>

        <eo-form-input [required]="true" [skipToggle]="true"
            *ngIf="aggregationPicker?.codesystem?.entries && this.setupForm.get('storedQuery').value"
            [label]="'eo.workspace.widget.form.label.aggregationField' | translate">
            <eo-codesystem #aggregationCodesystem formControlName="aggregation" [pickerTitle]="aggregationPicker.title"
                [codesystem]="aggregationPicker.codesystem"></eo-codesystem>
        </eo-form-input>

        <eo-form-input [required]="true" [skipToggle]="true"
            *ngIf="isDateTimeType" [label]="'eo.workspace.widget.form.label.histogram' | translate">
            <eo-codesystem formControlName="histogramType" [pickerTitle]="histogramTypePicker.title"
                [codesystem]="histogramTypePicker.codesystem"></eo-codesystem>
        </eo-form-input>

        <eo-form-input [skipToggle]="true" *ngIf="this.widgetConfig?.formValue?.histogramType !== 'standard'" [description]="'eo.workspace.widget.chart.form.label.description'"
            [label]="'eo.workspace.widget.form.partialValue' | translate">
            <eo-number [scale]="0" formControlName="size"></eo-number>
        </eo-form-input>

        <div class="err-msg" *ngIf="setupForm.get('size').hasError('max') || setupForm.get('size').hasError('min')">
            <div translate>
                eo.form.property.string.error.classification.partialValue </div>
        </div>

    </form>
</div>

<div class="preview"
    *ngIf="setupForm.get('aggregation').value && setupForm.get('chartType').value && setupForm.get('storedQuery').value && (isDateTimeType && setupForm.get('histogramType').value || !isDateTimeType); else tplEmpty">
    <eo-charts-widget [widgetConfig]="widgetConfig" [editMode]="true"></eo-charts-widget>
</div>

<ng-template #tplEmpty>
    <div class="empty">
        <eo-error-message
            [emptyState]="{icon: 'ic_saved_search.svg', text: 'eo.workspace.widget.configuration.notOk'}"></eo-error-message>
    </div>
</ng-template>
